export const checkWeekMoreThan28 = [
    "C8", "C9",
    "D8", "D9",
    "E8", "E9",
    "F8", "F9",
    "G8", "G9",
    "H8", "H9",
    "I8", "I9",
    "J8", "J9",
    "K8", "K9",
    "L8", "L9",

    "C18", "C19", "C20", "C21", "C22", "C23", "C24", "C25",
    "D18", "D19", "D20", "D21", "D22", "D23", "D24", "D25",
    "E18", "E19", "E20", "E21", "E22", "E23", "E24", "E25",
    "F18", "F19", "F20", "F21", "F22", "F23", "F24", "F25",
    "G18", "G19", "G20", "G21", "G22", "G23", "G24", "G25",
    "H18", "H19", "H20", "H21", "H22", "H23", "H24", "H25",
    "I18", "I19", "I20", "I21", "I22", "I23", "I24", "I25",
    "J18", "J19", "J20", "J21", "J22", "J23", "J24", "J25",
    "K18", "K19", "K20", "K21", "K22", "K23", "K24", "K25",
    "L18", "L19", "L20", "L21", "L22", "L23", "L24", "L25",

    "C33", "C34", "C35", "C36", "C37", "C38", "C39", "C40",
    "D33", "D34", "D35", "D36", "D37", "D38", "D39", "D40",
    "E33", "E34", "E35", "E36", "E37", "E38", "E39", "E40",
    "F33", "F34", "F35", "F36", "F37", "F38", "F39", "F40",
    "G33", "G34", "G35", "G36", "G37", "G38", "G39", "G40",
    "H33", "H34", "H35", "H36", "H37", "H38", "H39", "H40",
    "I33", "I34", "I35", "I36", "I37", "I38", "I39", "I40",
    "J33", "J34", "J35", "J36", "J37", "J38", "J39", "J40",
    "K33", "K34", "K35", "K36", "K37", "K38", "K39", "K40",
    "L33", "L34", "L35", "L36", "L37", "L38", "L39", "L40",

    "C11", "E11", "G11", "I11", "K11",

    "C27", "E27", "G27", "I27", "K27", "N27"
]

export const checkSummaryMoreThan28 = [
    "D8", "D9", "D11",

    "D18", "D19", "D20", "D21", "D22", "D23", "D24", "D25", "D27",

    "D33", "D34", "D35", "D36", "D37", "D38", "D39", "D40", "D42",

    "D49"
]