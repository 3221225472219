import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../layout";
import Admin from "../view/dashboard/admin";
import AddAdmin from "../view/dashboard/admin/addAdmin";
import Car from "../view/dashboard/car";
import AddCar from "../view/dashboard/car/addCar";
import LateReport from "../view/dashboard/lateReport";
import Location from "../view/dashboard/location";
import AddLocation from "../view/dashboard/location/addlocation";
import News from "../view/dashboard/news";
import AddNews from "../view/dashboard/news/addnews";
import DriverNews from "../view/dashboard/driverNews";
import AddDriverNews from "../view/dashboard/driverNews/addnews";
import AddNotification from "../view/dashboard/notification";
import ReachedValue from "../view/dashboard/reachedValue";
import Path from "../view/dashboard/path";
import AddPath from "../view/dashboard/path/addPath";
import PathGroup from "../view/dashboard/pathGroup";
import AddPathGroup from "../view/dashboard/pathGroup/addPathGroup";
import privacyPolicy from "../view/dashboard/privacyPolicy";
import OtherInfo from "../view/dashboard/otherInfoDriver";
import DriverFcmConfig from "../view/dashboard/driverFcmConfig";
import CancelSchedule from "../view/dashboard/cancelSchedule";
import SiteDB from "../view/dashboard/siteDB";
import AddSite from "../view/dashboard/siteDB/addsite";
import Template from "../view/dashboard/template";
import AddTemplate from "../view/dashboard/template/addTemlate";
import SetSchedule from "../view/dashboard/template/setSchedule";
import SetPreSchedule from "../view/dashboard/preSchedule/setSchedule";
import TermsandCondition from "../view/dashboard/termsCondition";
import UserManual from "../view/dashboard/userManual";
import NotesToPassenger from "../view/dashboard/notesToPassenger";
import NotesToPassengerWeb from "../view/dashboard/notesToPassengerWeb";
import DownloadApp from "../view/dashboard/DownloadApp";
import User from "../view/dashboard/user";
import AddUser from "../view/dashboard/user/adduser";
import ScheduleDaily from "../view/dashboard/scheduleDaily";
import ScheduleDailyTemplate from "../view/dashboard/scheduleDaily/ScheduleDailyTemplate";
import LogoImage from "../view/dashboard/logoImage";
import Reason from "../view/dashboard/reason";
import AddReason from "../view/dashboard/reason/addReason";
import AddLateReport from "../view/dashboard/lateReport/addLateReport";
import AppInfo from "../view/dashboard/appinfo";
import ScheduleReport from "../view/dashboard/scheduleReport";
import AddScheduleReport from "../view/dashboard/scheduleReport/addScheduleReport";
import AddScheduleManage from "../view/dashboard/scheduleReport/addScheduleManage";
import CompanyInfo from "../view/dashboard/companyInfo/index";
import InquiryService from "../view/dashboard/inquiryService";
import InquiryDetails from "../view/dashboard/inquiryService/details";
import ContactUs from "../view/dashboard/contactUs";
import ServiceTag from "../view/dashboard/serviceTag";
import AddServiceTag from "../view/dashboard/serviceTag/addServiceTag";
import ServiceTagDetails from "../view/dashboard/serviceTag/addServiceTag";
import CompanyService from "../view/dashboard/companyService";
import AddCompanyService from "../view/dashboard/companyService/addCompanyService";
import PreSchedule from "../view/dashboard/preSchedule";
import PreScheduleAdd from "../view/dashboard/preSchedule/addPreschedule";

import ExportDailyReport from "../view/Report/DailyReport";
import ExportMonthlyReport from "../view/Report/MonthlyReport";
import ExportMonthltManuLifeReport from "../view/Report/MonthyReportManuLife/index";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/site" />}
        {/* // {
        //   <Redirect exact from="/dashboard" to="/article" />
        // }  */}

        {/* <Route exact path="/auth/login" component={Login} /> */}
        <Route exact path="/site" component={SiteDB} />
        <Route exact path="/site/update/:id" component={AddSite} />
        <Route exact path="/site/add" component={AddSite} />
        <Route exact path="/s-car" component={Car} />
        <Route exact path="/s-car/add" component={AddCar} />
        <Route exact path="/s-car/update/:id" component={AddCar} />
        <Route exact path="/car" component={Car} />
        <Route exact path="/car/add" component={AddCar} />
        <Route exact path="/car/update/:id" component={AddCar} />
        <Route exact path="/s-location" component={Location} />
        <Route exact path="/s-location/update/:id" component={AddLocation} />
        <Route exact path="/s-location/add" component={AddLocation} />
        <Route exact path="/location" component={Location} />
        <Route exact path="/location/update/:id" component={AddLocation} />
        <Route exact path="/location/add" component={AddLocation} />
        <Route exact path="/path" component={Path} />
        <Route exact path="/path/update/:id" component={AddPath} />
        <Route exact path="/path/add" component={AddPath} />
        <Route exact path="/PathGroup" component={PathGroup} />
        <Route exact path="/PathGroup/update/:id" component={AddPathGroup} />
        <Route exact path="/PathGroup/add" component={AddPathGroup} />
        <Route exact path="/template" component={Template} />
        <Route exact path="/template/update/:id" component={AddTemplate} />
        <Route
          exact
          path="/template/update/:id/set/schedule"
          component={SetSchedule}
        />
        <Route
          exact
          path="/pre-schedule/update/:id/set/schedule"
          component={SetPreSchedule}
        />
        <Route exact path="/template/add" component={AddTemplate} />
        <Route
          exact
          path="/template/add/:id/set/schedule"
          component={SetSchedule}
        />
        <Route
          exact
          path="/pre-schedule/add/:id/set/schedule"
          component={SetPreSchedule}
        />
        <Route exact path="/schedule/daily" component={ScheduleDaily} />
        <Route
          exact
          path="/schedule/daily/:siteId/s_template"
          component={ScheduleDailyTemplate}
        />
        <Route exact path="/pre-schedule" component={PreSchedule} />
        <Route exact path="/pre-schedule/add" component={PreScheduleAdd} />
        <Route
          exact
          path="/pre-schedule/update/:id"
          component={PreScheduleAdd}
        />
        <Route exact path="/user" component={User} />
        <Route exact path="/user/update/:id" component={AddUser} />
        <Route exact path="/user/add" component={AddUser} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/admin/update/:id" component={AddAdmin} />
        <Route exact path="/admin/add" component={AddAdmin} />
        <Route exact path="/notification" component={AddNotification} />
        <Route exact path="/reachedValue" component={ReachedValue} />
        <Route exact path="/s-news" component={News} />
        <Route exact path="/s-news/update/:id" component={AddNews} />
        <Route exact path="/s-news/add" component={AddNews} />
        <Route exact path="/news" component={News} />
        <Route exact path="/news/update/:id" component={AddNews} />
        <Route exact path="/news/add" component={AddNews} />
        <Route exact path="/drivernews" component={DriverNews} />
        <Route exact path="/drivernews/update/:id" component={AddDriverNews} />
        <Route exact path="/drivernews/add" component={AddDriverNews} />
        <Route exact path="/lateReport" component={LateReport} />
        <Route exact path="/lateReport/add" component={AddLateReport} />
        <Route exact path="/lateReport/edit/:id" component={AddLateReport} />
        <Route exact path="/privacyPolicy" component={privacyPolicy} />
        <Route exact path="/companyInfo" component={CompanyInfo} />
        <Route exact path="/otherInfo" component={OtherInfo} />
        <Route exact path="/driverFcmConfig" component={DriverFcmConfig} />
        <Route exact path="/termsCondition" component={TermsandCondition} />
        <Route exact path="/cancel-schedule" component={CancelSchedule} />
        <Route exact path="/logoImage" component={LogoImage} />
        <Route exact path="/s-userManual" component={UserManual} />
        <Route exact path="/appinfo" component={AppInfo} />
        <Route exact path="/s-app-info" component={AppInfo} />
        <Route exact path="/notesToPassenger" component={NotesToPassenger} />
        <Route
          exact
          path="/notes-to-passenger-web"
          component={NotesToPassengerWeb}
        />
        <Route exact path="/download-app" component={DownloadApp} />
        <Route exact path="/reason" component={Reason} />
        <Route exact path="/reason/add" component={AddReason} />
        <Route exact path="/reason/update/:id" component={AddReason} />
        <Route exact path="/ScheduleReport" component={ScheduleReport} />
        <Route exact path="/ScheduleReport/Add" component={AddScheduleReport} />
        <Route
          exact
          path="/ScheduleReport/update/:id"
          component={AddScheduleReport}
        />
        <Route
          exact
          path="/ScheduleReport/details/:schedule_id/:location_id"
          component={AddScheduleManage}
        />
        <Route exact path="/companyService/list" component={CompanyService} />
        <Route exact path="/companyService/add" component={AddCompanyService} />
        <Route
          exact
          path="/companyService/details/:id"
          component={AddCompanyService}
        />
        <Route
          exact
          path="/companyService/inquiryService"
          component={InquiryService}
        />
        <Route
          exact
          path="/companyService/inquiryService/:id"
          component={InquiryDetails}
        />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/companyService/serviceTag" component={ServiceTag} />
        <Route
          exact
          path="/companyService/serviceTag/add"
          component={AddServiceTag}
        />
        <Route
          exact
          path="/companyService/serviceTag/:id"
          component={ServiceTagDetails}
        />
        <Route
          exact
          path="/export/daily-report"
          component={ExportDailyReport}
        />

        <Route
          exact
          path="/export/monthly-report"
          component={ExportMonthlyReport}
        />
        <Route
          exact
          path="/export/monthly-manulife-report"
          component={ExportMonthltManuLifeReport}
        />
      </Switch>
    </Suspense>
  );
}
