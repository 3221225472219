export const yellowBgCell = [
  "C6",
  "D6",
  "E6",
  "F6",
  "G6",
  "H6",
  "I6",
  "J6",
  "K6",
  "L6",
  "M6",
  "N6",
  "C16",
  "D16",
  "E16",
  "F16",
  "G16",
  "H16",
  "I16",
  "J16",
  "K16",
  "L16",
  "M16", "C47", "D47", "E47", "F47", "G47", "H47",
  "A48", "C48", "D48", "E48", "F48", "G48", "H48",
  "A49", "C49", "D49", "E49", "F49", "G49", "H49",
  "N16", "C31",
  "D31",
  "E31",
  "F31",
  "G31",
  "H31",
  "I31",
  "J31",
  "K31",
  "L31",
  "M31",
  "N31",
  "C32", "D32", "E32", "F32", "G32", "H32", "I32", "J32", "K32", "L32", "M32", "N32",
  "C33", "D33", "E33", "F33", "G33", "H33", "I33", "J33", "K33", "L33", "M33", "N33",
  "C34", "D34", "E34", "F34", "G34", "H34", "I34", "J34", "K34", "L34", "M34", "N34",
  "C35", "D35", "E35", "F35", "G35", "H35", "I35", "J35", "K35", "L35", "M35", "N35",
  "C36", "D36", "E36", "F36", "G36", "H36", "I36", "J36", "K36", "L36", "M36", "N36",
  "C37", "D37", "E37", "F37", "G37", "H37", "I37", "J37", "K37", "L37", "M37", "N37",
  "C38", "D38", "E38", "F38", "G38", "H38", "I38", "J38", "K38", "L38", "M38", "N38",
  "C39", "D39", "E39", "F39", "G39", "H39", "I39", "J39", "K39", "L39", "M39", "N39",
  "C40", "D40", "E40", "F40", "G40", "H40", "I40", "J40", "K40", "L40", "M40", "N40",
  "A41", "C41", "D41", "E41", "F41", "G41", "H41", "I41", "J41", "K41", "L41", "M41", "N41",
  "A42", "C42", "D42", "E42", "F42", "G42", "H42", "I42", "J42", "K42", "L42", "M42", "N42",
  "C7",
  "D7",
  "E7",
  "F7",
  "G7",
  "H7",
  "I7",
  "J7",
  "K7",
  "L7",
  "M7",
  "N7",
  "C17",
  "D17",
  "E17",
  "F17",
  "G17",
  "H17",
  "I17",
  "J17",
  "K17",
  "L17",
  "M17",
  "N17",
  "C8",
  "D8",
  "E8",
  "F8",
  "G8",
  "H8",
  "I8",
  "J8",
  "K8",
  "L8",
  "M8",
  "N8",
  "C18",
  "D18",
  "E18",
  "F18",
  "G18",
  "H18",
  "I18",
  "J18",
  "K18",
  "L18",
  "M18",
  "N18",
  "C9",
  "D9",
  "E9",
  "F9",
  "G9",
  "H9",
  "I9",
  "J9",
  "K9",
  "L9",
  "M9",
  "N9",
  "C19",
  "D19",
  "E19",
  "F19",
  "G19",
  "H19",
  "I19",
  "J19",
  "K19",
  "L19",
  "M19",
  "N19",
  "C20",
  "D20",
  "E20",
  "F20",
  "G20",
  "H20",
  "I20",
  "J20",
  "K20",
  "L20",
  "M20",
  "N20",
  "C21",
  "D21",
  "E21",
  "F21",
  "G21",
  "H21",
  "I21",
  "J21",
  "K21",
  "L21",
  "M21",
  "N21",
  "C22",
  "D22",
  "E22",
  "F22",
  "G22",
  "H22",
  "I22",
  "J22",
  "K22",
  "L22",
  "M22",
  "N22",
  "C23",
  "D23",
  "E23",
  "F23",
  "G23",
  "H23",
  "I23",
  "J23",
  "K23",
  "L23",
  "M23",
  "N23",
  "C24",
  "D24",
  "E24",
  "F24",
  "G24",
  "H24",
  "I24",
  "J24",
  "K24",
  "L24",
  "M24",
  "N24",
  "C25",
  "D25",
  "E25",
  "F25",
  "G25",
  "H25",
  "I25",
  "J25",
  "K25",
  "L25",
  "M25",
  "N25",
  "A26",
  "B26",
  "C26",
  "D26",
  "E26",
  "F26",
  "G26",
  "H26",
  "I26",
  "J26",
  "K26",
  "L26",
  "M26",
  "N26",
  "A27",
  "B27",
  "C27",
  "D27",
  "E27",
  "F27",
  "G27",
  "H27",
  "I27",
  "J27",
  "K27",
  "L27",
  "M27",
  "N27",
  "A10",
  "B10",
  "C10",
  "D10",
  "E10",
  "F10",
  "G10",
  "H10",
  "I10",
  "J10",
  "K10",
  "L10",
  "M10",
  "N10",
  "A11",
  "B11",
  "C11",
  "D11",
  "E11",
  "F11",
  "G11",
  "H11",
  "I11",
  "J11",
  "K11",
  "L11",
  "M11",
  "N11",
];

export const lightPurpleCell = ["B7", "B8", "B9"];

export const lightGreenCell = [
  "B17",
  "B18",
  "B19",
  "B20",
  "B21",
  "B22",
  "B23",
  "B24",
  "B25",
];

export const lightBlueCell = [
  "B32", "B33", "B34", "B35", "B36", "B37", "B38", "B39", "B40",
];

export const borderRightMediumCell = ["B8"];

export const borderRightThinCell = [];

export const borderTopBottomMediumCell = [
  "A6",
  "A16",
  "A31",
  "C6",
  "D6",
  "E6",
  "F6",
  "G6",
  "H6",
  "I6",
  "K6",
  "L6",
  "M6",
  "N6",
  "C16",
  "D16",
  "E16",
  "F16",
  "G16",
  "H16",
  "I16",
  "K16",
  "L16",
  "M16",
  "N16",
  "C31",
  "D31",
  "E31",
  "F31",
  "G31",
  "H31",
  "I31",
  "K31",
  "L31",
  "M31",
  "N31",
  "A7",
  "D7",
  "F7",
  "G7",
  "H7",
  "I7",
  "K7",
  "L7",
  "M7",
  "N7", "A32",
  "D32",
  "F32",
  "G32",
  "H32",
  "I32",
  "K32",
  "L32",
  "M32",
  "N32",
  "A17",
  "D17",
  "F17",
  "G17",
  "H17",
  "I17",
  "K17",
  "L17",
  "L32",
  "M17",
  "N17",
];

export const borderTopRightBottomMediumCell = [
  "B6", "B31", "A47", "D47", "E47", "G47",
  "B16",
  "J6", "J31",
  "J16",
  "L6", "L16", "L31",
  "N6", "N31",
  "N16",
  "A7",
  "B7",
  "J7",
  "L7",
  "N7",
  "D7",
  "G7",
  "A17", "A32",
  "B17", "B32",
  "B32",
  "J17", "J32",
  "L17", "L32",
  "N17", "N32",
  "D17", "D32",
  "G17", "G32",
];

export const borderRightThingBottomMediumCell = [
  "C7",
  "E7",
  "E17", "E32",
  "C17",
  "C32",
  "C9",
  "C25", "C40",
  "C11",
  "C27", "C42", "C49",
  "F9", "F25", "F40",
  "F11", "F27", "F42",
  "I9", "I25", "I40",
  "I11", "I27", "I42"
];

export const borderRightMediumBottomThinCell = [
  "C7",
  "E7",
  "E17",
  "A8",
  "B8",
  "A18",
  "B18",
  "A19",
  "B19",
  "A20",
  "B20",
  "A21",
  "B21",
  "A22",
  "B22",
  "A23",
  "B23",
  "A24",
  "B24", "A33",
  "B33",
  "A34",
  "B34",
  "A35",
  "B35",
  "A36",
  "B36",
  "A37",
  "B37",
  "A38",
  "B38",
  "A39",
  "B39",
  "A10",
  "A26", "A41", "A48",
  "D8",
  "D10",
  "D26", "D41", "D48",
  "D18",
  "D19",
  "D20",
  "D21",
  "D22",
  "D23",
  "D24", "D33",
  "D34",
  "D35",
  "D36",
  "D37",
  "D38",
  "D39",
  "G8", "G18", "G19", "G20", "G21", "G22", "G23", "G24", "G33", "G34", "G35", "G36", "G37", "G38", "G39",
  "G10", "G26", "G41", "F48", "H48",
  "J8", "J18", "J19", "J20", "J21", "J22", "J23", "J24", "J33", "J34", "J35", "J36", "J37", "J38", "J39",
  "J10", "J26", "J41",
  "L8", "L18", "L19", "L20", "L21", "L22", "L23", "L24", "L33", "L34", "L35", "L36", "L37", "L38", "L39",
  "L10", "L26", "L41",
  "N8", "N18", "N19", "N20", "N21", "N22", "N23", "N24", "N33", "N34", "N35", "N36", "N37", "N38", "N39",
  "N10", "N26", "N41",
];

export const borderBottomMediumCell = [
  "E9",
  "E25", "E40",
  "E11", "E27", "E42", "E49", "G49",
  "H9", "H25", "H40",
  "H11", "H27", "H42",
  "K9", "K25", "K40",
  "K11", "K27", "K42",
  "M9", "M25", "M40",
  "M11", "M27", "M42",
];

export const borderBottomThinCell = [
  "E8",
  "E18", "E19", "E20", "E21", "E22", "E23", "E24",
  "E33", "E34", "E35", "E36", "E37", "E38", "E39",
  "E10", "E26", "E41", "E48", "G48",
  "H8", "H18", "H19", "H20", "H21", "H22", "H23", "H24", "H33", "H34", "H35", "H36", "H37", "H38", "H39",
  "H10", "H26", "H41",
  "K8", "K18", "K19", "K20", "K21", "K22", "K23", "K24", "K33", "K34", "K35", "K36", "K37", "K38", "K39",
  "K10", "K26", "K41",
  "M8", "M18", "M19", "M20", "M21", "M22", "M23", "M24", "M33", "M34", "M35", "M36", "M37", "M38", "M39",
  "M10", "M26", "M41",
];

export const borderBottomRightMediumCell = [
  "A9",
  "B9",
  "A25", "A40",
  "B25", "B40",
  "D9",
  "D25", "D40",
  "A11",
  "A27", "A42", "A49",
  "D11",
  "D27", "D42", "D49",
  "G9", "G25", "G40",
  "G11", "G27", "G42", "F49", "H49",
  "J9", "J25", "J40",
  "J11", "J27", "J42",
  "L9", "L25", "L40",
  "L11", "L27", "L42",
  "N9", "N25", "N40",
  "N11", "N27", "N42"
];

export const borderBottomRightThin = [
  "C8",
  "C10",
  "C26", "C41", "C48",
  "C18",
  "C19",
  "C20",
  "C21",
  "C22",
  "C23",
  "C24",
  "C33",
  "C34",
  "C35",
  "C36",
  "C37",
  "C38",
  "C39",
  "F8",
  "F18", "F19", "F20", "F21", "F22", "F23", "F24",
  "F33", "F34", "F35", "F36", "F37", "F38", "F39",
  "I8", "I18", "I19", "I20", "I21", "I22", "I23", "I24", "I33", "I34", "I35", "I36", "I37", "I38", "I39",
  "F10", "F26", "F41",
  "I10", "I26", "I41"
];

export const alignmentCells = ["A7", "B7", "E7", "C6", "K6", "M6", "C7", "D7", "G7", "H7", "J7", "K7", "L7", "M7", "N7"]