import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { useFormik } from "formik";
import * as Yup from "yup";
import SearchSelect from "react-select";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
import moment from "moment";
interface Values {
  site: string;
  date: string;
  fromTime: string;
  toTime: string;
  status: string;
}

const statusOptions: any = [
  { label: "NORMAL", value: "NORMAL" },
  { label: "DELAY", value: "DELAY" },
  { label: "CANCEL", value: "CANCEL" },
];

const ReachedValue = () => {
  const [siteOptions, setSiteOptions] = useState<any>([]);

  const saveReachThresholdData = async (values) => {
    try {
      const body = {
        site: values.site,
        status: values.status,
        date: values.date,
        fromTime: values.fromTime,
        toTime: values.toTime,
      };

      const { data }: any = await api({
        url: `/schedule/cancel`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getSiteList = async () => {
    try {
      const { data }: any = await api({
        url: `/site/list?isActive=true&sort={%22name%22:%221%22}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        if (data?.result?.data?.siteData?.length) {
          const entities = data.result.data.siteData.map((entity: any) => {
            return {
              label: entity.name,
              value: entity._id,
            };
          });
          setSiteOptions([{ label: "All", value: "all" }, ...entities]);
        }
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      site: "all",
      date: moment().format("YYYY-MM-DD"),
      fromTime: "",
      toTime: "",
      status: "CANCEL",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("From Time is required"),
      fromTime: Yup.string().required("From Time is required"),
      toTime: Yup.string()
        .required("To Time is required")
        .test("endTest", "Invalid Time", function(value) {
          if (!value)
            return this.createError({
              message: "To time is required",
            });

          if (
            moment(this.parent.fromTime, "HH:mm").isSameOrAfter(
              moment(this.parent.toTime, "HH:mm")
            )
          ) {
            return this.createError({
              message: "To time must be after From time",
            });
          }
          return true;
        }),
    }),
    onSubmit: (values: Values) => {
      saveReachThresholdData(values);
    },
  });

  useEffect(() => {
    getSiteList();
  }, []);

  const siteValues = formik.values.site.split(",");
  return (
    <div>
      <Card>
        <CardHeader title={"Cancel Schedule"}></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-6 col-sm-2 mb-4 mb-sm-0">
              <div>Site</div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <SearchSelect
                  isMulti={true}
                  clearValue={() => {
                    formik.setFieldValue("site", "all");
                  }}
                  onChange={(opt: any) => {
                    const optValues = opt ? opt.map((x: any) => x.value) : [];
                    if (optValues.find((z: any) => z === "all")) {
                      formik.setFieldValue("site", "all");
                    } else {
                      formik.setFieldValue("site", optValues.join(","));
                    }
                  }}
                  options={siteOptions}
                  value={siteOptions.filter((x: any) => {
                    return siteValues.indexOf(x.value) !== -1;
                  })}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6 col-sm-2 mb-4 mb-sm-0">
              <div>Date</div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  id="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6 col-sm-2">
              <div>Duration</div>
            </div>
            <div className="col-4 d-flex justify-content-between">
              <div className="col-5 p-0">
                <input
                  type="time"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="fromTime"
                  id="fromTime"
                  value={formik.values.fromTime}
                />
                {formik.touched.fromTime && formik.errors.fromTime ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.fromTime}
                  </div>
                ) : null}
              </div>
              <div className="col-2 p-0 text-center d-flex my-2 justify-content-center">
                To
              </div>
              <div className="col-5 p-0">
                <input
                  type="time"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="toTime"
                  id="toTime"
                  value={formik.values.toTime}
                />
                {formik.touched.toTime && formik.errors.toTime ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.toTime}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6 col-sm-2 mb-4 mb-sm-0">
              <div>Status</div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <SearchSelect
                  onChange={(opt: any) => {
                    formik.setFieldValue("status", opt?.value || "");
                  }}
                  options={statusOptions}
                  value={statusOptions.find(
                    (x: any) => x.value === formik.values.status
                  )}
                />
              </div>
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ReachedValue;
