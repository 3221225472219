import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../helpers";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../partials/controls";

import { Link, useHistory } from "react-router-dom";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { sortString } from "../../../common/table/sorter";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import moment from "moment";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Datetime from 'react-datetime';

const DriverNews: FC = () => {
  const history = useHistory();
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(0);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("true");
  const [sortingType, setSortingType] = useState<any>("");
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [siteData, setSiteData] = useState<any>([]);
  const [selectedDateFormat, setSelectedDateFormat] = useState<string>("");
  const [customDatesList, setCustomDatesList] = useState<any>();
  const [expired, setExpired] = useState<string>("");
  const today = moment();

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isQuery = query !== "" ? `&search=${query}` : "";
    const isType = type !== "" ? `&isActive=${type}` : "";
    const isSortColumn = sortingType !== "" ? `&${sortingType}` : "";
    const isCreatedDate = selectedDateFormat !== "Invalid date" && selectedDateFormat !== "" ? `&createdAt=${selectedDateFormat}` : "";
    const isSite = selectedSite !== "" ? `&siteId=${selectedSite}` : "";
    const isExpired = expired !== "" ? `&expired=${expired}` : "";
    try {
      const { data }: any = await api({
        url: `/news/admin/list?page=${page}&size=${sizePerPage}${isQuery}${isType}${isSortColumn}${isExpired}${isCreatedDate}${isSite}&userType=DRIVER`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.newsData.map(
          (entity: any, ind: number) => {
            return {
              ...entity,
              id: ++ind + (page - 1) * sizePerPage,
              deadline: moment(entity.deadline).format("YYYY/MM/DD"),
              createdAt: moment(entity.createdAt).format("YYYY/MM/DD"),
              isActive: entity.isActive ? "ACTIVE" : "INACTIVE",
              type: entity.type === "HIGHLIGHTED" ? true : false,
              siteDetails: entity.siteDetails ? entity.siteDetails.name : "",
              siteId:
                entity.siteId && entity.siteId.name ? entity.siteId.name : "",
            };
          }
        );
        setTotalData(data.result.data.count);
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingType]);

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "deadline",
      text: "Deadline",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "siteDetails",
      text: "Site",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "type",
      text: "Highlighted",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "sendAsPush",
      text: "Send as Pushed",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: (data: any) => history.push(`/drivernews/update/${data._id}`),
  };

  const getNewsDateList = async () => {
    try {
      const { data }: any = await api({
        url: `/news/dates/list?userType=DRIVER`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const formatDateList = data.result.data.map(date => moment(date).format("YYYY-MM-DD"));
        setCustomDatesList(formatDateList);
      }

    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  }

  const getSiteDetails = async () => {
    try {
      const { data }: any = await api({
        url: "/site/list?isActive=true",
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const sites = data.result.data.siteData.map(
          (site: any) => {
            return {
              id: site._id,
              name: site.name,
            };
          }
        );

        setSiteData(sites);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getSiteDetails()
    getNewsDateList()
  }, [])

  const handleDateChange = (dateValue) => {
    setSelectedDateFormat(moment(dateValue).format("YYYY-MM-DD"));
  }

  const disableCustomDt = current => {
    if (customDatesList && customDatesList.length)
      return customDatesList.includes(current.format('YYYY-MM-DD')) && current.isBefore(today);
  }

  return (
    <Card>
      <CardHeader title="Driver News">
        <CardHeaderToolbar>
          <Link className="btn btn-primary" to="/drivernews/add">
            Add
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex flex-column flex-sm-row flex-wrap">
                <SizePerPage
                  paginationProps={paginationProps}
                  handleSizePerPage={handleSizePerPage}
                  entities={entities}
                />
                <FilterQuery query={query} setQuery={setQuery} title="Search" />
                <StatusFilter
                  status={type}
                  setStatus={setType}
                  options={[
                    { label: "All", value: "" },
                    { label: "Active", value: "true" },
                    { label: "Inactive", value: "false" },
                  ]}
                  title="Type"
                />

                <div className="mt-4">
                  <div className="ml-sm-1 d-flex align-items-center mt-4 mt-sm-0 mr-5">
                    <span className="mr-3 ml-2 ws-nowrap text-capitalize">Site</span>
                    <div
                      className={`d-flex align-items-center `}
                      style={{ position: "relative", minWidth: "8rem" }}
                    >
                      <select
                        className="form-control rounded"
                        name="Status"
                        onChange={(e) => {
                          setSelectedSite(e.target.value);
                        }}
                        style={{ paddingRight: "3rem" }}
                      >
                        <option value="">All</option>
                        {siteData.map((option: any, ind: number) => (
                          <option key={ind} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <ExpandMoreRoundedIcon style={{ position: "absolute", right: "1rem" }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row flex-wrap">
                <div className="px-0 mt-4">
                  <div className="d-flex align-items-center mt-4 mt-sm-0 mr-5">
                    <label className="mr-3 text-capitalize ws-nowrap">Created At</label>
                    <Datetime
                      value=""
                      timeFormat={false}
                      dateFormat={"DD-MM-YYYY"}
                      onChange={handleDateChange}
                      isValidDate={disableCustomDt}
                      closeOnSelect={true}
                    />
                  </div>
                </div>

                <StatusFilter
                  status={expired}
                  setStatus={setExpired}
                  options={[
                    { label: "All", value: "" },
                    { label: "No", value: "NO" },
                    { label: "Yes", value: "YES" },
                  ]}
                  title="Expired"
                />
                <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    setPage(1);
                    getEntities(1, sizePerPage);
                  }}
                >
                  <span className="navigation-icon icon-white">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </button>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default DriverNews;
