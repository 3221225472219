import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
import SearchSelect from "react-select";
import moment from "moment";
import { ScheduleDailyDialog } from "./scheduleDailyModal";
import { Tab, Tabs } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

interface Params {
  siteId?: string;
}

const ScheduleDailyTemplate: React.FC = () => {
  const history = useHistory();
  const params: Params = useParams();
  const isSiteId = params?.siteId ? `?siteId=${params?.siteId}` : "";
  const [carList, setCarList] = useState<any>([]);
  const [siteEntities, setSiteEntities] = useState<any>([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [stateEvent, setStateEvent] = useState<any>({
    calendarEvents: [],
    pathCalendarEvents: [],
  });
  const [key, setKey] = useState("schedule");
  const location: any = useLocation();
  const [isOpenAddPathModal, setIsOpenAddPathModal] = useState<boolean>(false);
  const [singlePathData, setSinglePathData] = useState<any>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedDateFormat, setSelectedDateFormat] = useState<any>();
  const [customDatesList, setCustomDatesList] = useState<any>();
  const [isHistoryMode, setIsHistoryMode] = useState(false);
  const todayDateFormat = moment().format("DD-MM-YYYY");
  const today = moment();

  // FETCH SITES
  const fetchSiteEntities = async () => {
    try {
      const { data } = await api({
        url: `/site/list/?page=1&size=1000&isActive=true&sort={"name":"1"}`,
        method: "get",
      });
      const entities = data.result.data.siteData.map((x) => {
        return {
          label: x?.name,
          value: x?._id,
        };
      });

      if (!params.siteId) {
        const findManuLife = entities.find((x: any) => x.label === "ManuLife");
        setSelectedSite(findManuLife ? findManuLife?.value : "");
      } else {
        const findManuLife = entities.find(
          (x: any) => x.value === params.siteId
        );
        setSelectedSite(findManuLife ? findManuLife?.value : "");
      }

      setSiteEntities(entities);
    } catch (err) {}
  };

  useEffect(() => {
    if (params.siteId) {
      // getPathList();
      getCarList();
      getScheduleDateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.siteId, location.pathname]);

  useEffect(() => {
    setSelectedDate(today);
    fetchSiteEntities();
    setSelectedDateFormat(todayDateFormat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDateFormat) getScheduleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateFormat, params.siteId]);

  const getScheduleDetail = async () => {
    try {
      const { data }: any = await api({
        url: `/schedule/schedule/list/${params.siteId}?day=${selectedDateFormat}`, //  `/schedule/sc/list/
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const eventList: any = [];
        const pathEventList: any = [];
        let item: any = data.result.data.schedules;
        if (item) {
          for (const element of item) {
            if (element && element.location && element.location.length > 1) {
              for (const [index, locationData] of element.location.entries()) {
                if (index !== element.location.length - 1) {
                  let locationTitle = "";
                  if (locationData.location_id) {
                    if (element.location[index].auto) {
                      if (
                        !element.location[index].status ||
                        element.location[index].status === "NORMAL"
                      )
                        locationTitle = `${
                          element.location[index].location_id.code
                        } > ${element.location[index + 1].location_id.code}`;
                      else
                        locationTitle = `${
                          element.location[index].location_id.code
                        } > ${element.location[index + 1].location_id.code} (${
                          element.location[index].status
                        })`;
                    } else if (
                      !element.location[index].manual_status ||
                      element.location[index].manual_status === "NORMAL"
                    )
                      locationTitle = `${
                        element.location[index].location_id.code
                      } > ${element.location[index + 1].location_id.code}`;
                    else
                      locationTitle = `${
                        element.location[index].location_id.code
                      } > ${element.location[index + 1].location_id.code} (${
                        element.location[index].manual_status
                      })`;
                  }
                  const pathEventObject = {
                    resourceId: element.carId,
                    title: locationTitle,
                    start: locationData
                      ? moment(locationData.start, "HH:mm").toISOString()
                      : ``,
                    end: locationData
                      ? moment(locationData.end, "HH:mm").toISOString()
                      : ``,
                    backgroundColor: "#B78989",
                    borderColor: "#B78989",
                    extendedProps: {
                      backgroundColor: "#B78989",
                      borderColor: "#B78989",
                      scheduleId: element._id,
                    },
                  };
                  pathEventList.push(pathEventObject);
                }
              }
            }
            const eventObject = {
              resourceId: element.carId,
              title:
                element.title && element.status === "NORMAL"
                  ? element.title
                  : `${element.title} (${element.status})`,
              start:
                element.location && element.location.length
                  ? moment(element.location[0].start, "HH:mm").toISOString()
                  : ``,
              end:
                element.location && element.location.length
                  ? moment(
                      element.location[element.location.length - 2].end,
                      "HH:mm"
                    ).toISOString()
                  : ``,
              backgroundColor: "#B78989",
              borderColor: "#B78989",
              extendedProps: {
                backgroundColor: "#B78989",
                borderColor: "#B78989",
                scheduleId: element._id,
              },
            };
            eventList.push(eventObject);
            // pathEventList.push(eventObject);
          }

          setStateEvent((state) => {
            return {
              ...state,
              calendarEvents: eventList,
              pathCalendarEvents: pathEventList,
            };
          });
        } else {
          setStateEvent((state) => {
            return {
              ...state,
              calendarEvents: [],
              pathCalendarEvents: [],
            };
          });
        }
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getScheduleDateList = async () => {
    try {
      const { data }: any = await api({
        url: `/schedule/sc/dates/${params.siteId}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const formatDateList = data.result.data.map((date) =>
          moment(date).format("YYYY-MM-DD")
        );
        setCustomDatesList(formatDateList);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getCarList = async () => {
    try {
      const { data }: any = await api({
        url: `/car/site/${params.siteId}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const carListResponse = data.result.data.carData;
        setCarList(carListResponse);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const handleEventClick = (info) => {
    setSinglePathData(info.event);
    setIsOpenAddPathModal(true);
    setIsEditMode(true);
    setIsHistoryMode(selectedDateFormat !== todayDateFormat);
  };

  const getResources = () => {
    return carList.map((selectedCar) => {
      return { id: selectedCar._id, title: selectedCar.name };
    });
  };

  const openAddPathModal = () => {
    setIsOpenAddPathModal(true);
    setIsEditMode(false);
    setSinglePathData(undefined);
  };

  const modalSaveAndRemoveClick = () => {
    getScheduleDetail();
    setIsOpenAddPathModal(false);
  };

  const handleDateChange = (dateValue) => {
    setSelectedDate(dateValue);
    setSelectedDateFormat(moment(dateValue).format("DD-MM-YYYY"));
  };

  const disableCustomDt = (current) => {
    if (customDatesList && customDatesList.length)
      return (
        customDatesList.includes(current.format("YYYY-MM-DD")) &&
        current.isBefore(today)
      );
  };

  return (
    <div>
      <div>
        <div className="d-flex flex-wrap align-items-end justify-content-end">
          <div className="d-flex flex-wrap align-items-end justify-content-end">
            {selectedDateFormat === todayDateFormat ? (
              <button
                type="button"
                className="btn button-yellow mr-2 mx-sm-2 m-2 text-white"
                onClick={openAddPathModal}
              >
                <span className="font-weight-bold">+</span> Add Path
              </button>
            ) : (
              ""
            )}
            <Link
              className="btn btn-primary mx-sm-2 m-2"
              to={`/schedule/daily${isSiteId}`}
            >
              Back
            </Link>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-9 mt-2">
          <span className="mt-2 font-weight-bold">
            Site:{" "}
            {siteEntities?.find((x: any) => x.value === params.siteId)?.label ||
              ""}
          </span>
        </div>
        <div className="col-3 mt-2 d-flex align-items-center">
          <label className="mr-3 font-weight-bold">Date</label>
          <Datetime
            value={selectedDate}
            timeFormat={false}
            dateFormat={"DD-MM-YYYY"}
            onChange={handleDateChange}
            isValidDate={disableCustomDt}
            closeOnSelect={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="schedule" title="Schedule">
              {key === "schedule" && (
                <>
                  <FullCalendar
                    droppable={true}
                    scrollTime={moment()
                      .subtract(5, "minutes")
                      .format("HH:mm:ss")}
                    schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                    timeZone="local"
                    plugins={[resourceTimeGridPlugin, interactionPlugin]}
                    initialView="resourceTimeGridDay"
                    allDaySlot={false}
                    headerToolbar={{
                      left: "",
                      center: "",
                      right: "",
                    }}
                    eventDurationEditable={false}
                    eventOverlap={false}
                    slotDuration={"00:05:00"}
                    eventClick={(info) => handleEventClick(info)}
                    resources={getResources()}
                    events={stateEvent.calendarEvents}
                    nowIndicator={true}
                  />
                </>
              )}
            </Tab>
            <Tab eventKey="path" title="Path">
              {key === "path" && (
                <>
                  <FullCalendar
                    droppable={true}
                    scrollTime={moment()
                      .subtract(5, "minutes")
                      .format("HH:mm:ss")}
                    schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                    timeZone="local"
                    plugins={[resourceTimeGridPlugin, interactionPlugin]}
                    initialView="resourceTimeGridDay"
                    allDaySlot={false}
                    headerToolbar={{
                      left: "",
                      center: "",
                      right: "",
                    }}
                    eventDurationEditable={false}
                    slotDuration={"00:05:00"}
                    eventClick={(info) => handleEventClick(info)}
                    resources={getResources()}
                    events={stateEvent.pathCalendarEvents}
                    nowIndicator={true}
                  />
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
      {isOpenAddPathModal && (
        <ScheduleDailyDialog
          name="edit"
          isEditMode={isEditMode}
          isHistoryMode={isHistoryMode}
          show={isOpenAddPathModal}
          onHide={() => setIsOpenAddPathModal(false)}
          saveAndRemoveClick={() => modalSaveAndRemoveClick()}
          action=""
          showNormalDriverField
          showBackupDriverField
          headerText={isEditMode ? "Edit" : "Add"}
          actionText="Save"
          actionStyle="success"
          dangerActionText="Remove"
          dangerActionStyle="danger"
          siteId={params.siteId || ""}
          scheduleData={singlePathData}
          eventsData={stateEvent.calendarEvents || []}
        />
      )}
    </div>
  );
};

export default ScheduleDailyTemplate;
