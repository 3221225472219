import React, { useMemo, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import SearchSelect from "react-select";
import { AsideMenuList } from "./AsideMenuList";
import { api } from "../../../../common/api";
import { useHtmlClassService } from "../../../core/MetronicLayout";

export function AsideMenu({ disableScroll, LayoutProps }) {
  const searchParams = new URLSearchParams(document.location.search);
  const siteId = searchParams.get("siteId");
  const location = useLocation();
  const history = useHistory();
  const [siteEntities, setSiteEntities] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  // FETCH SITES
  const fetchSiteEntities = async () => {
    try {
      const { data } = await api({
        url: `/site/list/?page=1&size=1000&isActive=true&sort={"name":"1"}`,
        method: "get",
      });
      const entities = data.result.data.siteData.map((x) => {
        return {
          label: x?.name,
          value: x?._id,
        };
      });

      if (!siteId) {
        const findManuLife = entities.find((x) => x.label === "ManuLife");
        setSelectedSite(findManuLife ? findManuLife?.value : "");
      } else {
        const findManuLife = entities.find((x) => x.value === siteId);
        setSelectedSite(findManuLife ? findManuLife?.value : "");
      }
      const finalEntities = [
        ...entities,
        { label: "Add Site", value: "addSite" },
      ];
      setSiteEntities(finalEntities);
    } catch (err) { }
  };

  useEffect(() => {
    fetchSiteEntities();
    // eslint-disable-next-line
  }, []);

  //  PRE SCHEDULE
  const fetchSitePreScheduleList = async (siteID) => {
    try {
      // const { data } = await api({
      //   url: `/pre-schedule/list?isActive=true&search=&siteId=${siteID}&page=1&size=10`,
      //   method: "get",
      // });

      // if (data.serverResponse.isError) {
      // } else {
      //   const entities = data.result.data?.preScheduleData;
      //   if (entities && entities.length) {
      //     const siteName = siteEntities.filter((x) => x.value === siteID);
      //     history.push(
      //       `/pre-schedule/update/${entities[0]._id}/set/schedule?siteId=${siteID}`
      //     );
      //   } else {
      //     history.push(`/pre-schedule?siteId=${siteID}`);
      //   }
      // }
      history.push(`/pre-schedule?siteId=${siteID}`);
    } catch (err) { }
  };

  // TODAY'S TEMPLATE
  const fetchSiteTodayScheduleList = async (siteID) => {
    try {
      // const { data } = await api({
      //   url: `/site/template/today/list?isActive=true&search=&siteId=${siteID}&page=1&size=10`,
      //   method: "get",
      // });

      // if (data.serverResponse.isError) {
      // } else {
      //   const entities = data.result.data?.todaySiteList;
      //   if (entities && entities.length) {
      //     const siteName = siteEntities.filter((x) => x.value === siteID);
      //     history.push({
      //       pathname: `/schedule/daily/${entities[0]._id}/s_template`,
      //       state: { siteName: siteName?.label },
      //     });
      //   } else {
      //     history.push(`/schedule/daily?siteId=${siteID}`);
      //   }
      // }
      history.push(`/schedule/daily?siteId=${siteID}`);
    } catch (err) { }
  };

  const fetchSiteRegularScheduleList = async (siteID) => {
    try {
      // const { data } = await api({
      //   url: `/template/list?isActive=true&search=&siteId=${siteID}&page=1&size=10`,
      //   method: "get",
      // });

      // if (data.serverResponse.isError) {
      // } else {
      //   const entities = data.result.data?.templateData;
      //   if (entities && entities.length) {
      //     const siteName = siteEntities.filter((x) => x.value === siteID);
      //     history.push(
      //       `/template/update/${entities[0]._id}/set/schedule?siteId=${siteID}`
      //     );
      //   } else {
      //     history.push(`/template?siteId=${siteID}`);
      //   }
      // }
      history.push(`/template?siteId=${siteID}`);
    } catch (err) { }
  };

  useEffect(() => {
    if (selectedSite === "addSite") {
      const findManuLife = siteEntities.find((x) => x.label === "ManuLife");
      history.push("/site/add");
      setSelectedSite(findManuLife ? findManuLife?.value : "");
    } else if (selectedSite && siteId) {
      history.push(`${window.location.pathname}?siteId=${selectedSite || ""}${searchParams.get("templateId") ? `&templateId=${searchParams.get("templateId")}` : ""}${searchParams.get("preScheduleId") ? `&preScheduleId=${searchParams.get("preScheduleId")}` : ""}`);
    }
    // eslint-disable-next-line
  }, [selectedSite]);

  return (
    <>
      <div
        style={{ padding: "9px 25px", paddingBottom: 0 }}
        id="aside_menu_list"
      >
        <SearchSelect
          className="sidebar_site_list_menu"
          onChange={(opt) => {
            setSelectedSite(opt?.value || "");
            if (location.pathname.includes("/template/update/")) { // && location.pathname.includes("/set/schedule")
              fetchSiteRegularScheduleList(opt?.value)
            } else if (location.pathname.includes("/pre-schedule/update/")) { // && location.pathname.includes("/set/schedule")
              fetchSitePreScheduleList(opt?.value)
            } else if (location.pathname.includes("/schedule/daily/")) { // && location.pathname.includes("/s_template")
              fetchSiteTodayScheduleList(opt?.value)
            }
          }}
          captureMenuScroll={true}
          options={siteEntities}
          value={siteEntities.find((x) => x.value === selectedSite)}
        />
      </div>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} selectedSite={selectedSite} siteEntities={siteEntities} />
      </div>
      {/* end::Menu Container */}
    </>
  );
}
