import React, { useEffect, useMemo, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { api } from "../../../common/api";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import objectPath from "object-path";
import { useHtmlClassService } from "../../core/MetronicLayout";
import { HeaderMenu } from "./header-menu/HeaderMenu";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export function Topbar() {
  const [open, setOpen] = useState(false);
  const [googleStatus, setGoogleStatus] = useState(true)
  const [autoTollStatus, setAutotollStatus] = useState(true)

  // FETCH GOOGLE AUTOTOLL STATUS
  const fetchGoogleAutotollStatus = async () => {
    try {
      const { data } = await api({
        url: `/google-autotall/status`,
        method: "get",
      });

      if (data.serverResponse.isError) {
      } else {
        const entities = data.result.data || []
        const findGoogle = entities.find((x) => x?.name === "Google")
        const findAutoToll = entities.find((x) => x?.name === "Autotoll")
        setGoogleStatus(Boolean(findGoogle?.isActive))
        setAutotollStatus(Boolean(findAutoToll?.isActive))
      }
    } catch (error) {
      setGoogleStatus(false)
      setAutotollStatus(false)
    }
  }

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  // GREEN - #05ac5b
  // YELLOW - #f9aa01
  // RED - #d70000

  useEffect(() => {
    fetchGoogleAutotollStatus()
    // eslint-disable-next-line
  }, [])

  const textToShow = (googleStatus && !autoTollStatus) ? "Autotall Not Working" : (!googleStatus && autoTollStatus) ? "Google Not working" : "Normal"
  const colorToShow = (googleStatus && !autoTollStatus) ? "#f9aa01" : (!googleStatus && autoTollStatus) ? "#d70000" : "#05ac5b"
  return (
    <div className="topbar w-100">
      <div className="d-flex align-items-center justify-content-between w-100">
        <div>
          <HeaderMenu layoutProps={layoutProps} />
        </div>

        <div className="d-flex align-items-center">
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <HtmlTooltip
              placement="bottom-end"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpen(false)}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <div className="gauto-status-tooltip">
                  <p className="title">Not Working Issue:</p>
                  <p className="issue-text">
                    {textToShow}
                  </p>
                </div>
              }
            >
              <div
                className="d-flex align-items-center"
                onClick={() => {
                  if (googleStatus && autoTollStatus) {
                    return
                  } else { setOpen(true) }
                }}
                style={{ width: 250 }}
              >
                <div
                  className="google-toll-status-icon"
                  style={{ background: colorToShow }}
                />

                <p className="pl-3 m-0 gt-status-txt">{textToShow}</p>
              </div>
            </HtmlTooltip>
          </ClickAwayListener>
          <div>Hi, Admin</div>
        </div>
      </div>
    </div>
  );
}
