import _ from "lodash";
import moment from "moment";
import {
  borderRightMediumCell,
  borderTopBottomMediumCell,
  borderTopRightBottomMediumCell,
  lightPurpleCell,
  lightGreenCell,
  yellowBgCell,
  lightBlueCell,
  topBottomMediumRightThin,
  borderBottomMediumCell,
  borderBottomRightMediumCell,
  borderRightThingBottomMediumCell,
  borderRightMediumBottomThinCell,
  borderBottomRightThin,
  borderBottomThinCell,
} from "./summaryCell";
import { checkSummaryMoreThan28 } from "./checkCells";

const getOnBoradCount = (items: any, route: any, slot: any) => {
  let a8Total = 0;

  items
    .filter((x: any) => x.title === route && x.Time === slot)
    .map((x: any) => {
      a8Total = a8Total + x.onBoard;
    });

  return a8Total;
};

const getOffBoradCount = (items: any, route: any, slot: any) => {
  let a8Total = 0;

  items
    .filter((x: any) => x.title === route && x.Time === slot)
    .map((x: any) => {
      a8Total = a8Total + x.offBoard;
    });

  return a8Total;
};

const getRouteCount = (items: any, route: any, slot: any) => {
  let a8Total = 0;

  items
    .filter((x: any) => x.title === route && x.Time === slot)
    .map((x: any) => {
      a8Total = a8Total + x.passengers;
    });

  return a8Total;
};

const getDelayCount = (items: any, route: any, slot: any) => {
  return (
    items.filter(
      (x: any) =>
        x.title === route && x.Time === slot && x.delay && x.delay > 15
    ).length || 0
  );
};

const getOnFullCounts = (
  items: any,
  route: any,
  slot: any,
  percentage: any
) => {
  const count =
    items.filter(
      (x: any) =>
        x.title === route && x.Time === slot && x.onfullPercentage >= percentage
    ).length || 0;

  return count;
};

const getFullCounts = (items: any, route: any, slot: any, percentage: any) => {
  const count =
    items.filter(
      (x: any) =>
        x.title === route && x.Time === slot && x.fullPercentage >= percentage
    ).length || 0;

  return count;
};

const getStatusCounts = (items: any, route: any, slot: any, status: any) => {
  const count =
    items.filter(
      (x: any) => x.title === route && x.Time === slot && x.status === status
    ).length || 0;

  return count;
};

export const summarySheet = (wb: any, allItems: any, totalDay: any) => {
  const ws = wb.addWorksheet("Summary", {
    properties: {},
  });
  ws.getCell("X3").value = totalDay;
  ws.getCell("X3").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFC000" },
  };
  ws.getCell("Y3").value = "(no. of working day)";

  ws.getCell("A1").value = `Monthly Usage Report: ${moment(
    allItems[0].Date
  ).format("MMMM YYYY")}`;

  ws.getCell("A3").value = "1)  Monthly Shuttle Bus Service";
  ws.getCell("A4").value = "1.1) Bus Trip: LG > MT > MFC";

  ws.getCell("A14").value = "1.2) Bus Trip: LG > MFC > MT";
  ws.getCell("A24").value = "1.3) Bus Trip: MT > MFC";
  ws.getCell("A34").value = "1.4) Bus Trip: MFC > MT";
  ws.getCell("A44").value =
    "Total No. of Passenger of Morning Shuttle Bus Services (1.1+1.2)";
  ws.getCell("A50").value = "2) Regular Shuttle Bus Services";
  ws.getCell("A51").value = "2.1) Bus Trip: MT > MFC > LG";
  ws.getCell("A70").value = "2.2) Bus Trip: LG > MFC > MT";
  ws.getCell("A93").value = "2.3) Bus Trip: MT > MFC";
  ws.getCell("A114").value = "2.4) Bus Trip: MFC > MT";
  ws.getCell("A136").value = "2.5) Bus Trip: MT > LG";
  ws.getCell("A143").value = "2.6) Bus Trip: MFC > LG";

  ws.getCell("A151").value = "3) Evening Shuttle Bus Services";
  ws.getCell("A152").value = "3.1) Bus Trip: MT > LG";

  ws.getCell("A159").value = "3.2) Bus Trip: MFC > LG";
  ws.getCell("A166").value =
    "Total No. of Passenger of Evening Shuttle Bus Services (3.1+3.2)";
  ws.getCell("A171").value = "3.3) Bus Trip: MT > MFC > LG";

  ws.getCell("C25").value = "MT > MFC";
  ws.getCell("C35").value = "MFC > MT";
  ws.getCell("C45").value = "1st Stop";
  ws.getCell("K45").value = "2nd Stop";
  ws.getCell("C52").value = "MT > MFC";
  ws.getCell("K52").value = "MFC > LG";
  ws.getCell("C71").value = "LG > MFC";
  ws.getCell("K71").value = "MFC > MT";
  ws.getCell("C94").value = "MT > MFC";
  ws.getCell("C115").value = "MFC > MT";
  ws.getCell("C172").value = "MT > MFC";
  ws.getCell("K172").value = "MFC > LG";

  ws.getCell("C5").value = "LG > MT";
  ws.getCell("K5").value = "MT > MFC";
  ws.getCell("A6").value = "LG > MT";
  ws.getCell("B6").value = "MT > MFC";
  ws.getCell("W6").value = "Average Alighting at MT";
  ws.getCell("X6").value = "Average Boarding at MT";
  ws.getCell("W16").value = "Average Alighting at MFC";
  ws.getCell("X16").value = "Average Boarding at MFC";
  ws.getCell("W53").value = "Average Alighting at MFC";
  ws.getCell("X53").value = "Average Boarding at MFC";
  ws.getCell("W72").value = "Average Alighting at MFC";
  ws.getCell("X72").value = "Average Boarding at MFC";
  ws.getCell("W173").value = "Average Alighting at MFC";
  ws.getCell("X173").value = "Average Boarding at MFC";

  ws.getCell("A7").value = "07:45";
  ws.getCell("A8").value = "08:15";
  ws.getCell("A9").value = "08:45";
  ws.getCell("A10").value = "09:15";
  ws.getCell("B7").value = "08:05";
  ws.getCell("B8").value = "08:35";
  ws.getCell("B9").value = "09:05";
  ws.getCell("B10").value = "09:35";

  ws.getCell("A17").value = "08:00";
  ws.getCell("A18").value = "08:30";
  ws.getCell("A19").value = "09:00";
  ws.getCell("A20").value = "09:30";
  ws.getCell("B17").value = "08:20";
  ws.getCell("B18").value = "08:55";
  ws.getCell("B19").value = "09:20";
  ws.getCell("B20").value = "09:55";

  ws.getCell("A26").value = "MT>MFC";
  ws.getCell("A27").value = "08:00";
  ws.getCell("A28").value = "08:20";
  ws.getCell("A29").value = "09:20";
  ws.getCell("A30").value = "09:50";

  ws.getCell("A36").value = "MFC >MT";
  ws.getCell("A37").value = "08:35";
  ws.getCell("A38").value = "09:05";
  ws.getCell("A39").value = "09:35";

  ws.getCell("A46").value = "1st stop";
  ws.getCell("B46").value = "2nd stop";

  ws.getCell("A53").value = "MT > MFC";
  ws.getCell("B53").value = "MFC >LG";

  ws.getCell("A54").value = "10:00";
  ws.getCell("A55").value = "10:30";
  ws.getCell("A56").value = "11:00";
  ws.getCell("A57").value = "11:30";
  ws.getCell("A58").value = "12:30";
  ws.getCell("A59").value = "14:30";
  ws.getCell("A60").value = "15:00";
  ws.getCell("A61").value = "15:30";
  ws.getCell("A62").value = "16:00";
  ws.getCell("A63").value = "16:30";
  ws.getCell("A64").value = "17:00";
  ws.getCell("A65").value = "17:30";
  ws.getCell("A66").value = "18:00";

  ws.getCell("B54").value = "10:10";
  ws.getCell("B55").value = "10:40";
  ws.getCell("B56").value = "11:10";
  ws.getCell("B57").value = "11:40";
  ws.getCell("B58").value = "12:40";
  ws.getCell("B59").value = "14:40";
  ws.getCell("B60").value = "15:10";
  ws.getCell("B61").value = "15:40";
  ws.getCell("B62").value = "16:10";
  ws.getCell("B63").value = "16:40";
  ws.getCell("B64").value = "17:10";
  ws.getCell("B65").value = "17:40";
  ws.getCell("B66").value = "18:10";

  ws.getCell("A72").value = "LG > MFC";
  ws.getCell("B72").value = "MFC > MT";

  ws.getCell("A73").value = "10:15";
  ws.getCell("A74").value = "10:45";
  ws.getCell("A75").value = "11:15";
  ws.getCell("A76").value = "11:45";
  ws.getCell("A77").value = "12:15";
  ws.getCell("A78").value = "12:45";
  ws.getCell("A79").value = "";
  ws.getCell("A80").value = "13:45";
  ws.getCell("A81").value = "14:15";
  ws.getCell("A82").value = "14:45";
  ws.getCell("A83").value = "15:15";
  ws.getCell("A84").value = "15:45";
  ws.getCell("A85").value = "16:15";
  ws.getCell("A86").value = "16:45";
  ws.getCell("A87").value = "17:15";
  ws.getCell("A88").value = "17:45";
  ws.getCell("A89").value = "18:15";

  ws.getCell("B73").value = "10:35";
  ws.getCell("B74").value = "11:05";
  ws.getCell("B75").value = "11:35";
  ws.getCell("B76").value = "12:05";
  ws.getCell("B77").value = "12:35";
  ws.getCell("B78").value = "13:05";
  ws.getCell("B79").value = "13:45";
  ws.getCell("B80").value = "14:05";
  ws.getCell("B81").value = "14:35";
  ws.getCell("B82").value = "15:05";
  ws.getCell("B83").value = "15:35";
  ws.getCell("B84").value = "16:05";
  ws.getCell("B85").value = "16:35";
  ws.getCell("B86").value = "17:05";
  ws.getCell("B87").value = "17:35";
  ws.getCell("B88").value = "18:05";
  ws.getCell("B89").value = "18:35";

  ws.getCell("A95").value = "MT > MFC";

  ws.getCell("A96").value = "10:10";
  ws.getCell("A97").value = "10:40";
  ws.getCell("A98").value = "11:10";
  ws.getCell("A99").value = "11:40";
  ws.getCell("A100").value = "12:10";
  ws.getCell("A101").value = "12:40";
  ws.getCell("A102").value = "14:10";
  ws.getCell("A103").value = "14:40";
  ws.getCell("A104").value = "15:10";
  ws.getCell("A105").value = "15:40";
  ws.getCell("A106").value = "16:10";
  ws.getCell("A107").value = "16:40";
  ws.getCell("A108").value = "17:10";
  ws.getCell("A109").value = "17:40";
  ws.getCell("A110").value = "18:10";

  ws.getCell("A116").value = "MFC > MT";

  ws.getCell("A117").value = "10:00";
  ws.getCell("A118").value = "10:25";
  ws.getCell("A119").value = "10:55";
  ws.getCell("A120").value = "11:25";
  ws.getCell("A121").value = "11:55";
  ws.getCell("A122").value = "12:25";
  ws.getCell("A123").value = "12:55";
  ws.getCell("A124").value = "14:25";
  ws.getCell("A125").value = "14:55";
  ws.getCell("A126").value = "15:25";
  ws.getCell("A127").value = "15:55";
  ws.getCell("A128").value = "16:25";
  ws.getCell("A129").value = "16:55";
  ws.getCell("A130").value = "17:25";
  ws.getCell("A131").value = "17:55";
  ws.getCell("A132").value = "18:25";

  ws.getCell("A138").value = "12:00";
  ws.getCell("A139").value = "14:00";

  ws.getCell("A145").value = "12:10";
  ws.getCell("A146").value = "14:10";

  ws.getCell("A154").value = "18:30";
  ws.getCell("A155").value = "19:00";

  ws.getCell("A161").value = "18:30";
  ws.getCell("A162").value = "19:00";

  ws.getCell("A173").value = "MT > MFC";
  ws.getCell("B173").value = "MFC > LG";

  ws.getCell("A174").value = "19:30";
  ws.getCell("A175").value = "20:00";
  ws.getCell("B174").value = "19:40";
  ws.getCell("B175").value = "20:10";

  ["S5", "S15", "L25", "L35", "S45", "S52", "S71", "K94", "K115", "S172"].map(
    (sItem: any) => {
      ws.getCell(sItem).value = "Late (≥15 mins)";
    }
  );

  ["K137", "K144", "K153", "K160", "E167"].map((sItem: any) => {
    ws.getCell(sItem).value = "Late (≥15 mins)\r\nNo. of Days";
  });

  ["U5", "U15", "N25", "N35", "U45", "U52", "U71", "M94", "M115", "U172"].map(
    (sItem: any) => {
      ws.getCell(sItem).value = "Cancellation";
    }
  );

  ["M137", "M144", "M153", "M160", "G167"].map((sItem: any) => {
    ws.getCell(sItem).value = "Cancellation\r\n No.of Days";
  });

  [
    "A11",
    "A21",
    "A31",
    "A40",
    "A47",
    "A67",
    "A90",
    "A111",
    "A133",
    "A140",
    "A147",
    "A156",
    "A163",
    "A168",
    "A176",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "Daily Total No. of Passenger";
  });

  [
    "A12",
    "A22",
    "A32",
    "A41",
    "A48",
    "A68",
    "A91",
    "A112",
    "A134",
    "A141",
    "A148",
    "A157",
    "A164",
    "A169",
    "A177",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "Average per trip (Daily)";
  });

  [
    "C6",
    "K6",
    "C16",
    "K16",
    "C26",
    "C36",
    "C46",
    "K46",
    "C53",
    "K53",
    "C72",
    "K72",
    "C95",
    "C116",
    "C137",
    "C144",
    "C153",
    "C160",
    "C167",
    "C173",
    "K173",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "Total No. of Passenger (per trip)";
  });

  [
    "D6",
    "L6",
    "D16",
    "L16",
    "D26",
    "D36",
    "D46",
    "L46",
    "D53",
    "L53",
    "D72",
    "L72",
    "D95",
    "D116",
    "D137",
    "D144",
    "D153",
    "D160",
    "D167",
    "D173",
    "L173",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "Average per trip";
  });

  [
    "E6",
    "M6",
    "E16",
    "M16",
    "E26",
    "E36",
    "E53",
    "M53",
    "E72",
    "M72",
    "E95",
    "E116",
    "E137",
    "E144",
    "E153",
    "E160",
    "E173",
    "M173",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "80% Full";
  });

  [
    "G6",
    "J6",
    "O6",
    "R6",
    "G16",
    "J16",
    "O16",
    "R16",
    "G26",
    "K26",
    "G36",
    "K36",
    "G53",
    "J53",
    "O53",
    "R53",
    "G72",
    "J72",
    "O72",
    "R72",
    "G95",
    "J95",
    "G116",
    "J116",
    "G137",
    "J137",
    "G144",
    "J144",
    "G153",
    "J153",
    "G160",
    "J160",
    "G173",
    "J173",
    "O173",
    "R173",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = ">10 days";
  });

  [
    "H6",
    "P6",
    "H16",
    "P16",
    "I26",
    "I36",
    "H53",
    "P53",
    "H72",
    "P72",
    "H95",
    "H116",
    "H137",
    "H144",
    "H153",
    "H160",
    "H173",
    "P173",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "100% Full";
  });

  ws.getCell("A16").value = "LG > MFC";
  ws.getCell("B16").value = "MFC > MT";

  [
    "S6",
    "U6",
    "S16",
    "U16",
    "L26",
    "N26",
    "L36",
    "N36",
    "S46",
    "U46",
    "S53",
    "U53",
    "S72",
    "U72",
    "S173",
    "U173",
    "K95",
    "M95",
    "K116",
    "M116",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "No. of Day";
  });

  [
    "T6",
    "V6",
    "T16",
    "V16",
    "M26",
    "O26",
    "M36",
    "O36",
    "T46",
    "V46",
    "T53",
    "V53",
    "T72",
    "V72",
    "T173",
    "V173",
    "L95",
    "N95",
    "L116",
    "N116",
  ].map((sItem: any) => {
    ws.getCell(sItem).value = "%";
  });

  ws.columns = [
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 20, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
  ];

  // BOLD TEXT
  [
    1,
    3,
    4,
    14,
    24,
    34,
    44,
    50,
    51,
    70,
    93,
    114,
    136,
    143,
    151,
    152,
    159,
    166,
    171,
  ].map((citem: any) => {
    ws.mergeCells(`A${citem}:D${citem}`);
    ws.getCell(`A${citem}`).font = { bold: true, color: { argb: "00000000" } };
  });

  ws.mergeCells(`A11:B11`);
  ws.mergeCells(`A12:B12`);
  ws.mergeCells(`A21:B21`);
  ws.mergeCells(`A22:B22`);
  ws.mergeCells(`E6:F6`);
  ws.mergeCells(`H6:I6`);
  ws.mergeCells(`M6:N6`);
  ws.mergeCells(`P6:Q6`);
  ws.mergeCells(`E16:F16`);
  ws.mergeCells(`H16:I16`);
  ws.mergeCells(`M16:N16`);
  ws.mergeCells(`P16:Q16`);
  ws.mergeCells(`E26:F26`);
  ws.mergeCells(`G26:H26`);
  ws.mergeCells(`I26:J26`);
  ws.mergeCells(`E36:F36`);
  ws.mergeCells(`G36:H36`);
  ws.mergeCells(`I36:J36`);
  ws.mergeCells(`E53:F53`);
  ws.mergeCells(`H53:I53`);
  ws.mergeCells(`M53:N53`);
  ws.mergeCells(`P53:Q53`);
  ws.mergeCells(`E72:F72`);
  ws.mergeCells(`H72:I72`);
  ws.mergeCells(`M72:N72`);
  ws.mergeCells(`P72:Q72`);
  ws.mergeCells(`E95:F95`);
  ws.mergeCells(`H95:I95`);
  ws.mergeCells(`E116:F116`);
  ws.mergeCells(`H116:I116`);
  ws.mergeCells(`E137:F137`);
  ws.mergeCells(`H137:I137`);
  ws.mergeCells(`K137:L137`);
  ws.mergeCells(`M137:N137`);
  ws.mergeCells(`E144:F144`);
  ws.mergeCells(`H144:I144`);
  ws.mergeCells(`K144:L144`);
  ws.mergeCells(`M144:N144`);
  ws.mergeCells(`E153:F153`);
  ws.mergeCells(`H153:I153`);
  ws.mergeCells(`K153:L153`);
  ws.mergeCells(`M153:N153`);
  ws.mergeCells(`E160:F160`);
  ws.mergeCells(`H160:I160`);
  ws.mergeCells(`K160:L160`);
  ws.mergeCells(`M160:N160`);

  ws.mergeCells(`E167:F167`);
  ws.mergeCells(`G167:H167`);

  ws.mergeCells(`A26:B26`);
  ws.mergeCells(`A27:B27`);
  ws.mergeCells(`A28:B28`);
  ws.mergeCells(`A29:B29`);
  ws.mergeCells(`A30:B30`);
  ws.mergeCells(`A31:B31`);
  ws.mergeCells(`A32:B32`);

  ws.mergeCells(`A36:B36`);
  ws.mergeCells(`A37:B37`);
  ws.mergeCells(`A38:B38`);
  ws.mergeCells(`A39:B39`);
  ws.mergeCells(`A40:B40`);
  ws.mergeCells(`A41:B41`);
  ws.mergeCells(`A47:B47`);
  ws.mergeCells(`A48:B48`);

  ws.mergeCells("D46:J46");
  ws.mergeCells("D47:J47");
  ws.mergeCells("D48:J48");
  ws.mergeCells("L46:R46");
  ws.mergeCells("L47:R47");
  ws.mergeCells("L48:R48");

  ws.mergeCells("A67:B67");
  ws.mergeCells("A68:B68");
  ws.mergeCells("A90:B90");
  ws.mergeCells("A91:B91");

  ws.mergeCells("A95:B95");
  ws.mergeCells("A96:B96");
  ws.mergeCells("A97:B97");
  ws.mergeCells("A98:B98");
  ws.mergeCells("A99:B99");
  ws.mergeCells("A100:B100");
  ws.mergeCells("A101:B101");
  ws.mergeCells("A102:B102");
  ws.mergeCells("A103:B103");
  ws.mergeCells("A104:B104");
  ws.mergeCells("A105:B105");
  ws.mergeCells("A106:B106");
  ws.mergeCells("A107:B107");
  ws.mergeCells("A108:B108");
  ws.mergeCells("A109:B109");
  ws.mergeCells("A110:B110");
  ws.mergeCells("A111:B111");
  ws.mergeCells("A112:B112");

  ws.mergeCells("A116:B116");
  ws.mergeCells("A117:B117");
  ws.mergeCells("A118:B118");
  ws.mergeCells("A119:B119");
  ws.mergeCells("A120:B120");
  ws.mergeCells("A121:B121");
  ws.mergeCells("A122:B122");
  ws.mergeCells("A123:B123");
  ws.mergeCells("A124:B124");
  ws.mergeCells("A125:B125");
  ws.mergeCells("A126:B126");
  ws.mergeCells("A127:B127");
  ws.mergeCells("A128:B128");
  ws.mergeCells("A129:B129");
  ws.mergeCells("A130:B130");
  ws.mergeCells("A131:B131");
  ws.mergeCells("A132:B132");
  ws.mergeCells("A133:B133");
  ws.mergeCells("A134:B134");
  ws.mergeCells("A137:B137");
  ws.mergeCells("A138:B138");
  ws.mergeCells("A139:B139");
  ws.mergeCells("A140:B140");
  ws.mergeCells("A141:B141");

  ws.mergeCells("A144:B144");
  ws.mergeCells("A145:B145");
  ws.mergeCells("A146:B146");
  ws.mergeCells("A147:B147");
  ws.mergeCells("A148:B148");

  ws.mergeCells("A153:B153");
  ws.mergeCells("A154:B154");
  ws.mergeCells("A155:B155");
  ws.mergeCells("A156:B156");
  ws.mergeCells("A157:B157");

  ws.mergeCells("A160:B160");
  ws.mergeCells("A161:B161");
  ws.mergeCells("A162:B162");
  ws.mergeCells("A163:B163");
  ws.mergeCells("A164:B164");

  ws.mergeCells("A167:B167");
  ws.mergeCells("A168:B168");
  ws.mergeCells("A169:B169");

  ws.mergeCells("E168:F168");
  ws.mergeCells("E169:F169");
  ws.mergeCells("G168:H168");
  ws.mergeCells("G169:H169");

  ws.mergeCells("A176:B176");
  ws.mergeCells("A177:B177");

  ws.mergeCells(`E173:F173`);
  ws.mergeCells(`H173:I 173`);
  ws.mergeCells(`M173:N173`);
  ws.mergeCells(`P173:Q173`);

  // CALCULATIONS
  ws.getCell("C7").value = getOnBoradCount(allItems, "LG > MT", "07:45");
  ws.getCell("C8").value = getOnBoradCount(allItems, "LG > MT", "08:15");
  ws.getCell("C9").value = getOnBoradCount(allItems, "LG > MT", "08:45");
  ws.getCell("C10").value = getOnBoradCount(allItems, "LG > MT", "09:15");
  ws.getCell("C11").value =
    ws.getCell("C7").value +
    ws.getCell("C8").value +
    ws.getCell("C9").value +
    ws.getCell("C10").value;
  ws.getCell("C12").value = Math.floor(ws.getCell("C11").value / 4) || 0;

  ws.getCell("D7").value =
    Math.floor(ws.getCell("C7").value / ws.getCell("X3").value) || 0;
  ws.getCell("D8").value =
    Math.floor(ws.getCell("C8").value / ws.getCell("X3").value) || 0;
  ws.getCell("D9").value =
    Math.floor(ws.getCell("C9").value / ws.getCell("X3").value) || 0;
  ws.getCell("D10").value =
    Math.floor(ws.getCell("C10").value / ws.getCell("X3").value) || 0;
  ws.getCell("D11").value =
    ws.getCell("D7").value +
    ws.getCell("D8").value +
    ws.getCell("D9").value +
    ws.getCell("D10").value;
  ws.getCell("D12").value = Math.floor(ws.getCell("D11").value / 4) || 0;

  ws.getCell("K7").value = getRouteCount(allItems, "MT > MFC", "08:05");
  ws.getCell("K8").value = getRouteCount(allItems, "MT > MFC", "08:35");
  ws.getCell("K9").value = getRouteCount(allItems, "MT > MFC", "09:05");
  ws.getCell("K10").value = getRouteCount(allItems, "MT > MFC", "09:35");
  ws.getCell("K11").value =
    ws.getCell("K7").value +
    ws.getCell("K8").value +
    ws.getCell("K9").value +
    ws.getCell("K10").value;
  ws.getCell("K12").value = Math.floor(ws.getCell("K11").value / 4) || 0;

  ws.getCell("L7").value =
    Math.floor(ws.getCell("K7").value / ws.getCell("X3").value) || 0;
  ws.getCell("L8").value =
    Math.floor(ws.getCell("K8").value / ws.getCell("X3").value) || 0;
  ws.getCell("L9").value =
    Math.floor(ws.getCell("K9").value / ws.getCell("X3").value) || 0;
  ws.getCell("L10").value =
    Math.floor(ws.getCell("K10").value / ws.getCell("X3").value) || 0;
  ws.getCell("L11").value =
    ws.getCell("L7").value +
    ws.getCell("L8").value +
    ws.getCell("L9").value +
    ws.getCell("L10").value;
  ws.getCell("L12").value = Math.floor(ws.getCell("L11").value / 4) || 0;

  ws.getCell("E7").value = getOnFullCounts(allItems, "LG > MT", "07:45", 80);
  ws.getCell("F7").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "07:45", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("E8").value = getOnFullCounts(allItems, "LG > MT", "08:15", 80);
  ws.getCell("F8").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "08:15", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("E9").value = getOnFullCounts(allItems, "LG > MT", "08:45", 80);
  ws.getCell("F9").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "08:45", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("E10").value = getOnFullCounts(allItems, "LG > MT", "09:15", 80);
  ws.getCell("F10").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "09:15", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("H7").value = getOnFullCounts(allItems, "LG > MT", "07:45", 100);
  ws.getCell("I7").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "07:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H8").value = getOnFullCounts(allItems, "LG > MT", "08:15", 100);
  ws.getCell("I8").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "08:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H9").value = getOnFullCounts(allItems, "LG > MT", "08:45", 100);
  ws.getCell("I9").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "08:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H10").value = getOnFullCounts(allItems, "LG > MT", "09:15", 100);
  ws.getCell("I10").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MT", "09:15", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("M7").value = getFullCounts(allItems, "MT > MFC", "08:05", 80);
  ws.getCell("N7").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "08:05", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("M8").value = getFullCounts(allItems, "MT > MFC", "08:35", 80);
  ws.getCell("N8").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "08:35", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("M9").value = getFullCounts(allItems, "MT > MFC", "09:05", 80);
  ws.getCell("N9").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "09:05", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("M10").value = getFullCounts(allItems, "MT > MFC", "09:35", 80);
  ws.getCell("N10").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "09:35", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("P7").value = getFullCounts(allItems, "MT > MFC", "08:05", 100);
  ws.getCell("Q7").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "08:05", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P8").value = getFullCounts(allItems, "MT > MFC", "08:35", 100);
  ws.getCell("Q8").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "08:35", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P9").value = getFullCounts(allItems, "MT > MFC", "09:05", 100);
  ws.getCell("Q9").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "09:05", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P10").value = getFullCounts(allItems, "MT > MFC", "09:35", 100);
  ws.getCell("Q10").value =
    Math.floor(
      (getFullCounts(allItems, "MT > MFC", "09:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("G7").value = ws.getCell("E7").value > 10 ? 1 : 0;
  ws.getCell("G8").value = ws.getCell("E8").value > 10 ? 1 : 0;
  ws.getCell("G9").value = ws.getCell("E9").value > 10 ? 1 : 0;
  ws.getCell("G10").value = ws.getCell("E10").value > 10 ? 1 : 0;
  ws.getCell("G11").value =
    ws.getCell("G7").value +
    ws.getCell("G8").value +
    ws.getCell("G9").value +
    ws.getCell("G10").value;

  ws.getCell("J7").value = ws.getCell("H7").value > 10 ? 1 : 0;
  ws.getCell("J8").value = ws.getCell("H8").value > 10 ? 1 : 0;
  ws.getCell("J9").value = ws.getCell("H9").value > 10 ? 1 : 0;
  ws.getCell("J10").value = ws.getCell("H10").value > 10 ? 1 : 0;
  ws.getCell("J11").value =
    ws.getCell("J7").value +
    ws.getCell("J8").value +
    ws.getCell("J9").value +
    ws.getCell("J10").value;

  ws.getCell("O7").value = ws.getCell("M7").value > 10 ? 1 : 0;
  ws.getCell("O8").value = ws.getCell("M8").value > 10 ? 1 : 0;
  ws.getCell("O9").value = ws.getCell("M9").value > 10 ? 1 : 0;
  ws.getCell("O10").value = ws.getCell("M10").value > 10 ? 1 : 0;
  ws.getCell("O11").value =
    ws.getCell("O7").value +
    ws.getCell("O8").value +
    ws.getCell("O9").value +
    ws.getCell("O10").value;

  ws.getCell("R7").value = ws.getCell("P7").value > 10 ? 1 : 0;
  ws.getCell("R8").value = ws.getCell("P8").value > 10 ? 1 : 0;
  ws.getCell("R9").value = ws.getCell("P9").value > 10 ? 1 : 0;
  ws.getCell("R10").value = ws.getCell("P10").value > 10 ? 1 : 0;
  ws.getCell("R11").value =
    ws.getCell("R7").value +
    ws.getCell("R8").value +
    ws.getCell("R9").value +
    ws.getCell("R10").value;

  ws.getCell("S7").value =
    (getDelayCount(allItems, "LG > MT", "07:45") || 0) +
      (getDelayCount(allItems, "MT > MFC", "08:05") || 0) || 0;
  ws.getCell("T7").value =
    Math.floor((ws.getCell("S7").value / totalDay) * 100) + "%";

  ws.getCell("S8").value =
    (getDelayCount(allItems, "LG > MT", "08:15") || 0) +
      (getDelayCount(allItems, "MT > MFC", "08:35") || 0) || 0;
  ws.getCell("T8").value =
    Math.floor((ws.getCell("S8").value / totalDay) * 100) + "%";

  ws.getCell("S9").value =
    (getDelayCount(allItems, "LG > MT", "08:45") || 0) +
      (getDelayCount(allItems, "MT > MFC", "09:05") || 0) || 0;
  ws.getCell("T9").value =
    Math.floor((ws.getCell("S9").value / totalDay) * 100) + "%";

  ws.getCell("S10").value =
    (getDelayCount(allItems, "LG > MT", "09:15") || 0) +
      (getDelayCount(allItems, "MT > MFC", "09:35") || 0) || 0;
  ws.getCell("T10").value =
    Math.floor((ws.getCell("S10").value / totalDay) * 100) + "%";

  ws.getCell("S11").value =
    ws.getCell("S7").value +
    ws.getCell("S8").value +
    ws.getCell("S9").value +
    ws.getCell("S10").value;

  ws.getCell("U7").value =
    (getStatusCounts(allItems, "LG > MT", "07:45", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MT > MFC", "08:05", "CANCEL") || 0);
  ws.getCell("V7").value =
    Math.floor((ws.getCell("U7").value / totalDay) * 100) + "%";

  ws.getCell("U8").value =
    (getStatusCounts(allItems, "LG > MT", "08:15", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MT > MFC", "08:35", "CANCEL") || 0);
  ws.getCell("V8").value =
    Math.floor((ws.getCell("U8").value / totalDay) * 100) + "%";

  ws.getCell("U9").value =
    (getStatusCounts(allItems, "LG > MT", "08:45", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MT > MFC", "09:05", "CANCEL") || 0);
  ws.getCell("V9").value =
    Math.floor((ws.getCell("U9").value / totalDay) * 100) + "%";

  ws.getCell("U10").value =
    (getStatusCounts(allItems, "LG > MT", "09:15", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MT > MFC", "09:35", "CANCEL") || 0);
  ws.getCell("V10").value =
    Math.floor((ws.getCell("U10").value / totalDay) * 100) + "%";

  ws.getCell("U11").value =
    ws.getCell("U7").value +
    ws.getCell("U8").value +
    ws.getCell("U9").value +
    ws.getCell("U10").value;

  ws.getCell("C15").value = "LG > MFC";
  ws.getCell("K15").value = "MFC > MT";

  ws.getCell("C17").value = getOnBoradCount(allItems, "LG > MFC", "08:00");
  ws.getCell("C18").value = getOnBoradCount(allItems, "LG > MFC", "08:30");
  ws.getCell("C19").value = getOnBoradCount(allItems, "LG > MFC", "09:00");
  ws.getCell("C20").value = getOnBoradCount(allItems, "LG > MFC", "09:30");
  ws.getCell("C21").value =
    ws.getCell("C17").value +
    ws.getCell("C18").value +
    ws.getCell("C19").value +
    ws.getCell("C20").value;
  ws.getCell("C22").value = Math.floor(ws.getCell("C21").value / 4) || 0;

  ws.getCell("D17").value =
    Math.floor(ws.getCell("C17").value / ws.getCell("X3").value) || 0;
  ws.getCell("D18").value =
    Math.floor(ws.getCell("C18").value / ws.getCell("X3").value) || 0;
  ws.getCell("D19").value =
    Math.floor(ws.getCell("C19").value / ws.getCell("X3").value) || 0;
  ws.getCell("D20").value =
    Math.floor(ws.getCell("C20").value / ws.getCell("X3").value) || 0;
  ws.getCell("D21").value =
    ws.getCell("D17").value +
    ws.getCell("D18").value +
    ws.getCell("D19").value +
    ws.getCell("D20").value;
  ws.getCell("D22").value = Math.floor(ws.getCell("D21").value / 4) || 0;

  ws.getCell("K17").value = getRouteCount(allItems, "MFC > MT", "08:20");
  ws.getCell("K18").value = getRouteCount(allItems, "MFC > MT", "08:55");
  ws.getCell("K19").value = getRouteCount(allItems, "MFC > MT", "09:20");
  ws.getCell("K20").value = getRouteCount(allItems, "MFC > MT", "09:55");
  ws.getCell("K21").value =
    ws.getCell("K17").value +
    ws.getCell("K18").value +
    ws.getCell("K19").value +
    ws.getCell("K20").value;
  ws.getCell("K22").value = Math.floor(ws.getCell("K21").value / 4) || 0;

  ws.getCell("L17").value =
    Math.floor(ws.getCell("K17").value / ws.getCell("X3").value) || 0;
  ws.getCell("L18").value =
    Math.floor(ws.getCell("K18").value / ws.getCell("X3").value) || 0;
  ws.getCell("L19").value =
    Math.floor(ws.getCell("K19").value / ws.getCell("X3").value) || 0;
  ws.getCell("L20").value =
    Math.floor(ws.getCell("K20").value / ws.getCell("X3").value) || 0;
  ws.getCell("L21").value =
    ws.getCell("L17").value +
    ws.getCell("L18").value +
    ws.getCell("L19").value +
    ws.getCell("L20").value;
  ws.getCell("L22").value = Math.floor(ws.getCell("L21").value / 4) || 0;

  ws.getCell("E17").value = getOnFullCounts(allItems, "LG > MFC", "08:00", 80);
  ws.getCell("F17").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "08:00", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("E18").value = getOnFullCounts(allItems, "LG > MFC", "08:30", 80);
  ws.getCell("F18").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "08:30", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("E19").value = getOnFullCounts(allItems, "LG > MFC", "09:00", 80);
  ws.getCell("F19").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "09:00", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("E20").value = getOnFullCounts(allItems, "LG > MFC", "09:30", 80);
  ws.getCell("F20").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "09:30", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("H17").value = getOnFullCounts(allItems, "LG > MFC", "08:00", 100);
  ws.getCell("I17").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "08:00", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H18").value = getOnFullCounts(allItems, "LG > MFC", "08:30", 100);
  ws.getCell("I18").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "08:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H19").value = getOnFullCounts(allItems, "LG > MFC", "09:00", 100);
  ws.getCell("I19").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "09:00", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H20").value = getOnFullCounts(allItems, "LG > MFC", "09:30", 100);
  ws.getCell("I20").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "09:30", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("M17").value = getFullCounts(allItems, "MFC > MT", "08:20", 80);
  ws.getCell("N17").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "08:20", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("M18").value = getFullCounts(allItems, "MFC > MT", "08:55", 80);
  ws.getCell("N18").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "08:55", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("M19").value = getFullCounts(allItems, "MFC > MT", "09:20", 80);
  ws.getCell("N19").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "09:20", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("M20").value = getFullCounts(allItems, "MFC > MT", "09:55", 80);
  ws.getCell("N20").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "09:55", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("P17").value = getFullCounts(allItems, "MFC > MT", "08:20", 100);
  ws.getCell("Q17").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "08:20", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P18").value = getFullCounts(allItems, "MFC > MT", "08:55", 100);
  ws.getCell("Q18").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "08:55", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P19").value = getFullCounts(allItems, "MFC > MT", "09:20", 100);
  ws.getCell("Q19").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "09:20", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P20").value = getFullCounts(allItems, "MFC > MT", "09:55", 100);
  ws.getCell("Q20").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "09:55", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("G17").value = ws.getCell("E17").value > 10 ? 1 : 0;
  ws.getCell("G18").value = ws.getCell("E18").value > 10 ? 1 : 0;
  ws.getCell("G19").value = ws.getCell("E19").value > 10 ? 1 : 0;
  ws.getCell("G20").value = ws.getCell("E20").value > 10 ? 1 : 0;
  ws.getCell("G21").value =
    ws.getCell("G17").value +
    ws.getCell("G18").value +
    ws.getCell("G19").value +
    ws.getCell("G20").value;

  ws.getCell("J17").value = ws.getCell("H17").value > 10 ? 1 : 0;
  ws.getCell("J18").value = ws.getCell("H18").value > 10 ? 1 : 0;
  ws.getCell("J19").value = ws.getCell("H19").value > 10 ? 1 : 0;
  ws.getCell("J20").value = ws.getCell("H20").value > 10 ? 1 : 0;
  ws.getCell("J21").value =
    ws.getCell("J17").value +
    ws.getCell("J18").value +
    ws.getCell("J19").value +
    ws.getCell("J20").value;

  ws.getCell("O17").value = ws.getCell("M17").value > 10 ? 1 : 0;
  ws.getCell("O18").value = ws.getCell("M18").value > 10 ? 1 : 0;
  ws.getCell("O19").value = ws.getCell("M19").value > 10 ? 1 : 0;
  ws.getCell("O20").value = ws.getCell("M20").value > 10 ? 1 : 0;
  ws.getCell("O21").value =
    ws.getCell("O17").value +
    ws.getCell("O18").value +
    ws.getCell("O19").value +
    ws.getCell("O20").value;

  ws.getCell("R17").value = ws.getCell("P17").value > 10 ? 1 : 0;
  ws.getCell("R18").value = ws.getCell("P18").value > 10 ? 1 : 0;
  ws.getCell("R19").value = ws.getCell("P19").value > 10 ? 1 : 0;
  ws.getCell("R20").value = ws.getCell("P20").value > 10 ? 1 : 0;
  ws.getCell("R21").value =
    ws.getCell("R17").value +
    ws.getCell("R18").value +
    ws.getCell("R19").value +
    ws.getCell("R20").value;

  ws.getCell("S17").value =
    (getDelayCount(allItems, "LG > MFC", "08:00") || 0) +
      (getDelayCount(allItems, "MFC > MT", "08:20") || 0) || 0;
  ws.getCell("T17").value =
    Math.floor((ws.getCell("S17").value / totalDay) * 100) + "%";

  ws.getCell("S18").value =
    (getDelayCount(allItems, "LG > MFC", "08:30") || 0) +
      (getDelayCount(allItems, "MFC > MT", "08:55") || 0) || 0;
  ws.getCell("T18").value =
    Math.floor((ws.getCell("S18").value / totalDay) * 100) + "%";

  ws.getCell("S19").value =
    (getDelayCount(allItems, "LG > MFC", "09:00") || 0) +
      (getDelayCount(allItems, "MFC > MT", "09:20") || 0) || 0;
  ws.getCell("T19").value =
    Math.floor((ws.getCell("S19").value / totalDay) * 100) + "%";

  ws.getCell("S20").value =
    (getDelayCount(allItems, "LG > MFC", "09:30") || 0) +
      (getDelayCount(allItems, "MFC > MT", "09:55") || 0) || 0;
  ws.getCell("T20").value =
    Math.floor((ws.getCell("S20").value / totalDay) * 100) + "%";

  ws.getCell("S21").value =
    ws.getCell("S17").value +
    ws.getCell("S18").value +
    ws.getCell("S19").value +
    ws.getCell("S20").value;

  ws.getCell("U17").value =
    (getStatusCounts(allItems, "LG > MFC", "08:00", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MFC > MT", "08:20", "CANCEL") || 0);
  ws.getCell("V17").value =
    Math.floor((ws.getCell("U17").value / totalDay) * 100) + "%";

  ws.getCell("U18").value =
    (getStatusCounts(allItems, "LG > MFC", "08:30", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MFC > MT", "08:55", "CANCEL") || 0);
  ws.getCell("V18").value =
    Math.floor((ws.getCell("U18").value / totalDay) * 100) + "%";

  ws.getCell("U19").value =
    (getStatusCounts(allItems, "LG > MFC", "09:00", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MFC > MT", "09:20", "CANCEL") || 0);
  ws.getCell("V19").value =
    Math.floor((ws.getCell("U19").value / totalDay) * 100) + "%";

  ws.getCell("U20").value =
    (getStatusCounts(allItems, "LG > MFC", "09:30", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MFC > MT", "09:55", "CANCEL") || 0);
  ws.getCell("V20").value =
    Math.floor((ws.getCell("U20").value / totalDay) * 100) + "%";

  ws.getCell("U21").value =
    ws.getCell("U17").value +
    ws.getCell("U18").value +
    ws.getCell("U19").value +
    ws.getCell("U20").value;

  ws.getCell("C27").value = getOnBoradCount(allItems, "MT > MFC", "08:00");
  ws.getCell("C28").value = getOnBoradCount(allItems, "MT > MFC", "08:20");
  ws.getCell("C29").value = getOnBoradCount(allItems, "MT > MFC", "09:20");
  ws.getCell("C30").value = getOnBoradCount(allItems, "MT > MFC", "09:50");
  ws.getCell("C31").value =
    ws.getCell("C27").value +
    ws.getCell("C28").value +
    ws.getCell("C29").value +
    ws.getCell("C30").value;
  ws.getCell("C32").value = Math.floor(ws.getCell("C31").value / 4) || 0;

  ws.getCell("D27").value =
    Math.floor(ws.getCell("C27").value / ws.getCell("X3").value) || 0;
  ws.getCell("D28").value =
    Math.floor(ws.getCell("C28").value / ws.getCell("X3").value) || 0;
  ws.getCell("D29").value =
    Math.floor(ws.getCell("C29").value / ws.getCell("X3").value) || 0;
  ws.getCell("D30").value =
    Math.floor(ws.getCell("C30").value / ws.getCell("X3").value) || 0;
  ws.getCell("D31").value =
    ws.getCell("D27").value +
    ws.getCell("D28").value +
    ws.getCell("D29").value +
    ws.getCell("D30").value;
  ws.getCell("D32").value = Math.floor(ws.getCell("D31").value / 4) || 0;

  ws.getCell("E27").value = getOnFullCounts(allItems, "MT > MFC", "08:00", 80);
  ws.getCell("F27").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "08:00", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E28").value = getOnFullCounts(allItems, "MT > MFC", "08:20", 80);
  ws.getCell("F28").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "08:20", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E29").value = getOnFullCounts(allItems, "MT > MFC", "09:20", 80);
  ws.getCell("F29").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "09:20", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E30").value = getOnFullCounts(allItems, "MT > MFC", "09:50", 80);
  ws.getCell("F30").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "09:50", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G27").value = ws.getCell("E27").value > 10 ? 1 : 0;
  ws.getCell("H27").value =
    (Math.floor((ws.getCell("G27").value / totalDay) * 100) || 0) + "%";
  ws.getCell("G28").value = ws.getCell("E28").value > 10 ? 1 : 0;
  ws.getCell("H28").value =
    (Math.floor((ws.getCell("G28").value / totalDay) * 100) || 0) + "%";
  ws.getCell("G29").value = ws.getCell("E29").value > 10 ? 1 : 0;
  ws.getCell("H29").value =
    (Math.floor((ws.getCell("G29").value / totalDay) * 100) || 0) + "%";
  ws.getCell("G30").value = ws.getCell("E30").value > 10 ? 1 : 0;
  ws.getCell("H30").value =
    (Math.floor((ws.getCell("G30").value / totalDay) * 100) || 0) + "%";

  ws.getCell("I27").value = getOnFullCounts(allItems, "MT > MFC", "08:00", 100);
  ws.getCell("J27").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "08:00", 100) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("I28").value = getOnFullCounts(allItems, "MT > MFC", "08:20", 100);
  ws.getCell("J28").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "08:20", 100) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("I29").value = getOnFullCounts(allItems, "MT > MFC", "09:20", 100);
  ws.getCell("J29").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "09:20", 100) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("I30").value = getOnFullCounts(allItems, "MT > MFC", "09:50", 100);
  ws.getCell("J30").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "09:50", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("K27").value = ws.getCell("I27").value > 10 ? 1 : 0;
  ws.getCell("K28").value = ws.getCell("I28").value > 10 ? 1 : 0;
  ws.getCell("K29").value = ws.getCell("I29").value > 10 ? 1 : 0;
  ws.getCell("K30").value = ws.getCell("I30").value > 10 ? 1 : 0;
  ws.getCell("K31").value =
    ws.getCell("K27").value +
    ws.getCell("K28").value +
    ws.getCell("K29").value +
    ws.getCell("K30").value;

  ws.getCell("L27").value = getDelayCount(allItems, "MT > MFC", "08:00") || 0;
  ws.getCell("M27").value =
    Math.floor((ws.getCell("L27").value / totalDay) * 100) + "%";

  ws.getCell("L28").value = getDelayCount(allItems, "MT > MFC", "08:20") || 0;
  ws.getCell("M28").value =
    Math.floor((ws.getCell("L28").value / totalDay) * 100) + "%";

  ws.getCell("L29").value = getDelayCount(allItems, "MT > MFC", "09:20") || 0;
  ws.getCell("M29").value =
    Math.floor((ws.getCell("L29").value / totalDay) * 100) + "%";

  ws.getCell("L30").value = getDelayCount(allItems, "MT > MFC", "09:50") || 0;
  ws.getCell("M30").value =
    Math.floor((ws.getCell("L30").value / totalDay) * 100) + "%";

  ws.getCell("L31").value =
    ws.getCell("L27").value +
    ws.getCell("L28").value +
    ws.getCell("L29").value +
    ws.getCell("L30").value;

  ws.getCell("N27").value =
    getStatusCounts(allItems, "MT > MFC", "08:00", "CANCEL") || 0;
  ws.getCell("O27").value =
    Math.floor((ws.getCell("N27").value / totalDay) * 100) + "%";

  ws.getCell("N28").value =
    getStatusCounts(allItems, "MT > MFC", "08:20", "CANCEL") || 0;
  ws.getCell("O28").value =
    Math.floor((ws.getCell("N28").value / totalDay) * 100) + "%";

  ws.getCell("N29").value =
    getStatusCounts(allItems, "MT > MFC", "09:20", "CANCEL") || 0;
  ws.getCell("O29").value =
    Math.floor((ws.getCell("N29").value / totalDay) * 100) + "%";

  ws.getCell("N30").value =
    getStatusCounts(allItems, "MT > MFC", "09:50", "CANCEL") || 0;
  ws.getCell("O30").value =
    Math.floor((ws.getCell("N30").value / totalDay) * 100) + "%";
  ws.getCell("N31").value =
    ws.getCell("N27").value +
    ws.getCell("N28").value +
    ws.getCell("N29").value +
    ws.getCell("N30").value;

  ws.getCell("C37").value = getOnBoradCount(allItems, "MFC > MT", "08:35");
  ws.getCell("C38").value = getOnBoradCount(allItems, "MFC > MT", "09:05");
  ws.getCell("C39").value = getOnBoradCount(allItems, "MFC > MT", "09:35");
  ws.getCell("C40").value =
    ws.getCell("C37").value +
      ws.getCell("C38").value +
      ws.getCell("C39").value || 0;
  ws.getCell("C41").value = Math.floor(ws.getCell("C40").value / 4) || 0;

  ws.getCell("D37").value =
    Math.floor(ws.getCell("C37").value / ws.getCell("X3").value) || 0;
  ws.getCell("D38").value =
    Math.floor(ws.getCell("C38").value / ws.getCell("X3").value) || 0;
  ws.getCell("D39").value =
    Math.floor(ws.getCell("C39").value / ws.getCell("X3").value) || 0;

  ws.getCell("D40").value =
    ws.getCell("D37").value +
      ws.getCell("D38").value +
      ws.getCell("D39").value || 0;
  ws.getCell("D41").value = Math.floor(ws.getCell("D40").value / 4) || 0;

  ws.getCell("E37").value = getOnFullCounts(allItems, "MFC > MT", "08:35", 80);
  ws.getCell("F37").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "08:35", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E38").value = getOnFullCounts(allItems, "MFC > MT", "09:05", 80);
  ws.getCell("F38").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "09:05", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E39").value = getOnFullCounts(allItems, "MFC > MT", "09:35", 80);
  ws.getCell("F39").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "09:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G37").value = ws.getCell("E37").value > 10 ? 1 : 0;
  ws.getCell("H37").value =
    (Math.floor((ws.getCell("G37").value / totalDay) * 100) || 0) + "%";
  ws.getCell("G38").value = ws.getCell("E38").value > 10 ? 1 : 0;
  ws.getCell("H38").value =
    (Math.floor((ws.getCell("G38").value / totalDay) * 100) || 0) + "%";
  ws.getCell("G39").value = ws.getCell("E39").value > 10 ? 1 : 0;
  ws.getCell("H39").value =
    (Math.floor((ws.getCell("G39").value / totalDay) * 100) || 0) + "%";

  ws.getCell("I37").value = getOnFullCounts(allItems, "MFC > MT", "08:35", 100);
  ws.getCell("J37").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "08:35", 100) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("I38").value = getOnFullCounts(allItems, "MFC > MT", "09:05", 100);
  ws.getCell("J38").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "09:05", 100) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("I39").value = getOnFullCounts(allItems, "MFC > MT", "09:35", 100);
  ws.getCell("J39").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "09:35", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("K37").value = ws.getCell("I37").value > 10 ? 1 : 0;
  ws.getCell("K38").value = ws.getCell("I38").value > 10 ? 1 : 0;
  ws.getCell("K39").value = ws.getCell("I39").value > 10 ? 1 : 0;
  ws.getCell("K40").value =
    ws.getCell("K37").value +
      ws.getCell("K38").value +
      ws.getCell("K39").value || 0;

  ws.getCell("L37").value = getDelayCount(allItems, "MFC > MT", "08:35") || 0;
  ws.getCell("M37").value =
    Math.floor((ws.getCell("L37").value / totalDay) * 100) + "%";

  ws.getCell("L38").value = getDelayCount(allItems, "MFC > MT", "09:05") || 0;
  ws.getCell("M38").value =
    Math.floor((ws.getCell("L38").value / totalDay) * 100) + "%";

  ws.getCell("L39").value = getDelayCount(allItems, "MFC > MT", "09:35") || 0;
  ws.getCell("M39").value =
    Math.floor((ws.getCell("L39").value / totalDay) * 100) + "%";

  ws.getCell("L40").value =
    ws.getCell("L37").value +
      ws.getCell("L38").value +
      ws.getCell("L39").value || 0;

  ws.getCell("N37").value =
    getStatusCounts(allItems, "MFC > MT", "08:35", "CANCEL") || 0;
  ws.getCell("O37").value =
    Math.floor((ws.getCell("N37").value / totalDay) * 100) + "%";

  ws.getCell("N38").value =
    getStatusCounts(allItems, "MFC > MT", "09:05", "CANCEL") || 0;
  ws.getCell("O38").value =
    Math.floor((ws.getCell("N38").value / totalDay) * 100) + "%";

  ws.getCell("N39").value =
    getStatusCounts(allItems, "MFC > MT", "09:35", "CANCEL") || 0;
  ws.getCell("O39").value =
    Math.floor((ws.getCell("N39").value / totalDay) * 100) + "%";

  ws.getCell("N40").value =
    ws.getCell("N37").value +
      ws.getCell("N38").value +
      ws.getCell("N39").value || 0;

  ws.getCell("C47").value = ws.getCell("C11").value + ws.getCell("C21").value;
  ws.getCell("C48").value = Math.floor(ws.getCell("C47").value / 8) || 0;

  ws.getCell("K47").value = ws.getCell("K11").value + ws.getCell("K21").value;
  ws.getCell("K48").value = Math.floor(ws.getCell("K47").value / 8) || 0;

  ws.getCell("S47").value =
    ws.getCell("S11").value +
    ws.getCell("S21").value +
    ws.getCell("L31").value +
    ws.getCell("L40").value;

  ws.getCell("U47").value =
    ws.getCell("U11").value +
    ws.getCell("U21").value +
    ws.getCell("N31").value +
    ws.getCell("N40").value;

  ws.getCell("D47").value =
    Math.floor(ws.getCell("C47").value / ws.getCell("X3").value) || 0;
  ws.getCell("D48").value =
    Math.floor(ws.getCell("C48").value / ws.getCell("X3").value) || 0;

  ws.getCell("L47").value =
    Math.floor(ws.getCell("K47").value / ws.getCell("X3").value) || 0;
  ws.getCell("L48").value =
    Math.floor(ws.getCell("K48").value / ws.getCell("X3").value) || 0;

  ws.getCell("C54").value = getOnBoradCount(allItems, "MT > MFC", "10:00");
  ws.getCell("C55").value = getOnBoradCount(allItems, "MT > MFC", "10:30");
  ws.getCell("C56").value = getOnBoradCount(allItems, "MT > MFC", "11:00");
  ws.getCell("C57").value = getOnBoradCount(allItems, "MT > MFC", "11:30");
  ws.getCell("C58").value = getOnBoradCount(allItems, "MT > MFC", "12:30");
  ws.getCell("C59").value = getOnBoradCount(allItems, "MT > MFC", "14:30");
  ws.getCell("C60").value = getOnBoradCount(allItems, "MT > MFC", "15:00");
  ws.getCell("C61").value = getOnBoradCount(allItems, "MT > MFC", "15:30");
  ws.getCell("C62").value = getOnBoradCount(allItems, "MT > MFC", "16:00");
  ws.getCell("C63").value = getOnBoradCount(allItems, "MT > MFC", "16:30");
  ws.getCell("C64").value = getOnBoradCount(allItems, "MT > MFC", "17:00");
  ws.getCell("C65").value = getOnBoradCount(allItems, "MT > MFC", "17:30");
  ws.getCell("C66").value = getOnBoradCount(allItems, "MT > MFC", "18:00");
  ws.getCell("C67").value =
    ws.getCell("C54").value +
    ws.getCell("C55").value +
    ws.getCell("C56").value +
    ws.getCell("C57").value +
    ws.getCell("C58").value +
    ws.getCell("C59").value +
    ws.getCell("C60").value +
    ws.getCell("C61").value +
    ws.getCell("C62").value +
    ws.getCell("C63").value +
    ws.getCell("C64").value +
    ws.getCell("C65").value +
    ws.getCell("C66").value;
  ws.getCell("C68").value = Math.floor(ws.getCell("C67").value / 13) || 0;

  ws.getCell("D54").value =
    Math.floor(ws.getCell("C54").value / ws.getCell("X3").value) || 0;
  ws.getCell("D55").value =
    Math.floor(ws.getCell("C55").value / ws.getCell("X3").value) || 0;
  ws.getCell("D56").value =
    Math.floor(ws.getCell("C56").value / ws.getCell("X3").value) || 0;
  ws.getCell("D57").value =
    Math.floor(ws.getCell("C57").value / ws.getCell("X3").value) || 0;
  ws.getCell("D58").value =
    Math.floor(ws.getCell("C58").value / ws.getCell("X3").value) || 0;
  ws.getCell("D59").value =
    Math.floor(ws.getCell("C59").value / ws.getCell("X3").value) || 0;
  ws.getCell("D60").value =
    Math.floor(ws.getCell("C60").value / ws.getCell("X3").value) || 0;
  ws.getCell("D61").value =
    Math.floor(ws.getCell("C61").value / ws.getCell("X3").value) || 0;
  ws.getCell("D62").value =
    Math.floor(ws.getCell("C62").value / ws.getCell("X3").value) || 0;
  ws.getCell("D63").value =
    Math.floor(ws.getCell("C63").value / ws.getCell("X3").value) || 0;
  ws.getCell("D64").value =
    Math.floor(ws.getCell("C64").value / ws.getCell("X3").value) || 0;
  ws.getCell("D65").value =
    Math.floor(ws.getCell("C65").value / ws.getCell("X3").value) || 0;
  ws.getCell("D66").value =
    Math.floor(ws.getCell("C66").value / ws.getCell("X3").value) || 0;
  ws.getCell("D67").value =
    Math.floor(ws.getCell("C67").value / ws.getCell("X3").value) || 0;
  ws.getCell("D68").value =
    Math.floor(ws.getCell("C68").value / ws.getCell("X3").value) || 0;

  ws.getCell("E54").value = getOnFullCounts(allItems, "MT > MFC", "10:00", 80);
  ws.getCell("F54").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E55").value = getOnFullCounts(allItems, "MT > MFC", "10:30", 80);
  ws.getCell("F55").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E56").value = getOnFullCounts(allItems, "MT > MFC", "11:00", 80);
  ws.getCell("F56").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E57").value = getOnFullCounts(allItems, "MT > MFC", "11:30", 80);
  ws.getCell("F57").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E58").value = getOnFullCounts(allItems, "MT > MFC", "12:30", 80);
  ws.getCell("F58").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "12:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E59").value = getOnFullCounts(allItems, "MT > MFC", "14:30", 80);
  ws.getCell("F59").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "14:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E60").value = getOnFullCounts(allItems, "MT > MFC", "15:00", 80);
  ws.getCell("F60").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E61").value = getOnFullCounts(allItems, "MT > MFC", "15:30", 80);
  ws.getCell("F61").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E62").value = getOnFullCounts(allItems, "MT > MFC", "16:00", 80);
  ws.getCell("F62").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E63").value = getOnFullCounts(allItems, "MT > MFC", "16:30", 80);
  ws.getCell("F63").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E64").value = getOnFullCounts(allItems, "MT > MFC", "17:00", 80);
  ws.getCell("F64").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E65").value = getOnFullCounts(allItems, "MT > MFC", "17:30", 80);
  ws.getCell("F65").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E66").value = getOnFullCounts(allItems, "MT > MFC", "18:00", 80);
  ws.getCell("F66").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "18:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G54").value = ws.getCell("E54").value > 10 ? 1 : 0;
  ws.getCell("G55").value = ws.getCell("E55").value > 10 ? 1 : 0;
  ws.getCell("G56").value = ws.getCell("E56").value > 10 ? 1 : 0;
  ws.getCell("G57").value = ws.getCell("E57").value > 10 ? 1 : 0;
  ws.getCell("G58").value = ws.getCell("E58").value > 10 ? 1 : 0;
  ws.getCell("G59").value = ws.getCell("E59").value > 10 ? 1 : 0;
  ws.getCell("G60").value = ws.getCell("E60").value > 10 ? 1 : 0;
  ws.getCell("G61").value = ws.getCell("E61").value > 10 ? 1 : 0;
  ws.getCell("G62").value = ws.getCell("E62").value > 10 ? 1 : 0;
  ws.getCell("G63").value = ws.getCell("E63").value > 10 ? 1 : 0;
  ws.getCell("G64").value = ws.getCell("E64").value > 10 ? 1 : 0;
  ws.getCell("G65").value = ws.getCell("E65").value > 10 ? 1 : 0;
  ws.getCell("G66").value = ws.getCell("E66").value > 10 ? 1 : 0;
  ws.getCell("G67").value =
    ws.getCell("G54").value +
    ws.getCell("G55").value +
    ws.getCell("G56").value +
    ws.getCell("G57").value +
    ws.getCell("G58").value +
    ws.getCell("G59").value +
    ws.getCell("G60").value +
    ws.getCell("G61").value +
    ws.getCell("G62").value +
    ws.getCell("G63").value +
    ws.getCell("G64").value +
    ws.getCell("G65").value +
    ws.getCell("G66").value;

  ws.getCell("H54").value = getOnFullCounts(allItems, "MT > MFC", "10:00", 100);
  ws.getCell("I54").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:00", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H55").value = getOnFullCounts(allItems, "MT > MFC", "10:30", 100);
  ws.getCell("I55").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H56").value = getOnFullCounts(allItems, "MT > MFC", "11:00", 100);
  ws.getCell("I56").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:00", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H57").value = getOnFullCounts(allItems, "MT > MFC", "11:30", 100);
  ws.getCell("I57").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H58").value = getOnFullCounts(allItems, "MT > MFC", "12:30", 100);
  ws.getCell("I58").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "12:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H59").value = getOnFullCounts(allItems, "MT > MFC", "14:30", 100);
  ws.getCell("I59").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "14:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H60").value = getOnFullCounts(allItems, "MT > MFC", "15:00", 100);
  ws.getCell("I60").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:00", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H61").value = getOnFullCounts(allItems, "MT > MFC", "15:30", 100);
  ws.getCell("I61").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H62").value = getOnFullCounts(allItems, "MT > MFC", "16:00", 100);
  ws.getCell("I62").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:00", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H63").value = getOnFullCounts(allItems, "MT > MFC", "16:30", 100);
  ws.getCell("I63").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H64").value = getOnFullCounts(allItems, "MT > MFC", "17:00", 100);
  ws.getCell("I64").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:00", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H65").value = getOnFullCounts(allItems, "MT > MFC", "17:30", 100);
  ws.getCell("I65").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H66").value = getOnFullCounts(allItems, "MT > MFC", "18:00", 100);
  ws.getCell("I66").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "18:00", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("J54").value = ws.getCell("H54").value > 10 ? 1 : 0;
  ws.getCell("J55").value = ws.getCell("H55").value > 10 ? 1 : 0;
  ws.getCell("J56").value = ws.getCell("H56").value > 10 ? 1 : 0;
  ws.getCell("J57").value = ws.getCell("H57").value > 10 ? 1 : 0;
  ws.getCell("J58").value = ws.getCell("H58").value > 10 ? 1 : 0;
  ws.getCell("J59").value = ws.getCell("H59").value > 10 ? 1 : 0;
  ws.getCell("J60").value = ws.getCell("H60").value > 10 ? 1 : 0;
  ws.getCell("J61").value = ws.getCell("H61").value > 10 ? 1 : 0;
  ws.getCell("J62").value = ws.getCell("H62").value > 10 ? 1 : 0;
  ws.getCell("J63").value = ws.getCell("H63").value > 10 ? 1 : 0;
  ws.getCell("J64").value = ws.getCell("H64").value > 10 ? 1 : 0;
  ws.getCell("J65").value = ws.getCell("H65").value > 10 ? 1 : 0;
  ws.getCell("J66").value = ws.getCell("H66").value > 10 ? 1 : 0;
  ws.getCell("J67").value =
    ws.getCell("J54").value +
    ws.getCell("J55").value +
    ws.getCell("J56").value +
    ws.getCell("J57").value +
    ws.getCell("J58").value +
    ws.getCell("J59").value +
    ws.getCell("J60").value +
    ws.getCell("J61").value +
    ws.getCell("J62").value +
    ws.getCell("J63").value +
    ws.getCell("J64").value +
    ws.getCell("J65").value +
    ws.getCell("J66").value;

  ws.getCell("K54").value = getRouteCount(allItems, "MFC > LG", "10:10");
  ws.getCell("K55").value = getRouteCount(allItems, "MFC > LG", "10:40");
  ws.getCell("K56").value = getRouteCount(allItems, "MFC > LG", "11:10");
  ws.getCell("K57").value = getRouteCount(allItems, "MFC > LG", "11:40");
  ws.getCell("K58").value = getRouteCount(allItems, "MFC > LG", "12:40");
  ws.getCell("K59").value = getRouteCount(allItems, "MFC > LG", "14:40");
  ws.getCell("K60").value = getRouteCount(allItems, "MFC > LG", "15:10");
  ws.getCell("K61").value = getRouteCount(allItems, "MFC > LG", "15:40");
  ws.getCell("K62").value = getRouteCount(allItems, "MFC > LG", "16:10");
  ws.getCell("K63").value = getRouteCount(allItems, "MFC > LG", "16:40");
  ws.getCell("K64").value = getRouteCount(allItems, "MFC > LG", "17:10");
  ws.getCell("K65").value = getRouteCount(allItems, "MFC > LG", "17:40");
  ws.getCell("K66").value = getRouteCount(allItems, "MFC > LG", "18:10");
  ws.getCell("K67").value =
    ws.getCell("K54").value +
    ws.getCell("K55").value +
    ws.getCell("K56").value +
    ws.getCell("K57").value +
    ws.getCell("K58").value +
    ws.getCell("K59").value +
    ws.getCell("K60").value +
    ws.getCell("K61").value +
    ws.getCell("K62").value +
    ws.getCell("K63").value +
    ws.getCell("K64").value +
    ws.getCell("K65").value +
    ws.getCell("K66").value;
  ws.getCell("K68").value = Math.floor(ws.getCell("K67").value / 13) || 0;

  ws.getCell("L54").value =
    Math.floor(ws.getCell("K54").value / ws.getCell("X3").value) || 0;
  ws.getCell("L55").value =
    Math.floor(ws.getCell("K55").value / ws.getCell("X3").value) || 0;
  ws.getCell("L56").value =
    Math.floor(ws.getCell("K56").value / ws.getCell("X3").value) || 0;
  ws.getCell("L57").value =
    Math.floor(ws.getCell("K57").value / ws.getCell("X3").value) || 0;
  ws.getCell("L58").value =
    Math.floor(ws.getCell("K58").value / ws.getCell("X3").value) || 0;
  ws.getCell("L59").value =
    Math.floor(ws.getCell("K59").value / ws.getCell("X3").value) || 0;
  ws.getCell("L60").value =
    Math.floor(ws.getCell("K60").value / ws.getCell("X3").value) || 0;
  ws.getCell("L61").value =
    Math.floor(ws.getCell("K61").value / ws.getCell("X3").value) || 0;
  ws.getCell("L62").value =
    Math.floor(ws.getCell("K62").value / ws.getCell("X3").value) || 0;
  ws.getCell("L63").value =
    Math.floor(ws.getCell("K63").value / ws.getCell("X3").value) || 0;
  ws.getCell("L64").value =
    Math.floor(ws.getCell("K64").value / ws.getCell("X3").value) || 0;
  ws.getCell("L65").value =
    Math.floor(ws.getCell("K65").value / ws.getCell("X3").value) || 0;
  ws.getCell("L66").value =
    Math.floor(ws.getCell("K66").value / ws.getCell("X3").value) || 0;
  ws.getCell("L67").value =
    Math.floor(ws.getCell("K67").value / ws.getCell("X3").value) || 0;
  ws.getCell("L68").value =
    Math.floor(ws.getCell("K68").value / ws.getCell("X3").value) || 0;

  ws.getCell("M54").value = getFullCounts(allItems, "MFC > LG", "10:10", 80);
  ws.getCell("N54").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "10:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M55").value = getFullCounts(allItems, "MFC > LG", "10:40", 80);
  ws.getCell("N55").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "10:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M56").value = getFullCounts(allItems, "MFC > LG", "11:10", 80);
  ws.getCell("N56").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "11:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M57").value = getFullCounts(allItems, "MFC > LG", "11:40", 80);
  ws.getCell("N57").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "11:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M58").value = getFullCounts(allItems, "MFC > LG", "12:40", 80);
  ws.getCell("N58").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "12:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M59").value = getFullCounts(allItems, "MFC > LG", "14:40", 80);
  ws.getCell("N59").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "14:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M60").value = getFullCounts(allItems, "MFC > LG", "15:10", 80);
  ws.getCell("N60").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "15:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M61").value = getFullCounts(allItems, "MFC > LG", "15:40", 80);
  ws.getCell("N61").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "15:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M62").value = getFullCounts(allItems, "MFC > LG", "16:10", 80);
  ws.getCell("N62").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "16:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M63").value = getFullCounts(allItems, "MFC > LG", "16:40", 80);
  ws.getCell("N63").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "16:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M64").value = getFullCounts(allItems, "MFC > LG", "17:10", 80);
  ws.getCell("N64").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "17:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M65").value = getFullCounts(allItems, "MFC > LG", "17:40", 80);
  ws.getCell("N65").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "17:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M66").value = getFullCounts(allItems, "MFC > LG", "18:10", 80);
  ws.getCell("N66").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > LG", "18:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("O54").value = ws.getCell("M54").value > 10 ? 1 : 0;
  ws.getCell("O55").value = ws.getCell("M55").value > 10 ? 1 : 0;
  ws.getCell("O56").value = ws.getCell("M56").value > 10 ? 1 : 0;
  ws.getCell("O57").value = ws.getCell("M57").value > 10 ? 1 : 0;
  ws.getCell("O58").value = ws.getCell("M58").value > 10 ? 1 : 0;
  ws.getCell("O59").value = ws.getCell("M59").value > 10 ? 1 : 0;
  ws.getCell("O60").value = ws.getCell("M60").value > 10 ? 1 : 0;
  ws.getCell("O61").value = ws.getCell("M61").value > 10 ? 1 : 0;
  ws.getCell("O62").value = ws.getCell("M62").value > 10 ? 1 : 0;
  ws.getCell("O63").value = ws.getCell("M63").value > 10 ? 1 : 0;
  ws.getCell("O64").value = ws.getCell("M64").value > 10 ? 1 : 0;
  ws.getCell("O65").value = ws.getCell("M65").value > 10 ? 1 : 0;
  ws.getCell("O66").value = ws.getCell("M66").value > 10 ? 1 : 0;
  ws.getCell("O67").value =
    ws.getCell("O54").value +
    ws.getCell("O55").value +
    ws.getCell("O56").value +
    ws.getCell("O57").value +
    ws.getCell("O58").value +
    ws.getCell("O59").value +
    ws.getCell("O60").value +
    ws.getCell("O61").value +
    ws.getCell("O62").value +
    ws.getCell("O63").value +
    ws.getCell("O64").value +
    ws.getCell("O65").value +
    ws.getCell("O66").value;

  ws.getCell("P54").value = getFullCounts(allItems, "MFC > LG", "10:10", 100);
  ws.getCell("Q54").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "10:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P55").value = getFullCounts(allItems, "MFC > LG", "10:40", 100);
  ws.getCell("Q55").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "10:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P56").value = getFullCounts(allItems, "MFC > LG", "11:10", 100);
  ws.getCell("Q56").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "11:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P57").value = getFullCounts(allItems, "MFC > LG", "11:40", 100);
  ws.getCell("Q57").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "11:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P58").value = getFullCounts(allItems, "MFC > LG", "12:40", 100);
  ws.getCell("Q58").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "12:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P59").value = getFullCounts(allItems, "MFC > LG", "14:40", 100);
  ws.getCell("Q59").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "14:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P60").value = getFullCounts(allItems, "MFC > LG", "15:10", 100);
  ws.getCell("Q60").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "15:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P61").value = getFullCounts(allItems, "MFC > LG", "15:40", 100);
  ws.getCell("Q61").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "15:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P62").value = getFullCounts(allItems, "MFC > LG", "16:10", 100);
  ws.getCell("Q62").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "16:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P63").value = getFullCounts(allItems, "MFC > LG", "16:40", 100);
  ws.getCell("Q63").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "16:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P64").value = getFullCounts(allItems, "MFC > LG", "17:10", 100);
  ws.getCell("Q64").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "17:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P65").value = getFullCounts(allItems, "MFC > LG", "17:40", 100);
  ws.getCell("Q65").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "17:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P66").value = getFullCounts(allItems, "MFC > LG", "18:10", 100);
  ws.getCell("Q66").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "18:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("R54").value = ws.getCell("P54").value > 10 ? 1 : 0;
  ws.getCell("R55").value = ws.getCell("P55").value > 10 ? 1 : 0;
  ws.getCell("R56").value = ws.getCell("P56").value > 10 ? 1 : 0;
  ws.getCell("R57").value = ws.getCell("P57").value > 10 ? 1 : 0;
  ws.getCell("R58").value = ws.getCell("P58").value > 10 ? 1 : 0;
  ws.getCell("R59").value = ws.getCell("P59").value > 10 ? 1 : 0;
  ws.getCell("R60").value = ws.getCell("P60").value > 10 ? 1 : 0;
  ws.getCell("R61").value = ws.getCell("P61").value > 10 ? 1 : 0;
  ws.getCell("R62").value = ws.getCell("P62").value > 10 ? 1 : 0;
  ws.getCell("R63").value = ws.getCell("P63").value > 10 ? 1 : 0;
  ws.getCell("R64").value = ws.getCell("P64").value > 10 ? 1 : 0;
  ws.getCell("R65").value = ws.getCell("P65").value > 10 ? 1 : 0;
  ws.getCell("R66").value = ws.getCell("P66").value > 10 ? 1 : 0;
  ws.getCell("R67").value =
    ws.getCell("R54").value +
    ws.getCell("R55").value +
    ws.getCell("R56").value +
    ws.getCell("R57").value +
    ws.getCell("R58").value +
    ws.getCell("R59").value +
    ws.getCell("R60").value +
    ws.getCell("R61").value +
    ws.getCell("R62").value +
    ws.getCell("R63").value +
    ws.getCell("R64").value +
    ws.getCell("R65").value +
    ws.getCell("R66").value;

  ws.getCell("S54").value =
    (getDelayCount(allItems, "MT > MFC", "10:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "10:10") || 0) || 0;
  ws.getCell("T54").value =
    Math.floor((ws.getCell("S54").value / totalDay) * 100) + "%";

  ws.getCell("S55").value =
    (getDelayCount(allItems, "MT > MFC", "10:30") || 0) +
      (getDelayCount(allItems, "MFC > LG", "10:40") || 0) || 0;
  ws.getCell("T55").value =
    Math.floor((ws.getCell("S55").value / totalDay) * 100) + "%";

  ws.getCell("S56").value =
    (getDelayCount(allItems, "MT > MFC", "11:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "11:10") || 0) || 0;
  ws.getCell("T56").value =
    Math.floor((ws.getCell("S56").value / totalDay) * 100) + "%";

  ws.getCell("S57").value =
    (getDelayCount(allItems, "MT > MFC", "11:30") || 0) +
      (getDelayCount(allItems, "MFC > LG", "11:40") || 0) || 0;
  ws.getCell("T57").value =
    Math.floor((ws.getCell("S57").value / totalDay) * 100) + "%";

  ws.getCell("S58").value =
    (getDelayCount(allItems, "MT > MFC", "12:30") || 0) +
      (getDelayCount(allItems, "MFC > LG", "12:40") || 0) || 0;
  ws.getCell("T58").value =
    Math.floor((ws.getCell("S58").value / totalDay) * 100) + "%";

  ws.getCell("S59").value =
    (getDelayCount(allItems, "MT > MFC", "14:30") || 0) +
      (getDelayCount(allItems, "MFC > LG", "14:40") || 0) || 0;
  ws.getCell("T59").value =
    Math.floor((ws.getCell("S59").value / totalDay) * 100) + "%";

  ws.getCell("S60").value =
    (getDelayCount(allItems, "MT > MFC", "15:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "15:10") || 0) || 0;
  ws.getCell("T60").value =
    Math.floor((ws.getCell("S60").value / totalDay) * 100) + "%";

  ws.getCell("S61").value =
    (getDelayCount(allItems, "MT > MFC", "15:30") || 0) +
      (getDelayCount(allItems, "MFC > LG", "15:40") || 0) || 0;
  ws.getCell("T61").value =
    Math.floor((ws.getCell("S61").value / totalDay) * 100) + "%";

  ws.getCell("S62").value =
    (getDelayCount(allItems, "MT > MFC", "16:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "16:10") || 0) || 0;
  ws.getCell("T62").value =
    Math.floor((ws.getCell("S62").value / totalDay) * 100) + "%";

  ws.getCell("S63").value =
    (getDelayCount(allItems, "MT > MFC", "16:30") || 0) +
      (getDelayCount(allItems, "MFC > LG", "16:40") || 0) || 0;
  ws.getCell("T63").value =
    Math.floor((ws.getCell("S63").value / totalDay) * 100) + "%";

  ws.getCell("S64").value =
    (getDelayCount(allItems, "MT > MFC", "17:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "17:10") || 0) || 0;
  ws.getCell("T64").value =
    Math.floor((ws.getCell("S64").value / totalDay) * 100) + "%";

  ws.getCell("S65").value =
    (getDelayCount(allItems, "MT > MFC", "17:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "17:40") || 0) || 0;
  ws.getCell("T65").value =
    Math.floor((ws.getCell("S65").value / totalDay) * 100) + "%";

  ws.getCell("S66").value =
    (getDelayCount(allItems, "MT > MFC", "18:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "18:10") || 0) || 0;
  ws.getCell("T66").value =
    Math.floor((ws.getCell("S66").value / totalDay) * 100) + "%";
  ws.getCell("S67").value =
    ws.getCell("S54").value +
    ws.getCell("S55").value +
    ws.getCell("S56").value +
    ws.getCell("S57").value +
    ws.getCell("S58").value +
    ws.getCell("S59").value +
    ws.getCell("S60").value +
    ws.getCell("S61").value +
    ws.getCell("S62").value +
    ws.getCell("S63").value +
    ws.getCell("S64").value +
    ws.getCell("S65").value +
    ws.getCell("S66").value;

  ws.getCell("U54").value =
    (getStatusCounts(allItems, "MT > MFC", "10:00", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "10:10", "CANCEL") || 0) || 0;
  ws.getCell("V54").value =
    Math.floor((ws.getCell("U54").value / totalDay) * 100) + "%";

  ws.getCell("U55").value =
    (getStatusCounts(allItems, "MT > MFC", "10:30", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "10:40", "CANCEL") || 0) || 0;
  ws.getCell("V55").value =
    Math.floor((ws.getCell("U55").value / totalDay) * 100) + "%";

  ws.getCell("U56").value =
    (getStatusCounts(allItems, "MT > MFC", "11:00", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "11:10", "CANCEL") || 0) || 0;
  ws.getCell("V56").value =
    Math.floor((ws.getCell("U56").value / totalDay) * 100) + "%";

  ws.getCell("U57").value =
    (getStatusCounts(allItems, "MT > MFC", "11:30", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "11:40", "CANCEL") || 0) || 0;
  ws.getCell("V57").value =
    Math.floor((ws.getCell("U57").value / totalDay) * 100) + "%";

  ws.getCell("U58").value =
    (getStatusCounts(allItems, "MT > MFC", "12:30", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "12:40", "CANCEL") || 0) || 0;
  ws.getCell("V58").value =
    Math.floor((ws.getCell("U58").value / totalDay) * 100) + "%";

  ws.getCell("U59").value =
    (getStatusCounts(allItems, "MT > MFC", "14:30", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "14:40", "CANCEL") || 0) || 0;
  ws.getCell("V59").value =
    Math.floor((ws.getCell("U59").value / totalDay) * 100) + "%";

  ws.getCell("U60").value =
    (getStatusCounts(allItems, "MT > MFC", "15:00", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "15:10", "CANCEL") || 0) || 0;
  ws.getCell("V60").value =
    Math.floor((ws.getCell("U60").value / totalDay) * 100) + "%";

  ws.getCell("U61").value =
    (getStatusCounts(allItems, "MT > MFC", "15:30", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "15:40", "CANCEL") || 0) || 0;
  ws.getCell("V61").value =
    Math.floor((ws.getCell("U61").value / totalDay) * 100) + "%";

  ws.getCell("U62").value =
    (getStatusCounts(allItems, "MT > MFC", "16:00", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "16:10", "CANCEL") || 0) || 0;
  ws.getCell("V62").value =
    Math.floor((ws.getCell("U62").value / totalDay) * 100) + "%";

  ws.getCell("U63").value =
    (getStatusCounts(allItems, "MT > MFC", "16:30", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "16:40", "CANCEL") || 0) || 0;
  ws.getCell("V63").value =
    Math.floor((ws.getCell("U63").value / totalDay) * 100) + "%";

  ws.getCell("U64").value =
    (getStatusCounts(allItems, "MT > MFC", "17:00", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "17:10", "CANCEL") || 0) || 0;
  ws.getCell("V64").value =
    Math.floor((ws.getCell("U64").value / totalDay) * 100) + "%";

  ws.getCell("U65").value =
    (getStatusCounts(allItems, "MT > MFC", "17:00", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "17:40", "CANCEL") || 0) || 0;
  ws.getCell("V65").value =
    Math.floor((ws.getCell("U65").value / totalDay) * 100) + "%";

  ws.getCell("U66").value =
    (getStatusCounts(allItems, "MT > MFC", "18:00", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > LG", "18:10", "CANCEL") || 0) || 0;
  ws.getCell("V66").value =
    Math.floor((ws.getCell("U66").value / totalDay) * 100) + "%";
  ws.getCell("U67").value =
    ws.getCell("U54").value +
    ws.getCell("U55").value +
    ws.getCell("U56").value +
    ws.getCell("U57").value +
    ws.getCell("U58").value +
    ws.getCell("U59").value +
    ws.getCell("U60").value +
    ws.getCell("U61").value +
    ws.getCell("U62").value +
    ws.getCell("U63").value +
    ws.getCell("U64").value +
    ws.getCell("U65").value +
    ws.getCell("U66").value;

  ws.getCell("C73").value = getOnBoradCount(allItems, "LG > MFC", "10:15");
  ws.getCell("C74").value = getOnBoradCount(allItems, "LG > MFC", "10:45");
  ws.getCell("C75").value = getOnBoradCount(allItems, "LG > MFC", "11:15");
  ws.getCell("C76").value = getOnBoradCount(allItems, "LG > MFC", "11:45");
  ws.getCell("C77").value = getOnBoradCount(allItems, "LG > MFC", "12:15");
  ws.getCell("C78").value = getOnBoradCount(allItems, "LG > MFC", "12:45");
  ws.getCell("C80").value = getOnBoradCount(allItems, "LG > MFC", "13:45");
  ws.getCell("C81").value = getOnBoradCount(allItems, "LG > MFC", "14:15");
  ws.getCell("C82").value = getOnBoradCount(allItems, "LG > MFC", "14:45");
  ws.getCell("C83").value = getOnBoradCount(allItems, "LG > MFC", "15:15");
  ws.getCell("C84").value = getOnBoradCount(allItems, "LG > MFC", "15:45");
  ws.getCell("C85").value = getOnBoradCount(allItems, "LG > MFC", "16:15");
  ws.getCell("C86").value = getOnBoradCount(allItems, "LG > MFC", "16:45");
  ws.getCell("C87").value = getOnBoradCount(allItems, "LG > MFC", "17:15");
  ws.getCell("C88").value = getOnBoradCount(allItems, "LG > MFC", "17:45");
  ws.getCell("C89").value = getOnBoradCount(allItems, "LG > MFC", "18:15");
  ws.getCell("C90").value =
    ws.getCell("C73").value +
    ws.getCell("C74").value +
    ws.getCell("C75").value +
    ws.getCell("C76").value +
    ws.getCell("C77").value +
    ws.getCell("C78").value +
    ws.getCell("C80").value +
    ws.getCell("C81").value +
    ws.getCell("C82").value +
    ws.getCell("C83").value +
    ws.getCell("C84").value +
    ws.getCell("C85").value +
    ws.getCell("C86").value +
    ws.getCell("C87").value +
    ws.getCell("C88").value +
    ws.getCell("C89").value;
  ws.getCell("C91").value = Math.floor(ws.getCell("C90").value / 16) || 0;

  ws.getCell("D73").value =
    Math.floor(ws.getCell("C73").value / ws.getCell("X3").value) || 0;
  ws.getCell("D74").value =
    Math.floor(ws.getCell("C74").value / ws.getCell("X3").value) || 0;
  ws.getCell("D75").value =
    Math.floor(ws.getCell("C75").value / ws.getCell("X3").value) || 0;
  ws.getCell("D76").value =
    Math.floor(ws.getCell("C76").value / ws.getCell("X3").value) || 0;
  ws.getCell("D77").value =
    Math.floor(ws.getCell("C77").value / ws.getCell("X3").value) || 0;
  ws.getCell("D78").value =
    Math.floor(ws.getCell("C78").value / ws.getCell("X3").value) || 0;
  ws.getCell("D80").value =
    Math.floor(ws.getCell("C80").value / ws.getCell("X3").value) || 0;
  ws.getCell("D81").value =
    Math.floor(ws.getCell("C81").value / ws.getCell("X3").value) || 0;
  ws.getCell("D82").value =
    Math.floor(ws.getCell("C82").value / ws.getCell("X3").value) || 0;
  ws.getCell("D83").value =
    Math.floor(ws.getCell("C83").value / ws.getCell("X3").value) || 0;
  ws.getCell("D84").value =
    Math.floor(ws.getCell("C84").value / ws.getCell("X3").value) || 0;
  ws.getCell("D85").value =
    Math.floor(ws.getCell("C85").value / ws.getCell("X3").value) || 0;
  ws.getCell("D86").value =
    Math.floor(ws.getCell("C86").value / ws.getCell("X3").value) || 0;
  ws.getCell("D87").value =
    Math.floor(ws.getCell("C87").value / ws.getCell("X3").value) || 0;
  ws.getCell("D88").value =
    Math.floor(ws.getCell("C88").value / ws.getCell("X3").value) || 0;
  ws.getCell("D89").value =
    Math.floor(ws.getCell("C89").value / ws.getCell("X3").value) || 0;
  ws.getCell("D90").value =
    Math.floor(ws.getCell("C90").value / ws.getCell("X3").value) || 0;
  ws.getCell("D91").value =
    Math.floor(ws.getCell("C91").value / ws.getCell("X3").value) || 0;

  ws.getCell("E73").value = getOnFullCounts(allItems, "LG > MFC", "10:15", 80);
  ws.getCell("F73").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "10:15", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E74").value = getOnFullCounts(allItems, "LG > MFC", "10:45", 80);
  ws.getCell("F74").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "10:45", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E75").value = getOnFullCounts(allItems, "LG > MFC", "11:15", 80);
  ws.getCell("F75").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "11:15", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E76").value = getOnFullCounts(allItems, "LG > MFC", "11:45", 80);
  ws.getCell("F76").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "11:45", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E77").value = getOnFullCounts(allItems, "LG > MFC", "12:15", 80);
  ws.getCell("F77").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "12:15", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E78").value = getOnFullCounts(allItems, "LG > MFC", "12:45", 80);
  ws.getCell("F78").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "12:45", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E80").value = getOnFullCounts(allItems, "LG > MFC", "13:45", 80);
  ws.getCell("F80").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "13:45", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E81").value = getOnFullCounts(allItems, "LG > MFC", "14:15", 80);
  ws.getCell("F81").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "14:15", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E82").value = getOnFullCounts(allItems, "LG > MFC", "14:45", 80);
  ws.getCell("F82").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "14:45", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E83").value = getOnFullCounts(allItems, "LG > MFC", "15:15", 80);
  ws.getCell("F83").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "15:15", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E84").value = getOnFullCounts(allItems, "LG > MFC", "15:45", 80);
  ws.getCell("F84").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "15:45", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E85").value = getOnFullCounts(allItems, "LG > MFC", "16:15", 80);
  ws.getCell("F85").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "16:15", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E86").value = getOnFullCounts(allItems, "LG > MFC", "16:45", 80);
  ws.getCell("F86").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "16:45", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E87").value = getOnFullCounts(allItems, "LG > MFC", "17:15", 80);
  ws.getCell("F87").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "17:15", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E88").value = getOnFullCounts(allItems, "LG > MFC", "17:45", 80);
  ws.getCell("F88").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "17:45", 80) / totalDay) * 100
    ) || 0) + "%";
  ws.getCell("E89").value = getOnFullCounts(allItems, "LG > MFC", "18:15", 80);
  ws.getCell("F89").value =
    (Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "18:15", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G73").value = ws.getCell("E73").value > 10 ? 1 : 0;
  ws.getCell("G74").value = ws.getCell("E74").value > 10 ? 1 : 0;
  ws.getCell("G75").value = ws.getCell("E75").value > 10 ? 1 : 0;
  ws.getCell("G76").value = ws.getCell("E76").value > 10 ? 1 : 0;
  ws.getCell("G77").value = ws.getCell("E77").value > 10 ? 1 : 0;
  ws.getCell("G78").value = ws.getCell("E78").value > 10 ? 1 : 0;
  ws.getCell("G80").value = ws.getCell("E80").value > 10 ? 1 : 0;
  ws.getCell("G81").value = ws.getCell("E81").value > 10 ? 1 : 0;
  ws.getCell("G82").value = ws.getCell("E82").value > 10 ? 1 : 0;
  ws.getCell("G83").value = ws.getCell("E83").value > 10 ? 1 : 0;
  ws.getCell("G84").value = ws.getCell("E84").value > 10 ? 1 : 0;
  ws.getCell("G85").value = ws.getCell("E85").value > 10 ? 1 : 0;
  ws.getCell("G86").value = ws.getCell("E86").value > 10 ? 1 : 0;
  ws.getCell("G87").value = ws.getCell("E87").value > 10 ? 1 : 0;
  ws.getCell("G88").value = ws.getCell("E88").value > 10 ? 1 : 0;
  ws.getCell("G89").value = ws.getCell("E89").value > 10 ? 1 : 0;
  ws.getCell("G90").value =
    ws.getCell("G73").value +
    ws.getCell("G74").value +
    ws.getCell("G75").value +
    ws.getCell("G76").value +
    ws.getCell("G77").value +
    ws.getCell("G78").value +
    ws.getCell("G80").value +
    ws.getCell("G81").value +
    ws.getCell("G82").value +
    ws.getCell("G83").value +
    ws.getCell("G84").value +
    ws.getCell("G85").value +
    ws.getCell("G86").value +
    ws.getCell("G87").value +
    ws.getCell("G88").value +
    ws.getCell("G89").value;

  ws.getCell("H73").value = getOnFullCounts(allItems, "LG > MFC", "10:15", 100);
  ws.getCell("I73").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "10:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H74").value = getOnFullCounts(allItems, "LG > MFC", "10:45", 100);
  ws.getCell("I74").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "10:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H75").value = getOnFullCounts(allItems, "LG > MFC", "11:15", 100);
  ws.getCell("I75").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "11:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H76").value = getOnFullCounts(allItems, "LG > MFC", "11:45", 100);
  ws.getCell("I76").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "11:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H77").value = getOnFullCounts(allItems, "LG > MFC", "12:15", 100);
  ws.getCell("I77").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "12:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H78").value = getOnFullCounts(allItems, "LG > MFC", "12:45", 100);
  ws.getCell("I78").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "12:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H80").value = getOnFullCounts(allItems, "LG > MFC", "13:45", 100);
  ws.getCell("I80").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "13:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H81").value = getOnFullCounts(allItems, "LG > MFC", "14:15", 100);
  ws.getCell("I81").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "14:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H82").value = getOnFullCounts(allItems, "LG > MFC", "14:45", 100);
  ws.getCell("I82").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "14:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H83").value = getOnFullCounts(allItems, "LG > MFC", "15:15", 100);
  ws.getCell("I83").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "15:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H84").value = getOnFullCounts(allItems, "LG > MFC", "15:45", 100);
  ws.getCell("I84").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "15:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H85").value = getOnFullCounts(allItems, "LG > MFC", "16:15", 100);
  ws.getCell("I85").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "16:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H86").value = getOnFullCounts(allItems, "LG > MFC", "16:45", 100);
  ws.getCell("I86").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "16:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H87").value = getOnFullCounts(allItems, "LG > MFC", "17:15", 100);
  ws.getCell("I87").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "17:15", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H88").value = getOnFullCounts(allItems, "LG > MFC", "17:45", 100);
  ws.getCell("I88").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "17:45", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H89").value = getOnFullCounts(allItems, "LG > MFC", "18:15", 100);
  ws.getCell("I89").value =
    Math.floor(
      (getOnFullCounts(allItems, "LG > MFC", "18:15", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("J73").value = ws.getCell("H73").value > 10 ? 1 : 0;
  ws.getCell("J74").value = ws.getCell("H74").value > 10 ? 1 : 0;
  ws.getCell("J75").value = ws.getCell("H75").value > 10 ? 1 : 0;
  ws.getCell("J76").value = ws.getCell("H76").value > 10 ? 1 : 0;
  ws.getCell("J77").value = ws.getCell("H77").value > 10 ? 1 : 0;
  ws.getCell("J78").value = ws.getCell("H78").value > 10 ? 1 : 0;
  ws.getCell("J80").value = ws.getCell("H80").value > 10 ? 1 : 0;
  ws.getCell("J82").value = ws.getCell("H82").value > 10 ? 1 : 0;
  ws.getCell("J82").value = ws.getCell("H82").value > 10 ? 1 : 0;
  ws.getCell("J83").value = ws.getCell("H83").value > 10 ? 1 : 0;
  ws.getCell("J84").value = ws.getCell("H84").value > 10 ? 1 : 0;
  ws.getCell("J85").value = ws.getCell("H85").value > 10 ? 1 : 0;
  ws.getCell("J86").value = ws.getCell("H86").value > 10 ? 1 : 0;
  ws.getCell("J87").value = ws.getCell("H87").value > 10 ? 1 : 0;
  ws.getCell("J88").value = ws.getCell("H88").value > 10 ? 1 : 0;
  ws.getCell("J89").value = ws.getCell("H89").value > 10 ? 1 : 0;
  ws.getCell("J90").value =
    ws.getCell("J73").value +
    ws.getCell("J74").value +
    ws.getCell("J75").value +
    ws.getCell("J76").value +
    ws.getCell("J77").value +
    ws.getCell("J78").value +
    ws.getCell("J80").value +
    ws.getCell("J81").value +
    ws.getCell("J82").value +
    ws.getCell("J83").value +
    ws.getCell("J84").value +
    ws.getCell("J85").value +
    ws.getCell("J86").value +
    ws.getCell("J87").value +
    ws.getCell("J88").value +
    ws.getCell("J89").value;

  ws.getCell("K73").value = getRouteCount(allItems, "MFC > MT", "10:35");
  ws.getCell("K74").value = getRouteCount(allItems, "MFC > MT", "11:05");
  ws.getCell("K75").value = getRouteCount(allItems, "MFC > MT", "11:35");
  ws.getCell("K76").value = getRouteCount(allItems, "MFC > MT", "12:05");
  ws.getCell("K77").value = getRouteCount(allItems, "MFC > MT", "12:35");
  ws.getCell("K78").value = getRouteCount(allItems, "MFC > MT", "13:05");
  ws.getCell("K79").value = getRouteCount(allItems, "MFC > MT", "13:45");
  ws.getCell("K80").value = getRouteCount(allItems, "MFC > MT", "14:05");
  ws.getCell("K81").value = getRouteCount(allItems, "MFC > MT", "14:35");
  ws.getCell("K82").value = getRouteCount(allItems, "MFC > MT", "15:05");
  ws.getCell("K83").value = getRouteCount(allItems, "MFC > MT", "15:35");
  ws.getCell("K84").value = getRouteCount(allItems, "MFC > MT", "16:05");
  ws.getCell("K85").value = getRouteCount(allItems, "MFC > MT", "16:35");
  ws.getCell("K86").value = getRouteCount(allItems, "MFC > MT", "17:05");
  ws.getCell("K87").value = getRouteCount(allItems, "MFC > MT", "17:35");
  ws.getCell("K88").value = getRouteCount(allItems, "MFC > MT", "18:05");
  ws.getCell("K89").value = getRouteCount(allItems, "MFC > MT", "18:35");
  ws.getCell("K90").value =
    ws.getCell("K74").value +
    ws.getCell("K75").value +
    ws.getCell("K76").value +
    ws.getCell("K77").value +
    ws.getCell("K78").value +
    ws.getCell("K79").value +
    ws.getCell("K80").value +
    ws.getCell("K81").value +
    ws.getCell("K82").value +
    ws.getCell("K83").value +
    ws.getCell("K84").value +
    ws.getCell("K85").value +
    ws.getCell("K86").value +
    ws.getCell("K87").value +
    ws.getCell("K88").value +
    ws.getCell("K89").value;
  ws.getCell("K91").value = Math.floor(ws.getCell("K90").value / 16) || 0;

  ws.getCell("L73").value =
    Math.floor(ws.getCell("K73").value / ws.getCell("X3").value) || 0;
  ws.getCell("L74").value =
    Math.floor(ws.getCell("K74").value / ws.getCell("X3").value) || 0;
  ws.getCell("L75").value =
    Math.floor(ws.getCell("K75").value / ws.getCell("X3").value) || 0;
  ws.getCell("L76").value =
    Math.floor(ws.getCell("K76").value / ws.getCell("X3").value) || 0;
  ws.getCell("L77").value =
    Math.floor(ws.getCell("K77").value / ws.getCell("X3").value) || 0;
  ws.getCell("L78").value =
    Math.floor(ws.getCell("K78").value / ws.getCell("X3").value) || 0;
  ws.getCell("L79").value =
    Math.floor(ws.getCell("K79").value / ws.getCell("X3").value) || 0;
  ws.getCell("L80").value =
    Math.floor(ws.getCell("K80").value / ws.getCell("X3").value) || 0;
  ws.getCell("L81").value =
    Math.floor(ws.getCell("K81").value / ws.getCell("X3").value) || 0;
  ws.getCell("L82").value =
    Math.floor(ws.getCell("K82").value / ws.getCell("X3").value) || 0;
  ws.getCell("L83").value =
    Math.floor(ws.getCell("K83").value / ws.getCell("X3").value) || 0;
  ws.getCell("L84").value =
    Math.floor(ws.getCell("K84").value / ws.getCell("X3").value) || 0;
  ws.getCell("L85").value =
    Math.floor(ws.getCell("K85").value / ws.getCell("X3").value) || 0;
  ws.getCell("L86").value =
    Math.floor(ws.getCell("K86").value / ws.getCell("X3").value) || 0;
  ws.getCell("L87").value =
    Math.floor(ws.getCell("K87").value / ws.getCell("X3").value) || 0;
  ws.getCell("L88").value =
    Math.floor(ws.getCell("K88").value / ws.getCell("X3").value) || 0;
  ws.getCell("L89").value =
    Math.floor(ws.getCell("K89").value / ws.getCell("X3").value) || 0;
  ws.getCell("L90").value =
    Math.floor(ws.getCell("K90").value / ws.getCell("X3").value) || 0;
  ws.getCell("L91").value =
    Math.floor(ws.getCell("K91").value / ws.getCell("X3").value) || 0;

  ws.getCell("L73").value =
    Math.floor(ws.getCell("K73").value / ws.getCell("X3").value) || 0;
  ws.getCell("L74").value =
    Math.floor(ws.getCell("K74").value / ws.getCell("X3").value) || 0;
  ws.getCell("L75").value =
    Math.floor(ws.getCell("K75").value / ws.getCell("X3").value) || 0;
  ws.getCell("L76").value =
    Math.floor(ws.getCell("K76").value / ws.getCell("X3").value) || 0;
  ws.getCell("L77").value =
    Math.floor(ws.getCell("K77").value / ws.getCell("X3").value) || 0;
  ws.getCell("L78").value =
    Math.floor(ws.getCell("K78").value / ws.getCell("X3").value) || 0;
  ws.getCell("L79").value =
    Math.floor(ws.getCell("K79").value / ws.getCell("X3").value) || 0;
  ws.getCell("L80").value =
    Math.floor(ws.getCell("K80").value / ws.getCell("X3").value) || 0;
  ws.getCell("L81").value =
    Math.floor(ws.getCell("K81").value / ws.getCell("X3").value) || 0;
  ws.getCell("L82").value =
    Math.floor(ws.getCell("K82").value / ws.getCell("X3").value) || 0;
  ws.getCell("L83").value =
    Math.floor(ws.getCell("K83").value / ws.getCell("X3").value) || 0;
  ws.getCell("L84").value =
    Math.floor(ws.getCell("K84").value / ws.getCell("X3").value) || 0;
  ws.getCell("L85").value =
    Math.floor(ws.getCell("K85").value / ws.getCell("X3").value) || 0;
  ws.getCell("L86").value =
    Math.floor(ws.getCell("K86").value / ws.getCell("X3").value) || 0;
  ws.getCell("L87").value =
    Math.floor(ws.getCell("K87").value / ws.getCell("X3").value) || 0;
  ws.getCell("L88").value =
    Math.floor(ws.getCell("K88").value / ws.getCell("X3").value) || 0;
  ws.getCell("L89").value =
    Math.floor(ws.getCell("K89").value / ws.getCell("X3").value) || 0;

  ws.getCell("M73").value = getFullCounts(allItems, "MFC > MT", "10:35", 80);
  ws.getCell("N73").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "10:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M74").value = getFullCounts(allItems, "MFC > MT", "11:05", 80);
  ws.getCell("N74").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "11:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M75").value = getFullCounts(allItems, "MFC > MT", "11:35", 80);
  ws.getCell("N75").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "11:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M76").value = getFullCounts(allItems, "MFC > MT", "12:05", 80);
  ws.getCell("N76").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "12:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M77").value = getFullCounts(allItems, "MFC > MT", "12:35", 80);
  ws.getCell("N77").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "12:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M78").value = getFullCounts(allItems, "MFC > MT", "13:05", 80);
  ws.getCell("N78").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "13:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M79").value = getFullCounts(allItems, "MFC > MT", "13:45", 80);
  ws.getCell("N79").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "13:45", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M80").value = getFullCounts(allItems, "MFC > MT", "14:05", 80);
  ws.getCell("N80").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "14:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M81").value = getFullCounts(allItems, "MFC > MT", "14:35", 80);
  ws.getCell("N81").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "14:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M82").value = getFullCounts(allItems, "MFC > MT", "15:05", 80);
  ws.getCell("N82").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "15:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M83").value = getFullCounts(allItems, "MFC > MT", "15:35", 80);
  ws.getCell("N83").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "15:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M84").value = getFullCounts(allItems, "MFC > MT", "16:05", 80);
  ws.getCell("N84").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "16:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M85").value = getFullCounts(allItems, "MFC > MT", "16:35", 80);
  ws.getCell("N85").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "16:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M86").value = getFullCounts(allItems, "MFC > MT", "17:05", 80);
  ws.getCell("N86").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "17:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M87").value = getFullCounts(allItems, "MFC > MT", "17:35", 80);
  ws.getCell("N87").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "17:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M88").value = getFullCounts(allItems, "MFC > MT", "18:05", 80);
  ws.getCell("N88").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "18:05", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("M89").value = getFullCounts(allItems, "MFC > MT", "18:35", 80);
  ws.getCell("N89").value =
    (Math.floor(
      (getFullCounts(allItems, "MFC > MT", "18:35", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("O73").value = ws.getCell("M73").value > 10 ? 1 : 0;
  ws.getCell("O74").value = ws.getCell("M74").value > 10 ? 1 : 0;
  ws.getCell("O75").value = ws.getCell("M75").value > 10 ? 1 : 0;
  ws.getCell("O76").value = ws.getCell("M76").value > 10 ? 1 : 0;
  ws.getCell("O77").value = ws.getCell("M77").value > 10 ? 1 : 0;
  ws.getCell("O78").value = ws.getCell("M78").value > 10 ? 1 : 0;
  ws.getCell("O79").value = ws.getCell("M79").value > 10 ? 1 : 0;
  ws.getCell("O80").value = ws.getCell("M80").value > 10 ? 1 : 0;
  ws.getCell("O81").value = ws.getCell("M81").value > 10 ? 1 : 0;
  ws.getCell("O82").value = ws.getCell("M82").value > 10 ? 1 : 0;
  ws.getCell("O83").value = ws.getCell("M83").value > 10 ? 1 : 0;
  ws.getCell("O84").value = ws.getCell("M84").value > 10 ? 1 : 0;
  ws.getCell("O85").value = ws.getCell("M85").value > 10 ? 1 : 0;
  ws.getCell("O86").value = ws.getCell("M86").value > 10 ? 1 : 0;
  ws.getCell("O87").value = ws.getCell("M87").value > 10 ? 1 : 0;
  ws.getCell("O88").value = ws.getCell("M88").value > 10 ? 1 : 0;
  ws.getCell("O89").value = ws.getCell("M89").value > 10 ? 1 : 0;
  ws.getCell("O90").value =
    ws.getCell("O73").value +
    ws.getCell("O74").value +
    ws.getCell("O75").value +
    ws.getCell("O76").value +
    ws.getCell("O77").value +
    ws.getCell("O78").value +
    ws.getCell("O79").value +
    ws.getCell("O80").value +
    ws.getCell("O81").value +
    ws.getCell("O82").value +
    ws.getCell("O83").value +
    ws.getCell("O84").value +
    ws.getCell("O85").value +
    ws.getCell("O86").value +
    ws.getCell("O87").value +
    ws.getCell("O88").value +
    ws.getCell("O89").value;

  ws.getCell("P73").value = getFullCounts(allItems, "MFC > MT", "10:35", 100);
  ws.getCell("Q73").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "10:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P74").value = getFullCounts(allItems, "MFC > MT", "11:05", 100);
  ws.getCell("Q74").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "11:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P75").value = getFullCounts(allItems, "MFC > MT", "11:35", 100);
  ws.getCell("Q75").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "11:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P76").value = getFullCounts(allItems, "MFC > MT", "12:05", 100);
  ws.getCell("Q76").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "12:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P77").value = getFullCounts(allItems, "MFC > MT", "12:35", 100);
  ws.getCell("Q77").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "12:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P78").value = getFullCounts(allItems, "MFC > MT", "13:05", 100);
  ws.getCell("Q78").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "13:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P79").value = getFullCounts(allItems, "MFC > MT", "13:45", 100);
  ws.getCell("Q79").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "13:45", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P80").value = getFullCounts(allItems, "MFC > MT", "14:05", 100);
  ws.getCell("Q80").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "14:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P81").value = getFullCounts(allItems, "MFC > MT", "14:35", 100);
  ws.getCell("Q81").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "14:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P82").value = getFullCounts(allItems, "MFC > MT", "15:05", 100);
  ws.getCell("Q82").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "15:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P83").value = getFullCounts(allItems, "MFC > MT", "15:35", 100);
  ws.getCell("Q83").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "15:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P84").value = getFullCounts(allItems, "MFC > MT", "16:05", 100);
  ws.getCell("Q84").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "16:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P85").value = getFullCounts(allItems, "MFC > MT", "16:35", 100);
  ws.getCell("Q85").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "16:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P86").value = getFullCounts(allItems, "MFC > MT", "17:05", 100);
  ws.getCell("Q86").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "17:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P87").value = getFullCounts(allItems, "MFC > MT", "17:35", 100);
  ws.getCell("Q87").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "17:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P88").value = getFullCounts(allItems, "MFC > MT", "18:05", 100);
  ws.getCell("Q88").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "18:05", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("P89").value = getFullCounts(allItems, "MFC > MT", "18:35", 100);
  ws.getCell("Q89").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > MT", "18:35", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("R73").value = ws.getCell("P73").value > 10 ? 1 : 0;
  ws.getCell("R74").value = ws.getCell("P74").value > 10 ? 1 : 0;
  ws.getCell("R75").value = ws.getCell("P75").value > 10 ? 1 : 0;
  ws.getCell("R76").value = ws.getCell("P76").value > 10 ? 1 : 0;
  ws.getCell("R77").value = ws.getCell("P77").value > 10 ? 1 : 0;
  ws.getCell("R78").value = ws.getCell("P78").value > 10 ? 1 : 0;
  ws.getCell("R79").value = ws.getCell("P79").value > 10 ? 1 : 0;
  ws.getCell("R80").value = ws.getCell("P80").value > 10 ? 1 : 0;
  ws.getCell("R81").value = ws.getCell("P81").value > 10 ? 1 : 0;
  ws.getCell("R82").value = ws.getCell("P82").value > 10 ? 1 : 0;
  ws.getCell("R83").value = ws.getCell("P83").value > 10 ? 1 : 0;
  ws.getCell("R84").value = ws.getCell("P84").value > 10 ? 1 : 0;
  ws.getCell("R85").value = ws.getCell("P85").value > 10 ? 1 : 0;
  ws.getCell("R86").value = ws.getCell("P86").value > 10 ? 1 : 0;
  ws.getCell("R87").value = ws.getCell("P87").value > 10 ? 1 : 0;
  ws.getCell("R88").value = ws.getCell("P88").value > 10 ? 1 : 0;
  ws.getCell("R89").value = ws.getCell("P89").value > 10 ? 1 : 0;
  ws.getCell("R90").value =
    ws.getCell("R73").value +
    ws.getCell("R74").value +
    ws.getCell("R75").value +
    ws.getCell("R76").value +
    ws.getCell("R77").value +
    ws.getCell("R78").value +
    ws.getCell("R79").value +
    ws.getCell("R80").value +
    ws.getCell("R81").value +
    ws.getCell("R82").value +
    ws.getCell("R83").value +
    ws.getCell("R84").value +
    ws.getCell("R85").value +
    ws.getCell("R86").value +
    ws.getCell("R87").value +
    ws.getCell("R88").value +
    ws.getCell("R89").value;

  ws.getCell("S73").value =
    (getDelayCount(allItems, "LG > MFC", "10:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "10:35") || 0) || 0;
  ws.getCell("T73").value =
    Math.floor((ws.getCell("S73").value / totalDay) * 100) + "%";

  ws.getCell("S74").value =
    (getDelayCount(allItems, "LG > MFC", "10:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "11:05") || 0) || 0;
  ws.getCell("T74").value =
    Math.floor((ws.getCell("S74").value / totalDay) * 100) + "%";

  ws.getCell("S75").value =
    (getDelayCount(allItems, "LG > MFC", "11:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "11:35") || 0) || 0;
  ws.getCell("T75").value =
    Math.floor((ws.getCell("S75").value / totalDay) * 100) + "%";

  ws.getCell("S76").value =
    (getDelayCount(allItems, "LG > MFC", "11:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "12:05") || 0) || 0;
  ws.getCell("T76").value =
    Math.floor((ws.getCell("S76").value / totalDay) * 100) + "%";

  ws.getCell("S77").value =
    (getDelayCount(allItems, "LG > MFC", "12:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "12:35") || 0) || 0;
  ws.getCell("T77").value =
    Math.floor((ws.getCell("S77").value / totalDay) * 100) + "%";

  ws.getCell("S78").value =
    (getDelayCount(allItems, "LG > MFC", "12:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "13:05") || 0) || 0;
  ws.getCell("T78").value =
    Math.floor((ws.getCell("S78").value / totalDay) * 100) + "%";

  ws.getCell("S79").value = getDelayCount(allItems, "MFC > MT", "13:45") || 0;
  ws.getCell("T79").value =
    Math.floor((ws.getCell("S79").value / totalDay) * 100) + "%";

  ws.getCell("S80").value =
    (getDelayCount(allItems, "LG > MFC", "13:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "14:05") || 0) || 0;
  ws.getCell("T80").value =
    Math.floor((ws.getCell("S80").value / totalDay) * 100) + "%";

  ws.getCell("S81").value =
    (getDelayCount(allItems, "LG > MFC", "14:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "14:35") || 0) || 0;
  ws.getCell("T81").value =
    Math.floor((ws.getCell("S81").value / totalDay) * 100) + "%";

  ws.getCell("S82").value =
    (getDelayCount(allItems, "LG > MFC", "14:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "15:05") || 0) || 0;
  ws.getCell("T82").value =
    Math.floor((ws.getCell("S82").value / totalDay) * 100) + "%";

  ws.getCell("S83").value =
    (getDelayCount(allItems, "LG > MFC", "15:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "15:35") || 0) || 0;
  ws.getCell("T83").value =
    Math.floor((ws.getCell("S83").value / totalDay) * 100) + "%";

  ws.getCell("S84").value =
    (getDelayCount(allItems, "LG > MFC", "15:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "16:05") || 0) || 0;
  ws.getCell("T84").value =
    Math.floor((ws.getCell("S84").value / totalDay) * 100) + "%";

  ws.getCell("S85").value =
    (getDelayCount(allItems, "LG > MFC", "16:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "16:35") || 0) || 0;
  ws.getCell("T85").value =
    Math.floor((ws.getCell("S85").value / totalDay) * 100) + "%";

  ws.getCell("S86").value =
    (getDelayCount(allItems, "LG > MFC", "16:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "17:05") || 0) || 0;
  ws.getCell("T86").value =
    Math.floor((ws.getCell("S86").value / totalDay) * 100) + "%";

  ws.getCell("S87").value =
    (getDelayCount(allItems, "LG > MFC", "17:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "17:35") || 0) || 0;
  ws.getCell("T87").value =
    Math.floor((ws.getCell("S87").value / totalDay) * 100) + "%";

  ws.getCell("S88").value =
    (getDelayCount(allItems, "LG > MFC", "17:45") || 0) +
      (getDelayCount(allItems, "MFC > MT", "18:05") || 0) || 0;
  ws.getCell("T88").value =
    Math.floor((ws.getCell("S88").value / totalDay) * 100) + "%";

  ws.getCell("S89").value =
    (getDelayCount(allItems, "LG > MFC", "18:15") || 0) +
      (getDelayCount(allItems, "MFC > MT", "18:35") || 0) || 0;
  ws.getCell("T89").value =
    Math.floor((ws.getCell("S89").value / totalDay) * 100) + "%";

  ws.getCell("S90").value =
    ws.getCell("S73").value +
    ws.getCell("S74").value +
    ws.getCell("S75").value +
    ws.getCell("S76").value +
    ws.getCell("S77").value +
    ws.getCell("S78").value +
    ws.getCell("S79").value +
    ws.getCell("S80").value +
    ws.getCell("S81").value +
    ws.getCell("S82").value +
    ws.getCell("S83").value +
    ws.getCell("S84").value +
    ws.getCell("S85").value +
    ws.getCell("S86").value +
    ws.getCell("S87").value +
    ws.getCell("S88").value +
    ws.getCell("S89").value;

  ws.getCell("U73").value =
    (getStatusCounts(allItems, "LG > MFC", "10:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "10:35", "CANCEL") || 0) || 0;
  ws.getCell("V73").value =
    Math.floor((ws.getCell("U73").value / totalDay) * 100) + "%";

  ws.getCell("U74").value =
    (getStatusCounts(allItems, "LG > MFC", "10:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "11:05", "CANCEL") || 0) || 0;
  ws.getCell("V74").value =
    Math.floor((ws.getCell("U74").value / totalDay) * 100) + "%";

  ws.getCell("U75").value =
    (getStatusCounts(allItems, "LG > MFC", "11:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "11:35", "CANCEL") || 0) || 0;
  ws.getCell("V75").value =
    Math.floor((ws.getCell("U75").value / totalDay) * 100) + "%";

  ws.getCell("U76").value =
    (getStatusCounts(allItems, "LG > MFC", "11:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "12:05", "CANCEL") || 0) || 0;
  ws.getCell("V76").value =
    Math.floor((ws.getCell("U76").value / totalDay) * 100) + "%";

  ws.getCell("U77").value =
    (getStatusCounts(allItems, "LG > MFC", "12:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "12:35", "CANCEL") || 0) || 0;
  ws.getCell("V77").value =
    Math.floor((ws.getCell("U77").value / totalDay) * 100) + "%";

  ws.getCell("U78").value =
    (getStatusCounts(allItems, "LG > MFC", "12:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "13:05", "CANCEL") || 0) || 0;
  ws.getCell("V78").value =
    Math.floor((ws.getCell("U78").value / totalDay) * 100) + "%";

  ws.getCell("U79").value =
    getStatusCounts(allItems, "MFC > MT", "13:45", "CANCEL") || 0;
  ws.getCell("V79").value =
    Math.floor((ws.getCell("U79").value / totalDay) * 100) + "%";

  ws.getCell("U80").value =
    (getStatusCounts(allItems, "LG > MFC", "13:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "14:05", "CANCEL") || 0) || 0;
  ws.getCell("V80").value =
    Math.floor((ws.getCell("U80").value / totalDay) * 100) + "%";

  ws.getCell("U81").value =
    (getStatusCounts(allItems, "LG > MFC", "14:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "14:35", "CANCEL") || 0) || 0;
  ws.getCell("V81").value =
    Math.floor((ws.getCell("U81").value / totalDay) * 100) + "%";

  ws.getCell("U82").value =
    (getStatusCounts(allItems, "LG > MFC", "14:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "15:05", "CANCEL") || 0) || 0;
  ws.getCell("V82").value =
    Math.floor((ws.getCell("U82").value / totalDay) * 100) + "%";

  ws.getCell("U83").value =
    (getStatusCounts(allItems, "LG > MFC", "15:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "15:35", "CANCEL") || 0) || 0;
  ws.getCell("V83").value =
    Math.floor((ws.getCell("U83").value / totalDay) * 100) + "%";

  ws.getCell("U84").value =
    (getStatusCounts(allItems, "LG > MFC", "15:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "16:05", "CANCEL") || 0) || 0;
  ws.getCell("V84").value =
    Math.floor((ws.getCell("U84").value / totalDay) * 100) + "%";

  ws.getCell("U85").value =
    (getStatusCounts(allItems, "LG > MFC", "16:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "16:35", "CANCEL") || 0) || 0;
  ws.getCell("V85").value =
    Math.floor((ws.getCell("U85").value / totalDay) * 100) + "%";

  ws.getCell("U86").value =
    (getStatusCounts(allItems, "LG > MFC", "16:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "17:05", "CANCEL") || 0) || 0;
  ws.getCell("V86").value =
    Math.floor((ws.getCell("U86").value / totalDay) * 100) + "%";

  ws.getCell("U87").value =
    (getStatusCounts(allItems, "LG > MFC", "17:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "17:35", "CANCEL") || 0) || 0;
  ws.getCell("V87").value =
    Math.floor((ws.getCell("U87").value / totalDay) * 100) + "%";

  ws.getCell("U88").value =
    (getStatusCounts(allItems, "LG > MFC", "17:45", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "18:05", "CANCEL") || 0) || 0;
  ws.getCell("V88").value =
    Math.floor((ws.getCell("U88").value / totalDay) * 100) + "%";

  ws.getCell("U89").value =
    (getStatusCounts(allItems, "LG > MFC", "18:15", "CANCEL") || 0) +
      (getStatusCounts(allItems, "MFC > MT", "18:35", "CANCEL") || 0) || 0;
  ws.getCell("V89").value =
    Math.floor((ws.getCell("U89").value / totalDay) * 100) + "%";

  ws.getCell("U90").value =
    ws.getCell("U73").value +
    ws.getCell("U74").value +
    ws.getCell("U75").value +
    ws.getCell("U76").value +
    ws.getCell("U77").value +
    ws.getCell("U78").value +
    ws.getCell("U79").value +
    ws.getCell("U80").value +
    ws.getCell("U81").value +
    ws.getCell("U82").value +
    ws.getCell("U83").value +
    ws.getCell("U84").value +
    ws.getCell("U85").value +
    ws.getCell("U86").value +
    ws.getCell("U87").value +
    ws.getCell("U88").value +
    ws.getCell("U89").value;

  ws.getCell("C96").value = getOnBoradCount(allItems, "MT > MFC", "10:00");
  ws.getCell("C97").value = getOnBoradCount(allItems, "MT > MFC", "10:40");
  ws.getCell("C98").value = getOnBoradCount(allItems, "MT > MFC", "11:10");
  ws.getCell("C99").value = getOnBoradCount(allItems, "MT > MFC", "11:40");
  ws.getCell("C100").value = getOnBoradCount(allItems, "MT > MFC", "12:10");
  ws.getCell("C101").value = getOnBoradCount(allItems, "MT > MFC", "12:40");
  ws.getCell("C102").value = getOnBoradCount(allItems, "MT > MFC", "14:10");
  ws.getCell("C103").value = getOnBoradCount(allItems, "MT > MFC", "14:40");
  ws.getCell("C104").value = getOnBoradCount(allItems, "MT > MFC", "15:10");
  ws.getCell("C105").value = getOnBoradCount(allItems, "MT > MFC", "15:40");
  ws.getCell("C106").value = getOnBoradCount(allItems, "MT > MFC", "16:10");
  ws.getCell("C107").value = getOnBoradCount(allItems, "MT > MFC", "16:40");
  ws.getCell("C108").value = getOnBoradCount(allItems, "MT > MFC", "17:10");
  ws.getCell("C109").value = getOnBoradCount(allItems, "MT > MFC", "17:40");
  ws.getCell("C110").value = getOnBoradCount(allItems, "MT > MFC", "18:10");
  ws.getCell("C111").value =
    ws.getCell("C96").value +
    ws.getCell("C97").value +
    ws.getCell("C98").value +
    ws.getCell("C99").value +
    ws.getCell("C100").value +
    ws.getCell("C101").value +
    ws.getCell("C102").value +
    ws.getCell("C103").value +
    ws.getCell("C104").value +
    ws.getCell("C105").value +
    ws.getCell("C106").value +
    ws.getCell("C107").value +
    ws.getCell("C108").value +
    ws.getCell("C109").value +
    ws.getCell("C110").value;
  ws.getCell("C112").value = Math.floor(ws.getCell("C111").value / 15) || 0;

  ws.getCell("D96").value =
    Math.floor(ws.getCell("C96").value / ws.getCell("X3").value) || 0;
  ws.getCell("D97").value =
    Math.floor(ws.getCell("C97").value / ws.getCell("X3").value) || 0;
  ws.getCell("D98").value =
    Math.floor(ws.getCell("C98").value / ws.getCell("X3").value) || 0;
  ws.getCell("D99").value =
    Math.floor(ws.getCell("C99").value / ws.getCell("X3").value) || 0;
  ws.getCell("D100").value =
    Math.floor(ws.getCell("C100").value / ws.getCell("X3").value) || 0;
  ws.getCell("D101").value =
    Math.floor(ws.getCell("C101").value / ws.getCell("X3").value) || 0;
  ws.getCell("D102").value =
    Math.floor(ws.getCell("C102").value / ws.getCell("X3").value) || 0;
  ws.getCell("D103").value =
    Math.floor(ws.getCell("C103").value / ws.getCell("X3").value) || 0;
  ws.getCell("D104").value =
    Math.floor(ws.getCell("C104").value / ws.getCell("X3").value) || 0;
  ws.getCell("D105").value =
    Math.floor(ws.getCell("C105").value / ws.getCell("X3").value) || 0;
  ws.getCell("D106").value =
    Math.floor(ws.getCell("C106").value / ws.getCell("X3").value) || 0;
  ws.getCell("D107").value =
    Math.floor(ws.getCell("C107").value / ws.getCell("X3").value) || 0;
  ws.getCell("D108").value =
    Math.floor(ws.getCell("C108").value / ws.getCell("X3").value) || 0;
  ws.getCell("D109").value =
    Math.floor(ws.getCell("C109").value / ws.getCell("X3").value) || 0;
  ws.getCell("D110").value =
    Math.floor(ws.getCell("C110").value / ws.getCell("X3").value) || 0;
  ws.getCell("D111").value =
    Math.floor(ws.getCell("C111").value / ws.getCell("X3").value) || 0;
  ws.getCell("D112").value =
    Math.floor(ws.getCell("C112").value / ws.getCell("X3").value) || 0;

  ws.getCell("E96").value = getOnFullCounts(allItems, "MT > MFC", "10:10", 80);
  ws.getCell("F96").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E97").value = getOnFullCounts(allItems, "MT > MFC", "10:40", 80);
  ws.getCell("F97").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E98").value = getOnFullCounts(allItems, "MT > MFC", "11:10", 80);
  ws.getCell("F98").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E99").value = getOnFullCounts(allItems, "MT > MFC", "11:40", 80);
  ws.getCell("F99").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E100").value = getOnFullCounts(allItems, "MT > MFC", "12:10", 80);
  ws.getCell("F100").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "12:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E101").value = getOnFullCounts(allItems, "MT > MFC", "12:40", 80);
  ws.getCell("F101").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "12:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E102").value = getOnFullCounts(allItems, "MT > MFC", "14:10", 80);
  ws.getCell("F102").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "14:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E103").value = getOnFullCounts(allItems, "MT > MFC", "14:40", 80);
  ws.getCell("F103").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "14:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E104").value = getOnFullCounts(allItems, "MT > MFC", "15:10", 80);
  ws.getCell("F104").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E105").value = getOnFullCounts(allItems, "MT > MFC", "15:40", 80);
  ws.getCell("F105").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E106").value = getOnFullCounts(allItems, "MT > MFC", "16:10", 80);
  ws.getCell("F106").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E107").value = getOnFullCounts(allItems, "MT > MFC", "16:40", 80);
  ws.getCell("F107").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E108").value = getOnFullCounts(allItems, "MT > MFC", "17:10", 80);
  ws.getCell("F108").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E109").value = getOnFullCounts(allItems, "MT > MFC", "17:40", 80);
  ws.getCell("F109").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:40", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E110").value = getOnFullCounts(allItems, "MT > MFC", "18:10", 80);
  ws.getCell("F110").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "18:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G96").value = ws.getCell("E96").value > 10 ? 1 : 0;
  ws.getCell("G97").value = ws.getCell("E97").value > 10 ? 1 : 0;
  ws.getCell("G98").value = ws.getCell("E98").value > 10 ? 1 : 0;
  ws.getCell("G99").value = ws.getCell("E99").value > 10 ? 1 : 0;
  ws.getCell("G100").value = ws.getCell("E100").value > 10 ? 1 : 0;
  ws.getCell("G101").value = ws.getCell("E101").value > 10 ? 1 : 0;
  ws.getCell("G102").value = ws.getCell("E102").value > 10 ? 1 : 0;
  ws.getCell("G103").value = ws.getCell("E103").value > 10 ? 1 : 0;
  ws.getCell("G104").value = ws.getCell("E104").value > 10 ? 1 : 0;
  ws.getCell("G105").value = ws.getCell("E105").value > 10 ? 1 : 0;
  ws.getCell("G106").value = ws.getCell("E106").value > 10 ? 1 : 0;
  ws.getCell("G107").value = ws.getCell("E107").value > 10 ? 1 : 0;
  ws.getCell("G108").value = ws.getCell("E108").value > 10 ? 1 : 0;
  ws.getCell("G109").value = ws.getCell("E109").value > 10 ? 1 : 0;
  ws.getCell("G110").value = ws.getCell("E110").value > 10 ? 1 : 0;
  ws.getCell("G111").value =
    ws.getCell("G96").value +
    ws.getCell("G97").value +
    ws.getCell("G98").value +
    ws.getCell("G99").value +
    ws.getCell("G100").value +
    ws.getCell("G101").value +
    ws.getCell("G102").value +
    ws.getCell("G103").value +
    ws.getCell("G104").value +
    ws.getCell("G105").value +
    ws.getCell("G106").value +
    ws.getCell("G107").value +
    ws.getCell("G108").value +
    ws.getCell("G109").value +
    ws.getCell("G110").value;

  ws.getCell("H96").value = getOnFullCounts(allItems, "MT > MFC", "10:00", 100);
  ws.getCell("I96").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:00", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H97").value = getOnFullCounts(allItems, "MT > MFC", "10:40", 100);
  ws.getCell("I97").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "10:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H98").value = getOnFullCounts(allItems, "MT > MFC", "11:10", 100);
  ws.getCell("I98").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H99").value = getOnFullCounts(allItems, "MT > MFC", "11:40", 100);
  ws.getCell("I99").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "11:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H100").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "12:10",
    100
  );
  ws.getCell("I100").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "12:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H101").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "12:40",
    100
  );
  ws.getCell("I101").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "12:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H102").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "14:10",
    100
  );
  ws.getCell("I102").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "14:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H103").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "14:40",
    100
  );
  ws.getCell("I103").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "14:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H104").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "15:10",
    100
  );
  ws.getCell("I104").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H105").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "15:40",
    100
  );
  ws.getCell("I105").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "15:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H106").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "16:10",
    100
  );
  ws.getCell("I106").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H107").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "16:40",
    100
  );
  ws.getCell("I107").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "16:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H108").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "17:10",
    100
  );
  ws.getCell("I108").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H109").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "17:40",
    100
  );
  ws.getCell("I109").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "17:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("H110").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "18:10",
    100
  );
  ws.getCell("I110").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "18:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("J96").value = ws.getCell("H96").value > 10 ? 1 : 0;
  ws.getCell("J97").value = ws.getCell("H97").value > 10 ? 1 : 0;
  ws.getCell("J98").value = ws.getCell("H98").value > 10 ? 1 : 0;
  ws.getCell("J99").value = ws.getCell("H99").value > 10 ? 1 : 0;
  ws.getCell("J100").value = ws.getCell("H100").value > 10 ? 1 : 0;
  ws.getCell("J101").value = ws.getCell("H101").value > 10 ? 1 : 0;
  ws.getCell("J102").value = ws.getCell("H102").value > 10 ? 1 : 0;
  ws.getCell("J103").value = ws.getCell("H103").value > 10 ? 1 : 0;
  ws.getCell("J104").value = ws.getCell("H104").value > 10 ? 1 : 0;
  ws.getCell("J105").value = ws.getCell("H105").value > 10 ? 1 : 0;
  ws.getCell("J106").value = ws.getCell("H106").value > 10 ? 1 : 0;
  ws.getCell("J107").value = ws.getCell("H107").value > 10 ? 1 : 0;
  ws.getCell("J108").value = ws.getCell("H108").value > 10 ? 1 : 0;
  ws.getCell("J109").value = ws.getCell("H109").value > 10 ? 1 : 0;
  ws.getCell("J110").value = ws.getCell("H110").value > 10 ? 1 : 0;
  ws.getCell("J111").value =
    ws.getCell("J96").value +
    ws.getCell("J97").value +
    ws.getCell("J98").value +
    ws.getCell("J99").value +
    ws.getCell("J100").value +
    ws.getCell("J101").value +
    ws.getCell("J102").value +
    ws.getCell("J103").value +
    ws.getCell("J104").value +
    ws.getCell("J105").value +
    ws.getCell("J106").value +
    ws.getCell("J107").value +
    ws.getCell("J108").value +
    ws.getCell("J109").value +
    ws.getCell("J110").value;

  ws.getCell("K96").value = getDelayCount(allItems, "MT > MFC", "10:10") || 0;
  ws.getCell("L96").value =
    Math.floor((ws.getCell("S96").value / totalDay) * 100) + "%";

  ws.getCell("K97").value = getDelayCount(allItems, "MT > MFC", "10:40") || 0;
  ws.getCell("L97").value =
    Math.floor((ws.getCell("S97").value / totalDay) * 100) + "%";

  ws.getCell("K98").value = getDelayCount(allItems, "MT > MFC", "11:10") || 0;
  ws.getCell("L98").value =
    Math.floor((ws.getCell("S98").value / totalDay) * 100) + "%";

  ws.getCell("K99").value = getDelayCount(allItems, "MT > MFC", "11:40") || 0;
  ws.getCell("L99").value =
    Math.floor((ws.getCell("S99").value / totalDay) * 100) + "%";

  ws.getCell("K100").value = getDelayCount(allItems, "MT > MFC", "12:10") || 0;
  ws.getCell("L100").value =
    Math.floor((ws.getCell("S100").value / totalDay) * 100) + "%";

  ws.getCell("K101").value = getDelayCount(allItems, "MT > MFC", "12:40") || 0;
  ws.getCell("L101").value =
    Math.floor((ws.getCell("S101").value / totalDay) * 100) + "%";

  ws.getCell("K102").value = getDelayCount(allItems, "MT > MFC", "14:10") || 0;
  ws.getCell("L102").value =
    Math.floor((ws.getCell("S102").value / totalDay) * 100) + "%";

  ws.getCell("K103").value = getDelayCount(allItems, "MT > MFC", "14:40") || 0;
  ws.getCell("L103").value =
    Math.floor((ws.getCell("S103").value / totalDay) * 100) + "%";

  ws.getCell("K104").value = getDelayCount(allItems, "MT > MFC", "15:10") || 0;
  ws.getCell("L104").value =
    Math.floor((ws.getCell("S104").value / totalDay) * 100) + "%";

  ws.getCell("K105").value = getDelayCount(allItems, "MT > MFC", "15:40") || 0;
  ws.getCell("L105").value =
    Math.floor((ws.getCell("S105").value / totalDay) * 100) + "%";

  ws.getCell("K106").value = getDelayCount(allItems, "MT > MFC", "16:10") || 0;
  ws.getCell("L106").value =
    Math.floor((ws.getCell("S106").value / totalDay) * 100) + "%";

  ws.getCell("K107").value = getDelayCount(allItems, "MT > MFC", "16:40") || 0;
  ws.getCell("L107").value =
    Math.floor((ws.getCell("S107").value / totalDay) * 100) + "%";

  ws.getCell("K108").value = getDelayCount(allItems, "MT > MFC", "17:10") || 0;
  ws.getCell("L108").value =
    Math.floor((ws.getCell("S108").value / totalDay) * 100) + "%";

  ws.getCell("K109").value = getDelayCount(allItems, "MT > MFC", "17:40") || 0;
  ws.getCell("L109").value =
    Math.floor((ws.getCell("S109").value / totalDay) * 100) + "%";

  ws.getCell("K110").value = getDelayCount(allItems, "MT > MFC", "18:10") || 0;
  ws.getCell("L110").value =
    Math.floor((ws.getCell("S110").value / totalDay) * 100) + "%";

  ws.getCell("K111").value =
    ws.getCell("K96").value +
    ws.getCell("K197").value +
    ws.getCell("K98").value +
    ws.getCell("K99").value +
    ws.getCell("K100").value +
    ws.getCell("K101").value +
    ws.getCell("K102").value +
    ws.getCell("K103").value +
    ws.getCell("K104").value +
    ws.getCell("K105").value +
    ws.getCell("K106").value +
    ws.getCell("K107").value +
    ws.getCell("K108").value +
    ws.getCell("K109").value +
    ws.getCell("K110").value;

  ws.getCell("M96").value =
    getStatusCounts(allItems, "MT > MFC", "10:10", "CANCEL") || 0;
  ws.getCell("N96").value =
    Math.floor((ws.getCell("M96").value / totalDay) * 100) + "%";

  ws.getCell("M97").value =
    getStatusCounts(allItems, "MT > MFC", "10:40", "CANCEL") || 0;
  ws.getCell("N97").value =
    Math.floor((ws.getCell("M97").value / totalDay) * 100) + "%";

  ws.getCell("M98").value =
    getStatusCounts(allItems, "MT > MFC", "11:10", "CANCEL") || 0;
  ws.getCell("N98").value =
    Math.floor((ws.getCell("M98").value / totalDay) * 100) + "%";

  ws.getCell("M99").value =
    getStatusCounts(allItems, "MT > MFC", "11:40", "CANCEL") || 0;
  ws.getCell("N99").value =
    Math.floor((ws.getCell("M99").value / totalDay) * 100) + "%";

  ws.getCell("M100").value =
    getStatusCounts(allItems, "MT > MFC", "12:10", "CANCEL") || 0;
  ws.getCell("N100").value =
    Math.floor((ws.getCell("M100").value / totalDay) * 100) + "%";

  ws.getCell("M101").value =
    getStatusCounts(allItems, "MT > MFC", "12:40", "CANCEL") || 0;
  ws.getCell("N101").value =
    Math.floor((ws.getCell("M101").value / totalDay) * 100) + "%";

  ws.getCell("M102").value =
    getStatusCounts(allItems, "MT > MFC", "14:10", "CANCEL") || 0;
  ws.getCell("N102").value =
    Math.floor((ws.getCell("M102").value / totalDay) * 100) + "%";

  ws.getCell("M103").value =
    getStatusCounts(allItems, "MT > MFC", "14:40", "CANCEL") || 0;
  ws.getCell("N103").value =
    Math.floor((ws.getCell("M103").value / totalDay) * 100) + "%";

  ws.getCell("M104").value =
    getStatusCounts(allItems, "MT > MFC", "15:10", "CANCEL") || 0;
  ws.getCell("N104").value =
    Math.floor((ws.getCell("M104").value / totalDay) * 100) + "%";

  ws.getCell("M105").value =
    getStatusCounts(allItems, "MT > MFC", "15:40", "CANCEL") || 0;
  ws.getCell("N105").value =
    Math.floor((ws.getCell("M105").value / totalDay) * 100) + "%";

  ws.getCell("M106").value =
    getStatusCounts(allItems, "MT > MFC", "16:10", "CANCEL") || 0;
  ws.getCell("N106").value =
    Math.floor((ws.getCell("M106").value / totalDay) * 100) + "%";

  ws.getCell("M107").value =
    getStatusCounts(allItems, "MT > MFC", "16:40", "CANCEL") || 0;
  ws.getCell("N107").value =
    Math.floor((ws.getCell("M107").value / totalDay) * 100) + "%";

  ws.getCell("M108").value =
    getStatusCounts(allItems, "MT > MFC", "17:10", "CANCEL") || 0;
  ws.getCell("N108").value =
    Math.floor((ws.getCell("M108").value / totalDay) * 100) + "%";

  ws.getCell("M109").value =
    getStatusCounts(allItems, "MT > MFC", "17:40", "CANCEL") || 0;
  ws.getCell("N109").value =
    Math.floor((ws.getCell("M109").value / totalDay) * 100) + "%";

  ws.getCell("M110").value =
    getStatusCounts(allItems, "MT > MFC", "18:10", "CANCEL") || 0;
  ws.getCell("N110").value =
    Math.floor((ws.getCell("M110").value / totalDay) * 100) + "%";

  ws.getCell("M111").value =
    ws.getCell("M96").value +
    ws.getCell("M197").value +
    ws.getCell("M98").value +
    ws.getCell("M99").value +
    ws.getCell("M100").value +
    ws.getCell("M101").value +
    ws.getCell("M102").value +
    ws.getCell("M103").value +
    ws.getCell("M104").value +
    ws.getCell("M105").value +
    ws.getCell("M106").value +
    ws.getCell("M107").value +
    ws.getCell("M108").value +
    ws.getCell("M109").value +
    ws.getCell("M110").value;

  ws.getCell("C117").value = getOnBoradCount(allItems, "MFC > MT", "10:00");
  ws.getCell("C118").value = getOnBoradCount(allItems, "MFC > MT", "10:25");
  ws.getCell("C119").value = getOnBoradCount(allItems, "MFC > MT", "10:55");
  ws.getCell("C120").value = getOnBoradCount(allItems, "MFC > MT", "11:25");
  ws.getCell("C121").value = getOnBoradCount(allItems, "MFC > MT", "11:55");
  ws.getCell("C122").value = getOnBoradCount(allItems, "MFC > MT", "12:25");
  ws.getCell("C123").value = getOnBoradCount(allItems, "MFC > MT", "12:55");
  ws.getCell("C124").value = getOnBoradCount(allItems, "MFC > MT", "14:25");
  ws.getCell("C125").value = getOnBoradCount(allItems, "MFC > MT", "14:55");
  ws.getCell("C126").value = getOnBoradCount(allItems, "MFC > MT", "15:25");
  ws.getCell("C127").value = getOnBoradCount(allItems, "MFC > MT", "15:55");
  ws.getCell("C128").value = getOnBoradCount(allItems, "MFC > MT", "16:25");
  ws.getCell("C129").value = getOnBoradCount(allItems, "MFC > MT", "16:55");
  ws.getCell("C130").value = getOnBoradCount(allItems, "MFC > MT", "17:25");
  ws.getCell("C131").value = getOnBoradCount(allItems, "MFC > MT", "17:55");
  ws.getCell("C132").value = getOnBoradCount(allItems, "MFC > MT", "18:25");
  ws.getCell("C133").value =
    ws.getCell("C117").value +
    ws.getCell("C118").value +
    ws.getCell("C119").value +
    ws.getCell("C120").value +
    ws.getCell("C121").value +
    ws.getCell("C122").value +
    ws.getCell("C123").value +
    ws.getCell("C124").value +
    ws.getCell("C125").value +
    ws.getCell("C126").value +
    ws.getCell("C127").value +
    ws.getCell("C128").value +
    ws.getCell("C129").value +
    ws.getCell("C130").value +
    ws.getCell("C131").value +
    ws.getCell("C132").value;
  ws.getCell("C134").value = Math.floor(ws.getCell("C133").value / 16) || 0;

  ws.getCell("D117").value =
    Math.floor(ws.getCell("C117").value / ws.getCell("X3").value) || 0;
  ws.getCell("D118").value =
    Math.floor(ws.getCell("C118").value / ws.getCell("X3").value) || 0;
  ws.getCell("D119").value =
    Math.floor(ws.getCell("C119").value / ws.getCell("X3").value) || 0;
  ws.getCell("D120").value =
    Math.floor(ws.getCell("C120").value / ws.getCell("X3").value) || 0;
  ws.getCell("D121").value =
    Math.floor(ws.getCell("C121").value / ws.getCell("X3").value) || 0;
  ws.getCell("D122").value =
    Math.floor(ws.getCell("C122").value / ws.getCell("X3").value) || 0;
  ws.getCell("D123").value =
    Math.floor(ws.getCell("C123").value / ws.getCell("X3").value) || 0;
  ws.getCell("D124").value =
    Math.floor(ws.getCell("C124").value / ws.getCell("X3").value) || 0;
  ws.getCell("D125").value =
    Math.floor(ws.getCell("C125").value / ws.getCell("X3").value) || 0;
  ws.getCell("D126").value =
    Math.floor(ws.getCell("C126").value / ws.getCell("X3").value) || 0;
  ws.getCell("D127").value =
    Math.floor(ws.getCell("C127").value / ws.getCell("X3").value) || 0;
  ws.getCell("D128").value =
    Math.floor(ws.getCell("C128").value / ws.getCell("X3").value) || 0;
  ws.getCell("D129").value =
    Math.floor(ws.getCell("C129").value / ws.getCell("X3").value) || 0;
  ws.getCell("D130").value =
    Math.floor(ws.getCell("C130").value / ws.getCell("X3").value) || 0;
  ws.getCell("D131").value =
    Math.floor(ws.getCell("C131").value / ws.getCell("X3").value) || 0;
  ws.getCell("D132").value =
    Math.floor(ws.getCell("C132").value / ws.getCell("X3").value) || 0;
  ws.getCell("D133").value =
    Math.floor(ws.getCell("C133").value / ws.getCell("X3").value) || 0;
  ws.getCell("D134").value =
    Math.floor(ws.getCell("C134").value / ws.getCell("X3").value) || 0;

  ws.getCell("E117").value = getOnFullCounts(allItems, "MFC > MT", "10:00", 80);
  ws.getCell("F117").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "10:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E118").value = getOnFullCounts(allItems, "MFC > MT", "10:25", 80);
  ws.getCell("F118").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "10:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E119").value = getOnFullCounts(allItems, "MFC > MT", "10:55", 80);
  ws.getCell("F119").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "10:55", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E120").value = getOnFullCounts(allItems, "MFC > MT", "11:25", 80);
  ws.getCell("F120").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "11:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E121").value = getOnFullCounts(allItems, "MFC > MT", "11:55", 80);
  ws.getCell("F121").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "11:55", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E122").value = getOnFullCounts(allItems, "MFC > MT", "12:25", 80);
  ws.getCell("F122").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "12:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E123").value = getOnFullCounts(allItems, "MFC > MT", "12:55", 80);
  ws.getCell("F123").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "12:55", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E124").value = getOnFullCounts(allItems, "MFC > MT", "14:25", 80);
  ws.getCell("F124").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "14:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E125").value = getOnFullCounts(allItems, "MFC > MT", "14:55", 80);
  ws.getCell("F125").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "14:55", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E126").value = getOnFullCounts(allItems, "MFC > MT", "15:25", 80);
  ws.getCell("F126").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "15:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E127").value = getOnFullCounts(allItems, "MFC > MT", "15:55", 80);
  ws.getCell("F127").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "15:55", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E128").value = getOnFullCounts(allItems, "MFC > MT", "16:25", 80);
  ws.getCell("F128").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "16:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E129").value = getOnFullCounts(allItems, "MFC > MT", "16:55", 80);
  ws.getCell("F129").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "16:55", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E130").value = getOnFullCounts(allItems, "MFC > MT", "17:25", 80);
  ws.getCell("F130").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "17:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E131").value = getOnFullCounts(allItems, "MFC > MT", "17:55", 80);
  ws.getCell("F131").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "17:55", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E132").value = getOnFullCounts(allItems, "MFC > MT", "18:25", 80);
  ws.getCell("F132").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "18:25", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G117").value = ws.getCell("E117").value > 10 ? 1 : 0;
  ws.getCell("G118").value = ws.getCell("E118").value > 10 ? 1 : 0;
  ws.getCell("G119").value = ws.getCell("E119").value > 10 ? 1 : 0;
  ws.getCell("G120").value = ws.getCell("E120").value > 10 ? 1 : 0;
  ws.getCell("G121").value = ws.getCell("E121").value > 10 ? 1 : 0;
  ws.getCell("G122").value = ws.getCell("E122").value > 10 ? 1 : 0;
  ws.getCell("G123").value = ws.getCell("E123").value > 10 ? 1 : 0;
  ws.getCell("G124").value = ws.getCell("E124").value > 10 ? 1 : 0;
  ws.getCell("G125").value = ws.getCell("E125").value > 10 ? 1 : 0;
  ws.getCell("G126").value = ws.getCell("E126").value > 10 ? 1 : 0;
  ws.getCell("G127").value = ws.getCell("E127").value > 10 ? 1 : 0;
  ws.getCell("G128").value = ws.getCell("E128").value > 10 ? 1 : 0;
  ws.getCell("G129").value = ws.getCell("E129").value > 10 ? 1 : 0;
  ws.getCell("G130").value = ws.getCell("E130").value > 10 ? 1 : 0;
  ws.getCell("G131").value = ws.getCell("E131").value > 10 ? 1 : 0;
  ws.getCell("G132").value = ws.getCell("E132").value > 10 ? 1 : 0;
  ws.getCell("G133").value =
    ws.getCell("G117").value +
    ws.getCell("G118").value +
    ws.getCell("G119").value +
    ws.getCell("G120").value +
    ws.getCell("G121").value +
    ws.getCell("G122").value +
    ws.getCell("G123").value +
    ws.getCell("G124").value +
    ws.getCell("G125").value +
    ws.getCell("G126").value +
    ws.getCell("G127").value +
    ws.getCell("G128").value +
    ws.getCell("G129").value +
    ws.getCell("G130").value +
    ws.getCell("G131").value +
    ws.getCell("G132").value;

  ws.getCell("H117").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "10:00",
    100
  );
  ws.getCell("I117").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "10:00", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H118").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "10:25",
    100
  );
  ws.getCell("I118").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "10:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H119").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "10:55",
    100
  );
  ws.getCell("I119").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "10:55", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H120").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "11:25",
    100
  );
  ws.getCell("I120").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "11:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H121").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "11:55",
    100
  );
  ws.getCell("I121").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "11:55", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H122").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "12:25",
    100
  );
  ws.getCell("I122").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "12:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H123").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "12:55",
    100
  );
  ws.getCell("I123").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "12:55", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H124").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "14:25",
    100
  );
  ws.getCell("I124").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "14:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H125").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "14:55",
    100
  );
  ws.getCell("I125").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "14:55", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H126").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "15:25",
    100
  );
  ws.getCell("I126").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "15:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H127").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "15:55",
    100
  );
  ws.getCell("I127").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "15:55", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H128").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "16:25",
    100
  );
  ws.getCell("I128").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "16:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H129").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "16:55",
    100
  );
  ws.getCell("I129").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "16:55", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H130").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "17:25",
    100
  );
  ws.getCell("I130").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "17:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H131").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "17:55",
    100
  );
  ws.getCell("I131").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "17:55", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H132").value = getOnFullCounts(
    allItems,
    "MFC > MT",
    "18:25",
    100
  );
  ws.getCell("I132").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > MT", "18:25", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("J117").value = ws.getCell("H117").value > 10 ? 1 : 0;
  ws.getCell("J118").value = ws.getCell("H118").value > 10 ? 1 : 0;
  ws.getCell("J119").value = ws.getCell("H119").value > 10 ? 1 : 0;
  ws.getCell("J120").value = ws.getCell("H120").value > 10 ? 1 : 0;
  ws.getCell("J121").value = ws.getCell("H121").value > 10 ? 1 : 0;
  ws.getCell("J122").value = ws.getCell("H122").value > 10 ? 1 : 0;
  ws.getCell("J123").value = ws.getCell("H123").value > 10 ? 1 : 0;
  ws.getCell("J124").value = ws.getCell("H124").value > 10 ? 1 : 0;
  ws.getCell("J125").value = ws.getCell("H125").value > 10 ? 1 : 0;
  ws.getCell("J126").value = ws.getCell("H126").value > 10 ? 1 : 0;
  ws.getCell("J127").value = ws.getCell("H127").value > 10 ? 1 : 0;
  ws.getCell("J128").value = ws.getCell("H128").value > 10 ? 1 : 0;
  ws.getCell("J129").value = ws.getCell("H129").value > 10 ? 1 : 0;
  ws.getCell("J130").value = ws.getCell("H130").value > 10 ? 1 : 0;
  ws.getCell("J131").value = ws.getCell("H131").value > 10 ? 1 : 0;
  ws.getCell("J132").value = ws.getCell("H132").value > 10 ? 1 : 0;
  ws.getCell("J133").value =
    ws.getCell("J117").value +
    ws.getCell("J118").value +
    ws.getCell("J119").value +
    ws.getCell("J120").value +
    ws.getCell("J121").value +
    ws.getCell("J122").value +
    ws.getCell("J123").value +
    ws.getCell("J124").value +
    ws.getCell("J125").value +
    ws.getCell("J126").value +
    ws.getCell("J127").value +
    ws.getCell("J128").value +
    ws.getCell("J129").value +
    ws.getCell("J130").value +
    ws.getCell("J131").value +
    ws.getCell("J132").value;

  ws.getCell("K117").value = getDelayCount(allItems, "MFC > MT", "10:10") || 0;
  ws.getCell("L117").value =
    Math.floor((ws.getCell("S117").value / totalDay) * 100) + "%";

  ws.getCell("K118").value = getDelayCount(allItems, "MFC > MT", "10:25") || 0;
  ws.getCell("L118").value =
    Math.floor((ws.getCell("S118").value / totalDay) * 100) + "%";

  ws.getCell("K119").value = getDelayCount(allItems, "MFC > MT", "10:55") || 0;
  ws.getCell("L119").value =
    Math.floor((ws.getCell("S119").value / totalDay) * 100) + "%";

  ws.getCell("K120").value = getDelayCount(allItems, "MFC > MT", "11:25") || 0;
  ws.getCell("L120").value =
    Math.floor((ws.getCell("S120").value / totalDay) * 100) + "%";

  ws.getCell("K121").value = getDelayCount(allItems, "MFC > MT", "11:55") || 0;
  ws.getCell("L121").value =
    Math.floor((ws.getCell("S121").value / totalDay) * 100) + "%";

  ws.getCell("K122").value = getDelayCount(allItems, "MFC > MT", "12:25") || 0;
  ws.getCell("L122").value =
    Math.floor((ws.getCell("S122").value / totalDay) * 100) + "%";

  ws.getCell("K123").value = getDelayCount(allItems, "MFC > MT", "12:55") || 0;
  ws.getCell("L123").value =
    Math.floor((ws.getCell("S123").value / totalDay) * 100) + "%";

  ws.getCell("K124").value = getDelayCount(allItems, "MFC > MT", "14:25") || 0;
  ws.getCell("L124").value =
    Math.floor((ws.getCell("S124").value / totalDay) * 100) + "%";

  ws.getCell("K125").value = getDelayCount(allItems, "MFC > MT", "14:55") || 0;
  ws.getCell("L125").value =
    Math.floor((ws.getCell("S125").value / totalDay) * 100) + "%";

  ws.getCell("K126").value = getDelayCount(allItems, "MFC > MT", "15:25") || 0;
  ws.getCell("L126").value =
    Math.floor((ws.getCell("S126").value / totalDay) * 100) + "%";

  ws.getCell("K127").value = getDelayCount(allItems, "MFC > MT", "15:55") || 0;
  ws.getCell("L127").value =
    Math.floor((ws.getCell("S127").value / totalDay) * 100) + "%";

  ws.getCell("K128").value = getDelayCount(allItems, "MFC > MT", "16:25") || 0;
  ws.getCell("L128").value =
    Math.floor((ws.getCell("S128").value / totalDay) * 100) + "%";

  ws.getCell("K129").value = getDelayCount(allItems, "MFC > MT", "16:55") || 0;
  ws.getCell("L129").value =
    Math.floor((ws.getCell("S129").value / totalDay) * 100) + "%";

  ws.getCell("K130").value = getDelayCount(allItems, "MFC > MT", "17:25") || 0;
  ws.getCell("L130").value =
    Math.floor((ws.getCell("S130").value / totalDay) * 100) + "%";

  ws.getCell("K131").value = getDelayCount(allItems, "MFC > MT", "17:55") || 0;
  ws.getCell("L131").value =
    Math.floor((ws.getCell("S131").value / totalDay) * 100) + "%";

  ws.getCell("K132").value = getDelayCount(allItems, "MFC > MT", "18:25") || 0;
  ws.getCell("L132").value =
    Math.floor((ws.getCell("S132").value / totalDay) * 100) + "%";

  ws.getCell("K133").value =
    ws.getCell("K117").value +
    ws.getCell("K118").value +
    ws.getCell("K119").value +
    ws.getCell("K120").value +
    ws.getCell("K121").value +
    ws.getCell("K122").value +
    ws.getCell("K123").value +
    ws.getCell("K124").value +
    ws.getCell("K125").value +
    ws.getCell("K126").value +
    ws.getCell("K127").value +
    ws.getCell("K128").value +
    ws.getCell("K129").value +
    ws.getCell("K130").value +
    ws.getCell("K131").value +
    ws.getCell("K132").value;

  ws.getCell("M117").value =
    getStatusCounts(allItems, "MFC > MT", "10:10", "CANCEL") || 0;
  ws.getCell("N117").value =
    Math.floor((ws.getCell("M117").value / totalDay) * 100) + "%";

  ws.getCell("M118").value =
    getStatusCounts(allItems, "MFC > MT", "10:25", "CANCEL") || 0;
  ws.getCell("N118").value =
    Math.floor((ws.getCell("M118").value / totalDay) * 100) + "%";

  ws.getCell("M119").value =
    getStatusCounts(allItems, "MFC > MT", "10:55", "CANCEL") || 0;
  ws.getCell("N119").value =
    Math.floor((ws.getCell("M119").value / totalDay) * 100) + "%";

  ws.getCell("M120").value =
    getStatusCounts(allItems, "MFC > MT", "11:25", "CANCEL") || 0;
  ws.getCell("N120").value =
    Math.floor((ws.getCell("M120").value / totalDay) * 100) + "%";

  ws.getCell("M121").value =
    getStatusCounts(allItems, "MFC > MT", "11:55", "CANCEL") || 0;
  ws.getCell("N121").value =
    Math.floor((ws.getCell("M121").value / totalDay) * 100) + "%";

  ws.getCell("M122").value =
    getStatusCounts(allItems, "MFC > MT", "12:25", "CANCEL") || 0;
  ws.getCell("N122").value =
    Math.floor((ws.getCell("M122").value / totalDay) * 100) + "%";

  ws.getCell("M123").value =
    getStatusCounts(allItems, "MFC > MT", "12:55", "CANCEL") || 0;
  ws.getCell("N123").value =
    Math.floor((ws.getCell("M123").value / totalDay) * 100) + "%";

  ws.getCell("M124").value =
    getStatusCounts(allItems, "MFC > MT", "14:25", "CANCEL") || 0;
  ws.getCell("N124").value =
    Math.floor((ws.getCell("M124").value / totalDay) * 100) + "%";

  ws.getCell("M125").value =
    getStatusCounts(allItems, "MFC > MT", "14:55", "CANCEL") || 0;
  ws.getCell("N125").value =
    Math.floor((ws.getCell("M125").value / totalDay) * 100) + "%";

  ws.getCell("M126").value =
    getStatusCounts(allItems, "MFC > MT", "15:25", "CANCEL") || 0;
  ws.getCell("N126").value =
    Math.floor((ws.getCell("M126").value / totalDay) * 100) + "%";

  ws.getCell("M127").value =
    getStatusCounts(allItems, "MFC > MT", "15:55", "CANCEL") || 0;
  ws.getCell("N127").value =
    Math.floor((ws.getCell("M127").value / totalDay) * 100) + "%";

  ws.getCell("M128").value =
    getStatusCounts(allItems, "MFC > MT", "16:25", "CANCEL") || 0;
  ws.getCell("N128").value =
    Math.floor((ws.getCell("M128").value / totalDay) * 100) + "%";

  ws.getCell("M129").value =
    getStatusCounts(allItems, "MFC > MT", "16:55", "CANCEL") || 0;
  ws.getCell("N129").value =
    Math.floor((ws.getCell("M129").value / totalDay) * 100) + "%";

  ws.getCell("M130").value =
    getStatusCounts(allItems, "MFC > MT", "17:25", "CANCEL") || 0;
  ws.getCell("N130").value =
    Math.floor((ws.getCell("M130").value / totalDay) * 100) + "%";

  ws.getCell("M131").value =
    getStatusCounts(allItems, "MFC > MT", "17:55", "CANCEL") || 0;
  ws.getCell("N131").value =
    Math.floor((ws.getCell("M131").value / totalDay) * 100) + "%";

  ws.getCell("M132").value =
    getStatusCounts(allItems, "MFC > MT", "18:25", "CANCEL") || 0;
  ws.getCell("N132").value =
    Math.floor((ws.getCell("M132").value / totalDay) * 100) + "%";

  ws.getCell("M133").value =
    ws.getCell("M117").value +
    ws.getCell("M118").value +
    ws.getCell("M119").value +
    ws.getCell("M120").value +
    ws.getCell("M121").value +
    ws.getCell("M122").value +
    ws.getCell("M123").value +
    ws.getCell("M124").value +
    ws.getCell("M125").value +
    ws.getCell("M126").value +
    ws.getCell("M127").value +
    ws.getCell("M128").value +
    ws.getCell("M129").value +
    ws.getCell("M130").value +
    ws.getCell("M131").value +
    ws.getCell("M132").value;

  ws.getCell("A137").value = "MT > LG";
  ws.getCell("C138").value = getOnBoradCount(allItems, "MT > LG", "12:00");
  ws.getCell("C139").value = getOnBoradCount(allItems, "MT > LG", "14:00");
  ws.getCell("C140").value =
    ws.getCell("C138").value + ws.getCell("C139").value;
  ws.getCell("C141").value = Math.floor(ws.getCell("C140").value / 2) || 0;

  ws.getCell("D138").value =
    Math.floor(ws.getCell("C138").value / ws.getCell("X3").value) || 0;
  ws.getCell("D139").value =
    Math.floor(ws.getCell("C139").value / ws.getCell("X3").value) || 0;
  ws.getCell("D140").value =
    Math.floor(ws.getCell("C140").value / ws.getCell("X3").value) || 0;
  ws.getCell("D141").value =
    Math.floor(ws.getCell("C141").value / ws.getCell("X3").value) || 0;

  ws.getCell("E138").value = getOnFullCounts(allItems, "MT > LG", "12:00", 80);
  ws.getCell("F138").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "12:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E139").value = getOnFullCounts(allItems, "MT > LG", "14:00", 80);
  ws.getCell("F139").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "14:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G138").value = ws.getCell("E138").value > 10 ? 1 : 0;
  ws.getCell("G139").value = ws.getCell("E139").value > 10 ? 1 : 0;
  ws.getCell("G140").value =
    ws.getCell("G138").value + ws.getCell("G139").value;

  ws.getCell("H138").value = getOnFullCounts(allItems, "MT > LG", "12:00", 100);
  ws.getCell("I138").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "12:00", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H139").value = getOnFullCounts(allItems, "MT > LG", "14:00", 100);
  ws.getCell("I139").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "14:00", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("J138").value = ws.getCell("H138").value > 10 ? 1 : 0;
  ws.getCell("J139").value = ws.getCell("H139").value > 10 ? 1 : 0;
  ws.getCell("J140").value =
    ws.getCell("J138").value + ws.getCell("J139").value;

  ws.getCell("K138").value = getDelayCount(allItems, "MT > LG", "12:00") || 0;
  ws.getCell("L138").value =
    Math.floor((ws.getCell("S138").value / totalDay) * 100) + "%";

  ws.getCell("K139").value = getDelayCount(allItems, "MT > LG", "14:00") || 0;
  ws.getCell("L139").value =
    Math.floor((ws.getCell("S139").value / totalDay) * 100) + "%";
  ws.getCell("K140").value =
    ws.getCell("K138").value + ws.getCell("K139").value;

  ws.getCell("M138").value =
    getStatusCounts(allItems, "MT > LG", "12:00", "CANCEL") || 0;
  ws.getCell("N138").value =
    Math.floor((ws.getCell("M138").value / totalDay) * 100) + "%";

  ws.getCell("M139").value =
    getStatusCounts(allItems, "MT > LG", "14:00", "CANCEL") || 0;
  ws.getCell("N139").value =
    Math.floor((ws.getCell("M139").value / totalDay) * 100) + "%";

  ws.getCell("M140").value =
    ws.getCell("M138").value + ws.getCell("M139").value;

  ws.getCell("A144").value = "MFC > LG";
  ws.getCell("C145").value = getOnBoradCount(allItems, "MFC > LG", "12:00");
  ws.getCell("C146").value = getOnBoradCount(allItems, "MFC > LG", "14:00");
  ws.getCell("C147").value =
    ws.getCell("C145").value + ws.getCell("C146").value;
  ws.getCell("C148").value = Math.floor(ws.getCell("C147").value / 2) || 0;

  ws.getCell("D145").value =
    Math.floor(ws.getCell("C145").value / ws.getCell("X3").value) || 0;
  ws.getCell("D146").value =
    Math.floor(ws.getCell("C146").value / ws.getCell("X3").value) || 0;
  ws.getCell("D147").value =
    Math.floor(ws.getCell("C147").value / ws.getCell("X3").value) || 0;
  ws.getCell("D148").value =
    Math.floor(ws.getCell("C148").value / ws.getCell("X3").value) || 0;

  ws.getCell("E145").value = getOnFullCounts(allItems, "MFC > LG", "12:10", 80);
  ws.getCell("F145").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "12:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E146").value = getOnFullCounts(allItems, "MFC > LG", "14:10", 80);
  ws.getCell("F146").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "14:10", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G145").value = ws.getCell("E145").value > 10 ? 1 : 0;
  ws.getCell("G146").value = ws.getCell("E146").value > 10 ? 1 : 0;
  ws.getCell("G147").value =
    ws.getCell("G145").value + ws.getCell("G146").value;

  ws.getCell("H145").value = getOnFullCounts(
    allItems,
    "MFC > LG",
    "12:10",
    100
  );
  ws.getCell("I145").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "12:10", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H146").value = getOnFullCounts(
    allItems,
    "MFC > LG",
    "14:10",
    100
  );
  ws.getCell("I146").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "14:10", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("J145").value = ws.getCell("H145").value > 10 ? 1 : 0;
  ws.getCell("J146").value = ws.getCell("H146").value > 10 ? 1 : 0;
  ws.getCell("J147").value =
    ws.getCell("J145").value + ws.getCell("J146").value;

  ws.getCell("K145").value = getDelayCount(allItems, "MFC > LG", "12:10") || 0;
  ws.getCell("L145").value =
    Math.floor((ws.getCell("S145").value / totalDay) * 100) + "%";

  ws.getCell("K146").value = getDelayCount(allItems, "MFC > LG", "14:10") || 0;
  ws.getCell("L146").value =
    Math.floor((ws.getCell("S146").value / totalDay) * 100) + "%";
  ws.getCell("K147").value =
    ws.getCell("K145").value + ws.getCell("K146").value;

  ws.getCell("M145").value =
    getStatusCounts(allItems, "MFC > LG", "12:10", "CANCEL") || 0;
  ws.getCell("N145").value =
    Math.floor((ws.getCell("M145").value / totalDay) * 100) + "%";

  ws.getCell("M146").value =
    getStatusCounts(allItems, "MFC > LG", "14:10", "CANCEL") || 0;
  ws.getCell("N146").value =
    Math.floor((ws.getCell("M146").value / totalDay) * 100) + "%";

  ws.getCell("M147").value =
    ws.getCell("M145").value + ws.getCell("M146").value;

  ws.getCell("A153").value = "MT > LG";
  ws.getCell("C154").value = getOnBoradCount(allItems, "MT > LG", "18:30");
  ws.getCell("C155").value = getOnBoradCount(allItems, "MT > LG", "19:00");
  ws.getCell("C156").value =
    ws.getCell("C154").value + ws.getCell("C155").value;
  ws.getCell("C157").value = Math.floor(ws.getCell("C156").value / 2) || 0;

  ws.getCell("D154").value =
    Math.floor(ws.getCell("C154").value / ws.getCell("X3").value) || 0;
  ws.getCell("D155").value =
    Math.floor(ws.getCell("C155").value / ws.getCell("X3").value) || 0;
  ws.getCell("D156").value =
    Math.floor(ws.getCell("C156").value / ws.getCell("X3").value) || 0;
  ws.getCell("D157").value =
    Math.floor(ws.getCell("C157").value / ws.getCell("X3").value) || 0;

  ws.getCell("E154").value = getOnFullCounts(allItems, "MT > LG", "18:30", 80);
  ws.getCell("F154").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "18:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E155").value = getOnFullCounts(allItems, "MT > LG", "19:00", 80);
  ws.getCell("F155").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "19:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G154").value = ws.getCell("E154").value > 10 ? 1 : 0;
  ws.getCell("G155").value = ws.getCell("E155").value > 10 ? 1 : 0;
  ws.getCell("G156").value =
    ws.getCell("G154").value + ws.getCell("G155").value;

  ws.getCell("H154").value = getOnFullCounts(allItems, "MT > LG", "18:30", 100);
  ws.getCell("I154").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "18:30", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H155").value = getOnFullCounts(allItems, "MT > LG", "19:00", 100);
  ws.getCell("I155").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MT > LG", "19:00", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("J154").value = ws.getCell("H154").value > 10 ? 1 : 0;
  ws.getCell("J155").value = ws.getCell("H155").value > 10 ? 1 : 0;
  ws.getCell("J156").value =
    ws.getCell("J154").value + ws.getCell("J155").value;

  ws.getCell("K154").value = getDelayCount(allItems, "MT > LG", "18:30") || 0;
  ws.getCell("L154").value =
    Math.floor((ws.getCell("S154").value / totalDay) * 100) + "%";

  ws.getCell("K155").value = getDelayCount(allItems, "MT > LG", "19:00") || 0;
  ws.getCell("L155").value =
    Math.floor((ws.getCell("S155").value / totalDay) * 100) + "%";
  ws.getCell("K156").value =
    ws.getCell("K154").value + ws.getCell("K155").value;

  ws.getCell("M154").value =
    getStatusCounts(allItems, "MT > LG", "18:30", "CANCEL") || 0;
  ws.getCell("N154").value =
    Math.floor((ws.getCell("M154").value / totalDay) * 100) + "%";

  ws.getCell("M155").value =
    getStatusCounts(allItems, "MT > LG", "19:00", "CANCEL") || 0;
  ws.getCell("N155").value =
    Math.floor((ws.getCell("M155").value / totalDay) * 100) + "%";

  ws.getCell("M156").value =
    ws.getCell("M154").value + ws.getCell("M155").value;

  ws.getCell("A160").value = "MFC > LG";
  ws.getCell("C161").value = getOnBoradCount(allItems, "MFC > LG", "18:30");
  ws.getCell("C162").value = getOnBoradCount(allItems, "MFC > LG", "19:00");
  ws.getCell("C163").value =
    ws.getCell("C161").value + ws.getCell("C162").value;
  ws.getCell("C164").value = Math.floor(ws.getCell("C163").value / 2) || 0;

  ws.getCell("D161").value =
    Math.floor(ws.getCell("C161").value / ws.getCell("X3").value) || 0;
  ws.getCell("D162").value =
    Math.floor(ws.getCell("C162").value / ws.getCell("X3").value) || 0;
  ws.getCell("D163").value =
    Math.floor(ws.getCell("C163").value / ws.getCell("X3").value) || 0;
  ws.getCell("D164").value =
    Math.floor(ws.getCell("C164").value / ws.getCell("X3").value) || 0;

  ws.getCell("E161").value = getOnFullCounts(allItems, "MFC > LG", "18:30", 80);
  ws.getCell("F161").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "18:30", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("E162").value = getOnFullCounts(allItems, "MFC > LG", "19:00", 80);
  ws.getCell("F162").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "19:00", 80) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("G161").value = ws.getCell("E161").value > 10 ? 1 : 0;
  ws.getCell("G162").value = ws.getCell("E162").value > 10 ? 1 : 0;
  ws.getCell("G163").value =
    ws.getCell("G161").value + ws.getCell("G162").value;

  ws.getCell("H161").value = getOnFullCounts(
    allItems,
    "MFC > LG",
    "18:30",
    100
  );
  ws.getCell("I161").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "18:30", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("H162").value = getOnFullCounts(
    allItems,
    "MFC > LG",
    "19:00",
    100
  );
  ws.getCell("I162").value =
    (Math.floor(
      (getOnFullCounts(allItems, "MFC > LG", "19:00", 100) / totalDay) * 100
    ) || 0) + "%";

  ws.getCell("J161").value = ws.getCell("H161").value > 10 ? 1 : 0;
  ws.getCell("J162").value = ws.getCell("H162").value > 10 ? 1 : 0;
  ws.getCell("J163").value =
    ws.getCell("J161").value + ws.getCell("J162").value;

  ws.getCell("K161").value = getDelayCount(allItems, "MFC > LG", "18:30") || 0;
  ws.getCell("L161").value =
    Math.floor((ws.getCell("S161").value / totalDay) * 100) + "%";

  ws.getCell("K162").value = getDelayCount(allItems, "MFC > LG", "19:00") || 0;
  ws.getCell("L162").value =
    Math.floor((ws.getCell("S162").value / totalDay) * 100) + "%";
  ws.getCell("K163").value =
    ws.getCell("K161").value + ws.getCell("K162").value;

  ws.getCell("M161").value =
    getStatusCounts(allItems, "MFC > LG", "18:30", "CANCEL") || 0;
  ws.getCell("N161").value =
    Math.floor((ws.getCell("M161").value / totalDay) * 100) + "%";

  ws.getCell("M162").value =
    getStatusCounts(allItems, "MFC > LG", "19:00", "CANCEL") || 0;
  ws.getCell("N162").value =
    Math.floor((ws.getCell("M162").value / totalDay) * 100) + "%";

  ws.getCell("M163").value =
    ws.getCell("M161").value + ws.getCell("M162").value;

  ws.getCell("C168").value =
    ws.getCell("C156").value + ws.getCell("C163").value;
  ws.getCell("C169").value = Math.floor(ws.getCell("C168").value / 4) || 0;

  ws.getCell("D168").value =
    Math.floor(ws.getCell("C168").value / ws.getCell("X3").value) || 0;
  ws.getCell("D169").value = Math.floor(ws.getCell("D168").value / 4) || 0;

  ws.getCell("E168").value =
    ws.getCell("K156").value + ws.getCell("K163").value;

  ws.getCell("G168").value = ws.getCell("M56").value + ws.getCell("M63").value;

  ws.getCell("C174").value = getOnBoradCount(allItems, "MT > MFC", "19:30");
  ws.getCell("C175").value = getOnBoradCount(allItems, "MT > MFC", "20:00");
  ws.getCell("C176").value =
    ws.getCell("C174").value + ws.getCell("C175").value;
  ws.getCell("C177").value = Math.floor(ws.getCell("C176").value / 2) || 0;

  ws.getCell("D174").value =
    Math.floor(ws.getCell("C174").value / ws.getCell("X3").value) || 0;
  ws.getCell("D175").value =
    Math.floor(ws.getCell("C175").value / ws.getCell("X3").value) || 0;
  ws.getCell("D176").value =
    Math.floor(ws.getCell("C176").value / ws.getCell("X3").value) || 0;
  ws.getCell("D177").value =
    Math.floor(ws.getCell("C177").value / ws.getCell("X3").value) || 0;

  ws.getCell("K174").value = getRouteCount(allItems, "MFC > LG", "19:40");
  ws.getCell("K175").value = getRouteCount(allItems, "MFC > LG", "20:10");
  ws.getCell("K176").value =
    ws.getCell("K174").value + ws.getCell("K175").value;
  ws.getCell("K177").value = Math.floor(ws.getCell("K176").value / 2) || 0;

  ws.getCell("L174").value =
    Math.floor(ws.getCell("K174").value / ws.getCell("X3").value) || 0;
  ws.getCell("L175").value =
    Math.floor(ws.getCell("K175").value / ws.getCell("X3").value) || 0;
  ws.getCell("L176").value =
    Math.floor(ws.getCell("K176").value / ws.getCell("X3").value) || 0;
  ws.getCell("L177").value =
    Math.floor(ws.getCell("K177").value / ws.getCell("X3").value) || 0;

  ws.getCell("E174").value = getOnFullCounts(allItems, "MT > MFC", "19:30", 80);
  ws.getCell("F174").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "19:30", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("E175").value = getOnFullCounts(allItems, "MT > MFC", "20:00", 80);
  ws.getCell("F175").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "20:00", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("H174").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "19:30",
    100
  );
  ws.getCell("I174").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "19:30", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("H175").value = getOnFullCounts(
    allItems,
    "MT > MFC",
    "20:00",
    100
  );
  ws.getCell("I175").value =
    Math.floor(
      (getOnFullCounts(allItems, "MT > MFC", "20:00", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("M174").value = getFullCounts(allItems, "MFC > LG", "19:40", 80);
  ws.getCell("N174").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "19:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("M175").value = getFullCounts(allItems, "MFC > LG", "20:10", 80);
  ws.getCell("N175").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "20:10", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("P174").value = getFullCounts(allItems, "MFC > LG", "19:40", 100);
  ws.getCell("Q174").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "19:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("P175").value = getFullCounts(allItems, "MFC > LG", "20:10", 100);
  ws.getCell("Q175").value =
    Math.floor(
      (getFullCounts(allItems, "MFC > LG", "20:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("G174").value = ws.getCell("E174").value > 10 ? 1 : 0;
  ws.getCell("G175").value = ws.getCell("E175").value > 10 ? 1 : 0;
  ws.getCell("G176").value =
    ws.getCell("G174").value + ws.getCell("G175").value;

  ws.getCell("J174").value = ws.getCell("H174").value > 10 ? 1 : 0;
  ws.getCell("J175").value = ws.getCell("H175").value > 10 ? 1 : 0;
  ws.getCell("J176").value =
    ws.getCell("J174").value + ws.getCell("J175").value;

  ws.getCell("O174").value = ws.getCell("M174").value > 10 ? 1 : 0;
  ws.getCell("O175").value = ws.getCell("M175").value > 10 ? 1 : 0;
  ws.getCell("O176").value =
    ws.getCell("O174").value + ws.getCell("O175").value;

  ws.getCell("R174").value = ws.getCell("P174").value > 10 ? 1 : 0;
  ws.getCell("R175").value = ws.getCell("P175").value > 10 ? 1 : 0;
  ws.getCell("R176").value =
    ws.getCell("R174").value + ws.getCell("R175").value;

  ws.getCell("S174").value =
    (getDelayCount(allItems, "MT > MFC", "19:30") || 0) +
      (getDelayCount(allItems, "MFC > LG", "19:40") || 0) || 0;
  ws.getCell("T174").value =
    Math.floor((ws.getCell("S174").value / totalDay) * 100) + "%";

  ws.getCell("S175").value =
    (getDelayCount(allItems, "MT > MFC", "20:00") || 0) +
      (getDelayCount(allItems, "MFC > LG", "20:10") || 0) || 0;
  ws.getCell("T175").value =
    Math.floor((ws.getCell("S175").value / totalDay) * 100) + "%";
  ws.getCell("S176").value =
    ws.getCell("S174").value + ws.getCell("S175").value;

  ws.getCell("U174").value =
    (getStatusCounts(allItems, "MT > MFC", "19:30", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MFC > LG", "19:40", "CANCEL") || 0);
  ws.getCell("V174").value =
    Math.floor((ws.getCell("U174").value / totalDay) * 100) + "%";

  ws.getCell("U175").value =
    (getStatusCounts(allItems, "MT > MFC", "20:00", "CANCEL") || 0) +
    (getStatusCounts(allItems, "MFC > LG", "20:10", "CANCEL") || 0);
  ws.getCell("V175").value =
    Math.floor((ws.getCell("U175").value / totalDay) * 100) + "%";
  ws.getCell("U176").value =
    ws.getCell("U174").value + ws.getCell("U175").value;

  ws.getCell("W7").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MT", "07:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W8").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MT", "08:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W9").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MT", "08:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W10").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MT", "09:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W11").value =
    ws.getCell("W7").value +
    ws.getCell("W8").value +
    ws.getCell("W9").value +
    ws.getCell("W10").value;
  ws.getCell("W12").value = Math.floor(ws.getCell("W11").value / 4) || 0;

  ws.getCell("W17").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "08:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W18").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "08:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W19").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "09:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W20").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "09:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W21").value =
    ws.getCell("W17").value +
    ws.getCell("W18").value +
    ws.getCell("W19").value +
    ws.getCell("W20").value;
  ws.getCell("W22").value = Math.floor(ws.getCell("W21").value / 4) || 0;

  ws.getCell("W54").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "10:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W55").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "10:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W56").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "11:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W57").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "11:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W58").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "12:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W59").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "14:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W60").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "15:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W61").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "15:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W62").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "16:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W63").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "16:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W64").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "17:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W65").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "17:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W66").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "18:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W67").value =
    ws.getCell("W54").value +
    ws.getCell("W55").value +
    ws.getCell("W56").value +
    ws.getCell("W57").value +
    ws.getCell("W58").value +
    ws.getCell("W59").value +
    ws.getCell("W60").value +
    ws.getCell("W61").value +
    ws.getCell("W62").value +
    ws.getCell("W63").value +
    ws.getCell("W64").value +
    ws.getCell("W65").value +
    ws.getCell("W66").value;
  ws.getCell("W68").value = Math.floor(ws.getCell("W67").value / 13) || 0;

  ws.getCell("W73").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "10:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W74").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "10:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W75").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "11:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W76").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "11:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W77").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "12:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W78").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "12:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W79").value = 0;
  ws.getCell("W80").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "13:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W81").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "14:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W82").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "14:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W83").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "15:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W84").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "15:45 ") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W85").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "15:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W86").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "16:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W87").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "17:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W88").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "17:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W89").value =
    Math.floor(
      getOffBoradCount(allItems, "LG > MFC", "18:15") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W90").value =
    ws.getCell("W73").value +
    ws.getCell("W74").value +
    ws.getCell("W75").value +
    ws.getCell("W76").value +
    ws.getCell("W77").value +
    ws.getCell("W78").value +
    ws.getCell("W79").value +
    ws.getCell("W80").value +
    ws.getCell("W81").value +
    ws.getCell("W82").value +
    ws.getCell("W83").value +
    ws.getCell("W84").value +
    ws.getCell("W85").value +
    ws.getCell("W86").value +
    ws.getCell("W87").value +
    ws.getCell("W88").value +
    ws.getCell("W89").value;
  ws.getCell("W91").value = Math.floor(ws.getCell("W90").value / 16) || 0;

  ws.getCell("W174").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "19:30") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W175").value =
    Math.floor(
      getOffBoradCount(allItems, "MT > MFC", "20:00") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("W176").value =
    ws.getCell("W174").value + ws.getCell("W175").value;
  ws.getCell("W177").value = Math.floor(ws.getCell("W176").value / 2) || 0;

  ws.getCell("X7").value =
    Math.floor(
      getOnBoradCount(allItems, "MT > MFC", "08:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X8").value =
    Math.floor(
      getOnBoradCount(allItems, "MT > MFC", "08:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X9").value =
    Math.floor(
      getOnBoradCount(allItems, "MT > MFC", "09:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X10").value =
    Math.floor(
      getOnBoradCount(allItems, "MT > MFC", "09:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X11").value =
    ws.getCell("X7").value +
    ws.getCell("X8").value +
    ws.getCell("X9").value +
    ws.getCell("X10").value;
  ws.getCell("X12").value = Math.floor(ws.getCell("X11").value / 4) || 0;

  ws.getCell("X17").value =
    Math.floor(
      getOnBoradCount(allItems, "MFC > MT", "08:20") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X18").value =
    Math.floor(
      getOnBoradCount(allItems, "MFC > MT", "08:55") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X19").value =
    Math.floor(
      getOnBoradCount(allItems, "MFC > MT", "09:20") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X20").value =
    Math.floor(
      getOnBoradCount(allItems, "MFC > MT", "09:55") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X21").value =
    ws.getCell("X17").value +
    ws.getCell("X18").value +
    ws.getCell("X19").value +
    ws.getCell("X20").value;
  ws.getCell("X22").value = Math.floor(ws.getCell("X21").value / 4) || 0;

  ws.getCell("X54").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "10:10") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X55").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "10:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X56").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "11:10") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X57").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "11:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X58").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "12:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X59").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "14:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X60").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "15:10") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X61").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "15:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X62").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "16:10") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X63").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "16:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X64").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "17:10") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X65").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "17:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X66").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "18:10") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X67").value =
    ws.getCell("X54").value +
    ws.getCell("X55").value +
    ws.getCell("X56").value +
    ws.getCell("X57").value +
    ws.getCell("X58").value +
    ws.getCell("X59").value +
    ws.getCell("X60").value +
    ws.getCell("X61").value +
    ws.getCell("X62").value +
    ws.getCell("X63").value +
    ws.getCell("X64").value +
    ws.getCell("X65").value +
    ws.getCell("X66").value;
  ws.getCell("X68").value = Math.floor(ws.getCell("X67").value / 13) || 0;

  ws.getCell("X73").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "10:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X74").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "11:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X75").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "11:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X76").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "12:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X77").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "12:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X78").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "13:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X79").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "13:45") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X80").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "14:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X81").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "14:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X82").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "15:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X83").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "15:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X84").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "16:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X85").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "16:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X86").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "17:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X87").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "17:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X88").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "18:05") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X89").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > MT", "18:35") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X90").value =
    ws.getCell("X73").value +
    ws.getCell("X74").value +
    ws.getCell("X75").value +
    ws.getCell("X76").value +
    ws.getCell("X77").value +
    ws.getCell("X78").value +
    ws.getCell("X79").value +
    ws.getCell("X80").value +
    ws.getCell("X81").value +
    ws.getCell("X82").value +
    ws.getCell("X83").value +
    ws.getCell("X84").value +
    ws.getCell("X85").value +
    ws.getCell("X86").value +
    ws.getCell("X87").value +
    ws.getCell("X88").value +
    ws.getCell("X89").value;
  ws.getCell("X91").value = Math.floor(ws.getCell("X90").value / 16) || 0;

  ws.getCell("X174").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "19:40") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X175").value =
    Math.floor(
      getOffBoradCount(allItems, "MFC > LG", "20:10") / ws.getCell("X3").value
    ) || 0;
  ws.getCell("X176").value =
    ws.getCell("X174").value + ws.getCell("X175").value;

  ws.getCell("X177").value = Math.floor(ws.getCell("X176").value / 2) || 0;

  // APPLY BACKGROUND COLOR (LIGHT YELLOW)
  yellowBgCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFCC" },
    };
  });

  // APPLY BACKGROUND COLOR (LIGHT BLUE)
  lightBlueCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "D9E1F2" },
    };
  });

  // APPLY BACKGROUND COLOR (LIGHT PURPLE)
  lightPurpleCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "CCCCFF" },
    };
  });

  // APPLY BACKGROUND COLOR (LIGHT GREEN)
  lightGreenCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "E2EFDA" },
    };
  });

  // APPLY BOLD BORDER AT RIGHT
  borderRightMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM RIGHT
  borderBottomRightMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "medium", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER RIGHT MEDIUM BOTTOM THIN
  borderRightMediumBottomThinCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "medium", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
  borderRightThingBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
  borderBottomRightThin.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM
  borderBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY THIN BORDER AT BOTTOM
  borderBottomThinCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      bottom: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT TOP & BOTTOM
  borderTopBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      top: { style: "medium", color: { argb: "000000" } },
      // left: { style: "thick", color: { argb: "000000FF" } },
      bottom: { style: "medium", color: { argb: "000000" } },
      // right: { style: "thick", color: { argb: "FF00FF00" } },
    };
  });

  // APPLY BOLD BORDER AT TOP BOTTOM AND RIGHT THIN
  topBottomMediumRightThin.map((cItem: any) => {
    ws.getCell(cItem).border = {
      top: { style: "medium", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT TOP RIGHT BOTTOM
  borderTopRightBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      top: { style: "medium", color: { argb: "000000" } },
      right: { style: "medium", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  // ROW HEIGHT = 20
  [
    3,
    4,
    5,
    14,
    15,
    24,
    25,
    34,
    35,
    44,
    45,
    50,
    51,
    52,
    70,
    71,
    93,
    94,
    114,
    115,
    136,
    143,
    151,
    152,
    159,
    166,
    171,
    172,
  ].map((rItm: any) => {
    ws.getRow(rItm).height = 20;
  });

  // ROW HEIGHT = 35
  [6, 16, 26, 36, 46, 53, 72, 95, 116, 137, 144, 153, 160, 167, 173].map(
    (rItm: any) => {
      ws.getRow(rItm).height = 45;
    }
  );

  ws.eachRow(function(row: any, rowNumber: any) {
    if (
      ![
        1,
        3,
        4,
        14,
        24,
        34,
        44,
        50,
        51,
        70,
        93,
        114,
        136,
        143,
        151,
        152,
        159,
        166,
        171,
      ].includes(rowNumber)
    ) {
      ws.getRow(rowNumber).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };
    }
  });

  ws.eachRow(function(row: any, rowNumber: any) {
    row.eachCell({ includeEmpty: true }, (cell: any, cellNumber: any) => {
      if (
        typeof ws.getCell(cell._address).value === "number" &&
        ws.getCell(cell._address).value < 0
      ) {
        // IF LESS THAN 0 THEN IT WILL BE SHOW IN RED
        ws.getCell(cell._address).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      }
    });
  });

  checkSummaryMoreThan28.map((cItem: any) => {
    if (
      typeof ws.getCell(cItem).value === "number" &&
      ws.getCell(cItem).value > 28
    ) {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF0000",
        },
      };
    } else if (
      typeof ws.getCell(cItem).value === "number" &&
      ws.getCell(cItem).value === 28
    ) {
      ws.getCell(cItem).font = {
        color: { argb: "FF0000" },
      };
    }
  });
};
