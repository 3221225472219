import React, { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import * as Yup from "yup";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";
import SearchSelect from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

interface Props {
  name: string;
  show: any;
  isEditMode: boolean;
  isHistoryMode?: boolean;
  onHide: any;
  action: any;
  headerText: string;
  bodyText?: string;
  loadingText?: string;
  actionText: string;
  actionStyle: string;
  dangerActionText: string;
  dangerActionStyle: string;
  onRemovelick?: any;
  siteId: string;
  scheduleData?: any;
  showNormalDriverField?: boolean;
  showBackupDriverField?: boolean;
  saveAndRemoveClick: any;
  eventsData: any;
}

const initialState = [
  {
    location_id: "",
    start: null,
    end: null,
    seat: "",
    station_code: "",
    people: "",
  },
  {
    location_id: "",
    start: null,
    end: null,
    seat: "",
    station_code: "",
    people: "",
  },
  {
    location_id: "",
    start: null,
    end: null,
    seat: "",
    station_code: "",
    people: "",
  },
];

export const ScheduleDailyDialog: FC<Props> = (props) => {
  const history = useHistory();
  const {
    show,
    onHide,
    isEditMode,
    headerText,
    actionText,
    actionStyle,
    dangerActionText,
    dangerActionStyle,
    siteId,
    scheduleData,
    saveAndRemoveClick,
    showNormalDriverField = false,
    showBackupDriverField = false,
    isHistoryMode = false,
    eventsData = [],
  } = props;

  const [isConflict, setConflict] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<any>([]);
  const [driverList, setDriverList] = useState<any>([]);
  const [carList, setCarList] = useState<any>([]);
  const [localScheduleDetail, setLocalScheduleDetail] = useState<any>({});
  const [applyCheck, setApplyCheck] = useState<boolean>(false);
  const statusList: any = [
    { label: "NORMAL", value: "NORMAL" },
    { label: "DELAY", value: "DELAY" },
    { label: "CANCEL", value: "CANCEL" },
  ];
  const pathStatusList = ["AUTO", "NORMAL", "DELAY", "CANCEL"];

  const getScheduleDetail = async (scheduleId) => {
    try {
      const { data }: any = await api({
        url: `/schedule/details/${scheduleId}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const scheduleDetailResponse = data.result.data;
        const locationData: any = [];
        for (const itemData of scheduleDetailResponse.location) {
          locationData.push({
            _id: itemData._id,
            location_id: itemData.location_id._id,
            start: itemData.start,
            end: itemData.end,
            seat: itemData.seat,
            etaCalculationStarted: itemData.etaCalculationStarted,
            ETA: itemData.ETA,
            reached: itemData.reached || false,
            station_code: itemData.station_code,
            status: itemData.status,
            auto: itemData.auto,
            manual_status: itemData.manual_status,
            remain_seat: itemData.remain_seat,
            reachedTime: itemData.reachedTime,
            people: itemData.people || "",
          });
        }
        const obj = {
          scheduleId: scheduleDetailResponse?._id,
          normalDriver: scheduleDetailResponse?.normalDriver || "",
          backupDriver: scheduleDetailResponse?.backupDriver || "",
          selectedCar: scheduleDetailResponse?.carId,
          selectedStatus: scheduleDetailResponse?.status,
          selectedPathList: locationData,
        };
        if (scheduleDetailResponse?.templateId) {
          obj["templateId"] = scheduleDetailResponse?.templateId?._id;
        }
        if (scheduleDetailResponse?.preScheduleId) {
          obj["preScheduleId"] = scheduleDetailResponse?.preScheduleId?._id;
        }
        setLocalScheduleDetail(obj);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    if (scheduleData) {
      getScheduleDetail(scheduleData.extendedProps.scheduleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationList = async () => {
    try {
      const { data }: any = await api({
        url: `/location/site/${siteId}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.locationData.map(
          (entity: any, ind: number) => {
            return {
              id: entity._id,
              name: entity.name,
              code: entity.code,
            };
          }
        );
        setLocationList(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getDriverList = async () => {
    try {
      const { data }: any = await api({
        url: `/user/list/?role=DRIVER`, // ${siteId}
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.userData.map(
          (entity: any, ind: number) => {
            return {
              value: entity._id,
              label: entity.name,
              carId: entity?.carId?._id,
            };
          }
        );
        setDriverList(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getCarList = async () => {
    try {
      const { data }: any = await api({
        url: `/car/site/${siteId}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const carListResponse = data.result.data.carData;
        setCarList(carListResponse);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      siteId: siteId,
      selectedCar: isEditMode ? localScheduleDetail.selectedCar : "",
      normalDriver: isEditMode ? localScheduleDetail?.normalDriver : "",
      backupDriver: isEditMode ? localScheduleDetail?.backupDriver : "",
      selectedStatus: isEditMode ? localScheduleDetail.selectedStatus : "",
      selectedPathList:
        isEditMode &&
        localScheduleDetail &&
        localScheduleDetail.selectedPathList &&
        localScheduleDetail.selectedPathList.length
          ? localScheduleDetail.selectedPathList
          : initialState,
    },
    validationSchema: Yup.object({
      selectedCar: Yup.string().required("Please select car"),
      // normalDriver: Yup.string().test(
      //   "normalDriver",
      //   "Please Select Normal Driver",
      //   function(val: any) {
      //     if (showNormalDriverField === true) {
      //       return Boolean(val && val !== "");
      //     } else {
      //       return true;
      //     }
      //   }
      // ),
      // backupDriver: Yup.string().test(
      //   "backupDriver",
      //   "Please Select Backup Driver",
      //   function(val: any) {
      //     if (showBackupDriverField === true) {
      //       return Boolean(val && val !== "");
      //     } else {
      //       return true;
      //     }
      //   }
      // ),
      selectedStatus: Yup.string().required("Please select status"),
      selectedPathList: Yup.array(
        Yup.object().shape({
          location_id: Yup.string().required("Please select location"),
          start: Yup.string()
            .nullable()
            .test("startEnd", "Invalid Time", function(value) {
              if (
                (this.options as any).index ===
                formik.values.selectedPathList.length - 1
              ) {
                return true;
              }
              if (!value)
                return this.createError({
                  message: "Start time is required",
                });
              return true;
            }),
          end: Yup.string()
            .nullable()
            .test("endTest", "Invalid Time", function(value) {
              if (
                (this.options as any).index ===
                formik.values.selectedPathList.length - 1
              ) {
                return true;
              }
              if (!value)
                return this.createError({
                  message: "End time is required",
                });

              if (
                moment(this.parent.start, "HH:mm").isSameOrAfter(
                  moment(this.parent.end, "HH:mm")
                )
              ) {
                return this.createError({
                  message: "End time must be after start time",
                });
              }

              return true;
            }),
        })
      ),
    }),
    onSubmit: (values: any) => {
      handleEdit(values);
    },
  });

  const removeAction = async () => {
    try {
      const { data }: any = await api({
        url: `schedule/delete/${localScheduleDetail.scheduleId}`,
        method: "delete",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        saveAndRemoveClick();
        toast.success("Schedule removed successfully");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const handleEdit = async (values) => {
    if (
      isValidTimeSelection(values.selectedPathList) !== "" ||
      isValidLocationSelection(values.selectedPathList) !== ""
    ) {
      return;
    }
    let locationTitle = "";
    if (values.selectedPathList && values.selectedPathList.length) {
      for (let i = 0; i < values.selectedPathList.length; i++) {
        locationTitle =
          locationTitle +
          locationList
            .map((location) => {
              if (values.selectedPathList[i].location_id === location.id) {
                return location.code;
              }
            })
            .filter(Boolean);

        if (i !== values.selectedPathList.length - 1) {
          locationTitle = locationTitle + " > ";
        }
      }
    }

    const reqBody: any = {
      siteId,
      title: locationTitle,
      remark: locationTitle,
      carId: values.selectedCar,
      location: values.selectedPathList.map((selectedPathData) => {
        return { ...selectedPathData, status: values.selectedStatus };
      }),
      status: values.selectedStatus,
    };
    if (
      showNormalDriverField &&
      values.normalDriver &&
      values.normalDriver !== ""
    ) {
      reqBody["normalDriver"] = values.normalDriver;
    }
    if (showBackupDriverField) {
      reqBody["backupDriver"] = values.backupDriver || "";
    }

    if (isEditMode) {
      if (localScheduleDetail?.templateId) {
        reqBody["templateId"] = localScheduleDetail?.templateId;
      }
      if (localScheduleDetail?.preScheduleId) {
        reqBody["preScheduleId"] = localScheduleDetail?.preScheduleId;
      }
      try {
        const { data }: any = await api({
          url: `/schedule/update/${localScheduleDetail.scheduleId}?applyUpcoming=${applyCheck}`,
          method: "put",
          body: reqBody,
        });
        if (data.serverResponse.isError) {
          toast.error(data.serverResponse.message);
        } else {
          saveAndRemoveClick();
          toast.success("Schedule updated successfully");
        }
      } catch (err) {
        // @ts-ignore
        err.response && toast.error(err.message);
      }
    } else {
      try {
        // reqBody.selectedPathList = reqBody.selectedPathList.splice(reqBody.selectedPathList.length - 1)
        // console.log(reqBody);
        const { data }: any = await api({
          url: `/schedule/add/${siteId}`,
          method: "post",
          body: reqBody,
        });
        if (data.serverResponse.isError) {
          toast.error(data.serverResponse.message);
        } else {
          saveAndRemoveClick();
          toast.success("Schedule created successfully");
        }
      } catch (err) {
        // @ts-ignore
        err.response && toast.error(err.message);
      }
    }
  };

  const handleLocationValue = (e: any, index: number) => {
    formik.setFieldValue(
      `selectedPathList[${index}].location_id`,
      e.target.value
    );
  };

  const isValidTimeSelection = (timeSlots) => {
    if (!timeSlots) return;

    // compare each slot to every other slot
    for (let i = 0; i < timeSlots.length; i++) {
      const slot1 = timeSlots[i];

      if (!slot1.start || !slot1.end) continue;

      const start1 = moment(slot1.start, "HH:mm");
      const end1 = moment(slot1.end, "HH:mm");

      for (let j = 0; j < timeSlots.length; j++) {
        // prevent comparision of slot with itself
        if (i === j) continue;

        const slot2 = timeSlots[j];

        if (!slot2.start || !slot2.end) continue;
        const start2 = moment(slot2.start, "HH:mm");
        const end2 = moment(slot2.end, "HH:mm");

        if (
          start2.isBetween(start1, end1, undefined, "()") ||
          end2.isBetween(start1, end1, undefined, "()")
        ) {
          return `Overlapping time in slot ${j + 1}`;
        }
      }
    }
    // All time slots are are valid
    return "";
  };

  const isValidLocationSelection = (locationSlots) => {
    if (!locationSlots) return;

    // compare each slot to every other slot
    for (let i = 0; i < locationSlots.length; i++) {
      const slot1 = locationSlots[i];

      if (!slot1.location_id) continue;

      for (let j = 0; j < locationSlots.length; j++) {
        // prevent comparision of slot with itself
        if (i === j) continue;

        const slot2 = locationSlots[j];

        if (!slot2.location_id) continue;

        if (slot2.location_id === slot1.location_id) {
          return `Overlapping location in slot ${j + 1}`;
        }
      }
    }
    // All time slots are are valid
    return "";
  };

  const handleSelectedStatus = (e: any) => {
    formik.setFieldValue("selectedStatus", e.target.value);
  };

  const handleLocationUpdate = (e: any, index: number) => {
    if (e.target.value === "AUTO") {
      formik.setFieldValue(`selectedPathList[${index}].auto`, true);
    } else {
      formik.setFieldValue(`selectedPathList[${index}].auto`, false);
      formik.setFieldValue(
        `selectedPathList[${index}].manual_status`,
        e.target.value
      );
    }
  };

  const option =
    carList?.map((item: any) => ({
      label: `${item.name} ${item?.seats ? `(${item?.seats})` : ""}`,
      seats: item?.seats || 0,
      value: item._id,
    })) || [];

  useEffect(() => {
    getLocationList();
    getCarList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showNormalDriverField || showBackupDriverField) {
      getDriverList();
    }
    // eslint-disable-next-line
  }, [showBackupDriverField, showNormalDriverField]);

  const getCarSeats = (pathData: any, index: any) => {
    const prevRemainSeat =
      formik.values.selectedPathList[index === 0 ? 0 : index - 1];
    const remained = index === 0 ? 0 : prevRemainSeat.seat || 0;

    const totalSeats =
      option.filter((x: any) => x.value === formik.values.selectedCar)[0]
        ?.seats || 0;

    let carSeats = totalSeats;

    carSeats = index !== 0 ? remained || 0 : carSeats;

    const onSeat: any = pathData?.remain_seat?.peopleOnBoard || 0;
    const offSeat: any = pathData?.remain_seat?.peopleOffBoard || 0;

    let availableSeats = carSeats - onSeat + offSeat;

    availableSeats =
      index === formik.values.selectedPathList.length - 1 || isHistoryMode
        ? ""
        : availableSeats;

    if (pathData.remain_seat?.clearCar) {
      availableSeats = totalSeats;
    }

    availableSeats = availableSeats < 0 ? 0 : availableSeats;

    availableSeats = availableSeats > totalSeats ? totalSeats : availableSeats;

    return availableSeats;
  };

  useEffect(() => {
    if (
      isEditMode &&
      localScheduleDetail.selectedCar !== formik.values.selectedCar
    ) {
      const allData = eventsData
        .filter((x: any) => x.resourceId === formik.values.selectedCar)
        .filter((x: any) => new Date(x?.start) > new Date());

      setConflict(Boolean(allData.length));
    } else {
      setConflict(false);
    }
    // eslint-disable-next-line
  }, [formik.values.selectedCar]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="remove-from-blacklist-modal"
      size={isEditMode ? "xl" : "lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="example-modal-sizes-title-lg"
          className="d-flex justify-content-between w-100"
        >
          {headerText}
          {isEditMode ? (
            <div>
              <input
                type="checkbox"
                checked={applyCheck}
                onClick={() => setApplyCheck(!applyCheck)}
                className="mr-2"
              />
              Apply for today's coming schedule
            </div>
          ) : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <div className="mt-5">
            <div className="form-group">
              <div className="row">
                {/* <div className="align-items-center col-1 text-center">
                  <label className="font-size-h4">Car</label>
                </div> */}
                <div className="col-3">
                  {/* <select
                      className="form-control top-select rounded"
                      name="selectedCar"
                      disabled={isHistoryMode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.selectedCar}
                      style={{ paddingRight: "3rem" }}
                    >
                      <option value="" disabled>
                        -- Select --
                      </option>
                      {carList?.length > 0 &&
                        carList?.map((option: any) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                    </select> */}
                  <div style={{ zIndex: 100 }}>
                    <label style={{ fontSize: "17.55px", fontWeight: "500" }}>
                      Car
                    </label>
                    <SearchSelect
                      onChange={(opt: any) => {
                        const findDriver = driverList.find(
                          (x: any) => x?.carId === opt?.value
                        );
                        formik.setFieldValue(
                          "normalDriver",
                          findDriver?.value || ""
                        );

                        formik.setFieldValue("selectedCar", opt.value);
                      }}
                      options={option}
                      value={
                        option.filter(
                          (x: any) => x.value === formik.values.selectedCar
                        )[0]
                      }
                    />
                  </div>

                  {formik.touched.selectedCar && formik.errors.selectedCar ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.selectedCar}
                    </div>
                  ) : null}
                </div>
                {!isEditMode && (
                  <>
                    <div className="col-3">
                      <label style={{ fontSize: "17.55px", fontWeight: "500" }}>
                        Status
                      </label>
                      <SearchSelect
                        onChange={(opt: any) => {
                          formik.setFieldValue("selectedStatus", opt.value);
                        }}
                        options={statusList}
                        value={
                          statusList.filter(
                            (x: any) => x.value === formik.values.selectedStatus
                          )[0]
                        }
                      />
                      {formik.touched.selectedStatus &&
                      formik.errors.selectedStatus ? (
                        <div className="text-danger mt-1 ml-1">
                          {formik.errors.selectedStatus}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}

                {showNormalDriverField ? (
                  <div className="col-3">
                    <label style={{ fontSize: "17.55px", fontWeight: "500" }}>
                      Normal Driver
                    </label>
                    <div style={{ zIndex: "2000" }}>
                      <SearchSelect
                        onChange={(opt: any) => {
                          formik.setFieldValue("normalDriver", opt.value);
                        }}
                        isDisabled={true}
                        options={driverList}
                        value={
                          driverList.filter(
                            (x: any) => x.value === formik.values.normalDriver
                          )[0]
                        }
                      />
                    </div>
                    {formik.touched.normalDriver &&
                    formik.errors.normalDriver ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.normalDriver}
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {showBackupDriverField ? (
                  <div className="col-3">
                    <label style={{ fontSize: "17.55px", fontWeight: "500" }}>
                      Backup Driver
                    </label>
                    <div style={{ zIndex: "2000" }}>
                      <SearchSelect
                        onChange={(opt: any) => {
                          if (opt) {
                            formik.setFieldValue("backupDriver", opt.value);
                          } else {
                            formik.setFieldValue("backupDriver", "");
                          }
                        }}
                        isClearable={true}
                        options={driverList}
                        value={
                          driverList.filter(
                            (x: any) => x.value === formik.values.backupDriver
                          )[0]
                        }
                      />
                    </div>
                    {formik.touched.backupDriver &&
                    formik.errors.backupDriver ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.backupDriver}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <FieldArray name="selectedPathList" validateOnChange>
                {({
                  form: { values, setValues, validateForm },
                  ...fieldArrayHelpers
                }) => {
                  const onAddClick = async () => {
                    await fieldArrayHelpers.push({
                      location_id: "",
                      start: null,
                      end: null,
                      seat: "",
                      station_code: "",
                      people: "",
                    });
                  };

                  const closeClick = async (removeIndex) => {
                    await fieldArrayHelpers.remove(removeIndex);
                    if (removeIndex === values.selectedPathList.length - 1) {
                      formik.setFieldValue(
                        `selectedPathList[${removeIndex - 1}].start`,
                        null
                      );
                      formik.setFieldValue(
                        `selectedPathList[${removeIndex - 1}].end`,
                        null
                      );
                    }
                  };

                  return (
                    <>
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Location</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">End Time</th>
                            {isEditMode && <th scope="col">ETA Time</th>}
                            <th scope="col">Available Seat</th>
                            <th scope="col">Station Code</th>
                            {isEditMode && <th scope="col">Status</th>}
                            {isEditMode && <th scope="col">Reached</th>}
                            {/* <th scope="col"> People </th> */}
                            <th scope="col"> &nbsp; </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.selectedPathList.map(
                            (pathData: any, index: any) => {
                              const carSeats =
                                option.filter(
                                  (x: any) =>
                                    x.value === formik.values.selectedCar
                                )[0]?.seats || 0;

                              const availableSeats = getCarSeats(
                                pathData,
                                index
                              );

                              pathData.seat = availableSeats;

                              const seatColor = !carSeats
                                ? "#9E9E9E" // SEAT NOT AVAILABLE (GRAY)
                                : index === 0 && pathData.reached === false
                                ? "#00Ac5B" // INITIAL LOCATION AND NOT REACHED YET (GREEN)
                                : availableSeats > 15
                                ? "#00Ac5B" // MORE THAN 20 (GREEN)
                                : availableSeats >= 11 && availableSeats <= 15
                                ? "#4C84F0" // 11-15 (ORANGE)
                                : availableSeats >= 6 && availableSeats <= 10
                                ? "#F8AA00" // 6-10 (YELLOW)
                                : availableSeats >= 1 && availableSeats <= 5
                                ? "#D70000" // 1-5 (RED)
                                : "#9E9E9E"; // ZERO SEATS (GRAY)

                              return (
                                <tr key={`content-${index}`}>
                                  <td style={{ width: "2%" }}>
                                    {index ===
                                    formik.values.selectedPathList.length - 1
                                      ? "Last"
                                      : index + 1}
                                  </td>

                                  <td
                                    style={{ width: "20%" }}
                                    className="position-relative"
                                  >
                                    <select
                                      className="form-control top-select rounded"
                                      name={`selectedPathList[${index}].location_id`}
                                      onChange={(e) =>
                                        handleLocationValue(e, index)
                                      }
                                      onBlur={formik.handleBlur}
                                      value={pathData.location_id || ""}
                                      style={{ paddingRight: "2rem" }}
                                      disabled={isHistoryMode}
                                    >
                                      <option value="" disabled>
                                        -- Select --
                                      </option>
                                      {locationList?.length > 0 &&
                                        locationList.map((option: any) => (
                                          <option
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </option>
                                        ))}
                                    </select>
                                    <ExpandMoreRoundedIcon
                                      style={{
                                        position: "absolute",
                                        right: "1rem",
                                        // zIndex: 9,
                                        top: "16px",
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`selectedPathList[${index}].location_id`}
                                      component="div"
                                      className="text-danger font-size-sm"
                                    />
                                  </td>

                                  <td
                                    style={{
                                      width: isEditMode ? "10%" : "20%",
                                    }}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className="form-control"
                                        id={`selectedPathList[${index}].start`}
                                        name={`selectedPathList[${index}].start`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={pathData.start || ""}
                                        disabled={
                                          index ===
                                            formik.values.selectedPathList
                                              .length -
                                              1 || isHistoryMode
                                        }
                                      />
                                      <ErrorMessage
                                        name={`selectedPathList[${index}].start`}
                                        component="div"
                                        className="text-danger font-size-sm"
                                      />
                                    </div>
                                  </td>

                                  <td
                                    style={{
                                      width: isEditMode ? "10%" : "20%",
                                    }}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="time"
                                        className={"form-control"}
                                        id={`selectedPathList[${index}].end`}
                                        name={`selectedPathList[${index}].end`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={pathData.end || ""}
                                        disabled={
                                          index ===
                                            formik.values.selectedPathList
                                              .length -
                                              1 || isHistoryMode
                                        }
                                      />
                                      <ErrorMessage
                                        name={`selectedPathList[${index}].end`}
                                        component="div"
                                        className="text-danger font-size-sm"
                                      />
                                    </div>
                                  </td>

                                  {isEditMode && (
                                    <td style={{ width: "10%" }}>
                                      <div className="form-group">
                                        <input
                                          type="time"
                                          className={
                                            pathData.ETA &&
                                            pathData.ETA > pathData.start
                                              ? "form-control text-danger"
                                              : "form-control"
                                          }
                                          id={`selectedPathList[${index}].end`}
                                          name={`selectedPathList[${index}].end`}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={pathData.ETA || ""}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                  )}

                                  <td
                                    style={{
                                      width: isEditMode ? "13%" : "16%",
                                    }}
                                  >
                                    <div className="form-group d-flex">
                                      <div
                                        className="seat_color_tag"
                                        style={{
                                          backgroundColor: seatColor,
                                        }}
                                      ></div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`selectedPathList[${index}].seat`}
                                        name={`selectedPathList[${index}].seat`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={pathData.seat || "0"}
                                        // value={availableSeats}
                                        disabled
                                        style={{ margin: "0px 10px" }}
                                        // disabled={
                                        // index ===
                                        //   formik.values.selectedPathList
                                        //     .length -
                                        //     1 || isHistoryMode
                                        // }
                                      />
                                      {index ===
                                        formik.values.selectedPathList.length -
                                          1 || isHistoryMode ? (
                                        <div style={{ minWidth: 15 }} />
                                      ) : (
                                        <EditIcon
                                          onClick={() => {
                                            history.push(
                                              `/ScheduleReport/details/${scheduleData.extendedProps.scheduleId}/${pathData._id}`
                                            );
                                          }}
                                          style={{
                                            width: "15px",
                                            margin: "auto 0",
                                            cursor: "pointer",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    style={{ width: isEditMode ? "7%" : "10%" }}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`selectedPathList[${index}].station_code`}
                                        name={`selectedPathList[${index}].station_code`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={pathData.station_code || ""}
                                        // disabled={index === 0}
                                        disabled={
                                          index ===
                                            formik.values.selectedPathList
                                              .length -
                                              1 || isHistoryMode
                                        }
                                      />
                                    </div>
                                  </td>

                                  {isEditMode && (
                                    <td
                                      style={{ width: "13%" }}
                                      className="position-relative"
                                    >
                                      <div className="form-group">
                                        <select
                                          className="form-control top-select rounded"
                                          name="selectedCar"
                                          // onChange={(e) => handleSelectedStatus(e)}
                                          value={
                                            pathData.auto
                                              ? pathData.status || "NORMAL"
                                              : pathData.manual_status ||
                                                "NORMAL"
                                          }
                                          onChange={(e) =>
                                            handleLocationUpdate(e, index)
                                          }
                                          onBlur={formik.handleBlur}
                                          // style={{ paddingRight: "3rem" }}
                                          disabled={
                                            index ===
                                              formik.values.selectedPathList
                                                .length -
                                                1 || isHistoryMode
                                          }
                                        >
                                          <option value="" disabled>
                                            -- Select --
                                          </option>
                                          {pathStatusList?.length > 0 &&
                                            pathStatusList?.map(
                                              (option: any, index) => (
                                                <option
                                                  key={index}
                                                  value={option}
                                                >
                                                  {option}
                                                </option>
                                              )
                                            )}
                                        </select>
                                        <ExpandMoreRoundedIcon
                                          style={{
                                            position: "absolute",
                                            right: "1rem",
                                            // zIndex: 9,
                                            top: "16px",
                                          }}
                                        />
                                        {formik.touched.selectedStatus &&
                                        formik.errors.selectedStatus ? (
                                          <div className="text-danger mt-1 ml-1">
                                            {formik.errors.selectedStatus}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  )}

                                  {isEditMode && (
                                    <td
                                      className="position-relative"
                                      style={{ width: "5%" }}
                                    >
                                      <div
                                        className="form-group justify-content-center"
                                        style={{
                                          paddingTop: "12px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <input
                                          className="form-check-input"
                                          disabled={
                                            index ===
                                              formik.values.selectedPathList
                                                .length -
                                                1 || isHistoryMode
                                          }
                                          onChange={formik.handleChange}
                                          type="checkbox"
                                          name={`selectedPathList[${index}].reached`}
                                          checked={pathData.reached}
                                        />
                                        {formik.touched.selectedStatus &&
                                        formik.errors.selectedStatus ? (
                                          <div className="text-danger mt-1 ml-1">
                                            {formik.errors.selectedStatus}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  )}

                                  {/* <td
                                    style={{ width: isEditMode ? "7%" : "10%" }}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={`selectedPathList[${index}].people`}
                                        name={`selectedPathList[${index}].people`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={pathData.people || ""}
                                        // disabled={index === 0}
                                        disabled={
                                          index ===
                                            formik.values.selectedPathList
                                              .length -
                                              1 || isHistoryMode
                                        }
                                      />
                                    </div>
                                  </td> */}

                                  <td>
                                    {formik.values.selectedPathList &&
                                    formik.values.selectedPathList.length >
                                      2 ? (
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          !isHistoryMode && closeClick(index);
                                        }}
                                      >
                                        <SVG
                                          src={
                                            isHistoryMode
                                              ? toAbsoluteUrl(
                                                  "/media/svg/icons/General/DeleteDisabled.svg"
                                                )
                                              : toAbsoluteUrl(
                                                  "/media/svg/icons/General/Delete.svg"
                                                )
                                          }
                                          width={30}
                                          height={30}
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                      <div className="text-danger text-center">
                        {isValidTimeSelection(values.selectedPathList)}
                      </div>
                      <div className="text-danger text-center">
                        {isValidLocationSelection(values.selectedPathList)}
                      </div>
                      <div className="row m-3">
                        <div className="col-12 d-flex justify-content-center align-items-center text-center">
                          <span
                            className="cursor-pointer"
                            onClick={() => !isHistoryMode && onAddClick()}
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Add.svg"
                              )}
                            />
                          </span>
                          <span
                            className="mr-2 mx-sm-2 font-size-h3 cursor-pointer"
                            onClick={() => !isHistoryMode && onAddClick()}
                          >
                            Add Location
                          </span>
                        </div>
                      </div>
                    </>
                  );
                }}
              </FieldArray>
            </div>
          </div>
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer className="cust-mdl-ftr d-block">
        <div className="row">
          <div className="col-6">
            {Boolean(isEditMode) ? (
              <button
                type="button"
                disabled={isHistoryMode}
                onClick={() => {
                  !isHistoryMode && removeAction();
                }}
                className={
                  isHistoryMode
                    ? `btn btn-default mr-2`
                    : `btn btn-${dangerActionStyle} btn-elevate mr-2`
                }
              >
                {dangerActionText}
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="col-6 col-6 d-flex justify-content-end">
            <button
              type="button"
              disabled={isHistoryMode}
              onClick={() => {
                if (isConflict && applyCheck && !isHistoryMode) {
                  MySwal.fire({
                    icon: "warning",
                    title: "Confirmation",
                    text:
                      "Have conflict with other schedule, would you like to continue this process?",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      !isHistoryMode && formik.handleSubmit();
                    } else {
                      onHide();
                    }
                  });
                } else {
                  !isHistoryMode && formik.handleSubmit();
                }
              }}
              className={
                isHistoryMode
                  ? `btn btn-light btn-elevate mr-2`
                  : `btn btn-${actionStyle} btn-elevate mr-2`
              }
            >
              {actionText}
            </button>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
