import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { Switch } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import { store } from "../../../redux/store";
import moment from "moment";

interface Values {
  emptyCar: any;
  passengers_getting_on: string;
  passengers_getting_off: string;
}
interface Params {
  schedule_id?: string;
  location_id?: string;
}

const ScheduleReportDetails = () => {
  const params: Params = useParams();
  const [scheduleDetails, setScheduleDetails] = useState<any>(null);
  const [latestReportData, setLatestReportData] = useState<any>(null);
  const [driverDetails, setDriverDetails] = useState<any>(null);
  const history = useHistory();

  const isNewReport = !Boolean(latestReportData);
  const scheduleLocation = scheduleDetails?.location?.find(
    (x: any) => x._id === params.location_id
  );

  const updateScheduleReport = async (values: Values) => {
    const body: any = {};
    body["pathId"] = {
      _id: scheduleDetails._id,
      title: scheduleDetails.title,
    };
    body["destination"] =
      latestReportData?.destination || scheduleLocation?.location_id?.code;
    body["carId"] = scheduleDetails?.carId || "";
    body["siteId"] = scheduleDetails?.siteId?._id || "";
    if (scheduleDetails?.normalDriver || scheduleDetails?.backupDriver) {
      body["driverId"] =
        scheduleDetails?.normalDriver || scheduleDetails?.backupDriver;
    }
    body["passengers_getting_on"] = values.passengers_getting_on;
    body["passengers_getting_off"] = values.passengers_getting_off;
    body["emptyCar"] = values.emptyCar;
    body["status"] = isNewReport ? scheduleLocation.status : "已完成";
    body["scheduleId"] = params.schedule_id;
    body["locationId"] = params.location_id;
    body["schedule_time"] =
      scheduleLocation?.start || latestReportData?.schedule_time;
    try {
      const { data }: any = await api({
        url: `schedule/report/save/`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Data updated Successfully");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emptyCar: latestReportData?.emptyCar || false,
      passengers_getting_on: latestReportData?.passengers_getting_on || 0,
      passengers_getting_off: latestReportData?.passengers_getting_off || 0,
    },
    validationSchema: Yup.object().shape(
      {
        passengers_getting_on: Yup.string().required(
          "Passengers Getting on is required"
        ),
        passengers_getting_off: Yup.string().required(
          "Passengers Getting off is required"
        ),
      },
      [["reason", "otherReason"]]
    ),
    onSubmit: (values: Values) => {
      updateScheduleReport(values);
    },
  });

  const fetchDriverDetails = async (id: string) => {
    try {
      const { data }: any = await api({
        url: `/user/details/${id}`,
        method: "get",
      });
      const item: any = data.result.data;
      if (item) {
        setDriverDetails(item);
      } else {
        setDriverDetails(null);
      }
    } catch (err) {}
  };

  const getLatestReportById = async () => {
    try {
      const { data }: any = await api({
        url: `/schedule/report/details/${params.schedule_id}/${params.location_id}`,
        method: "get",
      });
      const item: any = data.result.data;
      if (item) {
        setLatestReportData(item);
      } else {
        setLatestReportData(null);
      }
    } catch (err) {}
  };

  const fetchScheduleDetails = async () => {
    try {
      const { data }: any = await api({
        url: `/schedule/details/${params.schedule_id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: any = data.result.data;
        setScheduleDetails(item);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchScheduleDetails();
    params.schedule_id && params.location_id && getLatestReportById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (isNewReport && scheduleDetails) {
      if (scheduleDetails?.normalDriver || scheduleDetails?.backupDriver) {
        fetchDriverDetails(
          scheduleDetails?.normalDriver || scheduleDetails.backupDriver
        );
      }
    }

    // eslint-disable-next-line
  }, [isNewReport, scheduleDetails]);

  return (
    <div>
      <Card>
        <CardHeader title="Schedule Report Details"></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Site</div>
            <div className="col-4">
              {isNewReport
                ? scheduleDetails?.siteId?.name
                : latestReportData?.siteId?.name}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Date</div>
            <div className="col-4">
              {params?.schedule_id && params?.location_id
                ? moment(latestReportData?.createdAt).format(
                    "YYYY/MM/DD HH:mm:ss"
                  )
                : moment(Date.now()).format("YYYY/MM/DD HH:mm:ss")}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Path</div>
            <div className="col-4">
              {latestReportData?.pathId?.title || scheduleDetails?.title}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Passengers Getting on
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="passengers_getting_on"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passengers_getting_on}
                />
                {formik.touched.passengers_getting_on &&
                formik.errors.passengers_getting_on ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.passengers_getting_on}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Passengers Getting off
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="passengers_getting_off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passengers_getting_off}
                />
                {formik.touched.passengers_getting_off &&
                formik.errors.passengers_getting_off ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.passengers_getting_off}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Empty Car
            </div>
            <div className="col-4">
              <div className="form-group">
                <Switch
                  name="emptyCar"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emptyCar || ""}
                  checked={
                    formik.values.emptyCar ? formik.values.emptyCar : false
                  }
                />
                {formik.touched.emptyCar && formik.errors.emptyCar ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.emptyCar}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Origins</div>
            <div className="col-4">
              {latestReportData?.destination ||
                scheduleLocation?.location_id?.code}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Schedule Time</div>
            <div className="col-4">
              {latestReportData?.schedule_time || scheduleLocation?.start}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Arrival Time</div>
            <div className="col-4">
              {latestReportData?.arrival_time || "N/A"}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Departure Time</div>
            <div className="col-4">
              {latestReportData?.departureTime || "N/A"}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">
              Delayed Time (mins)
            </div>
            <div className="col-4">
              {latestReportData?.delayedTime || "N/A"}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 ws-nowrap">Driver</div>
            <div className="col-4">
              {isNewReport
                ? driverDetails?.name
                : latestReportData?.driverId?.name}
            </div>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Status
            </div>
            <div className="col-4">
              {isNewReport
                ? scheduleLocation?.status
                : latestReportData?.status}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/scheduleReport">
                Back
              </Link>
            </div>
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default ScheduleReportDetails;
