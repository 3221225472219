import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { Select } from "../../../common/components/Select";
import { useFormik } from "formik";
import MultipleDatesPicker from "@ambiot/material-ui-multiple-dates-picker";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";
import { api } from "../../../common/api";
import { toast } from "react-toastify";

interface Values {
  name: string;
  dates: any;
  status: string;
  siteId: string;
}
interface Params {
  id?: string;
}

const AddPreschedule = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const siteId = searchParams.get("siteId");
  const isSiteId = siteId ? `?siteId=${siteId}` : "";
  const params: Params = useParams();
  const history = useHistory();

  const [entities, setEntities] = useState<any>([]);
  const [editEntities, setEditEntities] = useState<any>();
  const [preScheduleEntities, setPreScheduleEntities] = useState<any>([]);
  const [showEditPreSchedule, setShowEditPreSchedule] = useState<boolean>(
    false
  );
  const [templateId, setTemplateId] = useState<string>("");
  const [openCalendar, setOpenCalendar] = useState(false);

  const getPreScheduleDetails = async () => {
    try {
      const { data }: any = await api({
        url: `/pre-schedule/details/${params.id}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: any = data.result.data;
        setEditEntities({
          ...item,
          status: item.isActive ? "Active" : "Inactive",
        });
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getPreScheduleEntities = async (page = 1, sizePerPage = 10) => {
    try {
      const { data }: any = await api({
        url: `/pre-schedule/list`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setPreScheduleEntities(data.result.data.preScheduleData);
      }
    } catch (err) {}
  };

  const getEntities = async () => {
    try {
      const { data }: any = await api({
        url: "site/list",
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.siteData.map(
          (entity: any, ind: number) => {
            return {
              id: entity._id,
              name: entity.name,
              templates: entity.templates,
            };
          }
        );
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    getPreScheduleEntities();
    params.id && getPreScheduleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddPrescheduleData = async (values: Values) => {
    let body = {
      name: values.name,
      siteId: values.siteId,
      dates: values.dates,
      isActive: values.status,
    };
    if (searchParams.get("templateId")) {
      body["templateId"] = searchParams.get("templateId");
    }
    if (searchParams.get("preScheduleId")) {
      body["preScheduleId"] = searchParams.get("preScheduleId");
    }
    try {
      const { data }: any = await api({
        url: `/pre-schedule/add`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setShowEditPreSchedule(true);

        setTemplateId(data.result.data._id);
        toast.success("Pre-Schedule created successfully");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const UpdatePrescheduleData = async (values: Values) => {
    let body = {
      name: values.name,
      siteId: values.siteId,
      dates: values.dates,
      isActive: values.status,
    };
    try {
      const { data }: any = await api({
        url: `/pre-schedule/update/${params.id}`,
        method: "put",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Data updated successfully");
        history.push(`/pre-schedule${isSiteId}`);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? editEntities && editEntities?.name : "",
      dates: params.id ? editEntities && editEntities?.dates : [],
      status: params.id ? editEntities && editEntities?.isActive : true,
      siteId: params.id
        ? editEntities && editEntities?.siteId?._id
        : siteId
        ? siteId
        : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      dates: Yup.array().min(1, "Date is required"),
      status: Yup.string().required("Status is required"),
      siteId: Yup.string().required("Site is required"),
    }),
    onSubmit: (values: Values) => {
      params.id ? UpdatePrescheduleData(values) : AddPrescheduleData(values);
    },
  });

  const newFinalDates: any = [];

  const isExistPreScheduleForSite = formik.values.dates?.length
    ? preScheduleEntities
        .filter((x: any) => x.siteId === formik.values.siteId)
        .map((x) => {
          const newDates = x.dates.map((y: any) => y).join(",");
          return newDates;
        })
        .join(",")
        .split(",")
        .filter((y: any) => {
          return formik.values.dates?.indexOf(y) !== -1;
        })
    : [];

  return (
    <div>
      <Card>
        <CardHeader
          title={params.id ? "Edit Pre-Schedule" : "Add Pre-Schedule"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Pre-Schedule Name</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name || ""}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row ">
                <div className="col-lg-4 ws-nowrap">Site</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <select
                      className="form-control top-select rounded"
                      name="siteId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.siteId || ""}
                      style={{ paddingRight: "3rem" }}
                    >
                      <option value="" disabled>
                        -- Select --
                      </option>
                      {entities?.length > 0 &&
                        entities.map((option: any) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "2rem",
                        zIndex: 9,
                        top: "6px",
                      }}
                    />
                    {formik.touched.siteId && formik.errors.siteId ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.siteId}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Date</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      onClick={() => setOpenCalendar(true)}
                      value={formik.values.dates || ""}
                    />
                    <MultipleDatesPicker
                      open={openCalendar}
                      selectedDates={formik.values.dates?.map((x) => {
                        return new Date(`${moment(x).format("YYYY-MM-DD")}`);
                      })}
                      onCancel={() => setOpenCalendar(false)}
                      onSubmit={(dates: any) => {
                        const newDates = dates.map((x) => {
                          return `${moment(x).format("YYYY-MM-DD")}`;
                        });

                        formik.setFieldValue("dates", newDates);
                        setOpenCalendar(false);
                      }}
                    />
                    {formik.touched.dates && formik.errors.dates ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.dates}
                      </div>
                    ) : !params.id && isExistPreScheduleForSite?.length ? (
                      <div className="text-danger mt-1 ml-1">{`${isExistPreScheduleForSite?.join(
                        ", "
                      )} already assigned Schedule`}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Status</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <Select
                      value={formik.values.status}
                      setValue={(value: string) =>
                        formik.setFieldValue("status", value)
                      }
                      options={[
                        { label: "Active", value: true },
                        { label: "Inactive", value: false },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showEditPreSchedule || params.id ? (
            <div>
              <Link
                to={
                  params && params.id
                    ? `/pre-schedule/update/${params.id}/set/schedule${isSiteId}`
                    : `/pre-schedule/add/${templateId}/set/schedule${isSiteId}`
                }
              >
                Edit Pre-Schedule
              </Link>
            </div>
          ) : (
            ""
          )}
        </CardBody>

        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to={`/pre-schedule${isSiteId}`}>
                Back
              </Link>
            </div>
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                disabled={
                  params.id
                    ? false
                    : isExistPreScheduleForSite.length
                    ? true
                    : showEditPreSchedule
                }
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params?.id ? "Save" : "Add"}
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddPreschedule;
