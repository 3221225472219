import React, { useEffect, useState } from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Link } from "react-router-dom";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { sortCaret, headerSortingClasses } from "../../../helpers";
import { useHistory } from "react-router-dom";
import ActionIcon from "../../../../src/assets/Icons/action.svg";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardHeaderToolbar,
} from "../../../partials/controls";
import { sortString } from "../../../common/table/sorter";
import { api, downloadApiFiles } from "../../../common/api";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";

const LateReport = () => {
  const history = useHistory();

  const [totalData, setTotalData] = useState<number>(0);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortingType, setSortingType] = useState<any>("");
  const [driverList, setDriverList] = useState<any>([]);
  const [siteList, setSiteList] = useState<any>([]);
  // const [pathList, setPathList] = useState<any>([])
  const [isFetchClick, setIsFetchClick] = useState(false);

  const renderActionButton = () => {
    return (
      <img
        src={ActionIcon}
        style={{ marginLeft: "-15px" }}
        alt="action"
        width="30"
        height="30"
      ></img>
    );
  };

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isSortColumn = sortingType !== "" ? `&${sortingType}` : "";
    const startDate = formik.values.startDate
      ? `&startDate=${formik.values.startDate}`
      : "";
    const endDate = formik.values.endDate
      ? `&endDate=${formik.values.endDate}`
      : "";
    const driverIdUrl = formik.values.driver
      ? `&driverId=${formik.values.driver}`
      : "";
    const siteIdUrl = formik.values.site ? `&siteId=${formik.values.site}` : "";

    try {
      const { data }: any = await api({
        url: `/issue/report/list/?page=${page}&size=${sizePerPage}${isSortColumn}${startDate}${endDate}${driverIdUrl}${siteIdUrl}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.issueReportData.map(
          (entity: any, ind: number) => {
            return {
              ...entity,
              id: ++ind + (page - 1) * sizePerPage,
              status: entity.isActive ? "ACTIVE" : "INACTIVE",
              date: moment(entity.reportDate).format("YYYY/MM/DD HH:mm:ss"),
              name: entity?.driverId?.name,
              action: renderActionButton(),
              path: entity?.pathScheduleId.title,
            };
          }
        );
        setTotalData(data.result.data.countIssueReport);
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingType]);

  const columns = [
    {
      dataField: "name",
      text: "Driver",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "siteId.name",
      text: "Site",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "path",
      text: "Path",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "delayedTime",
      text: "Delayed Time (mins)",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "date",
      text: "Report Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      headerAlign: "center",
      align: "center",
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: (data: any) => history.push(`/lateReport/edit/${data._id}`),
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getDriverEntities = async () => {
    try {
      const { data }: any = await api({
        url: `/user/list/?role=DRIVER`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setDriverList(data.result.data.userData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  //   GET SITE LIST DATA
  const fetchSiteListData = async () => {
    try {
      const { data }: any = await api({
        url: "/site/list",
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setSiteList(data.result.data.siteData);
      }
    } catch (err) {}
  };

  // const getPathList = async (driverId: string) => {
  //   try {
  //     const { data }: any = await api({
  //       url: `/schedule/path/list/by/driver/${driverId}`,
  //       method: "get",
  //     });
  //     if (data.serverResponse.isError) {
  //       toast.error(data.serverResponse.message);
  //     } else {
  //       setPathList(data.result.data)
  //     }
  //   } catch (err) {
  //     // @ts-ignore
  //     err.response && toast.error(err.message);
  //   }
  // };

  const fetchData = () => {
    setIsFetchClick(true);
    formik.handleSubmit();
  };

  const exportData = () => {
    setIsFetchClick(false);
    formik.handleSubmit();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: "",
      endDate: "",
      driver: "",
      site: "",
      // path: ""
    },

    validationSchema: Yup.object({
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before Start date")
        .required("End Date is required"),
      driver: Yup.string(),
      site: Yup.string(),
      // path: Yup.string()
    }),

    onSubmit: async (values) => {
      if (isFetchClick) {
        setPage(1);
        setSizePerPage(10);
        getEntities();
      } else {
        // exportFunction(values);
        downlaodImageZip(values);
        // await Promise.all([exportFunction(values),downlaodImageZip(values)]);
      }
    },
  });

  useEffect(() => {
    getDriverEntities();
    fetchSiteListData();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (formik.values.driver) {
  //     getPathList(formik.values.driver)
  //   }
  // }, [formik.values.driver])

  const driverOptions =
    [
      { label: "All", value: "" },
      ...driverList?.map((driver: any) => ({
        label: driver.name,
        value: driver._id,
      })),
    ] || [];

  // SITE OPTIONS
  const siteOptions =
    [
      { label: "All", value: "" },
      ...siteList?.map((site: any) => ({
        label: site.name,
        value: site._id,
      })),
    ] || [];

  // const pathListOptions = pathList?.map((path: any) => ({
  //   label: `${path.title}`,
  //   value: path.scheduleId,
  // })) || [];

  const exportFunction = async (values) => {
    try {
      const driverIdUrl = values.driver ? `&driverId=${values.driver}` : ``;
      const siteIdUrl = formik.values.site
        ? `&siteId=${formik.values.site}`
        : "";
      const download: any = await downloadApiFiles({
        url: `/issue/report/driver/excel?startDate=${values.startDate}&endDate=${values.endDate}${driverIdUrl}${siteIdUrl}`,
        //pathschedule id comment line
        // url: `/issue/report/driver/excel?startDate=${values.startDate}&endDate=${values.endDate}&driverId=${values.driver}&pathScheduleId=${values.path}`,
        method: "get",
      });

      if (
        download.request.responseType === "blob" &&
        download.data instanceof Blob &&
        download.data.type &&
        download.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        let reader: any = new FileReader();
        reader.onload = () => {
          download.data = JSON.parse(reader.result);
          if (download.data.serverResponse.isError) {
            toast.error(download.data.serverResponse.message);
          }
        };
        reader.onerror = () => {};
        reader.readAsText(download.data);
      } else {
        const file = new Blob([download.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileUrl = window.URL.createObjectURL(file);
        const issueReport = document.createElement("a");
        issueReport.href = fileUrl;
        issueReport.setAttribute(
          "download",
          `${moment(values.startDate).format("YYYYMMDD")} - ${moment(
            values.endDate
          ).format("YYYYMMDD")}.xlsx`
        );
        issueReport.click();
        toast.success("File downloaded successfully");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const downlaodImageZip = async (values) => {
    try {
      const driverIdUrl = values.driver ? `&driverId=${values.driver}` : ``;
      const download: any = await downloadApiFiles({
        url: `/issue/report/driver/zip?startDate=${values.startDate}&endDate=${values.endDate}${driverIdUrl}`,
        method: "get",
      });

      if (
        download.request.responseType === "blob" &&
        download.data instanceof Blob &&
        download.data.type &&
        download.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        let reader: any = new FileReader();
        reader.onload = () => {
          download.data = JSON.parse(reader.result);
          if (download.data.serverResponse.isError) {
            toast.error(download.data.serverResponse.message);
          }
        };
        reader.onerror = () => {};
        reader.readAsText(download.data);
      } else {
        const file = new Blob([download.data], { type: "application/zip" });
        const fileUrl = window.URL.createObjectURL(file);
        const issueReport = document.createElement("a");
        issueReport.href = fileUrl;
        issueReport.setAttribute(
          "download",
          `${moment(values.startDate).format("YYYYMMDD")} - ${moment(
            values.endDate
          ).format("YYYYMMDD")}.zip`
        );
        issueReport.click();
        // toast.success("File downloaded successfully");
      }
      await exportFunction(values);
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader title={"Issue Report"}>
          <CardHeaderToolbar>
            <Link className="btn btn-primary" to="/lateReport/add">
              Add
            </Link>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Start Date</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="startDate"
                      name="startDate"
                      onChange={formik.handleChange}
                      value={formik.values.startDate}
                    />

                    {formik.touched.startDate && formik.errors.startDate ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.startDate}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">End Date</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="datetime-local"
                      onChange={formik.handleChange}
                      value={formik.values.endDate}
                      className="form-control"
                      id="title"
                      name="endDate"
                    />

                    {formik.touched.endDate && formik.errors.endDate ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.endDate}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Driver</div>
                <div className="col-lg-8">
                  <div className="input-group relative d-flex align-items-center">
                    <Select
                      name="driver"
                      options={driverOptions}
                      closeMenuOnSelect={true}
                      value={driverOptions.filter(
                        (x: any) => x.value === formik.values.driver
                      )}
                      onChange={(val: any) => {
                        formik.setFieldValue("driver", val.value);
                      }}
                      className="w-100 filter-select"
                      classNamePrefix="select"
                      placeholder="-- Select Driver --"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Site</div>
                <div className="col-lg-8">
                  <div className="input-group search-filter relative d-flex align-items-center">
                    <Select
                      name="site"
                      options={siteOptions}
                      closeMenuOnSelect={true}
                      value={siteOptions.filter(
                        (x: any) => x.value === formik.values.site
                      )}
                      onChange={(val: any) => {
                        formik.setFieldValue("site", val.value);
                      }}
                      className="w-100 filter-select"
                      classNamePrefix="select"
                      placeholder="-- Select Site --"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-12">
              <div className="row">
                <div className="col-lg-12">
                  <button
                    className="btn btn-primary btn-success float-right h-100"
                    name="fetch"
                    type="submit"
                    onClick={() => fetchData()}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="d-flex flex-wrap align-items-center flex-row-reverse">
            {/* <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-primary btn-success"
                name="fetch"
                type="submit"
                onClick={() => fetchData()}
              >
                Filter
              </button>
            </div> */}

            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => exportData()}
              >
                Export
              </button>
            </div>
          </div>
        </CardFooter>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <>
                <Table
                  getSelectRow={getSelectRow}
                  paginationProps={paginationProps}
                  paginationOptions={paginationOptions}
                  paginationTableProps={paginationTableProps}
                  data={entities}
                  columns={columns}
                  remote
                  onTableChange={(type: any, props: any) =>
                    onTableChange(type, props, paginationProps)
                  }
                />
              </>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};

export default LateReport;
