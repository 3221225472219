import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../helpers";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Datetime from "react-datetime";
import { store } from "../../../redux/store";
import Selects from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import SearchSelect from "react-select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import moment from "moment";
import CloseRounded from "@material-ui/icons/CloseRounded";

interface Values {
  reporter: string;
  driver: string;
  path: string;
  destination: string;
  nextDestination: string;
  time: any;
  reason: any;
  otherReason: string;
  currentLocation: string;
  image: any;
  car: string;
}
interface Params {
  id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const calculateDelayTime = (obj: any) => {
  let delayedTime: number = 0;

  if (obj?.reached === true && obj?.reachedTime !== null) {
    const reachedTime: any = moment(obj?.reachedTime)
      .format("HH:mm")
      .split(":");
    const startTime: any = String(obj?.locationStartTime).split(":");
    const reachedMin: number =
      parseInt(reachedTime[0]) * 60 + parseInt(reachedTime[1]);
    const startMin: number =
      parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
    delayedTime = Math.max(0, reachedMin - startMin);
  } else if (obj?.ETA) {
    const etaTime: any = String(obj.ETA).split(":");
    const startTime: any = String(obj.locationStartTime).split(":");
    const etaMin: number = parseInt(etaTime[0]) * 60 + parseInt(etaTime[1]);
    const startMin: number =
      parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
    delayedTime = Math.max(0, etaMin - startMin);
  } else {
    delayedTime = 0;
  }

  return delayedTime;
};

function getStyles(name: string, personName: string[], theme: Theme) {
  if (personName && personName.length) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  } else {
    return { fontWeight: theme.typography.fontWeightMedium };
  }
}

const AddLateReport = () => {
  const params: Params = useParams();
  // let delayedVal: any;
  // let arrivalVal: any;
  // let etaVal: any;
  const inputFile = useRef<HTMLInputElement | null>(null);
  const mimeType: string[] = ["image/png", "image/jpeg"];
  const [latestReportData, setLatestReportData] = useState<any>([]);
  const [driverList, setDriverList] = useState<any>([]);
  const [carList, setCarList] = useState<any>([]);
  const [reasonList, setReasonList] = useState<any>([]);
  const [pathList, setPathList] = useState<any>([]);
  const [destinationList, setDestinationList] = useState<any>([]);
  const [selectedImage, setSelectedImage] = useState<any>([]);
  const [showImg, setShowImg] = useState<any>("");
  const [ispathEdited, setIspathEdited] = useState<any>(false);
  const [show, setShow] = useState<any>(false);
  const [destinationToShow, setDestinationToShow] = useState<any>(null);
  const state: any = store.getState();
  const history = useHistory();

  const delayedTimeshow: any = ispathEdited
    ? calculateDelayTime(destinationList)
    : latestReportData?.delayedTime
    ? latestReportData?.delayedTime
    : calculateDelayTime(destinationList);

  const arrivalTimeVal: any = ispathEdited
    ? destinationList?.reached === false
      ? "N/A"
      : moment(destinationList?.reachedTime).format("HH:mm")
    : latestReportData?.arrivalTime === null
    ? "N/A"
    : latestReportData?.arrivalTime
    ? latestReportData?.arrivalTime
    : destinationList?.reached === false
    ? "N/A"
    : destinationList?.reachedTime
    ? moment(destinationList?.reachedTime).format("HH:mm")
    : null;
  const ETATimeVal: any = ispathEdited
    ? destinationList?.ETA
    : latestReportData?.etaTime
    ? latestReportData?.etaTime
    : destinationList?.ETA
    ? destinationList?.ETA
    : null;

  const classes = useStyles();
  const theme = useTheme();

  const removeImage = (src: any) => {
    let imageList =
      formik.values.image &&
      formik.values.image.filter((filterImg: any) => filterImg !== src);
    setSelectedImage(imageList);
  };

  useEffect(() => {
    if (selectedImage) {
      formik.setFieldValue("image", selectedImage);
    }
    // eslint-disable-next-line
  }, [selectedImage]);

  const uploadImage = async () => {
    if (inputFile.current?.files) {
      for (const element of inputFile.current.files) {
        if (mimeType.includes(element.type)) {
          const formData = new FormData();
          formData.append("file", element);
          try {
            const { data }: any = await api({
              url: "/file/file-upload",
              method: "post",
              body: formData,
            });
            if (data.serverResponse.isError) {
              formik.setFieldError("image", "image is required");
              toast.error(data.serverResponse.message);
            } else {
              selectedImage.push(data.data.fileUrl);
              formik.setFieldValue("image", selectedImage);
              toast.success("image uploaded");
            }
          } catch (err) {
            formik.setFieldError("image", "image is required");
            toast.error("image not uploaded, please try again");
          }
        }
      }
    }
  };

  const addLateReport = async (values: Values) => {
    let body = {
      driverId: values.driver,
      carId: values.car,
      remark: values.otherReason,
      gpsString: values.currentLocation,
      reportDate: moment(values.time).toISOString(),
      pathScheduleId: values.path,
      pathScheduleLocationId: values.destination,
      pathScheduleNextLocationId: values.nextDestination,
      image: values.image,
      reasonId: values.reason,
      lat: 0,
      long: 0,
    };

    try {
      const { data }: any = await api({
        url: params?.id
          ? `issue/report/update/${params?.id}`
          : `issue/report/add`,
        method: params?.id ? "put" : "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(
          params?.id ? "Data Updated Successfully" : "Data added successfully"
        );
        history.push("/lateReport");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reporter: state.user.displayName || state.user.name || "",
      driver: params.id
        ? latestReportData && latestReportData?.driverId?._id
        : "",
      car: params.id
        ? latestReportData && latestReportData?.pathScheduleId?.carId
        : "",
      path: params.id
        ? latestReportData && latestReportData?.pathScheduleId?._id
        : "",
      destination: params.id
        ? latestReportData && latestReportData?.pathScheduleLocationId?._id
        : "",
      nextDestination: params.id
        ? latestReportData &&
          latestReportData?.pathScheduleNextLocationId &&
          latestReportData?.pathScheduleNextLocationId?._id
        : "",
      time: params.id
        ? latestReportData &&
          latestReportData?.reportDate &&
          moment(latestReportData?.reportDate).format("yyyy/MM/DD HH:mm")
        : moment().startOf("day"),
      reason: params.id
        ? latestReportData &&
          latestReportData?.reasonId?.map((reason) => reason._id)
        : [],
      otherReason: params.id
        ? latestReportData && latestReportData?.remark
        : "",
      currentLocation: params.id
        ? latestReportData && latestReportData?.gpsString
        : "",
      image: params.id ? latestReportData && latestReportData?.image : "",
    },
    validationSchema: Yup.object().shape(
      {
        driver: Yup.string().required("Driver is required"),
        car: Yup.string().required("Car is required"),
        path: Yup.string().required("Path is required"),
        destination: Yup.string().required("Destination is required"),
        nextDestination: Yup.string().required("Next Destination is required"),
        time: Yup.string().required("Time is required"),
        reason: Yup.array().when("otherReason", {
          is: (otherReason) => !otherReason || otherReason?.length === 0,
          then: Yup.array()
            .min(1, "Pick at least 1 item")
            .required("Reason is required"),
          otherwise: Yup.array(),
        }),
        otherReason: Yup.string().when("reason", {
          is: (reason) => !reason || reason?.length === 0,
          then: Yup.string().required("Other Reason is required"),
          otherwise: Yup.string(),
        }),
        currentLocation: Yup.string().required("Current Location is required"),
        image: Yup.array()
          .min(1, "Please select at least one image")
          .required("Image is required"),
      },
      [["reason", "otherReason"]]
    ),
    onSubmit: (values: Values) => {
      addLateReport(values);
    },
  });

  useEffect(() => {
    if (formik.values.path) {
      const isEdited =
        formik.values.path !== latestReportData.pathScheduleId?._id;
      setIspathEdited(isEdited);
    }
  }, [formik.values.path]);

  // isEdited = formik.values.path !== latestReportData.pathScheduleId?._id;

  const dropHandler = (ev) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          let files = ev.dataTransfer.items[i].getAsFile();
          if (files) {
            try {
              if (mimeType.includes(files.type)) {
                const formData = new FormData();
                formData.append("file", files);
                api({
                  url: "/file/file-upload",
                  method: "post",
                  body: formData,
                }).then((response: any) => {
                  if (response.data.serverResponse.isError) {
                    formik.setFieldError("image", "image is required");
                    toast.error(response.data.serverResponse.message);
                  } else {
                    selectedImage.push(response.data.data.fileUrl);
                    formik.setFieldValue("image", selectedImage);
                    toast.success("image uploaded");
                  }
                });
              } else {
                toast.error("Please select image file type only");
              }
            } catch (e) {
              toast.error("image not uploaded , please try again");
            }
          } else {
            toast.error("File size limit not more than 200kb");
          }
        }
      }
    }
  };

  const getDriverEntities = async () => {
    try {
      const { data }: any = await api({
        url: `/user/list/?role=DRIVER`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setDriverList(data.result.data.userData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getCarEntities = async () => {
    try {
      const { data }: any = await api({
        url: `/car/list`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setCarList(data.result.data.carData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getReasonEntities = async () => {
    try {
      const { data }: any = await api({
        url: `/issue/reason/list`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setReasonList(data.result.data.issueReasonData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getPathList = async (driverId: string) => {
    try {
      const { data }: any = await api({
        url: `/schedule/path/list/by/driver/${driverId}/${moment(
          formik.values.time,
          "yyyy/MM/DD HH:mm"
        ).toISOString()}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setPathList(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getLatestReportById = async () => {
    try {
      const { data }: any = await api({
        url: `/issue/report/details/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: any = data.result.data;
        setLatestReportData({
          ...item,
          status: item.isActive ? "Active" : "Inactive",
        });
        setSelectedImage(item?.image ? item?.image : []);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const imageModalToggleHandler = () => {
    setShow(false);
    setShowImg("");
  };

  useEffect(() => {
    getDriverEntities();
    getReasonEntities();
    getCarEntities();
    params.id && getLatestReportById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.driver) {
      getPathList(formik.values.driver);
    }
  }, [formik.values.driver, formik.values.time]);

  const driverOptions =
    driverList?.map((driver: any) => ({
      label: driver.name,
      value: driver._id,
    })) || [];

  const carListOptions =
    carList?.map((car: any) => ({
      label: car.licPlateNoHk,
      value: car._id,
    })) || [];

  const reasonOptions =
    reasonList?.map((reason: any) => ({
      label: reason.name,
      value: reason._id,
    })) || [];

  let pathListOptions =
    pathList?.map((path: any) => ({
      label: `[${path.siteId.name}] ${path.title} (${path.locationStartTime})`,
      value: path.scheduleId,
    })) || [];

  useEffect(() => {
    if (formik.values.path) {
      let destinations = pathList.find(
        (destinationFind: any) =>
          destinationFind.scheduleId === formik.values.path
      );
      setDestinationList(destinations);
      if (params.id) {
        const destinationValtoPass = destinations?.destination[0];
        setDestinationToShow(destinationValtoPass);
        formik.setFieldValue("destination", destinationValtoPass?._id);
      }
    }
  }, [formik.values.path, pathList]);

  const handleDateTime = (timeFormat: any) => {
    formik.setFieldValue("time", timeFormat);
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    formik.setFieldValue("reason", event.target.value as string[]);
  };

  const dragOverHandler = (ev: any) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const onUploadFileButtonClick = () => {
    const fileUploadElement: any = document.getElementById("upload-image");
    if (fileUploadElement) {
      fileUploadElement.click();
    }
  };

  const pathIsAvilableInSchedulePath = Boolean(
    pathListOptions.filter((x: any) => x.value === formik.values.path)[0]
  );

  const newmissingPathOpt = {
    label: `[${latestReportData?.siteId?.name}] ${
      latestReportData?.pathScheduleId?.title
    } ${latestReportData?.startTime ? `(${latestReportData?.startTime})` : ""}`,
    value: latestReportData?.pathScheduleId?._id,
  };

  pathListOptions = params?.id
    ? !pathIsAvilableInSchedulePath
      ? [newmissingPathOpt, ...pathListOptions]
      : pathListOptions
    : pathListOptions;

  return (
    <div>
      <Card>
        <CardHeader
          title={
            Boolean(params.id) ? "Issue Report Details" : "Add Issue Report"
          }
        ></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Reporter
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>{formik.values.reporter || ""}</label>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Driver
            </div>
            <div className="col-4">
              <div className="form-group">
                <SearchSelect
                  onChange={(opt: any) => {
                    formik.setFieldValue("driver", opt.value);
                  }}
                  options={driverOptions}
                  value={
                    driverOptions.filter(
                      (x: any) => x.value === formik.values.driver
                    )[0]
                  }
                />

                {formik.touched.driver && formik.errors.driver ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.driver}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">Car</div>
            <div className="col-4">
              <div className="form-group">
                <SearchSelect
                  onChange={(opt: any) => {
                    formik.setFieldValue("car", opt.value);
                  }}
                  options={carListOptions}
                  value={
                    carListOptions.filter(
                      (x: any) => x.value === formik.values.car
                    )[0]
                  }
                />
                {formik.touched.car && formik.errors.car ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.car}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Report Date
            </div>
            <div className="col-4">
              <div className="form-group">
                {!params.id ? (
                  <Datetime
                    onChange={handleDateTime}
                    timeFormat="HH:mm"
                    dateFormat="yyyy/MM/DD"
                    value={formik.values.time}
                  />
                ) : (
                  <Datetime
                    onChange={handleDateTime}
                    timeFormat="HH:mm"
                    dateFormat="yyyy/MM/DD"
                    value={formik.values.time}
                  />
                  // <input
                  //   // disabled={Boolean(params.id)}
                  //   type="text"
                  //   className="form-control"
                  //   id="time"
                  //   name="time"
                  //   onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  //   value={formik.values.time}
                  // />
                )}

                {formik.touched.time && formik.errors.time ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.time}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">Path</div>
            <div className="col-4">
              <div className="form-group">
                {/* <select
                  // disabled={formik.values.driver === "" || Boolean(params.id)}
                  className="form-control top-select rounded"
                  name="path"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.path}
                  style={{ paddingRight: "3rem" }}
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  {pathListOptions?.length > 0 &&
                    pathListOptions?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "2rem",
                    zIndex: 9,
                    top: "6px",
                  }}
                /> */}
                <SearchSelect
                  onChange={(opt: any) => {
                    formik.setFieldValue("path", opt.value);
                  }}
                  options={pathListOptions}
                  value={
                    pathListOptions.filter(
                      (x: any) => x.value === formik.values.path
                    )[0]
                  }
                />
                {formik.touched.path && formik.errors.path ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.path}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* LOCATION */}
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Destination
            </div>
            {params.id ? (
              <div className="col-4">
                <div className="form-group">
                  <label>
                    {/* {destinationToShow?.fullNameEN
                      ? destinationToShow?.fullNameEN
                      : latestReportData?.pathScheduleLocationId?.name} */}
                    {latestReportData?.pathScheduleLocationId?.fullNameEN}
                  </label>
                </div>
              </div>
            ) : (
              <div className="col-4">
                <div className="form-group">
                  <select
                    disabled={
                      formik.values.driver === "" ||
                      formik.values.path === "" ||
                      Boolean(params.id)
                    }
                    className="form-control top-select rounded"
                    name="destination"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.destination}
                    style={{ paddingRight: "3rem" }}
                  >
                    <option value="" disabled>
                      -- Select --
                    </option>
                    {destinationList?.destination?.length > 0 &&
                      destinationList?.destination?.map((option: any) => (
                        <option key={option._id} value={option._id}>
                          {option.fullNameEN}
                        </option>
                      ))}
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "2rem",
                      zIndex: 9,
                      top: "6px",
                    }}
                  />
                  {formik.touched.destination && formik.errors.destination ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.destination}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>

          {/* NEXT DESTINATION */}
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Next Destination
            </div>
            {params.id ? (
              <div className="col-4">
                <div className="form-group">
                  <label>
                    {/* {destinationToShow?.fullNameEN
                      ? destinationToShow?.fullNameEN
                      : latestReportData?.pathScheduleLocationId?.name} */}
                    {latestReportData?.pathScheduleNextLocationId?.fullNameEN}
                  </label>
                </div>
              </div>
            ) : (
              <div className="col-4">
                <div className="form-group">
                  <select
                    disabled={
                      formik.values.driver === "" ||
                      formik.values.path === "" ||
                      Boolean(params.id)
                    }
                    className="form-control top-select rounded"
                    name="nextDestination"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nextDestination}
                    style={{ paddingRight: "3rem" }}
                  >
                    <option value="" disabled>
                      -- Select --
                    </option>
                    {destinationList?.destination?.length > 0 &&
                      destinationList?.destination?.map((option: any) => (
                        <option key={option._id} value={option._id}>
                          {option.fullNameEN}
                        </option>
                      ))}
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "2rem",
                      zIndex: 9,
                      top: "6px",
                    }}
                  />
                  {formik.touched.nextDestination &&
                  formik.errors.nextDestination ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.nextDestination}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Arrival Time
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>{arrivalTimeVal ? arrivalTimeVal : "N/A"}</label>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              ETA Time
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>{ETATimeVal ? ETATimeVal : "N/A"}</label>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Delayed Time (mins)
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  {!arrivalTimeVal &&
                  !ETATimeVal &&
                  !destinationList?.departureTime
                    ? 0
                    : delayedTimeshow}
                </label>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Reason
            </div>
            <div className="col-4">
              <div className="form-group">
                <Selects
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  name="reason"
                  multiple
                  // disabled={Boolean(params.id)}
                  value={formik.values.reason || []}
                  onChange={(e: any) => {
                    // const checkLength = e.target.value?.filter(
                    //   (x: any) => existingReasonValues?.indexOf(x) !== -1
                    // );

                    // if (params.id) {
                    //   if (checkLength.length === existingReasonValues.length) {
                    //     formik.setFieldValue("reason", e.target.value);
                    //   }
                    // } else {
                    //   handleChange(e);
                    // }
                    handleChange(e);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          label={
                            reasonOptions.find(
                              (option: any) => option.value === value
                            )?.label || ""
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {reasonOptions.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      style={getStyles(
                        option.value,
                        formik?.values?.reason,
                        theme
                      )}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Selects>
                {formik.touched.reason && formik.errors.reason ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.reason}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Other Reason
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  // disabled={Boolean(params.id)}
                  type="text"
                  className="form-control"
                  id="otherReason"
                  placeholder="Please enter other reasons or supplements (if any)"
                  name="otherReason"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otherReason || ""}
                />
                {formik.touched.otherReason && formik.errors.otherReason ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.otherReason}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Current Loaction
            </div>
            <div className="col-4">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="currentLocation"
                  rows={3}
                  // disabled={Boolean(params.id)}
                  value={formik.values.currentLocation || ""}
                  name="currentLocation"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                ></textarea>
                {formik.touched.currentLocation &&
                formik.errors.currentLocation ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.currentLocation}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-4 col-sm-2 mb-4 mb-sm-0  ws-nowrap ">Photo</div>
            <div className="col-8">
              {/* {!params.id && ( */}
              <div className="position-relative fit-content w-100">
                <div
                  style={{
                    padding: "2rem",
                    textAlign: "center",
                    background: "#FFF",
                    borderRadius: "0.5rem",
                    borderStyle: "dashed",
                  }}
                  onDrop={(e) => dropHandler(e)}
                  onDragOver={(e) => dragOverHandler(e)}
                  onClick={() => onUploadFileButtonClick()}
                >
                  <p>Drop files here or click to upload</p>
                  <input
                    type="file"
                    name="upload-image"
                    id="upload-image"
                    onChange={uploadImage}
                    accept={mimeType.join(",")}
                    className="w-100 h-100"
                    ref={inputFile}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
                {selectedImage.length > 0 && (
                  <div className="container border mt-4">
                    <div
                      className="row pt-1 pb-2 "
                      style={{ overflowX: "auto" }}
                    >
                      <div className="d-flex">
                        {formik.values.image &&
                          formik.values.image.map((src, index) => {
                            return (
                              <div key={index}>
                                <div className="position-relative fit-content m-2 cursor-pointer">
                                  {/* {!params.id && ( */}
                                  <CloseRounded
                                    className="position-absolute bg-secondary"
                                    onClick={() => {
                                      removeImage(src);
                                    }}
                                  />
                                  {/* )} */}
                                  <img
                                    style={{
                                      height: "100px",
                                      width: "150px",
                                      border: "1px solid gray",
                                    }}
                                    onClick={() => {
                                      setShowImg(src);
                                      setShow(true);
                                    }}
                                    src={src}
                                    alt=""
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
                {formik.touched.image && formik.errors.image ? (
                  <div className="text-danger mb-0 mt-4 pt-1">
                    {formik.errors.image}
                  </div>
                ) : null}
              </div>
              {/* )} */}

              {/* {params.id && formik.values.image && (
                <div className="container border">
                  <div className="row pt-1 pb-2 " style={{ overflowX: "auto" }}>
                    <div className="d-flex">
                      {formik.values.image &&
                        formik.values.image.map((src, index) => {
                          return (
                            <div key={index}>
                              <div className="position-relative fit-content m-2">
                                {!params.id && (
                                  <IconButton
                                    disableRipple
                                    disableFocusRipple
                                    className="position-absolute delete-image-btn"
                                    onClick={() => removeImage(src)}
                                  >
                                    <CloseRoundedIcon />
                                  </IconButton>
                                )}
                                <img
                                  style={{
                                    height: "100px",
                                    width: "150px",
                                    border: "1px solid gray",
                                  }}
                                  src={src}
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/lateReport">
                Back
              </Link>
            </div>
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params?.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>

      {/* IMAGE */}
      <Modal isOpen={show} toggle={imageModalToggleHandler} size="xl">
        <ModalBody>
          <img
            src={showImg}
            className="mw-100 "
            style={{ maxHeight: `${window.screen.height - 200}px` }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={imageModalToggleHandler}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddLateReport;
