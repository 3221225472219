import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
} from "../../../helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../partials/controls";
import { Link, useHistory } from "react-router-dom";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Reason: FC = () => {
  const history = useHistory();

  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(0);
  const [reasonList, setReasonList] = useState<any>([]);
  const [type, setType] = useState<string>("true");

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isQuery = query !== "" ? `&search=${query}` : "";
    const isType = type !== "" ? `&isActive=${type}` : "";
    try {
      const { data }: any = await api({
        url: `/issue/reason/list?page=${page}${isQuery}${isType}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const reasonList = data.result.data.issueReasonData.map(
          (entity: any, ind: number) => {
            return {
              ...entity,
              id: ++ind + (page - 1) * sizePerPage,
              isActive: entity.isActive ? "ACTIVE" : "INACTIVE",
            };
          }
        );
        setTotalData(data.result.data.issueReasonCount);
        reasonList.sort((a, b) => a.orderNumber - b.orderNumber)
        setReasonList(reasonList);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = ["", "SORT", "REASON", "STATUS"];

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const orderedItems = [
      {
        _id: reasonList[result.source.index]._id,
        orderNumber: reasonList[result.destination.index].orderNumber
      },
      {
        _id: reasonList[result.destination.index]._id,
        orderNumber: reasonList[result.source.index].orderNumber
      }
    ]

    try {
      const { data }: any = await api({
        url: `/issue/reason/reorder`,
        method: "post",
        body: { data: orderedItems },
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        getEntities()
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  }


  return (
    <Card>
      <CardHeader title="Reason">
        <CardHeaderToolbar>
          <Link className="btn btn-primary" to="/reason/add">
            Add
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <>
                <div className="d-flex flex-column flex-sm-row">
                  <FilterQuery query={query} setQuery={setQuery} title="Search" />
                  <StatusFilter
                    status={type}
                    setStatus={setType}
                    options={[
                      { label: "All", value: "" },
                      { label: "Active", value: "true" },
                      { label: "Inactive", value: "false" },
                    ]}
                    title="Type" />
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => {
                      getEntities(1);
                    }}
                  >
                    <span className="navigation-icon icon-white">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
                    </span>
                  </button>
                </div>
                <div className="table-responsive">
                  <table
                    ref={provided.innerRef}
                    className="table mt-4"
                  >
                    <thead>
                      <tr>
                        {columns.map((col) => <th>{col}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {reasonList && reasonList.length > 0 ? reasonList.map((reason, index) => (
                        <Draggable key={reason.id} draggableId={reason._id} index={index}>
                          {(provided) => (
                            <tr ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() => history.push(`/reason/update/${reason._id}`)}>
                              <td className="cursor-grab" style={{ minWidth: "90px", width: "100px" }}>
                                <span className="material-icons opacity-25">
                                  menu
                                </span>
                              </td>
                              <td className="cursor-pointer" style={{ minWidth: "90px", width: "250px" }}>{reason.orderNumber}</td>
                              <td className="cursor-pointer" style={{ minWidth: "170px", width: "350px" }}>{reason.name}</td>
                              <td className="cursor-pointer" style={{ minWidth: "90px", width: "350px" }}>{reason.isActive}</td>
                            </tr>
                          )}
                        </Draggable>
                      )) :
                        <tr>
                          <td className="pr-0" style={{ minWidth: "90px", width: "100px" }}>
                            No data found
                          </td>
                        </tr>
                      }
                      {provided.placeholder}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </Droppable>
        </DragDropContext>
        <span className="react-bootstrap-table-total">
          {totalData} records ({totalData === 0 ? 0 : 1}{" "}
          to {reasonList.length})
        </span>
      </CardBody>
    </Card >
  );
};

export default Reason;
