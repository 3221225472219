/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { api } from "../../../../common/api";
import { toAbsoluteUrl, checkIsActive } from "../../../../helpers";
import { Logout } from "../../../../common/api/logout";

export function AsideMenuList({ layoutProps, selectedSite, siteEntities }) {
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const selectedTabSite = siteEntities.find(
    (item) => item.value === selectedSite
  );

  return (
    <>
      {/* begin::Menu Nav */}
      <ul
        className={`menu-nav ${layoutProps.ulClasses}`}
        style={{ paddingTop: 0 }}
      >
        {/* SITE NAME LIST */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/schedule/daily",
            false
          )}${getMenuItemActive("/template", false)}${getMenuItemActive(
            "/pre-schedule",
            false
          )}${getMenuItemActive("/s-car", false)}${getMenuItemActive(
            "/s-location",
            false
          )}${getMenuItemActive("/privacyPolicy", false)}${getMenuItemActive(
            "/termsCondition",
            false
          )}${getMenuItemActive("/s-userManual", false)}${getMenuItemActive(
            "/notesToPassenger",
            false
          )}${getMenuItemActive("/privacyPolicy", false)}${getMenuItemActive(
            "/s-news",
            false
          )}${getMenuItemActive("/s-app-info", false)}${getMenuItemActive(
            "/notes-to-passenger-web",
            false
          )}${getMenuItemActive("/download-app", false)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">
              Site {selectedTabSite ? `(${selectedTabSite?.label})` : ""}
            </span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* TEMPLATE */}
              <li
                className={`menu-item ${getMenuItemActive(`/template`, false)}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/template?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Regular Schedule</span>
                </NavLink>
              </li>

              {/* Pre-schedule */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/pre-schedule`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/pre-schedule?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Pre-Schedule</span>
                </NavLink>
              </li>

              {/* SCHEDULE */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/schedule/daily`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/schedule/daily?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Today's Schedule</span>
                </NavLink>
              </li>

              {/* CAR */}
              <li
                className={`menu-item ${getMenuItemActive(`/s-car`, false)}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/s-car?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Car</span>
                </NavLink>
              </li>

              {/* LOCATION */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/s-location`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/s-location?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Location</span>
                </NavLink>
              </li>

              {/* NEWS */}
              <li
                className={`menu-item ${getMenuItemActive(`/s-news`, false)}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/s-news?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">News</span>
                </NavLink>
              </li>

              {/* PRIVACY POLICY */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/privacyPolicy`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/privacyPolicy?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privacy Policy</span>
                </NavLink>
              </li>

              {/* TERMS CONDITIONS */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/termsCondition`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/termsCondition?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Terms & Conditions</span>
                </NavLink>
              </li>

              {/* USER MANUAL */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/s-userManual`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/s-userManual?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">User Manual</span>
                </NavLink>
              </li>

              {/* NOTES TO PASSENGER */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/notesToPassenger`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/notesToPassenger?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Notes to Passenger</span>
                </NavLink>
              </li>

              {/* NOTES TO PASSENGER WEB */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/notes-to-passenger-web`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/notes-to-passenger-web?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Notes to Passenger (Web)</span>
                </NavLink>
              </li>

              {/* DOWNLOAD APP */}
              <li
                className={`menu-item ${getMenuItemActive(
                  `/download-app`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/download-app?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Download App (Web)</span>
                </NavLink >
              </li >

              <li
                className={`menu-item ${getMenuItemActive(
                  `/s-app-info`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/s-app-info?siteId=${selectedSite}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">App Info</span>
                </NavLink>
              </li>
            </ul >
          </div >

          {/* <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {siteEntities.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      `/site/${selectedSite}`,
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to="#">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{item?.name}</span>
                      <i className="menu-arrow" />
                    </NavLink>
                  </li>
                );
              })}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  `/site/add`,
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to={`/site/add`}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Add New Site</span>
                </NavLink>
              </li>
            </ul>
          </div> */}
        </li >

        {/* <li
          className={`menu-item ${getMenuItemActive("/car", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/car">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Car</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/location", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/location">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Location</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/path", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/path">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Path</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/PathGroup", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/PathGroup">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Path Group</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/drivernews",
            false
          )} ${getMenuItemActive("/news", false)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Notification</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* NEWS */}
              <li
                className={`menu-item ${getMenuItemActive("/news", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/news">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">News</span>
                </NavLink>
              </li>

              {/* DRIVER NEWS */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/drivernews",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/drivernews">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Driver News</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/lateReport",
            false
          )} ${getMenuItemActive("/ScheduleReport", false)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Report</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* ISSUE REPORT */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/lateReport",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/lateReport">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Issue Report</span>
                </NavLink>
              </li>

              {/* SCHEDULE REPORT */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/ScheduleReport",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/ScheduleReport">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Schedule Report</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/template", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/template">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Template</span>
          </NavLink>
        </li> */}

        {/* user */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/schedule/daily", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/schedule/daily">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Schedule</span>
          </NavLink>
        </li> */}

        {/* Pre-schedule */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/pre-schedule", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pre-schedule">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Pre-Schedule</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/user",
            false
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">User</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/user",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/user">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Driver</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/admin",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/admin">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Admin</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        {/* notification */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/drivernews", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/drivernews">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Driver News</span>
          </NavLink>
        </li> */}

        {/* news */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/news", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/news">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">News</span>
          </NavLink>
        </li> */}

        {/* lateReport */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/lateReport", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/lateReport">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Issue Report</span>
          </NavLink>
        </li> */}

        {/* Schedule Report */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/ScheduleReport", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ScheduleReport">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Schedule Report</span>
          </NavLink>
        </li> */}

        {/* COMPANY SERVICE */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/companyService",
            false
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Company Service</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/companyService/list",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/companyService/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Company Service</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/companyService/serviceTag",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/companyService/serviceTag">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Service Tag</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/companyService/inquiryService",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/companyService/inquiryService"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Inquiry Service</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* settings */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/site",
            false
          )}${getMenuItemActive("/settings", false)}${getMenuItemActive(
            "/user",
            false
          )}${getMenuItemActive("/admin", false)}${getMenuItemActive(
            "/car",
            false
          )}${getMenuItemActive("/location", false)}${getMenuItemActive(
            "/companyInfo",
            false
          )}${getMenuItemActive("/contact-us", false)}${getMenuItemActive(
            "/driverFcmConfig",
            false
          )}${getMenuItemActive("/contact-us", false)}${getMenuItemActive(
            "/cancel-schedule",
            false
          )}${getMenuItemActive("/otherInfo", false)}${getMenuItemActive(
            "/reachedValue",
            false
          )}${getMenuItemActive("/logoImage", false)}${getMenuItemActive(
            "/reason",
            false
          )}${getMenuItemActive("/appinfo", false)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">General Setting</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* SITE */}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/site",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/site">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Site</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/user",
                  false
                )}${getMenuItemActive("/admin", false)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="#">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">User</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/user",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink className="menu-link menu-toggle" to="/user">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Driver</span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/admin",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink className="menu-link menu-toggle" to="/admin">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Admin</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              {/* CAR */}
              <li
                className={`menu-item ${getMenuItemActive(`/car`, false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={`/car`}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Car</span>
                </NavLink>
              </li>

              {/* LOCATION */}
              <li
                className={`menu-item ${getMenuItemActive(`/location`, false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={`/location`}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Location</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/cancel-schedule",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cancel-schedule">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Cancel Schedule</span>
                </NavLink>
              </li>

              {/* COMPANY INFO */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/companyInfo",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/companyInfo">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Company Info</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/contact-us",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/contact-us">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Contact Us</span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/privacyPolicy",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/privacyPolicy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privacy Policy</span>
                </NavLink>
              </li> */}

              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/termsCondition",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/termsCondition">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Terms & Conditions</span>
                </NavLink>
              </li> */}

              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/userManual",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/userManual">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">User Manual</span>
                </NavLink>
              </li> */}

              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/notesToPassenger",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/notesToPassenger">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Notes to Passenger</span>
                </NavLink>
              </li> */}

              <li
                className={`menu-item ${getMenuItemActive(
                  "/driverFcmConfig",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/driverFcmConfig">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Driver App Config</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/otherInfo",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/otherInfo">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Internal Info</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/reachedValue",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/reachedValue">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Arrived Line</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/logoImage",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/logoImage">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Logo Image</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/reason", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/reason">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Report Reasons Selection</span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item ${getMenuItemActive("/appInfo", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/appinfo">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">App Info</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/export/daily-report",
            false
          )} ${getMenuItemActive("/export/monthly-report", false)} ${getMenuItemActive("/export/monthly-manulife-report", false)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Excel Report</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* ISSUE REPORT */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/export/daily-report",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/export/daily-report">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Daily Report</span>
                </NavLink>
              </li>

              {/* MONTHLY REPORT */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/export/monthly-report",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/export/monthly-report">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Monthly Report (MP)</span>
                </NavLink>
              </li>

              {/* MONTHLY REPORT */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/export/monthly-manulife-report",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/export/monthly-manulife-report">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Monthly ManuLife Report</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>


        {/* app info */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/appinfo", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/appinfo">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">App Info</span>
          </NavLink>
        </li> */}
        {/* logout */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("#", false)}`}
          aria-haspopup="true"
          onClick={Logout}
        >
          <NavLink className="menu-link" to="/auth/login">
            {/* <span className="svg-icon menu-icon"> */}
            <span className="menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} /> */}
              <i className="fa fa-power-off" aria-hidden="true"></i>
            </span>
            <span className="menu-text">LOG OUT</span>
          </NavLink>
        </li>

        {/*end::1 Level*/}
      </ul >
      {/* end::Menu Nav */}
    </>
  );
}
