import _ from "lodash";
import moment from "moment";
import {
  borderRightMediumCell,
  borderTopBottomMediumCell,
  borderTopRightBottomMediumCell,
  lightPurpleCell,
  lightGreenCell,
  topBottomMediumRightThin,
  yellowBgCell,
  lightBlueCell,
  borderBottomMediumCell,
  borderBottomRightMediumCell,
  borderRightThingBottomMediumCell,
  borderRightMediumBottomThinCell,
  borderBottomRightThin,
  borderBottomThinCell,
} from "./weekly";
import { checkWeekMoreThan28 } from "./checkCells"

const getOnBoradCount = (items, route, slot) => {
  let a8Total = 0;

  items
    .filter((x) => x.title === route && x.Time === slot)
    .map((x) => {
      a8Total = a8Total + x.onBoard;
    });

  return a8Total;
};

const getOffBoradCount = (items, route, slot) => {
  let a8Total = 0;

  items
    .filter((x) => x.title === route && x.Time === slot)
    .map((x) => {
      a8Total = a8Total + x.offBoard;
    });

  return a8Total;
};

const getRouteCount = (items, route, slot) => {
  let a8Total = 0;

  items
    .filter((x) => x.title === route && x.Time === slot)
    .map((x) => {
      a8Total = a8Total + x.passengers;
    });

  return a8Total;
};

const getDelayCount = (items, route, slot) => {
  return (
    items.filter(
      (x) => x.title === route && x.Time === slot && x.delay && x.delay > 15
    ).length || 0
  );
};

const getFullCounts = (items, route, slot, percentage) => {
  const count =
    items.filter(
      (x) =>
        x.title === route && x.Time === slot && x.fullPercentage >= percentage
    ).length || 0;

  return count;
};

const getStatusCounts = (items, route, slot, status) => {
  const count =
    items.filter(
      (x) => x.title === route && x.Time === slot && x.status === status
    ).length || 0;

  return count;
};

export const weeklySheet = (wb, allItems, totalDay, weeks) => {
  const timeValues = Object.keys(weeks) || [];

  timeValues.forEach((v, idx) => {
    const ws = wb.addWorksheet(`Week ${idx + 1}`, {
      properties: {},
      views: [{ state: "frozen", xSplit: 1 }],
    });

    const weekData = weeks[v];

    // ADD DATA INTO CELL
    ws.getCell("C5").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C15").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C25").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C35").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C45").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C52").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C71").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C94").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C115").value = weekData[0]
      ? moment(weekData[0]).format("D")
      : 0;
    ws.getCell("C137").value = weekData[0]
      ? moment(weekData[0]).format("D")
      : 0;
    ws.getCell("C144").value = weekData[0]
      ? moment(weekData[0]).format("D")
      : 0;
    ws.getCell("C153").value = weekData[0]
      ? moment(weekData[0]).format("D")
      : 0;
    ws.getCell("C160").value = weekData[0]
      ? moment(weekData[0]).format("D")
      : 0;
    ws.getCell("C167").value = weekData[0]
      ? moment(weekData[0]).format("D")
      : 0;
    ws.getCell("C172").value = weekData[0]
      ? moment(weekData[0]).format("D")
      : 0;

    // DAY 2
    ws.getCell("G5").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("G15").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("D25").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("D35").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("G45").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("G52").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("G71").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("D94").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("D115").value = weekData[1]
      ? moment(weekData[1]).format("D")
      : 0;
    ws.getCell("D137").value = weekData[1]
      ? moment(weekData[1]).format("D")
      : 0;
    ws.getCell("D144").value = weekData[1]
      ? moment(weekData[1]).format("D")
      : 0;
    ws.getCell("D153").value = weekData[1]
      ? moment(weekData[1]).format("D")
      : 0;
    ws.getCell("D160").value = weekData[1]
      ? moment(weekData[1]).format("D")
      : 0;
    ws.getCell("D167").value = weekData[1]
      ? moment(weekData[1]).format("D")
      : 0;
    ws.getCell("G172").value = weekData[1]
      ? moment(weekData[1]).format("D")
      : 0;

    // DAY 3
    ws.getCell("K5").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("K15").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("E25").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("E35").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("K45").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("K52").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("K71").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("E94").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("E115").value = weekData[2]
      ? moment(weekData[2]).format("D")
      : 0;
    ws.getCell("E137").value = weekData[2]
      ? moment(weekData[2]).format("D")
      : 0;
    ws.getCell("E144").value = weekData[2]
      ? moment(weekData[2]).format("D")
      : 0;
    ws.getCell("E153").value = weekData[2]
      ? moment(weekData[2]).format("D")
      : 0;
    ws.getCell("E160").value = weekData[2]
      ? moment(weekData[2]).format("D")
      : 0;
    ws.getCell("E167").value = weekData[2]
      ? moment(weekData[2]).format("D")
      : 0;
    ws.getCell("K172").value = weekData[2]
      ? moment(weekData[2]).format("D")
      : 0;

    // DAY 4
    ws.getCell("O5").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("O15").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("F25").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("F35").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("O45").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("O52").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("O71").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("F94").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("F115").value = weekData[3]
      ? moment(weekData[3]).format("D")
      : 0;
    ws.getCell("F137").value = weekData[3]
      ? moment(weekData[3]).format("D")
      : 0;
    ws.getCell("F144").value = weekData[3]
      ? moment(weekData[3]).format("D")
      : 0;
    ws.getCell("F153").value = weekData[3]
      ? moment(weekData[3]).format("D")
      : 0;
    ws.getCell("F160").value = weekData[3]
      ? moment(weekData[3]).format("D")
      : 0;
    ws.getCell("F167").value = weekData[3]
      ? moment(weekData[3]).format("D")
      : 0;
    ws.getCell("O172").value = weekData[3]
      ? moment(weekData[3]).format("D")
      : 0;

    // DAY 5
    ws.getCell("S5").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("S15").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("G25").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("G35").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("S45").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("S52").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("S71").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("G94").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("G115").value = weekData[4]
      ? moment(weekData[4]).format("D")
      : 0;
    ws.getCell("G137").value = weekData[4]
      ? moment(weekData[4]).format("D")
      : 0;
    ws.getCell("G144").value = weekData[4]
      ? moment(weekData[4]).format("D")
      : 0;
    ws.getCell("G153").value = weekData[4]
      ? moment(weekData[4]).format("D")
      : 0;
    ws.getCell("G160").value = weekData[4]
      ? moment(weekData[4]).format("D")
      : 0;
    ws.getCell("G167").value = weekData[4]
      ? moment(weekData[4]).format("D")
      : 0;
    ws.getCell("S172").value = weekData[4]
      ? moment(weekData[4]).format("D")
      : 0;

    ws.getCell("A1").value = `Week ${idx + 1} : ${moment(weeks[v][0]).format(
      "DD MMMM YYYY"
    )}`;

    const weekTotal = weekData.length || 0;
    ws.getCell("X3").value = weekData.length || 0;
    ws.getCell("X3").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC000" },
    };
    ws.getCell("Y3").value = "(no. of working day)";

    ws.getCell("A3").value = "1)  Monthly Shuttle Bus Service";
    ws.getCell("A4").value = "1.1) Bus Trip: LG > MT > MFC";
    ws.getCell("A14").value = "1.2) Bus Trip: LG > MFC > MT";
    ws.getCell("A24").value = "1.3) Bus Trip: MT > MFC";
    ws.getCell("A34").value = "1.4) Bus Trip: MFC > MT";
    ws.getCell("A44").value =
      "Total No. of Passenger of Morning Shuttle Bus Services (1.1+1.2+1.3+1.4)";
    ws.getCell("A50").value = "2) Regular Shuttle Bus Services";
    ws.getCell("A51").value = "2.1) Bus Trip: MT > MFC > LG";
    ws.getCell("A70").value = "2.2) Bus Trip: LG > MFC > MT";
    ws.getCell("A93").value = "2.3) Bus Trip: MT > MFC ";
    ws.getCell("A114").value = "2.4) Bus Trip: MFC > MT";
    ws.getCell("A136").value = "2.5) Bus Trip: MT > LG";
    ws.getCell("A143").value = "2.6) Bus Trip: MFC > LG";
    ws.getCell("A151").value = "3) Evening Shuttle Bus Services";
    ws.getCell("A152").value = "3.1) Bus Trip: MT > LG";
    ws.getCell("A159").value = "3.2) Bus Trip: MFC > LG";
    ws.getCell("A166").value =
      "Total No. of Passenger of Evening Shuttle Bus Services (3.1+3.2)";
    ws.getCell("A171").value = "3.3) Bus Trip: MT > MFC > LG";

    ws.getCell("A6").value = "LG > MT";
    ws.getCell("B6").value = "MT > MFC";
    ws.getCell("A7").value = "07:45";
    ws.getCell("A8").value = "08:15";
    ws.getCell("A9").value = "08:45";
    ws.getCell("A10").value = "09:15";
    ws.getCell("B7").value = "08:05";
    ws.getCell("B8").value = "08:35";
    ws.getCell("B9").value = "09:05";
    ws.getCell("B10").value = "09:35";

    ws.getCell("A16").value = "LG > MFC";
    ws.getCell("B16").value = "MFC > MT";
    ws.getCell("A17").value = "08:00";
    ws.getCell("A18").value = "08:30";
    ws.getCell("A19").value = "09:00";
    ws.getCell("A20").value = "09:30";
    ws.getCell("B17").value = "08:20";
    ws.getCell("B18").value = "08:55";
    ws.getCell("B19").value = "09:20";
    ws.getCell("B20").value = "09:55";

    ws.getCell("A26").value = "MT > MFC";
    ws.getCell("A27").value = "08:20";
    ws.getCell("A28").value = "08:50";
    ws.getCell("A29").value = "09:20";
    ws.getCell("A30").value = "09:50";

    ws.getCell("A36").value = "MFC > MT";
    ws.getCell("A37").value = "08:35";
    ws.getCell("A38").value = "09:05";
    ws.getCell("A39").value = "09:35";

    ws.getCell("A46").value = "1st stop";
    ws.getCell("B46").value = "2nd stop";

    ws.getCell("A53").value = "MT > MFC";
    ws.getCell("B53").value = "MFC > LG";
    ws.getCell("A54").value = "10:00";
    ws.getCell("A55").value = "10:30";
    ws.getCell("A56").value = "11:00";
    ws.getCell("A57").value = "11:30";
    ws.getCell("A58").value = "12:30";
    ws.getCell("A59").value = "14:30";
    ws.getCell("A60").value = "15:00";
    ws.getCell("A61").value = "15:30";
    ws.getCell("A62").value = "16:00";
    ws.getCell("A63").value = "16:30";
    ws.getCell("A64").value = "17:00";
    ws.getCell("A65").value = "17:30";
    ws.getCell("A66").value = "18:00";

    ws.getCell("B54").value = "10:10";
    ws.getCell("B55").value = "10:40";
    ws.getCell("B56").value = "11:10";
    ws.getCell("B57").value = "11:40";
    ws.getCell("B58").value = "12:40";
    ws.getCell("B59").value = "14:40";
    ws.getCell("B60").value = "15:10";
    ws.getCell("B61").value = "15:40";
    ws.getCell("B62").value = "16:10";
    ws.getCell("B63").value = "16:40";
    ws.getCell("B64").value = "17:10";
    ws.getCell("B65").value = "17:40";
    ws.getCell("B66").value = "18:10";

    ws.getCell("A72").value = "LG > MFC";
    ws.getCell("B72").value = "MFC > MT";
    ws.getCell("A73").value = "10:15";
    ws.getCell("A74").value = "10:45";
    ws.getCell("A75").value = "11:15";
    ws.getCell("A76").value = "11:45";
    ws.getCell("A77").value = "12:15";
    ws.getCell("A78").value = "12:45";
    ws.getCell("A80").value = "13:45";
    ws.getCell("A81").value = "14:15";
    ws.getCell("A82").value = "14:45";
    ws.getCell("A83").value = "15:15";
    ws.getCell("A84").value = "15:45";
    ws.getCell("A85").value = "16:15";
    ws.getCell("A86").value = "16:45";
    ws.getCell("A87").value = "17:15";
    ws.getCell("A88").value = "17:45";
    ws.getCell("A89").value = "18:15";

    ws.getCell("B73").value = "10:35";
    ws.getCell("B74").value = "11:05";
    ws.getCell("B75").value = "11:35";
    ws.getCell("B76").value = "12:05";
    ws.getCell("B77").value = "12:35";
    ws.getCell("B78").value = "13:05";
    ws.getCell("B79").value = "13:45";
    ws.getCell("B80").value = "14:05";
    ws.getCell("B81").value = "14:35";
    ws.getCell("B82").value = "15:05";
    ws.getCell("B83").value = "15:35";
    ws.getCell("B84").value = "16:05";
    ws.getCell("B85").value = "16:35";
    ws.getCell("B86").value = "17:05";
    ws.getCell("B87").value = "17:35";
    ws.getCell("B88").value = "18:05";
    ws.getCell("B89").value = "18:35";

    ws.getCell("A95").value = "MT > MFC";
    ws.getCell("A96").value = "10:10";
    ws.getCell("A97").value = "10:40";
    ws.getCell("A98").value = "11:10";
    ws.getCell("A99").value = "11:40";
    ws.getCell("A100").value = "12:10";
    ws.getCell("A101").value = "12:40";
    ws.getCell("A102").value = "14:10";
    ws.getCell("A103").value = "14:40";
    ws.getCell("A104").value = "15:10";
    ws.getCell("A105").value = "15:40";
    ws.getCell("A106").value = "16:10";
    ws.getCell("A107").value = "16:40";
    ws.getCell("A108").value = "17:10";
    ws.getCell("A109").value = "17:40";
    ws.getCell("A110").value = "18:10";

    ws.getCell("A116").value = "MFC > MT";
    ws.getCell("A117").value = "10:00";
    ws.getCell("A118").value = "10:25";
    ws.getCell("A119").value = "10:55";
    ws.getCell("A120").value = "11:25";
    ws.getCell("A121").value = "11:55";
    ws.getCell("A122").value = "12:25";
    ws.getCell("A123").value = "12:55";
    ws.getCell("A124").value = "14:25";
    ws.getCell("A125").value = "14:55";
    ws.getCell("A126").value = "15:25";
    ws.getCell("A127").value = "15:55";
    ws.getCell("A128").value = "16:25";
    ws.getCell("A129").value = "16:55";
    ws.getCell("A130").value = "17:25";
    ws.getCell("A131").value = "17:55";
    ws.getCell("A132").value = "18:25";

    ws.getCell("A137").value = "MT > LG";
    ws.getCell("A138").value = "12:00";
    ws.getCell("A139").value = "14:00";

    ws.getCell("A144").value = "MFC > LG";
    ws.getCell("A145").value = "12:10";
    ws.getCell("A146").value = "14:10";

    ws.getCell("A153").value = "MT > LG";
    ws.getCell("A154").value = "18:30";
    ws.getCell("A155").value = "19:00";

    ws.getCell("A160").value = "MFC > LG";
    ws.getCell("A161").value = "18:30";
    ws.getCell("A162").value = "19:00";

    ws.getCell("W5").value = "LG > MT";
    ws.getCell("AC5").value = "MT > MFC";

    ws.getCell("W15").value = "LG > MFC";
    ws.getCell("AC15").value = "MFC > MT";

    ws.getCell("H25").value = "MT > MFC";
    ws.getCell("H35").value = "MFC > MT";

    ws.getCell("W46").value = "1st Stop";
    ws.getCell("AC46").value = "2nd Stop";

    ws.getCell("W52").value = "MT > MFC";
    ws.getCell("AC52").value = "MFC > LG";

    ws.getCell("W71").value = "LG > MFC";
    ws.getCell("AC71").value = "MFC > MT";

    ws.getCell("W172").value = "MT > MFC";
    ws.getCell("AC172").value = "MFC > LG";

    ws.getCell("A173").value = "MT > MFC";
    ws.getCell("B173").value = "MFC > LG";
    ws.getCell("A174").value = "19:30";
    ws.getCell("A175").value = "20:00";
    ws.getCell("B174").value = "19:40";
    ws.getCell("B175").value = "20:10";

    ws.getCell("AI6").value = "Average Alighting at MT";
    ws.getCell("AJ6").value = "Average Boarding at MT";
    ws.getCell("AI16").value = "Average Alighting at MFC";
    ws.getCell("AJ16").value = "Average Boarding at MFC";
    ws.getCell("AI53").value = "Average Alighting at MFC";
    ws.getCell("AJ53").value = "Average Boarding at MFC";
    ws.getCell("AI72").value = "Average Alighting at MFC";
    ws.getCell("AJ72").value = "Average Boarding at MFC";
    ws.getCell("AI173").value = "Average Alighting at MFC";
    ws.getCell("AJ173").value = "Average Boarding at MFC";

    [
      "W6",
      "AC6",
      "W16",
      "AC16",
      "H26",
      "H36",
      "W46",
      "AC46",
      "W53",
      "AC53",
      "W72",
      "AC72",
      "H95",
      "H116",
      "H137",
      "H144",
      "H153",
      "H160",
      "H167",
      "W173",
      "AC173",
    ].map((sItem) => {
      ws.getCell(sItem).value = "Total No. of Passenger (per trip)";
    });

    [
      "X6",
      "AD6",
      "X16",
      "AD16",
      "I26",
      "I36",
      "X46",
      "AD46",
      "X53",
      "AD53",
      "X72",
      "AD72",
      "I95",
      "I116",
      "I137",
      "I144",
      "I153",
      "I160",
      "I167",
      "X173",
      "AD173",
    ].map((sItem) => {
      ws.getCell(sItem).value = "Average per trip";
    });

    [
      "C6",
      "F6",
      "J6",
      "N6",
      "R6",
      "V6",
      "G6",
      "K6",
      "O6",
      "S6",
      "C16",
      "F16",
      "J16",
      "N16",
      "R16",
      "V16",
      "G16",
      "K16",
      "O16",
      "F46",
      "J46",
      "N46",
      "R46",
      "V46",
      "S16",
      "C26",
      "D26",
      "E26",
      "F26",
      "G26",
      "C36",
      "D36",
      "E36",
      "F36",
      "G36",
      "C46",
      "G46",
      "K46",
      "O46",
      "S46",
      "C53",
      "F53",
      "J53",
      "N53",
      "R53",
      "V53",
      "G53",
      "K53",
      "O53",
      "S53",
      "F72",
      "J72",
      "N72",
      "R72",
      "V72",
      "C72",
      "G72",
      "K72",
      "O72",
      "S72",
      "C95",
      "D95",
      "E95",
      "F95",
      "G95",
      "C116",
      "D116",
      "E116",
      "F116",
      "G116",
      "C173",
      "G173",
      "K173",
      "O173",
      "F173",
      "J173",
      "N173",
      "R173",
      "V173",
      "S173",
    ].map((sItem) => {
      ws.getCell(sItem).value = "Total";
    });

    ["D6", "H6", "L6", "P6", "T6"].map((sItem) => {
      ws.getCell(sItem).value = "Alighting at MT";
    });

    ["E6", "I6", "M6", "Q6", "U6"].map((sItem) => {
      ws.getCell(sItem).value = "Boarding at MT";
    });

    ["D46", "H46", "L46", "P46", "T46"].map((sItem) => {
      ws.getCell(sItem).value = "Alighting at MFC / MT";
    });

    ["E46", "I46", "M46", "Q46", "U46"].map((sItem) => {
      ws.getCell(sItem).value = "Boarding at MFC / MT";
    });

    [
      "D16",
      "H16",
      "L16",
      "P16",
      "T16",
      "D53",
      "H53",
      "L53",
      "P53",
      "T53",
      "D72",
      "H72",
      "L72",
      "P72",
      "T72",
      "D173",
      "H173",
      "L173",
      "P173",
      "T173",
    ].map((sItem) => {
      ws.getCell(sItem).value = "Alighting at MFC";
    });

    [
      "E16",
      "I16",
      "M16",
      "Q16",
      "U16",
      "E53",
      "I53",
      "M53",
      "Q53",
      "U53",
      "E72",
      "I72",
      "M72",
      "Q72",
      "U72",
      "E173",
      "I173",
      "M173",
      "Q173",
      "U173",
    ].map((sItem) => {
      ws.getCell(sItem).value = "Boarding at MFC";
    });

    [
      "Y6",
      "AE6",
      "Y16",
      "AE16",
      "J26",
      "J36",
      "Y53",
      "AE53",
      "Y72",
      "AE72",
      "J95",
      "J116",
      "J137",
      "J144",
      "J153",
      "J160",
      "Y173",
      "AE173",
    ].map((sItem) => {
      ws.getCell(sItem).value = "No. of days >22\r\npassengers";
    });

    [
      "AA6",
      "AG6",
      "AA16",
      "AG16",
      "L26",
      "L36",
      "AA53",
      "AG53",
      "AA72",
      "AG72",
      "L95",
      "L116",
      "L137",
      "L144",
      "L153",
      "L160",
      "AA173",
      "AG173",
    ].map((sItem) => {
      ws.getCell(sItem).value = "No. of days =28\r\npassengers";
    });

    [
      "A11",
      "A21",
      "A31",
      "A40",
      "A47",
      "A67",
      "A90",
      "A111",
      "A133",
      "A140",
      "A147",
      "A156",
      "A163",
      "A168",
      "A176",
    ].map((sItem) => {
      ws.getCell(sItem).value = "Daily Total No. of Passenger";
    });

    [
      "A12",
      "A22",
      "A32",
      "A41",
      "A48",
      "A68",
      "A91",
      "A112",
      "A134",
      "A141",
      "A148",
      "A157",
      "A164",
      "A169",
      "A177",
    ].map((sItem) => {
      ws.getCell(sItem).value = "Average per trip (Daily)";
    });

    // APPLY BACKGROUND COLOR (LIGHT YELLOW)
    yellowBgCell.map((cItem) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFCC" },
      };
    });

    // APPLY BACKGROUND COLOR (LIGHT BLUE)
    lightBlueCell.map((cItem) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D9E1F2" },
      };
    });

    // APPLY BACKGROUND COLOR (LIGHT PURPLE)
    lightPurpleCell.map((cItem) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCFF" },
      };
    });

    // APPLY BACKGROUND COLOR (LIGHT GREEN)
    lightGreenCell.map((cItem) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E2EFDA" },
      };
    });

    // APPLY BOLD BORDER AT RIGHT
    borderRightMediumCell.map((cItem) => {
      ws.getCell(cItem).border = {
        right: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM RIGHT
    borderBottomRightMediumCell.map((cItem) => {
      ws.getCell(cItem).border = {
        right: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER RIGHT MEDIUM BOTTOM THIN
    borderRightMediumBottomThinCell.map((cItem) => {
      ws.getCell(cItem).border = {
        right: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
    borderRightThingBottomMediumCell.map((cItem) => {
      ws.getCell(cItem).border = {
        right: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
    borderBottomRightThin.map((cItem) => {
      ws.getCell(cItem).border = {
        right: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM
    borderBottomMediumCell.map((cItem) => {
      ws.getCell(cItem).border = {
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY THIN BORDER AT BOTTOM
    borderBottomThinCell.map((cItem) => {
      ws.getCell(cItem).border = {
        bottom: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT TOP & BOTTOM
    borderTopBottomMediumCell.map((cItem) => {
      ws.getCell(cItem).border = {
        top: { style: "medium", color: { argb: "000000" } },
        // left: { style: "thick", color: { argb: "000000FF" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        // right: { style: "thick", color: { argb: "FF00FF00" } },
      };
    });

    // APPLY BOLD BORDER AT TOP BOTTOM AND RIGHT THIN
    topBottomMediumRightThin.map((cItem) => {
      ws.getCell(cItem).border = {
        top: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT TOP RIGHT BOTTOM
    borderTopRightBottomMediumCell.map((cItem) => {
      ws.getCell(cItem).border = {
        top: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    ws.mergeCells("Y6:Z6");
    ws.mergeCells("AA6:AB6");
    ws.mergeCells("AE6:AF6");
    ws.mergeCells("AG6:AH6");

    ws.mergeCells("Y16:Z16");
    ws.mergeCells("AA16:AB16");
    ws.mergeCells("AE16:AF16");
    ws.mergeCells("AG16:AH16");

    ws.mergeCells("A11:B11");
    ws.mergeCells("A12:B12");
    ws.mergeCells("A21:B21");
    ws.mergeCells("A22:B22");
    ws.mergeCells(`A26:B26`);
    ws.mergeCells(`A27:B27`);
    ws.mergeCells(`A28:B28`);
    ws.mergeCells(`A29:B29`);
    ws.mergeCells(`A30:B30`);
    ws.mergeCells("A31:B31");
    ws.mergeCells("A32:B32");
    ws.mergeCells(`A36:B36`);
    ws.mergeCells(`A37:B37`);
    ws.mergeCells(`A38:B38`);
    ws.mergeCells(`A39:B39`);
    ws.mergeCells("A40:B40");
    ws.mergeCells("A41:B41");
    ws.mergeCells("A47:B47");
    ws.mergeCells("A48:B48");
    ws.mergeCells("A67:B67");
    ws.mergeCells("A68:B68");
    ws.mergeCells("A90:B90");
    ws.mergeCells("A91:B91");
    ws.mergeCells("A95:B95");
    ws.mergeCells("A96:B96");
    ws.mergeCells("A97:B97");
    ws.mergeCells("A98:B98");
    ws.mergeCells("A99:B99");
    ws.mergeCells("A100:B100");
    ws.mergeCells("A101:B101");
    ws.mergeCells("A102:B102");
    ws.mergeCells("A103:B103");
    ws.mergeCells("A104:B104");
    ws.mergeCells("A105:B105");
    ws.mergeCells("A106:B106");
    ws.mergeCells("A107:B107");
    ws.mergeCells("A108:B108");
    ws.mergeCells("A109:B109");
    ws.mergeCells("A110:B110");
    ws.mergeCells("A111:B111");
    ws.mergeCells("A112:B112");
    ws.mergeCells("A116:B116");
    ws.mergeCells("A117:B117");
    ws.mergeCells("A118:B118");
    ws.mergeCells("A119:B119");
    ws.mergeCells("A120:B120");
    ws.mergeCells("A121:B121");
    ws.mergeCells("A122:B122");
    ws.mergeCells("A123:B123");
    ws.mergeCells("A124:B124");
    ws.mergeCells("A125:B125");
    ws.mergeCells("A126:B126");
    ws.mergeCells("A127:B127");
    ws.mergeCells("A128:B128");
    ws.mergeCells("A129:B129");
    ws.mergeCells("A130:B130");
    ws.mergeCells("A131:B131");
    ws.mergeCells("A132:B132");
    ws.mergeCells("A133:B133");
    ws.mergeCells("A134:B134");
    ws.mergeCells("A137:B137");
    ws.mergeCells("A138:B138");
    ws.mergeCells("A139:B139");
    ws.mergeCells("A140:B140");
    ws.mergeCells("A141:B141");
    ws.mergeCells("A144:B144");
    ws.mergeCells("A145:B145");
    ws.mergeCells("A146:B146");
    ws.mergeCells("A147:B147");
    ws.mergeCells("A148:B148");
    ws.mergeCells("A153:B153");
    ws.mergeCells("A154:B154");
    ws.mergeCells("A155:B155");
    ws.mergeCells("A156:B156");
    ws.mergeCells("A157:B157");
    ws.mergeCells("A160:B160");
    ws.mergeCells("A161:B161");
    ws.mergeCells("A162:B162");
    ws.mergeCells("A163:B163");
    ws.mergeCells("A164:B164");
    ws.mergeCells("A168:B168");
    ws.mergeCells("A169:B169");
    ws.mergeCells("A176:B176");
    ws.mergeCells("A177:B177");

    ws.mergeCells("J26:K26");
    ws.mergeCells("J36:K36");
    ws.mergeCells("Y53:Z53");
    ws.mergeCells("AE53:AF53");
    ws.mergeCells("Y72:Z72");
    ws.mergeCells("AE72:AF72");
    ws.mergeCells("J95:K95");
    ws.mergeCells("J116:K116");
    ws.mergeCells("J137:K137");
    ws.mergeCells("J144:K144");
    ws.mergeCells("J153:K153");
    ws.mergeCells("J160:K160");
    ws.mergeCells("Y173:Z173");
    ws.mergeCells("AE173:AF173");

    ws.mergeCells("L26:M26");
    ws.mergeCells("L36:M36");
    ws.mergeCells("AA53:AB53");
    ws.mergeCells("AG53:AH53");
    ws.mergeCells("AA72:AB72");
    ws.mergeCells("AG72:AH72");
    ws.mergeCells("L95:M95");
    ws.mergeCells("L116:M116");
    ws.mergeCells("L137:M137");
    ws.mergeCells("L144:M144");
    ws.mergeCells("L153:M153");
    ws.mergeCells("L160:M160");
    ws.mergeCells("AA173:AB173");
    ws.mergeCells("AG173:AH173");
    ws.mergeCells("X46:AB46");
    ws.mergeCells("AD46:AH46");
    ws.mergeCells("X47:AB47");
    ws.mergeCells("AD47:AH47");
    ws.mergeCells("X48:AB48");
    ws.mergeCells("AD48:AH48");

    ws.columns = [
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 20, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    ];

    // ROW HEIGHT = 20
    [
      3,
      4,
      5,
      14,
      15,
      24,
      25,
      34,
      35,
      44,
      45,
      50,
      51,
      52,
      70,
      71,
      93,
      94,
      114,
      115,
      136,
      143,
      151,
      152,
      159,
      166,
      171,
      172,
    ].map((rItm) => {
      ws.getRow(rItm).height = 20;
    });

    // ROW HEIGHT = 45
    [6, 16, 26, 36, 46, 53, 72, 95, 116, 137, 144, 153, 160, 167, 173].map(
      (rItm) => {
        ws.getRow(rItm).height = 45;
      }
    );

    // BOLD TEXT
    [
      1,
      3,
      4,
      14,
      24,
      34,
      44,
      50,
      51,
      70,
      93,
      114,
      136,
      143,
      151,
      152,
      159,
      166,
      171,
    ].map((citem) => {
      ws.mergeCells(`A${citem}:D${citem}`);
      ws.getCell(`A${citem}`).font = {
        bold: true,
        color: { argb: "00000000" },
      };
    });

    const week_data1 = weekData[0] ? allItems.filter(
      (sItem) =>
        moment(sItem?.Date).format("YYYY-MM-DD") ===
        moment(weekData[0]).format("YYYY-MM-DD")
    ) : [];

    const week_data2 = weekData[1] ? allItems.filter(
      (sItem) =>
        moment(sItem?.Date).format("YYYY-MM-DD") ===
        moment(weekData[1]).format("YYYY-MM-DD")
    ) : [];

    const week_data3 = weekData[2] ? allItems.filter(
      (sItem) =>
        moment(sItem?.Date).format("YYYY-MM-DD") ===
        moment(weekData[2]).format("YYYY-MM-DD")
    ) : [];

    const week_data4 = weekData[3] ? allItems.filter(
      (sItem) =>
        moment(sItem?.Date).format("YYYY-MM-DD") ===
        moment(weekData[3]).format("YYYY-MM-DD")
    ) : [];

    const week_data5 = weekData[4] ? allItems.filter(
      (sItem) =>
        moment(sItem?.Date).format("YYYY-MM-DD") ===
        moment(weekData[4]).format("YYYY-MM-DD")
    ) : [];

    ws.getCell("C7").value =
      getOnBoradCount(week_data1, "LG > MT", "07:45") || 0;
    ws.getCell("C8").value =
      getOnBoradCount(week_data1, "LG > MT", "08:15") || 0;
    ws.getCell("C9").value =
      getOnBoradCount(week_data1, "LG > MT", "08:45") || 0;
    ws.getCell("C10").value =
      getOnBoradCount(week_data1, "LG > MT", "09:15") || 0;
    ws.getCell("C11").value =
      ws.getCell("C7").value +
      ws.getCell("C8").value +
      ws.getCell("C9").value +
      ws.getCell("C10").value;
    ws.getCell("C12").value = Math.floor(ws.getCell("C11").value / 4) || 0;

    ws.getCell("D7").value =
      getOffBoradCount(week_data1, "LG > MT", "07:45") || 0;
    ws.getCell("D8").value =
      getOffBoradCount(week_data1, "LG > MT", "08:15") || 0;
    ws.getCell("D9").value =
      getOffBoradCount(week_data1, "LG > MT", "08:45") || 0;
    ws.getCell("D10").value =
      getOffBoradCount(week_data1, "LG > MT", "09:15") || 0;
    ws.getCell("D11").value =
      ws.getCell("D7").value +
      ws.getCell("D8").value +
      ws.getCell("D9").value +
      ws.getCell("D10").value;
    ws.getCell("D12").value = Math.floor(ws.getCell("D11").value / 4) || 0;

    ws.getCell("E7").value =
      getOnBoradCount(week_data1, "MT > MFC", "08:05") || 0;
    ws.getCell("E8").value =
      getOnBoradCount(week_data1, "MT > MFC", "08:35") || 0;
    ws.getCell("E9").value =
      getOnBoradCount(week_data1, "MT > MFC", "09:05") || 0;
    ws.getCell("E10").value =
      getOnBoradCount(week_data1, "MT > MFC", "09:35") || 0;
    ws.getCell("E11").value =
      ws.getCell("E7").value +
      ws.getCell("E8").value +
      ws.getCell("E9").value +
      ws.getCell("E10").value;
    ws.getCell("E12").value = Math.floor(ws.getCell("E11").value / 4) || 0;

    ws.getCell("F7").value =
      ws.getCell("C7").value - ws.getCell("D7").value + ws.getCell("E7").value;
    ws.getCell("F8").value =
      ws.getCell("C8").value - ws.getCell("D8").value + ws.getCell("E8").value;
    ws.getCell("F9").value =
      ws.getCell("C9").value - ws.getCell("D9").value + ws.getCell("E9").value;
    ws.getCell("F10").value =
      ws.getCell("C10").value -
      ws.getCell("D10").value +
      ws.getCell("E10").value;
    ws.getCell("F11").value =
      ws.getCell("F7").value +
      ws.getCell("F8").value +
      ws.getCell("F9").value +
      ws.getCell("F10").value;
    ws.getCell("F12").value = Math.floor(ws.getCell("F11").value / 4) || 0;

    ws.getCell("G7").value =
      getOnBoradCount(week_data2, "LG > MT", "07:45") || 0;
    ws.getCell("G8").value =
      getOnBoradCount(week_data2, "LG > MT", "08:15") || 0;
    ws.getCell("G9").value =
      getOnBoradCount(week_data2, "LG > MT", "08:45") || 0;
    ws.getCell("G10").value =
      getOnBoradCount(week_data2, "LG > MT", "09:15") || 0;
    ws.getCell("G11").value =
      ws.getCell("G7").value +
      ws.getCell("G8").value +
      ws.getCell("G9").value +
      ws.getCell("G10").value;
    ws.getCell("G12").value = Math.floor(ws.getCell("G11").value / 4) || 0;

    ws.getCell("H7").value =
      getOffBoradCount(week_data2, "LG > MT", "07:45") || 0;
    ws.getCell("H8").value =
      getOffBoradCount(week_data2, "LG > MT", "08:15") || 0;
    ws.getCell("H9").value =
      getOffBoradCount(week_data2, "LG > MT", "08:45") || 0;
    ws.getCell("H10").value =
      getOffBoradCount(week_data2, "LG > MT", "09:15") || 0;
    ws.getCell("H11").value =
      ws.getCell("H7").value +
      ws.getCell("H8").value +
      ws.getCell("H9").value +
      ws.getCell("H10").value;
    ws.getCell("H12").value = Math.floor(ws.getCell("H11").value / 4) || 0;

    ws.getCell("I7").value =
      getOnBoradCount(week_data2, "MT > MFC", "08:05") || 0;
    ws.getCell("I8").value =
      getOnBoradCount(week_data2, "MT > MFC", "08:35") || 0;
    ws.getCell("I9").value =
      getOnBoradCount(week_data2, "MT > MFC", "09:05") || 0;
    ws.getCell("I10").value =
      getOnBoradCount(week_data2, "MT > MFC", "09:35") || 0;
    ws.getCell("I11").value =
      ws.getCell("I7").value +
      ws.getCell("I8").value +
      ws.getCell("I9").value +
      ws.getCell("I10").value;
    ws.getCell("I12").value = Math.floor(ws.getCell("I11").value / 4) || 0;

    ws.getCell("J7").value =
      ws.getCell("G7").value - ws.getCell("H7").value + ws.getCell("I7").value;
    ws.getCell("J8").value =
      ws.getCell("G8").value - ws.getCell("H8").value + ws.getCell("I8").value;
    ws.getCell("J9").value =
      ws.getCell("G9").value - ws.getCell("H9").value + ws.getCell("I9").value;
    ws.getCell("J10").value =
      ws.getCell("G10").value -
      ws.getCell("H10").value +
      ws.getCell("I10").value;
    ws.getCell("J11").value =
      ws.getCell("J7").value +
      ws.getCell("J8").value +
      ws.getCell("J9").value +
      ws.getCell("J10").value;
    ws.getCell("J12").value = Math.floor(ws.getCell("J11").value / 4) || 0;

    ws.getCell("K7").value =
      getOnBoradCount(week_data3, "LG > MT", "07:45") || 0;
    ws.getCell("K8").value =
      getOnBoradCount(week_data3, "LG > MT", "08:15") || 0;
    ws.getCell("K9").value =
      getOnBoradCount(week_data3, "LG > MT", "08:45") || 0;
    ws.getCell("K10").value =
      getOnBoradCount(week_data3, "LG > MT", "09:15") || 0;
    ws.getCell("K11").value =
      ws.getCell("K7").value +
      ws.getCell("K8").value +
      ws.getCell("K9").value +
      ws.getCell("K10").value;
    ws.getCell("K12").value = Math.floor(ws.getCell("K11").value / 4) || 0;

    ws.getCell("L7").value =
      getOffBoradCount(week_data3, "LG > MT", "07:45") || 0;
    ws.getCell("L8").value =
      getOffBoradCount(week_data3, "LG > MT", "08:15") || 0;
    ws.getCell("L9").value =
      getOffBoradCount(week_data3, "LG > MT", "08:45") || 0;
    ws.getCell("L10").value =
      getOffBoradCount(week_data3, "LG > MT", "09:15") || 0;
    ws.getCell("L11").value =
      ws.getCell("L7").value +
      ws.getCell("L8").value +
      ws.getCell("L9").value +
      ws.getCell("L10").value;
    ws.getCell("L12").value = Math.floor(ws.getCell("L11").value / 4) || 0;

    ws.getCell("M7").value =
      getOnBoradCount(week_data3, "MT > MFC", "08:05") || 0;
    ws.getCell("M8").value =
      getOnBoradCount(week_data3, "MT > MFC", "08:35") || 0;
    ws.getCell("M9").value =
      getOnBoradCount(week_data3, "MT > MFC", "09:05") || 0;
    ws.getCell("M10").value =
      getOnBoradCount(week_data3, "MT > MFC", "09:35") || 0;
    ws.getCell("M11").value =
      ws.getCell("M7").value +
      ws.getCell("M8").value +
      ws.getCell("M9").value +
      ws.getCell("M10").value;
    ws.getCell("M12").value = Math.floor(ws.getCell("M11").value / 4) || 0;

    ws.getCell("N7").value =
      ws.getCell("K7").value - ws.getCell("L7").value + ws.getCell("M7").value;
    ws.getCell("N8").value =
      ws.getCell("K8").value - ws.getCell("L8").value + ws.getCell("M8").value;
    ws.getCell("N9").value =
      ws.getCell("K9").value - ws.getCell("L9").value + ws.getCell("M9").value;
    ws.getCell("N10").value =
      ws.getCell("K10").value -
      ws.getCell("L10").value +
      ws.getCell("M10").value;
    ws.getCell("N11").value =
      ws.getCell("N7").value +
      ws.getCell("N8").value +
      ws.getCell("N9").value +
      ws.getCell("N10").value;
    ws.getCell("N12").value = Math.floor(ws.getCell("N11").value / 4) || 0;

    ws.getCell("O7").value =
      getOnBoradCount(week_data4, "LG > MT", "07:45") || 0;
    ws.getCell("O8").value =
      getOnBoradCount(week_data4, "LG > MT", "08:15") || 0;
    ws.getCell("O9").value =
      getOnBoradCount(week_data4, "LG > MT", "08:45") || 0;
    ws.getCell("O10").value =
      getOnBoradCount(week_data4, "LG > MT", "09:15") || 0;
    ws.getCell("O11").value =
      ws.getCell("O7").value +
      ws.getCell("O8").value +
      ws.getCell("O9").value +
      ws.getCell("O10").value;
    ws.getCell("O12").value = Math.floor(ws.getCell("O11").value / 4) || 0;

    ws.getCell("P7").value =
      getOffBoradCount(week_data4, "LG > MT", "07:45") || 0;
    ws.getCell("P8").value =
      getOffBoradCount(week_data4, "LG > MT", "08:15") || 0;
    ws.getCell("P9").value =
      getOffBoradCount(week_data4, "LG > MT", "08:45") || 0;
    ws.getCell("P10").value =
      getOffBoradCount(week_data4, "LG > MT", "09:15") || 0;
    ws.getCell("P11").value =
      ws.getCell("P7").value +
      ws.getCell("P8").value +
      ws.getCell("P9").value +
      ws.getCell("P10").value;
    ws.getCell("P12").value = Math.floor(ws.getCell("P11").value / 4) || 0;

    ws.getCell("Q7").value =
      getOnBoradCount(week_data4, "MT > MFC", "08:05") || 0;
    ws.getCell("Q8").value =
      getOnBoradCount(week_data4, "MT > MFC", "08:35") || 0;
    ws.getCell("Q9").value =
      getOnBoradCount(week_data4, "MT > MFC", "09:05") || 0;
    ws.getCell("Q10").value =
      getOnBoradCount(week_data4, "MT > MFC", "09:35") || 0;
    ws.getCell("Q11").value =
      ws.getCell("Q7").value +
      ws.getCell("Q8").value +
      ws.getCell("Q9").value +
      ws.getCell("Q10").value;
    ws.getCell("Q12").value = Math.floor(ws.getCell("Q11").value / 4) || 0;

    ws.getCell("R7").value =
      ws.getCell("O7").value - ws.getCell("P7").value + ws.getCell("Q7").value;
    ws.getCell("R8").value =
      ws.getCell("O8").value - ws.getCell("P8").value + ws.getCell("Q8").value;
    ws.getCell("R9").value =
      ws.getCell("O9").value - ws.getCell("P9").value + ws.getCell("Q9").value;
    ws.getCell("R10").value =
      ws.getCell("O10").value -
      ws.getCell("P10").value +
      ws.getCell("Q10").value;
    ws.getCell("R11").value =
      ws.getCell("R7").value +
      ws.getCell("R8").value +
      ws.getCell("R9").value +
      ws.getCell("R10").value;
    ws.getCell("R12").value = Math.floor(ws.getCell("R11").value / 4) || 0;

    ws.getCell("S7").value =
      getOnBoradCount(week_data5, "LG > MT", "07:45") || 0;
    ws.getCell("S8").value =
      getOnBoradCount(week_data5, "LG > MT", "08:15") || 0;
    ws.getCell("S9").value =
      getOnBoradCount(week_data5, "LG > MT", "08:45") || 0;
    ws.getCell("S10").value =
      getOnBoradCount(week_data5, "LG > MT", "09:15") || 0;
    ws.getCell("S11").value =
      ws.getCell("S7").value +
      ws.getCell("S8").value +
      ws.getCell("S9").value +
      ws.getCell("S10").value;
    ws.getCell("S12").value = Math.floor(ws.getCell("S11").value / 4) || 0;

    ws.getCell("T7").value =
      getOffBoradCount(week_data5, "LG > MT", "07:45") || 0;
    ws.getCell("T8").value =
      getOffBoradCount(week_data5, "LG > MT", "08:15") || 0;
    ws.getCell("T9").value =
      getOffBoradCount(week_data5, "LG > MT", "08:45") || 0;
    ws.getCell("T10").value =
      getOffBoradCount(week_data5, "LG > MT", "09:15") || 0;
    ws.getCell("T11").value =
      ws.getCell("T7").value +
      ws.getCell("T8").value +
      ws.getCell("T9").value +
      ws.getCell("T10").value;
    ws.getCell("T12").value = Math.floor(ws.getCell("T11").value / 4) || 0;

    ws.getCell("U7").value =
      getOnBoradCount(week_data5, "MT > MFC", "08:05") || 0;
    ws.getCell("U8").value =
      getOnBoradCount(week_data5, "MT > MFC", "08:35") || 0;
    ws.getCell("U9").value =
      getOnBoradCount(week_data5, "MT > MFC", "09:05") || 0;
    ws.getCell("U10").value =
      getOnBoradCount(week_data5, "MT > MFC", "09:35") || 0;
    ws.getCell("U11").value =
      ws.getCell("U7").value +
      ws.getCell("U8").value +
      ws.getCell("U9").value +
      ws.getCell("U10").value;
    ws.getCell("U12").value = Math.floor(ws.getCell("U11").value / 4) || 0;

    ws.getCell("V7").value =
      ws.getCell("S7").value - ws.getCell("T7").value + ws.getCell("U7").value;
    ws.getCell("V8").value =
      ws.getCell("S8").value - ws.getCell("T8").value + ws.getCell("U8").value;
    ws.getCell("V9").value =
      ws.getCell("S9").value - ws.getCell("T9").value + ws.getCell("U9").value;
    ws.getCell("V10").value =
      ws.getCell("S10").value -
      ws.getCell("T10").value +
      ws.getCell("U10").value;
    ws.getCell("V11").value =
      ws.getCell("V7").value +
      ws.getCell("V8").value +
      ws.getCell("V9").value +
      ws.getCell("V10").value;
    ws.getCell("V12").value = Math.floor(ws.getCell("V11").value / 4) || 0;

    ws.getCell("W7").value =
      ws.getCell("C7").value +
      ws.getCell("G7").value +
      ws.getCell("K7").value +
      ws.getCell("O7").value +
      ws.getCell("S7").value;

    ws.getCell("W8").value =
      ws.getCell("C8").value +
      ws.getCell("G8").value +
      ws.getCell("K8").value +
      ws.getCell("O8").value +
      ws.getCell("S8").value;

    ws.getCell("W9").value =
      ws.getCell("C9").value +
      ws.getCell("G9").value +
      ws.getCell("K9").value +
      ws.getCell("O9").value +
      ws.getCell("S9").value;

    ws.getCell("W10").value =
      ws.getCell("C10").value +
      ws.getCell("G10").value +
      ws.getCell("K10").value +
      ws.getCell("O10").value +
      ws.getCell("S10").value;

    ws.getCell("W11").value =
      ws.getCell("C11").value +
      ws.getCell("G11").value +
      ws.getCell("K11").value +
      ws.getCell("O11").value +
      ws.getCell("S11").value;

    ws.getCell("W12").value =
      ws.getCell("C12").value +
      ws.getCell("G12").value +
      ws.getCell("K12").value +
      ws.getCell("O12").value +
      ws.getCell("S12").value;

    ws.getCell("X7").value = Math.floor(ws.getCell("W7").value / weekTotal) || 0;
    ws.getCell("X8").value = Math.floor(ws.getCell("W8").value / weekTotal) || 0;
    ws.getCell("X9").value = Math.floor(ws.getCell("W9").value / weekTotal) || 0;
    ws.getCell("X10").value =
      Math.floor(ws.getCell("W10").value / weekTotal) || 0;
    ws.getCell("X11").value =
      Math.floor(ws.getCell("W11").value / weekTotal) || 0;
    ws.getCell("X12").value =
      Math.floor(ws.getCell("W12").value / weekTotal) || 0;

    ws.getCell("Y7").value =
      (ws.getCell("C7").value > 22 ? 1 : 0) +
      (ws.getCell("G7").value > 22 ? 1 : 0) +
      (ws.getCell("K7").value > 22 ? 1 : 0) +
      (ws.getCell("O7").value > 22 ? 1 : 0) +
      (ws.getCell("S7").value > 22 ? 1 : 0);
    ws.getCell("Z7").value =
      (Math.floor((ws.getCell("Y7").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y8").value =
      (ws.getCell("C8").value > 22 ? 1 : 0) +
      (ws.getCell("G8").value > 22 ? 1 : 0) +
      (ws.getCell("K8").value > 22 ? 1 : 0) +
      (ws.getCell("O8").value > 22 ? 1 : 0) +
      (ws.getCell("S8").value > 22 ? 1 : 0);
    ws.getCell("Z8").value =
      (Math.floor((ws.getCell("Y8").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y9").value =
      (ws.getCell("C9").value > 22 ? 1 : 0) +
      (ws.getCell("G9").value > 22 ? 1 : 0) +
      (ws.getCell("K9").value > 22 ? 1 : 0) +
      (ws.getCell("O9").value > 22 ? 1 : 0) +
      (ws.getCell("S9").value > 22 ? 1 : 0);
    ws.getCell("Z9").value =
      (Math.floor((ws.getCell("Y9").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y10").value =
      (ws.getCell("C10").value > 22 ? 1 : 0) +
      (ws.getCell("G10").value > 22 ? 1 : 0) +
      (ws.getCell("K10").value > 22 ? 1 : 0) +
      (ws.getCell("O10").value > 22 ? 1 : 0) +
      (ws.getCell("S10").value > 22 ? 1 : 0);
    ws.getCell("Z10").value =
      (Math.floor((ws.getCell("Y10").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y11").value =
      (ws.getCell("C11").value > 22 ? 1 : 0) +
      (ws.getCell("G11").value > 22 ? 1 : 0) +
      (ws.getCell("K11").value > 22 ? 1 : 0) +
      (ws.getCell("O11").value > 22 ? 1 : 0) +
      (ws.getCell("S11").value > 22 ? 1 : 0);
    ws.getCell("Z11").value =
      (Math.floor((ws.getCell("Y11").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y12").value =
      (ws.getCell("C12").value > 22 ? 1 : 0) +
      (ws.getCell("G12").value > 22 ? 1 : 0) +
      (ws.getCell("K12").value > 22 ? 1 : 0) +
      (ws.getCell("O12").value > 22 ? 1 : 0) +
      (ws.getCell("S12").value > 22 ? 1 : 0);
    ws.getCell("Z12").value =
      (Math.floor((ws.getCell("Y12").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA7").value =
      (ws.getCell("C7").value === 28 ? 1 : 0) +
      (ws.getCell("G7").value === 28 ? 1 : 0) +
      (ws.getCell("K7").value === 28 ? 1 : 0) +
      (ws.getCell("O7").value === 28 ? 1 : 0) +
      (ws.getCell("S7").value === 28 ? 1 : 0);
    ws.getCell("AB7").value =
      (Math.floor((ws.getCell("AA7").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA8").value =
      (ws.getCell("C8").value === 28 ? 1 : 0) +
      (ws.getCell("G8").value === 28 ? 1 : 0) +
      (ws.getCell("K8").value === 28 ? 1 : 0) +
      (ws.getCell("O8").value === 28 ? 1 : 0) +
      (ws.getCell("S8").value === 28 ? 1 : 0);
    ws.getCell("AB8").value =
      (Math.floor((ws.getCell("AA8").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA9").value =
      (ws.getCell("C9").value === 28 ? 1 : 0) +
      (ws.getCell("G9").value === 28 ? 1 : 0) +
      (ws.getCell("K9").value === 28 ? 1 : 0) +
      (ws.getCell("O9").value === 28 ? 1 : 0) +
      (ws.getCell("S9").value === 28 ? 1 : 0);
    ws.getCell("AB9").value =
      (Math.floor((ws.getCell("AA9").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA10").value =
      (ws.getCell("C10").value === 28 ? 1 : 0) +
      (ws.getCell("G10").value === 28 ? 1 : 0) +
      (ws.getCell("K10").value === 28 ? 1 : 0) +
      (ws.getCell("O10").value === 28 ? 1 : 0) +
      (ws.getCell("S10").value === 28 ? 1 : 0);
    ws.getCell("AB10").value =
      (Math.floor((ws.getCell("AA10").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA11").value =
      (ws.getCell("C11").value === 28 ? 1 : 0) +
      (ws.getCell("G11").value === 28 ? 1 : 0) +
      (ws.getCell("K11").value === 28 ? 1 : 0) +
      (ws.getCell("O11").value === 28 ? 1 : 0) +
      (ws.getCell("S11").value === 28 ? 1 : 0);
    ws.getCell("AB11").value =
      (Math.floor((ws.getCell("AA11").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA12").value =
      (ws.getCell("C12").value === 28 ? 1 : 0) +
      (ws.getCell("G12").value === 28 ? 1 : 0) +
      (ws.getCell("K12").value === 28 ? 1 : 0) +
      (ws.getCell("O12").value === 28 ? 1 : 0) +
      (ws.getCell("S12").value === 28 ? 1 : 0);
    ws.getCell("AB12").value =
      (Math.floor((ws.getCell("AA12").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AC7").value =
      ws.getCell("F7").value +
      ws.getCell("J7").value +
      ws.getCell("N7").value +
      ws.getCell("R7").value +
      ws.getCell("V7").value;

    ws.getCell("AC8").value =
      ws.getCell("F8").value +
      ws.getCell("J8").value +
      ws.getCell("N8").value +
      ws.getCell("R8").value +
      ws.getCell("V8").value;

    ws.getCell("AC9").value =
      ws.getCell("F9").value +
      ws.getCell("J9").value +
      ws.getCell("N9").value +
      ws.getCell("R9").value +
      ws.getCell("V9").value;

    ws.getCell("AC10").value =
      ws.getCell("F10").value +
      ws.getCell("J10").value +
      ws.getCell("N10").value +
      ws.getCell("R10").value +
      ws.getCell("V10").value;

    ws.getCell("AC11").value =
      ws.getCell("F11").value +
      ws.getCell("J11").value +
      ws.getCell("N11").value +
      ws.getCell("R11").value +
      ws.getCell("V11").value;

    ws.getCell("AC12").value =
      ws.getCell("F12").value +
      ws.getCell("J12").value +
      ws.getCell("N12").value +
      ws.getCell("R12").value +
      ws.getCell("V12").value;

    ws.getCell("AD7").value =
      Math.floor(ws.getCell("AC7").value / weekTotal) || 0;
    ws.getCell("AD8").value =
      Math.floor(ws.getCell("AC8").value / weekTotal) || 0;
    ws.getCell("AD9").value =
      Math.floor(ws.getCell("AC9").value / weekTotal) || 0;
    ws.getCell("AD10").value =
      Math.floor(ws.getCell("AC10").value / weekTotal) || 0;
    ws.getCell("AD11").value =
      Math.floor(ws.getCell("AC11").value / weekTotal) || 0;
    ws.getCell("AD12").value =
      Math.floor(ws.getCell("AC12").value / weekTotal) || 0;

    ws.getCell("AE7").value =
      (ws.getCell("F7").value > 22 ? 1 : 0) +
      (ws.getCell("J7").value > 22 ? 1 : 0) +
      (ws.getCell("N7").value > 22 ? 1 : 0) +
      (ws.getCell("R7").value > 22 ? 1 : 0) +
      (ws.getCell("V7").value > 22 ? 1 : 0);
    ws.getCell("AF7").value =
      (Math.floor((ws.getCell("AE7").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE8").value =
      (ws.getCell("F8").value > 22 ? 1 : 0) +
      (ws.getCell("J8").value > 22 ? 1 : 0) +
      (ws.getCell("N8").value > 22 ? 1 : 0) +
      (ws.getCell("R8").value > 22 ? 1 : 0) +
      (ws.getCell("V8").value > 22 ? 1 : 0);
    ws.getCell("AF8").value =
      (Math.floor((ws.getCell("AE8").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE9").value =
      (ws.getCell("F9").value > 22 ? 1 : 0) +
      (ws.getCell("J9").value > 22 ? 1 : 0) +
      (ws.getCell("N9").value > 22 ? 1 : 0) +
      (ws.getCell("R9").value > 22 ? 1 : 0) +
      (ws.getCell("V9").value > 22 ? 1 : 0);
    ws.getCell("AF9").value =
      (Math.floor((ws.getCell("AE9").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE10").value =
      (ws.getCell("F10").value > 22 ? 1 : 0) +
      (ws.getCell("J10").value > 22 ? 1 : 0) +
      (ws.getCell("N10").value > 22 ? 1 : 0) +
      (ws.getCell("R10").value > 22 ? 1 : 0) +
      (ws.getCell("V10").value > 22 ? 1 : 0);
    ws.getCell("AF10").value =
      (Math.floor((ws.getCell("AE10").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE11").value =
      (ws.getCell("F11").value > 22 ? 1 : 0) +
      (ws.getCell("J11").value > 22 ? 1 : 0) +
      (ws.getCell("N11").value > 22 ? 1 : 0) +
      (ws.getCell("R11").value > 22 ? 1 : 0) +
      (ws.getCell("V11").value > 22 ? 1 : 0);
    ws.getCell("AF11").value =
      (Math.floor((ws.getCell("AE11").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE12").value =
      (ws.getCell("F12").value > 22 ? 1 : 0) +
      (ws.getCell("J12").value > 22 ? 1 : 0) +
      (ws.getCell("N12").value > 22 ? 1 : 0) +
      (ws.getCell("R12").value > 22 ? 1 : 0) +
      (ws.getCell("V12").value > 22 ? 1 : 0);
    ws.getCell("AF12").value =
      (Math.floor((ws.getCell("AE12").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG7").value =
      (ws.getCell("F7").value === 28 ? 1 : 0) +
      (ws.getCell("J7").value === 28 ? 1 : 0) +
      (ws.getCell("N7").value === 28 ? 1 : 0) +
      (ws.getCell("R7").value === 28 ? 1 : 0) +
      (ws.getCell("V7").value === 28 ? 1 : 0);
    ws.getCell("AH7").value =
      (Math.floor((ws.getCell("AG7").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG8").value =
      (ws.getCell("F8").value === 28 ? 1 : 0) +
      (ws.getCell("J8").value === 28 ? 1 : 0) +
      (ws.getCell("N8").value === 28 ? 1 : 0) +
      (ws.getCell("R8").value === 28 ? 1 : 0) +
      (ws.getCell("V8").value === 28 ? 1 : 0);
    ws.getCell("AH8").value =
      (Math.floor((ws.getCell("AG8").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG9").value =
      (ws.getCell("F9").value === 28 ? 1 : 0) +
      (ws.getCell("J9").value === 28 ? 1 : 0) +
      (ws.getCell("N9").value === 28 ? 1 : 0) +
      (ws.getCell("R9").value === 28 ? 1 : 0) +
      (ws.getCell("V9").value === 28 ? 1 : 0);
    ws.getCell("AH9").value =
      (Math.floor((ws.getCell("AG9").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG10").value =
      (ws.getCell("F10").value === 28 ? 1 : 0) +
      (ws.getCell("J10").value === 28 ? 1 : 0) +
      (ws.getCell("N10").value === 28 ? 1 : 0) +
      (ws.getCell("R10").value === 28 ? 1 : 0) +
      (ws.getCell("V10").value === 28 ? 1 : 0);
    ws.getCell("AH10").value =
      (Math.floor((ws.getCell("AG10").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG11").value =
      (ws.getCell("F11").value === 28 ? 1 : 0) +
      (ws.getCell("J11").value === 28 ? 1 : 0) +
      (ws.getCell("N11").value === 28 ? 1 : 0) +
      (ws.getCell("R11").value === 28 ? 1 : 0) +
      (ws.getCell("V11").value === 28 ? 1 : 0);
    ws.getCell("AH11").value =
      (Math.floor((ws.getCell("AG11").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG12").value =
      (ws.getCell("F12").value === 28 ? 1 : 0) +
      (ws.getCell("J12").value === 28 ? 1 : 0) +
      (ws.getCell("N12").value === 28 ? 1 : 0) +
      (ws.getCell("R12").value === 28 ? 1 : 0) +
      (ws.getCell("V12").value === 28 ? 1 : 0);
    ws.getCell("AH12").value =
      (Math.floor((ws.getCell("AG12").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AI7").value =
      Math.floor(
        (ws.getCell("D7").value +
          ws.getCell("H7").value +
          ws.getCell("L7").value +
          ws.getCell("P7").value +
          ws.getCell("T7").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI8").value =
      Math.floor(
        (ws.getCell("D8").value +
          ws.getCell("H8").value +
          ws.getCell("L8").value +
          ws.getCell("P8").value +
          ws.getCell("T8").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI9").value =
      Math.floor(
        (ws.getCell("D9").value +
          ws.getCell("H9").value +
          ws.getCell("L9").value +
          ws.getCell("P9").value +
          ws.getCell("T9").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI10").value =
      Math.floor(
        (ws.getCell("D10").value +
          ws.getCell("H10").value +
          ws.getCell("L10").value +
          ws.getCell("P10").value +
          ws.getCell("T10").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI11").value =
      ws.getCell("AI7").value +
      ws.getCell("AI8").value +
      ws.getCell("AI9").value +
      ws.getCell("AI10").value;
    ws.getCell("AI12").value = Math.floor(ws.getCell("AI11").value / 4) || 0;

    ws.getCell("AJ7").value =
      Math.floor(
        (ws.getCell("E7").value +
          ws.getCell("I7").value +
          ws.getCell("M7").value +
          ws.getCell("Q7").value +
          ws.getCell("U7").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ8").value =
      Math.floor(
        (ws.getCell("E8").value +
          ws.getCell("I8").value +
          ws.getCell("M8").value +
          ws.getCell("Q8").value +
          ws.getCell("U8").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ9").value =
      Math.floor(
        (ws.getCell("E9").value +
          ws.getCell("I9").value +
          ws.getCell("M9").value +
          ws.getCell("Q9").value +
          ws.getCell("U9").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ10").value =
      Math.floor(
        (ws.getCell("E10").value +
          ws.getCell("I10").value +
          ws.getCell("M10").value +
          ws.getCell("Q10").value +
          ws.getCell("U10").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ11").value =
      ws.getCell("AJ7").value +
      ws.getCell("AJ8").value +
      ws.getCell("AJ9").value +
      ws.getCell("AJ10").value;
    ws.getCell("AJ12").value = Math.floor(ws.getCell("AJ11").value / 4) || 0;

    // ROUTE 2
    ws.getCell("C17").value =
      getOnBoradCount(week_data1, "LG > MFC", "08:00") || 0;
    ws.getCell("C18").value =
      getOnBoradCount(week_data1, "LG > MFC", "08:30") || 0;
    ws.getCell("C19").value =
      getOnBoradCount(week_data1, "LG > MFC", "09:00") || 0;
    ws.getCell("C20").value =
      getOnBoradCount(week_data1, "LG > MFC", "09:30") || 0;
    ws.getCell("C21").value =
      ws.getCell("C17").value +
      ws.getCell("C18").value +
      ws.getCell("C19").value +
      ws.getCell("C20").value;
    ws.getCell("C22").value = Math.floor(ws.getCell("C21").value / 4) || 0;

    ws.getCell("D17").value =
      getOnBoradCount(week_data1, "LG > MFC", "08:00") || 0;
    ws.getCell("D18").value =
      getOnBoradCount(week_data1, "LG > MFC", "08:30") || 0;
    ws.getCell("D19").value =
      getOnBoradCount(week_data1, "LG > MFC", "09:00") || 0;
    ws.getCell("D20").value =
      getOnBoradCount(week_data1, "LG > MFC", "09:30") || 0;
    ws.getCell("D21").value =
      ws.getCell("D17").value +
      ws.getCell("D18").value +
      ws.getCell("D19").value +
      ws.getCell("D20").value;
    ws.getCell("D22").value = Math.floor(ws.getCell("D21").value / 4) || 0;

    ws.getCell("E17").value =
      getOnBoradCount(week_data1, "MFC > MT", "08:20") || 0;
    ws.getCell("E18").value =
      getOnBoradCount(week_data1, "MFC > MT", "08:55") || 0;
    ws.getCell("E19").value =
      getOnBoradCount(week_data1, "MFC > MT", "09:20") || 0;
    ws.getCell("E20").value =
      getOnBoradCount(week_data1, "MFC > MT", "09:55") || 0;
    ws.getCell("E21").value =
      ws.getCell("E17").value +
      ws.getCell("E18").value +
      ws.getCell("E19").value +
      ws.getCell("E20").value;
    ws.getCell("E22").value = Math.floor(ws.getCell("E21").value / 4) || 0;

    ws.getCell("F17").value =
      ws.getCell("C17").value -
      ws.getCell("D17").value +
      ws.getCell("E17").value;
    ws.getCell("F18").value =
      ws.getCell("C18").value -
      ws.getCell("D18").value +
      ws.getCell("E18").value;
    ws.getCell("F19").value =
      ws.getCell("C19").value -
      ws.getCell("D19").value +
      ws.getCell("E19").value;
    ws.getCell("F20").value =
      ws.getCell("C20").value -
      ws.getCell("D20").value +
      ws.getCell("E20").value;
    ws.getCell("F21").value =
      ws.getCell("F17").value +
      ws.getCell("F18").value +
      ws.getCell("F19").value +
      ws.getCell("F20").value;
    ws.getCell("F22").value = Math.floor(ws.getCell("F21").value / 4) || 0;

    ws.getCell("G17").value =
      getOnBoradCount(week_data2, "LG > MFC", "08:00") || 0;
    ws.getCell("G18").value =
      getOnBoradCount(week_data2, "LG > MFC", "08:30") || 0;
    ws.getCell("G19").value =
      getOnBoradCount(week_data2, "LG > MFC", "09:00") || 0;
    ws.getCell("G20").value =
      getOnBoradCount(week_data2, "LG > MFC", "09:30") || 0;
    ws.getCell("G21").value =
      ws.getCell("G17").value +
      ws.getCell("G18").value +
      ws.getCell("G19").value +
      ws.getCell("G20").value;
    ws.getCell("G22").value = Math.floor(ws.getCell("G21").value / 4) || 0;

    ws.getCell("H17").value =
      getOnBoradCount(week_data2, "LG > MFC", "08:00") || 0;
    ws.getCell("H18").value =
      getOnBoradCount(week_data2, "LG > MFC", "08:30") || 0;
    ws.getCell("H19").value =
      getOnBoradCount(week_data2, "LG > MFC", "09:00") || 0;
    ws.getCell("H20").value =
      getOnBoradCount(week_data2, "LG > MFC", "09:30") || 0;
    ws.getCell("H21").value =
      ws.getCell("H17").value +
      ws.getCell("H18").value +
      ws.getCell("H19").value +
      ws.getCell("H20").value;
    ws.getCell("H22").value = Math.floor(ws.getCell("H21").value / 4) || 0;

    ws.getCell("I17").value =
      getOnBoradCount(week_data1, "MFC > MT", "08:20") || 0;
    ws.getCell("I18").value =
      getOnBoradCount(week_data1, "MFC > MT", "08:55") || 0;
    ws.getCell("I19").value =
      getOnBoradCount(week_data1, "MFC > MT", "09:20") || 0;
    ws.getCell("I20").value =
      getOnBoradCount(week_data1, "MFC > MT", "09:55") || 0;
    ws.getCell("I21").value =
      ws.getCell("I17").value +
      ws.getCell("I18").value +
      ws.getCell("I19").value +
      ws.getCell("I20").value;
    ws.getCell("I22").value = Math.floor(ws.getCell("I21").value / 4) || 0;

    ws.getCell("J17").value =
      ws.getCell("G17").value -
      ws.getCell("H17").value +
      ws.getCell("I17").value;
    ws.getCell("J18").value =
      ws.getCell("G18").value -
      ws.getCell("H18").value +
      ws.getCell("I18").value;
    ws.getCell("J19").value =
      ws.getCell("G19").value -
      ws.getCell("H19").value +
      ws.getCell("I19").value;
    ws.getCell("J20").value =
      ws.getCell("G20").value -
      ws.getCell("H20").value +
      ws.getCell("I20").value;
    ws.getCell("J21").value =
      ws.getCell("J17").value +
      ws.getCell("J18").value +
      ws.getCell("J19").value +
      ws.getCell("J20").value;
    ws.getCell("J22").value = Math.floor(ws.getCell("J21").value / 4) || 0;

    ws.getCell("K17").value =
      getOnBoradCount(week_data3, "LG > MFC", "08:00") || 0;
    ws.getCell("K18").value =
      getOnBoradCount(week_data3, "LG > MFC", "08:30") || 0;
    ws.getCell("K19").value =
      getOnBoradCount(week_data3, "LG > MFC", "09:00") || 0;
    ws.getCell("K20").value =
      getOnBoradCount(week_data3, "LG > MFC", "09:30") || 0;
    ws.getCell("K21").value =
      ws.getCell("K17").value +
      ws.getCell("K18").value +
      ws.getCell("K19").value +
      ws.getCell("K20").value;
    ws.getCell("K22").value = Math.floor(ws.getCell("K21").value / 4) || 0;

    ws.getCell("L17").value =
      getOffBoradCount(week_data3, "LG > MFC", "08:00") || 0;
    ws.getCell("L18").value =
      getOffBoradCount(week_data3, "LG > MFC", "08:30") || 0;
    ws.getCell("L19").value =
      getOffBoradCount(week_data3, "LG > MFC", "09:00") || 0;
    ws.getCell("L20").value =
      getOffBoradCount(week_data3, "LG > MFC", "09:30") || 0;
    ws.getCell("L21").value =
      ws.getCell("L17").value +
      ws.getCell("L18").value +
      ws.getCell("L19").value +
      ws.getCell("L20").value;
    ws.getCell("L22").value = Math.floor(ws.getCell("L21").value / 4) || 0;

    ws.getCell("M17").value =
      getOnBoradCount(week_data3, "MFC > MT", "08:20") || 0;
    ws.getCell("M18").value =
      getOnBoradCount(week_data3, "MFC > MT", "08:55") || 0;
    ws.getCell("M19").value =
      getOnBoradCount(week_data3, "MFC > MT", "09:20") || 0;
    ws.getCell("M20").value =
      getOnBoradCount(week_data3, "MFC > MT", "09:55") || 0;
    ws.getCell("M21").value =
      ws.getCell("M17").value +
      ws.getCell("M18").value +
      ws.getCell("M19").value +
      ws.getCell("M20").value;
    ws.getCell("M22").value = Math.floor(ws.getCell("M11").value / 4) || 0;

    ws.getCell("N17").value =
      ws.getCell("K17").value -
      ws.getCell("L17").value +
      ws.getCell("M17").value;
    ws.getCell("N18").value =
      ws.getCell("K18").value -
      ws.getCell("L18").value +
      ws.getCell("M18").value;
    ws.getCell("N19").value =
      ws.getCell("K19").value -
      ws.getCell("L19").value +
      ws.getCell("M19").value;
    ws.getCell("N20").value =
      ws.getCell("K20").value -
      ws.getCell("L20").value +
      ws.getCell("M20").value;
    ws.getCell("N21").value =
      ws.getCell("N17").value +
      ws.getCell("N18").value +
      ws.getCell("N19").value +
      ws.getCell("N20").value;
    ws.getCell("N22").value = Math.floor(ws.getCell("N21").value / 4) || 0;

    ws.getCell("O17").value =
      getOnBoradCount(week_data4, "LG > MFC", "08:00") || 0;
    ws.getCell("O18").value =
      getOnBoradCount(week_data4, "LG > MFC", "08:30") || 0;
    ws.getCell("O19").value =
      getOnBoradCount(week_data4, "LG > MFC", "09:00") || 0;
    ws.getCell("O20").value =
      getOnBoradCount(week_data4, "LG > MFC", "09:30") || 0;
    ws.getCell("O21").value =
      ws.getCell("O17").value +
      ws.getCell("O18").value +
      ws.getCell("O19").value +
      ws.getCell("O20").value;
    ws.getCell("O22").value = Math.floor(ws.getCell("O21").value / 4) || 0;

    ws.getCell("P17").value =
      getOffBoradCount(week_data4, "LG > MFC", "08:00") || 0;
    ws.getCell("P18").value =
      getOffBoradCount(week_data4, "LG > MFC", "08:30") || 0;
    ws.getCell("P19").value =
      getOffBoradCount(week_data4, "LG > MFC", "09:00") || 0;
    ws.getCell("P20").value =
      getOffBoradCount(week_data4, "LG > MFC", "09:30") || 0;
    ws.getCell("P21").value =
      ws.getCell("P17").value +
      ws.getCell("P18").value +
      ws.getCell("P19").value +
      ws.getCell("P20").value;
    ws.getCell("P22").value = Math.floor(ws.getCell("P21").value / 4) || 0;

    ws.getCell("Q17").value =
      getOnBoradCount(week_data4, "MFC > MT", "08:20") || 0;
    ws.getCell("Q18").value =
      getOnBoradCount(week_data4, "MFC > MT", "08:55") || 0;
    ws.getCell("Q19").value =
      getOnBoradCount(week_data4, "MFC > MT", "09:20") || 0;
    ws.getCell("Q20").value =
      getOnBoradCount(week_data4, "MFC > MT", "09:55") || 0;
    ws.getCell("Q21").value =
      ws.getCell("Q17").value +
      ws.getCell("Q18").value +
      ws.getCell("Q19").value +
      ws.getCell("Q20").value;
    ws.getCell("Q22").value = Math.floor(ws.getCell("Q21").value / 4) || 0;

    ws.getCell("R17").value =
      ws.getCell("O17").value -
      ws.getCell("P17").value +
      ws.getCell("Q17").value;
    ws.getCell("R18").value =
      ws.getCell("O18").value -
      ws.getCell("P18").value +
      ws.getCell("Q18").value;
    ws.getCell("R19").value =
      ws.getCell("O19").value -
      ws.getCell("P19").value +
      ws.getCell("Q19").value;
    ws.getCell("R20").value =
      ws.getCell("O20").value -
      ws.getCell("P20").value +
      ws.getCell("Q20").value;
    ws.getCell("R21").value =
      ws.getCell("R17").value +
      ws.getCell("R18").value +
      ws.getCell("R19").value +
      ws.getCell("R20").value;
    ws.getCell("R22").value = Math.floor(ws.getCell("R21").value / 4) || 0;

    ws.getCell("S17").value =
      getOnBoradCount(week_data5, "LG > MFC", "08:00") || 0;
    ws.getCell("S18").value =
      getOnBoradCount(week_data5, "LG > MFC", "08:30") || 0;
    ws.getCell("S19").value =
      getOnBoradCount(week_data5, "LG > MFC", "08:00") || 0;
    ws.getCell("S20").value =
      getOnBoradCount(week_data5, "LG > MFC", "09:30") || 0;
    ws.getCell("S21").value =
      ws.getCell("S17").value +
      ws.getCell("S18").value +
      ws.getCell("S19").value +
      ws.getCell("S20").value;
    ws.getCell("S22").value = Math.floor(ws.getCell("S21").value / 4) || 0;

    ws.getCell("T17").value =
      getOffBoradCount(week_data5, "LG > MFC", "08:00") || 0;
    ws.getCell("T18").value =
      getOffBoradCount(week_data5, "LG > MFC", "08:30") || 0;
    ws.getCell("T19").value =
      getOffBoradCount(week_data5, "LG > MFC", "09:00") || 0;
    ws.getCell("T20").value =
      getOffBoradCount(week_data5, "LG > MFC", "09:30") || 0;
    ws.getCell("T21").value =
      ws.getCell("T17").value +
      ws.getCell("T18").value +
      ws.getCell("T19").value +
      ws.getCell("T20").value;
    ws.getCell("T22").value = Math.floor(ws.getCell("T21").value / 4) || 0;

    ws.getCell("U17").value =
      getOnBoradCount(week_data5, "MFC > MT", "08:20") || 0;
    ws.getCell("U18").value =
      getOnBoradCount(week_data5, "MFC > MT", "08:55") || 0;
    ws.getCell("U19").value =
      getOnBoradCount(week_data5, "MFC > MT", "09:20") || 0;
    ws.getCell("U20").value =
      getOnBoradCount(week_data5, "MFC > MT", "09:55") || 0;
    ws.getCell("U21").value =
      ws.getCell("U17").value +
      ws.getCell("U18").value +
      ws.getCell("U19").value +
      ws.getCell("U20").value;
    ws.getCell("U22").value = Math.floor(ws.getCell("U21").value / 4) || 0;

    ws.getCell("V17").value =
      ws.getCell("S17").value -
      ws.getCell("T17").value +
      ws.getCell("U17").value;
    ws.getCell("V18").value =
      ws.getCell("S18").value -
      ws.getCell("T18").value +
      ws.getCell("U18").value;
    ws.getCell("V19").value =
      ws.getCell("S19").value -
      ws.getCell("T19").value +
      ws.getCell("U19").value;
    ws.getCell("V20").value =
      ws.getCell("S20").value -
      ws.getCell("T20").value +
      ws.getCell("U20").value;
    ws.getCell("V21").value =
      ws.getCell("V17").value +
      ws.getCell("V18").value +
      ws.getCell("V19").value +
      ws.getCell("V20").value;
    ws.getCell("V22").value = Math.floor(ws.getCell("V21").value / 4) || 0;

    ws.getCell("W17").value =
      ws.getCell("C17").value +
      ws.getCell("G17").value +
      ws.getCell("K17").value +
      ws.getCell("O17").value +
      ws.getCell("S17").value;

    ws.getCell("W18").value =
      ws.getCell("C18").value +
      ws.getCell("G18").value +
      ws.getCell("K18").value +
      ws.getCell("O18").value +
      ws.getCell("S18").value;

    ws.getCell("W19").value =
      ws.getCell("C19").value +
      ws.getCell("G19").value +
      ws.getCell("K19").value +
      ws.getCell("O19").value +
      ws.getCell("S19").value;

    ws.getCell("W20").value =
      ws.getCell("C20").value +
      ws.getCell("G20").value +
      ws.getCell("K20").value +
      ws.getCell("O20").value +
      ws.getCell("S20").value;

    ws.getCell("W21").value =
      ws.getCell("C21").value +
      ws.getCell("G21").value +
      ws.getCell("K21").value +
      ws.getCell("O21").value +
      ws.getCell("S21").value;

    ws.getCell("W22").value =
      ws.getCell("C22").value +
      ws.getCell("G22").value +
      ws.getCell("K22").value +
      ws.getCell("O22").value +
      ws.getCell("S22").value;

    ws.getCell("X17").value =
      Math.floor(ws.getCell("W17").value / weekTotal) || 0;
    ws.getCell("X18").value =
      Math.floor(ws.getCell("W18").value / weekTotal) || 0;
    ws.getCell("X19").value =
      Math.floor(ws.getCell("W19").value / weekTotal) || 0;
    ws.getCell("X20").value =
      Math.floor(ws.getCell("W20").value / weekTotal) || 0;
    ws.getCell("X21").value =
      Math.floor(ws.getCell("W21").value / weekTotal) || 0;
    ws.getCell("X22").value =
      Math.floor(ws.getCell("W22").value / weekTotal) || 0;

    ws.getCell("Y17").value =
      (ws.getCell("C17").value > 22 ? 1 : 0) +
      (ws.getCell("G17").value > 22 ? 1 : 0) +
      (ws.getCell("K17").value > 22 ? 1 : 0) +
      (ws.getCell("O17").value > 22 ? 1 : 0) +
      (ws.getCell("S17").value > 22 ? 1 : 0);
    ws.getCell("Z17").value =
      (Math.floor((ws.getCell("Y17").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y18").value =
      (ws.getCell("C18").value > 22 ? 1 : 0) +
      (ws.getCell("G18").value > 22 ? 1 : 0) +
      (ws.getCell("K18").value > 22 ? 1 : 0) +
      (ws.getCell("O18").value > 22 ? 1 : 0) +
      (ws.getCell("S18").value > 22 ? 1 : 0);
    ws.getCell("Z18").value =
      (Math.floor((ws.getCell("Y18").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y19").value =
      (ws.getCell("C19").value > 22 ? 1 : 0) +
      (ws.getCell("G19").value > 22 ? 1 : 0) +
      (ws.getCell("K19").value > 22 ? 1 : 0) +
      (ws.getCell("O19").value > 22 ? 1 : 0) +
      (ws.getCell("S19").value > 22 ? 1 : 0);
    ws.getCell("Z19").value =
      (Math.floor((ws.getCell("Y19").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y20").value =
      (ws.getCell("C20").value > 22 ? 1 : 0) +
      (ws.getCell("G20").value > 22 ? 1 : 0) +
      (ws.getCell("K20").value > 22 ? 1 : 0) +
      (ws.getCell("O20").value > 22 ? 1 : 0) +
      (ws.getCell("S20").value > 22 ? 1 : 0);
    ws.getCell("Z20").value =
      (Math.floor((ws.getCell("Y20").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y21").value =
      (ws.getCell("C21").value > 22 ? 1 : 0) +
      (ws.getCell("G21").value > 22 ? 1 : 0) +
      (ws.getCell("K21").value > 22 ? 1 : 0) +
      (ws.getCell("O21").value > 22 ? 1 : 0) +
      (ws.getCell("S21").value > 22 ? 1 : 0);
    ws.getCell("Z21").value =
      (Math.floor((ws.getCell("Y21").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("Y22").value =
      (ws.getCell("C22").value > 22 ? 1 : 0) +
      (ws.getCell("G22").value > 22 ? 1 : 0) +
      (ws.getCell("K22").value > 22 ? 1 : 0) +
      (ws.getCell("O22").value > 22 ? 1 : 0) +
      (ws.getCell("S22").value > 22 ? 1 : 0);
    ws.getCell("Z22").value =
      (Math.floor((ws.getCell("Y22").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA17").value =
      (ws.getCell("C17").value === 28 ? 1 : 0) +
      (ws.getCell("G17").value === 28 ? 1 : 0) +
      (ws.getCell("K17").value === 28 ? 1 : 0) +
      (ws.getCell("O17").value === 28 ? 1 : 0) +
      (ws.getCell("S17").value === 28 ? 1 : 0);
    ws.getCell("AB17").value =
      (Math.floor((ws.getCell("AA17").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA18").value =
      (ws.getCell("C18").value === 28 ? 1 : 0) +
      (ws.getCell("G18").value === 28 ? 1 : 0) +
      (ws.getCell("K18").value === 28 ? 1 : 0) +
      (ws.getCell("O18").value === 28 ? 1 : 0) +
      (ws.getCell("S18").value === 28 ? 1 : 0);
    ws.getCell("AB18").value =
      (Math.floor((ws.getCell("AA18").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA19").value =
      (ws.getCell("C19").value === 28 ? 1 : 0) +
      (ws.getCell("G19").value === 28 ? 1 : 0) +
      (ws.getCell("K19").value === 28 ? 1 : 0) +
      (ws.getCell("O19").value === 28 ? 1 : 0) +
      (ws.getCell("S19").value === 28 ? 1 : 0);
    ws.getCell("AB19").value =
      (Math.floor((ws.getCell("AA19").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA20").value =
      (ws.getCell("C20").value === 28 ? 1 : 0) +
      (ws.getCell("G20").value === 28 ? 1 : 0) +
      (ws.getCell("K20").value === 28 ? 1 : 0) +
      (ws.getCell("O20").value === 28 ? 1 : 0) +
      (ws.getCell("S20").value === 28 ? 1 : 0);
    ws.getCell("AB20").value =
      (Math.floor((ws.getCell("AA20").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA21").value =
      (ws.getCell("C21").value === 28 ? 1 : 0) +
      (ws.getCell("G21").value === 28 ? 1 : 0) +
      (ws.getCell("K21").value === 28 ? 1 : 0) +
      (ws.getCell("O21").value === 28 ? 1 : 0) +
      (ws.getCell("S21").value === 28 ? 1 : 0);
    ws.getCell("AB21").value =
      (Math.floor((ws.getCell("AA21").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA22").value =
      (ws.getCell("C22").value === 28 ? 1 : 0) +
      (ws.getCell("G22").value === 28 ? 1 : 0) +
      (ws.getCell("K22").value === 28 ? 1 : 0) +
      (ws.getCell("O22").value === 28 ? 1 : 0) +
      (ws.getCell("S22").value === 28 ? 1 : 0);
    ws.getCell("AB22").value =
      (Math.floor((ws.getCell("AA22").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AC17").value =
      ws.getCell("F17").value +
      ws.getCell("J17").value +
      ws.getCell("N17").value +
      ws.getCell("R17").value +
      ws.getCell("V17").value;

    ws.getCell("AC18").value =
      ws.getCell("F18").value +
      ws.getCell("J18").value +
      ws.getCell("N18").value +
      ws.getCell("R18").value +
      ws.getCell("V18").value;

    ws.getCell("AC19").value =
      ws.getCell("F19").value +
      ws.getCell("J19").value +
      ws.getCell("N19").value +
      ws.getCell("R19").value +
      ws.getCell("V19").value;

    ws.getCell("AC20").value =
      ws.getCell("F20").value +
      ws.getCell("J20").value +
      ws.getCell("N20").value +
      ws.getCell("R20").value +
      ws.getCell("V20").value;

    ws.getCell("AC21").value =
      ws.getCell("F21").value +
      ws.getCell("J21").value +
      ws.getCell("N21").value +
      ws.getCell("R21").value +
      ws.getCell("V21").value;

    ws.getCell("AC22").value =
      ws.getCell("F22").value +
      ws.getCell("J22").value +
      ws.getCell("N22").value +
      ws.getCell("R22").value +
      ws.getCell("V22").value;

    ws.getCell("AD17").value =
      Math.floor(ws.getCell("AC17").value / weekTotal) || 0;
    ws.getCell("AD18").value =
      Math.floor(ws.getCell("AC18").value / weekTotal) || 0;
    ws.getCell("AD19").value =
      Math.floor(ws.getCell("AC19").value / weekTotal) || 0;
    ws.getCell("AD20").value =
      Math.floor(ws.getCell("AC20").value / weekTotal) || 0;
    ws.getCell("AD21").value =
      Math.floor(ws.getCell("AC21").value / weekTotal) || 0;
    ws.getCell("AD22").value =
      Math.floor(ws.getCell("AC22").value / weekTotal) || 0;

    ws.getCell("AE17").value =
      (ws.getCell("F17").value > 22 ? 1 : 0) +
      (ws.getCell("J17").value > 22 ? 1 : 0) +
      (ws.getCell("N17").value > 22 ? 1 : 0) +
      (ws.getCell("R17").value > 22 ? 1 : 0) +
      (ws.getCell("V17").value > 22 ? 1 : 0);
    ws.getCell("AF17").value =
      (Math.floor((ws.getCell("AE17").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE18").value =
      (ws.getCell("F18").value > 22 ? 1 : 0) +
      (ws.getCell("J18").value > 22 ? 1 : 0) +
      (ws.getCell("N18").value > 22 ? 1 : 0) +
      (ws.getCell("R18").value > 22 ? 1 : 0) +
      (ws.getCell("V18").value > 22 ? 1 : 0);
    ws.getCell("AF18").value =
      (Math.floor((ws.getCell("AE18").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE19").value =
      (ws.getCell("F19").value > 22 ? 1 : 0) +
      (ws.getCell("J19").value > 22 ? 1 : 0) +
      (ws.getCell("N19").value > 22 ? 1 : 0) +
      (ws.getCell("R19").value > 22 ? 1 : 0) +
      (ws.getCell("V19").value > 22 ? 1 : 0);
    ws.getCell("AF19").value =
      (Math.floor((ws.getCell("AE19").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE20").value =
      (ws.getCell("F20").value > 22 ? 1 : 0) +
      (ws.getCell("J20").value > 22 ? 1 : 0) +
      (ws.getCell("N20").value > 22 ? 1 : 0) +
      (ws.getCell("R20").value > 22 ? 1 : 0) +
      (ws.getCell("V20").value > 22 ? 1 : 0);
    ws.getCell("AF20").value =
      (Math.floor((ws.getCell("AE20").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE21").value =
      (ws.getCell("F21").value > 22 ? 1 : 0) +
      (ws.getCell("J21").value > 22 ? 1 : 0) +
      (ws.getCell("N21").value > 22 ? 1 : 0) +
      (ws.getCell("R21").value > 22 ? 1 : 0) +
      (ws.getCell("V21").value > 22 ? 1 : 0);
    ws.getCell("AF21").value =
      (Math.floor((ws.getCell("AE21").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AE22").value =
      (ws.getCell("F22").value > 22 ? 1 : 0) +
      (ws.getCell("J22").value > 22 ? 1 : 0) +
      (ws.getCell("N22").value > 22 ? 1 : 0) +
      (ws.getCell("R22").value > 22 ? 1 : 0) +
      (ws.getCell("V22").value > 22 ? 1 : 0);
    ws.getCell("AF22").value =
      (Math.floor((ws.getCell("AE22").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG17").value =
      (ws.getCell("F17").value === 28 ? 1 : 0) +
      (ws.getCell("J17").value === 28 ? 1 : 0) +
      (ws.getCell("N17").value === 28 ? 1 : 0) +
      (ws.getCell("R17").value === 28 ? 1 : 0) +
      (ws.getCell("V17").value === 28 ? 1 : 0);
    ws.getCell("AH17").value =
      (Math.floor((ws.getCell("AG17").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG18").value =
      (ws.getCell("F18").value === 28 ? 1 : 0) +
      (ws.getCell("J18").value === 28 ? 1 : 0) +
      (ws.getCell("N18").value === 28 ? 1 : 0) +
      (ws.getCell("R18").value === 28 ? 1 : 0) +
      (ws.getCell("V18").value === 28 ? 1 : 0);
    ws.getCell("AH18").value =
      (Math.floor((ws.getCell("AG18").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG19").value =
      (ws.getCell("F19").value === 28 ? 1 : 0) +
      (ws.getCell("J19").value === 28 ? 1 : 0) +
      (ws.getCell("N19").value === 28 ? 1 : 0) +
      (ws.getCell("R19").value === 28 ? 1 : 0) +
      (ws.getCell("V19").value === 28 ? 1 : 0);
    ws.getCell("AH19").value =
      (Math.floor((ws.getCell("AG19").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG20").value =
      (ws.getCell("F20").value === 28 ? 1 : 0) +
      (ws.getCell("J20").value === 28 ? 1 : 0) +
      (ws.getCell("N20").value === 28 ? 1 : 0) +
      (ws.getCell("R20").value === 28 ? 1 : 0) +
      (ws.getCell("V20").value === 28 ? 1 : 0);
    ws.getCell("AH20").value =
      (Math.floor((ws.getCell("AG10").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG21").value =
      (ws.getCell("F21").value === 28 ? 1 : 0) +
      (ws.getCell("J21").value === 28 ? 1 : 0) +
      (ws.getCell("N21").value === 28 ? 1 : 0) +
      (ws.getCell("R21").value === 28 ? 1 : 0) +
      (ws.getCell("V21").value === 28 ? 1 : 0);
    ws.getCell("AH21").value =
      (Math.floor((ws.getCell("AG21").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG22").value =
      (ws.getCell("F22").value === 28 ? 1 : 0) +
      (ws.getCell("J22").value === 28 ? 1 : 0) +
      (ws.getCell("N22").value === 28 ? 1 : 0) +
      (ws.getCell("R22").value === 28 ? 1 : 0) +
      (ws.getCell("V22").value === 28 ? 1 : 0);
    ws.getCell("AH22").value =
      (Math.floor((ws.getCell("AG22").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AI17").value =
      Math.floor(
        (ws.getCell("D17").value +
          ws.getCell("H17").value +
          ws.getCell("L17").value +
          ws.getCell("P17").value +
          ws.getCell("T17").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI18").value =
      Math.floor(
        (ws.getCell("D18").value +
          ws.getCell("H18").value +
          ws.getCell("L18").value +
          ws.getCell("P18").value +
          ws.getCell("T18").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI19").value =
      Math.floor(
        (ws.getCell("D19").value +
          ws.getCell("H19").value +
          ws.getCell("L19").value +
          ws.getCell("P19").value +
          ws.getCell("T19").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI20").value =
      Math.floor(
        (ws.getCell("D20").value +
          ws.getCell("H20").value +
          ws.getCell("L20").value +
          ws.getCell("P20").value +
          ws.getCell("T20").value) /
        weekTotal
      ) || 0;

    ws.getCell("AI21").value =
      ws.getCell("AI17").value +
      ws.getCell("AI18").value +
      ws.getCell("AI19").value +
      ws.getCell("AI20").value;
    ws.getCell("AI22").value = Math.floor(ws.getCell("AI21").value / 4) || 0;

    ws.getCell("AJ17").value =
      Math.floor(
        (ws.getCell("E17").value +
          ws.getCell("I17").value +
          ws.getCell("M17").value +
          ws.getCell("Q17").value +
          ws.getCell("U17").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ18").value =
      Math.floor(
        (ws.getCell("E18").value +
          ws.getCell("I18").value +
          ws.getCell("M18").value +
          ws.getCell("Q18").value +
          ws.getCell("U18").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ19").value =
      Math.floor(
        (ws.getCell("E19").value +
          ws.getCell("I19").value +
          ws.getCell("M19").value +
          ws.getCell("Q19").value +
          ws.getCell("U19").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ20").value =
      Math.floor(
        (ws.getCell("E20").value +
          ws.getCell("I20").value +
          ws.getCell("M20").value +
          ws.getCell("Q20").value +
          ws.getCell("U20").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ21").value =
      ws.getCell("AJ17").value +
      ws.getCell("AJ18").value +
      ws.getCell("AJ19").value +
      ws.getCell("AJ20").value;
    ws.getCell("AJ22").value = Math.floor(ws.getCell("AJ21").value / 4) || 0;

    ws.getCell("C27").value = getOnBoradCount(week_data1, "MT > MFC", "08:20");
    ws.getCell("C28").value = getOnBoradCount(week_data1, "MT > MFC", "08:50");
    ws.getCell("C29").value = getOnBoradCount(week_data1, "MT > MFC", "09:20");
    ws.getCell("C30").value = getOnBoradCount(week_data1, "MT > MFC", "09:50");
    ws.getCell("C31").value =
      ws.getCell("C27").value +
      ws.getCell("C28").value +
      ws.getCell("C29").value +
      ws.getCell("C30").value;
    ws.getCell("C32").value = Math.floor(ws.getCell("C31").value / 4) || 0;

    ws.getCell("D27").value = getOnBoradCount(week_data2, "MT > MFC", "08:20");
    ws.getCell("D28").value = getOnBoradCount(week_data2, "MT > MFC", "08:50");
    ws.getCell("D29").value = getOnBoradCount(week_data2, "MT > MFC", "09:20");
    ws.getCell("D30").value = getOnBoradCount(week_data2, "MT > MFC", "09:50");
    ws.getCell("D31").value =
      ws.getCell("D27").value +
      ws.getCell("D28").value +
      ws.getCell("D29").value +
      ws.getCell("D30").value;
    ws.getCell("D32").value = Math.floor(ws.getCell("D31").value / 4) || 0;

    ws.getCell("E27").value = getOnBoradCount(week_data3, "MT > MFC", "08:20");
    ws.getCell("E28").value = getOnBoradCount(week_data3, "MT > MFC", "08:50");
    ws.getCell("E29").value = getOnBoradCount(week_data3, "MT > MFC", "09:20");
    ws.getCell("E30").value = getOnBoradCount(week_data3, "MT > MFC", "09:50");
    ws.getCell("E31").value =
      ws.getCell("E27").value +
      ws.getCell("E28").value +
      ws.getCell("E29").value +
      ws.getCell("E30").value;
    ws.getCell("E32").value = Math.floor(ws.getCell("E31").value / 4) || 0;

    ws.getCell("F27").value = getOnBoradCount(week_data4, "MT > MFC", "08:20");
    ws.getCell("F28").value = getOnBoradCount(week_data4, "MT > MFC", "08:50");
    ws.getCell("F29").value = getOnBoradCount(week_data4, "MT > MFC", "09:20");
    ws.getCell("F30").value = getOnBoradCount(week_data4, "MT > MFC", "09:50");
    ws.getCell("F31").value =
      ws.getCell("F27").value +
      ws.getCell("F28").value +
      ws.getCell("F29").value +
      ws.getCell("F30").value;
    ws.getCell("F32").value = Math.floor(ws.getCell("F31").value / 4) || 0;

    ws.getCell("G27").value = getOnBoradCount(week_data5, "MT > MFC", "08:20");
    ws.getCell("G28").value = getOnBoradCount(week_data5, "MT > MFC", "08:50");
    ws.getCell("G29").value = getOnBoradCount(week_data5, "MT > MFC", "09:20");
    ws.getCell("G30").value = getOnBoradCount(week_data5, "MT > MFC", "09:50");
    ws.getCell("G31").value =
      ws.getCell("G27").value +
      ws.getCell("G28").value +
      ws.getCell("G29").value +
      ws.getCell("G30").value;
    ws.getCell("G32").value = Math.floor(ws.getCell("G31").value / 4) || 0;

    ws.getCell("H27").value =
      ws.getCell("C27").value +
      ws.getCell("D27").value +
      ws.getCell("E27").value +
      ws.getCell("F27").value +
      ws.getCell("G27").value;

    ws.getCell("H28").value =
      ws.getCell("C28").value +
      ws.getCell("D28").value +
      ws.getCell("E28").value +
      ws.getCell("F28").value +
      ws.getCell("G28").value;

    ws.getCell("H29").value =
      ws.getCell("C29").value +
      ws.getCell("D29").value +
      ws.getCell("E29").value +
      ws.getCell("F29").value +
      ws.getCell("G29").value;

    ws.getCell("H30").value =
      ws.getCell("C30").value +
      ws.getCell("D30").value +
      ws.getCell("E30").value +
      ws.getCell("F30").value +
      ws.getCell("G30").value;

    ws.getCell("H31").value =
      ws.getCell("C31").value +
      ws.getCell("D31").value +
      ws.getCell("E31").value +
      ws.getCell("F31").value +
      ws.getCell("G31").value;

    ws.getCell("H32").value =
      ws.getCell("C32").value +
      ws.getCell("D32").value +
      ws.getCell("E32").value +
      ws.getCell("F32").value +
      ws.getCell("G32").value;

    ws.getCell("I27").value =
      Math.floor(ws.getCell("H27").value / weekTotal) || 0;
    ws.getCell("I28").value =
      Math.floor(ws.getCell("H28").value / weekTotal) || 0;
    ws.getCell("I29").value =
      Math.floor(ws.getCell("H29").value / weekTotal) || 0;
    ws.getCell("I30").value =
      Math.floor(ws.getCell("H30").value / weekTotal) || 0;
    ws.getCell("I31").value =
      Math.floor(ws.getCell("H31").value / weekTotal) || 0;
    ws.getCell("I32").value =
      Math.floor(ws.getCell("H32").value / weekTotal) || 0;

    ws.getCell("J27").value =
      (ws.getCell("C27").value > 22 ? 1 : 0) +
      (ws.getCell("D27").value > 22 ? 1 : 0) +
      (ws.getCell("E27").value > 22 ? 1 : 0) +
      (ws.getCell("F27").value > 22 ? 1 : 0) +
      (ws.getCell("G27").value > 22 ? 1 : 0);
    ws.getCell("K27").value =
      (Math.floor((ws.getCell("J27").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J28").value =
      (ws.getCell("C28").value > 22 ? 1 : 0) +
      (ws.getCell("D28").value > 22 ? 1 : 0) +
      (ws.getCell("E28").value > 22 ? 1 : 0) +
      (ws.getCell("F28").value > 22 ? 1 : 0) +
      (ws.getCell("G28").value > 22 ? 1 : 0);
    ws.getCell("K28").value =
      (Math.floor((ws.getCell("J28").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J29").value =
      (ws.getCell("C29").value > 22 ? 1 : 0) +
      (ws.getCell("D29").value > 22 ? 1 : 0) +
      (ws.getCell("E29").value > 22 ? 1 : 0) +
      (ws.getCell("F29").value > 22 ? 1 : 0) +
      (ws.getCell("G29").value > 22 ? 1 : 0);
    ws.getCell("K29").value =
      (Math.floor((ws.getCell("J29").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J30").value =
      (ws.getCell("C30").value > 22 ? 1 : 0) +
      (ws.getCell("D30").value > 22 ? 1 : 0) +
      (ws.getCell("E30").value > 22 ? 1 : 0) +
      (ws.getCell("F30").value > 22 ? 1 : 0) +
      (ws.getCell("G30").value > 22 ? 1 : 0);
    ws.getCell("K30").value =
      (Math.floor((ws.getCell("J30").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J31").value =
      (ws.getCell("C31").value > 22 ? 1 : 0) +
      (ws.getCell("D31").value > 22 ? 1 : 0) +
      (ws.getCell("E31").value > 22 ? 1 : 0) +
      (ws.getCell("F31").value > 22 ? 1 : 0) +
      (ws.getCell("G31").value > 22 ? 1 : 0);
    ws.getCell("K31").value =
      (Math.floor((ws.getCell("J31").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J32").value =
      (ws.getCell("C32").value > 22 ? 1 : 0) +
      (ws.getCell("D32").value > 22 ? 1 : 0) +
      (ws.getCell("E32").value > 22 ? 1 : 0) +
      (ws.getCell("F32").value > 22 ? 1 : 0) +
      (ws.getCell("G32").value > 22 ? 1 : 0);
    ws.getCell("K32").value =
      (Math.floor((ws.getCell("J32").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L27").value =
      (ws.getCell("C27").value === 28 ? 1 : 0) +
      (ws.getCell("D27").value === 28 ? 1 : 0) +
      (ws.getCell("E27").value === 28 ? 1 : 0) +
      (ws.getCell("F27").value === 28 ? 1 : 0) +
      (ws.getCell("G27").value === 28 ? 1 : 0);
    ws.getCell("M27").value =
      (Math.floor((ws.getCell("L27").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L28").value =
      (ws.getCell("C28").value === 28 ? 1 : 0) +
      (ws.getCell("D28").value === 28 ? 1 : 0) +
      (ws.getCell("E28").value === 28 ? 1 : 0) +
      (ws.getCell("F28").value === 28 ? 1 : 0) +
      (ws.getCell("G28").value === 28 ? 1 : 0);
    ws.getCell("M28").value =
      (Math.floor((ws.getCell("L28").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L29").value =
      (ws.getCell("C29").value === 28 ? 1 : 0) +
      (ws.getCell("D29").value === 28 ? 1 : 0) +
      (ws.getCell("E29").value === 28 ? 1 : 0) +
      (ws.getCell("F29").value === 28 ? 1 : 0) +
      (ws.getCell("G29").value === 28 ? 1 : 0);
    ws.getCell("M29").value =
      (Math.floor((ws.getCell("L29").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L30").value =
      (ws.getCell("C30").value === 28 ? 1 : 0) +
      (ws.getCell("D30").value === 28 ? 1 : 0) +
      (ws.getCell("E30").value === 28 ? 1 : 0) +
      (ws.getCell("F30").value === 28 ? 1 : 0) +
      (ws.getCell("G30").value === 28 ? 1 : 0);
    ws.getCell("M30").value =
      (Math.floor((ws.getCell("L30").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L31").value =
      (ws.getCell("C31").value === 28 ? 1 : 0) +
      (ws.getCell("D31").value === 28 ? 1 : 0) +
      (ws.getCell("E31").value === 28 ? 1 : 0) +
      (ws.getCell("F31").value === 28 ? 1 : 0) +
      (ws.getCell("G31").value === 28 ? 1 : 0);
    ws.getCell("M31").value =
      (Math.floor((ws.getCell("L31").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L32").value =
      (ws.getCell("C32").value === 28 ? 1 : 0) +
      (ws.getCell("D32").value === 28 ? 1 : 0) +
      (ws.getCell("E32").value === 28 ? 1 : 0) +
      (ws.getCell("F32").value === 28 ? 1 : 0) +
      (ws.getCell("G32").value === 28 ? 1 : 0);
    ws.getCell("M32").value =
      (Math.floor((ws.getCell("L32").value / weekTotal) * 100) || 0) + "%";

    // 4TH ROUTE
    ws.getCell("C37").value = getOnBoradCount(week_data1, "MFC > MT", "08:35");
    ws.getCell("C38").value = getOnBoradCount(week_data1, "MFC > MT", "09:05");
    ws.getCell("C39").value = getOnBoradCount(week_data1, "MFC > MT", "09:35");
    ws.getCell("C40").value =
      ws.getCell("C37").value +
      ws.getCell("C38").value +
      ws.getCell("C39").value;
    ws.getCell("C41").value = Math.floor(ws.getCell("C40").value / 3) || 0;

    ws.getCell("D37").value = getOnBoradCount(week_data2, "MFC > MT", "08:35");
    ws.getCell("D38").value = getOnBoradCount(week_data2, "MFC > MT", "09:05");
    ws.getCell("D39").value = getOnBoradCount(week_data2, "MFC > MT", "09:35");
    ws.getCell("D40").value =
      ws.getCell("D37").value +
      ws.getCell("D38").value +
      ws.getCell("D39").value;
    ws.getCell("D41").value = Math.floor(ws.getCell("D40").value / 3) || 0;

    ws.getCell("E37").value = getOnBoradCount(week_data3, "MFC > MT", "08:35");
    ws.getCell("E38").value = getOnBoradCount(week_data3, "MFC > MT", "09:05");
    ws.getCell("E39").value = getOnBoradCount(week_data3, "MFC > MT", "09:35");
    ws.getCell("E40").value =
      ws.getCell("E37").value +
      ws.getCell("E38").value +
      ws.getCell("E39").value;
    ws.getCell("E41").value = Math.floor(ws.getCell("E40").value / 3) || 0;

    ws.getCell("F37").value = getOnBoradCount(week_data4, "MFC > MT", "08:35");
    ws.getCell("F38").value = getOnBoradCount(week_data4, "MFC > MT", "09:05");
    ws.getCell("F39").value = getOnBoradCount(week_data4, "MFC > MT", "09:35");
    ws.getCell("F40").value =
      ws.getCell("F37").value +
      ws.getCell("F38").value +
      ws.getCell("F39").value;
    ws.getCell("F41").value = Math.floor(ws.getCell("F40").value / 3) || 0;

    ws.getCell("G37").value = getOnBoradCount(week_data5, "MFC > MT", "08:35");
    ws.getCell("G38").value = getOnBoradCount(week_data5, "MFC > MT", "09:05");
    ws.getCell("G39").value = getOnBoradCount(week_data5, "MFC > MT", "09:35");
    ws.getCell("G40").value =
      ws.getCell("G37").value +
      ws.getCell("G38").value +
      ws.getCell("G39").value;
    ws.getCell("G41").value = Math.floor(ws.getCell("G40").value / 3) || 0;

    ws.getCell("H37").value =
      ws.getCell("C37").value +
      ws.getCell("D37").value +
      ws.getCell("E37").value +
      ws.getCell("F37").value +
      ws.getCell("G37").value;

    ws.getCell("H38").value =
      ws.getCell("C38").value +
      ws.getCell("D38").value +
      ws.getCell("E38").value +
      ws.getCell("F38").value +
      ws.getCell("G38").value;

    ws.getCell("H39").value =
      ws.getCell("C39").value +
      ws.getCell("D39").value +
      ws.getCell("E39").value +
      ws.getCell("F39").value +
      ws.getCell("G39").value;

    ws.getCell("H40").value =
      ws.getCell("C40").value +
      ws.getCell("D40").value +
      ws.getCell("E40").value +
      ws.getCell("F40").value +
      ws.getCell("G40").value;

    ws.getCell("H41").value =
      ws.getCell("C41").value +
      ws.getCell("D41").value +
      ws.getCell("E41").value +
      ws.getCell("F41").value +
      ws.getCell("G41").value;

    ws.getCell("I37").value =
      Math.floor(ws.getCell("H37").value / weekTotal) || 0;
    ws.getCell("I38").value =
      Math.floor(ws.getCell("H38").value / weekTotal) || 0;
    ws.getCell("I39").value =
      Math.floor(ws.getCell("H39").value / weekTotal) || 0;
    ws.getCell("I40").value =
      Math.floor(ws.getCell("H40").value / weekTotal) || 0;
    ws.getCell("I41").value =
      Math.floor(ws.getCell("H41").value / weekTotal) || 0;

    ws.getCell("J37").value =
      (ws.getCell("C37").value > 22 ? 1 : 0) +
      (ws.getCell("D37").value > 22 ? 1 : 0) +
      (ws.getCell("E37").value > 22 ? 1 : 0) +
      (ws.getCell("F37").value > 22 ? 1 : 0) +
      (ws.getCell("G37").value > 22 ? 1 : 0);
    ws.getCell("K37").value =
      (Math.floor((ws.getCell("J37").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J38").value =
      (ws.getCell("C38").value > 22 ? 1 : 0) +
      (ws.getCell("D38").value > 22 ? 1 : 0) +
      (ws.getCell("E38").value > 22 ? 1 : 0) +
      (ws.getCell("F38").value > 22 ? 1 : 0) +
      (ws.getCell("G38").value > 22 ? 1 : 0);
    ws.getCell("K38").value =
      (Math.floor((ws.getCell("J38").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J39").value =
      (ws.getCell("C39").value > 22 ? 1 : 0) +
      (ws.getCell("D39").value > 22 ? 1 : 0) +
      (ws.getCell("E39").value > 22 ? 1 : 0) +
      (ws.getCell("F39").value > 22 ? 1 : 0) +
      (ws.getCell("G39").value > 22 ? 1 : 0);
    ws.getCell("K39").value =
      (Math.floor((ws.getCell("J39").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J40").value =
      (ws.getCell("C40").value > 22 ? 1 : 0) +
      (ws.getCell("D40").value > 22 ? 1 : 0) +
      (ws.getCell("E40").value > 22 ? 1 : 0) +
      (ws.getCell("F40").value > 22 ? 1 : 0) +
      (ws.getCell("G40").value > 22 ? 1 : 0);
    ws.getCell("K40").value =
      (Math.floor((ws.getCell("J40").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("J41").value =
      (ws.getCell("C41").value > 22 ? 1 : 0) +
      (ws.getCell("D41").value > 22 ? 1 : 0) +
      (ws.getCell("E41").value > 22 ? 1 : 0) +
      (ws.getCell("F41").value > 22 ? 1 : 0) +
      (ws.getCell("G41").value > 22 ? 1 : 0);
    ws.getCell("K41").value =
      (Math.floor((ws.getCell("J41").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L37").value =
      (ws.getCell("C37").value === 28 ? 1 : 0) +
      (ws.getCell("D37").value === 28 ? 1 : 0) +
      (ws.getCell("E37").value === 28 ? 1 : 0) +
      (ws.getCell("F37").value === 28 ? 1 : 0) +
      (ws.getCell("G37").value === 28 ? 1 : 0);
    ws.getCell("M37").value =
      (Math.floor((ws.getCell("L37").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L38").value =
      (ws.getCell("C38").value === 28 ? 1 : 0) +
      (ws.getCell("D38").value === 28 ? 1 : 0) +
      (ws.getCell("E38").value === 28 ? 1 : 0) +
      (ws.getCell("F38").value === 28 ? 1 : 0) +
      (ws.getCell("G38").value === 28 ? 1 : 0);
    ws.getCell("M38").value =
      (Math.floor((ws.getCell("L38").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L39").value =
      (ws.getCell("C39").value === 28 ? 1 : 0) +
      (ws.getCell("D39").value === 28 ? 1 : 0) +
      (ws.getCell("E39").value === 28 ? 1 : 0) +
      (ws.getCell("F39").value === 28 ? 1 : 0) +
      (ws.getCell("G39").value === 28 ? 1 : 0);
    ws.getCell("M39").value =
      (Math.floor((ws.getCell("L39").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L40").value =
      (ws.getCell("C40").value === 28 ? 1 : 0) +
      (ws.getCell("D40").value === 28 ? 1 : 0) +
      (ws.getCell("E40").value === 28 ? 1 : 0) +
      (ws.getCell("F40").value === 28 ? 1 : 0) +
      (ws.getCell("G40").value === 28 ? 1 : 0);
    ws.getCell("M40").value =
      (Math.floor((ws.getCell("L40").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L41").value =
      (ws.getCell("C41").value === 28 ? 1 : 0) +
      (ws.getCell("D41").value === 28 ? 1 : 0) +
      (ws.getCell("E41").value === 28 ? 1 : 0) +
      (ws.getCell("F41").value === 28 ? 1 : 0) +
      (ws.getCell("G41").value === 28 ? 1 : 0);
    ws.getCell("M41").value =
      (Math.floor((ws.getCell("L41").value / weekTotal) * 100) || 0) + "%";

    // CALCULATION
    ws.getCell("C47").value =
      (ws.getCell("C11").value || 0) +
      (ws.getCell("C21").value || 0) +
      (ws.getCell("C31").value || 0) +
      (ws.getCell("C40").value || 0);
    ws.getCell("C48").value =
      Math.floor((ws.getCell("C47").value || 0) / 16) || 0;

    ws.getCell("D47").value =
      (ws.getCell("D11").value || 0) + (ws.getCell("D21").value || 0);
    ws.getCell("D48").value =
      Math.floor((ws.getCell("D47").value || 0) / 8) || 0;

    ws.getCell("E47").value =
      (ws.getCell("E11").value || 0) + (ws.getCell("E21").value || 0);
    ws.getCell("E48").value =
      Math.floor((ws.getCell("E47").value || 0) / 8) || 0;

    ws.getCell("F47").value =
      (ws.getCell("F11").value || 0) + (ws.getCell("F21").value || 0);
    ws.getCell("F48").value =
      Math.floor((ws.getCell("F47").value || 0) / 8) || 0;

    ws.getCell("G47").value =
      (ws.getCell("G11").value || 0) +
      (ws.getCell("G21").value || 0) +
      (ws.getCell("D31").value || 0) +
      (ws.getCell("D40").value || 0);
    ws.getCell("G48").value =
      Math.floor((ws.getCell("G47").value || 0) / 16) || 0;

    ws.getCell("H47").value =
      (ws.getCell("H11").value || 0) + (ws.getCell("H21").value || 0);
    ws.getCell("H48").value =
      Math.floor((ws.getCell("H47").value || 0) / 8) || 0;

    ws.getCell("I47").value =
      (ws.getCell("I11").value || 0) + (ws.getCell("I21").value || 0);
    ws.getCell("I48").value =
      Math.floor((ws.getCell("I47").value || 0) / 8) || 0;

    ws.getCell("J47").value =
      (ws.getCell("J11").value || 0) + (ws.getCell("J21").value || 0);
    ws.getCell("J48").value =
      Math.floor((ws.getCell("J47").value || 0) / 8) || 0;

    ws.getCell("K47").value =
      (ws.getCell("K11").value || 0) +
      (ws.getCell("K21").value || 0) +
      (ws.getCell("E31").value || 0) +
      (ws.getCell("E40").value || 0);
    ws.getCell("K48").value =
      Math.floor((ws.getCell("K47").value || 0) / 16) || 0;

    ws.getCell("L47").value =
      (ws.getCell("L11").value || 0) + (ws.getCell("L21").value || 0);
    ws.getCell("L48").value =
      Math.floor((ws.getCell("L47").value || 0) / 8) || 0;

    ws.getCell("M47").value =
      (ws.getCell("M11").value || 0) + (ws.getCell("M21").value || 0);
    ws.getCell("M48").value =
      Math.floor((ws.getCell("M47").value || 0) / 8) || 0;

    ws.getCell("N47").value =
      (ws.getCell("N11").value || 0) + (ws.getCell("N21").value || 0);
    ws.getCell("N48").value =
      Math.floor((ws.getCell("N47").value || 0) / 8) || 0;

    ws.getCell("O47").value =
      (ws.getCell("O11").value || 0) +
      (ws.getCell("O21").value || 0) +
      (ws.getCell("F31").value || 0) +
      (ws.getCell("F40").value || 0);
    ws.getCell("O48").value =
      Math.floor((ws.getCell("O47").value || 0) / 16) || 0;

    ws.getCell("P47").value =
      (ws.getCell("P11").value || 0) + (ws.getCell("P21").value || 0);
    ws.getCell("P48").value =
      Math.floor((ws.getCell("P47").value || 0) / 8) || 0;

    ws.getCell("Q47").value =
      (ws.getCell("Q11").value || 0) + (ws.getCell("Q21").value || 0);
    ws.getCell("Q48").value =
      Math.floor((ws.getCell("Q47").value || 0) / 8) || 0;

    ws.getCell("R47").value =
      (ws.getCell("R11").value || 0) + (ws.getCell("R21").value || 0);
    ws.getCell("R48").value =
      Math.floor((ws.getCell("R47").value || 0) / 8) || 0;

    ws.getCell("S47").value =
      (ws.getCell("S11").value || 0) +
      (ws.getCell("S21").value || 0) +
      (ws.getCell("G31").value || 0) +
      (ws.getCell("G40").value || 0);
    ws.getCell("S48").value =
      Math.floor((ws.getCell("S47").value || 0) / 16) || 0;

    ws.getCell("T47").value =
      (ws.getCell("T11").value || 0) + (ws.getCell("T21").value || 0);
    ws.getCell("T48").value =
      Math.floor((ws.getCell("T47").value || 0) / 8) || 0;

    ws.getCell("U47").value =
      (ws.getCell("U11").value || 0) + (ws.getCell("U21").value || 0);
    ws.getCell("U48").value =
      Math.floor((ws.getCell("U47").value || 0) / 8) || 0;

    ws.getCell("V47").value =
      (ws.getCell("V11").value || 0) + (ws.getCell("V21").value || 0);
    ws.getCell("V48").value =
      Math.floor((ws.getCell("V47").value || 0) / 8) || 0;

    ws.getCell("W47").value =
      (ws.getCell("C47").value || 0) +
      (ws.getCell("G47").value || 0) +
      (ws.getCell("K47").value || 0) +
      (ws.getCell("O47").value || 0) +
      (ws.getCell("S47").value || 0);
    ws.getCell("W48").value =
      (ws.getCell("C48").value || 0) +
      (ws.getCell("G48").value || 0) +
      (ws.getCell("K48").value || 0) +
      (ws.getCell("O48").value || 0) +
      (ws.getCell("S48").value || 0);

    ws.getCell("X47").value =
      Math.floor(ws.getCell("W47").value / weekTotal) || 0;
    ws.getCell("X48").value =
      Math.floor(ws.getCell("W48").value / weekTotal) || 0;

    ws.getCell("AC47").value =
      (ws.getCell("F47").value || 0) +
      (ws.getCell("J47").value || 0) +
      (ws.getCell("N47").value || 0) +
      (ws.getCell("R47").value || 0) +
      (ws.getCell("V47").value || 0);
    ws.getCell("AC48").value =
      (ws.getCell("F48").value || 0) +
      (ws.getCell("J48").value || 0) +
      (ws.getCell("N48").value || 0) +
      (ws.getCell("R48").value || 0) +
      (ws.getCell("V48").value || 0);

    ws.getCell("AD47").value =
      Math.floor(ws.getCell("AC47").value / weekTotal) || 0;
    ws.getCell("AD48").value =
      Math.floor(ws.getCell("AC48").value / weekTotal) || 0;

    // ROUTE 5
    ws.getCell("C54").value =
      getOnBoradCount(week_data1, "MT > MFC", "10:00") || 0;
    ws.getCell("C55").value =
      getOnBoradCount(week_data1, "MT > MFC", "10:30") || 0;
    ws.getCell("C56").value =
      getOnBoradCount(week_data1, "MT > MFC", "11:00") || 0;
    ws.getCell("C57").value =
      getOnBoradCount(week_data1, "MT > MFC", "11:30") || 0;
    ws.getCell("C58").value =
      getOnBoradCount(week_data1, "MT > MFC", "12:30") || 0;
    ws.getCell("C59").value =
      getOnBoradCount(week_data1, "MT > MFC", "14:30") || 0;
    ws.getCell("C60").value =
      getOnBoradCount(week_data1, "MT > MFC", "15:00") || 0;
    ws.getCell("C61").value =
      getOnBoradCount(week_data1, "MT > MFC", "15:30") || 0;
    ws.getCell("C62").value =
      getOnBoradCount(week_data1, "MT > MFC", "16:00") || 0;
    ws.getCell("C63").value =
      getOnBoradCount(week_data1, "MT > MFC", "16:30") || 0;
    ws.getCell("C64").value =
      getOnBoradCount(week_data1, "MT > MFC", "17:00") || 0;
    ws.getCell("C65").value =
      getOnBoradCount(week_data1, "MT > MFC", "17:30") || 0;
    ws.getCell("C66").value =
      getOnBoradCount(week_data1, "MT > MFC", "18:00") || 0;
    ws.getCell("C67").value =
      ws.getCell("C54").value +
      ws.getCell("C55").value +
      ws.getCell("C56").value +
      ws.getCell("C57").value +
      ws.getCell("C58").value +
      ws.getCell("C59").value +
      ws.getCell("C60").value +
      ws.getCell("C61").value +
      ws.getCell("C62").value +
      ws.getCell("C63").value +
      ws.getCell("C64").value +
      ws.getCell("C65").value +
      ws.getCell("C66").value;
    ws.getCell("C68").value = Math.floor(ws.getCell("C67").value / 15) || 0;

    ws.getCell("D54").value =
      getOffBoradCount(week_data1, "MT > MFC", "10:00") || 0;
    ws.getCell("D55").value =
      getOffBoradCount(week_data1, "MT > MFC", "10:30") || 0;
    ws.getCell("D56").value =
      getOffBoradCount(week_data1, "MT > MFC", "11:00") || 0;
    ws.getCell("D57").value =
      getOffBoradCount(week_data1, "MT > MFC", "11:30") || 0;
    ws.getCell("D58").value =
      getOffBoradCount(week_data1, "MT > MFC", "12:30") || 0;
    ws.getCell("D59").value =
      getOffBoradCount(week_data1, "MT > MFC", "14:30") || 0;
    ws.getCell("D60").value =
      getOffBoradCount(week_data1, "MT > MFC", "15:00") || 0;
    ws.getCell("D61").value =
      getOffBoradCount(week_data1, "MT > MFC", "15:30") || 0;
    ws.getCell("D62").value =
      getOffBoradCount(week_data1, "MT > MFC", "16:00") || 0;
    ws.getCell("D63").value =
      getOffBoradCount(week_data1, "MT > MFC", "16:30") || 0;
    ws.getCell("D64").value =
      getOffBoradCount(week_data1, "MT > MFC", "17:00") || 0;
    ws.getCell("D65").value =
      getOffBoradCount(week_data1, "MT > MFC", "17:30") || 0;
    ws.getCell("D66").value =
      getOffBoradCount(week_data1, "MT > MFC", "18:00") || 0;
    ws.getCell("D67").value =
      ws.getCell("D54").value +
      ws.getCell("D55").value +
      ws.getCell("D56").value +
      ws.getCell("D57").value +
      ws.getCell("D58").value +
      ws.getCell("D59").value +
      ws.getCell("D60").value +
      ws.getCell("D61").value +
      ws.getCell("D62").value +
      ws.getCell("D63").value +
      ws.getCell("D64").value +
      ws.getCell("D65").value +
      ws.getCell("D66").value;
    ws.getCell("D68").value = Math.floor(ws.getCell("D67").value / 15) || 0;

    ws.getCell("E54").value =
      getOnBoradCount(week_data1, "MFC > LG", "10:10") || 0;
    ws.getCell("E55").value =
      getOnBoradCount(week_data1, "MFC > LG", "10:40") || 0;
    ws.getCell("E56").value =
      getOnBoradCount(week_data1, "MFC > LG", "11:10") || 0;
    ws.getCell("E57").value =
      getOnBoradCount(week_data1, "MFC > LG", "11:40") || 0;
    ws.getCell("E58").value =
      getOnBoradCount(week_data1, "MFC > LG", "12:40") || 0;
    ws.getCell("E59").value =
      getOnBoradCount(week_data1, "MFC > LG", "14:40") || 0;
    ws.getCell("E60").value =
      getOnBoradCount(week_data1, "MFC > LG", "15:10") || 0;
    ws.getCell("E61").value =
      getOnBoradCount(week_data1, "MFC > LG", "15:40") || 0;
    ws.getCell("E62").value =
      getOnBoradCount(week_data1, "MFC > LG", "16:10") || 0;
    ws.getCell("E63").value =
      getOnBoradCount(week_data1, "MFC > LG", "16:40") || 0;
    ws.getCell("E64").value =
      getOnBoradCount(week_data1, "MFC > LG", "17:10") || 0;
    ws.getCell("E65").value =
      getOnBoradCount(week_data1, "MFC > LG", "17:40") || 0;
    ws.getCell("E66").value =
      getOnBoradCount(week_data1, "MFC > LG", "18:10") || 0;
    ws.getCell("E67").value =
      ws.getCell("E54").value +
      ws.getCell("E55").value +
      ws.getCell("E56").value +
      ws.getCell("E57").value +
      ws.getCell("E58").value +
      ws.getCell("E59").value +
      ws.getCell("E60").value +
      ws.getCell("E61").value +
      ws.getCell("E62").value +
      ws.getCell("E63").value +
      ws.getCell("E64").value +
      ws.getCell("E65").value +
      ws.getCell("E66").value;
    ws.getCell("E68").value = Math.floor(ws.getCell("E67").value / 15) || 0;

    ws.getCell("F54").value =
      ws.getCell("C54").value -
      ws.getCell("D54").value +
      ws.getCell("E54").value;
    ws.getCell("F55").value =
      ws.getCell("C55").value -
      ws.getCell("D55").value +
      ws.getCell("E55").value;
    ws.getCell("F56").value =
      ws.getCell("C56").value -
      ws.getCell("D56").value +
      ws.getCell("E56").value;
    ws.getCell("F57").value =
      ws.getCell("C57").value -
      ws.getCell("D57").value +
      ws.getCell("E57").value;
    ws.getCell("F58").value =
      ws.getCell("C58").value -
      ws.getCell("D58").value +
      ws.getCell("E58").value;
    ws.getCell("F59").value =
      ws.getCell("C59").value -
      ws.getCell("D59").value +
      ws.getCell("E59").value;
    ws.getCell("F60").value =
      ws.getCell("C60").value -
      ws.getCell("D60").value +
      ws.getCell("E60").value;
    ws.getCell("F61").value =
      ws.getCell("C61").value -
      ws.getCell("D61").value +
      ws.getCell("E61").value;
    ws.getCell("F62").value =
      ws.getCell("C62").value -
      ws.getCell("D62").value +
      ws.getCell("E62").value;
    ws.getCell("F63").value =
      ws.getCell("C63").value -
      ws.getCell("D63").value +
      ws.getCell("E63").value;
    ws.getCell("F64").value =
      ws.getCell("C64").value -
      ws.getCell("D64").value +
      ws.getCell("E64").value;
    ws.getCell("F65").value =
      ws.getCell("C65").value -
      ws.getCell("D65").value +
      ws.getCell("E65").value;
    ws.getCell("F66").value =
      ws.getCell("C66").value -
      ws.getCell("D66").value +
      ws.getCell("E66").value;
    ws.getCell("F67").value =
      ws.getCell("F54").value +
      ws.getCell("F55").value +
      ws.getCell("F56").value +
      ws.getCell("F57").value +
      ws.getCell("F58").value +
      ws.getCell("F59").value +
      ws.getCell("F60").value +
      ws.getCell("F61").value +
      ws.getCell("F62").value +
      ws.getCell("F63").value +
      ws.getCell("F64").value +
      ws.getCell("F65").value +
      ws.getCell("F66").value;
    ws.getCell("F68").value = Math.floor(ws.getCell("F67").value / 15) || 0;

    ws.getCell("G54").value =
      getOnBoradCount(week_data2, "MT > MFC", "10:00") || 0;
    ws.getCell("G55").value =
      getOnBoradCount(week_data2, "MT > MFC", "10:30") || 0;
    ws.getCell("G56").value =
      getOnBoradCount(week_data2, "MT > MFC", "11:00") || 0;
    ws.getCell("G57").value =
      getOnBoradCount(week_data2, "MT > MFC", "11:30") || 0;
    ws.getCell("G58").value =
      getOnBoradCount(week_data2, "MT > MFC", "12:30") || 0;
    ws.getCell("G59").value =
      getOnBoradCount(week_data2, "MT > MFC", "14:30") || 0;
    ws.getCell("G60").value =
      getOnBoradCount(week_data2, "MT > MFC", "15:00") || 0;
    ws.getCell("G61").value =
      getOnBoradCount(week_data2, "MT > MFC", "15:30") || 0;
    ws.getCell("G62").value =
      getOnBoradCount(week_data2, "MT > MFC", "16:00") || 0;
    ws.getCell("G63").value =
      getOnBoradCount(week_data2, "MT > MFC", "16:30") || 0;
    ws.getCell("G64").value =
      getOnBoradCount(week_data2, "MT > MFC", "17:00") || 0;
    ws.getCell("G65").value =
      getOnBoradCount(week_data2, "MT > MFC", "17:30") || 0;
    ws.getCell("G66").value =
      getOnBoradCount(week_data2, "MT > MFC", "18:00") || 0;
    ws.getCell("G67").value =
      ws.getCell("G54").value +
      ws.getCell("G55").value +
      ws.getCell("G56").value +
      ws.getCell("G57").value +
      ws.getCell("G58").value +
      ws.getCell("G59").value +
      ws.getCell("G60").value +
      ws.getCell("G61").value +
      ws.getCell("G62").value +
      ws.getCell("G63").value +
      ws.getCell("G64").value +
      ws.getCell("G65").value +
      ws.getCell("G66").value;
    ws.getCell("G68").value = Math.floor(ws.getCell("G67").value / 15) || 0;

    ws.getCell("H54").value =
      getOffBoradCount(week_data2, "MT > MFC", "10:00") || 0;
    ws.getCell("H55").value =
      getOffBoradCount(week_data2, "MT > MFC", "10:30") || 0;
    ws.getCell("H56").value =
      getOffBoradCount(week_data2, "MT > MFC", "11:00") || 0;
    ws.getCell("H57").value =
      getOffBoradCount(week_data2, "MT > MFC", "11:30") || 0;
    ws.getCell("H58").value =
      getOffBoradCount(week_data2, "MT > MFC", "12:30") || 0;
    ws.getCell("H59").value =
      getOffBoradCount(week_data2, "MT > MFC", "14:30") || 0;
    ws.getCell("H60").value =
      getOffBoradCount(week_data2, "MT > MFC", "15:00") || 0;
    ws.getCell("H61").value =
      getOffBoradCount(week_data2, "MT > MFC", "15:30") || 0;
    ws.getCell("H62").value =
      getOffBoradCount(week_data2, "MT > MFC", "16:00") || 0;
    ws.getCell("H63").value =
      getOffBoradCount(week_data2, "MT > MFC", "16:30") || 0;
    ws.getCell("H64").value =
      getOffBoradCount(week_data2, "MT > MFC", "17:00") || 0;
    ws.getCell("H65").value =
      getOffBoradCount(week_data2, "MT > MFC", "17:30") || 0;
    ws.getCell("H66").value =
      getOffBoradCount(week_data2, "MT > MFC", "18:00") || 0;
    ws.getCell("H67").value =
      ws.getCell("H54").value +
      ws.getCell("H55").value +
      ws.getCell("H56").value +
      ws.getCell("H57").value +
      ws.getCell("H58").value +
      ws.getCell("H59").value +
      ws.getCell("H60").value +
      ws.getCell("H61").value +
      ws.getCell("H62").value +
      ws.getCell("H63").value +
      ws.getCell("H64").value +
      ws.getCell("H65").value +
      ws.getCell("H66").value;
    ws.getCell("H68").value = Math.floor(ws.getCell("H67").value / 15) || 0;

    ws.getCell("I54").value =
      getOnBoradCount(week_data2, "MFC > LG", "10:10") || 0;
    ws.getCell("I55").value =
      getOnBoradCount(week_data2, "MFC > LG", "10:40") || 0;
    ws.getCell("I56").value =
      getOnBoradCount(week_data2, "MFC > LG", "11:10") || 0;
    ws.getCell("I57").value =
      getOnBoradCount(week_data2, "MFC > LG", "11:40") || 0;
    ws.getCell("I58").value =
      getOnBoradCount(week_data2, "MFC > LG", "12:40") || 0;
    ws.getCell("I59").value =
      getOnBoradCount(week_data2, "MFC > LG", "14:40") || 0;
    ws.getCell("I60").value =
      getOnBoradCount(week_data2, "MFC > LG", "15:10") || 0;
    ws.getCell("I61").value =
      getOnBoradCount(week_data2, "MFC > LG", "15:40") || 0;
    ws.getCell("I62").value =
      getOnBoradCount(week_data2, "MFC > LG", "16:10") || 0;
    ws.getCell("I63").value =
      getOnBoradCount(week_data2, "MFC > LG", "16:40") || 0;
    ws.getCell("I64").value =
      getOnBoradCount(week_data2, "MFC > LG", "17:10") || 0;
    ws.getCell("I65").value =
      getOnBoradCount(week_data2, "MFC > LG", "17:40") || 0;
    ws.getCell("I66").value =
      getOnBoradCount(week_data2, "MFC > LG", "18:10") || 0;
    ws.getCell("I67").value =
      ws.getCell("I54").value +
      ws.getCell("I55").value +
      ws.getCell("I56").value +
      ws.getCell("I57").value +
      ws.getCell("I58").value +
      ws.getCell("I59").value +
      ws.getCell("I60").value +
      ws.getCell("I61").value +
      ws.getCell("I62").value +
      ws.getCell("I63").value +
      ws.getCell("I64").value +
      ws.getCell("I65").value +
      ws.getCell("I66").value;
    ws.getCell("I68").value = Math.floor(ws.getCell("I67").value / 15) || 0;

    ws.getCell("J54").value =
      ws.getCell("G54").value -
      ws.getCell("H54").value +
      ws.getCell("I54").value;
    ws.getCell("J55").value =
      ws.getCell("G55").value -
      ws.getCell("H55").value +
      ws.getCell("I55").value;
    ws.getCell("J56").value =
      ws.getCell("G56").value -
      ws.getCell("H56").value +
      ws.getCell("I56").value;
    ws.getCell("J57").value =
      ws.getCell("G57").value -
      ws.getCell("H57").value +
      ws.getCell("I57").value;
    ws.getCell("J58").value =
      ws.getCell("G58").value -
      ws.getCell("H58").value +
      ws.getCell("I58").value;
    ws.getCell("J59").value =
      ws.getCell("G59").value -
      ws.getCell("H59").value +
      ws.getCell("I59").value;
    ws.getCell("J60").value =
      ws.getCell("G60").value -
      ws.getCell("H60").value +
      ws.getCell("I60").value;
    ws.getCell("J61").value =
      ws.getCell("G61").value -
      ws.getCell("H61").value +
      ws.getCell("I61").value;
    ws.getCell("J62").value =
      ws.getCell("G62").value -
      ws.getCell("H62").value +
      ws.getCell("I62").value;
    ws.getCell("J63").value =
      ws.getCell("G63").value -
      ws.getCell("H63").value +
      ws.getCell("I63").value;
    ws.getCell("J64").value =
      ws.getCell("G64").value -
      ws.getCell("H64").value +
      ws.getCell("I64").value;
    ws.getCell("J65").value =
      ws.getCell("G65").value -
      ws.getCell("H65").value +
      ws.getCell("I65").value;
    ws.getCell("J66").value =
      ws.getCell("G66").value -
      ws.getCell("H66").value +
      ws.getCell("I66").value;
    ws.getCell("J67").value =
      ws.getCell("J54").value +
      ws.getCell("J55").value +
      ws.getCell("J56").value +
      ws.getCell("J57").value +
      ws.getCell("J58").value +
      ws.getCell("J59").value +
      ws.getCell("J60").value +
      ws.getCell("J61").value +
      ws.getCell("J62").value +
      ws.getCell("J63").value +
      ws.getCell("J64").value +
      ws.getCell("J65").value +
      ws.getCell("J66").value;
    ws.getCell("J68").value = Math.floor(ws.getCell("J67").value / 15) || 0;

    ws.getCell("K54").value =
      getOnBoradCount(week_data3, "MT > MFC", "10:00") || 0;
    ws.getCell("K55").value =
      getOnBoradCount(week_data3, "MT > MFC", "10:30") || 0;
    ws.getCell("K56").value =
      getOnBoradCount(week_data3, "MT > MFC", "11:00") || 0;
    ws.getCell("K57").value =
      getOnBoradCount(week_data3, "MT > MFC", "11:30") || 0;
    ws.getCell("K58").value =
      getOnBoradCount(week_data3, "MT > MFC", "12:30") || 0;
    ws.getCell("K59").value =
      getOnBoradCount(week_data3, "MT > MFC", "14:30") || 0;
    ws.getCell("K60").value =
      getOnBoradCount(week_data3, "MT > MFC", "15:00") || 0;
    ws.getCell("K61").value =
      getOnBoradCount(week_data3, "MT > MFC", "15:30") || 0;
    ws.getCell("K62").value =
      getOnBoradCount(week_data3, "MT > MFC", "16:00") || 0;
    ws.getCell("K63").value =
      getOnBoradCount(week_data3, "MT > MFC", "16:30") || 0;
    ws.getCell("K64").value =
      getOnBoradCount(week_data3, "MT > MFC", "17:00") || 0;
    ws.getCell("K65").value =
      getOnBoradCount(week_data3, "MT > MFC", "17:30") || 0;
    ws.getCell("K66").value =
      getOnBoradCount(week_data3, "MT > MFC", "18:00") || 0;
    ws.getCell("K67").value =
      ws.getCell("K54").value +
      ws.getCell("K55").value +
      ws.getCell("K56").value +
      ws.getCell("K57").value +
      ws.getCell("K58").value +
      ws.getCell("K59").value +
      ws.getCell("K60").value +
      ws.getCell("K61").value +
      ws.getCell("K62").value +
      ws.getCell("K63").value +
      ws.getCell("K64").value +
      ws.getCell("K65").value +
      ws.getCell("K66").value;
    ws.getCell("K68").value = Math.floor(ws.getCell("K67").value / 15) || 0;

    ws.getCell("L54").value =
      getOffBoradCount(week_data3, "MT > MFC", "10:00") || 0;
    ws.getCell("L55").value =
      getOffBoradCount(week_data3, "MT > MFC", "10:30") || 0;
    ws.getCell("L56").value =
      getOffBoradCount(week_data3, "MT > MFC", "11:00") || 0;
    ws.getCell("L57").value =
      getOffBoradCount(week_data3, "MT > MFC", "11:30") || 0;
    ws.getCell("L58").value =
      getOffBoradCount(week_data3, "MT > MFC", "12:30") || 0;
    ws.getCell("L59").value =
      getOffBoradCount(week_data3, "MT > MFC", "14:30") || 0;
    ws.getCell("L60").value =
      getOffBoradCount(week_data3, "MT > MFC", "15:00") || 0;
    ws.getCell("L61").value =
      getOffBoradCount(week_data3, "MT > MFC", "15:30") || 0;
    ws.getCell("L62").value =
      getOffBoradCount(week_data3, "MT > MFC", "16:00") || 0;
    ws.getCell("L63").value =
      getOffBoradCount(week_data3, "MT > MFC", "16:30") || 0;
    ws.getCell("L64").value =
      getOffBoradCount(week_data3, "MT > MFC", "17:00") || 0;
    ws.getCell("L65").value =
      getOffBoradCount(week_data3, "MT > MFC", "17:30") || 0;
    ws.getCell("L66").value =
      getOffBoradCount(week_data3, "MT > MFC", "18:00") || 0;
    ws.getCell("L67").value =
      ws.getCell("L54").value +
      ws.getCell("L55").value +
      ws.getCell("L56").value +
      ws.getCell("L57").value +
      ws.getCell("L58").value +
      ws.getCell("L59").value +
      ws.getCell("L60").value +
      ws.getCell("L61").value +
      ws.getCell("L62").value +
      ws.getCell("L63").value +
      ws.getCell("L64").value +
      ws.getCell("L65").value +
      ws.getCell("L66").value;
    ws.getCell("L68").value = Math.floor(ws.getCell("L67").value / 15) || 0;

    ws.getCell("M54").value =
      getOnBoradCount(week_data3, "MFC > LG", "10:10") || 0;
    ws.getCell("M55").value =
      getOnBoradCount(week_data3, "MFC > LG", "10:40") || 0;
    ws.getCell("M56").value =
      getOnBoradCount(week_data3, "MFC > LG", "11:10") || 0;
    ws.getCell("M57").value =
      getOnBoradCount(week_data3, "MFC > LG", "11:40") || 0;
    ws.getCell("M58").value =
      getOnBoradCount(week_data3, "MFC > LG", "12:40") || 0;
    ws.getCell("M59").value =
      getOnBoradCount(week_data3, "MFC > LG", "14:40") || 0;
    ws.getCell("M60").value =
      getOnBoradCount(week_data3, "MFC > LG", "15:10") || 0;
    ws.getCell("M61").value =
      getOnBoradCount(week_data3, "MFC > LG", "15:40") || 0;
    ws.getCell("M62").value =
      getOnBoradCount(week_data3, "MFC > LG", "16:10") || 0;
    ws.getCell("M63").value =
      getOnBoradCount(week_data3, "MFC > LG", "16:40") || 0;
    ws.getCell("M64").value =
      getOnBoradCount(week_data3, "MFC > LG", "17:10") || 0;
    ws.getCell("M65").value =
      getOnBoradCount(week_data3, "MFC > LG", "17:40") || 0;
    ws.getCell("M66").value =
      getOnBoradCount(week_data3, "MFC > LG", "18:10") || 0;
    ws.getCell("M67").value =
      ws.getCell("M54").value +
      ws.getCell("M55").value +
      ws.getCell("M56").value +
      ws.getCell("M57").value +
      ws.getCell("M58").value +
      ws.getCell("M59").value +
      ws.getCell("M60").value +
      ws.getCell("M61").value +
      ws.getCell("M62").value +
      ws.getCell("M63").value +
      ws.getCell("M64").value +
      ws.getCell("M65").value +
      ws.getCell("M66").value;
    ws.getCell("M68").value = Math.floor(ws.getCell("M67").value / 15) || 0;

    ws.getCell("N54").value =
      ws.getCell("K54").value -
      ws.getCell("L54").value +
      ws.getCell("M54").value;
    ws.getCell("N55").value =
      ws.getCell("K55").value -
      ws.getCell("L55").value +
      ws.getCell("M55").value;
    ws.getCell("N56").value =
      ws.getCell("K56").value -
      ws.getCell("L56").value +
      ws.getCell("M56").value;
    ws.getCell("N57").value =
      ws.getCell("K57").value -
      ws.getCell("L57").value +
      ws.getCell("M57").value;
    ws.getCell("N58").value =
      ws.getCell("K58").value -
      ws.getCell("L58").value +
      ws.getCell("M58").value;
    ws.getCell("N59").value =
      ws.getCell("K59").value -
      ws.getCell("L59").value +
      ws.getCell("M59").value;
    ws.getCell("N60").value =
      ws.getCell("K60").value -
      ws.getCell("L60").value +
      ws.getCell("M60").value;
    ws.getCell("N61").value =
      ws.getCell("K61").value -
      ws.getCell("L61").value +
      ws.getCell("M61").value;
    ws.getCell("N62").value =
      ws.getCell("K62").value -
      ws.getCell("L62").value +
      ws.getCell("M62").value;
    ws.getCell("N63").value =
      ws.getCell("K63").value -
      ws.getCell("L63").value +
      ws.getCell("M63").value;
    ws.getCell("N64").value =
      ws.getCell("K64").value -
      ws.getCell("L64").value +
      ws.getCell("M64").value;
    ws.getCell("N65").value =
      ws.getCell("K65").value -
      ws.getCell("L65").value +
      ws.getCell("M65").value;
    ws.getCell("N66").value =
      ws.getCell("K66").value -
      ws.getCell("L66").value +
      ws.getCell("M66").value;
    ws.getCell("N67").value =
      ws.getCell("N54").value +
      ws.getCell("N55").value +
      ws.getCell("N56").value +
      ws.getCell("N57").value +
      ws.getCell("N58").value +
      ws.getCell("N59").value +
      ws.getCell("N60").value +
      ws.getCell("N61").value +
      ws.getCell("N62").value +
      ws.getCell("N63").value +
      ws.getCell("N64").value +
      ws.getCell("N65").value +
      ws.getCell("N66").value;
    ws.getCell("N68").value = Math.floor(ws.getCell("N67").value / 15) || 0;

    ws.getCell("O54").value =
      getOnBoradCount(week_data4, "MT > MFC", "10:00") || 0;
    ws.getCell("O55").value =
      getOnBoradCount(week_data4, "MT > MFC", "10:30") || 0;
    ws.getCell("O56").value =
      getOnBoradCount(week_data4, "MT > MFC", "11:00") || 0;
    ws.getCell("O57").value =
      getOnBoradCount(week_data4, "MT > MFC", "11:30") || 0;
    ws.getCell("O58").value =
      getOnBoradCount(week_data4, "MT > MFC", "12:30") || 0;
    ws.getCell("O59").value =
      getOnBoradCount(week_data4, "MT > MFC", "14:30") || 0;
    ws.getCell("O60").value =
      getOnBoradCount(week_data4, "MT > MFC", "15:00") || 0;
    ws.getCell("O61").value =
      getOnBoradCount(week_data4, "MT > MFC", "15:30") || 0;
    ws.getCell("O62").value =
      getOnBoradCount(week_data4, "MT > MFC", "16:00") || 0;
    ws.getCell("O63").value =
      getOnBoradCount(week_data4, "MT > MFC", "16:30") || 0;
    ws.getCell("O64").value =
      getOnBoradCount(week_data4, "MT > MFC", "17:00") || 0;
    ws.getCell("O65").value =
      getOnBoradCount(week_data4, "MT > MFC", "17:30") || 0;
    ws.getCell("O66").value =
      getOnBoradCount(week_data4, "MT > MFC", "18:00") || 0;
    ws.getCell("O67").value =
      ws.getCell("O54").value +
      ws.getCell("O55").value +
      ws.getCell("O56").value +
      ws.getCell("O57").value +
      ws.getCell("O58").value +
      ws.getCell("O59").value +
      ws.getCell("O60").value +
      ws.getCell("O61").value +
      ws.getCell("O62").value +
      ws.getCell("O63").value +
      ws.getCell("O64").value +
      ws.getCell("O65").value +
      ws.getCell("O66").value;
    ws.getCell("O68").value = Math.floor(ws.getCell("O67").value / 15) || 0;

    ws.getCell("P54").value =
      getOffBoradCount(week_data4, "MT > MFC", "10:00") || 0;
    ws.getCell("P55").value =
      getOffBoradCount(week_data4, "MT > MFC", "10:30") || 0;
    ws.getCell("P56").value =
      getOffBoradCount(week_data4, "MT > MFC", "11:00") || 0;
    ws.getCell("P57").value =
      getOffBoradCount(week_data4, "MT > MFC", "11:30") || 0;
    ws.getCell("P58").value =
      getOffBoradCount(week_data4, "MT > MFC", "12:30") || 0;
    ws.getCell("P59").value =
      getOffBoradCount(week_data4, "MT > MFC", "14:30") || 0;
    ws.getCell("P60").value =
      getOffBoradCount(week_data4, "MT > MFC", "15:00") || 0;
    ws.getCell("P61").value =
      getOffBoradCount(week_data4, "MT > MFC", "15:30") || 0;
    ws.getCell("P62").value =
      getOffBoradCount(week_data4, "MT > MFC", "16:00") || 0;
    ws.getCell("P63").value =
      getOffBoradCount(week_data4, "MT > MFC", "16:30") || 0;
    ws.getCell("P64").value =
      getOffBoradCount(week_data4, "MT > MFC", "17:00") || 0;
    ws.getCell("P65").value =
      getOffBoradCount(week_data4, "MT > MFC", "17:30") || 0;
    ws.getCell("P66").value =
      getOffBoradCount(week_data4, "MT > MFC", "18:00") || 0;
    ws.getCell("P67").value =
      ws.getCell("P54").value +
      ws.getCell("P55").value +
      ws.getCell("P56").value +
      ws.getCell("P57").value +
      ws.getCell("P58").value +
      ws.getCell("P59").value +
      ws.getCell("P60").value +
      ws.getCell("P61").value +
      ws.getCell("P62").value +
      ws.getCell("P63").value +
      ws.getCell("P64").value +
      ws.getCell("P65").value +
      ws.getCell("P66").value;
    ws.getCell("P68").value = Math.floor(ws.getCell("P67").value / 15) || 0;

    ws.getCell("Q54").value =
      getOnBoradCount(week_data4, "MFC > LG", "10:10") || 0;
    ws.getCell("Q55").value =
      getOnBoradCount(week_data4, "MFC > LG", "10:40") || 0;
    ws.getCell("Q56").value =
      getOnBoradCount(week_data4, "MFC > LG", "11:10") || 0;
    ws.getCell("Q57").value =
      getOnBoradCount(week_data4, "MFC > LG", "11:40") || 0;
    ws.getCell("Q58").value =
      getOnBoradCount(week_data4, "MFC > LG", "12:40") || 0;
    ws.getCell("Q59").value =
      getOnBoradCount(week_data4, "MFC > LG", "14:40") || 0;
    ws.getCell("Q60").value =
      getOnBoradCount(week_data4, "MFC > LG", "15:10") || 0;
    ws.getCell("Q61").value =
      getOnBoradCount(week_data4, "MFC > LG", "15:40") || 0;
    ws.getCell("Q62").value =
      getOnBoradCount(week_data4, "MFC > LG", "16:10") || 0;
    ws.getCell("Q63").value =
      getOnBoradCount(week_data4, "MFC > LG", "16:40") || 0;
    ws.getCell("Q64").value =
      getOnBoradCount(week_data4, "MFC > LG", "17:10") || 0;
    ws.getCell("Q65").value =
      getOnBoradCount(week_data4, "MFC > LG", "17:40") || 0;
    ws.getCell("Q66").value =
      getOnBoradCount(week_data4, "MFC > LG", "18:10") || 0;
    ws.getCell("Q67").value =
      ws.getCell("Q54").value +
      ws.getCell("Q55").value +
      ws.getCell("Q56").value +
      ws.getCell("Q57").value +
      ws.getCell("Q58").value +
      ws.getCell("Q59").value +
      ws.getCell("Q60").value +
      ws.getCell("Q61").value +
      ws.getCell("Q62").value +
      ws.getCell("Q63").value +
      ws.getCell("Q64").value +
      ws.getCell("Q65").value +
      ws.getCell("Q66").value;
    ws.getCell("Q68").value = Math.floor(ws.getCell("Q67").value / 15) || 0;

    ws.getCell("R54").value =
      ws.getCell("O54").value -
      ws.getCell("P54").value +
      ws.getCell("Q54").value;
    ws.getCell("R55").value =
      ws.getCell("O55").value -
      ws.getCell("P55").value +
      ws.getCell("Q55").value;
    ws.getCell("R56").value =
      ws.getCell("O56").value -
      ws.getCell("P56").value +
      ws.getCell("Q56").value;
    ws.getCell("R57").value =
      ws.getCell("O57").value -
      ws.getCell("P57").value +
      ws.getCell("Q57").value;
    ws.getCell("R58").value =
      ws.getCell("O58").value -
      ws.getCell("P58").value +
      ws.getCell("Q58").value;
    ws.getCell("R59").value =
      ws.getCell("O59").value -
      ws.getCell("P59").value +
      ws.getCell("Q59").value;
    ws.getCell("R60").value =
      ws.getCell("O60").value -
      ws.getCell("P60").value +
      ws.getCell("Q60").value;
    ws.getCell("R61").value =
      ws.getCell("O61").value -
      ws.getCell("P61").value +
      ws.getCell("Q61").value;
    ws.getCell("R62").value =
      ws.getCell("O62").value -
      ws.getCell("P62").value +
      ws.getCell("Q62").value;
    ws.getCell("R63").value =
      ws.getCell("O63").value -
      ws.getCell("P63").value +
      ws.getCell("Q63").value;
    ws.getCell("R64").value =
      ws.getCell("O64").value -
      ws.getCell("P64").value +
      ws.getCell("Q64").value;
    ws.getCell("R65").value =
      ws.getCell("O65").value -
      ws.getCell("P65").value +
      ws.getCell("Q65").value;
    ws.getCell("R66").value =
      ws.getCell("O66").value -
      ws.getCell("P66").value +
      ws.getCell("Q66").value;
    ws.getCell("R67").value =
      ws.getCell("R54").value +
      ws.getCell("R55").value +
      ws.getCell("R56").value +
      ws.getCell("R57").value +
      ws.getCell("R58").value +
      ws.getCell("R59").value +
      ws.getCell("R60").value +
      ws.getCell("R61").value +
      ws.getCell("R62").value +
      ws.getCell("R63").value +
      ws.getCell("R64").value +
      ws.getCell("R65").value +
      ws.getCell("R66").value;
    ws.getCell("R68").value = Math.floor(ws.getCell("R67").value / 15) || 0;

    ws.getCell("S54").value =
      getOnBoradCount(week_data5, "MT > MFC", "10:00") || 0;
    ws.getCell("S55").value =
      getOnBoradCount(week_data5, "MT > MFC", "10:30") || 0;
    ws.getCell("S56").value =
      getOnBoradCount(week_data5, "MT > MFC", "11:00") || 0;
    ws.getCell("S57").value =
      getOnBoradCount(week_data5, "MT > MFC", "11:30") || 0;
    ws.getCell("S58").value =
      getOnBoradCount(week_data5, "MT > MFC", "12:30") || 0;
    ws.getCell("S59").value =
      getOnBoradCount(week_data5, "MT > MFC", "14:30") || 0;
    ws.getCell("S60").value =
      getOnBoradCount(week_data5, "MT > MFC", "15:00") || 0;
    ws.getCell("S61").value =
      getOnBoradCount(week_data5, "MT > MFC", "15:30") || 0;
    ws.getCell("S62").value =
      getOnBoradCount(week_data5, "MT > MFC", "16:00") || 0;
    ws.getCell("S63").value =
      getOnBoradCount(week_data5, "MT > MFC", "16:30") || 0;
    ws.getCell("S64").value =
      getOnBoradCount(week_data5, "MT > MFC", "17:00") || 0;
    ws.getCell("S65").value =
      getOnBoradCount(week_data5, "MT > MFC", "17:30") || 0;
    ws.getCell("S66").value =
      getOnBoradCount(week_data5, "MT > MFC", "18:00") || 0;
    ws.getCell("S67").value =
      ws.getCell("S54").value +
      ws.getCell("S55").value +
      ws.getCell("S56").value +
      ws.getCell("S57").value +
      ws.getCell("S58").value +
      ws.getCell("S59").value +
      ws.getCell("S60").value +
      ws.getCell("S61").value +
      ws.getCell("S62").value +
      ws.getCell("S63").value +
      ws.getCell("S64").value +
      ws.getCell("S65").value +
      ws.getCell("S66").value;
    ws.getCell("S68").value = Math.floor(ws.getCell("G67").value / 15) || 0;

    ws.getCell("T54").value =
      getOffBoradCount(week_data5, "MT > MFC", "10:00") || 0;
    ws.getCell("T55").value =
      getOffBoradCount(week_data5, "MT > MFC", "10:30") || 0;
    ws.getCell("T56").value =
      getOffBoradCount(week_data5, "MT > MFC", "11:00") || 0;
    ws.getCell("T57").value =
      getOffBoradCount(week_data5, "MT > MFC", "11:30") || 0;
    ws.getCell("T58").value =
      getOffBoradCount(week_data5, "MT > MFC", "12:30") || 0;
    ws.getCell("T59").value =
      getOffBoradCount(week_data5, "MT > MFC", "14:30") || 0;
    ws.getCell("T60").value =
      getOffBoradCount(week_data5, "MT > MFC", "15:00") || 0;
    ws.getCell("T61").value =
      getOffBoradCount(week_data5, "MT > MFC", "15:30") || 0;
    ws.getCell("T62").value =
      getOffBoradCount(week_data5, "MT > MFC", "16:00") || 0;
    ws.getCell("T63").value =
      getOffBoradCount(week_data5, "MT > MFC", "16:30") || 0;
    ws.getCell("T64").value =
      getOffBoradCount(week_data5, "MT > MFC", "17:00") || 0;
    ws.getCell("T65").value =
      getOffBoradCount(week_data5, "MT > MFC", "17:30") || 0;
    ws.getCell("T66").value =
      getOffBoradCount(week_data5, "MT > MFC", "18:00") || 0;
    ws.getCell("T67").value =
      ws.getCell("T54").value +
      ws.getCell("T55").value +
      ws.getCell("T56").value +
      ws.getCell("T57").value +
      ws.getCell("T58").value +
      ws.getCell("T59").value +
      ws.getCell("T60").value +
      ws.getCell("T61").value +
      ws.getCell("T62").value +
      ws.getCell("T63").value +
      ws.getCell("T64").value +
      ws.getCell("T65").value +
      ws.getCell("T66").value;
    ws.getCell("T68").value = Math.floor(ws.getCell("T67").value / 15) || 0;

    ws.getCell("U54").value =
      getOnBoradCount(week_data5, "MFC > LG", "10:10") || 0;
    ws.getCell("U55").value =
      getOnBoradCount(week_data5, "MFC > LG", "10:40") || 0;
    ws.getCell("U56").value =
      getOnBoradCount(week_data5, "MFC > LG", "11:10") || 0;
    ws.getCell("U57").value =
      getOnBoradCount(week_data5, "MFC > LG", "11:40") || 0;
    ws.getCell("U58").value =
      getOnBoradCount(week_data5, "MFC > LG", "12:40") || 0;
    ws.getCell("U59").value =
      getOnBoradCount(week_data5, "MFC > LG", "14:40") || 0;
    ws.getCell("U60").value =
      getOnBoradCount(week_data5, "MFC > LG", "15:10") || 0;
    ws.getCell("U61").value =
      getOnBoradCount(week_data5, "MFC > LG", "15:40") || 0;
    ws.getCell("U62").value =
      getOnBoradCount(week_data5, "MFC > LG", "16:10") || 0;
    ws.getCell("U63").value =
      getOnBoradCount(week_data5, "MFC > LG", "16:40") || 0;
    ws.getCell("U64").value =
      getOnBoradCount(week_data5, "MFC > LG", "17:10") || 0;
    ws.getCell("U65").value =
      getOnBoradCount(week_data5, "MFC > LG", "17:40") || 0;
    ws.getCell("U66").value =
      getOnBoradCount(week_data5, "MFC > LG", "18:10") || 0;
    ws.getCell("U67").value =
      ws.getCell("U54").value +
      ws.getCell("U55").value +
      ws.getCell("U56").value +
      ws.getCell("U57").value +
      ws.getCell("U58").value +
      ws.getCell("U59").value +
      ws.getCell("U60").value +
      ws.getCell("U61").value +
      ws.getCell("U62").value +
      ws.getCell("U63").value +
      ws.getCell("U64").value +
      ws.getCell("U65").value +
      ws.getCell("U66").value;
    ws.getCell("U68").value = Math.floor(ws.getCell("U67").value / 15) || 0;

    ws.getCell("V54").value =
      ws.getCell("S54").value -
      ws.getCell("T54").value +
      ws.getCell("U54").value;
    ws.getCell("V55").value =
      ws.getCell("S55").value -
      ws.getCell("T55").value +
      ws.getCell("U55").value;
    ws.getCell("V56").value =
      ws.getCell("S56").value -
      ws.getCell("T56").value +
      ws.getCell("U56").value;
    ws.getCell("V57").value =
      ws.getCell("S57").value -
      ws.getCell("T57").value +
      ws.getCell("U57").value;
    ws.getCell("V58").value =
      ws.getCell("S58").value -
      ws.getCell("T58").value +
      ws.getCell("U58").value;
    ws.getCell("V59").value =
      ws.getCell("S59").value -
      ws.getCell("T59").value +
      ws.getCell("U59").value;
    ws.getCell("V60").value =
      ws.getCell("S60").value -
      ws.getCell("T60").value +
      ws.getCell("U60").value;
    ws.getCell("V61").value =
      ws.getCell("S61").value -
      ws.getCell("T61").value +
      ws.getCell("U61").value;
    ws.getCell("V62").value =
      ws.getCell("S62").value -
      ws.getCell("T62").value +
      ws.getCell("U62").value;
    ws.getCell("V63").value =
      ws.getCell("S63").value -
      ws.getCell("T63").value +
      ws.getCell("U63").value;
    ws.getCell("V64").value =
      ws.getCell("S64").value -
      ws.getCell("T64").value +
      ws.getCell("U64").value;
    ws.getCell("V65").value =
      ws.getCell("S65").value -
      ws.getCell("T65").value +
      ws.getCell("U65").value;
    ws.getCell("V66").value =
      ws.getCell("S66").value -
      ws.getCell("T66").value +
      ws.getCell("U66").value;
    ws.getCell("V67").value =
      ws.getCell("V54").value +
      ws.getCell("V55").value +
      ws.getCell("V56").value +
      ws.getCell("V57").value +
      ws.getCell("V58").value +
      ws.getCell("V59").value +
      ws.getCell("V60").value +
      ws.getCell("V61").value +
      ws.getCell("V62").value +
      ws.getCell("V63").value +
      ws.getCell("V64").value +
      ws.getCell("V65").value +
      ws.getCell("V66").value;
    ws.getCell("V68").value = Math.floor(ws.getCell("V67").value / 15) || 0;

    ws.getCell("W54").value =
      ws.getCell("C54").value +
      ws.getCell("G54").value +
      ws.getCell("K54").value +
      ws.getCell("O54").value +
      ws.getCell("S54").value;
    ws.getCell("W55").value =
      ws.getCell("C55").value +
      ws.getCell("G55").value +
      ws.getCell("K55").value +
      ws.getCell("O55").value +
      ws.getCell("S55").value;
    ws.getCell("W56").value =
      ws.getCell("C56").value +
      ws.getCell("G56").value +
      ws.getCell("K56").value +
      ws.getCell("O56").value +
      ws.getCell("S56").value;
    ws.getCell("W57").value =
      ws.getCell("C57").value +
      ws.getCell("G57").value +
      ws.getCell("K57").value +
      ws.getCell("O57").value +
      ws.getCell("S57").value;
    ws.getCell("W58").value =
      ws.getCell("C58").value +
      ws.getCell("G58").value +
      ws.getCell("K58").value +
      ws.getCell("O58").value +
      ws.getCell("S58").value;
    ws.getCell("W59").value =
      ws.getCell("C59").value +
      ws.getCell("G59").value +
      ws.getCell("K59").value +
      ws.getCell("O59").value +
      ws.getCell("S59").value;
    ws.getCell("W60").value =
      ws.getCell("C60").value +
      ws.getCell("G60").value +
      ws.getCell("K60").value +
      ws.getCell("O60").value +
      ws.getCell("S60").value;
    ws.getCell("W61").value =
      ws.getCell("C61").value +
      ws.getCell("G61").value +
      ws.getCell("K61").value +
      ws.getCell("O61").value +
      ws.getCell("S61").value;
    ws.getCell("W62").value =
      ws.getCell("C62").value +
      ws.getCell("G62").value +
      ws.getCell("K62").value +
      ws.getCell("O62").value +
      ws.getCell("S62").value;
    ws.getCell("W63").value =
      ws.getCell("C63").value +
      ws.getCell("G63").value +
      ws.getCell("K63").value +
      ws.getCell("O63").value +
      ws.getCell("S63").value;
    ws.getCell("W64").value =
      ws.getCell("C64").value +
      ws.getCell("G64").value +
      ws.getCell("K64").value +
      ws.getCell("O64").value +
      ws.getCell("S64").value;
    ws.getCell("W65").value =
      ws.getCell("C65").value +
      ws.getCell("G65").value +
      ws.getCell("K65").value +
      ws.getCell("O65").value +
      ws.getCell("S65").value;
    ws.getCell("W66").value =
      ws.getCell("C66").value +
      ws.getCell("G66").value +
      ws.getCell("K66").value +
      ws.getCell("O66").value +
      ws.getCell("S66").value;
    ws.getCell("W67").value =
      ws.getCell("C67").value +
      ws.getCell("G67").value +
      ws.getCell("K67").value +
      ws.getCell("O67").value +
      ws.getCell("S67").value;
    ws.getCell("W68").value =
      ws.getCell("C68").value +
      ws.getCell("G68").value +
      ws.getCell("K68").value +
      ws.getCell("O68").value +
      ws.getCell("S68").value;

    ws.getCell("X54").value =
      Math.floor(ws.getCell("W54").value / weekTotal) || 0;
    ws.getCell("X55").value =
      Math.floor(ws.getCell("W55").value / weekTotal) || 0;
    ws.getCell("X56").value =
      Math.floor(ws.getCell("W56").value / weekTotal) || 0;
    ws.getCell("X57").value =
      Math.floor(ws.getCell("W57").value / weekTotal) || 0;
    ws.getCell("X58").value =
      Math.floor(ws.getCell("W58").value / weekTotal) || 0;
    ws.getCell("X59").value =
      Math.floor(ws.getCell("W59").value / weekTotal) || 0;
    ws.getCell("X60").value =
      Math.floor(ws.getCell("W60").value / weekTotal) || 0;
    ws.getCell("X61").value =
      Math.floor(ws.getCell("W61").value / weekTotal) || 0;
    ws.getCell("X62").value =
      Math.floor(ws.getCell("W62").value / weekTotal) || 0;
    ws.getCell("X63").value =
      Math.floor(ws.getCell("W63").value / weekTotal) || 0;
    ws.getCell("X64").value =
      Math.floor(ws.getCell("W64").value / weekTotal) || 0;
    ws.getCell("X65").value =
      Math.floor(ws.getCell("W65").value / weekTotal) || 0;
    ws.getCell("X66").value =
      Math.floor(ws.getCell("W66").value / weekTotal) || 0;
    ws.getCell("X67").value =
      Math.floor(ws.getCell("W67").value / weekTotal) || 0;
    ws.getCell("X68").value =
      Math.floor(ws.getCell("W68").value / weekTotal) || 0;

    ws.getCell("Y54").value =
      (ws.getCell("C54").value > 22 ? 1 : 0) +
      (ws.getCell("G54").value > 22 ? 1 : 0) +
      (ws.getCell("K54").value > 22 ? 1 : 0) +
      (ws.getCell("O54").value > 22 ? 1 : 0) +
      (ws.getCell("S54").value > 22 ? 1 : 0);
    ws.getCell("Y55").value =
      (ws.getCell("C55").value > 22 ? 1 : 0) +
      (ws.getCell("G55").value > 22 ? 1 : 0) +
      (ws.getCell("K55").value > 22 ? 1 : 0) +
      (ws.getCell("O55").value > 22 ? 1 : 0) +
      (ws.getCell("S55").value > 22 ? 1 : 0);
    ws.getCell("Y56").value =
      (ws.getCell("C56").value > 22 ? 1 : 0) +
      (ws.getCell("G56").value > 22 ? 1 : 0) +
      (ws.getCell("K56").value > 22 ? 1 : 0) +
      (ws.getCell("O56").value > 22 ? 1 : 0) +
      (ws.getCell("S56").value > 22 ? 1 : 0);
    ws.getCell("Y57").value =
      (ws.getCell("C57").value > 22 ? 1 : 0) +
      (ws.getCell("G57").value > 22 ? 1 : 0) +
      (ws.getCell("K57").value > 22 ? 1 : 0) +
      (ws.getCell("O57").value > 22 ? 1 : 0) +
      (ws.getCell("S57").value > 22 ? 1 : 0);
    ws.getCell("Y58").value =
      (ws.getCell("C58").value > 22 ? 1 : 0) +
      (ws.getCell("G58").value > 22 ? 1 : 0) +
      (ws.getCell("K58").value > 22 ? 1 : 0) +
      (ws.getCell("O58").value > 22 ? 1 : 0) +
      (ws.getCell("S58").value > 22 ? 1 : 0);
    ws.getCell("Y59").value =
      (ws.getCell("C59").value > 22 ? 1 : 0) +
      (ws.getCell("G59").value > 22 ? 1 : 0) +
      (ws.getCell("K59").value > 22 ? 1 : 0) +
      (ws.getCell("O59").value > 22 ? 1 : 0) +
      (ws.getCell("S59").value > 22 ? 1 : 0);
    ws.getCell("Y60").value =
      (ws.getCell("C60").value > 22 ? 1 : 0) +
      (ws.getCell("G60").value > 22 ? 1 : 0) +
      (ws.getCell("K60").value > 22 ? 1 : 0) +
      (ws.getCell("O60").value > 22 ? 1 : 0) +
      (ws.getCell("S60").value > 22 ? 1 : 0);
    ws.getCell("Y61").value =
      (ws.getCell("C61").value > 22 ? 1 : 0) +
      (ws.getCell("G61").value > 22 ? 1 : 0) +
      (ws.getCell("K61").value > 22 ? 1 : 0) +
      (ws.getCell("O61").value > 22 ? 1 : 0) +
      (ws.getCell("S61").value > 22 ? 1 : 0);
    ws.getCell("Y62").value =
      (ws.getCell("C62").value > 22 ? 1 : 0) +
      (ws.getCell("G62").value > 22 ? 1 : 0) +
      (ws.getCell("K62").value > 22 ? 1 : 0) +
      (ws.getCell("O62").value > 22 ? 1 : 0) +
      (ws.getCell("S62").value > 22 ? 1 : 0);
    ws.getCell("Y63").value =
      (ws.getCell("C63").value > 22 ? 1 : 0) +
      (ws.getCell("G63").value > 22 ? 1 : 0) +
      (ws.getCell("K63").value > 22 ? 1 : 0) +
      (ws.getCell("O63").value > 22 ? 1 : 0) +
      (ws.getCell("S63").value > 22 ? 1 : 0);
    ws.getCell("Y64").value =
      (ws.getCell("C64").value > 22 ? 1 : 0) +
      (ws.getCell("G64").value > 22 ? 1 : 0) +
      (ws.getCell("K64").value > 22 ? 1 : 0) +
      (ws.getCell("O64").value > 22 ? 1 : 0) +
      (ws.getCell("S64").value > 22 ? 1 : 0);
    ws.getCell("Y65").value =
      (ws.getCell("C65").value > 22 ? 1 : 0) +
      (ws.getCell("G65").value > 22 ? 1 : 0) +
      (ws.getCell("K65").value > 22 ? 1 : 0) +
      (ws.getCell("O65").value > 22 ? 1 : 0) +
      (ws.getCell("S65").value > 22 ? 1 : 0);
    ws.getCell("Y66").value =
      (ws.getCell("C66").value > 22 ? 1 : 0) +
      (ws.getCell("G66").value > 22 ? 1 : 0) +
      (ws.getCell("K66").value > 22 ? 1 : 0) +
      (ws.getCell("O66").value > 22 ? 1 : 0) +
      (ws.getCell("S66").value > 22 ? 1 : 0);

    ws.getCell("Z54").value =
      (Math.floor((ws.getCell("Y54").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z55").value =
      (Math.floor((ws.getCell("Y55").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z56").value =
      (Math.floor((ws.getCell("Y56").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z57").value =
      (Math.floor((ws.getCell("Y57").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z58").value =
      (Math.floor((ws.getCell("Y58").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z59").value =
      (Math.floor((ws.getCell("Y59").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z60").value =
      (Math.floor((ws.getCell("Y60").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z61").value =
      (Math.floor((ws.getCell("Y61").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z62").value =
      (Math.floor((ws.getCell("Y62").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z63").value =
      (Math.floor((ws.getCell("Y63").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z64").value =
      (Math.floor((ws.getCell("Y64").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z65").value =
      (Math.floor((ws.getCell("Y65").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z66").value =
      (Math.floor((ws.getCell("Y66").value / weekTotal) * 100) || 0) + "%";


    ws.getCell("AA54").value =
      (ws.getCell("C54").value === 28 ? 1 : 0) +
      (ws.getCell("G54").value === 28 ? 1 : 0) +
      (ws.getCell("K54").value === 28 ? 1 : 0) +
      (ws.getCell("O54").value === 28 ? 1 : 0) +
      (ws.getCell("S54").value === 28 ? 1 : 0);
    ws.getCell("AA55").value =
      (ws.getCell("C55").value === 28 ? 1 : 0) +
      (ws.getCell("G55").value === 28 ? 1 : 0) +
      (ws.getCell("K55").value === 28 ? 1 : 0) +
      (ws.getCell("O55").value === 28 ? 1 : 0) +
      (ws.getCell("S55").value === 28 ? 1 : 0);
    ws.getCell("AA56").value =
      (ws.getCell("C56").value === 28 ? 1 : 0) +
      (ws.getCell("G56").value === 28 ? 1 : 0) +
      (ws.getCell("K56").value === 28 ? 1 : 0) +
      (ws.getCell("O56").value === 28 ? 1 : 0) +
      (ws.getCell("S56").value === 28 ? 1 : 0);
    ws.getCell("AA57").value =
      (ws.getCell("C57").value === 28 ? 1 : 0) +
      (ws.getCell("G57").value === 28 ? 1 : 0) +
      (ws.getCell("K57").value === 28 ? 1 : 0) +
      (ws.getCell("O57").value === 28 ? 1 : 0) +
      (ws.getCell("S57").value === 28 ? 1 : 0);
    ws.getCell("AA58").value =
      (ws.getCell("C58").value === 28 ? 1 : 0) +
      (ws.getCell("G58").value === 28 ? 1 : 0) +
      (ws.getCell("K58").value === 28 ? 1 : 0) +
      (ws.getCell("O58").value === 28 ? 1 : 0) +
      (ws.getCell("S58").value === 28 ? 1 : 0);
    ws.getCell("AA59").value =
      (ws.getCell("C59").value === 28 ? 1 : 0) +
      (ws.getCell("G59").value === 28 ? 1 : 0) +
      (ws.getCell("K59").value === 28 ? 1 : 0) +
      (ws.getCell("O59").value === 28 ? 1 : 0) +
      (ws.getCell("S59").value === 28 ? 1 : 0);
    ws.getCell("AA60").value =
      (ws.getCell("C60").value === 28 ? 1 : 0) +
      (ws.getCell("G60").value === 28 ? 1 : 0) +
      (ws.getCell("K60").value === 28 ? 1 : 0) +
      (ws.getCell("O60").value === 28 ? 1 : 0) +
      (ws.getCell("S60").value === 28 ? 1 : 0);
    ws.getCell("AA61").value =
      (ws.getCell("C61").value === 28 ? 1 : 0) +
      (ws.getCell("G61").value === 28 ? 1 : 0) +
      (ws.getCell("K61").value === 28 ? 1 : 0) +
      (ws.getCell("O61").value === 28 ? 1 : 0) +
      (ws.getCell("S61").value === 28 ? 1 : 0);
    ws.getCell("AA62").value =
      (ws.getCell("C62").value === 28 ? 1 : 0) +
      (ws.getCell("G62").value === 28 ? 1 : 0) +
      (ws.getCell("K62").value === 28 ? 1 : 0) +
      (ws.getCell("O62").value === 28 ? 1 : 0) +
      (ws.getCell("S62").value === 28 ? 1 : 0);
    ws.getCell("AA63").value =
      (ws.getCell("C63").value === 28 ? 1 : 0) +
      (ws.getCell("G63").value === 28 ? 1 : 0) +
      (ws.getCell("K63").value === 28 ? 1 : 0) +
      (ws.getCell("O63").value === 28 ? 1 : 0) +
      (ws.getCell("S63").value === 28 ? 1 : 0);
    ws.getCell("AA64").value =
      (ws.getCell("C64").value === 28 ? 1 : 0) +
      (ws.getCell("G64").value === 28 ? 1 : 0) +
      (ws.getCell("K64").value === 28 ? 1 : 0) +
      (ws.getCell("O64").value === 28 ? 1 : 0) +
      (ws.getCell("S64").value === 28 ? 1 : 0);
    ws.getCell("AA65").value =
      (ws.getCell("C65").value === 28 ? 1 : 0) +
      (ws.getCell("G65").value === 28 ? 1 : 0) +
      (ws.getCell("K65").value === 28 ? 1 : 0) +
      (ws.getCell("O65").value === 28 ? 1 : 0) +
      (ws.getCell("S65").value === 28 ? 1 : 0);
    ws.getCell("AA66").value =
      (ws.getCell("C66").value === 28 ? 1 : 0) +
      (ws.getCell("G66").value === 28 ? 1 : 0) +
      (ws.getCell("K66").value === 28 ? 1 : 0) +
      (ws.getCell("O66").value === 28 ? 1 : 0) +
      (ws.getCell("S66").value === 28 ? 1 : 0);

    ws.getCell("AB54").value =
      (Math.floor((ws.getCell("AA54").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB55").value =
      (Math.floor((ws.getCell("AA55").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB56").value =
      (Math.floor((ws.getCell("AA56").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB57").value =
      (Math.floor((ws.getCell("AA57").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB58").value =
      (Math.floor((ws.getCell("AA58").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB59").value =
      (Math.floor((ws.getCell("AA59").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB60").value =
      (Math.floor((ws.getCell("AA60").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB61").value =
      (Math.floor((ws.getCell("AA61").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB62").value =
      (Math.floor((ws.getCell("AA62").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB63").value =
      (Math.floor((ws.getCell("AA63").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB64").value =
      (Math.floor((ws.getCell("AA64").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB65").value =
      (Math.floor((ws.getCell("AA65").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB66").value =
      (Math.floor((ws.getCell("AA66").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AC54").value =
      ws.getCell("F54").value +
      ws.getCell("J54").value +
      ws.getCell("N54").value +
      ws.getCell("R54").value +
      ws.getCell("V54").value;
    ws.getCell("AC55").value =
      ws.getCell("F55").value +
      ws.getCell("J55").value +
      ws.getCell("N55").value +
      ws.getCell("R55").value +
      ws.getCell("V55").value;
    ws.getCell("AC56").value =
      ws.getCell("F56").value +
      ws.getCell("J56").value +
      ws.getCell("N56").value +
      ws.getCell("R56").value +
      ws.getCell("V56").value;
    ws.getCell("AC57").value =
      ws.getCell("F57").value +
      ws.getCell("J57").value +
      ws.getCell("N57").value +
      ws.getCell("R57").value +
      ws.getCell("V57").value;
    ws.getCell("AC58").value =
      ws.getCell("F58").value +
      ws.getCell("J58").value +
      ws.getCell("N58").value +
      ws.getCell("R58").value +
      ws.getCell("V58").value;
    ws.getCell("AC59").value =
      ws.getCell("F59").value +
      ws.getCell("J59").value +
      ws.getCell("N59").value +
      ws.getCell("R59").value +
      ws.getCell("V59").value;
    ws.getCell("AC60").value =
      ws.getCell("F60").value +
      ws.getCell("J60").value +
      ws.getCell("N60").value +
      ws.getCell("R60").value +
      ws.getCell("V60").value;
    ws.getCell("AC61").value =
      ws.getCell("F61").value +
      ws.getCell("J61").value +
      ws.getCell("N61").value +
      ws.getCell("R61").value +
      ws.getCell("V61").value;
    ws.getCell("AC62").value =
      ws.getCell("F62").value +
      ws.getCell("J62").value +
      ws.getCell("N62").value +
      ws.getCell("R62").value +
      ws.getCell("V62").value;
    ws.getCell("AC63").value =
      ws.getCell("F63").value +
      ws.getCell("J63").value +
      ws.getCell("N63").value +
      ws.getCell("R63").value +
      ws.getCell("V63").value;
    ws.getCell("AC64").value =
      ws.getCell("F64").value +
      ws.getCell("J64").value +
      ws.getCell("N64").value +
      ws.getCell("R64").value +
      ws.getCell("V64").value;
    ws.getCell("AC65").value =
      ws.getCell("F65").value +
      ws.getCell("J65").value +
      ws.getCell("N65").value +
      ws.getCell("R65").value +
      ws.getCell("V65").value;
    ws.getCell("AC66").value =
      ws.getCell("F66").value +
      ws.getCell("J66").value +
      ws.getCell("N66").value +
      ws.getCell("R66").value +
      ws.getCell("V66").value;
    ws.getCell("AC67").value =
      ws.getCell("F67").value +
      ws.getCell("J67").value +
      ws.getCell("N67").value +
      ws.getCell("R67").value +
      ws.getCell("V67").value;
    ws.getCell("AC68").value =
      ws.getCell("F68").value +
      ws.getCell("J68").value +
      ws.getCell("N68").value +
      ws.getCell("R68").value +
      ws.getCell("V68").value;

    ws.getCell("AD54").value =
      Math.floor(ws.getCell("AC54").value / weekTotal) || 0;
    ws.getCell("AD55").value =
      Math.floor(ws.getCell("AC55").value / weekTotal) || 0;
    ws.getCell("AD56").value =
      Math.floor(ws.getCell("AC56").value / weekTotal) || 0;
    ws.getCell("AD57").value =
      Math.floor(ws.getCell("AC57").value / weekTotal) || 0;
    ws.getCell("AD58").value =
      Math.floor(ws.getCell("AC58").value / weekTotal) || 0;
    ws.getCell("AD59").value =
      Math.floor(ws.getCell("AC59").value / weekTotal) || 0;
    ws.getCell("AD60").value =
      Math.floor(ws.getCell("AC60").value / weekTotal) || 0;
    ws.getCell("AD61").value =
      Math.floor(ws.getCell("AC61").value / weekTotal) || 0;
    ws.getCell("AD62").value =
      Math.floor(ws.getCell("AC62").value / weekTotal) || 0;
    ws.getCell("AD63").value =
      Math.floor(ws.getCell("AC63").value / weekTotal) || 0;
    ws.getCell("AD64").value =
      Math.floor(ws.getCell("AC64").value / weekTotal) || 0;
    ws.getCell("AD65").value =
      Math.floor(ws.getCell("AC65").value / weekTotal) || 0;
    ws.getCell("AD66").value =
      Math.floor(ws.getCell("AC66").value / weekTotal) || 0;
    ws.getCell("AD67").value =
      Math.floor(ws.getCell("AC67").value / weekTotal) || 0;
    ws.getCell("AD68").value =
      Math.floor(ws.getCell("AC68").value / weekTotal) || 0;

    ws.getCell("AE54").value =
      (ws.getCell("F54").value > 22 ? 1 : 0) +
      (ws.getCell("J54").value > 22 ? 1 : 0) +
      (ws.getCell("N54").value > 22 ? 1 : 0) +
      (ws.getCell("R54").value > 22 ? 1 : 0) +
      (ws.getCell("V54").value > 22 ? 1 : 0);
    ws.getCell("AE55").value =
      (ws.getCell("F55").value > 22 ? 1 : 0) +
      (ws.getCell("J55").value > 22 ? 1 : 0) +
      (ws.getCell("N55").value > 22 ? 1 : 0) +
      (ws.getCell("R55").value > 22 ? 1 : 0) +
      (ws.getCell("V55").value > 22 ? 1 : 0);
    ws.getCell("AE56").value =
      (ws.getCell("F56").value > 22 ? 1 : 0) +
      (ws.getCell("J56").value > 22 ? 1 : 0) +
      (ws.getCell("N56").value > 22 ? 1 : 0) +
      (ws.getCell("R56").value > 22 ? 1 : 0) +
      (ws.getCell("V56").value > 22 ? 1 : 0);
    ws.getCell("AE57").value =
      (ws.getCell("F57").value > 22 ? 1 : 0) +
      (ws.getCell("J57").value > 22 ? 1 : 0) +
      (ws.getCell("N57").value > 22 ? 1 : 0) +
      (ws.getCell("R57").value > 22 ? 1 : 0) +
      (ws.getCell("V57").value > 22 ? 1 : 0);
    ws.getCell("AE58").value =
      (ws.getCell("F58").value > 22 ? 1 : 0) +
      (ws.getCell("J58").value > 22 ? 1 : 0) +
      (ws.getCell("N58").value > 22 ? 1 : 0) +
      (ws.getCell("R58").value > 22 ? 1 : 0) +
      (ws.getCell("V58").value > 22 ? 1 : 0);
    ws.getCell("AE59").value =
      (ws.getCell("F59").value > 22 ? 1 : 0) +
      (ws.getCell("J59").value > 22 ? 1 : 0) +
      (ws.getCell("N59").value > 22 ? 1 : 0) +
      (ws.getCell("R59").value > 22 ? 1 : 0) +
      (ws.getCell("V59").value > 22 ? 1 : 0);
    ws.getCell("AE60").value =
      (ws.getCell("F60").value > 22 ? 1 : 0) +
      (ws.getCell("J60").value > 22 ? 1 : 0) +
      (ws.getCell("N60").value > 22 ? 1 : 0) +
      (ws.getCell("R60").value > 22 ? 1 : 0) +
      (ws.getCell("V60").value > 22 ? 1 : 0);
    ws.getCell("AE61").value =
      (ws.getCell("F61").value > 22 ? 1 : 0) +
      (ws.getCell("J61").value > 22 ? 1 : 0) +
      (ws.getCell("N61").value > 22 ? 1 : 0) +
      (ws.getCell("R61").value > 22 ? 1 : 0) +
      (ws.getCell("V61").value > 22 ? 1 : 0);
    ws.getCell("AE62").value =
      (ws.getCell("F62").value > 22 ? 1 : 0) +
      (ws.getCell("J62").value > 22 ? 1 : 0) +
      (ws.getCell("N62").value > 22 ? 1 : 0) +
      (ws.getCell("R62").value > 22 ? 1 : 0) +
      (ws.getCell("V62").value > 22 ? 1 : 0);
    ws.getCell("AE63").value =
      (ws.getCell("F63").value > 22 ? 1 : 0) +
      (ws.getCell("J63").value > 22 ? 1 : 0) +
      (ws.getCell("N63").value > 22 ? 1 : 0) +
      (ws.getCell("R63").value > 22 ? 1 : 0) +
      (ws.getCell("V63").value > 22 ? 1 : 0);
    ws.getCell("AE64").value =
      (ws.getCell("F64").value > 22 ? 1 : 0) +
      (ws.getCell("J64").value > 22 ? 1 : 0) +
      (ws.getCell("N64").value > 22 ? 1 : 0) +
      (ws.getCell("R64").value > 22 ? 1 : 0) +
      (ws.getCell("V64").value > 22 ? 1 : 0);
    ws.getCell("AE65").value =
      (ws.getCell("F65").value > 22 ? 1 : 0) +
      (ws.getCell("J65").value > 22 ? 1 : 0) +
      (ws.getCell("N65").value > 22 ? 1 : 0) +
      (ws.getCell("R65").value > 22 ? 1 : 0) +
      (ws.getCell("V65").value > 22 ? 1 : 0);
    ws.getCell("AE66").value =
      (ws.getCell("F66").value > 22 ? 1 : 0) +
      (ws.getCell("J66").value > 22 ? 1 : 0) +
      (ws.getCell("N66").value > 22 ? 1 : 0) +
      (ws.getCell("R66").value > 22 ? 1 : 0) +
      (ws.getCell("V66").value > 22 ? 1 : 0);

    ws.getCell("AF54").value =
      (Math.floor((ws.getCell("AE54").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF55").value =
      (Math.floor((ws.getCell("AE55").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF56").value =
      (Math.floor((ws.getCell("AE56").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF57").value =
      (Math.floor((ws.getCell("AE57").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF58").value =
      (Math.floor((ws.getCell("AE58").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF59").value =
      (Math.floor((ws.getCell("AE59").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF60").value =
      (Math.floor((ws.getCell("AE60").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF61").value =
      (Math.floor((ws.getCell("AE61").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF62").value =
      (Math.floor((ws.getCell("AE62").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF63").value =
      (Math.floor((ws.getCell("AE63").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF64").value =
      (Math.floor((ws.getCell("AE64").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF65").value =
      (Math.floor((ws.getCell("AE65").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF66").value =
      (Math.floor((ws.getCell("AE66").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG54").value =
      (ws.getCell("F54").value === 28 ? 1 : 0) +
      (ws.getCell("J54").value === 28 ? 1 : 0) +
      (ws.getCell("N54").value === 28 ? 1 : 0) +
      (ws.getCell("R54").value === 28 ? 1 : 0) +
      (ws.getCell("V54").value === 28 ? 1 : 0);
    ws.getCell("AG55").value =
      (ws.getCell("F55").value === 28 ? 1 : 0) +
      (ws.getCell("J55").value === 28 ? 1 : 0) +
      (ws.getCell("N55").value === 28 ? 1 : 0) +
      (ws.getCell("R55").value === 28 ? 1 : 0) +
      (ws.getCell("V55").value === 28 ? 1 : 0);
    ws.getCell("AG56").value =
      (ws.getCell("F56").value === 28 ? 1 : 0) +
      (ws.getCell("J56").value === 28 ? 1 : 0) +
      (ws.getCell("N56").value === 28 ? 1 : 0) +
      (ws.getCell("R56").value === 28 ? 1 : 0) +
      (ws.getCell("V56").value === 28 ? 1 : 0);
    ws.getCell("AG57").value =
      (ws.getCell("F57").value === 28 ? 1 : 0) +
      (ws.getCell("J57").value === 28 ? 1 : 0) +
      (ws.getCell("N57").value === 28 ? 1 : 0) +
      (ws.getCell("R57").value === 28 ? 1 : 0) +
      (ws.getCell("V57").value === 28 ? 1 : 0);
    ws.getCell("AG58").value =
      (ws.getCell("F58").value === 28 ? 1 : 0) +
      (ws.getCell("J58").value === 28 ? 1 : 0) +
      (ws.getCell("N58").value === 28 ? 1 : 0) +
      (ws.getCell("R58").value === 28 ? 1 : 0) +
      (ws.getCell("V58").value === 28 ? 1 : 0);
    ws.getCell("AG59").value =
      (ws.getCell("F59").value === 28 ? 1 : 0) +
      (ws.getCell("J59").value === 28 ? 1 : 0) +
      (ws.getCell("N59").value === 28 ? 1 : 0) +
      (ws.getCell("R59").value === 28 ? 1 : 0) +
      (ws.getCell("V59").value === 28 ? 1 : 0);
    ws.getCell("AG60").value =
      (ws.getCell("F60").value === 28 ? 1 : 0) +
      (ws.getCell("J60").value === 28 ? 1 : 0) +
      (ws.getCell("N60").value === 28 ? 1 : 0) +
      (ws.getCell("R60").value === 28 ? 1 : 0) +
      (ws.getCell("V60").value === 28 ? 1 : 0);
    ws.getCell("AG61").value =
      (ws.getCell("F61").value === 28 ? 1 : 0) +
      (ws.getCell("J61").value === 28 ? 1 : 0) +
      (ws.getCell("N61").value === 28 ? 1 : 0) +
      (ws.getCell("R61").value === 28 ? 1 : 0) +
      (ws.getCell("V61").value === 28 ? 1 : 0);
    ws.getCell("AG62").value =
      (ws.getCell("F62").value === 28 ? 1 : 0) +
      (ws.getCell("J62").value === 28 ? 1 : 0) +
      (ws.getCell("N62").value === 28 ? 1 : 0) +
      (ws.getCell("R62").value === 28 ? 1 : 0) +
      (ws.getCell("V62").value === 28 ? 1 : 0);
    ws.getCell("AG63").value =
      (ws.getCell("F63").value === 28 ? 1 : 0) +
      (ws.getCell("J63").value === 28 ? 1 : 0) +
      (ws.getCell("N63").value === 28 ? 1 : 0) +
      (ws.getCell("R63").value === 28 ? 1 : 0) +
      (ws.getCell("V63").value === 28 ? 1 : 0);
    ws.getCell("AG64").value =
      (ws.getCell("F64").value === 28 ? 1 : 0) +
      (ws.getCell("J64").value === 28 ? 1 : 0) +
      (ws.getCell("N64").value === 28 ? 1 : 0) +
      (ws.getCell("R64").value === 28 ? 1 : 0) +
      (ws.getCell("V64").value === 28 ? 1 : 0);
    ws.getCell("AG65").value =
      (ws.getCell("F65").value === 28 ? 1 : 0) +
      (ws.getCell("J65").value === 28 ? 1 : 0) +
      (ws.getCell("N65").value === 28 ? 1 : 0) +
      (ws.getCell("R65").value === 28 ? 1 : 0) +
      (ws.getCell("V65").value === 28 ? 1 : 0);
    ws.getCell("AG66").value =
      (ws.getCell("F66").value === 28 ? 1 : 0) +
      (ws.getCell("J66").value === 28 ? 1 : 0) +
      (ws.getCell("N66").value === 28 ? 1 : 0) +
      (ws.getCell("R66").value === 28 ? 1 : 0) +
      (ws.getCell("V66").value === 28 ? 1 : 0);

    ws.getCell("AH54").value =
      (Math.floor((ws.getCell("AG54").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH55").value =
      (Math.floor((ws.getCell("AG55").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH56").value =
      (Math.floor((ws.getCell("AG56").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH57").value =
      (Math.floor((ws.getCell("AG57").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH58").value =
      (Math.floor((ws.getCell("AG58").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH59").value =
      (Math.floor((ws.getCell("AG59").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH60").value =
      (Math.floor((ws.getCell("AG60").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH61").value =
      (Math.floor((ws.getCell("AG61").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH62").value =
      (Math.floor((ws.getCell("AG62").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH63").value =
      (Math.floor((ws.getCell("AG63").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH64").value =
      (Math.floor((ws.getCell("AG64").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH65").value =
      (Math.floor((ws.getCell("AG65").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH66").value =
      (Math.floor((ws.getCell("AG66").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AI54").value =
      Math.floor(
        (ws.getCell("D54").value +
          ws.getCell("H54").value +
          ws.getCell("L54").value +
          ws.getCell("P54").value +
          ws.getCell("T54").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI55").value =
      Math.floor(
        (ws.getCell("D55").value +
          ws.getCell("H55").value +
          ws.getCell("L55").value +
          ws.getCell("P55").value +
          ws.getCell("T55").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI56").value =
      Math.floor(
        (ws.getCell("D56").value +
          ws.getCell("H56").value +
          ws.getCell("L56").value +
          ws.getCell("P56").value +
          ws.getCell("T56").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI57").value =
      Math.floor(
        (ws.getCell("D57").value +
          ws.getCell("H57").value +
          ws.getCell("L57").value +
          ws.getCell("P57").value +
          ws.getCell("T57").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI58").value =
      Math.floor(
        (ws.getCell("D58").value +
          ws.getCell("H58").value +
          ws.getCell("L58").value +
          ws.getCell("P58").value +
          ws.getCell("T58").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI59").value =
      Math.floor(
        (ws.getCell("D59").value +
          ws.getCell("H59").value +
          ws.getCell("L59").value +
          ws.getCell("P59").value +
          ws.getCell("T59").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI60").value =
      Math.floor(
        (ws.getCell("D60").value +
          ws.getCell("H60").value +
          ws.getCell("L60").value +
          ws.getCell("P60").value +
          ws.getCell("T60").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI61").value =
      Math.floor(
        (ws.getCell("D61").value +
          ws.getCell("H61").value +
          ws.getCell("L61").value +
          ws.getCell("P61").value +
          ws.getCell("T61").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI62").value =
      Math.floor(
        (ws.getCell("D62").value +
          ws.getCell("H62").value +
          ws.getCell("L62").value +
          ws.getCell("P62").value +
          ws.getCell("T62").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI63").value =
      Math.floor(
        (ws.getCell("D63").value +
          ws.getCell("H63").value +
          ws.getCell("L63").value +
          ws.getCell("P63").value +
          ws.getCell("T63").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI64").value =
      Math.floor(
        (ws.getCell("D64").value +
          ws.getCell("H64").value +
          ws.getCell("L64").value +
          ws.getCell("P64").value +
          ws.getCell("T64").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI65").value =
      Math.floor(
        (ws.getCell("D65").value +
          ws.getCell("H65").value +
          ws.getCell("L65").value +
          ws.getCell("P65").value +
          ws.getCell("T65").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI66").value =
      Math.floor(
        (ws.getCell("D66").value +
          ws.getCell("H66").value +
          ws.getCell("L66").value +
          ws.getCell("P66").value +
          ws.getCell("T66").value) /
        weekTotal
      ) || 0;
    ws.getCell("AI67").value =
      ws.getCell("AI54").value +
      ws.getCell("AI55").value +
      ws.getCell("AI56").value +
      ws.getCell("AI57").value +
      ws.getCell("AI58").value +
      ws.getCell("AI59").value +
      ws.getCell("AI60").value +
      ws.getCell("AI61").value +
      ws.getCell("AI62").value +
      ws.getCell("AI63").value +
      ws.getCell("AI64").value +
      ws.getCell("AI65").value +
      ws.getCell("AI66").value;
    ws.getCell("AI68").value = Math.floor(ws.getCell("AI67").value / 15) || 0;

    ws.getCell("AJ54").value =
      Math.floor(
        (ws.getCell("E54").value +
          ws.getCell("I54").value +
          ws.getCell("M54").value +
          ws.getCell("Q54").value +
          ws.getCell("U54").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ55").value =
      Math.floor(
        (ws.getCell("E55").value +
          ws.getCell("I55").value +
          ws.getCell("M55").value +
          ws.getCell("Q55").value +
          ws.getCell("U55").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ56").value =
      Math.floor(
        (ws.getCell("E56").value +
          ws.getCell("I56").value +
          ws.getCell("M56").value +
          ws.getCell("Q56").value +
          ws.getCell("U56").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ57").value =
      Math.floor(
        (ws.getCell("E57").value +
          ws.getCell("I57").value +
          ws.getCell("M57").value +
          ws.getCell("Q57").value +
          ws.getCell("U57").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ58").value =
      Math.floor(
        (ws.getCell("E58").value +
          ws.getCell("I58").value +
          ws.getCell("M58").value +
          ws.getCell("Q58").value +
          ws.getCell("U58").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ59").value =
      Math.floor(
        (ws.getCell("E59").value +
          ws.getCell("I59").value +
          ws.getCell("M59").value +
          ws.getCell("Q59").value +
          ws.getCell("U59").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ60").value =
      Math.floor(
        (ws.getCell("E60").value +
          ws.getCell("I60").value +
          ws.getCell("M60").value +
          ws.getCell("Q60").value +
          ws.getCell("U60").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ61").value =
      Math.floor(
        (ws.getCell("E61").value +
          ws.getCell("I61").value +
          ws.getCell("M61").value +
          ws.getCell("Q61").value +
          ws.getCell("U61").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ62").value =
      Math.floor(
        (ws.getCell("E62").value +
          ws.getCell("I62").value +
          ws.getCell("M62").value +
          ws.getCell("Q62").value +
          ws.getCell("U62").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ63").value =
      Math.floor(
        (ws.getCell("E63").value +
          ws.getCell("I63").value +
          ws.getCell("M63").value +
          ws.getCell("Q63").value +
          ws.getCell("U63").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ64").value =
      Math.floor(
        (ws.getCell("E64").value +
          ws.getCell("I64").value +
          ws.getCell("M64").value +
          ws.getCell("Q64").value +
          ws.getCell("U64").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ65").value =
      Math.floor(
        (ws.getCell("E65").value +
          ws.getCell("I65").value +
          ws.getCell("M65").value +
          ws.getCell("Q65").value +
          ws.getCell("U65").value) /
        weekTotal
      ) || 0;
    ws.getCell("AJ66").value =
      Math.floor(
        (ws.getCell("E66").value +
          ws.getCell("I66").value +
          ws.getCell("M66").value +
          ws.getCell("Q66").value +
          ws.getCell("U66").value) /
        weekTotal
      ) || 0;

    ws.getCell("AJ67").value =
      ws.getCell("AJ54").value +
      ws.getCell("AJ55").value +
      ws.getCell("AJ56").value +
      ws.getCell("AJ57").value +
      ws.getCell("AJ58").value +
      ws.getCell("AJ59").value +
      ws.getCell("AJ60").value +
      ws.getCell("AJ61").value +
      ws.getCell("AJ62").value +
      ws.getCell("AJ63").value +
      ws.getCell("AJ64").value +
      ws.getCell("AJ65").value +
      ws.getCell("AJ66").value;
    ws.getCell("AJ68").value = Math.floor(ws.getCell("AJ67").value / 15) || 0;

    // ROUTE 6
    ws.getCell("C73").value =
      getOnBoradCount(week_data1, "LG > MFC", "10:15") || 0;
    ws.getCell("C74").value =
      getOnBoradCount(week_data1, "LG > MFC", "10:45") || 0;
    ws.getCell("C75").value =
      getOnBoradCount(week_data1, "LG > MFC", "11:15") || 0;
    ws.getCell("C76").value =
      getOnBoradCount(week_data1, "LG > MFC", "11:45") || 0;
    ws.getCell("C77").value =
      getOnBoradCount(week_data1, "LG > MFC", "12:15") || 0;
    ws.getCell("C78").value =
      getOnBoradCount(week_data1, "LG > MFC", "12:45") || 0;
    ws.getCell("C80").value =
      getOnBoradCount(week_data1, "LG > MFC", "13:45") || 0;
    ws.getCell("C81").value =
      getOnBoradCount(week_data1, "LG > MFC", "14:15") || 0;
    ws.getCell("C82").value =
      getOnBoradCount(week_data1, "LG > MFC", "14:45") || 0;
    ws.getCell("C83").value =
      getOnBoradCount(week_data1, "LG > MFC", "15:15") || 0;
    ws.getCell("C84").value =
      getOnBoradCount(week_data1, "LG > MFC", "15:45") || 0;
    ws.getCell("C85").value =
      getOnBoradCount(week_data1, "LG > MFC", "16:15") || 0;
    ws.getCell("C86").value =
      getOnBoradCount(week_data1, "LG > MFC", "16:45") || 0;
    ws.getCell("C87").value =
      getOnBoradCount(week_data1, "LG > MFC", "17:15") || 0;
    ws.getCell("C88").value =
      getOnBoradCount(week_data1, "LG > MFC", "17:45") || 0;
    ws.getCell("C89").value =
      getOnBoradCount(week_data1, "LG > MFC", "18:15") || 0;
    ws.getCell("C90").value =
      ws.getCell("C73").value +
      ws.getCell("C74").value +
      ws.getCell("C75").value +
      ws.getCell("C76").value +
      ws.getCell("C77").value +
      ws.getCell("C78").value +
      ws.getCell("C80").value +
      ws.getCell("C81").value +
      ws.getCell("C82").value +
      ws.getCell("C83").value +
      ws.getCell("C84").value +
      ws.getCell("C85").value +
      ws.getCell("C86").value +
      ws.getCell("C87").value +
      ws.getCell("C88").value +
      ws.getCell("C89").value;
    ws.getCell("C91").value = Math.floor(ws.getCell("C90").value / 16) || 0;

    ws.getCell("D73").value =
      getOffBoradCount(week_data1, "LG > MFC", "10:15") || 0;
    ws.getCell("D74").value =
      getOffBoradCount(week_data1, "LG > MFC", "10:45") || 0;
    ws.getCell("D75").value =
      getOffBoradCount(week_data1, "LG > MFC", "11:15") || 0;
    ws.getCell("D76").value =
      getOffBoradCount(week_data1, "LG > MFC", "11:45") || 0;
    ws.getCell("D77").value =
      getOffBoradCount(week_data1, "LG > MFC", "12:15") || 0;
    ws.getCell("D78").value =
      getOffBoradCount(week_data1, "LG > MFC", "12:45") || 0;
    ws.getCell("D80").value =
      getOffBoradCount(week_data1, "LG > MFC", "13:45") || 0;
    ws.getCell("D81").value =
      getOffBoradCount(week_data1, "LG > MFC", "14:15") || 0;
    ws.getCell("D82").value =
      getOffBoradCount(week_data1, "LG > MFC", "14:45") || 0;
    ws.getCell("D83").value =
      getOffBoradCount(week_data1, "LG > MFC", "15:15") || 0;
    ws.getCell("D84").value =
      getOffBoradCount(week_data1, "LG > MFC", "15:45") || 0;
    ws.getCell("D85").value =
      getOffBoradCount(week_data1, "LG > MFC", "16:15") || 0;
    ws.getCell("D86").value =
      getOffBoradCount(week_data1, "LG > MFC", "16:45") || 0;
    ws.getCell("D87").value =
      getOffBoradCount(week_data1, "LG > MFC", "17:15") || 0;
    ws.getCell("D88").value =
      getOffBoradCount(week_data1, "LG > MFC", "17:45") || 0;
    ws.getCell("D89").value =
      getOffBoradCount(week_data1, "LG > MFC", "18:15") || 0;
    ws.getCell("D90").value =
      ws.getCell("D73").value +
      ws.getCell("D74").value +
      ws.getCell("D75").value +
      ws.getCell("D76").value +
      ws.getCell("D77").value +
      ws.getCell("D78").value +
      ws.getCell("D80").value +
      ws.getCell("D81").value +
      ws.getCell("D82").value +
      ws.getCell("D83").value +
      ws.getCell("D84").value +
      ws.getCell("D85").value +
      ws.getCell("D86").value +
      ws.getCell("D87").value +
      ws.getCell("D88").value +
      ws.getCell("D89").value;
    ws.getCell("D91").value = Math.floor(ws.getCell("D90").value / 16) || 0;

    ws.getCell("E73").value =
      getOnBoradCount(week_data1, "MFC > MT", "10:35") || 0;
    ws.getCell("E74").value =
      getOnBoradCount(week_data1, "MFC > MT", "11:05") || 0;
    ws.getCell("E75").value =
      getOnBoradCount(week_data1, "MFC > MT", "11:35") || 0;
    ws.getCell("E76").value =
      getOnBoradCount(week_data1, "MFC > MT", "12:05") || 0;
    ws.getCell("E77").value =
      getOnBoradCount(week_data1, "MFC > MT", "12:35") || 0;
    ws.getCell("E78").value =
      getOnBoradCount(week_data1, "MFC > MT", "13:05") || 0;
    ws.getCell("E79").value =
      getOnBoradCount(week_data1, "MFC > MT", "13:45") || 0;
    ws.getCell("E80").value =
      getOnBoradCount(week_data1, "MFC > MT", "14:05") || 0;
    ws.getCell("E81").value =
      getOnBoradCount(week_data1, "MFC > MT", "14:35") || 0;
    ws.getCell("E82").value =
      getOnBoradCount(week_data1, "MFC > MT", "15:05") || 0;
    ws.getCell("E83").value =
      getOnBoradCount(week_data1, "MFC > MT", "15:35") || 0;
    ws.getCell("E84").value =
      getOnBoradCount(week_data1, "MFC > MT", "16:05") || 0;
    ws.getCell("E85").value =
      getOnBoradCount(week_data1, "MFC > MT", "16:35") || 0;
    ws.getCell("E86").value =
      getOnBoradCount(week_data1, "MFC > MT", "17:05") || 0;
    ws.getCell("E87").value =
      getOnBoradCount(week_data1, "MFC > MT", "17:35") || 0;
    ws.getCell("E88").value =
      getOnBoradCount(week_data1, "MFC > MT", "18:05") || 0;
    ws.getCell("E89").value =
      getOnBoradCount(week_data1, "MFC > MT", "18:35") || 0;
    ws.getCell("E90").value =
      ws.getCell("E73").value +
      ws.getCell("E74").value +
      ws.getCell("E75").value +
      ws.getCell("E76").value +
      ws.getCell("E77").value +
      ws.getCell("E78").value +
      ws.getCell("E80").value +
      ws.getCell("E81").value +
      ws.getCell("E82").value +
      ws.getCell("E83").value +
      ws.getCell("E84").value +
      ws.getCell("E85").value +
      ws.getCell("E86").value +
      ws.getCell("E87").value +
      ws.getCell("E88").value +
      ws.getCell("E89").value;
    ws.getCell("E91").value = Math.floor(ws.getCell("E90").value / 16) || 0;

    ws.getCell("F73").value =
      ws.getCell("C73").value -
      ws.getCell("D73").value +
      ws.getCell("E73").value;
    ws.getCell("F74").value =
      ws.getCell("C74").value -
      ws.getCell("D74").value +
      ws.getCell("E74").value;
    ws.getCell("F75").value =
      ws.getCell("C75").value -
      ws.getCell("D75").value +
      ws.getCell("E75").value;
    ws.getCell("F76").value =
      ws.getCell("C76").value -
      ws.getCell("D76").value +
      ws.getCell("E76").value;
    ws.getCell("F77").value =
      ws.getCell("C77").value -
      ws.getCell("D77").value +
      ws.getCell("E77").value;
    ws.getCell("F78").value =
      ws.getCell("C78").value -
      ws.getCell("D78").value +
      ws.getCell("E78").value;
    ws.getCell("F79").value = ws.getCell("D79").value + ws.getCell("E79").value;
    ws.getCell("F80").value =
      ws.getCell("C80").value -
      ws.getCell("D80").value +
      ws.getCell("E80").value;
    ws.getCell("F81").value =
      ws.getCell("C81").value -
      ws.getCell("D81").value +
      ws.getCell("E81").value;
    ws.getCell("F82").value =
      ws.getCell("C82").value -
      ws.getCell("D82").value +
      ws.getCell("E82").value;
    ws.getCell("F83").value =
      ws.getCell("C83").value -
      ws.getCell("D83").value +
      ws.getCell("E83").value;
    ws.getCell("F84").value =
      ws.getCell("C84").value -
      ws.getCell("D84").value +
      ws.getCell("E84").value;
    ws.getCell("F85").value =
      ws.getCell("C85").value -
      ws.getCell("D85").value +
      ws.getCell("E85").value;
    ws.getCell("F86").value =
      ws.getCell("C86").value -
      ws.getCell("D86").value +
      ws.getCell("E86").value;
    ws.getCell("F87").value =
      ws.getCell("C87").value -
      ws.getCell("D87").value +
      ws.getCell("E87").value;
    ws.getCell("F88").value =
      ws.getCell("C88").value -
      ws.getCell("D88").value +
      ws.getCell("E88").value;
    ws.getCell("F89").value =
      ws.getCell("C89").value -
      ws.getCell("D89").value +
      ws.getCell("E89").value;
    ws.getCell("F90").value =
      ws.getCell("F73").value +
      ws.getCell("F74").value +
      ws.getCell("F75").value +
      ws.getCell("F76").value +
      ws.getCell("F77").value +
      ws.getCell("F78").value +
      ws.getCell("F80").value +
      ws.getCell("F81").value +
      ws.getCell("F82").value +
      ws.getCell("F83").value +
      ws.getCell("F84").value +
      ws.getCell("F85").value +
      ws.getCell("F86").value +
      ws.getCell("F87").value +
      ws.getCell("F88").value +
      ws.getCell("F89").value;
    ws.getCell("F91").value = Math.floor(ws.getCell("F90").value / 16) || 0;

    ws.getCell("G73").value =
      getOnBoradCount(week_data2, "LG > MFC", "10:15") || 0;
    ws.getCell("G74").value =
      getOnBoradCount(week_data2, "LG > MFC", "10:45") || 0;
    ws.getCell("G75").value =
      getOnBoradCount(week_data2, "LG > MFC", "11:15") || 0;
    ws.getCell("G76").value =
      getOnBoradCount(week_data2, "LG > MFC", "11:45") || 0;
    ws.getCell("G77").value =
      getOnBoradCount(week_data2, "LG > MFC", "12:15") || 0;
    ws.getCell("G78").value =
      getOnBoradCount(week_data2, "LG > MFC", "12:45") || 0;
    ws.getCell("G80").value =
      getOnBoradCount(week_data2, "LG > MFC", "13:45") || 0;
    ws.getCell("G81").value =
      getOnBoradCount(week_data2, "LG > MFC", "14:15") || 0;
    ws.getCell("G82").value =
      getOnBoradCount(week_data2, "LG > MFC", "14:45") || 0;
    ws.getCell("G83").value =
      getOnBoradCount(week_data2, "LG > MFC", "15:15") || 0;
    ws.getCell("G84").value =
      getOnBoradCount(week_data2, "LG > MFC", "15:45") || 0;
    ws.getCell("G85").value =
      getOnBoradCount(week_data2, "LG > MFC", "16:15") || 0;
    ws.getCell("G86").value =
      getOnBoradCount(week_data2, "LG > MFC", "16:45") || 0;
    ws.getCell("G87").value =
      getOnBoradCount(week_data2, "LG > MFC", "17:15") || 0;
    ws.getCell("G88").value =
      getOnBoradCount(week_data2, "LG > MFC", "17:45") || 0;
    ws.getCell("G89").value =
      getOnBoradCount(week_data2, "LG > MFC", "18:15") || 0;
    ws.getCell("G90").value =
      ws.getCell("G73").value +
      ws.getCell("G74").value +
      ws.getCell("G75").value +
      ws.getCell("G76").value +
      ws.getCell("G77").value +
      ws.getCell("G78").value +
      ws.getCell("G80").value +
      ws.getCell("G81").value +
      ws.getCell("G82").value +
      ws.getCell("G83").value +
      ws.getCell("G84").value +
      ws.getCell("G85").value +
      ws.getCell("G86").value +
      ws.getCell("G87").value +
      ws.getCell("G88").value +
      ws.getCell("G89").value;
    ws.getCell("G91").value = Math.floor(ws.getCell("G90").value / 16) || 0;

    ws.getCell("H73").value =
      getOffBoradCount(week_data2, "LG > MFC", "10:15") || 0;
    ws.getCell("H74").value =
      getOffBoradCount(week_data2, "LG > MFC", "10:45") || 0;
    ws.getCell("H75").value =
      getOffBoradCount(week_data2, "LG > MFC", "11:15") || 0;
    ws.getCell("H76").value =
      getOffBoradCount(week_data2, "LG > MFC", "11:45") || 0;
    ws.getCell("H77").value =
      getOffBoradCount(week_data2, "LG > MFC", "12:15") || 0;
    ws.getCell("H78").value =
      getOffBoradCount(week_data2, "LG > MFC", "12:45") || 0;
    ws.getCell("H80").value =
      getOffBoradCount(week_data2, "LG > MFC", "13:45") || 0;
    ws.getCell("H81").value =
      getOffBoradCount(week_data2, "LG > MFC", "14:15") || 0;
    ws.getCell("H82").value =
      getOffBoradCount(week_data2, "LG > MFC", "14:45") || 0;
    ws.getCell("H83").value =
      getOffBoradCount(week_data2, "LG > MFC", "15:15") || 0;
    ws.getCell("H84").value =
      getOffBoradCount(week_data2, "LG > MFC", "15:45") || 0;
    ws.getCell("H85").value =
      getOffBoradCount(week_data2, "LG > MFC", "16:15") || 0;
    ws.getCell("H86").value =
      getOffBoradCount(week_data2, "LG > MFC", "16:45") || 0;
    ws.getCell("H87").value =
      getOffBoradCount(week_data2, "LG > MFC", "17:15") || 0;
    ws.getCell("H88").value =
      getOffBoradCount(week_data2, "LG > MFC", "17:45") || 0;
    ws.getCell("H89").value =
      getOffBoradCount(week_data2, "LG > MFC", "18:15") || 0;
    ws.getCell("H90").value =
      ws.getCell("H73").value +
      ws.getCell("H74").value +
      ws.getCell("H75").value +
      ws.getCell("H76").value +
      ws.getCell("H77").value +
      ws.getCell("H78").value +
      ws.getCell("H80").value +
      ws.getCell("H81").value +
      ws.getCell("H82").value +
      ws.getCell("H83").value +
      ws.getCell("H84").value +
      ws.getCell("H85").value +
      ws.getCell("H86").value +
      ws.getCell("H87").value +
      ws.getCell("H88").value +
      ws.getCell("H89").value;
    ws.getCell("H91").value = Math.floor(ws.getCell("H90").value / 16) || 0;

    ws.getCell("I73").value =
      getOnBoradCount(week_data2, "MFC > MT", "10:35") || 0;
    ws.getCell("I74").value =
      getOnBoradCount(week_data2, "MFC > MT", "11:05") || 0;
    ws.getCell("I75").value =
      getOnBoradCount(week_data2, "MFC > MT", "11:35") || 0;
    ws.getCell("I76").value =
      getOnBoradCount(week_data2, "MFC > MT", "12:05") || 0;
    ws.getCell("I77").value =
      getOnBoradCount(week_data2, "MFC > MT", "12:35") || 0;
    ws.getCell("I78").value =
      getOnBoradCount(week_data2, "MFC > MT", "13:05") || 0;
    ws.getCell("I79").value =
      getOnBoradCount(week_data2, "MFC > MT", "13:45") || 0;
    ws.getCell("I80").value =
      getOnBoradCount(week_data2, "MFC > MT", "14:05") || 0;
    ws.getCell("I81").value =
      getOnBoradCount(week_data2, "MFC > MT", "14:35") || 0;
    ws.getCell("I82").value =
      getOnBoradCount(week_data2, "MFC > MT", "15:05") || 0;
    ws.getCell("I83").value =
      getOnBoradCount(week_data2, "MFC > MT", "15:35") || 0;
    ws.getCell("I84").value =
      getOnBoradCount(week_data2, "MFC > MT", "16:05") || 0;
    ws.getCell("I85").value =
      getOnBoradCount(week_data2, "MFC > MT", "16:35") || 0;
    ws.getCell("I86").value =
      getOnBoradCount(week_data2, "MFC > MT", "17:05") || 0;
    ws.getCell("I87").value =
      getOnBoradCount(week_data2, "MFC > MT", "17:35") || 0;
    ws.getCell("I88").value =
      getOnBoradCount(week_data2, "MFC > MT", "18:05") || 0;
    ws.getCell("I89").value =
      getOnBoradCount(week_data2, "MFC > MT", "18:35") || 0;
    ws.getCell("I90").value =
      ws.getCell("I73").value +
      ws.getCell("I74").value +
      ws.getCell("I75").value +
      ws.getCell("I76").value +
      ws.getCell("I77").value +
      ws.getCell("I78").value +
      ws.getCell("I80").value +
      ws.getCell("I81").value +
      ws.getCell("I82").value +
      ws.getCell("I83").value +
      ws.getCell("I84").value +
      ws.getCell("I85").value +
      ws.getCell("I86").value +
      ws.getCell("I87").value +
      ws.getCell("I88").value +
      ws.getCell("I89").value;
    ws.getCell("I91").value = Math.floor(ws.getCell("I90").value / 16) || 0;

    ws.getCell("J73").value =
      ws.getCell("G73").value -
      ws.getCell("H73").value +
      ws.getCell("I73").value;
    ws.getCell("J74").value =
      ws.getCell("G74").value -
      ws.getCell("H74").value +
      ws.getCell("I74").value;
    ws.getCell("J75").value =
      ws.getCell("G75").value -
      ws.getCell("H75").value +
      ws.getCell("I75").value;
    ws.getCell("J76").value =
      ws.getCell("G76").value -
      ws.getCell("H76").value +
      ws.getCell("I76").value;
    ws.getCell("J77").value =
      ws.getCell("G77").value -
      ws.getCell("H77").value +
      ws.getCell("I77").value;
    ws.getCell("J78").value =
      ws.getCell("G78").value -
      ws.getCell("H78").value +
      ws.getCell("I78").value;
    ws.getCell("J79").value = ws.getCell("H79").value + ws.getCell("I79").value;
    ws.getCell("J80").value =
      ws.getCell("G80").value -
      ws.getCell("H80").value +
      ws.getCell("I80").value;
    ws.getCell("J81").value =
      ws.getCell("G81").value -
      ws.getCell("H81").value +
      ws.getCell("I81").value;
    ws.getCell("J82").value =
      ws.getCell("G82").value -
      ws.getCell("H82").value +
      ws.getCell("I82").value;
    ws.getCell("J83").value =
      ws.getCell("G83").value -
      ws.getCell("H83").value +
      ws.getCell("I83").value;
    ws.getCell("J84").value =
      ws.getCell("G84").value -
      ws.getCell("H84").value +
      ws.getCell("I84").value;
    ws.getCell("J85").value =
      ws.getCell("G85").value -
      ws.getCell("H85").value +
      ws.getCell("I85").value;
    ws.getCell("J86").value =
      ws.getCell("G86").value -
      ws.getCell("H86").value +
      ws.getCell("I86").value;
    ws.getCell("J87").value =
      ws.getCell("G87").value -
      ws.getCell("H87").value +
      ws.getCell("I87").value;
    ws.getCell("J88").value =
      ws.getCell("G88").value -
      ws.getCell("H88").value +
      ws.getCell("I88").value;
    ws.getCell("J89").value =
      ws.getCell("G89").value -
      ws.getCell("H89").value +
      ws.getCell("I89").value;
    ws.getCell("J90").value =
      ws.getCell("J73").value +
      ws.getCell("J74").value +
      ws.getCell("J75").value +
      ws.getCell("J76").value +
      ws.getCell("J77").value +
      ws.getCell("J78").value +
      ws.getCell("J80").value +
      ws.getCell("J81").value +
      ws.getCell("J82").value +
      ws.getCell("J83").value +
      ws.getCell("J84").value +
      ws.getCell("J85").value +
      ws.getCell("J86").value +
      ws.getCell("J87").value +
      ws.getCell("J88").value +
      ws.getCell("J89").value;
    ws.getCell("J91").value = Math.floor(ws.getCell("J90").value / 16) || 0;

    ws.getCell("K73").value =
      getOnBoradCount(week_data3, "LG > MFC", "10:15") || 0;
    ws.getCell("K74").value =
      getOnBoradCount(week_data3, "LG > MFC", "10:45") || 0;
    ws.getCell("K75").value =
      getOnBoradCount(week_data3, "LG > MFC", "11:15") || 0;
    ws.getCell("K76").value =
      getOnBoradCount(week_data3, "LG > MFC", "11:45") || 0;
    ws.getCell("K77").value =
      getOnBoradCount(week_data3, "LG > MFC", "12:15") || 0;
    ws.getCell("K78").value =
      getOnBoradCount(week_data3, "LG > MFC", "12:45") || 0;
    ws.getCell("K80").value =
      getOnBoradCount(week_data3, "LG > MFC", "13:45") || 0;
    ws.getCell("K81").value =
      getOnBoradCount(week_data3, "LG > MFC", "14:15") || 0;
    ws.getCell("K82").value =
      getOnBoradCount(week_data3, "LG > MFC", "14:45") || 0;
    ws.getCell("K83").value =
      getOnBoradCount(week_data3, "LG > MFC", "15:15") || 0;
    ws.getCell("K84").value =
      getOnBoradCount(week_data3, "LG > MFC", "15:45") || 0;
    ws.getCell("K85").value =
      getOnBoradCount(week_data3, "LG > MFC", "16:15") || 0;
    ws.getCell("K86").value =
      getOnBoradCount(week_data3, "LG > MFC", "16:45") || 0;
    ws.getCell("K87").value =
      getOnBoradCount(week_data3, "LG > MFC", "17:15") || 0;
    ws.getCell("K88").value =
      getOnBoradCount(week_data3, "LG > MFC", "17:45") || 0;
    ws.getCell("K89").value =
      getOnBoradCount(week_data3, "LG > MFC", "18:15") || 0;
    ws.getCell("K90").value =
      ws.getCell("K73").value +
      ws.getCell("K74").value +
      ws.getCell("K75").value +
      ws.getCell("K76").value +
      ws.getCell("K77").value +
      ws.getCell("K78").value +
      ws.getCell("K80").value +
      ws.getCell("K81").value +
      ws.getCell("K82").value +
      ws.getCell("K83").value +
      ws.getCell("K84").value +
      ws.getCell("K85").value +
      ws.getCell("K86").value +
      ws.getCell("K87").value +
      ws.getCell("K88").value +
      ws.getCell("K89").value;
    ws.getCell("K91").value = Math.floor(ws.getCell("K90").value / 16) || 0;

    ws.getCell("L73").value =
      getOffBoradCount(week_data3, "LG > MFC", "10:15") || 0;
    ws.getCell("L74").value =
      getOffBoradCount(week_data3, "LG > MFC", "10:45") || 0;
    ws.getCell("L75").value =
      getOffBoradCount(week_data3, "LG > MFC", "11:15") || 0;
    ws.getCell("L76").value =
      getOffBoradCount(week_data3, "LG > MFC", "11:45") || 0;
    ws.getCell("L77").value =
      getOffBoradCount(week_data3, "LG > MFC", "12:15") || 0;
    ws.getCell("L78").value =
      getOffBoradCount(week_data3, "LG > MFC", "12:45") || 0;
    ws.getCell("L80").value =
      getOffBoradCount(week_data3, "LG > MFC", "13:45") || 0;
    ws.getCell("L81").value =
      getOffBoradCount(week_data3, "LG > MFC", "14:15") || 0;
    ws.getCell("L82").value =
      getOffBoradCount(week_data3, "LG > MFC", "14:45") || 0;
    ws.getCell("L83").value =
      getOffBoradCount(week_data3, "LG > MFC", "15:15") || 0;
    ws.getCell("L84").value =
      getOffBoradCount(week_data3, "LG > MFC", "15:45") || 0;
    ws.getCell("L85").value =
      getOffBoradCount(week_data3, "LG > MFC", "16:15") || 0;
    ws.getCell("L86").value =
      getOffBoradCount(week_data3, "LG > MFC", "16:45") || 0;
    ws.getCell("L87").value =
      getOffBoradCount(week_data3, "LG > MFC", "17:15") || 0;
    ws.getCell("L88").value =
      getOffBoradCount(week_data3, "LG > MFC", "17:45") || 0;
    ws.getCell("L89").value =
      getOffBoradCount(week_data3, "LG > MFC", "18:15") || 0;
    ws.getCell("L90").value =
      ws.getCell("L73").value +
      ws.getCell("L74").value +
      ws.getCell("L75").value +
      ws.getCell("L76").value +
      ws.getCell("L77").value +
      ws.getCell("L78").value +
      ws.getCell("L80").value +
      ws.getCell("L81").value +
      ws.getCell("L82").value +
      ws.getCell("L83").value +
      ws.getCell("L84").value +
      ws.getCell("L85").value +
      ws.getCell("L86").value +
      ws.getCell("L87").value +
      ws.getCell("L88").value +
      ws.getCell("L89").value;
    ws.getCell("L91").value = Math.floor(ws.getCell("L90").value / 16) || 0;

    ws.getCell("M73").value =
      getOnBoradCount(week_data3, "MFC > MT", "10:35") || 0;
    ws.getCell("M74").value =
      getOnBoradCount(week_data3, "MFC > MT", "11:05") || 0;
    ws.getCell("M75").value =
      getOnBoradCount(week_data3, "MFC > MT", "11:35") || 0;
    ws.getCell("M76").value =
      getOnBoradCount(week_data3, "MFC > MT", "12:05") || 0;
    ws.getCell("M77").value =
      getOnBoradCount(week_data3, "MFC > MT", "12:35") || 0;
    ws.getCell("M78").value =
      getOnBoradCount(week_data3, "MFC > MT", "13:05") || 0;
    ws.getCell("M79").value =
      getOnBoradCount(week_data3, "MFC > MT", "13:45") || 0;
    ws.getCell("M80").value =
      getOnBoradCount(week_data3, "MFC > MT", "14:05") || 0;
    ws.getCell("M81").value =
      getOnBoradCount(week_data3, "MFC > MT", "14:35") || 0;
    ws.getCell("M82").value =
      getOnBoradCount(week_data3, "MFC > MT", "15:05") || 0;
    ws.getCell("M83").value =
      getOnBoradCount(week_data3, "MFC > MT", "15:35") || 0;
    ws.getCell("M84").value =
      getOnBoradCount(week_data3, "MFC > MT", "16:05") || 0;
    ws.getCell("M85").value =
      getOnBoradCount(week_data3, "MFC > MT", "16:35") || 0;
    ws.getCell("M86").value =
      getOnBoradCount(week_data3, "MFC > MT", "17:05") || 0;
    ws.getCell("M87").value =
      getOnBoradCount(week_data3, "MFC > MT", "17:35") || 0;
    ws.getCell("M88").value =
      getOnBoradCount(week_data3, "MFC > MT", "18:05") || 0;
    ws.getCell("M89").value =
      getOnBoradCount(week_data3, "MFC > MT", "18:35") || 0;
    ws.getCell("M90").value =
      ws.getCell("M73").value +
      ws.getCell("M74").value +
      ws.getCell("M75").value +
      ws.getCell("M76").value +
      ws.getCell("M77").value +
      ws.getCell("M78").value +
      ws.getCell("M80").value +
      ws.getCell("M81").value +
      ws.getCell("M82").value +
      ws.getCell("M83").value +
      ws.getCell("M84").value +
      ws.getCell("M85").value +
      ws.getCell("M86").value +
      ws.getCell("M87").value +
      ws.getCell("M88").value +
      ws.getCell("M89").value;
    ws.getCell("M91").value = Math.floor(ws.getCell("M90").value / 16) || 0;

    ws.getCell("N73").value =
      ws.getCell("K73").value -
      ws.getCell("L73").value +
      ws.getCell("M73").value;
    ws.getCell("N74").value =
      ws.getCell("K74").value -
      ws.getCell("L74").value +
      ws.getCell("M74").value;
    ws.getCell("N75").value =
      ws.getCell("K75").value -
      ws.getCell("L75").value +
      ws.getCell("M75").value;
    ws.getCell("N76").value =
      ws.getCell("K76").value -
      ws.getCell("L76").value +
      ws.getCell("M76").value;
    ws.getCell("N77").value =
      ws.getCell("K77").value -
      ws.getCell("L77").value +
      ws.getCell("M77").value;
    ws.getCell("N78").value =
      ws.getCell("K78").value -
      ws.getCell("L78").value +
      ws.getCell("M78").value;
    ws.getCell("N79").value = ws.getCell("L79").value + ws.getCell("M79").value;
    ws.getCell("N80").value =
      ws.getCell("K80").value -
      ws.getCell("L80").value +
      ws.getCell("M80").value;
    ws.getCell("N81").value =
      ws.getCell("K81").value -
      ws.getCell("L81").value +
      ws.getCell("M81").value;
    ws.getCell("N82").value =
      ws.getCell("K82").value -
      ws.getCell("L82").value +
      ws.getCell("M82").value;
    ws.getCell("N83").value =
      ws.getCell("K83").value -
      ws.getCell("L83").value +
      ws.getCell("M83").value;
    ws.getCell("N84").value =
      ws.getCell("K84").value -
      ws.getCell("L84").value +
      ws.getCell("M84").value;
    ws.getCell("N85").value =
      ws.getCell("K85").value -
      ws.getCell("L85").value +
      ws.getCell("M85").value;
    ws.getCell("N86").value =
      ws.getCell("K86").value -
      ws.getCell("L86").value +
      ws.getCell("M86").value;
    ws.getCell("N87").value =
      ws.getCell("K87").value -
      ws.getCell("L87").value +
      ws.getCell("M87").value;
    ws.getCell("N88").value =
      ws.getCell("K88").value -
      ws.getCell("L88").value +
      ws.getCell("M88").value;
    ws.getCell("N89").value =
      ws.getCell("K89").value -
      ws.getCell("L89").value +
      ws.getCell("M89").value;
    ws.getCell("N90").value =
      ws.getCell("N73").value +
      ws.getCell("N74").value +
      ws.getCell("N75").value +
      ws.getCell("N76").value +
      ws.getCell("N77").value +
      ws.getCell("N78").value +
      ws.getCell("N80").value +
      ws.getCell("N81").value +
      ws.getCell("N82").value +
      ws.getCell("N83").value +
      ws.getCell("N84").value +
      ws.getCell("N85").value +
      ws.getCell("N86").value +
      ws.getCell("N87").value +
      ws.getCell("N88").value +
      ws.getCell("N89").value;
    ws.getCell("N91").value = Math.floor(ws.getCell("N90").value / 16) || 0;

    ws.getCell("O73").value =
      getOnBoradCount(week_data4, "LG > MFC", "10:15") || 0;
    ws.getCell("O74").value =
      getOnBoradCount(week_data4, "LG > MFC", "10:45") || 0;
    ws.getCell("O75").value =
      getOnBoradCount(week_data4, "LG > MFC", "11:15") || 0;
    ws.getCell("O76").value =
      getOnBoradCount(week_data4, "LG > MFC", "11:45") || 0;
    ws.getCell("O77").value =
      getOnBoradCount(week_data4, "LG > MFC", "12:15") || 0;
    ws.getCell("O78").value =
      getOnBoradCount(week_data4, "LG > MFC", "12:45") || 0;
    ws.getCell("O80").value =
      getOnBoradCount(week_data4, "LG > MFC", "13:45") || 0;
    ws.getCell("O81").value =
      getOnBoradCount(week_data4, "LG > MFC", "14:15") || 0;
    ws.getCell("O82").value =
      getOnBoradCount(week_data4, "LG > MFC", "14:45") || 0;
    ws.getCell("O83").value =
      getOnBoradCount(week_data4, "LG > MFC", "15:15") || 0;
    ws.getCell("O84").value =
      getOnBoradCount(week_data4, "LG > MFC", "15:45") || 0;
    ws.getCell("O85").value =
      getOnBoradCount(week_data4, "LG > MFC", "16:15") || 0;
    ws.getCell("O86").value =
      getOnBoradCount(week_data4, "LG > MFC", "16:45") || 0;
    ws.getCell("O87").value =
      getOnBoradCount(week_data4, "LG > MFC", "17:15") || 0;
    ws.getCell("O88").value =
      getOnBoradCount(week_data4, "LG > MFC", "17:45") || 0;
    ws.getCell("O89").value =
      getOnBoradCount(week_data4, "LG > MFC", "18:15") || 0;
    ws.getCell("O90").value =
      ws.getCell("O73").value +
      ws.getCell("O74").value +
      ws.getCell("O75").value +
      ws.getCell("O76").value +
      ws.getCell("O77").value +
      ws.getCell("O78").value +
      ws.getCell("O80").value +
      ws.getCell("O81").value +
      ws.getCell("O82").value +
      ws.getCell("O83").value +
      ws.getCell("O84").value +
      ws.getCell("O85").value +
      ws.getCell("O86").value +
      ws.getCell("O87").value +
      ws.getCell("O88").value +
      ws.getCell("O89").value;
    ws.getCell("O91").value = Math.floor(ws.getCell("O90").value / 16) || 0;

    ws.getCell("P73").value =
      getOffBoradCount(week_data4, "LG > MFC", "10:15") || 0;
    ws.getCell("P74").value =
      getOffBoradCount(week_data4, "LG > MFC", "10:45") || 0;
    ws.getCell("P75").value =
      getOffBoradCount(week_data4, "LG > MFC", "11:15") || 0;
    ws.getCell("P76").value =
      getOffBoradCount(week_data4, "LG > MFC", "11:45") || 0;
    ws.getCell("P77").value =
      getOffBoradCount(week_data4, "LG > MFC", "12:15") || 0;
    ws.getCell("P78").value =
      getOffBoradCount(week_data4, "LG > MFC", "12:45") || 0;
    ws.getCell("P80").value =
      getOffBoradCount(week_data4, "LG > MFC", "13:45") || 0;
    ws.getCell("P81").value =
      getOffBoradCount(week_data4, "LG > MFC", "14:15") || 0;
    ws.getCell("P82").value =
      getOffBoradCount(week_data4, "LG > MFC", "14:45") || 0;
    ws.getCell("P83").value =
      getOffBoradCount(week_data4, "LG > MFC", "15:15") || 0;
    ws.getCell("P84").value =
      getOffBoradCount(week_data4, "LG > MFC", "15:45") || 0;
    ws.getCell("P85").value =
      getOffBoradCount(week_data4, "LG > MFC", "16:15") || 0;
    ws.getCell("P86").value =
      getOffBoradCount(week_data4, "LG > MFC", "16:45") || 0;
    ws.getCell("P87").value =
      getOffBoradCount(week_data4, "LG > MFC", "17:15") || 0;
    ws.getCell("P88").value =
      getOffBoradCount(week_data4, "LG > MFC", "17:45") || 0;
    ws.getCell("P89").value =
      getOffBoradCount(week_data4, "LG > MFC", "18:15") || 0;
    ws.getCell("P90").value =
      ws.getCell("P73").value +
      ws.getCell("P74").value +
      ws.getCell("P75").value +
      ws.getCell("P76").value +
      ws.getCell("P77").value +
      ws.getCell("P78").value +
      ws.getCell("P80").value +
      ws.getCell("P81").value +
      ws.getCell("P82").value +
      ws.getCell("P83").value +
      ws.getCell("P84").value +
      ws.getCell("P85").value +
      ws.getCell("P86").value +
      ws.getCell("P87").value +
      ws.getCell("P88").value +
      ws.getCell("P89").value;
    ws.getCell("P91").value = Math.floor(ws.getCell("P90").value / 16) || 0;

    ws.getCell("Q73").value =
      getOnBoradCount(week_data4, "MFC > MT", "10:35") || 0;
    ws.getCell("Q74").value =
      getOnBoradCount(week_data4, "MFC > MT", "11:05") || 0;
    ws.getCell("Q75").value =
      getOnBoradCount(week_data4, "MFC > MT", "11:35") || 0;
    ws.getCell("Q76").value =
      getOnBoradCount(week_data4, "MFC > MT", "12:05") || 0;
    ws.getCell("Q77").value =
      getOnBoradCount(week_data4, "MFC > MT", "12:35") || 0;
    ws.getCell("Q78").value =
      getOnBoradCount(week_data4, "MFC > MT", "13:05") || 0;
    ws.getCell("Q79").value =
      getOnBoradCount(week_data4, "MFC > MT", "13:45") || 0;
    ws.getCell("Q80").value =
      getOnBoradCount(week_data4, "MFC > MT", "14:05") || 0;
    ws.getCell("Q81").value =
      getOnBoradCount(week_data4, "MFC > MT", "14:35") || 0;
    ws.getCell("Q82").value =
      getOnBoradCount(week_data4, "MFC > MT", "15:05") || 0;
    ws.getCell("Q83").value =
      getOnBoradCount(week_data4, "MFC > MT", "15:35") || 0;
    ws.getCell("Q84").value =
      getOnBoradCount(week_data4, "MFC > MT", "16:05") || 0;
    ws.getCell("Q85").value =
      getOnBoradCount(week_data4, "MFC > MT", "16:35") || 0;
    ws.getCell("Q86").value =
      getOnBoradCount(week_data4, "MFC > MT", "17:05") || 0;
    ws.getCell("Q87").value =
      getOnBoradCount(week_data4, "MFC > MT", "17:35") || 0;
    ws.getCell("Q88").value =
      getOnBoradCount(week_data4, "MFC > MT", "18:05") || 0;
    ws.getCell("Q89").value =
      getOnBoradCount(week_data4, "MFC > MT", "18:35") || 0;
    ws.getCell("Q90").value =
      ws.getCell("Q73").value +
      ws.getCell("Q74").value +
      ws.getCell("Q75").value +
      ws.getCell("Q76").value +
      ws.getCell("Q77").value +
      ws.getCell("Q78").value +
      ws.getCell("Q80").value +
      ws.getCell("Q81").value +
      ws.getCell("Q82").value +
      ws.getCell("Q83").value +
      ws.getCell("Q84").value +
      ws.getCell("Q85").value +
      ws.getCell("Q86").value +
      ws.getCell("Q87").value +
      ws.getCell("Q88").value +
      ws.getCell("Q89").value;
    ws.getCell("Q91").value = Math.floor(ws.getCell("Q90").value / 16) || 0;

    ws.getCell("R73").value =
      ws.getCell("O73").value -
      ws.getCell("P73").value +
      ws.getCell("Q73").value;
    ws.getCell("R74").value =
      ws.getCell("O74").value -
      ws.getCell("P74").value +
      ws.getCell("Q74").value;
    ws.getCell("R75").value =
      ws.getCell("O75").value -
      ws.getCell("P75").value +
      ws.getCell("Q75").value;
    ws.getCell("R76").value =
      ws.getCell("O76").value -
      ws.getCell("P76").value +
      ws.getCell("Q76").value;
    ws.getCell("R77").value =
      ws.getCell("O77").value -
      ws.getCell("P77").value +
      ws.getCell("Q77").value;
    ws.getCell("R78").value =
      ws.getCell("O78").value -
      ws.getCell("P78").value +
      ws.getCell("Q78").value;
    ws.getCell("R79").value = ws.getCell("P79").value + ws.getCell("Q79").value;
    ws.getCell("R80").value =
      ws.getCell("O80").value -
      ws.getCell("P80").value +
      ws.getCell("Q80").value;
    ws.getCell("R81").value =
      ws.getCell("O81").value -
      ws.getCell("P81").value +
      ws.getCell("Q81").value;
    ws.getCell("R82").value =
      ws.getCell("O82").value -
      ws.getCell("P82").value +
      ws.getCell("Q82").value;
    ws.getCell("R83").value =
      ws.getCell("O83").value -
      ws.getCell("P83").value +
      ws.getCell("Q83").value;
    ws.getCell("R84").value =
      ws.getCell("O84").value -
      ws.getCell("P84").value +
      ws.getCell("Q84").value;
    ws.getCell("R85").value =
      ws.getCell("O85").value -
      ws.getCell("P85").value +
      ws.getCell("Q85").value;
    ws.getCell("R86").value =
      ws.getCell("O86").value -
      ws.getCell("P86").value +
      ws.getCell("Q86").value;
    ws.getCell("R87").value =
      ws.getCell("O87").value -
      ws.getCell("P87").value +
      ws.getCell("Q87").value;
    ws.getCell("R88").value =
      ws.getCell("O88").value -
      ws.getCell("P88").value +
      ws.getCell("Q88").value;
    ws.getCell("R89").value =
      ws.getCell("O89").value -
      ws.getCell("P89").value +
      ws.getCell("Q89").value;
    ws.getCell("R90").value =
      ws.getCell("R73").value +
      ws.getCell("R74").value +
      ws.getCell("R75").value +
      ws.getCell("R76").value +
      ws.getCell("R77").value +
      ws.getCell("R78").value +
      ws.getCell("R80").value +
      ws.getCell("R81").value +
      ws.getCell("R82").value +
      ws.getCell("R83").value +
      ws.getCell("R84").value +
      ws.getCell("R85").value +
      ws.getCell("R86").value +
      ws.getCell("R87").value +
      ws.getCell("R88").value +
      ws.getCell("R89").value;
    ws.getCell("R91").value = Math.floor(ws.getCell("R90").value / 16) || 0;

    ws.getCell("S73").value =
      getOnBoradCount(week_data5, "LG > MFC", "10:15") || 0;
    ws.getCell("S74").value =
      getOnBoradCount(week_data5, "LG > MFC", "10:45") || 0;
    ws.getCell("S75").value =
      getOnBoradCount(week_data5, "LG > MFC", "11:15") || 0;
    ws.getCell("S76").value =
      getOnBoradCount(week_data5, "LG > MFC", "11:45") || 0;
    ws.getCell("S77").value =
      getOnBoradCount(week_data5, "LG > MFC", "12:15") || 0;
    ws.getCell("S78").value =
      getOnBoradCount(week_data5, "LG > MFC", "12:45") || 0;
    ws.getCell("S80").value =
      getOnBoradCount(week_data5, "LG > MFC", "13:45") || 0;
    ws.getCell("S81").value =
      getOnBoradCount(week_data5, "LG > MFC", "14:15") || 0;
    ws.getCell("S82").value =
      getOnBoradCount(week_data5, "LG > MFC", "14:45") || 0;
    ws.getCell("S83").value =
      getOnBoradCount(week_data5, "LG > MFC", "15:15") || 0;
    ws.getCell("S84").value =
      getOnBoradCount(week_data5, "LG > MFC", "15:45") || 0;
    ws.getCell("S85").value =
      getOnBoradCount(week_data5, "LG > MFC", "16:15") || 0;
    ws.getCell("S86").value =
      getOnBoradCount(week_data5, "LG > MFC", "16:45") || 0;
    ws.getCell("S87").value =
      getOnBoradCount(week_data5, "LG > MFC", "17:15") || 0;
    ws.getCell("S88").value =
      getOnBoradCount(week_data5, "LG > MFC", "17:45") || 0;
    ws.getCell("S89").value =
      getOnBoradCount(week_data5, "LG > MFC", "18:15") || 0;
    ws.getCell("S90").value =
      ws.getCell("S73").value +
      ws.getCell("S74").value +
      ws.getCell("S75").value +
      ws.getCell("S76").value +
      ws.getCell("S77").value +
      ws.getCell("S78").value +
      ws.getCell("S80").value +
      ws.getCell("S81").value +
      ws.getCell("S82").value +
      ws.getCell("S83").value +
      ws.getCell("S84").value +
      ws.getCell("S85").value +
      ws.getCell("S86").value +
      ws.getCell("S87").value +
      ws.getCell("S88").value +
      ws.getCell("S89").value;
    ws.getCell("S91").value = Math.floor(ws.getCell("S90").value / 16) || 0;

    ws.getCell("T73").value =
      getOffBoradCount(week_data5, "TG > MFC", "10:15") || 0;
    ws.getCell("T74").value =
      getOffBoradCount(week_data5, "TG > MFC", "10:45") || 0;
    ws.getCell("T75").value =
      getOffBoradCount(week_data5, "TG > MFC", "11:15") || 0;
    ws.getCell("T76").value =
      getOffBoradCount(week_data5, "TG > MFC", "11:45") || 0;
    ws.getCell("T77").value =
      getOffBoradCount(week_data5, "TG > MFC", "12:15") || 0;
    ws.getCell("T78").value =
      getOffBoradCount(week_data5, "TG > MFC", "12:45") || 0;
    ws.getCell("T80").value =
      getOffBoradCount(week_data5, "TG > MFC", "13:45") || 0;
    ws.getCell("T81").value =
      getOffBoradCount(week_data5, "TG > MFC", "14:15") || 0;
    ws.getCell("T82").value =
      getOffBoradCount(week_data5, "TG > MFC", "14:45") || 0;
    ws.getCell("T83").value =
      getOffBoradCount(week_data5, "TG > MFC", "15:15") || 0;
    ws.getCell("T84").value =
      getOffBoradCount(week_data5, "TG > MFC", "15:45") || 0;
    ws.getCell("T85").value =
      getOffBoradCount(week_data5, "TG > MFC", "16:15") || 0;
    ws.getCell("T86").value =
      getOffBoradCount(week_data5, "TG > MFC", "16:45") || 0;
    ws.getCell("T87").value =
      getOffBoradCount(week_data5, "TG > MFC", "17:15") || 0;
    ws.getCell("T88").value =
      getOffBoradCount(week_data5, "TG > MFC", "17:45") || 0;
    ws.getCell("T89").value =
      getOffBoradCount(week_data5, "TG > MFC", "18:15") || 0;
    ws.getCell("T90").value =
      ws.getCell("T73").value +
      ws.getCell("T74").value +
      ws.getCell("T75").value +
      ws.getCell("T76").value +
      ws.getCell("T77").value +
      ws.getCell("T78").value +
      ws.getCell("T80").value +
      ws.getCell("T81").value +
      ws.getCell("T82").value +
      ws.getCell("T83").value +
      ws.getCell("T84").value +
      ws.getCell("T85").value +
      ws.getCell("T86").value +
      ws.getCell("T87").value +
      ws.getCell("T88").value +
      ws.getCell("T89").value;
    ws.getCell("T91").value = Math.floor(ws.getCell("T90").value / 16) || 0;

    ws.getCell("U73").value =
      getOnBoradCount(week_data5, "MFC > MT", "10:35") || 0;
    ws.getCell("U74").value =
      getOnBoradCount(week_data5, "MFC > MT", "11:05") || 0;
    ws.getCell("U75").value =
      getOnBoradCount(week_data5, "MFC > MT", "11:35") || 0;
    ws.getCell("U76").value =
      getOnBoradCount(week_data5, "MFC > MT", "12:05") || 0;
    ws.getCell("U77").value =
      getOnBoradCount(week_data5, "MFC > MT", "12:35") || 0;
    ws.getCell("U78").value =
      getOnBoradCount(week_data5, "MFC > MT", "13:05") || 0;
    ws.getCell("U79").value =
      getOnBoradCount(week_data5, "MFC > MT", "13:45") || 0;
    ws.getCell("U80").value =
      getOnBoradCount(week_data5, "MFC > MT", "14:05") || 0;
    ws.getCell("U81").value =
      getOnBoradCount(week_data5, "MFC > MT", "14:35") || 0;
    ws.getCell("U82").value =
      getOnBoradCount(week_data5, "MFC > MT", "15:05") || 0;
    ws.getCell("U83").value =
      getOnBoradCount(week_data5, "MFC > MT", "15:35") || 0;
    ws.getCell("U84").value =
      getOnBoradCount(week_data5, "MFC > MT", "16:05") || 0;
    ws.getCell("U85").value =
      getOnBoradCount(week_data5, "MFC > MT", "16:35") || 0;
    ws.getCell("U86").value =
      getOnBoradCount(week_data5, "MFC > MT", "17:05") || 0;
    ws.getCell("U87").value =
      getOnBoradCount(week_data5, "MFC > MT", "17:35") || 0;
    ws.getCell("U88").value =
      getOnBoradCount(week_data5, "MFC > MT", "18:05") || 0;
    ws.getCell("U89").value =
      getOnBoradCount(week_data5, "MFC > MT", "18:35") || 0;
    ws.getCell("U90").value =
      ws.getCell("U73").value +
      ws.getCell("U74").value +
      ws.getCell("U75").value +
      ws.getCell("U76").value +
      ws.getCell("U77").value +
      ws.getCell("U78").value +
      ws.getCell("U80").value +
      ws.getCell("U81").value +
      ws.getCell("U82").value +
      ws.getCell("U83").value +
      ws.getCell("U84").value +
      ws.getCell("U85").value +
      ws.getCell("U86").value +
      ws.getCell("U87").value +
      ws.getCell("U88").value +
      ws.getCell("U89").value;
    ws.getCell("U91").value = Math.floor(ws.getCell("U90").value / 16) || 0;

    ws.getCell("V73").value =
      ws.getCell("S73").value -
      ws.getCell("T73").value +
      ws.getCell("U73").value;
    ws.getCell("V74").value =
      ws.getCell("S74").value -
      ws.getCell("T74").value +
      ws.getCell("U74").value;
    ws.getCell("V75").value =
      ws.getCell("S75").value -
      ws.getCell("T75").value +
      ws.getCell("U75").value;
    ws.getCell("V76").value =
      ws.getCell("S76").value -
      ws.getCell("T76").value +
      ws.getCell("U76").value;
    ws.getCell("V77").value =
      ws.getCell("S77").value -
      ws.getCell("T77").value +
      ws.getCell("U77").value;
    ws.getCell("V78").value =
      ws.getCell("S78").value -
      ws.getCell("T78").value +
      ws.getCell("U78").value;
    ws.getCell("V79").value = ws.getCell("T79").value + ws.getCell("U79").value;
    ws.getCell("V80").value =
      ws.getCell("S80").value -
      ws.getCell("T80").value +
      ws.getCell("U80").value;
    ws.getCell("V81").value =
      ws.getCell("S81").value -
      ws.getCell("T81").value +
      ws.getCell("U81").value;
    ws.getCell("V82").value =
      ws.getCell("S82").value -
      ws.getCell("T82").value +
      ws.getCell("U82").value;
    ws.getCell("V83").value =
      ws.getCell("S83").value -
      ws.getCell("T83").value +
      ws.getCell("U83").value;
    ws.getCell("V84").value =
      ws.getCell("S84").value -
      ws.getCell("T84").value +
      ws.getCell("U84").value;
    ws.getCell("V85").value =
      ws.getCell("S85").value -
      ws.getCell("T85").value +
      ws.getCell("U85").value;
    ws.getCell("V86").value =
      ws.getCell("S86").value -
      ws.getCell("T86").value +
      ws.getCell("U86").value;
    ws.getCell("V87").value =
      ws.getCell("S87").value -
      ws.getCell("T87").value +
      ws.getCell("U87").value;
    ws.getCell("V88").value =
      ws.getCell("S88").value -
      ws.getCell("T88").value +
      ws.getCell("U88").value;
    ws.getCell("V89").value =
      ws.getCell("S89").value -
      ws.getCell("T89").value +
      ws.getCell("U89").value;
    ws.getCell("V90").value =
      ws.getCell("V73").value +
      ws.getCell("V74").value +
      ws.getCell("V75").value +
      ws.getCell("V76").value +
      ws.getCell("V77").value +
      ws.getCell("V78").value +
      ws.getCell("V80").value +
      ws.getCell("V81").value +
      ws.getCell("V82").value +
      ws.getCell("V83").value +
      ws.getCell("V84").value +
      ws.getCell("V85").value +
      ws.getCell("V86").value +
      ws.getCell("V87").value +
      ws.getCell("V88").value +
      ws.getCell("V89").value;
    ws.getCell("V91").value = Math.floor(ws.getCell("V90").value / 16) || 0;

    ws.getCell("W73").value =
      ws.getCell("C73").value +
      ws.getCell("G73").value +
      ws.getCell("K73").value +
      ws.getCell("O73").value +
      ws.getCell("S73").value;
    ws.getCell("W74").value =
      ws.getCell("C74").value +
      ws.getCell("G74").value +
      ws.getCell("K74").value +
      ws.getCell("O74").value +
      ws.getCell("S74").value;
    ws.getCell("W75").value =
      ws.getCell("C75").value +
      ws.getCell("G75").value +
      ws.getCell("K75").value +
      ws.getCell("O75").value +
      ws.getCell("S75").value;
    ws.getCell("W76").value =
      ws.getCell("C76").value +
      ws.getCell("G76").value +
      ws.getCell("K76").value +
      ws.getCell("O76").value +
      ws.getCell("S76").value;
    ws.getCell("W77").value =
      ws.getCell("C77").value +
      ws.getCell("G77").value +
      ws.getCell("K77").value +
      ws.getCell("O77").value +
      ws.getCell("S77").value;
    ws.getCell("W78").value =
      ws.getCell("C78").value +
      ws.getCell("G78").value +
      ws.getCell("K78").value +
      ws.getCell("O78").value +
      ws.getCell("S78").value;
    ws.getCell("W79").value = 0;
    ws.getCell("W80").value =
      ws.getCell("C80").value +
      ws.getCell("G80").value +
      ws.getCell("K80").value +
      ws.getCell("O80").value +
      ws.getCell("S80").value;
    ws.getCell("W81").value =
      ws.getCell("C81").value +
      ws.getCell("G81").value +
      ws.getCell("K81").value +
      ws.getCell("O81").value +
      ws.getCell("S81").value;
    ws.getCell("W82").value =
      ws.getCell("C82").value +
      ws.getCell("G82").value +
      ws.getCell("K82").value +
      ws.getCell("O82").value +
      ws.getCell("S82").value;
    ws.getCell("W83").value =
      ws.getCell("C83").value +
      ws.getCell("G83").value +
      ws.getCell("K83").value +
      ws.getCell("O83").value +
      ws.getCell("S83").value;
    ws.getCell("W84").value =
      ws.getCell("C84").value +
      ws.getCell("G84").value +
      ws.getCell("K84").value +
      ws.getCell("O84").value +
      ws.getCell("S84").value;
    ws.getCell("W85").value =
      ws.getCell("C85").value +
      ws.getCell("G85").value +
      ws.getCell("K85").value +
      ws.getCell("O85").value +
      ws.getCell("S85").value;
    ws.getCell("W86").value =
      ws.getCell("C86").value +
      ws.getCell("G86").value +
      ws.getCell("K86").value +
      ws.getCell("O86").value +
      ws.getCell("S86").value;
    ws.getCell("W87").value =
      ws.getCell("C87").value +
      ws.getCell("G87").value +
      ws.getCell("K87").value +
      ws.getCell("O87").value +
      ws.getCell("S87").value;
    ws.getCell("W88").value =
      ws.getCell("C88").value +
      ws.getCell("G88").value +
      ws.getCell("K88").value +
      ws.getCell("O88").value +
      ws.getCell("S88").value;
    ws.getCell("W89").value =
      ws.getCell("C89").value +
      ws.getCell("G89").value +
      ws.getCell("K89").value +
      ws.getCell("O89").value +
      ws.getCell("S89").value;
    ws.getCell("W90").value =
      ws.getCell("C90").value +
      ws.getCell("G90").value +
      ws.getCell("K90").value +
      ws.getCell("O90").value +
      ws.getCell("S90").value;
    ws.getCell("W91").value =
      ws.getCell("C91").value +
      ws.getCell("G91").value +
      ws.getCell("K91").value +
      ws.getCell("O91").value +
      ws.getCell("S91").value;

    ws.getCell("X73").value =
      Math.floor(ws.getCell("W73").value / weekTotal) || 0;
    ws.getCell("X74").value =
      Math.floor(ws.getCell("W74").value / weekTotal) || 0;
    ws.getCell("X75").value =
      Math.floor(ws.getCell("W75").value / weekTotal) || 0;
    ws.getCell("X76").value =
      Math.floor(ws.getCell("W76").value / weekTotal) || 0;
    ws.getCell("X77").value =
      Math.floor(ws.getCell("W77").value / weekTotal) || 0;
    ws.getCell("X78").value =
      Math.floor(ws.getCell("W78").value / weekTotal) || 0;
    ws.getCell("X79").value = 0;
    ws.getCell("X80").value =
      Math.floor(ws.getCell("W80").value / weekTotal) || 0;
    ws.getCell("X81").value =
      Math.floor(ws.getCell("W81").value / weekTotal) || 0;
    ws.getCell("X82").value =
      Math.floor(ws.getCell("W82").value / weekTotal) || 0;
    ws.getCell("X83").value =
      Math.floor(ws.getCell("W83").value / weekTotal) || 0;
    ws.getCell("X84").value =
      Math.floor(ws.getCell("W84").value / weekTotal) || 0;
    ws.getCell("X85").value =
      Math.floor(ws.getCell("W85").value / weekTotal) || 0;
    ws.getCell("X86").value =
      Math.floor(ws.getCell("W86").value / weekTotal) || 0;
    ws.getCell("X87").value =
      Math.floor(ws.getCell("W87").value / weekTotal) || 0;
    ws.getCell("X88").value =
      Math.floor(ws.getCell("W88").value / weekTotal) || 0;
    ws.getCell("X89").value =
      Math.floor(ws.getCell("W89").value / weekTotal) || 0;
    ws.getCell("X90").value =
      Math.floor(ws.getCell("W90").value / weekTotal) || 0;
    ws.getCell("X91").value =
      Math.floor(ws.getCell("W91").value / weekTotal) || 0;

    ws.getCell("Y73").value =
      (ws.getCell("C73").value > 22 ? 1 : 0) +
      (ws.getCell("G73").value > 22 ? 1 : 0) +
      (ws.getCell("K73").value > 22 ? 1 : 0) +
      (ws.getCell("O73").value > 22 ? 1 : 0) +
      (ws.getCell("S73").value > 22 ? 1 : 0);
    ws.getCell("Y74").value =
      (ws.getCell("C74").value > 22 ? 1 : 0) +
      (ws.getCell("G74").value > 22 ? 1 : 0) +
      (ws.getCell("K74").value > 22 ? 1 : 0) +
      (ws.getCell("O74").value > 22 ? 1 : 0) +
      (ws.getCell("S74").value > 22 ? 1 : 0);
    ws.getCell("Y75").value =
      (ws.getCell("C75").value > 22 ? 1 : 0) +
      (ws.getCell("G75").value > 22 ? 1 : 0) +
      (ws.getCell("K75").value > 22 ? 1 : 0) +
      (ws.getCell("O75").value > 22 ? 1 : 0) +
      (ws.getCell("S75").value > 22 ? 1 : 0);
    ws.getCell("Y76").value =
      (ws.getCell("C76").value > 22 ? 1 : 0) +
      (ws.getCell("G76").value > 22 ? 1 : 0) +
      (ws.getCell("K76").value > 22 ? 1 : 0) +
      (ws.getCell("O76").value > 22 ? 1 : 0) +
      (ws.getCell("S76").value > 22 ? 1 : 0);
    ws.getCell("Y77").value =
      (ws.getCell("C77").value > 22 ? 1 : 0) +
      (ws.getCell("G77").value > 22 ? 1 : 0) +
      (ws.getCell("K77").value > 22 ? 1 : 0) +
      (ws.getCell("O77").value > 22 ? 1 : 0) +
      (ws.getCell("S77").value > 22 ? 1 : 0);
    ws.getCell("Y78").value = (ws.getCell("C78").value > 22 ? 1 : 0) + (ws.getCell("G78").value > 22 ? 1 : 0) + (ws.getCell("K78").value > 22 ? 1 : 0) + (ws.getCell("O78").value > 22 ? 1 : 0) + (ws.getCell("S78").value > 22 ? 1 : 0);
    ws.getCell("Y79").value = 0;
    ws.getCell("Y80").value = (ws.getCell("C80").value > 22 ? 1 : 0) + (ws.getCell("G80").value > 22 ? 1 : 0) + (ws.getCell("K80").value > 22 ? 1 : 0) + (ws.getCell("O80").value > 22 ? 1 : 0) + (ws.getCell("S80").value > 22 ? 1 : 0);
    ws.getCell("Y81").value = (ws.getCell("C81").value > 22 ? 1 : 0) + (ws.getCell("G81").value > 22 ? 1 : 0) + (ws.getCell("K81").value > 22 ? 1 : 0) + (ws.getCell("O81").value > 22 ? 1 : 0) + (ws.getCell("S81").value > 22 ? 1 : 0);
    ws.getCell("Y82").value = (ws.getCell("C82").value > 22 ? 1 : 0) + (ws.getCell("G82").value > 22 ? 1 : 0) + (ws.getCell("K82").value > 22 ? 1 : 0) + (ws.getCell("O82").value > 22 ? 1 : 0) + (ws.getCell("S82").value > 22 ? 1 : 0);
    ws.getCell("Y83").value = (ws.getCell("C83").value > 22 ? 1 : 0) + (ws.getCell("G83").value > 22 ? 1 : 0) + (ws.getCell("K83").value > 22 ? 1 : 0) + (ws.getCell("O83").value > 22 ? 1 : 0) + (ws.getCell("S83").value > 22 ? 1 : 0);
    ws.getCell("Y84").value = (ws.getCell("C84").value > 22 ? 1 : 0) + (ws.getCell("G84").value > 22 ? 1 : 0) + (ws.getCell("K84").value > 22 ? 1 : 0) + (ws.getCell("O84").value > 22 ? 1 : 0) + (ws.getCell("S84").value > 22 ? 1 : 0);
    ws.getCell("Y85").value = (ws.getCell("C85").value > 22 ? 1 : 0) + (ws.getCell("G85").value > 22 ? 1 : 0) + (ws.getCell("K85").value > 22 ? 1 : 0) + (ws.getCell("O85").value > 22 ? 1 : 0) + (ws.getCell("S85").value > 22 ? 1 : 0);
    ws.getCell("Y86").value = (ws.getCell("C86").value > 22 ? 1 : 0) + (ws.getCell("G86").value > 22 ? 1 : 0) + (ws.getCell("K86").value > 22 ? 1 : 0) + (ws.getCell("O86").value > 22 ? 1 : 0) + (ws.getCell("S86").value > 22 ? 1 : 0);
    ws.getCell("Y87").value = (ws.getCell("C87").value > 22 ? 1 : 0) + (ws.getCell("G87").value > 22 ? 1 : 0) + (ws.getCell("K87").value > 22 ? 1 : 0) + (ws.getCell("O87").value > 22 ? 1 : 0) + (ws.getCell("S87").value > 22 ? 1 : 0);
    ws.getCell("Y88").value = (ws.getCell("C88").value > 22 ? 1 : 0) + (ws.getCell("G88").value > 22 ? 1 : 0) + (ws.getCell("K88").value > 22 ? 1 : 0) + (ws.getCell("O88").value > 22 ? 1 : 0) + (ws.getCell("S88").value > 22 ? 1 : 0);
    ws.getCell("Y89").value = (ws.getCell("C89").value > 22 ? 1 : 0) + (ws.getCell("G89").value > 22 ? 1 : 0) + (ws.getCell("K89").value > 22 ? 1 : 0) + (ws.getCell("O89").value > 22 ? 1 : 0) + (ws.getCell("S89").value > 22 ? 1 : 0);

    ws.getCell("Z73").value = (Math.floor((ws.getCell("Y73").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z74").value = (Math.floor((ws.getCell("Y74").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z75").value = (Math.floor((ws.getCell("Y75").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z76").value = (Math.floor((ws.getCell("Y76").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z77").value = (Math.floor((ws.getCell("Y77").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z78").value = (Math.floor((ws.getCell("Y78").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z79").value = (Math.floor((ws.getCell("Y79").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z80").value = (Math.floor((ws.getCell("Y80").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z81").value = (Math.floor((ws.getCell("Y81").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z82").value = (Math.floor((ws.getCell("Y82").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z83").value = (Math.floor((ws.getCell("Y83").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z84").value = (Math.floor((ws.getCell("Y84").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z85").value = (Math.floor((ws.getCell("Y85").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z86").value = (Math.floor((ws.getCell("Y86").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z87").value = (Math.floor((ws.getCell("Y87").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z88").value = (Math.floor((ws.getCell("Y88").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z89").value = (Math.floor((ws.getCell("Y89").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA73").value = (ws.getCell("C73").value > 22 ? 1 : 0) + (ws.getCell("G73").value > 22 ? 1 : 0) + (ws.getCell("K73").value > 22 ? 1 : 0) + (ws.getCell("O73").value > 22 ? 1 : 0) + (ws.getCell("S73").value > 22 ? 1 : 0);
    ws.getCell("AA74").value = (ws.getCell("C74").value > 22 ? 1 : 0) + (ws.getCell("G74").value > 22 ? 1 : 0) + (ws.getCell("K74").value > 22 ? 1 : 0) + (ws.getCell("O74").value > 22 ? 1 : 0) + (ws.getCell("S74").value > 22 ? 1 : 0);
    ws.getCell("AA75").value = (ws.getCell("C75").value > 22 ? 1 : 0) + (ws.getCell("G75").value > 22 ? 1 : 0) + (ws.getCell("K75").value > 22 ? 1 : 0) + (ws.getCell("O75").value > 22 ? 1 : 0) + (ws.getCell("S75").value > 22 ? 1 : 0);
    ws.getCell("AA76").value = (ws.getCell("C76").value > 22 ? 1 : 0) + (ws.getCell("G76").value > 22 ? 1 : 0) + (ws.getCell("K76").value > 22 ? 1 : 0) + (ws.getCell("O76").value > 22 ? 1 : 0) + (ws.getCell("S76").value > 22 ? 1 : 0);
    ws.getCell("AA77").value = (ws.getCell("C77").value > 22 ? 1 : 0) + (ws.getCell("G77").value > 22 ? 1 : 0) + (ws.getCell("K77").value > 22 ? 1 : 0) + (ws.getCell("O77").value > 22 ? 1 : 0) + (ws.getCell("S77").value > 22 ? 1 : 0);
    ws.getCell("AA78").value = (ws.getCell("C78").value > 22 ? 1 : 0) + (ws.getCell("G78").value > 22 ? 1 : 0) + (ws.getCell("K78").value > 22 ? 1 : 0) + (ws.getCell("O78").value > 22 ? 1 : 0) + (ws.getCell("S78").value > 22 ? 1 : 0);
    ws.getCell("AA79").value = 0;
    ws.getCell("AA80").value = (ws.getCell("C80").value > 22 ? 1 : 0) + (ws.getCell("G80").value > 22 ? 1 : 0) + (ws.getCell("K80").value > 22 ? 1 : 0) + (ws.getCell("O80").value > 22 ? 1 : 0) + (ws.getCell("S80").value > 22 ? 1 : 0);
    ws.getCell("AA81").value = (ws.getCell("C81").value > 22 ? 1 : 0) + (ws.getCell("G81").value > 22 ? 1 : 0) + (ws.getCell("K81").value > 22 ? 1 : 0) + (ws.getCell("O81").value > 22 ? 1 : 0) + (ws.getCell("S81").value > 22 ? 1 : 0);
    ws.getCell("AA82").value = (ws.getCell("C82").value > 22 ? 1 : 0) + (ws.getCell("G82").value > 22 ? 1 : 0) + (ws.getCell("K82").value > 22 ? 1 : 0) + (ws.getCell("O82").value > 22 ? 1 : 0) + (ws.getCell("S82").value > 22 ? 1 : 0);
    ws.getCell("AA83").value = (ws.getCell("C83").value > 22 ? 1 : 0) + (ws.getCell("G83").value > 22 ? 1 : 0) + (ws.getCell("K83").value > 22 ? 1 : 0) + (ws.getCell("O83").value > 22 ? 1 : 0) + (ws.getCell("S83").value > 22 ? 1 : 0);
    ws.getCell("AA84").value = (ws.getCell("C84").value > 22 ? 1 : 0) + (ws.getCell("G84").value > 22 ? 1 : 0) + (ws.getCell("K84").value > 22 ? 1 : 0) + (ws.getCell("O84").value > 22 ? 1 : 0) + (ws.getCell("S84").value > 22 ? 1 : 0);
    ws.getCell("AA85").value = (ws.getCell("C85").value > 22 ? 1 : 0) + (ws.getCell("G85").value > 22 ? 1 : 0) + (ws.getCell("K85").value > 22 ? 1 : 0) + (ws.getCell("O85").value > 22 ? 1 : 0) + (ws.getCell("S85").value > 22 ? 1 : 0);
    ws.getCell("AA86").value = (ws.getCell("C86").value > 22 ? 1 : 0) + (ws.getCell("G86").value > 22 ? 1 : 0) + (ws.getCell("K86").value > 22 ? 1 : 0) + (ws.getCell("O86").value > 22 ? 1 : 0) + (ws.getCell("S86").value > 22 ? 1 : 0);
    ws.getCell("AA87").value = (ws.getCell("C87").value > 22 ? 1 : 0) + (ws.getCell("G87").value > 22 ? 1 : 0) + (ws.getCell("K87").value > 22 ? 1 : 0) + (ws.getCell("O87").value > 22 ? 1 : 0) + (ws.getCell("S87").value > 22 ? 1 : 0);
    ws.getCell("AA88").value = (ws.getCell("C88").value > 22 ? 1 : 0) + (ws.getCell("G88").value > 22 ? 1 : 0) + (ws.getCell("K88").value > 22 ? 1 : 0) + (ws.getCell("O88").value > 22 ? 1 : 0) + (ws.getCell("S88").value > 22 ? 1 : 0);
    ws.getCell("AA89").value = (ws.getCell("C89").value > 22 ? 1 : 0) + (ws.getCell("G89").value > 22 ? 1 : 0) + (ws.getCell("K89").value > 22 ? 1 : 0) + (ws.getCell("O89").value > 22 ? 1 : 0) + (ws.getCell("S89").value > 22 ? 1 : 0);

    ws.getCell("AB73").value = (Math.floor((ws.getCell("AA73").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB74").value = (Math.floor((ws.getCell("AA74").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB75").value = (Math.floor((ws.getCell("AA75").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB76").value = (Math.floor((ws.getCell("AA76").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB77").value = (Math.floor((ws.getCell("AA77").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB78").value = (Math.floor((ws.getCell("AA78").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB79").value = (Math.floor((ws.getCell("AA79").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB80").value = (Math.floor((ws.getCell("AA80").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB81").value = (Math.floor((ws.getCell("AA81").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB82").value = (Math.floor((ws.getCell("AA82").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB83").value = (Math.floor((ws.getCell("AA83").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB84").value = (Math.floor((ws.getCell("AA84").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB85").value = (Math.floor((ws.getCell("AA85").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB86").value = (Math.floor((ws.getCell("AA86").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB87").value = (Math.floor((ws.getCell("AA87").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB88").value = (Math.floor((ws.getCell("AA88").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB89").value = (Math.floor((ws.getCell("AA89").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AC73").value = ws.getCell("F73").value + ws.getCell("J73").value + ws.getCell("N73").value + ws.getCell("R73").value + ws.getCell("V73").value;
    ws.getCell("AC74").value = ws.getCell("F74").value + ws.getCell("J74").value + ws.getCell("N74").value + ws.getCell("R74").value + ws.getCell("V74").value;
    ws.getCell("AC75").value = ws.getCell("F75").value + ws.getCell("J75").value + ws.getCell("N75").value + ws.getCell("R75").value + ws.getCell("V75").value;
    ws.getCell("AC76").value = ws.getCell("F76").value + ws.getCell("J76").value + ws.getCell("N76").value + ws.getCell("R76").value + ws.getCell("V76").value;
    ws.getCell("AC77").value = ws.getCell("F77").value + ws.getCell("J77").value + ws.getCell("N77").value + ws.getCell("R77").value + ws.getCell("V77").value;
    ws.getCell("AC78").value = ws.getCell("F78").value + ws.getCell("J78").value + ws.getCell("N78").value + ws.getCell("R78").value + ws.getCell("V78").value;
    ws.getCell("AC79").value = ws.getCell("F79").value + ws.getCell("J79").value + ws.getCell("N79").value + ws.getCell("R79").value + ws.getCell("V79").value;
    ws.getCell("AC80").value = ws.getCell("F80").value + ws.getCell("J80").value + ws.getCell("N80").value + ws.getCell("R80").value + ws.getCell("V80").value;
    ws.getCell("AC81").value = ws.getCell("F81").value + ws.getCell("J81").value + ws.getCell("N81").value + ws.getCell("R81").value + ws.getCell("V81").value;
    ws.getCell("AC82").value = ws.getCell("F82").value + ws.getCell("J82").value + ws.getCell("N82").value + ws.getCell("R82").value + ws.getCell("V82").value;
    ws.getCell("AC83").value = ws.getCell("F83").value + ws.getCell("J83").value + ws.getCell("N83").value + ws.getCell("R83").value + ws.getCell("V83").value;
    ws.getCell("AC84").value = ws.getCell("F84").value + ws.getCell("J84").value + ws.getCell("N84").value + ws.getCell("R84").value + ws.getCell("V84").value;
    ws.getCell("AC85").value = ws.getCell("F85").value + ws.getCell("J85").value + ws.getCell("N85").value + ws.getCell("R85").value + ws.getCell("V85").value;
    ws.getCell("AC86").value = ws.getCell("F86").value + ws.getCell("J86").value + ws.getCell("N86").value + ws.getCell("R86").value + ws.getCell("V86").value;
    ws.getCell("AC87").value = ws.getCell("F87").value + ws.getCell("J87").value + ws.getCell("N87").value + ws.getCell("R87").value + ws.getCell("V87").value;
    ws.getCell("AC88").value = ws.getCell("F88").value + ws.getCell("J88").value + ws.getCell("N88").value + ws.getCell("R88").value + ws.getCell("V88").value;
    ws.getCell("AC89").value = ws.getCell("F89").value + ws.getCell("J89").value + ws.getCell("N89").value + ws.getCell("R89").value + ws.getCell("V89").value;
    ws.getCell("AC90").value = ws.getCell("F90").value + ws.getCell("J90").value + ws.getCell("N90").value + ws.getCell("R90").value + ws.getCell("V90").value;
    ws.getCell("AC91").value = ws.getCell("F91").value + ws.getCell("J91").value + ws.getCell("N91").value + ws.getCell("R91").value + ws.getCell("V91").value;

    ws.getCell("AD73").value = Math.floor(ws.getCell("AC73").value / weekTotal) || 0;
    ws.getCell("AD74").value = Math.floor(ws.getCell("AC74").value / weekTotal) || 0;
    ws.getCell("AD75").value = Math.floor(ws.getCell("AC75").value / weekTotal) || 0;
    ws.getCell("AD76").value = Math.floor(ws.getCell("AC76").value / weekTotal) || 0;
    ws.getCell("AD77").value = Math.floor(ws.getCell("AC77").value / weekTotal) || 0;
    ws.getCell("AD78").value = Math.floor(ws.getCell("AC78").value / weekTotal) || 0;
    ws.getCell("AD79").value = Math.floor(ws.getCell("AC79").value / weekTotal) || 0;
    ws.getCell("AD80").value = Math.floor(ws.getCell("AC80").value / weekTotal) || 0;
    ws.getCell("AD81").value = Math.floor(ws.getCell("AC81").value / weekTotal) || 0;
    ws.getCell("AD82").value = Math.floor(ws.getCell("AC82").value / weekTotal) || 0;
    ws.getCell("AD83").value = Math.floor(ws.getCell("AC83").value / weekTotal) || 0;
    ws.getCell("AD84").value = Math.floor(ws.getCell("AC84").value / weekTotal) || 0;
    ws.getCell("AD85").value = Math.floor(ws.getCell("AC85").value / weekTotal) || 0;
    ws.getCell("AD86").value = Math.floor(ws.getCell("AC86").value / weekTotal) || 0;
    ws.getCell("AD87").value = Math.floor(ws.getCell("AC87").value / weekTotal) || 0;
    ws.getCell("AD88").value = Math.floor(ws.getCell("AC88").value / weekTotal) || 0;
    ws.getCell("AD89").value = Math.floor(ws.getCell("AC89").value / weekTotal) || 0;
    ws.getCell("AD90").value = Math.floor(ws.getCell("AC90").value / weekTotal) || 0;
    ws.getCell("AD91").value = Math.floor(ws.getCell("AC91").value / weekTotal) || 0;

    ws.getCell("AE73").value = (ws.getCell("F73").value > 22 ? 1 : 0) + (ws.getCell("J73").value > 22 ? 1 : 0) + (ws.getCell("N73").value > 22 ? 1 : 0) + (ws.getCell("R73").value > 22 ? 1 : 0) + (ws.getCell("V73").value > 22 ? 1 : 0);
    ws.getCell("AE74").value = (ws.getCell("F74").value > 22 ? 1 : 0) + (ws.getCell("J74").value > 22 ? 1 : 0) + (ws.getCell("N74").value > 22 ? 1 : 0) + (ws.getCell("R74").value > 22 ? 1 : 0) + (ws.getCell("V74").value > 22 ? 1 : 0);
    ws.getCell("AE75").value = (ws.getCell("F75").value > 22 ? 1 : 0) + (ws.getCell("J75").value > 22 ? 1 : 0) + (ws.getCell("N75").value > 22 ? 1 : 0) + (ws.getCell("R75").value > 22 ? 1 : 0) + (ws.getCell("V75").value > 22 ? 1 : 0);
    ws.getCell("AE76").value = (ws.getCell("F76").value > 22 ? 1 : 0) + (ws.getCell("J76").value > 22 ? 1 : 0) + (ws.getCell("N76").value > 22 ? 1 : 0) + (ws.getCell("R76").value > 22 ? 1 : 0) + (ws.getCell("V76").value > 22 ? 1 : 0);
    ws.getCell("AE77").value = (ws.getCell("F77").value > 22 ? 1 : 0) + (ws.getCell("J77").value > 22 ? 1 : 0) + (ws.getCell("N77").value > 22 ? 1 : 0) + (ws.getCell("R77").value > 22 ? 1 : 0) + (ws.getCell("V77").value > 22 ? 1 : 0);
    ws.getCell("AE78").value = (ws.getCell("F78").value > 22 ? 1 : 0) + (ws.getCell("J78").value > 22 ? 1 : 0) + (ws.getCell("N78").value > 22 ? 1 : 0) + (ws.getCell("R78").value > 22 ? 1 : 0) + (ws.getCell("V78").value > 22 ? 1 : 0);
    ws.getCell("AE79").value = (ws.getCell("F79").value > 22 ? 1 : 0) + (ws.getCell("J79").value > 22 ? 1 : 0) + (ws.getCell("N79").value > 22 ? 1 : 0) + (ws.getCell("R79").value > 22 ? 1 : 0) + (ws.getCell("V79").value > 22 ? 1 : 0);
    ws.getCell("AE80").value = (ws.getCell("F80").value > 22 ? 1 : 0) + (ws.getCell("J80").value > 22 ? 1 : 0) + (ws.getCell("N80").value > 22 ? 1 : 0) + (ws.getCell("R80").value > 22 ? 1 : 0) + (ws.getCell("V80").value > 22 ? 1 : 0);
    ws.getCell("AE81").value = (ws.getCell("F81").value > 22 ? 1 : 0) + (ws.getCell("J81").value > 22 ? 1 : 0) + (ws.getCell("N81").value > 22 ? 1 : 0) + (ws.getCell("R81").value > 22 ? 1 : 0) + (ws.getCell("V81").value > 22 ? 1 : 0);
    ws.getCell("AE82").value = (ws.getCell("F82").value > 22 ? 1 : 0) + (ws.getCell("J82").value > 22 ? 1 : 0) + (ws.getCell("N82").value > 22 ? 1 : 0) + (ws.getCell("R82").value > 22 ? 1 : 0) + (ws.getCell("V82").value > 22 ? 1 : 0);
    ws.getCell("AE83").value = (ws.getCell("F83").value > 22 ? 1 : 0) + (ws.getCell("J83").value > 22 ? 1 : 0) + (ws.getCell("N83").value > 22 ? 1 : 0) + (ws.getCell("R83").value > 22 ? 1 : 0) + (ws.getCell("V83").value > 22 ? 1 : 0);
    ws.getCell("AE84").value = (ws.getCell("F84").value > 22 ? 1 : 0) + (ws.getCell("J84").value > 22 ? 1 : 0) + (ws.getCell("N84").value > 22 ? 1 : 0) + (ws.getCell("R84").value > 22 ? 1 : 0) + (ws.getCell("V84").value > 22 ? 1 : 0);
    ws.getCell("AE85").value = (ws.getCell("F85").value > 22 ? 1 : 0) + (ws.getCell("J85").value > 22 ? 1 : 0) + (ws.getCell("N85").value > 22 ? 1 : 0) + (ws.getCell("R85").value > 22 ? 1 : 0) + (ws.getCell("V85").value > 22 ? 1 : 0);
    ws.getCell("AE86").value = (ws.getCell("F86").value > 22 ? 1 : 0) + (ws.getCell("J86").value > 22 ? 1 : 0) + (ws.getCell("N86").value > 22 ? 1 : 0) + (ws.getCell("R86").value > 22 ? 1 : 0) + (ws.getCell("V86").value > 22 ? 1 : 0);
    ws.getCell("AE87").value = (ws.getCell("F87").value > 22 ? 1 : 0) + (ws.getCell("J87").value > 22 ? 1 : 0) + (ws.getCell("N87").value > 22 ? 1 : 0) + (ws.getCell("R87").value > 22 ? 1 : 0) + (ws.getCell("V87").value > 22 ? 1 : 0);
    ws.getCell("AE88").value = (ws.getCell("F88").value > 22 ? 1 : 0) + (ws.getCell("J88").value > 22 ? 1 : 0) + (ws.getCell("N88").value > 22 ? 1 : 0) + (ws.getCell("R88").value > 22 ? 1 : 0) + (ws.getCell("V88").value > 22 ? 1 : 0);
    ws.getCell("AE89").value = (ws.getCell("F89").value > 22 ? 1 : 0) + (ws.getCell("J89").value > 22 ? 1 : 0) + (ws.getCell("N89").value > 22 ? 1 : 0) + (ws.getCell("R89").value > 22 ? 1 : 0) + (ws.getCell("V89").value > 22 ? 1 : 0);

    ws.getCell("AF73").value = (Math.floor((ws.getCell("AE73").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF74").value = (Math.floor((ws.getCell("AE74").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF75").value = (Math.floor((ws.getCell("AE75").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF76").value = (Math.floor((ws.getCell("AE76").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF77").value = (Math.floor((ws.getCell("AE77").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF78").value = (Math.floor((ws.getCell("AE78").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF79").value = (Math.floor((ws.getCell("AE79").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF80").value = (Math.floor((ws.getCell("AE80").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF81").value = (Math.floor((ws.getCell("AE81").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF82").value = (Math.floor((ws.getCell("AE82").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF83").value = (Math.floor((ws.getCell("AE83").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF84").value = (Math.floor((ws.getCell("AE84").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF85").value = (Math.floor((ws.getCell("AE85").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF86").value = (Math.floor((ws.getCell("AE86").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF87").value = (Math.floor((ws.getCell("AE87").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF88").value = (Math.floor((ws.getCell("AE88").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF89").value = (Math.floor((ws.getCell("AE89").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG73").value = (ws.getCell("F73").value === 28 ? 1 : 0) + (ws.getCell("J73").value === 28 ? 1 : 0) + (ws.getCell("N73").value === 28 ? 1 : 0) + (ws.getCell("R73").value === 28 ? 1 : 0) + (ws.getCell("V73").value === 28 ? 1 : 0);
    ws.getCell("AG74").value = (ws.getCell("F74").value === 28 ? 1 : 0) + (ws.getCell("J74").value === 28 ? 1 : 0) + (ws.getCell("N74").value === 28 ? 1 : 0) + (ws.getCell("R74").value === 28 ? 1 : 0) + (ws.getCell("V74").value === 28 ? 1 : 0);
    ws.getCell("AG75").value = (ws.getCell("F75").value === 28 ? 1 : 0) + (ws.getCell("J75").value === 28 ? 1 : 0) + (ws.getCell("N75").value === 28 ? 1 : 0) + (ws.getCell("R75").value === 28 ? 1 : 0) + (ws.getCell("V75").value === 28 ? 1 : 0);
    ws.getCell("AG76").value = (ws.getCell("F76").value === 28 ? 1 : 0) + (ws.getCell("J76").value === 28 ? 1 : 0) + (ws.getCell("N76").value === 28 ? 1 : 0) + (ws.getCell("R76").value === 28 ? 1 : 0) + (ws.getCell("V76").value === 28 ? 1 : 0);
    ws.getCell("AG77").value = (ws.getCell("F77").value === 28 ? 1 : 0) + (ws.getCell("J77").value === 28 ? 1 : 0) + (ws.getCell("N77").value === 28 ? 1 : 0) + (ws.getCell("R77").value === 28 ? 1 : 0) + (ws.getCell("V77").value === 28 ? 1 : 0);
    ws.getCell("AG78").value = (ws.getCell("F78").value === 28 ? 1 : 0) + (ws.getCell("J78").value === 28 ? 1 : 0) + (ws.getCell("N78").value === 28 ? 1 : 0) + (ws.getCell("R78").value === 28 ? 1 : 0) + (ws.getCell("V78").value === 28 ? 1 : 0);
    ws.getCell("AG79").value = (ws.getCell("F79").value === 28 ? 1 : 0) + (ws.getCell("J79").value === 28 ? 1 : 0) + (ws.getCell("N79").value === 28 ? 1 : 0) + (ws.getCell("R79").value === 28 ? 1 : 0) + (ws.getCell("V79").value === 28 ? 1 : 0);
    ws.getCell("AG80").value = (ws.getCell("F80").value === 28 ? 1 : 0) + (ws.getCell("J80").value === 28 ? 1 : 0) + (ws.getCell("N80").value === 28 ? 1 : 0) + (ws.getCell("R80").value === 28 ? 1 : 0) + (ws.getCell("V80").value === 28 ? 1 : 0);
    ws.getCell("AG81").value = (ws.getCell("F81").value === 28 ? 1 : 0) + (ws.getCell("J81").value === 28 ? 1 : 0) + (ws.getCell("N81").value === 28 ? 1 : 0) + (ws.getCell("R81").value === 28 ? 1 : 0) + (ws.getCell("V81").value === 28 ? 1 : 0);
    ws.getCell("AG82").value = (ws.getCell("F82").value === 28 ? 1 : 0) + (ws.getCell("J82").value === 28 ? 1 : 0) + (ws.getCell("N82").value === 28 ? 1 : 0) + (ws.getCell("R82").value === 28 ? 1 : 0) + (ws.getCell("V82").value === 28 ? 1 : 0);
    ws.getCell("AG83").value = (ws.getCell("F83").value === 28 ? 1 : 0) + (ws.getCell("J83").value === 28 ? 1 : 0) + (ws.getCell("N83").value === 28 ? 1 : 0) + (ws.getCell("R83").value === 28 ? 1 : 0) + (ws.getCell("V83").value === 28 ? 1 : 0);
    ws.getCell("AG84").value = (ws.getCell("F84").value === 28 ? 1 : 0) + (ws.getCell("J84").value === 28 ? 1 : 0) + (ws.getCell("N84").value === 28 ? 1 : 0) + (ws.getCell("R84").value === 28 ? 1 : 0) + (ws.getCell("V84").value === 28 ? 1 : 0);
    ws.getCell("AG85").value = (ws.getCell("F85").value === 28 ? 1 : 0) + (ws.getCell("J85").value === 28 ? 1 : 0) + (ws.getCell("N85").value === 28 ? 1 : 0) + (ws.getCell("R85").value === 28 ? 1 : 0) + (ws.getCell("V85").value === 28 ? 1 : 0);
    ws.getCell("AG86").value = (ws.getCell("F86").value === 28 ? 1 : 0) + (ws.getCell("J86").value === 28 ? 1 : 0) + (ws.getCell("N86").value === 28 ? 1 : 0) + (ws.getCell("R86").value === 28 ? 1 : 0) + (ws.getCell("V86").value === 28 ? 1 : 0);
    ws.getCell("AG87").value = (ws.getCell("F87").value === 28 ? 1 : 0) + (ws.getCell("J87").value === 28 ? 1 : 0) + (ws.getCell("N87").value === 28 ? 1 : 0) + (ws.getCell("R87").value === 28 ? 1 : 0) + (ws.getCell("V87").value === 28 ? 1 : 0);
    ws.getCell("AG88").value = (ws.getCell("F88").value === 28 ? 1 : 0) + (ws.getCell("J88").value === 28 ? 1 : 0) + (ws.getCell("N88").value === 28 ? 1 : 0) + (ws.getCell("R88").value === 28 ? 1 : 0) + (ws.getCell("V88").value === 28 ? 1 : 0);
    ws.getCell("AG89").value = (ws.getCell("F89").value === 28 ? 1 : 0) + (ws.getCell("J89").value === 28 ? 1 : 0) + (ws.getCell("N89").value === 28 ? 1 : 0) + (ws.getCell("R89").value === 28 ? 1 : 0) + (ws.getCell("V89").value === 28 ? 1 : 0);

    ws.getCell("AH73").value = (Math.floor((ws.getCell("AG73").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH74").value = (Math.floor((ws.getCell("AG74").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH75").value = (Math.floor((ws.getCell("AG75").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH76").value = (Math.floor((ws.getCell("AG76").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH77").value = (Math.floor((ws.getCell("AG77").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH78").value = (Math.floor((ws.getCell("AG78").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH79").value = (Math.floor((ws.getCell("AG79").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH80").value = (Math.floor((ws.getCell("AG80").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH81").value = (Math.floor((ws.getCell("AG81").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH82").value = (Math.floor((ws.getCell("AG82").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH83").value = (Math.floor((ws.getCell("AG83").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH84").value = (Math.floor((ws.getCell("AG84").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH85").value = (Math.floor((ws.getCell("AG85").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH86").value = (Math.floor((ws.getCell("AG86").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH87").value = (Math.floor((ws.getCell("AG87").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH88").value = (Math.floor((ws.getCell("AG88").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH89").value = (Math.floor((ws.getCell("AG89").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AI73").value = Math.floor((ws.getCell("D73").value + ws.getCell("H73").value + ws.getCell("L73").value + ws.getCell("P73").value + ws.getCell("T73").value) / weekTotal) || 0;
    ws.getCell("AI74").value = Math.floor((ws.getCell("D74").value + ws.getCell("H74").value + ws.getCell("L74").value + ws.getCell("P74").value + ws.getCell("T74").value) / weekTotal) || 0;
    ws.getCell("AI75").value = Math.floor((ws.getCell("D75").value + ws.getCell("H75").value + ws.getCell("L75").value + ws.getCell("P75").value + ws.getCell("T75").value) / weekTotal) || 0;
    ws.getCell("AI76").value = Math.floor((ws.getCell("D76").value + ws.getCell("H76").value + ws.getCell("L76").value + ws.getCell("P76").value + ws.getCell("T76").value) / weekTotal) || 0;
    ws.getCell("AI77").value = Math.floor((ws.getCell("D77").value + ws.getCell("H77").value + ws.getCell("L77").value + ws.getCell("P77").value + ws.getCell("T77").value) / weekTotal) || 0;
    ws.getCell("AI78").value = Math.floor((ws.getCell("D78").value + ws.getCell("H78").value + ws.getCell("L78").value + ws.getCell("P78").value + ws.getCell("T78").value) / weekTotal) || 0;
    ws.getCell("AI79").value = 0;
    ws.getCell("AI80").value = Math.floor((ws.getCell("D80").value + ws.getCell("H80").value + ws.getCell("L80").value + ws.getCell("P80").value + ws.getCell("T80").value) / weekTotal) || 0;
    ws.getCell("AI81").value = Math.floor((ws.getCell("D81").value + ws.getCell("H81").value + ws.getCell("L81").value + ws.getCell("P81").value + ws.getCell("T81").value) / weekTotal) || 0;
    ws.getCell("AI82").value = Math.floor((ws.getCell("D82").value + ws.getCell("H82").value + ws.getCell("L82").value + ws.getCell("P82").value + ws.getCell("T82").value) / weekTotal) || 0;
    ws.getCell("AI83").value = Math.floor((ws.getCell("D83").value + ws.getCell("H83").value + ws.getCell("L83").value + ws.getCell("P83").value + ws.getCell("T83").value) / weekTotal) || 0;
    ws.getCell("AI84").value = Math.floor((ws.getCell("D84").value + ws.getCell("H84").value + ws.getCell("L84").value + ws.getCell("P84").value + ws.getCell("T84").value) / weekTotal) || 0;
    ws.getCell("AI85").value = Math.floor((ws.getCell("D85").value + ws.getCell("H85").value + ws.getCell("L85").value + ws.getCell("P85").value + ws.getCell("T85").value) / weekTotal) || 0;
    ws.getCell("AI86").value = Math.floor((ws.getCell("D86").value + ws.getCell("H86").value + ws.getCell("L86").value + ws.getCell("P86").value + ws.getCell("T86").value) / weekTotal) || 0;
    ws.getCell("AI87").value = Math.floor((ws.getCell("D87").value + ws.getCell("H87").value + ws.getCell("L87").value + ws.getCell("P87").value + ws.getCell("T87").value) / weekTotal) || 0;
    ws.getCell("AI88").value = Math.floor((ws.getCell("D88").value + ws.getCell("H88").value + ws.getCell("L88").value + ws.getCell("P88").value + ws.getCell("T88").value) / weekTotal) || 0;
    ws.getCell("AI89").value = Math.floor((ws.getCell("D89").value + ws.getCell("H89").value + ws.getCell("L89").value + ws.getCell("P89").value + ws.getCell("T89").value) / weekTotal) || 0;
    ws.getCell("AI90").value =
      ws.getCell("AI73").value +
      ws.getCell("AI74").value +
      ws.getCell("AI75").value +
      ws.getCell("AI76").value +
      ws.getCell("AI77").value +
      ws.getCell("AI78").value +
      ws.getCell("AI79").value +
      ws.getCell("AI80").value +
      ws.getCell("AI81").value +
      ws.getCell("AI82").value +
      ws.getCell("AI83").value +
      ws.getCell("AI84").value +
      ws.getCell("AI85").value +
      ws.getCell("AI86").value +
      ws.getCell("AI87").value +
      ws.getCell("AI88").value +
      ws.getCell("AI89").value;
    ws.getCell("AI91").value = Math.floor(ws.getCell("AI90").value / 16) || 0;

    ws.getCell("AJ73").value = Math.floor((ws.getCell("E73").value + ws.getCell("I73").value + ws.getCell("M73").value + ws.getCell("Q73").value + ws.getCell("U73").value) / weekTotal) || 0;
    ws.getCell("AJ74").value = Math.floor((ws.getCell("E74").value + ws.getCell("I74").value + ws.getCell("M74").value + ws.getCell("Q74").value + ws.getCell("U74").value) / weekTotal) || 0;
    ws.getCell("AJ75").value = Math.floor((ws.getCell("E75").value + ws.getCell("I75").value + ws.getCell("M75").value + ws.getCell("Q75").value + ws.getCell("U75").value) / weekTotal) || 0;
    ws.getCell("AJ76").value = Math.floor((ws.getCell("E76").value + ws.getCell("I76").value + ws.getCell("M76").value + ws.getCell("Q76").value + ws.getCell("U76").value) / weekTotal) || 0;
    ws.getCell("AJ77").value = Math.floor((ws.getCell("E77").value + ws.getCell("I77").value + ws.getCell("M77").value + ws.getCell("Q77").value + ws.getCell("U77").value) / weekTotal) || 0;
    ws.getCell("AJ78").value = Math.floor((ws.getCell("E78").value + ws.getCell("I78").value + ws.getCell("M78").value + ws.getCell("Q78").value + ws.getCell("U78").value) / weekTotal) || 0;
    ws.getCell("AJ79").value = Math.floor((ws.getCell("E79").value + ws.getCell("I79").value + ws.getCell("M79").value + ws.getCell("Q79").value + ws.getCell("U79").value) / weekTotal) || 0;
    ws.getCell("AJ80").value = Math.floor((ws.getCell("E80").value + ws.getCell("I80").value + ws.getCell("M80").value + ws.getCell("Q80").value + ws.getCell("U80").value) / weekTotal) || 0;
    ws.getCell("AJ81").value = Math.floor((ws.getCell("E81").value + ws.getCell("I81").value + ws.getCell("M81").value + ws.getCell("Q81").value + ws.getCell("U81").value) / weekTotal) || 0;
    ws.getCell("AJ82").value = Math.floor((ws.getCell("E82").value + ws.getCell("I82").value + ws.getCell("M82").value + ws.getCell("Q82").value + ws.getCell("U82").value) / weekTotal) || 0;
    ws.getCell("AJ83").value = Math.floor((ws.getCell("E83").value + ws.getCell("I83").value + ws.getCell("M83").value + ws.getCell("Q83").value + ws.getCell("U83").value) / weekTotal) || 0;
    ws.getCell("AJ84").value = Math.floor((ws.getCell("E84").value + ws.getCell("I84").value + ws.getCell("M84").value + ws.getCell("Q84").value + ws.getCell("U84").value) / weekTotal) || 0;
    ws.getCell("AJ85").value = Math.floor((ws.getCell("E85").value + ws.getCell("I85").value + ws.getCell("M85").value + ws.getCell("Q85").value + ws.getCell("U85").value) / weekTotal) || 0;
    ws.getCell("AJ86").value = Math.floor((ws.getCell("E86").value + ws.getCell("I86").value + ws.getCell("M86").value + ws.getCell("Q86").value + ws.getCell("U86").value) / weekTotal) || 0;
    ws.getCell("AJ87").value = Math.floor((ws.getCell("E87").value + ws.getCell("I87").value + ws.getCell("M87").value + ws.getCell("Q87").value + ws.getCell("U87").value) / weekTotal) || 0;
    ws.getCell("AJ88").value = Math.floor((ws.getCell("E88").value + ws.getCell("I88").value + ws.getCell("M88").value + ws.getCell("Q88").value + ws.getCell("U88").value) / weekTotal) || 0;
    ws.getCell("AJ89").value = Math.floor((ws.getCell("E89").value + ws.getCell("I89").value + ws.getCell("M89").value + ws.getCell("Q89").value + ws.getCell("U89").value) / weekTotal) || 0;
    ws.getCell("AJ90").value =
      ws.getCell("AJ73").value +
      ws.getCell("AJ74").value +
      ws.getCell("AJ75").value +
      ws.getCell("AJ76").value +
      ws.getCell("AJ77").value +
      ws.getCell("AJ78").value +
      ws.getCell("AJ79").value +
      ws.getCell("AJ80").value +
      ws.getCell("AJ81").value +
      ws.getCell("AJ82").value +
      ws.getCell("AJ83").value +
      ws.getCell("AJ84").value +
      ws.getCell("AJ85").value +
      ws.getCell("AJ86").value +
      ws.getCell("AJ87").value +
      ws.getCell("AJ88").value +
      ws.getCell("AJ89").value;
    ws.getCell("AJ91").value = Math.floor(ws.getCell("AJ90").value / 16) || 0;

    // ROUTE - 7
    ws.getCell("C96").value = getOnBoradCount(week_data1, "MT > MFC", "10:10");
    ws.getCell("C97").value = getOnBoradCount(week_data1, "MT > MFC", "10:40");
    ws.getCell("C98").value = getOnBoradCount(week_data1, "MT > MFC", "11:10");
    ws.getCell("C99").value = getOnBoradCount(week_data1, "MT > MFC", "11:40");
    ws.getCell("C100").value = getOnBoradCount(week_data1, "MT > MFC", "12:10");
    ws.getCell("C101").value = getOnBoradCount(week_data1, "MT > MFC", "12:40");
    ws.getCell("C102").value = getOnBoradCount(week_data1, "MT > MFC", "14:10");
    ws.getCell("C103").value = getOnBoradCount(week_data1, "MT > MFC", "14:40");
    ws.getCell("C104").value = getOnBoradCount(week_data1, "MT > MFC", "15:10");
    ws.getCell("C105").value = getOnBoradCount(week_data1, "MT > MFC", "15:40");
    ws.getCell("C106").value = getOnBoradCount(week_data1, "MT > MFC", "16:10");
    ws.getCell("C107").value = getOnBoradCount(week_data1, "MT > MFC", "16:40");
    ws.getCell("C108").value = getOnBoradCount(week_data1, "MT > MFC", "17:10");
    ws.getCell("C109").value = getOnBoradCount(week_data1, "MT > MFC", "17:40");
    ws.getCell("C110").value = getOnBoradCount(week_data1, "MT > MFC", "18:10");
    ws.getCell("C111").value =
      ws.getCell("C96").value +
      ws.getCell("C97").value +
      ws.getCell("C98").value +
      ws.getCell("C99").value +
      ws.getCell("C100").value +
      ws.getCell("C101").value +
      ws.getCell("C102").value +
      ws.getCell("C103").value +
      ws.getCell("C104").value +
      ws.getCell("C105").value +
      ws.getCell("C106").value +
      ws.getCell("C107").value +
      ws.getCell("C108").value +
      ws.getCell("C109").value +
      ws.getCell("C110").value;
    ws.getCell("C112").value = Math.floor(ws.getCell("C111").value / 15) || 0;

    ws.getCell("D96").value = getOnBoradCount(week_data2, "MT > MFC", "10:10");
    ws.getCell("D97").value = getOnBoradCount(week_data2, "MT > MFC", "10:40");
    ws.getCell("D98").value = getOnBoradCount(week_data2, "MT > MFC", "11:10");
    ws.getCell("D99").value = getOnBoradCount(week_data2, "MT > MFC", "11:40");
    ws.getCell("D100").value = getOnBoradCount(week_data2, "MT > MFC", "12:10");
    ws.getCell("D101").value = getOnBoradCount(week_data2, "MT > MFC", "12:40");
    ws.getCell("D102").value = getOnBoradCount(week_data2, "MT > MFC", "14:10");
    ws.getCell("D103").value = getOnBoradCount(week_data2, "MT > MFC", "14:40");
    ws.getCell("D104").value = getOnBoradCount(week_data2, "MT > MFC", "15:10");
    ws.getCell("D105").value = getOnBoradCount(week_data2, "MT > MFC", "15:40");
    ws.getCell("D106").value = getOnBoradCount(week_data2, "MT > MFC", "16:10");
    ws.getCell("D107").value = getOnBoradCount(week_data2, "MT > MFC", "16:40");
    ws.getCell("D108").value = getOnBoradCount(week_data2, "MT > MFC", "17:10");
    ws.getCell("D109").value = getOnBoradCount(week_data2, "MT > MFC", "17:40");
    ws.getCell("D110").value = getOnBoradCount(week_data2, "MT > MFC", "18:10");
    ws.getCell("D111").value =
      ws.getCell("D96").value +
      ws.getCell("D97").value +
      ws.getCell("D98").value +
      ws.getCell("D99").value +
      ws.getCell("D100").value +
      ws.getCell("D101").value +
      ws.getCell("D102").value +
      ws.getCell("D103").value +
      ws.getCell("D104").value +
      ws.getCell("D105").value +
      ws.getCell("D106").value +
      ws.getCell("D107").value +
      ws.getCell("D108").value +
      ws.getCell("D109").value +
      ws.getCell("D110").value;
    ws.getCell("D112").value = Math.floor(ws.getCell("D111").value / 15) || 0;

    ws.getCell("E96").value = getOnBoradCount(week_data3, "MT > MFC", "10:10");
    ws.getCell("E97").value = getOnBoradCount(week_data3, "MT > MFC", "10:40");
    ws.getCell("E98").value = getOnBoradCount(week_data3, "MT > MFC", "11:10");
    ws.getCell("E99").value = getOnBoradCount(week_data3, "MT > MFC", "11:40");
    ws.getCell("E100").value = getOnBoradCount(week_data3, "MT > MFC", "12:10");
    ws.getCell("E101").value = getOnBoradCount(week_data3, "MT > MFC", "12:40");
    ws.getCell("E102").value = getOnBoradCount(week_data3, "MT > MFC", "14:10");
    ws.getCell("E103").value = getOnBoradCount(week_data3, "MT > MFC", "14:40");
    ws.getCell("E104").value = getOnBoradCount(week_data3, "MT > MFC", "15:10");
    ws.getCell("E105").value = getOnBoradCount(week_data3, "MT > MFC", "15:40");
    ws.getCell("E106").value = getOnBoradCount(week_data3, "MT > MFC", "16:10");
    ws.getCell("E107").value = getOnBoradCount(week_data3, "MT > MFC", "16:40");
    ws.getCell("E108").value = getOnBoradCount(week_data3, "MT > MFC", "17:10");
    ws.getCell("E109").value = getOnBoradCount(week_data3, "MT > MFC", "17:40");
    ws.getCell("E110").value = getOnBoradCount(week_data3, "MT > MFC", "18:10");
    ws.getCell("E111").value =
      ws.getCell("E96").value +
      ws.getCell("E97").value +
      ws.getCell("E98").value +
      ws.getCell("E99").value +
      ws.getCell("E100").value +
      ws.getCell("E101").value +
      ws.getCell("E102").value +
      ws.getCell("E103").value +
      ws.getCell("E104").value +
      ws.getCell("E105").value +
      ws.getCell("E106").value +
      ws.getCell("E107").value +
      ws.getCell("E108").value +
      ws.getCell("E109").value +
      ws.getCell("E110").value;
    ws.getCell("E112").value = Math.floor(ws.getCell("E111").value / 15) || 0;

    ws.getCell("F96").value = getOnBoradCount(week_data4, "MT > MFC", "10:10");
    ws.getCell("F97").value = getOnBoradCount(week_data4, "MT > MFC", "10:40");
    ws.getCell("F98").value = getOnBoradCount(week_data4, "MT > MFC", "11:10");
    ws.getCell("F99").value = getOnBoradCount(week_data4, "MT > MFC", "11:40");
    ws.getCell("F100").value = getOnBoradCount(week_data4, "MT > MFC", "12:10");
    ws.getCell("F101").value = getOnBoradCount(week_data4, "MT > MFC", "12:40");
    ws.getCell("F102").value = getOnBoradCount(week_data4, "MT > MFC", "14:10");
    ws.getCell("F103").value = getOnBoradCount(week_data4, "MT > MFC", "14:40");
    ws.getCell("F104").value = getOnBoradCount(week_data4, "MT > MFC", "15:10");
    ws.getCell("F105").value = getOnBoradCount(week_data4, "MT > MFC", "15:40");
    ws.getCell("F106").value = getOnBoradCount(week_data4, "MT > MFC", "16:10");
    ws.getCell("F107").value = getOnBoradCount(week_data4, "MT > MFC", "16:40");
    ws.getCell("F108").value = getOnBoradCount(week_data4, "MT > MFC", "17:10");
    ws.getCell("F109").value = getOnBoradCount(week_data4, "MT > MFC", "17:40");
    ws.getCell("F110").value = getOnBoradCount(week_data4, "MT > MFC", "18:10");
    ws.getCell("F111").value =
      ws.getCell("F96").value +
      ws.getCell("F97").value +
      ws.getCell("F98").value +
      ws.getCell("F99").value +
      ws.getCell("F100").value +
      ws.getCell("F101").value +
      ws.getCell("F102").value +
      ws.getCell("F103").value +
      ws.getCell("F104").value +
      ws.getCell("F105").value +
      ws.getCell("F106").value +
      ws.getCell("F107").value +
      ws.getCell("F108").value +
      ws.getCell("F109").value +
      ws.getCell("F110").value;
    ws.getCell("F112").value = Math.floor(ws.getCell("F111").value / 15) || 0;

    ws.getCell("G96").value = getOnBoradCount(week_data5, "MT > MFC", "10:10");
    ws.getCell("G97").value = getOnBoradCount(week_data5, "MT > MFC", "10:40");
    ws.getCell("G98").value = getOnBoradCount(week_data5, "MT > MFC", "11:10");
    ws.getCell("G99").value = getOnBoradCount(week_data5, "MT > MFC", "11:40");
    ws.getCell("G100").value = getOnBoradCount(week_data5, "MT > MFC", "12:10");
    ws.getCell("G101").value = getOnBoradCount(week_data5, "MT > MFC", "12:40");
    ws.getCell("G102").value = getOnBoradCount(week_data5, "MT > MFC", "14:10");
    ws.getCell("G103").value = getOnBoradCount(week_data5, "MT > MFC", "14:40");
    ws.getCell("G104").value = getOnBoradCount(week_data5, "MT > MFC", "15:10");
    ws.getCell("G105").value = getOnBoradCount(week_data5, "MT > MFC", "15:40");
    ws.getCell("G106").value = getOnBoradCount(week_data5, "MT > MFC", "16:10");
    ws.getCell("G107").value = getOnBoradCount(week_data5, "MT > MFC", "16:40");
    ws.getCell("G108").value = getOnBoradCount(week_data5, "MT > MFC", "17:10");
    ws.getCell("G109").value = getOnBoradCount(week_data5, "MT > MFC", "17:40");
    ws.getCell("G110").value = getOnBoradCount(week_data5, "MT > MFC", "18:10");
    ws.getCell("G111").value =
      ws.getCell("G96").value +
      ws.getCell("G97").value +
      ws.getCell("G98").value +
      ws.getCell("G99").value +
      ws.getCell("G100").value +
      ws.getCell("G101").value +
      ws.getCell("G102").value +
      ws.getCell("G103").value +
      ws.getCell("G104").value +
      ws.getCell("G105").value +
      ws.getCell("G106").value +
      ws.getCell("G107").value +
      ws.getCell("G108").value +
      ws.getCell("G109").value +
      ws.getCell("G110").value;
    ws.getCell("G112").value = Math.floor(ws.getCell("G111").value / 15) || 0;

    ws.getCell("H96").value = ws.getCell("C96").value + ws.getCell("D96").value + ws.getCell("E96").value + ws.getCell("F96").value + ws.getCell("G96").value;
    ws.getCell("H97").value = ws.getCell("C97").value + ws.getCell("D97").value + ws.getCell("E97").value + ws.getCell("F97").value + ws.getCell("G97").value;
    ws.getCell("H98").value = ws.getCell("C98").value + ws.getCell("D98").value + ws.getCell("E98").value + ws.getCell("F98").value + ws.getCell("G98").value;
    ws.getCell("H99").value = ws.getCell("C99").value + ws.getCell("D99").value + ws.getCell("E99").value + ws.getCell("F99").value + ws.getCell("G99").value;
    ws.getCell("H100").value = ws.getCell("C100").value + ws.getCell("D100").value + ws.getCell("E100").value + ws.getCell("F100").value + ws.getCell("G100").value;
    ws.getCell("H101").value = ws.getCell("C101").value + ws.getCell("D101").value + ws.getCell("E101").value + ws.getCell("F101").value + ws.getCell("G101").value;
    ws.getCell("H102").value = ws.getCell("C102").value + ws.getCell("D102").value + ws.getCell("E102").value + ws.getCell("F102").value + ws.getCell("G102").value;
    ws.getCell("H103").value = ws.getCell("C103").value + ws.getCell("D103").value + ws.getCell("E103").value + ws.getCell("F103").value + ws.getCell("G103").value;
    ws.getCell("H104").value = ws.getCell("C104").value + ws.getCell("D104").value + ws.getCell("E104").value + ws.getCell("F104").value + ws.getCell("G104").value;
    ws.getCell("H105").value = ws.getCell("C105").value + ws.getCell("D105").value + ws.getCell("E105").value + ws.getCell("F105").value + ws.getCell("G105").value;
    ws.getCell("H106").value = ws.getCell("C106").value + ws.getCell("D106").value + ws.getCell("E106").value + ws.getCell("F106").value + ws.getCell("G106").value;
    ws.getCell("H107").value = ws.getCell("C107").value + ws.getCell("D107").value + ws.getCell("E107").value + ws.getCell("F107").value + ws.getCell("G107").value;
    ws.getCell("H108").value = ws.getCell("C108").value + ws.getCell("D108").value + ws.getCell("E108").value + ws.getCell("F108").value + ws.getCell("G108").value;
    ws.getCell("H109").value = ws.getCell("C109").value + ws.getCell("D109").value + ws.getCell("E109").value + ws.getCell("F109").value + ws.getCell("G109").value;
    ws.getCell("H110").value = ws.getCell("C110").value + ws.getCell("D110").value + ws.getCell("E110").value + ws.getCell("F110").value + ws.getCell("G110").value;
    ws.getCell("H111").value = ws.getCell("C111").value + ws.getCell("D111").value + ws.getCell("E111").value + ws.getCell("F111").value + ws.getCell("G111").value;
    ws.getCell("H112").value = ws.getCell("C112").value + ws.getCell("D112").value + ws.getCell("E112").value + ws.getCell("F112").value + ws.getCell("G112").value;

    ws.getCell("I96").value = Math.floor(ws.getCell("H96").value / weekTotal) || 0;
    ws.getCell("I97").value = Math.floor(ws.getCell("H97").value / weekTotal) || 0;
    ws.getCell("I98").value = Math.floor(ws.getCell("H98").value / weekTotal) || 0;
    ws.getCell("I99").value = Math.floor(ws.getCell("H99").value / weekTotal) || 0;
    ws.getCell("I100").value = Math.floor(ws.getCell("H100").value / weekTotal) || 0;
    ws.getCell("I101").value = Math.floor(ws.getCell("H101").value / weekTotal) || 0;
    ws.getCell("I102").value = Math.floor(ws.getCell("H102").value / weekTotal) || 0;
    ws.getCell("I103").value = Math.floor(ws.getCell("H103").value / weekTotal) || 0;
    ws.getCell("I104").value = Math.floor(ws.getCell("H104").value / weekTotal) || 0;
    ws.getCell("I105").value = Math.floor(ws.getCell("H105").value / weekTotal) || 0;
    ws.getCell("I106").value = Math.floor(ws.getCell("H106").value / weekTotal) || 0;
    ws.getCell("I107").value = Math.floor(ws.getCell("H107").value / weekTotal) || 0;
    ws.getCell("I108").value = Math.floor(ws.getCell("H108").value / weekTotal) || 0;
    ws.getCell("I109").value = Math.floor(ws.getCell("H109").value / weekTotal) || 0;
    ws.getCell("I110").value = Math.floor(ws.getCell("H110").value / weekTotal) || 0;
    ws.getCell("I111").value = Math.floor(ws.getCell("H111").value / weekTotal) || 0;
    ws.getCell("I112").value = Math.floor(ws.getCell("H112").value / weekTotal) || 0;

    ws.getCell("J96").value = (ws.getCell("C96").value > 22 ? 1 : 0) + (ws.getCell("D96").value > 22 ? 1 : 0) + (ws.getCell("E96").value > 22 ? 1 : 0) + (ws.getCell("F96").value > 22 ? 1 : 0) + (ws.getCell("G96").value > 22 ? 1 : 0);
    ws.getCell("J97").value = (ws.getCell("C97").value > 22 ? 1 : 0) + (ws.getCell("D97").value > 22 ? 1 : 0) + (ws.getCell("E97").value > 22 ? 1 : 0) + (ws.getCell("F97").value > 22 ? 1 : 0) + (ws.getCell("G97").value > 22 ? 1 : 0);
    ws.getCell("J98").value = (ws.getCell("C98").value > 22 ? 1 : 0) + (ws.getCell("D98").value > 22 ? 1 : 0) + (ws.getCell("E98").value > 22 ? 1 : 0) + (ws.getCell("F98").value > 22 ? 1 : 0) + (ws.getCell("G98").value > 22 ? 1 : 0);
    ws.getCell("J99").value = (ws.getCell("C99").value > 22 ? 1 : 0) + (ws.getCell("D99").value > 22 ? 1 : 0) + (ws.getCell("E99").value > 22 ? 1 : 0) + (ws.getCell("F99").value > 22 ? 1 : 0) + (ws.getCell("G99").value > 22 ? 1 : 0);
    ws.getCell("J100").value = (ws.getCell("C100").value > 22 ? 1 : 0) + (ws.getCell("D100").value > 22 ? 1 : 0) + (ws.getCell("E100").value > 22 ? 1 : 0) + (ws.getCell("F100").value > 22 ? 1 : 0) + (ws.getCell("G100").value > 22 ? 1 : 0);
    ws.getCell("J101").value = (ws.getCell("C101").value > 22 ? 1 : 0) + (ws.getCell("D101").value > 22 ? 1 : 0) + (ws.getCell("E101").value > 22 ? 1 : 0) + (ws.getCell("F101").value > 22 ? 1 : 0) + (ws.getCell("G101").value > 22 ? 1 : 0);
    ws.getCell("J102").value = (ws.getCell("C102").value > 22 ? 1 : 0) + (ws.getCell("D102").value > 22 ? 1 : 0) + (ws.getCell("E102").value > 22 ? 1 : 0) + (ws.getCell("F102").value > 22 ? 1 : 0) + (ws.getCell("G102").value > 22 ? 1 : 0);
    ws.getCell("J103").value = (ws.getCell("C103").value > 22 ? 1 : 0) + (ws.getCell("D103").value > 22 ? 1 : 0) + (ws.getCell("E103").value > 22 ? 1 : 0) + (ws.getCell("F103").value > 22 ? 1 : 0) + (ws.getCell("G103").value > 22 ? 1 : 0);
    ws.getCell("J104").value = (ws.getCell("C104").value > 22 ? 1 : 0) + (ws.getCell("D104").value > 22 ? 1 : 0) + (ws.getCell("E104").value > 22 ? 1 : 0) + (ws.getCell("F104").value > 22 ? 1 : 0) + (ws.getCell("G104").value > 22 ? 1 : 0);
    ws.getCell("J105").value = (ws.getCell("C105").value > 22 ? 1 : 0) + (ws.getCell("D105").value > 22 ? 1 : 0) + (ws.getCell("E105").value > 22 ? 1 : 0) + (ws.getCell("F105").value > 22 ? 1 : 0) + (ws.getCell("G105").value > 22 ? 1 : 0);
    ws.getCell("J106").value = (ws.getCell("C106").value > 22 ? 1 : 0) + (ws.getCell("D106").value > 22 ? 1 : 0) + (ws.getCell("E106").value > 22 ? 1 : 0) + (ws.getCell("F106").value > 22 ? 1 : 0) + (ws.getCell("G106").value > 22 ? 1 : 0);
    ws.getCell("J107").value = (ws.getCell("C107").value > 22 ? 1 : 0) + (ws.getCell("D107").value > 22 ? 1 : 0) + (ws.getCell("E107").value > 22 ? 1 : 0) + (ws.getCell("F107").value > 22 ? 1 : 0) + (ws.getCell("G107").value > 22 ? 1 : 0);
    ws.getCell("J108").value = (ws.getCell("C108").value > 22 ? 1 : 0) + (ws.getCell("D108").value > 22 ? 1 : 0) + (ws.getCell("E108").value > 22 ? 1 : 0) + (ws.getCell("F108").value > 22 ? 1 : 0) + (ws.getCell("G108").value > 22 ? 1 : 0);
    ws.getCell("J109").value = (ws.getCell("C109").value > 22 ? 1 : 0) + (ws.getCell("D109").value > 22 ? 1 : 0) + (ws.getCell("E109").value > 22 ? 1 : 0) + (ws.getCell("F109").value > 22 ? 1 : 0) + (ws.getCell("G109").value > 22 ? 1 : 0);
    ws.getCell("J110").value = (ws.getCell("C110").value > 22 ? 1 : 0) + (ws.getCell("D110").value > 22 ? 1 : 0) + (ws.getCell("E110").value > 22 ? 1 : 0) + (ws.getCell("F110").value > 22 ? 1 : 0) + (ws.getCell("G110").value > 22 ? 1 : 0);

    ws.getCell("K96").value = (Math.floor((ws.getCell("J96").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K97").value = (Math.floor((ws.getCell("J97").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K98").value = (Math.floor((ws.getCell("J98").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K99").value = (Math.floor((ws.getCell("J99").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K100").value = (Math.floor((ws.getCell("J100").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K101").value = (Math.floor((ws.getCell("J101").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K102").value = (Math.floor((ws.getCell("J102").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K103").value = (Math.floor((ws.getCell("J103").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K104").value = (Math.floor((ws.getCell("J104").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K105").value = (Math.floor((ws.getCell("J105").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K106").value = (Math.floor((ws.getCell("J106").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K107").value = (Math.floor((ws.getCell("J107").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K108").value = (Math.floor((ws.getCell("J108").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K109").value = (Math.floor((ws.getCell("J109").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K110").value = (Math.floor((ws.getCell("J110").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L96").value = (ws.getCell("C96").value === 28 ? 1 : 0) + (ws.getCell("D96").value === 28 ? 1 : 0) + (ws.getCell("E96").value === 28 ? 1 : 0) + (ws.getCell("F96").value === 28 ? 1 : 0) + (ws.getCell("G96").value === 28 ? 1 : 0);
    ws.getCell("L97").value = (ws.getCell("C97").value === 28 ? 1 : 0) + (ws.getCell("D97").value === 28 ? 1 : 0) + (ws.getCell("E97").value === 28 ? 1 : 0) + (ws.getCell("F97").value === 28 ? 1 : 0) + (ws.getCell("G97").value === 28 ? 1 : 0);
    ws.getCell("L98").value = (ws.getCell("C98").value === 28 ? 1 : 0) + (ws.getCell("D98").value === 28 ? 1 : 0) + (ws.getCell("E98").value === 28 ? 1 : 0) + (ws.getCell("F98").value === 28 ? 1 : 0) + (ws.getCell("G98").value === 28 ? 1 : 0);
    ws.getCell("L99").value = (ws.getCell("C99").value === 28 ? 1 : 0) + (ws.getCell("D99").value === 28 ? 1 : 0) + (ws.getCell("E99").value === 28 ? 1 : 0) + (ws.getCell("F99").value === 28 ? 1 : 0) + (ws.getCell("G99").value === 28 ? 1 : 0);
    ws.getCell("L100").value = (ws.getCell("C100").value === 28 ? 1 : 0) + (ws.getCell("D100").value === 28 ? 1 : 0) + (ws.getCell("E100").value === 28 ? 1 : 0) + (ws.getCell("F100").value === 28 ? 1 : 0) + (ws.getCell("G100").value === 28 ? 1 : 0);
    ws.getCell("L101").value = (ws.getCell("C101").value === 28 ? 1 : 0) + (ws.getCell("D101").value === 28 ? 1 : 0) + (ws.getCell("E101").value === 28 ? 1 : 0) + (ws.getCell("F101").value === 28 ? 1 : 0) + (ws.getCell("G101").value === 28 ? 1 : 0);
    ws.getCell("L102").value = (ws.getCell("C102").value === 28 ? 1 : 0) + (ws.getCell("D102").value === 28 ? 1 : 0) + (ws.getCell("E102").value === 28 ? 1 : 0) + (ws.getCell("F102").value === 28 ? 1 : 0) + (ws.getCell("G102").value === 28 ? 1 : 0);
    ws.getCell("L103").value = (ws.getCell("C103").value === 28 ? 1 : 0) + (ws.getCell("D103").value === 28 ? 1 : 0) + (ws.getCell("E103").value === 28 ? 1 : 0) + (ws.getCell("F103").value === 28 ? 1 : 0) + (ws.getCell("G103").value === 28 ? 1 : 0);
    ws.getCell("L104").value = (ws.getCell("C104").value === 28 ? 1 : 0) + (ws.getCell("D104").value === 28 ? 1 : 0) + (ws.getCell("E104").value === 28 ? 1 : 0) + (ws.getCell("F104").value === 28 ? 1 : 0) + (ws.getCell("G104").value === 28 ? 1 : 0);
    ws.getCell("L105").value = (ws.getCell("C105").value === 28 ? 1 : 0) + (ws.getCell("D105").value === 28 ? 1 : 0) + (ws.getCell("E105").value === 28 ? 1 : 0) + (ws.getCell("F105").value === 28 ? 1 : 0) + (ws.getCell("G105").value === 28 ? 1 : 0);
    ws.getCell("L106").value = (ws.getCell("C106").value === 28 ? 1 : 0) + (ws.getCell("D106").value === 28 ? 1 : 0) + (ws.getCell("E106").value === 28 ? 1 : 0) + (ws.getCell("F106").value === 28 ? 1 : 0) + (ws.getCell("G106").value === 28 ? 1 : 0);
    ws.getCell("L107").value = (ws.getCell("C107").value === 28 ? 1 : 0) + (ws.getCell("D107").value === 28 ? 1 : 0) + (ws.getCell("E107").value === 28 ? 1 : 0) + (ws.getCell("F107").value === 28 ? 1 : 0) + (ws.getCell("G107").value === 28 ? 1 : 0);
    ws.getCell("L108").value = (ws.getCell("C108").value === 28 ? 1 : 0) + (ws.getCell("D108").value === 28 ? 1 : 0) + (ws.getCell("E108").value === 28 ? 1 : 0) + (ws.getCell("F108").value === 28 ? 1 : 0) + (ws.getCell("G108").value === 28 ? 1 : 0);
    ws.getCell("L109").value = (ws.getCell("C109").value === 28 ? 1 : 0) + (ws.getCell("D109").value === 28 ? 1 : 0) + (ws.getCell("E109").value === 28 ? 1 : 0) + (ws.getCell("F109").value === 28 ? 1 : 0) + (ws.getCell("G109").value === 28 ? 1 : 0);
    ws.getCell("L110").value = (ws.getCell("C110").value === 28 ? 1 : 0) + (ws.getCell("D110").value === 28 ? 1 : 0) + (ws.getCell("E110").value === 28 ? 1 : 0) + (ws.getCell("F110").value === 28 ? 1 : 0) + (ws.getCell("G110").value === 28 ? 1 : 0);

    ws.getCell("M96").value = (Math.floor((ws.getCell("L96").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M97").value = (Math.floor((ws.getCell("L97").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M98").value = (Math.floor((ws.getCell("L98").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M99").value = (Math.floor((ws.getCell("L99").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M100").value = (Math.floor((ws.getCell("L100").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M101").value = (Math.floor((ws.getCell("L101").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M102").value = (Math.floor((ws.getCell("L102").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M103").value = (Math.floor((ws.getCell("L103").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M104").value = (Math.floor((ws.getCell("L104").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M105").value = (Math.floor((ws.getCell("L105").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M106").value = (Math.floor((ws.getCell("L106").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M107").value = (Math.floor((ws.getCell("L107").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M108").value = (Math.floor((ws.getCell("L108").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M109").value = (Math.floor((ws.getCell("L109").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M110").value = (Math.floor((ws.getCell("L110").value / weekTotal) * 100) || 0) + "%";

    // ROUTE -8
    ws.getCell("C117").value = getOnBoradCount(week_data1, "MFC > MT", "10:10");
    ws.getCell("C118").value = getOnBoradCount(week_data1, "MFC > MT", "10:25");
    ws.getCell("C119").value = getOnBoradCount(week_data1, "MFC > MT", "10:55");
    ws.getCell("C120").value = getOnBoradCount(week_data1, "MFC > MT", "11:25");
    ws.getCell("C121").value = getOnBoradCount(week_data1, "MFC > MT", "11:55");
    ws.getCell("C122").value = getOnBoradCount(week_data1, "MFC > MT", "12:25");
    ws.getCell("C123").value = getOnBoradCount(week_data1, "MFC > MT", "12:55");
    ws.getCell("C124").value = getOnBoradCount(week_data1, "MFC > MT", "14:25");
    ws.getCell("C125").value = getOnBoradCount(week_data1, "MFC > MT", "14:55");
    ws.getCell("C126").value = getOnBoradCount(week_data1, "MFC > MT", "15:25");
    ws.getCell("C127").value = getOnBoradCount(week_data1, "MFC > MT", "15:55");
    ws.getCell("C128").value = getOnBoradCount(week_data1, "MFC > MT", "16:25");
    ws.getCell("C129").value = getOnBoradCount(week_data1, "MFC > MT", "16:55");
    ws.getCell("C130").value = getOnBoradCount(week_data1, "MFC > MT", "17:25");
    ws.getCell("C131").value = getOnBoradCount(week_data1, "MFC > MT", "17:55");
    ws.getCell("C132").value = getOnBoradCount(week_data1, "MFC > MT", "18:25");
    ws.getCell("C133").value =
      ws.getCell("C117").value +
      ws.getCell("C118").value +
      ws.getCell("C119").value +
      ws.getCell("C120").value +
      ws.getCell("C121").value +
      ws.getCell("C122").value +
      ws.getCell("C123").value +
      ws.getCell("C124").value +
      ws.getCell("C125").value +
      ws.getCell("C126").value +
      ws.getCell("C127").value +
      ws.getCell("C128").value +
      ws.getCell("C129").value +
      ws.getCell("C130").value +
      ws.getCell("C131").value +
      ws.getCell("C132").value;
    ws.getCell("C134").value = Math.floor(ws.getCell("C133").value / 16) || 0;

    ws.getCell("D117").value = getOnBoradCount(week_data2, "MFC > MT", "10:10");
    ws.getCell("D118").value = getOnBoradCount(week_data2, "MFC > MT", "10:25");
    ws.getCell("D119").value = getOnBoradCount(week_data2, "MFC > MT", "10:55");
    ws.getCell("D120").value = getOnBoradCount(week_data2, "MFC > MT", "11:25");
    ws.getCell("D121").value = getOnBoradCount(week_data2, "MFC > MT", "11:55");
    ws.getCell("D122").value = getOnBoradCount(week_data2, "MFC > MT", "12:25");
    ws.getCell("D123").value = getOnBoradCount(week_data2, "MFC > MT", "12:55");
    ws.getCell("D124").value = getOnBoradCount(week_data2, "MFC > MT", "14:25");
    ws.getCell("D125").value = getOnBoradCount(week_data2, "MFC > MT", "14:55");
    ws.getCell("D126").value = getOnBoradCount(week_data2, "MFC > MT", "15:25");
    ws.getCell("D127").value = getOnBoradCount(week_data2, "MFC > MT", "15:55");
    ws.getCell("D128").value = getOnBoradCount(week_data2, "MFC > MT", "16:25");
    ws.getCell("D129").value = getOnBoradCount(week_data2, "MFC > MT", "16:55");
    ws.getCell("D130").value = getOnBoradCount(week_data2, "MFC > MT", "17:25");
    ws.getCell("D131").value = getOnBoradCount(week_data2, "MFC > MT", "17:55");
    ws.getCell("D132").value = getOnBoradCount(week_data2, "MFC > MT", "18:25");
    ws.getCell("D133").value =
      ws.getCell("D117").value +
      ws.getCell("D118").value +
      ws.getCell("D119").value +
      ws.getCell("D120").value +
      ws.getCell("D121").value +
      ws.getCell("D122").value +
      ws.getCell("D123").value +
      ws.getCell("D124").value +
      ws.getCell("D125").value +
      ws.getCell("D126").value +
      ws.getCell("D127").value +
      ws.getCell("D128").value +
      ws.getCell("D129").value +
      ws.getCell("D130").value +
      ws.getCell("D131").value +
      ws.getCell("D132").value;
    ws.getCell("D134").value = Math.floor(ws.getCell("D133").value / 16) || 0;

    ws.getCell("E117").value = getOnBoradCount(week_data3, "MFC > MT", "10:10");
    ws.getCell("E118").value = getOnBoradCount(week_data3, "MFC > MT", "10:25");
    ws.getCell("E119").value = getOnBoradCount(week_data3, "MFC > MT", "10:55");
    ws.getCell("E120").value = getOnBoradCount(week_data3, "MFC > MT", "11:25");
    ws.getCell("E121").value = getOnBoradCount(week_data3, "MFC > MT", "11:55");
    ws.getCell("E122").value = getOnBoradCount(week_data3, "MFC > MT", "12:25");
    ws.getCell("E123").value = getOnBoradCount(week_data3, "MFC > MT", "12:55");
    ws.getCell("E124").value = getOnBoradCount(week_data3, "MFC > MT", "14:25");
    ws.getCell("E125").value = getOnBoradCount(week_data3, "MFC > MT", "14:55");
    ws.getCell("E126").value = getOnBoradCount(week_data3, "MFC > MT", "15:25");
    ws.getCell("E127").value = getOnBoradCount(week_data3, "MFC > MT", "15:55");
    ws.getCell("E128").value = getOnBoradCount(week_data3, "MFC > MT", "16:25");
    ws.getCell("E129").value = getOnBoradCount(week_data3, "MFC > MT", "16:55");
    ws.getCell("E130").value = getOnBoradCount(week_data3, "MFC > MT", "17:25");
    ws.getCell("E131").value = getOnBoradCount(week_data3, "MFC > MT", "17:55");
    ws.getCell("E132").value = getOnBoradCount(week_data3, "MFC > MT", "18:25");
    ws.getCell("E133").value =
      ws.getCell("E117").value +
      ws.getCell("E118").value +
      ws.getCell("E119").value +
      ws.getCell("E120").value +
      ws.getCell("E121").value +
      ws.getCell("E122").value +
      ws.getCell("E123").value +
      ws.getCell("E124").value +
      ws.getCell("E125").value +
      ws.getCell("E126").value +
      ws.getCell("E127").value +
      ws.getCell("E128").value +
      ws.getCell("E129").value +
      ws.getCell("E130").value +
      ws.getCell("E131").value +
      ws.getCell("E132").value;
    ws.getCell("E134").value = Math.floor(ws.getCell("E133").value / 16) || 0;

    ws.getCell("F117").value = getOnBoradCount(week_data4, "MFC > MT", "10:10");
    ws.getCell("F118").value = getOnBoradCount(week_data4, "MFC > MT", "10:25");
    ws.getCell("F119").value = getOnBoradCount(week_data4, "MFC > MT", "10:55");
    ws.getCell("F120").value = getOnBoradCount(week_data4, "MFC > MT", "11:25");
    ws.getCell("F121").value = getOnBoradCount(week_data4, "MFC > MT", "11:55");
    ws.getCell("F122").value = getOnBoradCount(week_data4, "MFC > MT", "12:25");
    ws.getCell("F123").value = getOnBoradCount(week_data4, "MFC > MT", "12:55");
    ws.getCell("F124").value = getOnBoradCount(week_data4, "MFC > MT", "14:25");
    ws.getCell("F125").value = getOnBoradCount(week_data4, "MFC > MT", "14:55");
    ws.getCell("F126").value = getOnBoradCount(week_data4, "MFC > MT", "15:25");
    ws.getCell("F127").value = getOnBoradCount(week_data4, "MFC > MT", "15:55");
    ws.getCell("F128").value = getOnBoradCount(week_data4, "MFC > MT", "16:25");
    ws.getCell("F129").value = getOnBoradCount(week_data4, "MFC > MT", "16:55");
    ws.getCell("F130").value = getOnBoradCount(week_data4, "MFC > MT", "17:25");
    ws.getCell("F131").value = getOnBoradCount(week_data4, "MFC > MT", "17:55");
    ws.getCell("F132").value = getOnBoradCount(week_data4, "MFC > MT", "18:25");
    ws.getCell("F133").value =
      ws.getCell("F117").value +
      ws.getCell("F118").value +
      ws.getCell("F119").value +
      ws.getCell("F120").value +
      ws.getCell("F121").value +
      ws.getCell("F122").value +
      ws.getCell("F123").value +
      ws.getCell("F124").value +
      ws.getCell("F125").value +
      ws.getCell("F126").value +
      ws.getCell("F127").value +
      ws.getCell("F128").value +
      ws.getCell("F129").value +
      ws.getCell("F130").value +
      ws.getCell("F131").value +
      ws.getCell("F132").value;
    ws.getCell("F134").value = Math.floor(ws.getCell("F133").value / 16) || 0;

    ws.getCell("G117").value = getOnBoradCount(week_data5, "MFC > MT", "10:10");
    ws.getCell("G118").value = getOnBoradCount(week_data5, "MFC > MT", "10:25");
    ws.getCell("G119").value = getOnBoradCount(week_data5, "MFC > MT", "10:55");
    ws.getCell("G120").value = getOnBoradCount(week_data5, "MFC > MT", "11:25");
    ws.getCell("G121").value = getOnBoradCount(week_data5, "MFC > MT", "11:55");
    ws.getCell("G122").value = getOnBoradCount(week_data5, "MFC > MT", "12:25");
    ws.getCell("G123").value = getOnBoradCount(week_data5, "MFC > MT", "12:55");
    ws.getCell("G124").value = getOnBoradCount(week_data5, "MFC > MT", "14:25");
    ws.getCell("G125").value = getOnBoradCount(week_data5, "MFC > MT", "14:55");
    ws.getCell("G126").value = getOnBoradCount(week_data5, "MFC > MT", "15:25");
    ws.getCell("G127").value = getOnBoradCount(week_data5, "MFC > MT", "15:55");
    ws.getCell("G128").value = getOnBoradCount(week_data5, "MFC > MT", "16:25");
    ws.getCell("G129").value = getOnBoradCount(week_data5, "MFC > MT", "16:55");
    ws.getCell("G130").value = getOnBoradCount(week_data5, "MFC > MT", "17:25");
    ws.getCell("G131").value = getOnBoradCount(week_data5, "MFC > MT", "17:55");
    ws.getCell("G132").value = getOnBoradCount(week_data5, "MFC > MT", "18:25");
    ws.getCell("G133").value =
      ws.getCell("G117").value +
      ws.getCell("G118").value +
      ws.getCell("G119").value +
      ws.getCell("G120").value +
      ws.getCell("G121").value +
      ws.getCell("G122").value +
      ws.getCell("G123").value +
      ws.getCell("G124").value +
      ws.getCell("G125").value +
      ws.getCell("G126").value +
      ws.getCell("G127").value +
      ws.getCell("G128").value +
      ws.getCell("G129").value +
      ws.getCell("G130").value +
      ws.getCell("G131").value +
      ws.getCell("G132").value;
    ws.getCell("G134").value = Math.floor(ws.getCell("G133").value / 16) || 0;

    ws.getCell("H117").value = ws.getCell("C117").value + ws.getCell("D117").value + ws.getCell("E117").value + ws.getCell("F117").value + ws.getCell("G117").value;
    ws.getCell("H118").value = ws.getCell("C118").value + ws.getCell("D118").value + ws.getCell("E118").value + ws.getCell("F118").value + ws.getCell("G118").value;
    ws.getCell("H119").value = ws.getCell("C119").value + ws.getCell("D119").value + ws.getCell("E119").value + ws.getCell("F119").value + ws.getCell("G119").value;
    ws.getCell("H120").value = ws.getCell("C120").value + ws.getCell("D120").value + ws.getCell("E120").value + ws.getCell("F120").value + ws.getCell("G120").value;
    ws.getCell("H121").value = ws.getCell("C121").value + ws.getCell("D121").value + ws.getCell("E121").value + ws.getCell("F121").value + ws.getCell("G121").value;
    ws.getCell("H122").value = ws.getCell("C122").value + ws.getCell("D122").value + ws.getCell("E122").value + ws.getCell("F122").value + ws.getCell("G122").value;
    ws.getCell("H123").value = ws.getCell("C123").value + ws.getCell("D123").value + ws.getCell("E123").value + ws.getCell("F123").value + ws.getCell("G123").value;
    ws.getCell("H124").value = ws.getCell("C124").value + ws.getCell("D124").value + ws.getCell("E124").value + ws.getCell("F124").value + ws.getCell("G124").value;
    ws.getCell("H125").value = ws.getCell("C125").value + ws.getCell("D125").value + ws.getCell("E125").value + ws.getCell("F125").value + ws.getCell("G125").value;
    ws.getCell("H126").value = ws.getCell("C126").value + ws.getCell("D126").value + ws.getCell("E126").value + ws.getCell("F126").value + ws.getCell("G126").value;
    ws.getCell("H127").value = ws.getCell("C127").value + ws.getCell("D127").value + ws.getCell("E127").value + ws.getCell("F127").value + ws.getCell("G127").value;
    ws.getCell("H128").value = ws.getCell("C128").value + ws.getCell("D128").value + ws.getCell("E128").value + ws.getCell("F128").value + ws.getCell("G128").value;
    ws.getCell("H129").value = ws.getCell("C129").value + ws.getCell("D129").value + ws.getCell("E129").value + ws.getCell("F129").value + ws.getCell("G129").value;
    ws.getCell("H130").value = ws.getCell("C130").value + ws.getCell("D130").value + ws.getCell("E130").value + ws.getCell("F130").value + ws.getCell("G130").value;
    ws.getCell("H131").value = ws.getCell("C131").value + ws.getCell("D131").value + ws.getCell("E131").value + ws.getCell("F131").value + ws.getCell("G131").value;
    ws.getCell("H132").value = ws.getCell("C132").value + ws.getCell("D132").value + ws.getCell("E132").value + ws.getCell("F132").value + ws.getCell("G132").value;
    ws.getCell("H133").value = ws.getCell("C133").value + ws.getCell("D133").value + ws.getCell("E133").value + ws.getCell("F133").value + ws.getCell("G133").value;
    ws.getCell("H134").value = ws.getCell("C134").value + ws.getCell("D134").value + ws.getCell("E134").value + ws.getCell("F134").value + ws.getCell("G134").value;

    ws.getCell("I117").value = Math.floor(ws.getCell("H117").value / weekTotal) || 0;
    ws.getCell("I118").value = Math.floor(ws.getCell("H118").value / weekTotal) || 0;
    ws.getCell("I119").value = Math.floor(ws.getCell("H119").value / weekTotal) || 0;
    ws.getCell("I120").value = Math.floor(ws.getCell("H120").value / weekTotal) || 0;
    ws.getCell("I121").value = Math.floor(ws.getCell("H121").value / weekTotal) || 0;
    ws.getCell("I122").value = Math.floor(ws.getCell("H122").value / weekTotal) || 0;
    ws.getCell("I123").value = Math.floor(ws.getCell("H123").value / weekTotal) || 0;
    ws.getCell("I124").value = Math.floor(ws.getCell("H124").value / weekTotal) || 0;
    ws.getCell("I125").value = Math.floor(ws.getCell("H125").value / weekTotal) || 0;
    ws.getCell("I126").value = Math.floor(ws.getCell("H126").value / weekTotal) || 0;
    ws.getCell("I127").value = Math.floor(ws.getCell("H127").value / weekTotal) || 0;
    ws.getCell("I128").value = Math.floor(ws.getCell("H128").value / weekTotal) || 0;
    ws.getCell("I129").value = Math.floor(ws.getCell("H129").value / weekTotal) || 0;
    ws.getCell("I130").value = Math.floor(ws.getCell("H130").value / weekTotal) || 0;
    ws.getCell("I131").value = Math.floor(ws.getCell("H131").value / weekTotal) || 0;
    ws.getCell("I132").value = Math.floor(ws.getCell("H132").value / weekTotal) || 0;
    ws.getCell("I133").value = Math.floor(ws.getCell("H133").value / weekTotal) || 0;
    ws.getCell("I134").value = Math.floor(ws.getCell("H134").value / weekTotal) || 0;

    ws.getCell("J117").value = (ws.getCell("C117").value > 22 ? 1 : 0) + (ws.getCell("D117").value > 22 ? 1 : 0) + (ws.getCell("E117").value > 22 ? 1 : 0) + (ws.getCell("F117").value > 22 ? 1 : 0) + (ws.getCell("G117").value > 22 ? 1 : 0);
    ws.getCell("J118").value = (ws.getCell("C118").value > 22 ? 1 : 0) + (ws.getCell("D118").value > 22 ? 1 : 0) + (ws.getCell("E118").value > 22 ? 1 : 0) + (ws.getCell("F118").value > 22 ? 1 : 0) + (ws.getCell("G118").value > 22 ? 1 : 0);
    ws.getCell("J119").value = (ws.getCell("C119").value > 22 ? 1 : 0) + (ws.getCell("D119").value > 22 ? 1 : 0) + (ws.getCell("E119").value > 22 ? 1 : 0) + (ws.getCell("F119").value > 22 ? 1 : 0) + (ws.getCell("G119").value > 22 ? 1 : 0);
    ws.getCell("J120").value = (ws.getCell("C120").value > 22 ? 1 : 0) + (ws.getCell("D120").value > 22 ? 1 : 0) + (ws.getCell("E120").value > 22 ? 1 : 0) + (ws.getCell("F120").value > 22 ? 1 : 0) + (ws.getCell("G120").value > 22 ? 1 : 0);
    ws.getCell("J121").value = (ws.getCell("C121").value > 22 ? 1 : 0) + (ws.getCell("D121").value > 22 ? 1 : 0) + (ws.getCell("E121").value > 22 ? 1 : 0) + (ws.getCell("F121").value > 22 ? 1 : 0) + (ws.getCell("G121").value > 22 ? 1 : 0);
    ws.getCell("J122").value = (ws.getCell("C122").value > 22 ? 1 : 0) + (ws.getCell("D122").value > 22 ? 1 : 0) + (ws.getCell("E122").value > 22 ? 1 : 0) + (ws.getCell("F122").value > 22 ? 1 : 0) + (ws.getCell("G122").value > 22 ? 1 : 0);
    ws.getCell("J123").value = (ws.getCell("C123").value > 22 ? 1 : 0) + (ws.getCell("D123").value > 22 ? 1 : 0) + (ws.getCell("E123").value > 22 ? 1 : 0) + (ws.getCell("F123").value > 22 ? 1 : 0) + (ws.getCell("G123").value > 22 ? 1 : 0);
    ws.getCell("J124").value = (ws.getCell("C124").value > 22 ? 1 : 0) + (ws.getCell("D124").value > 22 ? 1 : 0) + (ws.getCell("E124").value > 22 ? 1 : 0) + (ws.getCell("F124").value > 22 ? 1 : 0) + (ws.getCell("G124").value > 22 ? 1 : 0);
    ws.getCell("J125").value = (ws.getCell("C125").value > 22 ? 1 : 0) + (ws.getCell("D125").value > 22 ? 1 : 0) + (ws.getCell("E125").value > 22 ? 1 : 0) + (ws.getCell("F125").value > 22 ? 1 : 0) + (ws.getCell("G125").value > 22 ? 1 : 0);
    ws.getCell("J126").value = (ws.getCell("C126").value > 22 ? 1 : 0) + (ws.getCell("D126").value > 22 ? 1 : 0) + (ws.getCell("E126").value > 22 ? 1 : 0) + (ws.getCell("F126").value > 22 ? 1 : 0) + (ws.getCell("G126").value > 22 ? 1 : 0);
    ws.getCell("J127").value = (ws.getCell("C127").value > 22 ? 1 : 0) + (ws.getCell("D127").value > 22 ? 1 : 0) + (ws.getCell("E127").value > 22 ? 1 : 0) + (ws.getCell("F127").value > 22 ? 1 : 0) + (ws.getCell("G127").value > 22 ? 1 : 0);
    ws.getCell("J128").value = (ws.getCell("C128").value > 22 ? 1 : 0) + (ws.getCell("D128").value > 22 ? 1 : 0) + (ws.getCell("E128").value > 22 ? 1 : 0) + (ws.getCell("F128").value > 22 ? 1 : 0) + (ws.getCell("G128").value > 22 ? 1 : 0);
    ws.getCell("J129").value = (ws.getCell("C129").value > 22 ? 1 : 0) + (ws.getCell("D129").value > 22 ? 1 : 0) + (ws.getCell("E129").value > 22 ? 1 : 0) + (ws.getCell("F129").value > 22 ? 1 : 0) + (ws.getCell("G129").value > 22 ? 1 : 0);
    ws.getCell("J130").value = (ws.getCell("C130").value > 22 ? 1 : 0) + (ws.getCell("D130").value > 22 ? 1 : 0) + (ws.getCell("E130").value > 22 ? 1 : 0) + (ws.getCell("F130").value > 22 ? 1 : 0) + (ws.getCell("G130").value > 22 ? 1 : 0);
    ws.getCell("J131").value = (ws.getCell("C131").value > 22 ? 1 : 0) + (ws.getCell("D131").value > 22 ? 1 : 0) + (ws.getCell("E131").value > 22 ? 1 : 0) + (ws.getCell("F131").value > 22 ? 1 : 0) + (ws.getCell("G131").value > 22 ? 1 : 0);
    ws.getCell("J132").value = (ws.getCell("C132").value > 22 ? 1 : 0) + (ws.getCell("D132").value > 22 ? 1 : 0) + (ws.getCell("E132").value > 22 ? 1 : 0) + (ws.getCell("F132").value > 22 ? 1 : 0) + (ws.getCell("G132").value > 22 ? 1 : 0);

    ws.getCell("K117").value = (Math.floor((ws.getCell("J117").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K118").value = (Math.floor((ws.getCell("J118").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K119").value = (Math.floor((ws.getCell("J119").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K120").value = (Math.floor((ws.getCell("J120").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K121").value = (Math.floor((ws.getCell("J121").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K122").value = (Math.floor((ws.getCell("J122").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K123").value = (Math.floor((ws.getCell("J123").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K124").value = (Math.floor((ws.getCell("J124").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K125").value = (Math.floor((ws.getCell("J125").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K126").value = (Math.floor((ws.getCell("J126").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K127").value = (Math.floor((ws.getCell("J127").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K128").value = (Math.floor((ws.getCell("J128").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K129").value = (Math.floor((ws.getCell("J129").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K130").value = (Math.floor((ws.getCell("J130").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K131").value = (Math.floor((ws.getCell("J131").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K132").value = (Math.floor((ws.getCell("J132").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L117").value = (ws.getCell("C117").value === 28 ? 1 : 0) + (ws.getCell("D117").value === 28 ? 1 : 0) + (ws.getCell("E117").value === 28 ? 1 : 0) + (ws.getCell("F117").value === 28 ? 1 : 0) + (ws.getCell("G117").value === 28 ? 1 : 0);
    ws.getCell("L118").value = (ws.getCell("C118").value === 28 ? 1 : 0) + (ws.getCell("D118").value === 28 ? 1 : 0) + (ws.getCell("E118").value === 28 ? 1 : 0) + (ws.getCell("F118").value === 28 ? 1 : 0) + (ws.getCell("G118").value === 28 ? 1 : 0);
    ws.getCell("L119").value = (ws.getCell("C119").value === 28 ? 1 : 0) + (ws.getCell("D119").value === 28 ? 1 : 0) + (ws.getCell("E119").value === 28 ? 1 : 0) + (ws.getCell("F119").value === 28 ? 1 : 0) + (ws.getCell("G119").value === 28 ? 1 : 0);
    ws.getCell("L120").value = (ws.getCell("C120").value === 28 ? 1 : 0) + (ws.getCell("D120").value === 28 ? 1 : 0) + (ws.getCell("E120").value === 28 ? 1 : 0) + (ws.getCell("F120").value === 28 ? 1 : 0) + (ws.getCell("G120").value === 28 ? 1 : 0);
    ws.getCell("L121").value = (ws.getCell("C121").value === 28 ? 1 : 0) + (ws.getCell("D121").value === 28 ? 1 : 0) + (ws.getCell("E121").value === 28 ? 1 : 0) + (ws.getCell("F121").value === 28 ? 1 : 0) + (ws.getCell("G121").value === 28 ? 1 : 0);
    ws.getCell("L122").value = (ws.getCell("C122").value === 28 ? 1 : 0) + (ws.getCell("D122").value === 28 ? 1 : 0) + (ws.getCell("E122").value === 28 ? 1 : 0) + (ws.getCell("F122").value === 28 ? 1 : 0) + (ws.getCell("G122").value === 28 ? 1 : 0);
    ws.getCell("L123").value = (ws.getCell("C123").value === 28 ? 1 : 0) + (ws.getCell("D123").value === 28 ? 1 : 0) + (ws.getCell("E123").value === 28 ? 1 : 0) + (ws.getCell("F123").value === 28 ? 1 : 0) + (ws.getCell("G123").value === 28 ? 1 : 0);
    ws.getCell("L124").value = (ws.getCell("C124").value === 28 ? 1 : 0) + (ws.getCell("D124").value === 28 ? 1 : 0) + (ws.getCell("E124").value === 28 ? 1 : 0) + (ws.getCell("F124").value === 28 ? 1 : 0) + (ws.getCell("G124").value === 28 ? 1 : 0);
    ws.getCell("L125").value = (ws.getCell("C125").value === 28 ? 1 : 0) + (ws.getCell("D125").value === 28 ? 1 : 0) + (ws.getCell("E125").value === 28 ? 1 : 0) + (ws.getCell("F125").value === 28 ? 1 : 0) + (ws.getCell("G125").value === 28 ? 1 : 0);
    ws.getCell("L126").value = (ws.getCell("C126").value === 28 ? 1 : 0) + (ws.getCell("D126").value === 28 ? 1 : 0) + (ws.getCell("E126").value === 28 ? 1 : 0) + (ws.getCell("F126").value === 28 ? 1 : 0) + (ws.getCell("G126").value === 28 ? 1 : 0);
    ws.getCell("L127").value = (ws.getCell("C127").value === 28 ? 1 : 0) + (ws.getCell("D127").value === 28 ? 1 : 0) + (ws.getCell("E127").value === 28 ? 1 : 0) + (ws.getCell("F127").value === 28 ? 1 : 0) + (ws.getCell("G127").value === 28 ? 1 : 0);
    ws.getCell("L128").value = (ws.getCell("C128").value === 28 ? 1 : 0) + (ws.getCell("D128").value === 28 ? 1 : 0) + (ws.getCell("E128").value === 28 ? 1 : 0) + (ws.getCell("F128").value === 28 ? 1 : 0) + (ws.getCell("G128").value === 28 ? 1 : 0);
    ws.getCell("L129").value = (ws.getCell("C129").value === 28 ? 1 : 0) + (ws.getCell("D129").value === 28 ? 1 : 0) + (ws.getCell("E129").value === 28 ? 1 : 0) + (ws.getCell("F129").value === 28 ? 1 : 0) + (ws.getCell("G129").value === 28 ? 1 : 0);
    ws.getCell("L130").value = (ws.getCell("C130").value === 28 ? 1 : 0) + (ws.getCell("D130").value === 28 ? 1 : 0) + (ws.getCell("E130").value === 28 ? 1 : 0) + (ws.getCell("F130").value === 28 ? 1 : 0) + (ws.getCell("G130").value === 28 ? 1 : 0);
    ws.getCell("L131").value = (ws.getCell("C131").value === 28 ? 1 : 0) + (ws.getCell("D131").value === 28 ? 1 : 0) + (ws.getCell("E131").value === 28 ? 1 : 0) + (ws.getCell("F131").value === 28 ? 1 : 0) + (ws.getCell("G131").value === 28 ? 1 : 0);
    ws.getCell("L132").value = (ws.getCell("C132").value === 28 ? 1 : 0) + (ws.getCell("D132").value === 28 ? 1 : 0) + (ws.getCell("E132").value === 28 ? 1 : 0) + (ws.getCell("F132").value === 28 ? 1 : 0) + (ws.getCell("G132").value === 28 ? 1 : 0);

    ws.getCell("M117").value = (Math.floor((ws.getCell("L117").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M118").value = (Math.floor((ws.getCell("L118").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M119").value = (Math.floor((ws.getCell("L119").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M120").value = (Math.floor((ws.getCell("L120").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M121").value = (Math.floor((ws.getCell("L121").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M122").value = (Math.floor((ws.getCell("L122").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M123").value = (Math.floor((ws.getCell("L123").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M124").value = (Math.floor((ws.getCell("L124").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M125").value = (Math.floor((ws.getCell("L125").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M126").value = (Math.floor((ws.getCell("L126").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M127").value = (Math.floor((ws.getCell("L127").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M128").value = (Math.floor((ws.getCell("L128").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M129").value = (Math.floor((ws.getCell("L129").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M130").value = (Math.floor((ws.getCell("L130").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M131").value = (Math.floor((ws.getCell("L131").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M132").value = (Math.floor((ws.getCell("L132").value / weekTotal) * 100) || 0) + "%";

    // ROUTE - 9
    ws.getCell("C138").value = getOnBoradCount(week_data1, "MT > LG", "12:00");
    ws.getCell("C139").value = getOnBoradCount(week_data1, "MT > LG", "14:00");
    ws.getCell("C140").value =
      ws.getCell("C138").value +
      ws.getCell("C139").value
    ws.getCell("C141").value = Math.floor(ws.getCell("C140").value / 2) || 0;

    ws.getCell("D138").value = getOnBoradCount(week_data2, "MT > LG", "12:00");
    ws.getCell("D139").value = getOnBoradCount(week_data2, "MT > LG", "14:00");
    ws.getCell("D140").value =
      ws.getCell("D138").value +
      ws.getCell("D139").value
    ws.getCell("D141").value = Math.floor(ws.getCell("D140").value / 2) || 0;

    ws.getCell("E138").value = getOnBoradCount(week_data3, "MT > LG", "12:00");
    ws.getCell("E139").value = getOnBoradCount(week_data3, "MT > LG", "14:00");
    ws.getCell("E140").value =
      ws.getCell("E138").value +
      ws.getCell("E139").value
    ws.getCell("E141").value = Math.floor(ws.getCell("E140").value / 2) || 0;

    ws.getCell("F138").value = getOnBoradCount(week_data4, "MT > LG", "12:00");
    ws.getCell("F139").value = getOnBoradCount(week_data4, "MT > LG", "14:00");
    ws.getCell("F140").value =
      ws.getCell("F138").value +
      ws.getCell("F139").value
    ws.getCell("F141").value = Math.floor(ws.getCell("F140").value / 2) || 0;

    ws.getCell("G138").value = getOnBoradCount(week_data5, "MT > LG", "12:00");
    ws.getCell("G139").value = getOnBoradCount(week_data5, "MT > LG", "14:00");
    ws.getCell("G140").value =
      ws.getCell("G138").value +
      ws.getCell("G139").value
    ws.getCell("G141").value = Math.floor(ws.getCell("G140").value / 2) || 0;

    ws.getCell("H138").value = ws.getCell("C138").value + ws.getCell("D138").value + ws.getCell("E138").value + ws.getCell("F138").value + ws.getCell("G138").value;
    ws.getCell("H139").value = ws.getCell("C139").value + ws.getCell("D139").value + ws.getCell("E139").value + ws.getCell("F139").value + ws.getCell("G139").value;
    ws.getCell("H140").value = ws.getCell("C140").value + ws.getCell("D140").value + ws.getCell("E140").value + ws.getCell("F140").value + ws.getCell("G140").value;
    ws.getCell("H141").value = ws.getCell("C141").value + ws.getCell("D141").value + ws.getCell("E141").value + ws.getCell("F141").value + ws.getCell("G141").value;

    ws.getCell("I138").value = Math.floor(ws.getCell("H138").value / weekTotal) || 0;
    ws.getCell("I139").value = Math.floor(ws.getCell("H139").value / weekTotal) || 0;
    ws.getCell("I140").value = Math.floor(ws.getCell("H140").value / weekTotal) || 0;
    ws.getCell("I141").value = Math.floor(ws.getCell("H141").value / weekTotal) || 0;

    ws.getCell("J138").value = (ws.getCell("C138").value > 22 ? 1 : 0) + (ws.getCell("D138").value > 22 ? 1 : 0) + (ws.getCell("E138").value > 22 ? 1 : 0) + (ws.getCell("F138").value > 22 ? 1 : 0) + (ws.getCell("G138").value > 22 ? 1 : 0);
    ws.getCell("J139").value = (ws.getCell("C139").value > 22 ? 1 : 0) + (ws.getCell("D139").value > 22 ? 1 : 0) + (ws.getCell("E139").value > 22 ? 1 : 0) + (ws.getCell("F139").value > 22 ? 1 : 0) + (ws.getCell("G139").value > 22 ? 1 : 0);

    ws.getCell("K138").value = (Math.floor((ws.getCell("J138").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K139").value = (Math.floor((ws.getCell("J139").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L138").value = (ws.getCell("C138").value === 28 ? 1 : 0) + (ws.getCell("D138").value === 28 ? 1 : 0) + (ws.getCell("E138").value === 28 ? 1 : 0) + (ws.getCell("F138").value === 28 ? 1 : 0) + (ws.getCell("G138").value === 28 ? 1 : 0);
    ws.getCell("L139").value = (ws.getCell("C139").value === 28 ? 1 : 0) + (ws.getCell("D139").value === 28 ? 1 : 0) + (ws.getCell("E139").value === 28 ? 1 : 0) + (ws.getCell("F139").value === 28 ? 1 : 0) + (ws.getCell("G139").value === 28 ? 1 : 0);

    ws.getCell("M138").value = (Math.floor((ws.getCell("L138").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M139").value = (Math.floor((ws.getCell("L139").value / weekTotal) * 100) || 0) + "%";

    // ROUTE - 10
    ws.getCell("C145").value = getOnBoradCount(week_data1, "MFC > LG", "12:10");
    ws.getCell("C146").value = getOnBoradCount(week_data1, "MFC > LG", "14:10");
    ws.getCell("C147").value =
      ws.getCell("C145").value +
      ws.getCell("C146").value
    ws.getCell("C148").value = Math.floor(ws.getCell("C147").value / 2) || 0;

    ws.getCell("D145").value = getOnBoradCount(week_data2, "MFC > LG", "12:10");
    ws.getCell("D146").value = getOnBoradCount(week_data2, "MFC > LG", "14:10");
    ws.getCell("D147").value =
      ws.getCell("D145").value +
      ws.getCell("D146").value
    ws.getCell("D148").value = Math.floor(ws.getCell("D147").value / 2) || 0;

    ws.getCell("E145").value = getOnBoradCount(week_data3, "MFC > LG", "12:10");
    ws.getCell("E146").value = getOnBoradCount(week_data3, "MFC > LG", "14:10");
    ws.getCell("E147").value =
      ws.getCell("E145").value +
      ws.getCell("E146").value
    ws.getCell("E148").value = Math.floor(ws.getCell("E147").value / 2) || 0;

    ws.getCell("F145").value = getOnBoradCount(week_data4, "MFC > LG", "12:10");
    ws.getCell("F146").value = getOnBoradCount(week_data4, "MFC > LG", "14:10");
    ws.getCell("F147").value =
      ws.getCell("F145").value +
      ws.getCell("F146").value
    ws.getCell("F148").value = Math.floor(ws.getCell("F147").value / 2) || 0;

    ws.getCell("G145").value = getOnBoradCount(week_data5, "MFC > LG", "12:10");
    ws.getCell("G146").value = getOnBoradCount(week_data5, "MFC > LG", "14:10");
    ws.getCell("G147").value =
      ws.getCell("G145").value +
      ws.getCell("G146").value
    ws.getCell("G148").value = Math.floor(ws.getCell("G147").value / 2) || 0;

    ws.getCell("H145").value = ws.getCell("C145").value + ws.getCell("D145").value + ws.getCell("E145").value + ws.getCell("F145").value + ws.getCell("G145").value;
    ws.getCell("H146").value = ws.getCell("C146").value + ws.getCell("D146").value + ws.getCell("E146").value + ws.getCell("F146").value + ws.getCell("G146").value;
    ws.getCell("H147").value = ws.getCell("C147").value + ws.getCell("D147").value + ws.getCell("E147").value + ws.getCell("F147").value + ws.getCell("G147").value;
    ws.getCell("H148").value = ws.getCell("C148").value + ws.getCell("D148").value + ws.getCell("E148").value + ws.getCell("F148").value + ws.getCell("G148").value;

    ws.getCell("I145").value = Math.floor(ws.getCell("H145").value / weekTotal) || 0;
    ws.getCell("I146").value = Math.floor(ws.getCell("H146").value / weekTotal) || 0;
    ws.getCell("I147").value = Math.floor(ws.getCell("H147").value / weekTotal) || 0;
    ws.getCell("I148").value = Math.floor(ws.getCell("H148").value / weekTotal) || 0;

    ws.getCell("J145").value = (ws.getCell("C145").value > 22 ? 1 : 0) + (ws.getCell("D145").value > 22 ? 1 : 0) + (ws.getCell("E145").value > 22 ? 1 : 0) + (ws.getCell("F145").value > 22 ? 1 : 0) + (ws.getCell("G145").value > 22 ? 1 : 0);
    ws.getCell("J146").value = (ws.getCell("C146").value > 22 ? 1 : 0) + (ws.getCell("D146").value > 22 ? 1 : 0) + (ws.getCell("E146").value > 22 ? 1 : 0) + (ws.getCell("F146").value > 22 ? 1 : 0) + (ws.getCell("G146").value > 22 ? 1 : 0);

    ws.getCell("K145").value = (Math.floor((ws.getCell("J145").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K146").value = (Math.floor((ws.getCell("J146").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L145").value = (ws.getCell("C145").value === 28 ? 1 : 0) + (ws.getCell("D145").value === 28 ? 1 : 0) + (ws.getCell("E145").value === 28 ? 1 : 0) + (ws.getCell("F145").value === 28 ? 1 : 0) + (ws.getCell("G145").value === 28 ? 1 : 0);
    ws.getCell("L146").value = (ws.getCell("C146").value === 28 ? 1 : 0) + (ws.getCell("D146").value === 28 ? 1 : 0) + (ws.getCell("E146").value === 28 ? 1 : 0) + (ws.getCell("F146").value === 28 ? 1 : 0) + (ws.getCell("G146").value === 28 ? 1 : 0);

    ws.getCell("M145").value = (Math.floor((ws.getCell("L145").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M146").value = (Math.floor((ws.getCell("L146").value / weekTotal) * 100) || 0) + "%";

    // ROUTE - 11
    ws.getCell("C154").value = getOnBoradCount(week_data1, "MT > LG", "18:30");
    ws.getCell("C155").value = getOnBoradCount(week_data1, "MT > LG", "19:00");
    ws.getCell("C156").value =
      ws.getCell("C154").value +
      ws.getCell("C155").value
    ws.getCell("C157").value = Math.floor(ws.getCell("C156").value / 2) || 0;

    ws.getCell("D154").value = getOnBoradCount(week_data2, "MT > LG", "18:30");
    ws.getCell("D155").value = getOnBoradCount(week_data2, "MT > LG", "19:00");
    ws.getCell("D156").value =
      ws.getCell("D154").value +
      ws.getCell("D155").value
    ws.getCell("D157").value = Math.floor(ws.getCell("D156").value / 2) || 0;

    ws.getCell("E154").value = getOnBoradCount(week_data3, "MT > LG", "18:30");
    ws.getCell("E155").value = getOnBoradCount(week_data3, "MT > LG", "19:00");
    ws.getCell("E156").value =
      ws.getCell("E154").value +
      ws.getCell("E155").value
    ws.getCell("E157").value = Math.floor(ws.getCell("E156").value / 2) || 0;

    ws.getCell("F154").value = getOnBoradCount(week_data4, "MT > LG", "18:30");
    ws.getCell("F155").value = getOnBoradCount(week_data4, "MT > LG", "19:00");
    ws.getCell("F156").value =
      ws.getCell("F154").value +
      ws.getCell("F155").value
    ws.getCell("F157").value = Math.floor(ws.getCell("F156").value / 2) || 0;

    ws.getCell("G154").value = getOnBoradCount(week_data5, "MT > LG", "18:30");
    ws.getCell("G155").value = getOnBoradCount(week_data5, "MT > LG", "19:00");
    ws.getCell("G156").value =
      ws.getCell("G154").value +
      ws.getCell("G155").value
    ws.getCell("G157").value = Math.floor(ws.getCell("G156").value / 2) || 0;

    ws.getCell("H154").value = ws.getCell("C154").value + ws.getCell("D154").value + ws.getCell("E154").value + ws.getCell("F154").value + ws.getCell("G154").value;
    ws.getCell("H155").value = ws.getCell("C155").value + ws.getCell("D155").value + ws.getCell("E155").value + ws.getCell("F155").value + ws.getCell("G155").value;
    ws.getCell("H156").value = ws.getCell("C156").value + ws.getCell("D156").value + ws.getCell("E156").value + ws.getCell("F156").value + ws.getCell("G156").value;
    ws.getCell("H157").value = ws.getCell("C157").value + ws.getCell("D157").value + ws.getCell("E157").value + ws.getCell("F157").value + ws.getCell("G157").value;

    ws.getCell("I154").value = Math.floor(ws.getCell("H154").value / weekTotal) || 0;
    ws.getCell("I155").value = Math.floor(ws.getCell("H155").value / weekTotal) || 0;
    ws.getCell("I156").value = Math.floor(ws.getCell("H156").value / weekTotal) || 0;
    ws.getCell("I157").value = Math.floor(ws.getCell("H157").value / weekTotal) || 0;

    ws.getCell("J154").value = (ws.getCell("C154").value > 22 ? 1 : 0) + (ws.getCell("D154").value > 22 ? 1 : 0) + (ws.getCell("E154").value > 22 ? 1 : 0) + (ws.getCell("F154").value > 22 ? 1 : 0) + (ws.getCell("G154").value > 22 ? 1 : 0);
    ws.getCell("J155").value = (ws.getCell("C155").value > 22 ? 1 : 0) + (ws.getCell("D155").value > 22 ? 1 : 0) + (ws.getCell("E155").value > 22 ? 1 : 0) + (ws.getCell("F155").value > 22 ? 1 : 0) + (ws.getCell("G155").value > 22 ? 1 : 0);

    ws.getCell("K154").value = (Math.floor((ws.getCell("J154").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K155").value = (Math.floor((ws.getCell("J155").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L154").value = (ws.getCell("C154").value === 28 ? 1 : 0) + (ws.getCell("D154").value === 28 ? 1 : 0) + (ws.getCell("E154").value === 28 ? 1 : 0) + (ws.getCell("F154").value === 28 ? 1 : 0) + (ws.getCell("G154").value === 28 ? 1 : 0);
    ws.getCell("L155").value = (ws.getCell("C155").value === 28 ? 1 : 0) + (ws.getCell("D155").value === 28 ? 1 : 0) + (ws.getCell("E155").value === 28 ? 1 : 0) + (ws.getCell("F155").value === 28 ? 1 : 0) + (ws.getCell("G155").value === 28 ? 1 : 0);

    ws.getCell("M154").value = (Math.floor((ws.getCell("L154").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M155").value = (Math.floor((ws.getCell("L155").value / weekTotal) * 100) || 0) + "%";

    // ROUTE - 12
    ws.getCell("C161").value = getOnBoradCount(week_data1, "MFC > LG", "18:30");
    ws.getCell("C162").value = getOnBoradCount(week_data1, "MFC > LG", "19:00");
    ws.getCell("C163").value =
      ws.getCell("C161").value +
      ws.getCell("C162").value
    ws.getCell("C164").value = Math.floor(ws.getCell("C163").value / 2) || 0;

    ws.getCell("D161").value = getOnBoradCount(week_data2, "MFC > LG", "18:30");
    ws.getCell("D162").value = getOnBoradCount(week_data2, "MFC > LG", "19:00");
    ws.getCell("D163").value =
      ws.getCell("D161").value +
      ws.getCell("D162").value
    ws.getCell("D164").value = Math.floor(ws.getCell("D163").value / 2) || 0;

    ws.getCell("E161").value = getOnBoradCount(week_data3, "MFC > LG", "18:30");
    ws.getCell("E162").value = getOnBoradCount(week_data3, "MFC > LG", "19:00");
    ws.getCell("E163").value =
      ws.getCell("E161").value +
      ws.getCell("E162").value
    ws.getCell("E164").value = Math.floor(ws.getCell("E163").value / 2) || 0;

    ws.getCell("F161").value = getOnBoradCount(week_data4, "MFC > LG", "18:30");
    ws.getCell("F162").value = getOnBoradCount(week_data4, "MFC > LG", "19:00");
    ws.getCell("F163").value =
      ws.getCell("F161").value +
      ws.getCell("F162").value
    ws.getCell("F164").value = Math.floor(ws.getCell("F163").value / 2) || 0;

    ws.getCell("G161").value = getOnBoradCount(week_data5, "MFC > LG", "18:30");
    ws.getCell("G162").value = getOnBoradCount(week_data5, "MFC > LG", "19:00");
    ws.getCell("G163").value =
      ws.getCell("G161").value +
      ws.getCell("G162").value
    ws.getCell("G164").value = Math.floor(ws.getCell("G163").value / 2) || 0;

    ws.getCell("H161").value = ws.getCell("C161").value + ws.getCell("D161").value + ws.getCell("E161").value + ws.getCell("F161").value + ws.getCell("G161").value;
    ws.getCell("H162").value = ws.getCell("C162").value + ws.getCell("D162").value + ws.getCell("E162").value + ws.getCell("F162").value + ws.getCell("G162").value;
    ws.getCell("H163").value = ws.getCell("C163").value + ws.getCell("D163").value + ws.getCell("E163").value + ws.getCell("F163").value + ws.getCell("G163").value;
    ws.getCell("H164").value = ws.getCell("C164").value + ws.getCell("D164").value + ws.getCell("E164").value + ws.getCell("F164").value + ws.getCell("G164").value;

    ws.getCell("I161").value = Math.floor(ws.getCell("H161").value / weekTotal) || 0;
    ws.getCell("I162").value = Math.floor(ws.getCell("H162").value / weekTotal) || 0;
    ws.getCell("I163").value = Math.floor(ws.getCell("H163").value / weekTotal) || 0;
    ws.getCell("I164").value = Math.floor(ws.getCell("H164").value / weekTotal) || 0;

    ws.getCell("J161").value = (ws.getCell("C161").value > 22 ? 1 : 0) + (ws.getCell("D161").value > 22 ? 1 : 0) + (ws.getCell("E161").value > 22 ? 1 : 0) + (ws.getCell("F161").value > 22 ? 1 : 0) + (ws.getCell("G161").value > 22 ? 1 : 0);
    ws.getCell("J162").value = (ws.getCell("C162").value > 22 ? 1 : 0) + (ws.getCell("D162").value > 22 ? 1 : 0) + (ws.getCell("E162").value > 22 ? 1 : 0) + (ws.getCell("F162").value > 22 ? 1 : 0) + (ws.getCell("G162").value > 22 ? 1 : 0);

    ws.getCell("K161").value = (Math.floor((ws.getCell("J161").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("K162").value = (Math.floor((ws.getCell("J162").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("L161").value = (ws.getCell("C161").value === 28 ? 1 : 0) + (ws.getCell("D161").value === 28 ? 1 : 0) + (ws.getCell("E161").value === 28 ? 1 : 0) + (ws.getCell("F161").value === 28 ? 1 : 0) + (ws.getCell("G161").value === 28 ? 1 : 0);
    ws.getCell("L162").value = (ws.getCell("C162").value === 28 ? 1 : 0) + (ws.getCell("D162").value === 28 ? 1 : 0) + (ws.getCell("E162").value === 28 ? 1 : 0) + (ws.getCell("F162").value === 28 ? 1 : 0) + (ws.getCell("G162").value === 28 ? 1 : 0);

    ws.getCell("M161").value = (Math.floor((ws.getCell("L161").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("M162").value = (Math.floor((ws.getCell("L162").value / weekTotal) * 100) || 0) + "%";

    // CALCULATION
    ws.getCell("C168").value = ws.getCell("C156").value + ws.getCell("C163").value;
    ws.getCell("D168").value = ws.getCell("D156").value + ws.getCell("D163").value;
    ws.getCell("E168").value = ws.getCell("E156").value + ws.getCell("E163").value;
    ws.getCell("F168").value = ws.getCell("F156").value + ws.getCell("F163").value;
    ws.getCell("G168").value = ws.getCell("G156").value + ws.getCell("G163").value;

    ws.getCell("C169").value = (Math.floor(ws.getCell("C168").value / 8) || 0);
    ws.getCell("D169").value = (Math.floor(ws.getCell("D168").value / 8) || 0);
    ws.getCell("E169").value = (Math.floor(ws.getCell("E168").value / 8) || 0);
    ws.getCell("F169").value = (Math.floor(ws.getCell("F168").value / 8) || 0);
    ws.getCell("G169").value = (Math.floor(ws.getCell("G168").value / 8) || 0);

    ws.getCell("H168").value = ws.getCell("C168").value + ws.getCell("D168").value + ws.getCell("E168").value + ws.getCell("F168").value + ws.getCell("G168").value;
    ws.getCell("H169").value = ws.getCell("C169").value + ws.getCell("D169").value + ws.getCell("E169").value + ws.getCell("F169").value + ws.getCell("G169").value;

    ws.getCell("I168").value = (Math.floor(ws.getCell("H168").value / weekTotal) || 0);
    ws.getCell("I169").value = (Math.floor(ws.getCell("H169").value / weekTotal) || 0);

    // ROUTE - 13
    ws.getCell("C174").value = getOnBoradCount(week_data1, "MT > MFC", "19:30") || 0;
    ws.getCell("C175").value = getOnBoradCount(week_data1, "MT > MFC", "20:00") || 0;
    ws.getCell("C176").value = ws.getCell("C174").value + ws.getCell("C175").value;
    ws.getCell("C177").value = (Math.floor(ws.getCell("C176").value / 2) || 0);

    ws.getCell("D174").value = getOffBoradCount(week_data1, "MT > MFC", "19:30") || 0;
    ws.getCell("D175").value = getOffBoradCount(week_data1, "MT > MFC", "20:00") || 0;
    ws.getCell("D176").value = ws.getCell("D174").value + ws.getCell("D175").value;
    ws.getCell("D177").value = (Math.floor(ws.getCell("D176").value / 2) || 0);

    ws.getCell("E174").value = getOnBoradCount(week_data1, "MFC > LG", "19:40") || 0;
    ws.getCell("E175").value = getOnBoradCount(week_data1, "MFC > LG", "20:10") || 0;
    ws.getCell("E176").value = ws.getCell("E174").value + ws.getCell("E175").value;
    ws.getCell("E177").value = (Math.floor(ws.getCell("E176").value / 2) || 0);

    ws.getCell("F174").value = ws.getCell("C174").value - ws.getCell("D174").value + ws.getCell("E174").value;
    ws.getCell("F175").value = ws.getCell("C175").value - ws.getCell("D175").value + ws.getCell("E175").value;
    ws.getCell("F176").value = ws.getCell("F174").value + ws.getCell("F175").value;
    ws.getCell("F177").value = (Math.floor(ws.getCell("F176").value / 2) || 0);

    ws.getCell("G174").value = getOnBoradCount(week_data2, "MT > MFC", "19:30") || 0;
    ws.getCell("G175").value = getOnBoradCount(week_data2, "MT > MFC", "20:00") || 0;
    ws.getCell("G176").value = ws.getCell("G174").value + ws.getCell("G175").value;
    ws.getCell("G177").value = (Math.floor(ws.getCell("G176").value / 2) || 0);

    ws.getCell("H174").value = getOffBoradCount(week_data2, "MT > MFC", "19:30") || 0;
    ws.getCell("H175").value = getOffBoradCount(week_data2, "MT > MFC", "20:00") || 0;
    ws.getCell("H176").value = ws.getCell("H174").value + ws.getCell("H175").value;
    ws.getCell("H177").value = (Math.floor(ws.getCell("H176").value / 2) || 0);

    ws.getCell("I174").value = getOnBoradCount(week_data2, "MFC > LG", "19:40") || 0;
    ws.getCell("I175").value = getOnBoradCount(week_data2, "MFC > LG", "20:10") || 0;
    ws.getCell("I176").value = ws.getCell("I174").value + ws.getCell("I175").value;
    ws.getCell("I177").value = (Math.floor(ws.getCell("I176").value / 2) || 0);

    ws.getCell("J174").value = ws.getCell("G174").value - ws.getCell("H174").value + ws.getCell("I174").value;
    ws.getCell("J175").value = ws.getCell("G175").value - ws.getCell("H175").value + ws.getCell("I175").value;
    ws.getCell("J176").value = ws.getCell("J174").value + ws.getCell("J175").value;
    ws.getCell("J177").value = (Math.floor(ws.getCell("J176").value / 2) || 0);

    ws.getCell("K174").value = getOnBoradCount(week_data3, "MT > MFC", "19:30") || 0;
    ws.getCell("K175").value = getOnBoradCount(week_data3, "MT > MFC", "20:00") || 0;
    ws.getCell("K176").value = ws.getCell("K174").value + ws.getCell("K175").value;
    ws.getCell("K177").value = (Math.floor(ws.getCell("K176").value / 2) || 0);

    ws.getCell("L174").value = getOffBoradCount(week_data3, "MT > MFC", "19:30") || 0;
    ws.getCell("L175").value = getOffBoradCount(week_data3, "MT > MFC", "20:00") || 0;
    ws.getCell("L176").value = ws.getCell("L174").value + ws.getCell("L175").value;
    ws.getCell("L177").value = (Math.floor(ws.getCell("L176").value / 2) || 0);

    ws.getCell("M174").value = getOnBoradCount(week_data3, "MFC > LG", "19:40") || 0;
    ws.getCell("M175").value = getOnBoradCount(week_data3, "MFC > LG", "20:10") || 0;
    ws.getCell("M176").value = ws.getCell("M174").value + ws.getCell("M175").value;
    ws.getCell("M177").value = (Math.floor(ws.getCell("M176").value / 2) || 0);

    ws.getCell("N174").value = ws.getCell("K174").value - ws.getCell("L174").value + ws.getCell("M174").value;
    ws.getCell("N175").value = ws.getCell("K175").value - ws.getCell("L175").value + ws.getCell("M175").value;
    ws.getCell("N176").value = ws.getCell("N174").value + ws.getCell("N175").value;
    ws.getCell("N177").value = (Math.floor(ws.getCell("N176").value / 2) || 0);

    ws.getCell("O174").value = getOnBoradCount(week_data4, "MT > MFC", "19:30") || 0;
    ws.getCell("O175").value = getOnBoradCount(week_data4, "MT > MFC", "20:00") || 0;
    ws.getCell("O176").value = ws.getCell("O174").value + ws.getCell("O175").value;
    ws.getCell("O177").value = (Math.floor(ws.getCell("O176").value / 2) || 0);

    ws.getCell("P174").value = getOffBoradCount(week_data4, "MT > MFC", "19:30") || 0;
    ws.getCell("P175").value = getOffBoradCount(week_data4, "MT > MFC", "20:00") || 0;
    ws.getCell("P176").value = ws.getCell("P174").value + ws.getCell("P175").value;
    ws.getCell("P177").value = (Math.floor(ws.getCell("P176").value / 2) || 0);

    ws.getCell("Q174").value = getOnBoradCount(week_data4, "MFC > LG", "19:40") || 0;
    ws.getCell("Q175").value = getOnBoradCount(week_data4, "MFC > LG", "20:10") || 0;
    ws.getCell("Q176").value = ws.getCell("Q174").value + ws.getCell("Q175").value;
    ws.getCell("Q177").value = (Math.floor(ws.getCell("Q176").value / 2) || 0);

    ws.getCell("R174").value = ws.getCell("O174").value - ws.getCell("P174").value + ws.getCell("Q174").value;
    ws.getCell("R175").value = ws.getCell("O175").value - ws.getCell("P175").value + ws.getCell("Q175").value;
    ws.getCell("R176").value = ws.getCell("R174").value + ws.getCell("R175").value;
    ws.getCell("R177").value = (Math.floor(ws.getCell("R176").value / 2) || 0);

    ws.getCell("S174").value = getOnBoradCount(week_data5, "MT > MFC", "19:30") || 0;
    ws.getCell("S175").value = getOnBoradCount(week_data5, "MT > MFC", "20:00") || 0;
    ws.getCell("S176").value = ws.getCell("S174").value + ws.getCell("S175").value;
    ws.getCell("S177").value = (Math.floor(ws.getCell("S176").value / 2) || 0);

    ws.getCell("T174").value = getOffBoradCount(week_data5, "MT > MFC", "19:30") || 0;
    ws.getCell("T175").value = getOffBoradCount(week_data5, "MT > MFC", "20:00") || 0;
    ws.getCell("T176").value = ws.getCell("T174").value + ws.getCell("T175").value;
    ws.getCell("T177").value = (Math.floor(ws.getCell("T176").value / 2) || 0);

    ws.getCell("U174").value = getOnBoradCount(week_data5, "MFC > LG", "19:40") || 0;
    ws.getCell("U175").value = getOnBoradCount(week_data5, "MFC > LG", "20:10") || 0;
    ws.getCell("U176").value = ws.getCell("U174").value + ws.getCell("U175").value;
    ws.getCell("U177").value = (Math.floor(ws.getCell("U176").value / 2) || 0);

    ws.getCell("V174").value = ws.getCell("S174").value - ws.getCell("T174").value + ws.getCell("U174").value;
    ws.getCell("V175").value = ws.getCell("S175").value - ws.getCell("T175").value + ws.getCell("U175").value;
    ws.getCell("V176").value = ws.getCell("V174").value + ws.getCell("V175").value;
    ws.getCell("V177").value = (Math.floor(ws.getCell("V176").value / 2) || 0);

    ws.getCell("W174").value = ws.getCell("C174").value + ws.getCell("G174").value + ws.getCell("K174").value + ws.getCell("O174").value + ws.getCell("S174").value;
    ws.getCell("W175").value = ws.getCell("C175").value + ws.getCell("G175").value + ws.getCell("K175").value + ws.getCell("O175").value + ws.getCell("S175").value;
    ws.getCell("W176").value = ws.getCell("C176").value + ws.getCell("G176").value + ws.getCell("K176").value + ws.getCell("O176").value + ws.getCell("S176").value;
    ws.getCell("W177").value = ws.getCell("C177").value + ws.getCell("G177").value + ws.getCell("K177").value + ws.getCell("O177").value + ws.getCell("S177").value;

    ws.getCell("X174").value = Math.floor(ws.getCell("W174").value / weekTotal) || 0;
    ws.getCell("X175").value = Math.floor(ws.getCell("W175").value / weekTotal) || 0;
    ws.getCell("X176").value = Math.floor(ws.getCell("W176").value / weekTotal) || 0;
    ws.getCell("X177").value = Math.floor(ws.getCell("W177").value / weekTotal) || 0;

    ws.getCell("Y174").value = (ws.getCell("C174").value > 22 ? 1 : 0) + (ws.getCell("G174").value > 22 ? 1 : 0) + (ws.getCell("K174").value > 22 ? 1 : 0) + (ws.getCell("O174").value > 22 ? 1 : 0) + (ws.getCell("S174").value > 22 ? 1 : 0);
    ws.getCell("Y175").value = (ws.getCell("C175").value > 22 ? 1 : 0) + (ws.getCell("G175").value > 22 ? 1 : 0) + (ws.getCell("K175").value > 22 ? 1 : 0) + (ws.getCell("O175").value > 22 ? 1 : 0) + (ws.getCell("S175").value > 22 ? 1 : 0);
    ws.getCell("Y176").value = (ws.getCell("C176").value > 22 ? 1 : 0) + (ws.getCell("G176").value > 22 ? 1 : 0) + (ws.getCell("K176").value > 22 ? 1 : 0) + (ws.getCell("O176").value > 22 ? 1 : 0) + (ws.getCell("S176").value > 22 ? 1 : 0);
    ws.getCell("Y177").value = (ws.getCell("C177").value > 22 ? 1 : 0) + (ws.getCell("G177").value > 22 ? 1 : 0) + (ws.getCell("K177").value > 22 ? 1 : 0) + (ws.getCell("O177").value > 22 ? 1 : 0) + (ws.getCell("S177").value > 22 ? 1 : 0);

    ws.getCell("Z174").value = (Math.floor((ws.getCell("Y174").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z175").value = (Math.floor((ws.getCell("Y175").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z176").value = (Math.floor((ws.getCell("Y176").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("Z177").value = (Math.floor((ws.getCell("Y177").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AA174").value = (ws.getCell("C174").value === 28 ? 1 : 0) + (ws.getCell("G174").value === 28 ? 1 : 0) + (ws.getCell("K174").value === 28 ? 1 : 0) + (ws.getCell("O174").value === 28 ? 1 : 0) + (ws.getCell("S174").value === 28 ? 1 : 0);
    ws.getCell("AA175").value = (ws.getCell("C175").value === 28 ? 1 : 0) + (ws.getCell("G175").value === 28 ? 1 : 0) + (ws.getCell("K175").value === 28 ? 1 : 0) + (ws.getCell("O175").value === 28 ? 1 : 0) + (ws.getCell("S175").value === 28 ? 1 : 0);
    ws.getCell("AA176").value = (ws.getCell("C176").value === 28 ? 1 : 0) + (ws.getCell("G176").value === 28 ? 1 : 0) + (ws.getCell("K176").value === 28 ? 1 : 0) + (ws.getCell("O176").value === 28 ? 1 : 0) + (ws.getCell("S176").value === 28 ? 1 : 0);
    ws.getCell("AA177").value = (ws.getCell("C177").value === 28 ? 1 : 0) + (ws.getCell("G177").value === 28 ? 1 : 0) + (ws.getCell("K177").value === 28 ? 1 : 0) + (ws.getCell("O177").value === 28 ? 1 : 0) + (ws.getCell("S177").value === 28 ? 1 : 0);

    ws.getCell("AB174").value = (Math.floor((ws.getCell("Y174").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB175").value = (Math.floor((ws.getCell("Y175").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB176").value = (Math.floor((ws.getCell("Y176").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AB177").value = (Math.floor((ws.getCell("Y177").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AC174").value = ws.getCell("F174").value + ws.getCell("J174").value + ws.getCell("N174").value + ws.getCell("R174").value + ws.getCell("V174").value;
    ws.getCell("AC175").value = ws.getCell("F175").value + ws.getCell("J175").value + ws.getCell("N175").value + ws.getCell("R175").value + ws.getCell("V175").value;
    ws.getCell("AC176").value = ws.getCell("F176").value + ws.getCell("J176").value + ws.getCell("N176").value + ws.getCell("R176").value + ws.getCell("V176").value;
    ws.getCell("AC177").value = ws.getCell("F177").value + ws.getCell("J177").value + ws.getCell("N177").value + ws.getCell("R177").value + ws.getCell("V177").value;

    ws.getCell("AD174").value = Math.floor(ws.getCell("AC174").value / weekTotal) || 0;
    ws.getCell("AD175").value = Math.floor(ws.getCell("AC175").value / weekTotal) || 0;
    ws.getCell("AD176").value = Math.floor(ws.getCell("AC176").value / weekTotal) || 0;
    ws.getCell("AD177").value = Math.floor(ws.getCell("AC177").value / weekTotal) || 0;

    ws.getCell("AE174").value = (ws.getCell("F174").value > 22 ? 1 : 0) + (ws.getCell("J174").value > 22 ? 1 : 0) + (ws.getCell("N174").value > 22 ? 1 : 0) + (ws.getCell("R174").value > 22 ? 1 : 0) + (ws.getCell("V174").value > 22 ? 1 : 0);
    ws.getCell("AE175").value = (ws.getCell("F175").value > 22 ? 1 : 0) + (ws.getCell("J175").value > 22 ? 1 : 0) + (ws.getCell("N175").value > 22 ? 1 : 0) + (ws.getCell("R175").value > 22 ? 1 : 0) + (ws.getCell("V175").value > 22 ? 1 : 0);
    ws.getCell("AE176").value = (ws.getCell("F176").value > 22 ? 1 : 0) + (ws.getCell("J176").value > 22 ? 1 : 0) + (ws.getCell("N176").value > 22 ? 1 : 0) + (ws.getCell("R176").value > 22 ? 1 : 0) + (ws.getCell("V176").value > 22 ? 1 : 0);
    ws.getCell("AE177").value = (ws.getCell("F177").value > 22 ? 1 : 0) + (ws.getCell("J177").value > 22 ? 1 : 0) + (ws.getCell("N177").value > 22 ? 1 : 0) + (ws.getCell("R177").value > 22 ? 1 : 0) + (ws.getCell("V177").value > 22 ? 1 : 0);

    ws.getCell("AF174").value = (Math.floor((ws.getCell("AE174").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF175").value = (Math.floor((ws.getCell("AE175").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF176").value = (Math.floor((ws.getCell("AE176").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AF177").value = (Math.floor((ws.getCell("AE177").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AG174").value = (ws.getCell("F174").value === 28 ? 1 : 0) + (ws.getCell("J174").value === 28 ? 1 : 0) + (ws.getCell("N174").value === 28 ? 1 : 0) + (ws.getCell("R174").value === 28 ? 1 : 0) + (ws.getCell("V174").value === 28 ? 1 : 0);
    ws.getCell("AG175").value = (ws.getCell("F175").value === 28 ? 1 : 0) + (ws.getCell("J175").value === 28 ? 1 : 0) + (ws.getCell("N175").value === 28 ? 1 : 0) + (ws.getCell("R175").value === 28 ? 1 : 0) + (ws.getCell("V175").value === 28 ? 1 : 0);
    ws.getCell("AG176").value = (ws.getCell("F176").value === 28 ? 1 : 0) + (ws.getCell("J176").value === 28 ? 1 : 0) + (ws.getCell("N176").value === 28 ? 1 : 0) + (ws.getCell("R176").value === 28 ? 1 : 0) + (ws.getCell("V176").value === 28 ? 1 : 0);
    ws.getCell("AG177").value = (ws.getCell("F177").value === 28 ? 1 : 0) + (ws.getCell("J177").value === 28 ? 1 : 0) + (ws.getCell("N177").value === 28 ? 1 : 0) + (ws.getCell("R177").value === 28 ? 1 : 0) + (ws.getCell("V177").value === 28 ? 1 : 0);

    ws.getCell("AH174").value = (Math.floor((ws.getCell("AG174").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH175").value = (Math.floor((ws.getCell("AG175").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH176").value = (Math.floor((ws.getCell("AG176").value / weekTotal) * 100) || 0) + "%";
    ws.getCell("AH177").value = (Math.floor((ws.getCell("AG177").value / weekTotal) * 100) || 0) + "%";

    ws.getCell("AI174").value = Math.floor((ws.getCell("D174").value + ws.getCell("H174").value + ws.getCell("L174").value + ws.getCell("P174").value + ws.getCell("T174").value) / weekTotal) || 0;
    ws.getCell("AI175").value = Math.floor((ws.getCell("D175").value + ws.getCell("H175").value + ws.getCell("L175").value + ws.getCell("P175").value + ws.getCell("T175").value) / weekTotal) || 0;
    ws.getCell("AI176").value = ws.getCell("AI174").value + ws.getCell("AI175").value;
    ws.getCell("AI177").value = Math.floor(ws.getCell("AI176").value / 4) || 0;

    ws.getCell("AJ174").value = Math.floor((ws.getCell("E174").value + ws.getCell("M174").value + ws.getCell("Q174").value + ws.getCell("U174").value) / weekTotal) || 0;
    ws.getCell("AJ175").value = Math.floor((ws.getCell("E175").value + ws.getCell("M175").value + ws.getCell("Q175").value + ws.getCell("U175").value) / weekTotal) || 0;
    ws.getCell("AJ176").value = ws.getCell("AJ174").value + ws.getCell("AJ175").value;
    ws.getCell("AJ177").value = Math.floor(ws.getCell("AJ176").value / 4) || 0;

    // STYLE FOR ALL CELLS
    ws.eachRow(function (row, rowNumber) {
      if (
        ![
          1,
          3,
          4,
          14,
          24,
          34,
          44,
          50,
          51,
          70,
          93,
          114,
          136,
          143,
          151,
          152,
          159,
          166,
          171,
        ].includes(rowNumber)
      ) {
        ws.getRow(rowNumber).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
      }
    });

    ws.eachRow(function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, (cell, cellNumber) => {
        if (
          typeof ws.getCell(cell._address).value === "number" &&
          ws.getCell(cell._address).value < 0
        ) {
          // IF LESS THAN 0 OR MORE THAN 28 THEN IT WILL BE SHOW IN RED
          ws.getCell(cell._address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      })
    })

    checkWeekMoreThan28.map((cItem) => {
      if (
        typeof ws.getCell(cItem).value === "number" &&
        ws.getCell(cItem).value > 28
      ) {
        ws.getCell(cItem).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FF0000",
          },
        };
      } else if (
        typeof ws.getCell(cItem).value === "number" &&
        ws.getCell(cItem).value === 28
      ) {
        ws.getCell(cItem).font = {
          color: { argb: "FF0000" },
        };
      }
    })
  });
};
