import React, { useEffect, useRef, useState } from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { FormGroup, Input } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import CloseRounded from "@material-ui/icons/CloseRounded";

interface Values {
  name: string;
  name_chi: string;
  icon: any;
  isActive: boolean;
}
interface Params {
  id?: string;
}

const AddServiceTag = () => {
  const params: Params = useParams();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const mimeType: string[] = ["image/png", "image/jpeg"];
  const [latestReportData, setLatestReportData] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>("");
  const history = useHistory();

  useEffect(() => {
    if (selectedImage) {
      formik.setFieldValue("icon", selectedImage);
    }
    // eslint-disable-next-line
  }, [selectedImage]);

  const uploadImage = async () => {
    if (inputFile.current?.files) {
      for (const element of inputFile.current.files) {
        if (mimeType.includes(element.type)) {
          const formData = new FormData();
          formData.append("file", element);
          try {
            const { data }: any = await api({
              url: "/file/file-upload",
              method: "post",
              body: formData,
            });
            if (data.serverResponse.isError) {
              formik.setFieldError("icon", "icon is required");
              toast.error(data.serverResponse.message);
            } else {
              setSelectedImage(data.data.fileUrl);
              formik.setFieldValue("icon", data.data.fileUrl);
              toast.success("icon uploaded");
            }
          } catch (err) {
            formik.setFieldError("icon", "Icon is required");
            toast.error("icon not uploaded, please try again");
          }
        }
      }
    }
  };

  const addServiceTag = async (values: Values) => {
    let body = {
      name: values.name,
      name_chi: values.name_chi,
      icon: values.icon,
      isActive: values.isActive,
    };

    try {
      const { data }: any = await api({
        url: params?.id
          ? `service/tag/update/${params?.id}`
          : `service/tag/add`,
        method: params?.id ? "put" : "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(
          params?.id ? "Data Updated Successfully" : "Data added successfully"
        );
        history.push("/companyService/serviceTag");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_chi: params.id ? latestReportData && latestReportData?.name_chi : "",
      name: params.id ? latestReportData && latestReportData?.name : "",
      icon: params.id ? latestReportData && latestReportData?.icon : "",
      isActive: params.id
        ? latestReportData && latestReportData?.isActive
        : "true",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name (English) is required"),
      name_chi: Yup.string().required("Name (Chinese) is required"),
      icon: Yup.string().required("Icon is required"),
    }),
    onSubmit: (values: any) => {
      addServiceTag({
        ...values,
      });
    },
  });

  const dropHandler = (ev) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          let files = ev.dataTransfer.items[i].getAsFile();
          if (files) {
            try {
              if (mimeType.includes(files.type)) {
                const formData = new FormData();
                formData.append("file", files);
                api({
                  url: "/file/file-upload",
                  method: "post",
                  body: formData,
                }).then((response: any) => {
                  if (response.data.serverResponse.isError) {
                    formik.setFieldError("icon", "icon is required");
                    toast.error(response.data.serverResponse.message);
                  } else {
                    setSelectedImage(response.data.data.fileUrl);
                    formik.setFieldValue("icon", response.data.data.fileUrl);
                    toast.success("icon uploaded");
                  }
                });
              } else {
                toast.error("Please select icon file type only");
              }
            } catch (e) {
              toast.error("icon not uploaded , please try again");
            }
          } else {
            toast.error("File size limit not more than 200kb");
          }
        }
      }
    }
  };

  const getLatestReportById = async () => {
    try {
      const { data }: any = await api({
        url: `/service/tag/details/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: any = data.result.data;
        setLatestReportData({
          ...item,
          status: item.isActive ? "Active" : "Inactive",
        });
        setSelectedImage(item?.icon ? item?.icon : "");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getLatestReportById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dragOverHandler = (ev: any) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const onUploadFileButtonClick = () => {
    const fileUploadElement: any = document.getElementById("upload-image");
    if (fileUploadElement) {
      fileUploadElement.click();
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={
            Boolean(params.id) ? "Service Tag Details" : "Create Service Tag"
          }
        ></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Name (Chinese)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  // disabled={Boolean(params.id)}
                  type="text"
                  className="form-control"
                  id="name_chi"
                  placeholder="Please enter name"
                  name="name_chi"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name_chi || ""}
                />
                {formik.touched.name_chi && formik.errors.name_chi ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.name_chi}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Name (English)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  // disabled={Boolean(params.id)}
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Please enter name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Upload Icon
            </div>
            <div className="col-4">
              <div className="position-relative fit-content w-100">
                <div
                  style={{
                    padding: "2rem",
                    textAlign: "center",
                    background: "#FFF",
                    borderRadius: "0.5rem",
                    borderStyle: "dashed",
                  }}
                  onDrop={(e) => dropHandler(e)}
                  onDragOver={(e) => dragOverHandler(e)}
                  onClick={() => onUploadFileButtonClick()}
                >
                  <p>Drop files here or click to upload</p>
                  <input
                    type="file"
                    name="upload-image"
                    id="upload-image"
                    onChange={uploadImage}
                    accept={mimeType.join(",")}
                    className="w-100 h-100"
                    ref={inputFile}
                    style={{ display: "none" }}
                    multiple={false}
                  />
                </div>
                {selectedImage !== "" && (
                  <div className="container border mt-4">
                    <div
                      className="row pt-1 pb-2 "
                      style={{ overflowX: "auto" }}
                    >
                      <div className="d-flex">
                        {formik.values.icon && formik.values.icon !== "" ? (
                          <div>
                            <div className="position-relative fit-content m-2 ml-0 cursor-pointer">
                              <CloseRounded
                                className="position-absolute bg-secondary"
                                onClick={() => {
                                  setSelectedImage("");
                                  formik.setFieldValue("icon", "");
                                }}
                              />

                              <img
                                style={{
                                  height: "100px",
                                  width: "150px",
                                  border: "1px solid gray",
                                }}
                                src={formik.values.icon}
                                alt=""
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
                {formik.touched.icon && formik.errors.icon ? (
                  <div className="text-danger mb-0 mt-4 pt-1">
                    {formik.errors.icon}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {params.id && (
            <div className="row mt-8">
              <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                Status
              </div>
              <div className="col-4">
                <FormGroup style={{ position: "relative", minWidth: "8rem" }}>
                  <Input
                    type="select"
                    className="form-control"
                    id="exampleSelect"
                    placeholder="Status"
                    name="isActive"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={String(formik.values.isActive) || "true"}
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Input>
                  <ExpandMoreRoundedIcon
                    style={{ position: "absolute", right: "1rem", top: "10px" }}
                  />
                  {formik.touched.isActive && formik.errors.isActive ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.isActive}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/companyService/serviceTag">
                Back
              </Link>
            </div>
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params?.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default AddServiceTag;
