export const sortString = (
  field: string,
  order: string,
  sortingType?: any
) => {
  let sortOrder;
  if (order === "asc") {
    sortOrder = 1;
  } else {
    sortOrder = -1;
  }
  let sortParam = `sort={"${field}":${sortOrder}}`;
  if (sortParam) {
    sortingType(sortParam);
  }
};
