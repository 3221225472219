import _ from "lodash";
import {
  borderRightMediumCell,
  borderRightThinCell,
  borderTopBottomMediumCell,
  borderTopRightBottomMediumCell,
  lightPurpleCell,
  lightGreenCell,
  yellowBgCell,
  lightBlueCell,
  alignmentCells,
  borderBottomMediumCell,
  borderBottomRightMediumCell,
  borderRightThingBottomMediumCell,
  borderRightMediumBottomThinCell,
  borderBottomRightThin,
  borderBottomThinCell,
} from "./summaryCellNumber.js";
import moment from "moment";
import { checkSummaryMoreThan28 } from "./checkCell.js";

const getRouteCount = (items: any, route: any, slot: any) => {
  let a8Total = 0;

  items
    .filter((x: any) => x.title === route && x.Time === slot)
    .map((x: any) => {
      a8Total = a8Total + x.onBoard;
    });

  return a8Total;
};

const getDelayCount = (items: any, route: any, slot: any) => {
  return (
    items.filter(
      (x: any) => x.title === route && x.Time === slot && x.delay > 15
    ).length || 0
  );
};

const getFullCounts = (items: any, route: any, slot: any, percentage: any) => {
  const count =
    items.filter(
      (x: any) =>
        x.title === route && x.Time === slot && x.fullPercentage >= percentage
    ).length || 0;

  return count;
};

const getStatusCounts = (items: any, route: any, slot: any, status: any) => {
  const count =
    items.filter(
      (x: any) => x.title === route && x.Time === slot && x.status === status
    ).length || 0;

  return count;
};

export const summarySheet = (wb: any, allItems: any, totalDay: any) => {
  const ws = wb.addWorksheet("Summary", {
    properties: {},
  });

  ws.getCell("A1").value = `Monthly Usage Report: ${moment(
    allItems[0].Date
  ).format("YYYY-MM")}`;

  ws.getCell("A3").value = "1)  Morning Shuttle Bus Service";
  ws.getCell("A4").value = "1.1) Bus Trip: LG > MP";

  let a8Total = 0;
  let a9Total = 0;

  const A8row = allItems
    .filter((x: any) => x.title === "LG > MP" && x.Time === "08:40")
    .map((x: any) => {
      a8Total = a8Total + x.passengers;
    });
  const A9row = allItems
    .filter((x: any) => x.title === "LG > MP" && x.Time === "08:40")
    .map((x: any) => {
      a9Total = a9Total + x.passengers;
    });

  ws.getCell("C8").value = getRouteCount(allItems, "LG > MP", "08:40");
  ws.getCell("C9").value = getRouteCount(allItems, "LG > MP", "09:40");
  ws.getCell("C10").value = ws.getCell("C8").value + ws.getCell("C9").value;
  ws.getCell("C11").value = Math.floor(ws.getCell("C10").value / 2) || 0;

  ws.getCell("C18").value = getRouteCount(allItems, "MP > LG", "10:10");
  ws.getCell("C19").value = getRouteCount(allItems, "MP > LG", "11:10");
  ws.getCell("C20").value = getRouteCount(allItems, "MP > LG", "12:10");
  ws.getCell("C21").value = getRouteCount(allItems, "MP > LG", "14:10");
  ws.getCell("C22").value = getRouteCount(allItems, "MP > LG", "15:10");
  ws.getCell("C23").value = getRouteCount(allItems, "MP > LG", "16:10");
  ws.getCell("C24").value = getRouteCount(allItems, "MP > LG", "17:10");
  ws.getCell("C25").value = getRouteCount(allItems, "MP > LG", "18:10");
  ws.getCell("C26").value =
    ws.getCell("C18").value +
    ws.getCell("C19").value +
    ws.getCell("C20").value +
    ws.getCell("C21").value +
    ws.getCell("C22").value +
    ws.getCell("C23").value +
    ws.getCell("C24").value +
    ws.getCell("C25").value;
  ws.getCell("C27").value = Math.floor(ws.getCell("C26").value / 8);

  ws.getCell("C33").value = getRouteCount(allItems, "LG > MP", "10:40");
  ws.getCell("C34").value = getRouteCount(allItems, "LG > MP", "11:40");
  ws.getCell("C35").value = getRouteCount(allItems, "LG > MP", "12:40");
  ws.getCell("C36").value = getRouteCount(allItems, "LG > MP", "14:40");
  ws.getCell("C37").value = getRouteCount(allItems, "LG > MP", "15:40");
  ws.getCell("C38").value = getRouteCount(allItems, "LG > MP", "16:40");
  ws.getCell("C39").value = getRouteCount(allItems, "LG > MP", "17:40");
  ws.getCell("C40").value = getRouteCount(allItems, "LG > MP", "18:40");
  ws.getCell("C41").value =
    ws.getCell("C33").value +
    ws.getCell("C34").value +
    ws.getCell("C35").value +
    ws.getCell("C36").value +
    ws.getCell("C37").value +
    ws.getCell("C38").value +
    ws.getCell("C39").value +
    ws.getCell("C40").value;
  ws.getCell("C42").value = Math.floor(ws.getCell("C41").value / 8);

  ws.getCell("D8").value = Math.floor(a8Total / totalDay);
  ws.getCell("D9").value = Math.floor(a9Total / totalDay);
  ws.getCell("D10").value = ws.getCell("D8").value + ws.getCell("D9").value;
  ws.getCell("D11").value = Math.floor(ws.getCell("D10").value / 2);

  ws.getCell("D18").value = Math.floor(ws.getCell("C18").value / totalDay);
  ws.getCell("D19").value = Math.floor(ws.getCell("C19").value / totalDay);
  ws.getCell("D20").value = Math.floor(ws.getCell("C20").value / totalDay);
  ws.getCell("D21").value = Math.floor(ws.getCell("C21").value / totalDay);
  ws.getCell("D22").value = Math.floor(ws.getCell("C22").value / totalDay);
  ws.getCell("D23").value = Math.floor(ws.getCell("C23").value / totalDay);
  ws.getCell("D24").value = Math.floor(ws.getCell("C24").value / totalDay);
  ws.getCell("D25").value = Math.floor(ws.getCell("C25").value / totalDay);
  ws.getCell("D26").value =
    ws.getCell("D18").value +
    ws.getCell("D19").value +
    ws.getCell("D20").value +
    ws.getCell("D21").value +
    ws.getCell("D22").value +
    ws.getCell("D23").value +
    ws.getCell("D24").value +
    ws.getCell("D25").value;
  ws.getCell("D27").value = Math.floor(ws.getCell("D26").value / 8);

  ws.getCell("D33").value = Math.floor(ws.getCell("C33").value / totalDay);
  ws.getCell("D34").value = Math.floor(ws.getCell("C34").value / totalDay);
  ws.getCell("D35").value = Math.floor(ws.getCell("C35").value / totalDay);
  ws.getCell("D36").value = Math.floor(ws.getCell("C36").value / totalDay);
  ws.getCell("D37").value = Math.floor(ws.getCell("C37").value / totalDay);
  ws.getCell("D38").value = Math.floor(ws.getCell("C38").value / totalDay);
  ws.getCell("D39").value = Math.floor(ws.getCell("C39").value / totalDay);
  ws.getCell("D40").value = Math.floor(ws.getCell("C40").value / totalDay);
  ws.getCell("D41").value =
    ws.getCell("D18").value +
    ws.getCell("D19").value +
    ws.getCell("D20").value +
    ws.getCell("D21").value +
    ws.getCell("D22").value +
    ws.getCell("D23").value +
    ws.getCell("D24").value +
    ws.getCell("D25").value;
  ws.getCell("D42").value = Math.floor(ws.getCell("D41").value / 8);

  ws.getCell("E8").value = getFullCounts(allItems, "LG > MP", "08:40", 80);
  ws.getCell("E9").value = getFullCounts(allItems, "LG > MP", "09:40", 80);

  ws.getCell("E18").value = getFullCounts(allItems, "MP > LG", "10:10", 80);
  ws.getCell("E19").value = getFullCounts(allItems, "MP > LG", "11:10", 80);
  ws.getCell("E20").value = getFullCounts(allItems, "MP > LG", "12:10", 80);
  ws.getCell("E21").value = getFullCounts(allItems, "MP > LG", "14:10", 80);
  ws.getCell("E22").value = getFullCounts(allItems, "MP > LG", "15:10", 80);
  ws.getCell("E23").value = getFullCounts(allItems, "MP > LG", "16:10", 80);
  ws.getCell("E24").value = getFullCounts(allItems, "MP > LG", "17:10", 80);
  ws.getCell("E25").value = getFullCounts(allItems, "MP > LG", "18:10", 80);

  ws.getCell("E33").value = getFullCounts(allItems, "LG > MP", "10:40", 80);
  ws.getCell("E34").value = getFullCounts(allItems, "LG > MP", "11:40", 80);
  ws.getCell("E35").value = getFullCounts(allItems, "LG > MP", "12:40", 80);
  ws.getCell("E36").value = getFullCounts(allItems, "LG > MP", "14:40", 80);
  ws.getCell("E37").value = getFullCounts(allItems, "LG > MP", "15:40", 80);
  ws.getCell("E38").value = getFullCounts(allItems, "LG > MP", "16:40", 80);
  ws.getCell("E39").value = getFullCounts(allItems, "LG > MP", "17:40", 80);
  ws.getCell("E40").value = getFullCounts(allItems, "LG > MP", "18:40", 80);

  ws.getCell("H8").value = getFullCounts(allItems, "LG > MP", "08:40", 100);
  ws.getCell("H9").value = getFullCounts(allItems, "LG > MP", "09:40", 100);

  ws.getCell("H18").value = getFullCounts(allItems, "MP > LG", "10:10", 100);
  ws.getCell("H19").value = getFullCounts(allItems, "MP > LG", "11:10", 100);
  ws.getCell("H20").value = getFullCounts(allItems, "MP > LG", "12:10", 100);
  ws.getCell("H21").value = getFullCounts(allItems, "MP > LG", "14:10", 100);
  ws.getCell("H22").value = getFullCounts(allItems, "MP > LG", "15:10", 100);
  ws.getCell("H23").value = getFullCounts(allItems, "MP > LG", "16:10", 100);
  ws.getCell("H24").value = getFullCounts(allItems, "MP > LG", "17:10", 100);
  ws.getCell("H25").value = getFullCounts(allItems, "MP > LG", "18:10", 100);

  ws.getCell("H33").value = getFullCounts(allItems, "LG > MP", "10:40", 100);
  ws.getCell("H34").value = getFullCounts(allItems, "LG > MP", "11:40", 100);
  ws.getCell("H35").value = getFullCounts(allItems, "LG > MP", "12:40", 100);
  ws.getCell("H36").value = getFullCounts(allItems, "LG > MP", "14:40", 100);
  ws.getCell("H37").value = getFullCounts(allItems, "LG > MP", "15:40", 100);
  ws.getCell("H38").value = getFullCounts(allItems, "LG > MP", "16:40", 100);
  ws.getCell("H39").value = getFullCounts(allItems, "LG > MP", "17:40", 100);
  ws.getCell("H40").value = getFullCounts(allItems, "LG > MP", "18:40", 100);

  ws.getCell("F8").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "08:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F9").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "09:40", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("F18").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "10:10", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F19").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "11:10", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F20").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "12:10", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F21").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "14:10", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F22").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "15:10", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F23").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "16:10", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F24").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "17:10", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F25").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "18:10", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("F33").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "10:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F34").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "11:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F35").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "12:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F36").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "14:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F37").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "15:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F38").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "16:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F39").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "17:40", 80) / totalDay) * 100
    ) + "%";
  ws.getCell("F40").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "18:40", 80) / totalDay) * 100
    ) + "%";

  ws.getCell("I8").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "08:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I9").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "09:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("I18").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "10:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I19").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "11:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I20").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "12:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I21").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "14:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I22").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "15:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I23").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "16:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I24").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "17:10", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I25").value =
    Math.floor(
      (getFullCounts(allItems, "MP > LG", "18:10", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("I33").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "10:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I34").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "11:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I35").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "12:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I36").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "14:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I37").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "15:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I38").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "16:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I39").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "17:40", 100) / totalDay) * 100
    ) + "%";
  ws.getCell("I40").value =
    Math.floor(
      (getFullCounts(allItems, "LG > MP", "18:40", 100) / totalDay) * 100
    ) + "%";

  ws.getCell("G8").value = ws.getCell("E8").value > 10 ? 1 : 0;
  ws.getCell("G9").value = ws.getCell("E9").value > 10 ? 1 : 0;
  ws.getCell("G10").value = ws.getCell("G8").value + ws.getCell("G9").value;

  ws.getCell("G18").value = ws.getCell("E18").value > 10 ? 1 : 0;
  ws.getCell("G19").value = ws.getCell("E19").value > 10 ? 1 : 0;
  ws.getCell("G20").value = ws.getCell("E20").value > 10 ? 1 : 0;
  ws.getCell("G21").value = ws.getCell("E21").value > 10 ? 1 : 0;
  ws.getCell("G22").value = ws.getCell("E22").value > 10 ? 1 : 0;
  ws.getCell("G23").value = ws.getCell("E23").value > 10 ? 1 : 0;
  ws.getCell("G24").value = ws.getCell("E24").value > 10 ? 1 : 0;
  ws.getCell("G25").value = ws.getCell("E25").value > 10 ? 1 : 0;
  ws.getCell("G26").value =
    ws.getCell("G18").value +
    ws.getCell("G19").value +
    ws.getCell("G20").value +
    ws.getCell("G21").value +
    ws.getCell("G22").value +
    ws.getCell("G23").value +
    ws.getCell("G24").value +
    ws.getCell("G25").value;

  ws.getCell("G33").value = ws.getCell("E33").value > 10 ? 1 : 0;
  ws.getCell("G34").value = ws.getCell("E34").value > 10 ? 1 : 0;
  ws.getCell("G35").value = ws.getCell("E35").value > 10 ? 1 : 0;
  ws.getCell("G36").value = ws.getCell("E36").value > 10 ? 1 : 0;
  ws.getCell("G37").value = ws.getCell("E37").value > 10 ? 1 : 0;
  ws.getCell("G38").value = ws.getCell("E38").value > 10 ? 1 : 0;
  ws.getCell("G39").value = ws.getCell("E39").value > 10 ? 1 : 0;
  ws.getCell("G40").value = ws.getCell("E40").value > 10 ? 1 : 0;
  ws.getCell("G41").value =
    ws.getCell("G33").value +
    ws.getCell("G34").value +
    ws.getCell("G35").value +
    ws.getCell("G36").value +
    ws.getCell("G37").value +
    ws.getCell("G38").value +
    ws.getCell("G39").value +
    ws.getCell("G40").value;

  ws.getCell("J8").value = ws.getCell("H8").value > 10 ? 1 : 0;
  ws.getCell("J9").value = ws.getCell("H9").value > 10 ? 1 : 0;
  ws.getCell("J10").value = ws.getCell("J8").value + ws.getCell("J9").value;

  ws.getCell("J18").value = ws.getCell("H18").value > 10 ? 1 : 0;
  ws.getCell("J19").value = ws.getCell("H19").value > 10 ? 1 : 0;
  ws.getCell("J20").value = ws.getCell("H20").value > 10 ? 1 : 0;
  ws.getCell("J21").value = ws.getCell("H21").value > 10 ? 1 : 0;
  ws.getCell("J22").value = ws.getCell("H22").value > 10 ? 1 : 0;
  ws.getCell("J23").value = ws.getCell("H23").value > 10 ? 1 : 0;
  ws.getCell("J24").value = ws.getCell("H24").value > 10 ? 1 : 0;
  ws.getCell("J25").value = ws.getCell("H25").value > 10 ? 1 : 0;
  ws.getCell("J26").value =
    ws.getCell("J18").value +
    ws.getCell("J19").value +
    ws.getCell("J20").value +
    ws.getCell("J21").value +
    ws.getCell("J22").value +
    ws.getCell("J23").value +
    ws.getCell("J24").value +
    ws.getCell("J25").value;

  ws.getCell("J33").value = ws.getCell("H33").value > 10 ? 1 : 0;
  ws.getCell("J34").value = ws.getCell("H34").value > 10 ? 1 : 0;
  ws.getCell("J35").value = ws.getCell("H35").value > 10 ? 1 : 0;
  ws.getCell("J36").value = ws.getCell("H36").value > 10 ? 1 : 0;
  ws.getCell("J37").value = ws.getCell("H37").value > 10 ? 1 : 0;
  ws.getCell("J38").value = ws.getCell("H38").value > 10 ? 1 : 0;
  ws.getCell("J39").value = ws.getCell("H39").value > 10 ? 1 : 0;
  ws.getCell("J40").value = ws.getCell("H40").value > 10 ? 1 : 0;
  ws.getCell("J41").value =
    ws.getCell("J33").value +
    ws.getCell("J34").value +
    ws.getCell("J34").value +
    ws.getCell("J36").value +
    ws.getCell("J37").value +
    ws.getCell("J38").value +
    ws.getCell("J39").value +
    ws.getCell("J40").value;

  ws.getCell("C48").value = ws.getCell("C26").value + ws.getCell("C41").value;
  ws.getCell("C49").value = ws.getCell("C27").value + ws.getCell("C42").value;
  ws.getCell("D48").value = Math.floor(ws.getCell("C48").value / totalDay);
  ws.getCell("D49").value = Math.floor(ws.getCell("D48").value / totalDay);

  // TO SHOW ALL WORKING DAY
  ws.getCell("Q3").value = "(no. of working day)";
  ws.getCell("P3").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFC000" },
  };

  ws.getCell("K8").value = getDelayCount(allItems, "LG > MP", "08:40");
  ws.getCell("L8").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "08:40") / totalDay) * 100) +
    "%";
  ws.getCell("K9").value = getDelayCount(allItems, "LG > MP", "09:40");
  ws.getCell("L9").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "09:40") / totalDay) * 100) +
    "%";

  ws.getCell("K10").value = ws.getCell("K8").value + ws.getCell("K9").value;
  ws.getCell("M10").value = ws.getCell("M8").value + ws.getCell("M9").value;

  ws.getCell("K18").value = getDelayCount(allItems, "MP > LG", "10:10");
  ws.getCell("L18").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "10:10") / totalDay) * 100) +
    "%";
  ws.getCell("K19").value = getDelayCount(allItems, "MP > LG", "11:10");
  ws.getCell("L19").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "11:10") / totalDay) * 100) +
    "%";
  ws.getCell("K20").value = getDelayCount(allItems, "MP > LG", "12:10");
  ws.getCell("L20").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "12:10") / totalDay) * 100) +
    "%";
  ws.getCell("K21").value = getDelayCount(allItems, "MP > LG", "14:10");
  ws.getCell("L21").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "14:10") / totalDay) * 100) +
    "%";
  ws.getCell("K22").value = getDelayCount(allItems, "MP > LG", "15:10");
  ws.getCell("L22").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "15:10") / totalDay) * 100) +
    "%";
  ws.getCell("K23").value = getDelayCount(allItems, "MP > LG", "16:10");
  ws.getCell("L23").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "16:10") / totalDay) * 100) +
    "%";
  ws.getCell("K24").value = getDelayCount(allItems, "MP > LG", "17:10");
  ws.getCell("L24").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "17:10") / totalDay) * 100) +
    "%";
  ws.getCell("K25").value = getDelayCount(allItems, "MP > LG", "18:10");
  ws.getCell("L25").value =
    Math.floor((getDelayCount(allItems, "MP > LG", "18:10") / totalDay) * 100) +
    "%";
  ws.getCell("K26").value =
    ws.getCell("K18").value +
    ws.getCell("K19").value +
    ws.getCell("K20").value +
    ws.getCell("K21").value +
    ws.getCell("K22").value +
    ws.getCell("K23").value +
    ws.getCell("K24").value +
    ws.getCell("K25").value;

  ws.getCell("M8").value = getStatusCounts(
    allItems,
    "LG > MP",
    "08:40",
    "CANCEL"
  );
  ws.getCell("N8").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "08:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M9").value = getStatusCounts(
    allItems,
    "LG > MP",
    "09:40",
    "CANCEL"
  );
  ws.getCell("N9").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "09:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M18").value = getStatusCounts(
    allItems,
    "MP > LG",
    "10:10",
    "CANCEL"
  );
  ws.getCell("N18").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "10:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M19").value = getStatusCounts(
    allItems,
    "MP > LG",
    "11:10",
    "CANCEL"
  );
  ws.getCell("N19").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "11:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M20").value = getStatusCounts(
    allItems,
    "MP > LG",
    "12:10",
    "CANCEL"
  );
  ws.getCell("N20").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "12:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M21").value = getStatusCounts(
    allItems,
    "MP > LG",
    "14:10",
    "CANCEL"
  );
  ws.getCell("N21").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "14:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M22").value = getStatusCounts(
    allItems,
    "MP > LG",
    "15:10",
    "CANCEL"
  );
  ws.getCell("N22").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "15:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M23").value = getStatusCounts(
    allItems,
    "MP > LG",
    "16:10",
    "CANCEL"
  );
  ws.getCell("N23").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "16:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M24").value = getStatusCounts(
    allItems,
    "MP > LG",
    "17:10",
    "CANCEL"
  );
  ws.getCell("N24").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "17:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M25").value = getStatusCounts(
    allItems,
    "MP > LG",
    "18:10",
    "CANCEL"
  );
  ws.getCell("N25").value =
    Math.floor(
      (getStatusCounts(allItems, "MP > LG", "18:10", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M26").value =
    ws.getCell("M18").value +
    ws.getCell("M19").value +
    ws.getCell("M20").value +
    ws.getCell("M21").value +
    ws.getCell("M22").value +
    ws.getCell("M23").value +
    ws.getCell("M24").value +
    ws.getCell("M25").value;

  ws.getCell("K33").value = getDelayCount(allItems, "LG > MP", "10:40");
  ws.getCell("L33").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "10:40") / totalDay) * 100) +
    "%";

  ws.getCell("K34").value = getDelayCount(allItems, "LG > MP", "11:40");
  ws.getCell("L34").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "11:40") / totalDay) * 100) +
    "%";

  ws.getCell("K35").value = getDelayCount(allItems, "LG > MP", "12:40");
  ws.getCell("L35").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "12:40") / totalDay) * 100) +
    "%";

  ws.getCell("K36").value = getDelayCount(allItems, "LG > MP", "14:40");
  ws.getCell("L36").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "14:40") / totalDay) * 100) +
    "%";

  ws.getCell("K37").value = getDelayCount(allItems, "LG > MP", "15:40");
  ws.getCell("L37").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "15:40") / totalDay) * 100) +
    "%";

  ws.getCell("K38").value = getDelayCount(allItems, "LG > MP", "16:40");
  ws.getCell("L38").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "16:40") / totalDay) * 100) +
    "%";

  ws.getCell("K39").value = getDelayCount(allItems, "LG > MP", "17:40");
  ws.getCell("L39").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "17:40") / totalDay) * 100) +
    "%";

  ws.getCell("K40").value = getDelayCount(allItems, "LG > MP", "18:40");
  ws.getCell("L40").value =
    Math.floor((getDelayCount(allItems, "LG > MP", "18:40") / totalDay) * 100) +
    "%";

  ws.getCell("K41").value =
    ws.getCell("K33").value +
    ws.getCell("K34").value +
    ws.getCell("K35").value +
    ws.getCell("K36").value +
    ws.getCell("K37").value +
    ws.getCell("K38").value +
    ws.getCell("K39").value +
    ws.getCell("K40").value;

  ws.getCell("M33").value = getStatusCounts(
    allItems,
    "LG > MP",
    "10:40",
    "CANCEL"
  );
  ws.getCell("N33").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "10:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M34").value = getStatusCounts(
    allItems,
    "LG > MP",
    "11:40",
    "CANCEL"
  );
  ws.getCell("N34").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "11:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M35").value = getStatusCounts(
    allItems,
    "LG > MP",
    "12:40",
    "CANCEL"
  );
  ws.getCell("N35").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "12:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M36").value = getStatusCounts(
    allItems,
    "LG > MP",
    "14:40",
    "CANCEL"
  );
  ws.getCell("N36").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "14:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M37").value = getStatusCounts(
    allItems,
    "LG > MP",
    "15:40",
    "CANCEL"
  );
  ws.getCell("N37").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "15:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M38").value = getStatusCounts(
    allItems,
    "LG > MP",
    "16:40",
    "CANCEL"
  );
  ws.getCell("N38").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "16:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M39").value = getStatusCounts(
    allItems,
    "LG > MP",
    "17:40",
    "CANCEL"
  );
  ws.getCell("N39").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "17:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M40").value = getStatusCounts(
    allItems,
    "LG > MP",
    "18:40",
    "CANCEL"
  );
  ws.getCell("N40").value =
    Math.floor(
      (getStatusCounts(allItems, "LG > MP", "18:40", "CANCEL") / totalDay) * 100
    ) + "%";

  ws.getCell("M41").value =
    ws.getCell("M33").value +
    ws.getCell("M34").value +
    ws.getCell("M35").value +
    ws.getCell("M36").value +
    ws.getCell("M37").value +
    ws.getCell("M38").value +
    ws.getCell("M39").value +
    ws.getCell("M40").value;

  ws.columns = [
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 20, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
  ];

  ws.addRow([]).height = 15;

  ws.getCell("E48").value =
    ws.getCell("K26").value + ws.getCell("K41").value || 0;
  ws.getCell("G48").value =
    ws.getCell("M26").value + ws.getCell("M41").value || 0;

  // BOLD TEXT
  ["A1", "A3", "A4", "A13", "A14", "A29", "A45"].map((citem: any) => {
    ws.getCell(citem).font = { bold: true, color: { argb: "00000000" } };
  });

  ws.getCell("P3").value = totalDay;
  ws.getCell("A7").value = "LG >";
  ws.getCell("B7").value = "MP";
  ws.getCell("K6").value = "Late (≥15 mins)";
  ws.getCell("K16").value = "Late (≥15 mins)";
  ws.getCell("K31").value = "Late (≥15 mins)";
  ws.getCell("M6").value = "Cancellation";
  ws.getCell("M16").value = "Cancellation";
  ws.getCell("M31").value = "Cancellation";
  ws.getCell("A45").value = "Daily Total No. of Passenger";
  ws.getCell("C7").value = "Total No. of Passenger (per trip)";
  ws.getCell("C17").value = "Total No. of Passenger (per trip)";
  ws.getCell("C32").value = "Total No. of Passenger (per trip)";
  ws.getCell("D7").value = "Average per trip";
  ws.getCell("D17").value = "Average per trip";
  ws.getCell("D32").value = "Average per trip";
  ws.getCell("E7").value = "80% Full";
  ws.getCell("E17").value = "80% Full";
  ws.getCell("E32").value = "80% Full";
  ws.getCell("G7").value = ">10 days";
  ws.getCell("G17").value = ">10 days";
  ws.getCell("G32").value = ">10 days";
  ws.getCell("H7").value = "100% Full";
  ws.getCell("H17").value = "100% Full";
  ws.getCell("H17").value = "100% Full";
  ws.getCell("H32").value = "100% Full";
  ws.getCell("J7").value = ">10 days";
  ws.getCell("J17").value = ">10 days";
  ws.getCell("J32").value = ">10 days";
  ws.getCell("K7").value = "No. of Day";
  ws.getCell("K17").value = "No. of Day";
  ws.getCell("K32").value = "No. of Day";
  ws.getCell("L7").value = "%";
  ws.getCell("L17").value = "%";
  ws.getCell("L32").value = "%";
  ws.getCell("M7").value = "No. of Day";
  ws.getCell("M17").value = "No. of Day";
  ws.getCell("M32").value = "No. of Day";
  ws.getCell("N7").value = "%";
  ws.getCell("N17").value = "%";
  ws.getCell("N32").value = "%";

  ws.getCell("E47").value = "Late (≥15 mins)\r\nNo. of Days";
  ws.getCell("G47").value = "Cancellation\r\nNo.of Days";
  ws.getCell("C47").value = "Total no. of Passenger (per trip)";
  ws.getCell("D47").value = "Average per trip";

  ws.getCell("A17").value = "MP >";
  ws.getCell("B17").value = "LG";
  ws.getCell("C16").value = "MP > LG";

  ws.getCell("A32").value = "LG >";
  ws.getCell("B32").value = "MP";
  ws.getCell("C6").value = "LG > MP";
  ws.getCell("C31").value = "LG > MP";

  ws.getCell("A8").value = "08:40";
  ws.getCell("A9").value = "09:40";
  ws.getCell("B8").value = "09:10";
  ws.getCell("B9").value = "10:10";

  ws.getCell("A18").value = "10:10";
  ws.getCell("A19").value = "11:10";
  ws.getCell("A20").value = "12:10";
  ws.getCell("A21").value = "14:10";
  ws.getCell("A22").value = "15:10";
  ws.getCell("A23").value = "16:10";
  ws.getCell("A24").value = "17:10";
  ws.getCell("A25").value = "18:10";

  ws.getCell("B18").value = "10:40";
  ws.getCell("B19").value = "11:40";
  ws.getCell("B20").value = "12:40";
  ws.getCell("B21").value = "14:40";
  ws.getCell("B22").value = "15:40";
  ws.getCell("B23").value = "16:40";
  ws.getCell("B24").value = "17:40";
  ws.getCell("B25").value = "18:40";

  ws.getCell("A33").value = "10:40";
  ws.getCell("A34").value = "11:40";
  ws.getCell("A35").value = "12:40";
  ws.getCell("A36").value = "14:40";
  ws.getCell("A37").value = "15:40";
  ws.getCell("A38").value = "16:40";
  ws.getCell("A39").value = "17:40";
  ws.getCell("A40").value = "18:40";

  ws.getCell("B33").value = "11:10";
  ws.getCell("B34").value = "12:10";
  ws.getCell("B35").value = "13:10";
  ws.getCell("B36").value = "15:10";
  ws.getCell("B37").value = "16:10";
  ws.getCell("B38").value = "17:10";
  ws.getCell("B39").value = "18:10";
  ws.getCell("B40").value = "19:10";

  ["A10", "A26", "A41"].map((cItem: any) => {
    ws.getCell(cItem).value = "Daily Total No. of Passenger";
  });

  ["A11", "A27", "A42"].map((cItem: any) => {
    ws.getCell(cItem).value = "Average per trip (Daily)";
  });

  // ROW HEIGHT = 20
  [6, 16, 31].map((rItm: any) => {
    ws.getRow(rItm).height = 20;
  });

  // ROW HEIGHT = 35
  [7, 17, 32, 47].map((rItm: any) => {
    ws.getRow(rItm).height = 45;
  });

  // APPLY BACKGROUND COLOR (LIGHT YELLOW)
  yellowBgCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFCC" },
    };
  });

  // APPLY BACKGROUND COLOR (LIGHT BLUE)
  lightBlueCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "D9E1F2" },
    };
  });

  // APPLY BACKGROUND COLOR (LIGHT PURPLE)
  lightPurpleCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "CCCCFF" },
    };
  });

  // APPLY BACKGROUND COLOR (LIGHT GREEN)
  lightGreenCell.map((cItem: any) => {
    ws.getCell(cItem).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "E2EFDA" },
    };
  });

  // APPLY BOLD BORDER AT RIGHT
  borderRightMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM RIGHT
  borderBottomRightMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "medium", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER RIGHT MEDIUM BOTTOM THIN
  borderRightMediumBottomThinCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "medium", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
  borderRightThingBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
  borderBottomRightThin.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT BOTTOM
  borderBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  // APPLY THIN BORDER AT BOTTOM
  borderBottomThinCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      bottom: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY THIN BORDER AT RIGHT
  borderRightThinCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  // APPLY BOLD BORDER AT TOP & BOTTOM
  borderTopBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      top: { style: "medium", color: { argb: "000000" } },
      // left: { style: "thick", color: { argb: "000000FF" } },
      bottom: { style: "medium", color: { argb: "000000" } },
      // right: { style: "thick", color: { argb: "FF00FF00" } },
    };
  });

  ws.mergeCells("K6:L6");
  ws.mergeCells("K16:L16");
  ws.mergeCells("E7:F7");
  ws.mergeCells("H7:I7");
  ws.mergeCells("H17:I17");
  ws.mergeCells("H32:I32");
  ws.mergeCells("A10:B10");
  ws.mergeCells("E17:F17");
  ws.mergeCells("E32:F32");
  ws.mergeCells("A11:B11");
  ws.mergeCells("A26:B26");
  ws.mergeCells("A27:B27");
  ws.mergeCells("A41:B41");
  ws.mergeCells("A42:B42");
  ws.mergeCells("A47:B47");
  ws.mergeCells("E47:F47");
  ws.mergeCells("G47:H47");
  ws.mergeCells("A48:B48");
  ws.mergeCells("A49:B49");
  ws.mergeCells("M6:N6");
  ws.mergeCells("M16:N16");
  ws.mergeCells("M31:N31");
  ws.mergeCells("A1:B1");
  ws.mergeCells("A3:B3");
  ws.mergeCells("A4:B4");

  ws.getCell(`A48`).value = "Daily Total No. of Passenger";
  ws.getCell(`A49`).value = "Average per trip (Daily)";

  ws.getCell(`A13`).value = "2) Regular Shuttle Bus Services";
  ws.getCell(`A14`).value = "2.1) Bus Trip: MP > LG";
  ws.getCell(`A29`).value = "2.2) Bus Trip: LG > MP";
  ws.getCell(`A45`).value =
    "Total No. of Passenger of Regular Shuttle Bus Services (2.1+2.2)";

  ws.getCell("C47").border = {
    top: { style: "medium", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "medium", color: { argb: "000000" } },
  };

  // APPLY BOLD BORDER AT TOP RIGHT BOTTOM
  borderTopRightBottomMediumCell.map((cItem: any) => {
    ws.getCell(cItem).border = {
      top: { style: "medium", color: { argb: "000000" } },
      right: { style: "medium", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
    };
  });

  ws.eachRow(function(row: any, rowNumber: any) {
    if (![1, 3, 4, 13, 14, 29, 45].includes(rowNumber)) {
      ws.getRow(rowNumber).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };
    }

    row.eachCell({ includeEmpty: true }, (cell, cellNumber) => {
      if (
        typeof ws.getCell(cell._address).value === "number" &&
        ws.getCell(cell._address).value < 0
      ) {
        // IF LESS THAN 0 OR MORE THAN 28 THEN IT WILL BE SHOW IN RED
        ws.getCell(cell._address).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      }
    });
  });

  checkSummaryMoreThan28.map((cItem: any) => {
    if (
      typeof ws.getCell(cItem).value === "number" &&
      ws.getCell(cItem).value > 28
    ) {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF0000",
        },
      };
    } else if (
      typeof ws.getCell(cItem).value === "number" &&
      ws.getCell(cItem).value === 28
    ) {
      ws.getCell(cItem).font = {
        color: { argb: "FF0000" },
      };
    }
  });
};
