import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
import moment from "moment";
interface Values {
  driverFCMKey: string;
  fromTime: string;
  toTime: string;
}
const ReachedValue = () => {
  const [driverFcmKey, setDriverFcmKey] = useState<any>();
  const [fromTime, setFromTime] = useState<any>();
  const [toTime, setToTime] = useState<any>();

  const saveReachThresholdData = async (values: Values) => {
    try {
      const body = {
        description: values.driverFCMKey,
        type: "driver_fcm_config",
        additional_attributes: [
          { name: "fromTime", value: values.fromTime },
          { name: "toTime", value: values.toTime },
        ]
      };
      const { data }: any = await api({
        url: `/legal/add`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getDriverFcmKey();
  }, []);

  const getDriverFcmKey = async () => {
    try {
      const { data }: any = await api({
        url: `/legal/list?type=driver_fcm_config`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setDriverFcmKey(data.result.data);
        if (data?.result?.data?.additional_attributes?.length) {
          for (const additionalAttribute of data?.result?.data?.additional_attributes) {
            if (additionalAttribute.name === "fromTime") {
              setFromTime(additionalAttribute.value)
            }
            if (additionalAttribute.name === "toTime") {
              setToTime(additionalAttribute.value)
            }
          }
        }
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      driverFCMKey: driverFcmKey?.description,
      fromTime: "" || fromTime,
      toTime: "" || toTime
    },
    validationSchema: Yup.object({
      driverFCMKey: Yup.string().required("Distance is required"),
      fromTime: Yup.string().nullable().required("From Time is required"),
      toTime: Yup.string().nullable().required("To Time is required").test("endTest", "Invalid Time", function (
        value
      ) {
        if (!value) return this.createError({
          message: "To time is required"
        });

        if (
          moment(this.parent.fromTime, "HH:mm").isSameOrAfter(
            moment(this.parent.toTime, "HH:mm")
          )
        ) {
          return this.createError({
            message: "To time must be after From time"
          })
        }
        return true;
      })
    }),
    onSubmit: (values: Values) => {
      saveReachThresholdData(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader title={"Driver App Config"}></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-6 col-sm-2 mb-4 mb-sm-0">
              <div>FCM Key</div>
              <div>(Developer mode. Please don't modify)</div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="driverFCMKey"
                  rows={3}
                  value={formik.values.driverFCMKey || ""}
                  name="driverFCMKey"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                ></textarea>
                {formik.touched.driverFCMKey && formik.errors.driverFCMKey ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.driverFCMKey}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6 col-sm-2">
              <div>Office Time</div>
            </div>
            <div className="col-4 d-flex justify-content-between">
              <div className="col-5 p-0">
                <input
                  type="time"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="fromTime"
                  id="fromTime"
                  value={formik.values.fromTime}
                />
                {formik.touched.fromTime && formik.errors.fromTime ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.fromTime}
                  </div>
                ) : null}
              </div>
              <div className="col-2 p-0 text-center d-flex my-2 justify-content-center">To</div>
              <div className="col-5 p-0">
                <input
                  type="time"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="toTime"
                  id="toTime"
                  value={formik.values.toTime}
                />
                {formik.touched.toTime && formik.errors.toTime ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.toTime}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

        </CardBody>

        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ReachedValue;
