export const checkWeekMoreThan28 = [
    "C7", "D7", "E7", "F7", "G7", "H7", "I7", "J7", "K7", "L7", "M7", "N7", "O7", "P7", "Q7", "R7", "S7", "T7", "U7", "V7",
    "C8", "D8", "E8", "F8", "G8", "H8", "I8", "J8", "K8", "L8", "M8", "N8", "O8", "P8", "Q8", "R8", "S8", "T8", "U8", "V8",
    "C9", "D9", "E9", "F9", "G9", "H9", "I9", "J9", "K9", "L9", "M9", "N9", "O9", "P9", "Q9", "R9", "S9", "T9", "U9", "V9",
    "C10", "D10", "E10", "F10", "G10", "H10", "I10", "J10", "K10", "L10", "M10", "N10", "O10", "P10", "Q10", "R10", "S10", "T10", "U10", "V10",

    "C12", "D12", "E12", "F12", "G12", "H12", "I12", "J12", "K12", "L12", "M12", "N12", "O12", "P12", "Q12", "R12", "S12", "T12", "U12", "V12",

    "X7", "X8", "X9", "X10", "X12",
    "AD7", "AD8", "AD9", "AD10", "AD12",
    "AI7", "AI8", "AI9", "AI10", "AI12",
    "AJ7", "AJ8", "AJ9", "AJ10", "AJ12",

    "C22", "D22", "E22", "F22", "G22", "H22", "I22", "J22", "K22", "L22", "M22", "N22", "O22", "P22", "Q22", "R22", "S22", "T22", "U22", "V22",

    "X17", "X18", "X19", "X20", "X22",
    "AD17", "AD18", "AD19", "AD20", "AD22",
    "AI17", "AI18", "AI19", "AI20", "AI22",
    "AJ17", "AJ18", "AJ19", "AJ20", "AJ22",

    "C32", "D32", "E32", "F32", "G32", "I32",

    "C17", "D17", "E17", "F17", "G17", "H17", "I17", "J17", "K17", "L17", "M17", "N17", "O17", "P17", "Q17", "R17", "S17", "T17", "U17", "V17",
    "C18", "D18", "E18", "F18", "G18", "H18", "I18", "J18", "K18", "L18", "M18", "N18", "O18", "P18", "Q18", "R18", "S18", "T18", "U18", "V18",
    "C19", "D19", "E19", "F19", "G19", "H19", "I19", "J19", "K19", "L19", "M19", "N19", "O19", "P19", "Q19", "R19", "S19", "T19", "U19", "V19",
    "C20", "D20", "E20", "F20", "G20", "H20", "I20", "J20", "K20", "L20", "M20", "N20", "O20", "P20", "Q20", "R20", "S20", "T20", "U20", "V20",

    "C41", "D41", "E41", "F41", "G41", "I41",

    "C27", "D27", "E27", "F27", "G27",
    "C28", "D28", "E28", "F28", "G28",
    "C29", "D29", "E29", "F29", "G29",
    "C30", "D30", "E30", "F30", "G30",
    "C37", "D37", "E37", "F37", "G37",
    "C38", "D38", "E38", "F38", "G38",
    "C39", "D39", "E39", "F39", "G39",

    "C48", "D48", "E48", "F48", "G48", "H48", "I48", "J48", "K48", "L48", "M48", "N48", "O48", "P48", "Q48", "R48", "S48", "T48", "U48", "V48", "X48", "AD48",

    "C54", "D54", "E54", "F54", "G54", "H54", "I54", "J54", "K54", "L54", "M54", "N54", "O54", "P54", "Q54", "R54", "S54", "T54", "U54", "V54",
    "C55", "D55", "E55", "F55", "G55", "H55", "I55", "J55", "K55", "L55", "M55", "N55", "O55", "P55", "Q55", "R55", "S55", "T55", "U55", "V55",
    "C56", "D56", "E56", "F56", "G56", "H56", "I56", "J56", "K56", "L56", "M56", "N56", "O56", "P56", "Q56", "R56", "S56", "T56", "U56", "V56",
    "C57", "D57", "E57", "F57", "G57", "H57", "I57", "J57", "K57", "L57", "M57", "N57", "O57", "P57", "Q57", "R57", "S57", "T57", "U57", "V57",
    "C58", "D58", "E58", "F58", "G58", "H58", "I58", "J58", "K58", "L58", "M58", "N58", "O58", "P58", "Q58", "R58", "S58", "T58", "U58", "V58",
    "C59", "D59", "E59", "F59", "G59", "H59", "I59", "J59", "K59", "L59", "M59", "N59", "O59", "P59", "Q59", "R59", "S59", "T59", "U59", "V59",
    "C60", "D60", "E60", "F60", "G60", "H60", "I60", "J60", "K60", "L60", "M60", "N60", "O60", "P60", "Q60", "R60", "S60", "T60", "U60", "V60",
    "C61", "D61", "E61", "F61", "G61", "H61", "I61", "J61", "K61", "L61", "M61", "N61", "O61", "P61", "Q61", "R61", "S61", "T61", "U61", "V61",
    "C62", "D62", "E62", "F62", "G62", "H62", "I62", "J62", "K62", "L62", "M62", "N62", "O62", "P62", "Q62", "R62", "S62", "T62", "U62", "V62",
    "C63", "D63", "E63", "F63", "G63", "H63", "I63", "J63", "K63", "L63", "M63", "N63", "O63", "P63", "Q63", "R63", "S63", "T63", "U63", "V63",
    "C64", "D64", "E64", "F64", "G64", "H64", "I64", "J64", "K64", "L64", "M64", "N64", "O64", "P64", "Q64", "R64", "S64", "T64", "U64", "V64",
    "C65", "D65", "E65", "F65", "G65", "H65", "I65", "J65", "K65", "L65", "M65", "N65", "O65", "P65", "Q65", "R65", "S65", "T65", "U65", "V65",
    "C66", "D66", "E66", "F66", "G66", "H66", "I66", "J66", "K66", "L66", "M66", "N66", "O66", "P66", "Q66", "R66", "S66", "T66", "U66", "V66",

    "C68", "F68", "G68", "J68", "K68", "N68", "O68", "R68", "S68", "V68", "X68", "AD68", "AI68", "AJ68",

    "C73", "D73", "E73", "F73", "G73", "H73", "I73", "J73", "K73", "L73", "M73", "N73", "O73", "P73", "Q73", "R73", "S73", "T73", "U73", "V73",
    "C74", "D74", "E74", "F74", "G74", "H74", "I74", "J74", "K74", "L74", "M74", "N74", "O74", "P74", "Q74", "R74", "S74", "T74", "U74", "V74",
    "C75", "D75", "E75", "F75", "G75", "H75", "I75", "J75", "K75", "L75", "M75", "N75", "O75", "P75", "Q75", "R75", "S75", "T75", "U75", "V75",
    "C76", "D76", "E76", "F76", "G76", "H76", "I76", "J76", "K76", "L76", "M76", "N76", "O76", "P76", "Q76", "R76", "S76", "T76", "U76", "V76",
    "C77", "D77", "E77", "F77", "G77", "H77", "I77", "J77", "K77", "L77", "M77", "N77", "O77", "P77", "Q77", "R77", "S77", "T77", "U77", "V77",
    "C78", "D78", "E78", "F78", "G78", "H78", "I78", "J78", "K78", "L78", "M78", "N78", "O78", "P78", "Q78", "R78", "S78", "T78", "U78", "V78",
    "C79", "D79", "E79", "F79", "G79", "H79", "I79", "J79", "K79", "L79", "M79", "N79", "O79", "P79", "Q79", "R79", "S79", "T79", "U79", "V79",
    "C80", "D80", "E80", "F80", "G80", "H80", "I80", "J80", "K80", "L80", "M80", "N80", "O80", "P80", "Q80", "R80", "S80", "T80", "U80", "V80",
    "C81", "D81", "E81", "F81", "G81", "H81", "I81", "J81", "K81", "L81", "M81", "N81", "O81", "P81", "Q81", "R81", "S81", "T81", "U81", "V81",
    "C82", "D82", "E82", "F82", "G82", "H82", "I82", "J82", "K82", "L82", "M82", "N82", "O82", "P82", "Q82", "R82", "S82", "T82", "U82", "V82",
    "C83", "D83", "E83", "F83", "G83", "H83", "I83", "J83", "K83", "L83", "M83", "N83", "O83", "P83", "Q83", "R83", "S83", "T83", "U83", "V83",
    "C84", "D84", "E84", "F84", "G84", "H84", "I84", "J84", "K84", "L84", "M84", "N84", "O84", "P84", "Q84", "R84", "S84", "T84", "U84", "V84",
    "C85", "D85", "E85", "F85", "G85", "H85", "I85", "J85", "K85", "L85", "M85", "N85", "O85", "P85", "Q85", "R85", "S85", "T85", "U85", "V85",
    "C86", "D86", "E86", "F86", "G86", "H86", "I86", "J86", "K86", "L86", "M86", "N86", "O86", "P86", "Q86", "R86", "S86", "T86", "U86", "V86",
    "C87", "D87", "E87", "F87", "G87", "H87", "I87", "J87", "K87", "L87", "M87", "N87", "O87", "P87", "Q87", "R87", "S87", "T87", "U87", "V87",
    "C88", "D88", "E88", "F88", "G88", "H88", "I88", "J88", "K88", "L88", "M88", "N88", "O88", "P88", "Q88", "R88", "S88", "T88", "U88", "V88",
    "C89", "D89", "E89", "F89", "G89", "H89", "I89", "J89", "K89", "L89", "M89", "N89", "O89", "P89", "Q89", "R89", "S89", "T89", "U89", "V89",

    "C91", "F91", "G91", "J91", "K91", "N91", "O91", "R91", "S91", "V91", "X91", "AD91", "AI91", "AJ91",

    "C96", "D96", "E96", "F96", "G96",
    "C97", "D97", "E97", "F97", "G97",
    "C98", "D98", "E98", "F98", "G98",
    "C99", "D99", "E99", "F99", "G99",
    "C100", "D100", "E100", "F100", "G100",
    "C101", "D101", "E101", "F101", "G101",
    "C102", "D102", "E102", "F102", "G102",
    "C103", "D103", "E103", "F103", "G103",
    "C104", "D104", "E104", "F104", "G104",
    "C105", "D105", "E105", "F105", "G105",
    "C106", "D106", "E106", "F106", "G106",
    "C107", "D107", "E107", "F107", "G107",
    "C108", "D108", "E108", "F108", "G108",
    "C109", "D109", "E109", "F109", "G109",
    "C110", "D110", "E110", "F110", "G110",

    "C112", "D112", "E112", "F112", "G112", "I112",

    "C116", "D116", "E116", "F116", "G116",
    "C117", "D117", "E117", "F117", "G117",
    "C118", "D118", "E118", "F118", "G118",
    "C119", "D119", "E119", "F119", "G119",
    "C120", "D120", "E120", "F120", "G120",
    "C121", "D121", "E121", "F121", "G121",
    "C122", "D122", "E122", "F122", "G122",
    "C123", "D123", "E123", "F123", "G123",
    "C124", "D124", "E124", "F124", "G124",
    "C125", "D125", "E125", "F125", "G125",
    "C126", "D126", "E126", "F126", "G126",
    "C127", "D127", "E127", "F127", "G127",
    "C128", "D128", "E128", "F128", "G128",
    "C129", "D129", "E129", "F129", "G129",
    "C130", "D130", "E130", "F130", "G130",
    "C131", "D131", "E131", "F131", "G131",
    "C132", "D132", "E132", "F132", "G132",

    "C134", "D134", "E134", "F134", "G134", "I134",

    "C138", "D138", "E138", "F138", "G138",
    "C139", "D139", "E139", "F139", "G139",

    "C141", "D141", "E141", "F141", "G141", "I141",

    "C145", "D145", "E145", "F145", "G145",
    "C146", "D146", "E146", "F146", "G146",

    "C148", "D148", "E148", "F148", "G148", "I148",

    "C154", "D154", "E154", "F154", "G154",
    "C155", "D155", "E155", "F155", "G155",

    "C157", "D157", "E157", "F157", "G157", "I157",

    "C161", "D161", "E161", "F161", "G161",
    "C162", "D162", "E162", "F162", "G162",

    "C164", "D164", "E164", "F164", "G164", "I164",

    "C169", "D169", "E169", "F169", "G169", "I169",

    "C174", "D174", "E174", "F174", "G174", "H174", "I174", "J174", "K174", "L174", "M174", "N174", "O174", "P174", "Q174", "R174", "S174", "T174", "U174", "V174",
    "C175", "D175", "E175", "F175", "G175", "H175", "I175", "J175", "K175", "L175", "M175", "N175", "O175", "P175", "Q175", "R175", "S175", "T175", "U175", "V175",
    "C177", "D177", "E177", "F177", "G177", "H177", "I177", "J177", "K177", "L177", "M177", "N177", "O177", "P177", "Q177", "R177", "S177", "T177", "U177", "V177",

    "X174", "X175", "X177",
    "AD174", "AD175", "AD177",
    "AI174", "AI175", "AI177",
    "AJ174", "AJ175", "AJ177",
]

export const checkSummaryMoreThan28 = [
    "D7", "D8", "D9", "D10", "D12",
    "L7", "L8", "L9", "L10", "L12",
    "W7", "W8", "W9", "W10", "W12",
    "X7", "X8", "X9", "X10", "X12",

    "D17", "D18", "D19", "D20", "D22",
    "L17", "L18", "L19", "L20", "L22",
    "W17", "W18", "W19", "W20", "W22",
    "X17", "X18", "X19", "X20", "X22",

    "D27", "D28", "D29", "D30", "D32",

    "D37", "D38", "D39", "D41",

    "D48", "L48",

    "D54", "D55", "D56", "D57", "D58", "D59", "D60", "D61", "D62", "D63", "D64", "D65", "D66", "D68",
    "L54", "L55", "L56", "L57", "L58", "L59", "L60", "L61", "L62", "L63", "L64", "L65", "L66", "L68",
    "W54", "W55", "W56", "W57", "W58", "W59", "W60", "W61", "W62", "W63", "W64", "W65", "W66", "W68",
    "X54", "X55", "X56", "X57", "X58", "X59", "X60", "X61", "X62", "X63", "X64", "X65", "X66", "X68",

    "D73", "D74", "D75", "D76", "D77", "D78", "D79", "D80", "D81", "D82", "D83", "D84", "D85", "D86", "D87", "D88", "D89", "D91",
    "L73", "L74", "L75", "L76", "L77", "L78", "L79", "L80", "L81", "L82", "L83", "L84", "L85", "L86", "L87", "L88", "L89", "L91",
    "W73", "W74", "W75", "W76", "W77", "W78", "W79", "W80", "W81", "W82", "W83", "W84", "W85", "W86", "W87", "W88", "W89", "W91",
    "X73", "X74", "X75", "X76", "X77", "X78", "X79", "X80", "X81", "X82", "X83", "X84", "X85", "X86", "X87", "X88", "X89", "X91",

    "D96", "D97", "D98", "D99", "D100", "D101", "D102", "D102", "D103", "D104", "D105", "D106", "D107", "D108", "D109", "D110", "D112",

    "D117", "D118", "D119", "D120", "D121", "D122", "D123", "D124", "D125", "D126", "D127", "D128", "D129", "D130", "D131", "D132", "D134",

    "D138", "D139", "D141",

    "D145", "D146", "D148",

    "D154", "D155", "D157",

    "D161", "D162", "D164",

    "D169",

    "D174", "D175", "D177",
    "L174", "L175", "L177",
    "W174", "W175", "W177",
    "X174", "X175", "X177",
]