import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";
import excel from "exceljs";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";

const columnsName = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN",
  "AO",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AW",
  "AX",
  "AY",
  "AZ",
];

const ExportDailyReport = () => {
  const [siteList, setSiteList] = useState<any>([]);

  //   GET SITE LIST DATA
  const fetchSiteListData = async () => {
    try {
      const { data }: any = await api({
        url: "/site/list",
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const findManulife = data.result?.data?.siteData?.find(
          (x: any) => x.name === "ManuLife"
        );
        if (findManulife) {
          formik.setFieldValue("site", findManulife._id);
        }
        setSiteList(data.result.data.siteData);
      }
    } catch (err) {}
  };

  const getCarSeats = (
    pathData: any,
    index: any,
    selectedLoc: any,
    seats: any
  ) => {
    const prevRemainSeat = pathData[index === 0 ? 0 : index - 1];
    const remained = index === 0 ? 0 : prevRemainSeat.seat || 0;
    const nextLoc = pathData[index + 1];
    const totalSeats = seats || 0;

    let carSeats = totalSeats;
    carSeats = index !== 0 ? remained || 0 : carSeats;
    const onSeat: any = selectedLoc?.remain_seat?.peopleOnBoard || 0;
    const offSeat: any =
      (nextLoc.location_id.code === "MFC"
        ? 0
        : selectedLoc?.remain_seat?.peopleOffBoard) || 0;

    let availableSeats = carSeats - onSeat + offSeat;
    availableSeats = index === pathData.length - 1 ? "" : availableSeats;

    if (selectedLoc.remain_seat?.clearCar) {
      availableSeats = totalSeats;
    }

    availableSeats = availableSeats < 0 ? 0 : availableSeats;
    availableSeats = availableSeats > totalSeats ? totalSeats : availableSeats;

    return availableSeats;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      site: "",
      // path: ""
    },

    validationSchema: Yup.object({
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before Start date")
        .required("End Date is required"),
      site: Yup.string(),
    }),

    onSubmit: async (values) => {
      // getCarData(values);
      getScheduleDetail(values);
    },
  });

  useEffect(() => {
    fetchSiteListData();
    // eslint-disable-next-line
  }, []);

  // SITE OPTIONS
  const siteOptions =
    [
      ...siteList?.map((site: any) => ({
        label: site.name,
        value: site._id,
      })),
    ] || [];

  const cutDateSheet = (
    wb: any,
    onBoardAllRoutes: any,
    offBoardAllRoutes: any,
    schedules: any,
    dates: any
  ) => {
    const timeValues: any = dates || [];
    const DailyData = schedules;

    timeValues.forEach((v: any, idx: any) => {
      const fillCellYellow: any = [];
      const fillCellRed: any = [];
      const sheetdate = moment(v).format("DD-MM-YYYY");
      const onBoardRoutes: any = [];
      let totalMFCCell: any = 0;

      if (onBoardAllRoutes[idx].includes("LG")) {
        onBoardRoutes.push("LG");
        delete onBoardAllRoutes[idx][onBoardAllRoutes[idx].indexOf("LG")];
      }
      if (onBoardAllRoutes[idx].includes("MT")) {
        onBoardRoutes.push("MT");
        delete onBoardAllRoutes[idx][onBoardAllRoutes[idx].indexOf("MT")];
      }
      if (onBoardAllRoutes[idx].includes("MFC")) {
        onBoardRoutes.push("MFC");
        delete onBoardAllRoutes[idx][onBoardAllRoutes[idx].indexOf("MFC")];
      }
      if (onBoardAllRoutes[idx].includes("MP")) {
        onBoardRoutes.push("MP");
        delete onBoardAllRoutes[idx][onBoardAllRoutes[idx].indexOf("MP")];
      }
      onBoardAllRoutes[idx]
        .filter((x: any) => x && x)
        .map((x: any) => {
          onBoardRoutes.push(x);
        });

      const offBoardRoutes: any = offBoardAllRoutes[idx].sort();

      let row_no = 1;
      const ws = wb.addWorksheet(`${sheetdate}`, {
        properties: {},
        views: [{ state: "frozen", ySplit: 1, xSplit: 8 }],
      });
      const ws_columns = [
        { width: 5, alignment: { vertical: "middle", horizontal: "center" } },
        { width: 15, alignment: { vertical: "middle", horizontal: "center" } },
        {
          width: 20,
          hidden: false,
          alignment: { vertical: "middle", horizontal: "center" },
        },
        { width: 15, alignment: { vertical: "middle", horizontal: "center" } },
        { width: 15, alignment: { vertical: "middle", horizontal: "center" } },
        { width: 15, alignment: { vertical: "middle", horizontal: "center" } },
        { width: 15, alignment: { vertical: "middle", horizontal: "center" } },
        { width: 15, alignment: { vertical: "middle", horizontal: "center" } },
      ];

      if (onBoardRoutes.length) {
        onBoardRoutes.map((rItem: any) => {
          ws_columns.push({
            width: 17,
            alignment: { vertical: "middle", horizontal: "center" },
          });
        });

        offBoardRoutes.map((rItem: any) => {
          ws_columns.push({
            width: 20,
            hidden: !["MFC", "MT"].includes(rItem),
            alignment: { vertical: "middle", horizontal: "center" },
          });

          if (rItem === "MFC") {
            ws_columns.push({
              width: 17,
              alignment: { vertical: "middle", horizontal: "center" },
            });
          }
        });
      }
      ws_columns.push({
        width: 20,
        alignment: { vertical: "middle", horizontal: "center" },
      });
      ws_columns.push({
        width: 20,
        alignment: { vertical: "middle", horizontal: "center" },
      });
      ws_columns.push({
        width: 25,
        alignment: { vertical: "middle", horizontal: "center" },
      });
      ws_columns.push({
        width: 20,
        alignment: { vertical: "middle", horizontal: "center" },
      });
      ws_columns.push({
        width: 20,
        alignment: { vertical: "middle", horizontal: "center" },
      });
      ws_columns.push({
        width: 25,
        alignment: { vertical: "middle", horizontal: "center" },
      });
      ws_columns.push({
        width: 20,
        alignment: { vertical: "middle", horizontal: "center" },
      });

      ws_columns.push({
        width: 10,
        alignment: { vertical: "middle", horizontal: "center" },
      });
      ws_columns.push({
        width: 20,
        alignment: { vertical: "middle", horizontal: "center" },
      });

      ws.columns = ws_columns;

      const rowToShow = [
        "No.",
        "Date",
        "Session",
        "Time",
        "Direct Bus",
        "Route from",
        "Route to",
        "Midway stop",
      ];

      if (onBoardRoutes.length) {
        onBoardRoutes.map((rItem: any) => {
          rowToShow.push(`Boarding at ${rItem}`);
        });
      }

      if (offBoardRoutes) {
        offBoardRoutes.map((rItem: any) => {
          rowToShow.push(`Alighting at ${rItem}`);

          if (rItem === "MFC") {
            totalMFCCell = rowToShow.length;
            rowToShow.push(`Total at MFC`);
          }
        });
      }

      rowToShow.push("No. of passenger line at MFC");
      rowToShow.push("Passengers leave from the queue at MFC");
      rowToShow.push("Exceed passenger at MFC");
      rowToShow.push("Delay");
      rowToShow.push("Cancellation");

      ws.addRow(rowToShow).height = 35;

      ws.getRow(row_no).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "C7C7C7" },
      };
      ws.getRow(row_no).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      ws.getRow(row_no).font = { bold: true, color: { argb: "00000000" } };
      ws.getRow(row_no).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };

      for (let i = 0; i < DailyData[idx]?.length; i++) {
        let r_write = [
          i + 1,
          DailyData[idx][i]?.["Date"],
          DailyData[idx][i]?.["Session"],
          DailyData[idx][i]?.["Time"],
          DailyData[idx][i]?.["directBus"],
          DailyData[idx][i]?.["From"],
          DailyData[idx][i]?.["To"],
          DailyData[idx][i]?.["isMidwayStop"],
        ];

        if (onBoardRoutes.length) {
          onBoardRoutes.map((rItem: any) => {
            if (
              (DailyData[idx][i]?.["To"] === "MFC" ||
                DailyData[idx][i]?.["From"] === "MFC") &&
              DailyData[idx][i]?.["isMidwayStop"] === 1
            ) {
              fillCellYellow.push(`${columnsName[totalMFCCell]}${row_no + 1}`);
            } else if (
              DailyData[idx][i]?.["From"] === "MFC" ||
              (DailyData[idx][i]?.["To"] === "MFC" &&
                DailyData[idx][i]?.["isMidwayStop"] === 0 &&
                DailyData[idx][i]?.["directBus"] === 1)
            ) {
              fillCellYellow.push(`${columnsName[totalMFCCell]}${row_no + 1}`);
            }

            if (
              DailyData[idx][i]?.["To"] === "MFC" &&
              DailyData[idx][i]?.["isMidwayStop"] === 1
            ) {
              fillCellYellow.push(`${columnsName[totalMFCCell]}${row_no + 1}`);
            }

            if (DailyData[idx][i]?.["From"] === rItem) {
              fillCellYellow.push(
                `${columnsName[r_write.length]}${row_no + 1}`
              );

              r_write.push(DailyData[idx][i]?.["onBoard"] || 0);
            } else {
              r_write.push(0);
            }
          });
        }

        if (offBoardRoutes.length) {
          offBoardRoutes.map((rItem: any) => {
            // ALIGHTINGS
            if (DailyData[idx][i]?.["To"] === rItem) {
              fillCellYellow.push(
                `${columnsName[r_write.length]}${row_no + 1}`
              );
              r_write.push(DailyData[idx][i]?.["offBoard"] || 0);
            } else {
              r_write.push(0);
            }
            // TOTAL AT MFC
            if (rItem === "MFC") {
              if (
                DailyData[idx][i]?.["From"] === rItem ||
                DailyData[idx][i]?.["To"] === rItem
              ) {
                if (DailyData[idx][i]?.["isMidwayStop"] === 1) {
                  const prevRouteTotal =
                    DailyData[idx][i - 1]?.["onBoard"] -
                    DailyData[idx][i - 1]?.["offBoard"];
                  const onBoardCounts =
                    prevRouteTotal +
                    // DailyData[idx][i]?.["offBoard"] +
                    DailyData[idx][i]?.["onBoard"];

                  // IF TOTAL AT MFC < 0 THEN IT WILL BE HIGHLIGHTED AS "RED" BACKGROUND
                  if (onBoardCounts < 0) {
                    fillCellRed.push(
                      `${columnsName[totalMFCCell]}${row_no + 1}`
                    );
                  }
                  // onBoardCounts = onBoardCounts > 0 ? onBoardCounts : 0;
                  // DailyData[idx][i - 1]?.["onBoard"] +
                  // DailyData[idx][i]?.["onBoard"] -
                  // DailyData[idx][i - 1]?.["offBoard"];
                  r_write.push(onBoardCounts || 0);
                } else if (
                  DailyData[idx][i]?.["isMidwayStop"] === 0 &&
                  DailyData[idx][i]?.["directBus"] === 1
                ) {
                  r_write.push(DailyData[idx][i]?.["onBoard"] || 0);
                } else {
                  r_write.push(0);
                }
              } else {
                r_write.push(0);
              }
            }
          });
        }

        // LOGIC FOR MFC & MT (ALIGHTINGS) (IF HAVE TOTAL AT MFC > 0 THEN RELAVENT ALIGHTING AT === 0)
        for (const route_item of ["MFC", "MT"]) {
          const indexOfTMFC = rowToShow.indexOf(`Total at MFC`);
          const totalAtMFC = r_write[indexOfTMFC];
          if (totalAtMFC > 0) {
            const indexToUpdate = rowToShow.indexOf(
              `Alighting at ${route_item}`
            );
            r_write = r_write.map((x, ind) => {
              if (
                DailyData[idx][i]?.["To"] === route_item &&
                ind === indexToUpdate
              ) {
                return 0;
              }
              return x;
            });
          }
        }

        // NO. OF PASSENGERS LINE AT MFC
        if (DailyData[idx][i]?.["From"] === "MFC") {
          fillCellYellow.push(`${columnsName[r_write.length]}${row_no + 1}`);
          r_write.push(DailyData[idx][i]?.["onBoard"] || 0);
        } else {
          r_write.push(0);
        }

        if (DailyData[idx][i]?.["From"] === "MFC") {
          fillCellYellow.push(`${columnsName[r_write.length]}${row_no + 1}`);
        }
        r_write.push(0);

        if (DailyData[idx][i]?.["From"] === "MFC") {
          fillCellYellow.push(`${columnsName[r_write.length]}${row_no + 1}`);
        }
        r_write.push(0);

        r_write.push(DailyData[idx][i]?.["Delay"] || 0);
        r_write.push(DailyData[idx][i]?.["Cancellation"] || 0);

        ws.addRow(r_write).height = 16;

        ws.getRow(row_no).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        ws.getRow(row_no).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        row_no++;
        ws.getRow(row_no).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      ws.addRow([]).height = 14;
      ws.addRow([]).height = 14;

      fillCellYellow.map((cellAddress: string) => {
        ws.getCell(cellAddress).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
      });

      fillCellRed.map((cellAddress: string) => {
        ws.getCell(cellAddress).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0000" },
        };
      });

      ws.eachRow(function(row: any, rowNumber: any) {
        ws.getRow(rowNumber).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };

        // row.eachCell({ includeEmpty: true }, (cell: any, cellNumber: any) => {
        //   if (cellNumber > 8) {
        //     if (
        //       typeof ws.getCell(cell._address).value === "number" &&
        //       ws.getCell(cell._address).value === 28
        //     ) {
        //       // IF VALUE IS EXACTLY 28 THEN IT WILL BE SHOW IN RED COLOR
        //       ws.getCell(cell._address).font = {
        //         color: { argb: "FF0000" },
        //       };
        //     } else if (
        //       typeof ws.getCell(cell._address).value === "number" &&
        //       (ws.getCell(cell._address).value < 0 ||
        //         ws.getCell(cell._address).value > 28)
        //     ) {
        //       // IF LESS THAN 0 OR MORE THAN 28 THEN IT WILL BE SHOW IN RED
        //       ws.getCell(cell._address).fill = {
        //         type: "pattern",
        //         pattern: "solid",
        //         fgColor: { argb: "FF0000" },
        //       };
        //     }
        //   }
        // });
      });
    });
  };

  const getScheduleDetail = async (values: any) => {
    const wb: any = new excel.Workbook();

    try {
      const carRes = await api({
        url: "/car/list?page=1&size=1000&isActive=true",
        method: "get",
      });
      const carList = carRes?.data?.result?.data?.carData;
      if (carList.length) {
        const { data }: any = await api({
          url: `/schedule/schedule/export/${values.site}?start_date=${values.startDate}&end_date=${values.endDate}`, //  `/schedule/sc/list/
          method: "get",
        });
        if (data.serverResponse.isError) {
          toast.error(data.serverResponse.message);
        } else {
          let item: any = data.result.data
            .map((x: any) => {
              return {
                ...x,
                scheduleDate: moment(x?.scheduleDate).format("YYYY-MM-DD"),
              };
            })
            .filter((x: any) => x.showInReqport === true);

          const groupbyDates = _.groupBy(item, "scheduleDate");

          const clearDates: any = Object.keys(groupbyDates);

          const onBoardAllRouteCodes: any = [];
          const offBoardAllRouteCodes: any = [];
          const pathEventList: any = [];

          if (clearDates.length) {
            clearDates.map((singleDate: any) => {
              const pathDataArray: any = [];
              const onBoardRouteCodes: any = [];
              const offBoardRouteCodes: any = [];
              for (const element of groupbyDates[singleDate]) {
                if (
                  element &&
                  element.location &&
                  element.location.length > 1
                ) {
                  for (const [
                    index,
                    locationData,
                  ] of element.location.entries()) {
                    const locationTime = element.location[0].start.split(":");
                    const sortOrder = Number(
                      Number(locationTime[0] * 60) + Number(locationTime[1])
                    );

                    // DELAYED TIME CALCULATE
                    let finalValDelay = 0;
                    if (
                      element.location[index].reached &&
                      element.location[index].reachedTime !== null
                    ) {
                      const reachedSplit = moment(
                        element.location[index].reachedTime
                      )
                        .format("HH:mm")
                        .split(":");
                      const startSplit = element.location[index].start.split(
                        ":"
                      );
                      const reachedMin =
                        parseInt(reachedSplit[0]) * 60 +
                        parseInt(reachedSplit[1]);
                      const startMin =
                        parseInt(startSplit[0]) * 60 + parseInt(startSplit[1]);
                      finalValDelay = Math.max(0, reachedMin - startMin);
                    }

                    if (index !== element.location.length - 1) {
                      if (
                        !element.location[index]?.location_id?.display_timetable
                      ) {
                        onBoardRouteCodes.push(
                          element.location[index].location_id.code
                        );
                      }
                      if (
                        !element.location[index + 1]?.location_id
                          ?.display_timetable
                      ) {
                        offBoardRouteCodes.push(
                          element.location[index + 1].location_id.code
                        );
                      }

                      let locationTitle = "";
                      if (locationData.location_id) {
                        locationTitle = `${
                          element.location[index].location_id.code
                        } > ${element.location[index + 1].location_id.code}`;
                      }

                      const carSeats =
                        carList.find((cId: any) => cId._id === element?.carId)
                          ?.seats || 0;

                      const availableSeats = getCarSeats(
                        element.location,
                        index,
                        element.location[index],
                        carSeats
                      );

                      element.location[index].seat = availableSeats || 0;

                      const pathEventObject = {
                        title: locationTitle,
                        delay: finalValDelay || 0,
                        sortT: sortOrder,
                        Date: moment(element.scheduleDate).format(
                          "DD-MMM-YYYY"
                        ),
                        sequence: element?.sequence || 0,
                        Session: element.session,
                        Time: locationData.start,
                        carId: element.carId,
                        directBus: element.location.length > 2 ? 0 : 1,
                        From: element.location[index].location_id.code,
                        To: element.location[index + 1].location_id.code,
                        isMidwayStop: index,
                        Delay: locationData.status === "DELAY" ? 1 : 0,
                        Cancellation: locationData.status === "CANCEL" ? 1 : 0,
                        passengers:
                          carSeats - element.location[index].seat || 0, // available passengers at the current route
                        onBoard: locationData?.remain_seat?.peopleOnBoard || 0,
                        offBoard:
                          locationData?.remain_seat?.peopleOffBoard || 0,
                      };
                      if (
                        element.showInReqport &&
                        !element.location[index]?.location_id
                          ?.display_timetable &&
                        !element.location[index + 1]?.location_id
                          ?.display_timetable
                      ) {
                        pathDataArray.push(pathEventObject);
                      }
                    }
                  }
                }
              }
              const finalData: any =
                pathDataArray.sort((a: any, b: any) => {
                  return a.sequence - b.sequence;
                }) || [];

              onBoardAllRouteCodes.push(_.uniq(onBoardRouteCodes) || []);
              offBoardAllRouteCodes.push(_.uniq(offBoardRouteCodes) || []);
              pathEventList.push(finalData);
            });

            cutDateSheet(
              wb,
              onBoardAllRouteCodes,
              offBoardAllRouteCodes,
              pathEventList,
              clearDates
            );

            wb.xlsx
              .writeBuffer(
                `Shuttle Bus Daily Record  - ${moment().format("YYYY")}.xlsx`
              )
              .then((buffer: any) => {
                saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  `Shuttle Bus Daily Record  - ${moment().format("YYYY")}.xlsx`
                );
              });
          } else {
          }
        }
      }
    } catch (err) {
      console.log("error", err);
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader title={"Daily Report"}></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Start Date</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      name="startDate"
                      onChange={formik.handleChange}
                      value={formik.values.startDate}
                    />

                    {formik.touched.startDate && formik.errors.startDate ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.startDate}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">End Date</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="date"
                      onChange={formik.handleChange}
                      value={formik.values.endDate}
                      className="form-control"
                      id="title"
                      name="endDate"
                    />

                    {formik.touched.endDate && formik.errors.endDate ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.endDate}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Site</div>
                <div className="col-lg-8">
                  <div className="input-group search-filter relative d-flex align-items-center">
                    <Select
                      name="site"
                      isDisabled
                      options={siteOptions}
                      closeMenuOnSelect={true}
                      value={siteOptions.filter(
                        (x: any) => x.value === formik.values.site
                      )}
                      onChange={(val: any) => {
                        formik.setFieldValue("site", val.value);
                      }}
                      className="w-100 filter-select"
                      classNamePrefix="select"
                      placeholder="-- Select Site --"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12"></div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <button
                    className="btn btn-primary btn-success h-100"
                    name="fetch"
                    type="submit"
                    onClick={() => formik.handleSubmit()}
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ExportDailyReport;
