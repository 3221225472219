import _ from "lodash";
import moment from "moment";
import {
  borderRightMediumCell,
  borderRightThinCell,
  borderTopBottomMediumCell,
  topBottomMediumRightThin,
  borderTopRightBottomMediumCell,
  lightPurpleCell,
  lightGreenCell,
  yellowBgCell,
  lightBlueCell,
  alignmentCells,
  borderBottomMediumCell,
  borderBottomRightMediumCell,
  borderRightThingBottomMediumCell,
  borderRightMediumBottomThinCell,
  borderBottomRightThin,
  borderBottomThinCell,
} from "./weeklyCell.js";
import { checkWeekMoreThan28 } from "./checkCell.js";

const getRouteCount = (items: any, route: any, slot: any) => {
  let a8Total = 0;

  items
    .filter((x: any) => x.title === route && x.Time === slot)
    .map((x: any) => {
      a8Total = a8Total + x.onBoard;
    });

  return a8Total;
};

export const weeklySheet = (
  wb: any,
  allItems: any,
  totalDay: any,
  weeks: any
) => {
  const timeValues: any = Object.keys(weeks) || [];

  timeValues.forEach((v: any, idx: any) => {
    const ws = wb.addWorksheet(`Week ${idx + 1}`, {
      properties: {},
    });

    const weekData = weeks[v];

    ws.getCell("A1").value = `Week ${idx + 1} : ${moment(weeks[v][0]).format(
      "DD MMMM YYYY"
    )}`;

    ws.getCell("A3").value = "1)  Morning Shuttle Bus Service";
    ws.getCell("A4").value = "1.1) Bus Trip: LG > MP";

    ws.getCell(`A13`).value = "2) Regular Shuttle Bus Services";
    ws.getCell(`A14`).value = "2.1) Bus Trip: MP > LG";
    ws.getCell(`A29`).value = "2.2) Bus Trip: LG > MP";
    ws.getCell(`A45`).value =
      "Total No. of Passenger of Regular Shuttle Bus Services (2.1+2.2)";

    // BOLD TEXT
    ["A1", "A3", "A4", "A13", "A14", "A29", "A45"].map((citem: any) => {
      ws.getCell(citem).font = { bold: true, color: { argb: "00000000" } };
    });

    ws.getCell("C47").border = {
      top: { style: "medium", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "medium", color: { argb: "000000" } },
    };

    ws.columns = [
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 20, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
      { width: 12, alignment: { vertical: "middle", horizontal: "center" } },
    ];

    ws.getCell("P3").value = weekData.length || 0;
    ws.getCell("P3").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC000" },
    };
    ws.getCell("Q3").value = "(no. of working day)";

    // ROW HEIGHT = 20
    [6, 16, 31].map((rItm: any) => {
      ws.getRow(rItm).height = 20;
    });

    // ROW HEIGHT = 35
    [7, 17, 32, 47].map((rItm: any) => {
      ws.getRow(rItm).height = 45;
    });

    // APPLY BACKGROUND COLOR (LIGHT YELLOW)
    yellowBgCell.map((cItem: any) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFCC" },
      };
    });

    // APPLY BACKGROUND COLOR (LIGHT BLUE)
    lightBlueCell.map((cItem: any) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D9E1F2" },
      };
    });

    // APPLY BACKGROUND COLOR (LIGHT PURPLE)
    lightPurpleCell.map((cItem: any) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCFF" },
      };
    });

    // APPLY BACKGROUND COLOR (LIGHT GREEN)
    lightGreenCell.map((cItem: any) => {
      ws.getCell(cItem).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E2EFDA" },
      };
    });

    // APPLY BOLD BORDER AT RIGHT
    borderRightMediumCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        right: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT TOP BOTTOM AND RIGHT THIN
    topBottomMediumRightThin.map((cItem: any) => {
      ws.getCell(cItem).border = {
        top: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM RIGHT
    borderBottomRightMediumCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        right: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER RIGHT MEDIUM BOTTOM THIN
    borderRightMediumBottomThinCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        right: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
    borderRightThingBottomMediumCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        right: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT TOP RIGHT BOTTOM
    borderTopRightBottomMediumCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        top: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM RIGHT THIN & BOTTOM BOLD BORDER
    borderBottomRightThin.map((cItem: any) => {
      ws.getCell(cItem).border = {
        right: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT BOTTOM
    borderBottomMediumCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        bottom: { style: "medium", color: { argb: "000000" } },
      };
    });

    // APPLY THIN BORDER AT BOTTOM
    borderBottomThinCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        bottom: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY THIN BORDER AT RIGHT
    borderRightThinCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    // APPLY BOLD BORDER AT TOP & BOTTOM
    borderTopBottomMediumCell.map((cItem: any) => {
      ws.getCell(cItem).border = {
        top: { style: "medium", color: { argb: "000000" } },
        // left: { style: "thick", color: { argb: "000000FF" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        // right: { style: "thick", color: { argb: "FF00FF00" } },
      };
    });

    ["A6", "A16", "A31"].map((cItem: any) => {
      ws.getCell(cItem).value = "Date:";
    });

    [
      "C7",
      "E7",
      "G7",
      "I7",
      "K7",
      "C17",
      "E17",
      "G17",
      "I17",
      "K17",
      "C32",
      "E32",
      "G32",
      "I32",
      "K32",
    ].map((cItem: any) => {
      ws.getCell(cItem).value = "Total";
    });

    ws.getCell("A7").value = "LG >";
    ws.getCell("B7").value = "MP";
    ws.getCell("A32").value = "LG >";
    ws.getCell("B32").value = "MP";
    ws.getCell("A8").value = "08:40";
    ws.getCell("B8").value = "09:10";
    ws.getCell("A9").value = "09:40";
    ws.getCell("B9").value = "10:10";

    ws.getCell("A17").value = "MP >";
    ws.getCell("B17").value = "LG";

    ws.getCell("A18").value = "10:10";
    ws.getCell("A19").value = "11:10";
    ws.getCell("A20").value = "12:10";
    ws.getCell("A21").value = "14:10";
    ws.getCell("A22").value = "15:10";
    ws.getCell("A23").value = "16:10";
    ws.getCell("A24").value = "17:10";
    ws.getCell("A25").value = "18:10";

    ws.getCell("B18").value = "10:40";
    ws.getCell("B19").value = "11:40";
    ws.getCell("B20").value = "12:40";
    ws.getCell("B21").value = "14:40";
    ws.getCell("B22").value = "15:40";
    ws.getCell("B23").value = "16:40";
    ws.getCell("B24").value = "17:40";
    ws.getCell("B25").value = "18:40";

    //

    ws.getCell("A33").value = "10:40";
    ws.getCell("A34").value = "11:40";
    ws.getCell("A35").value = "12:40";
    ws.getCell("A36").value = "14:40";
    ws.getCell("A37").value = "15:40";
    ws.getCell("A38").value = "16:40";
    ws.getCell("A39").value = "17:40";
    ws.getCell("A40").value = "18:40";

    ws.getCell("B33").value = "11:10";
    ws.getCell("B34").value = "12:10";
    ws.getCell("B35").value = "13:10";
    ws.getCell("B36").value = "15:10";
    ws.getCell("B37").value = "16:10";
    ws.getCell("B38").value = "17:10";
    ws.getCell("B39").value = "18:10";
    ws.getCell("B40").value = "19:10";

    ["D7", "F7", "H7", "J7", "L7"].map((cItem: any) => {
      ws.getCell(cItem).value = "Boarding at LG";
      ws.getCell(cItem).alignment = {
        wrapText: true,
      };
    });

    ["M7", "M17", "M32", "H47"].map((cItem: any) => {
      ws.getCell(cItem).value = "Total no. of Passenger (per trip)";
      ws.getCell(cItem).alignment = {
        wrapText: true,
      };
    });

    ["N7", "N17", "N32", "I47"].map((cItem: any) => {
      ws.getCell(cItem).value = "Average per trip";
      ws.getCell(cItem).alignment = {
        wrapText: true,
      };
    });

    ["D17", "F17", "H17", "J17", "L17"].map((cItem: any) => {
      ws.getCell(cItem).value = "Boarding at MP";
    });

    ["D32", "F32", "H32", "J32", "L32"].map((cItem: any) => {
      ws.getCell(cItem).value = "Boarding at LG";
    });

    ["A10", "A26", "A41", "A48"].map((cItem: any) => {
      ws.getCell(cItem).value = "Daily Total No. of Passenger";
    });
    ["A11", "A27", "A42", "A49"].map((cItem: any) => {
      ws.getCell(cItem).value = "Average per trip (Daily)";
    });

    ["O7", "O17", "O32"].map((cItem: any) => {
      ws.getCell(cItem).value = "No. of days >22\r\npassengers";
      ws.getCell(cItem).alignment = {
        wrapText: true,
      };
    });

    ["Q7", "Q17", "Q32"].map((cItem: any) => {
      ws.getCell(cItem).value = "No. of days =28\r\npassengers";
    });

    ws.mergeCells("A1:B1");
    ws.mergeCells("A3:B3");
    ws.mergeCells("A4:B4");
    ws.mergeCells("A13:B13");
    ws.mergeCells("A14:B14");
    ws.mergeCells("A29:B29");
    ws.mergeCells("A45:D45");
    ws.mergeCells("O7:P7");
    ws.mergeCells("Q7:R7");
    ws.mergeCells("O17:P17");
    ws.mergeCells("Q17:R17");
    ws.mergeCells("O32:P32");
    ws.mergeCells("Q32:R32");
    ws.mergeCells("A47:B47");

    ws.mergeCells("A10:B10");
    ws.mergeCells("A11:B11");
    ws.mergeCells("A26:B26");
    ws.mergeCells("A27:B27");
    ws.mergeCells("A41:B41");
    ws.mergeCells("A42:B42");
    ws.mergeCells("A48:B48");
    ws.mergeCells("A49:B49");

    // ADD DATA INTO CELL
    ws.getCell("C6").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C16").value = weekData[0] ? moment(weekData[0]).format("D") : 0;
    ws.getCell("C31").value = weekData[0] ? moment(weekData[0]).format("D") : 0;

    //  DAY 1
    const week_data1 = weekData[0]
      ? allItems.filter(
          (sItem: any) =>
            moment(sItem?.Date).format("YYYY-MM-DD") ===
            moment(weekData[0]).format("YYYY-MM-DD")
        )
      : [];

    ws.getCell("C8").value = getRouteCount(week_data1, "LG > MP", "08:40") || 0;
    ws.getCell("C9").value = getRouteCount(week_data1, "LG > MP", "09:40") || 0;

    ws.getCell("D8").value = ws.getCell("C8").value || 0;
    ws.getCell("D9").value = ws.getCell("C9").value || 0;

    ws.getCell("C10").value =
      ws.getCell("C8").value + ws.getCell("C9").value || 0;
    ws.getCell("C11").value = Math.floor(ws.getCell("C10").value / 2 || 0);

    ws.getCell("E6").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("E16").value = weekData[1] ? moment(weekData[1]).format("D") : 0;
    ws.getCell("E31").value = weekData[1] ? moment(weekData[1]).format("D") : 0;

    ws.getCell("C18").value =
      getRouteCount(week_data1, "MP > LG", "10:10") || 0;
    ws.getCell("C19").value =
      getRouteCount(week_data1, "MP > LG", "11:10") || 0;
    ws.getCell("C20").value =
      getRouteCount(week_data1, "MP > LG", "12:10") || 0;
    ws.getCell("C21").value =
      getRouteCount(week_data1, "MP > LG", "14:10") || 0;
    ws.getCell("C22").value =
      getRouteCount(week_data1, "MP > LG", "15:10") || 0;
    ws.getCell("C23").value =
      getRouteCount(week_data1, "MP > LG", "16:10") || 0;
    ws.getCell("C24").value =
      getRouteCount(week_data1, "MP > LG", "17:10") || 0;
    ws.getCell("C25").value =
      getRouteCount(week_data1, "MP > LG", "18:10") || 0;

    ws.getCell("D18").value =
      getRouteCount(week_data1, "MP > LG", "10:10") || 0;
    ws.getCell("D19").value =
      getRouteCount(week_data1, "MP > LG", "11:10") || 0;
    ws.getCell("D20").value =
      getRouteCount(week_data1, "MP > LG", "12:10") || 0;
    ws.getCell("D21").value =
      getRouteCount(week_data1, "MP > LG", "14:10") || 0;
    ws.getCell("D22").value =
      getRouteCount(week_data1, "MP > LG", "15:10") || 0;
    ws.getCell("D23").value =
      getRouteCount(week_data1, "MP > LG", "16:10") || 0;
    ws.getCell("D24").value =
      getRouteCount(week_data1, "MP > LG", "17:10") || 0;
    ws.getCell("D25").value =
      getRouteCount(week_data1, "MP > LG", "18:10") || 0;

    // DAY 1 PATH 3
    ws.getCell("C33").value =
      getRouteCount(week_data1, "LG > MP", "10:40") || 0;
    ws.getCell("C34").value =
      getRouteCount(week_data1, "LG > MP", "11:40") || 0;
    ws.getCell("C35").value =
      getRouteCount(week_data1, "LG > MP", "12:40") || 0;
    ws.getCell("C36").value =
      getRouteCount(week_data1, "LG > MP", "14:40") || 0;
    ws.getCell("C37").value =
      getRouteCount(week_data1, "LG > MP", "15:40") || 0;
    ws.getCell("C38").value =
      getRouteCount(week_data1, "LG > MP", "16:40") || 0;
    ws.getCell("C39").value =
      getRouteCount(week_data1, "LG > MP", "17:40") || 0;
    ws.getCell("C40").value =
      getRouteCount(week_data1, "LG > MP", "18:40") || 0;

    ws.getCell("D33").value =
      getRouteCount(week_data1, "LG > MP", "10:40") || 0;
    ws.getCell("D34").value =
      getRouteCount(week_data1, "LG > MP", "11:40") || 0;
    ws.getCell("D35").value =
      getRouteCount(week_data1, "LG > MP", "12:40") || 0;
    ws.getCell("D36").value =
      getRouteCount(week_data1, "LG > MP", "14:40") || 0;
    ws.getCell("D37").value =
      getRouteCount(week_data1, "LG > MP", "15:40") || 0;
    ws.getCell("D38").value =
      getRouteCount(week_data1, "LG > MP", "16:40") || 0;
    ws.getCell("D39").value =
      getRouteCount(week_data1, "LG > MP", "17:40") || 0;
    ws.getCell("D40").value =
      getRouteCount(week_data1, "LG > MP", "18:40") || 0;

    //  DAY 2
    const week_data2 = weekData[1]
      ? allItems.filter(
          (sItem: any) =>
            moment(sItem?.Date).format("YYYY-MM-DD") ===
            moment(weekData[1]).format("YYYY-MM-DD")
        )
      : [];

    ws.getCell("E8").value = getRouteCount(week_data2, "LG > MP", "08:40") || 0;
    ws.getCell("E9").value = getRouteCount(week_data2, "LG > MP", "09:40") || 0;

    ws.getCell("F8").value = ws.getCell("E8").value || 0;
    ws.getCell("F9").value = ws.getCell("E9").value || 0;

    ws.getCell("E10").value =
      ws.getCell("E8").value + ws.getCell("E9").value || 0;
    ws.getCell("E11").value = Math.floor(ws.getCell("E10").value / 2 || 0);

    ws.getCell("G6").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("G16").value = weekData[2] ? moment(weekData[2]).format("D") : 0;
    ws.getCell("G31").value = weekData[2] ? moment(weekData[2]).format("D") : 0;

    // DAY 2 PATH 2
    ws.getCell("E18").value =
      getRouteCount(week_data2, "MP > LG", "10:10") || 0;
    ws.getCell("E19").value =
      getRouteCount(week_data2, "MP > LG", "11:10") || 0;
    ws.getCell("E20").value =
      getRouteCount(week_data2, "MP > LG", "12:10") || 0;
    ws.getCell("E21").value =
      getRouteCount(week_data2, "MP > LG", "14:10") || 0;
    ws.getCell("E22").value =
      getRouteCount(week_data2, "MP > LG", "15:10") || 0;
    ws.getCell("E23").value =
      getRouteCount(week_data2, "MP > LG", "16:10") || 0;
    ws.getCell("E24").value =
      getRouteCount(week_data2, "MP > LG", "17:10") || 0;
    ws.getCell("E25").value =
      getRouteCount(week_data2, "MP > LG", "18:10") || 0;

    ws.getCell("F18").value =
      getRouteCount(week_data2, "MP > LG", "10:10") || 0;
    ws.getCell("F19").value =
      getRouteCount(week_data2, "MP > LG", "11:10") || 0;
    ws.getCell("F20").value =
      getRouteCount(week_data2, "MP > LG", "12:10") || 0;
    ws.getCell("F21").value =
      getRouteCount(week_data2, "MP > LG", "14:10") || 0;
    ws.getCell("F22").value =
      getRouteCount(week_data2, "MP > LG", "15:10") || 0;
    ws.getCell("F23").value =
      getRouteCount(week_data2, "MP > LG", "16:10") || 0;
    ws.getCell("F24").value =
      getRouteCount(week_data2, "MP > LG", "17:10") || 0;
    ws.getCell("F25").value =
      getRouteCount(week_data2, "MP > LG", "18:10") || 0;

    // DAY 2 PATH 3
    ws.getCell("E33").value =
      getRouteCount(week_data2, "LG > MP", "10:40") || 0;
    ws.getCell("E34").value =
      getRouteCount(week_data2, "LG > MP", "11:40") || 0;
    ws.getCell("E35").value =
      getRouteCount(week_data2, "LG > MP", "12:40") || 0;
    ws.getCell("E36").value =
      getRouteCount(week_data2, "LG > MP", "14:40") || 0;
    ws.getCell("E37").value =
      getRouteCount(week_data2, "LG > MP", "15:40") || 0;
    ws.getCell("E38").value =
      getRouteCount(week_data2, "LG > MP", "16:40") || 0;
    ws.getCell("E39").value =
      getRouteCount(week_data2, "LG > MP", "17:40") || 0;
    ws.getCell("E40").value =
      getRouteCount(week_data2, "LG > MP", "18:40") || 0;

    ws.getCell("F33").value =
      getRouteCount(week_data2, "LG > MP", "10:40") || 0;
    ws.getCell("F34").value =
      getRouteCount(week_data2, "LG > MP", "11:40") || 0;
    ws.getCell("F35").value =
      getRouteCount(week_data2, "LG > MP", "12:40") || 0;
    ws.getCell("F36").value =
      getRouteCount(week_data2, "LG > MP", "14:40") || 0;
    ws.getCell("F37").value =
      getRouteCount(week_data2, "LG > MP", "15:40") || 0;
    ws.getCell("F38").value =
      getRouteCount(week_data2, "LG > MP", "16:40") || 0;
    ws.getCell("F39").value =
      getRouteCount(week_data2, "LG > MP", "17:40") || 0;
    ws.getCell("F40").value =
      getRouteCount(week_data2, "LG > MP", "18:40") || 0;

    // DAY 3
    const week_data3 = weekData[2]
      ? allItems.filter(
          (sItem: any) =>
            moment(sItem?.Date).format("YYYY-MM-DD") ===
            moment(weekData[2]).format("YYYY-MM-DD")
        )
      : [];

    ws.getCell("G8").value = getRouteCount(week_data3, "LG > MP", "08:40") || 0;
    ws.getCell("G9").value = getRouteCount(week_data3, "LG > MP", "09:40") || 0;

    ws.getCell("H8").value = ws.getCell("G8").value || 0;
    ws.getCell("H9").value = ws.getCell("G9").value || 0;

    ws.getCell("G10").value =
      ws.getCell("G8").value + ws.getCell("G9").value || 0;
    ws.getCell("G11").value = Math.floor(ws.getCell("G10").value / 2 || 0);

    ws.getCell("I6").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("I16").value = weekData[3] ? moment(weekData[3]).format("D") : 0;
    ws.getCell("I31").value = weekData[3] ? moment(weekData[3]).format("D") : 0;

    // DAY 3 PATH 2
    ws.getCell("G18").value =
      getRouteCount(week_data3, "MP > LG", "10:10") || 0;
    ws.getCell("G19").value =
      getRouteCount(week_data3, "MP > LG", "11:10") || 0;
    ws.getCell("G20").value =
      getRouteCount(week_data3, "MP > LG", "12:10") || 0;
    ws.getCell("G21").value =
      getRouteCount(week_data3, "MP > LG", "14:10") || 0;
    ws.getCell("G22").value =
      getRouteCount(week_data3, "MP > LG", "15:10") || 0;
    ws.getCell("G23").value =
      getRouteCount(week_data3, "MP > LG", "16:10") || 0;
    ws.getCell("G24").value =
      getRouteCount(week_data3, "MP > LG", "17:10") || 0;
    ws.getCell("G25").value =
      getRouteCount(week_data3, "MP > LG", "18:10") || 0;

    ws.getCell("H18").value =
      getRouteCount(week_data3, "MP > LG", "10:10") || 0;
    ws.getCell("H19").value =
      getRouteCount(week_data3, "MP > LG", "11:10") || 0;
    ws.getCell("H20").value =
      getRouteCount(week_data3, "MP > LG", "12:10") || 0;
    ws.getCell("H21").value =
      getRouteCount(week_data3, "MP > LG", "14:10") || 0;
    ws.getCell("H22").value =
      getRouteCount(week_data3, "MP > LG", "15:10") || 0;
    ws.getCell("H23").value =
      getRouteCount(week_data3, "MP > LG", "16:10") || 0;
    ws.getCell("H24").value =
      getRouteCount(week_data3, "MP > LG", "17:10") || 0;
    ws.getCell("H25").value =
      getRouteCount(week_data3, "MP > LG", "18:10") || 0;

    // DAY 3 PATH 3
    ws.getCell("G33").value =
      getRouteCount(week_data3, "LG > MP", "10:40") || 0;
    ws.getCell("G34").value =
      getRouteCount(week_data3, "LG > MP", "11:40") || 0;
    ws.getCell("G35").value =
      getRouteCount(week_data3, "LG > MP", "12:40") || 0;
    ws.getCell("G36").value =
      getRouteCount(week_data3, "LG > MP", "14:40") || 0;
    ws.getCell("G37").value =
      getRouteCount(week_data3, "LG > MP", "15:40") || 0;
    ws.getCell("G38").value =
      getRouteCount(week_data3, "LG > MP", "16:40") || 0;
    ws.getCell("G39").value =
      getRouteCount(week_data3, "LG > MP", "17:40") || 0;
    ws.getCell("G40").value =
      getRouteCount(week_data3, "LG > MP", "18:40") || 0;

    ws.getCell("H33").value =
      getRouteCount(week_data3, "LG > MP", "10:40") || 0;
    ws.getCell("H34").value =
      getRouteCount(week_data3, "LG > MP", "11:40") || 0;
    ws.getCell("H35").value =
      getRouteCount(week_data3, "LG > MP", "12:40") || 0;
    ws.getCell("H36").value =
      getRouteCount(week_data3, "LG > MP", "14:40") || 0;
    ws.getCell("H37").value =
      getRouteCount(week_data3, "LG > MP", "15:40") || 0;
    ws.getCell("H38").value =
      getRouteCount(week_data3, "LG > MP", "16:40") || 0;
    ws.getCell("H39").value =
      getRouteCount(week_data3, "LG > MP", "17:40") || 0;
    ws.getCell("H40").value =
      getRouteCount(week_data3, "LG > MP", "18:40") || 0;

    // DAY 4
    const week_data4 = weekData[3]
      ? allItems.filter(
          (sItem: any) =>
            moment(sItem?.Date).format("YYYY-MM-DD") ===
            moment(weekData[3]).format("YYYY-MM-DD")
        )
      : [];

    ws.getCell("I8").value = getRouteCount(week_data4, "LG > MP", "08:40") || 0;
    ws.getCell("I9").value = getRouteCount(week_data4, "LG > MP", "09:40") || 0;

    ws.getCell("J8").value = ws.getCell("I8").value || 0;
    ws.getCell("J9").value = ws.getCell("I9").value || 0;

    ws.getCell("I10").value =
      ws.getCell("I8").value + ws.getCell("I9").value || 0;
    ws.getCell("I11").value = Math.floor(ws.getCell("I10").value / 2 || 0);

    ws.getCell("K6").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("K16").value = weekData[4] ? moment(weekData[4]).format("D") : 0;
    ws.getCell("K31").value = weekData[4] ? moment(weekData[4]).format("D") : 0;

    ws.getCell("A47").value = "Date";
    ws.getCell("C47").value = ws.getCell("C6").value;
    ws.getCell("D47").value = ws.getCell("E6").value;
    ws.getCell("E47").value = ws.getCell("G6").value;
    ws.getCell("F47").value = ws.getCell("I6").value;
    ws.getCell("G47").value = ws.getCell("K6").value;

    // DAY 4 PATH 2
    ws.getCell("I18").value =
      getRouteCount(week_data4, "MP > LG", "10:10") || 0;
    ws.getCell("I19").value =
      getRouteCount(week_data4, "MP > LG", "11:10") || 0;
    ws.getCell("I20").value =
      getRouteCount(week_data4, "MP > LG", "12:10") || 0;
    ws.getCell("I21").value =
      getRouteCount(week_data4, "MP > LG", "14:10") || 0;
    ws.getCell("I22").value =
      getRouteCount(week_data4, "MP > LG", "15:10") || 0;
    ws.getCell("I23").value =
      getRouteCount(week_data4, "MP > LG", "16:10") || 0;
    ws.getCell("I24").value =
      getRouteCount(week_data4, "MP > LG", "17:10") || 0;
    ws.getCell("I25").value =
      getRouteCount(week_data4, "MP > LG", "18:10") || 0;

    ws.getCell("J18").value =
      getRouteCount(week_data4, "MP > LG", "10:10") || 0;
    ws.getCell("J19").value =
      getRouteCount(week_data4, "MP > LG", "11:10") || 0;
    ws.getCell("J20").value =
      getRouteCount(week_data4, "MP > LG", "12:10") || 0;
    ws.getCell("J21").value =
      getRouteCount(week_data4, "MP > LG", "14:10") || 0;
    ws.getCell("J22").value =
      getRouteCount(week_data4, "MP > LG", "15:10") || 0;
    ws.getCell("J23").value =
      getRouteCount(week_data4, "MP > LG", "16:10") || 0;
    ws.getCell("J24").value =
      getRouteCount(week_data4, "MP > LG", "17:10") || 0;
    ws.getCell("J25").value =
      getRouteCount(week_data4, "MP > LG", "18:10") || 0;

    // DAY 4 PATH 3
    ws.getCell("I33").value =
      getRouteCount(week_data4, "LG > MP", "10:40") || 0;
    ws.getCell("I34").value =
      getRouteCount(week_data4, "LG > MP", "11:40") || 0;
    ws.getCell("I35").value =
      getRouteCount(week_data4, "LG > MP", "12:40") || 0;
    ws.getCell("I36").value =
      getRouteCount(week_data4, "LG > MP", "14:40") || 0;
    ws.getCell("I37").value =
      getRouteCount(week_data4, "LG > MP", "15:40") || 0;
    ws.getCell("I38").value =
      getRouteCount(week_data4, "LG > MP", "16:40") || 0;
    ws.getCell("I39").value =
      getRouteCount(week_data4, "LG > MP", "17:40") || 0;
    ws.getCell("I40").value =
      getRouteCount(week_data4, "LG > MP", "18:40") || 0;

    ws.getCell("J33").value =
      getRouteCount(week_data4, "LG > MP", "10:40") || 0;
    ws.getCell("J34").value =
      getRouteCount(week_data4, "LG > MP", "11:40") || 0;
    ws.getCell("J35").value =
      getRouteCount(week_data4, "LG > MP", "12:40") || 0;
    ws.getCell("J36").value =
      getRouteCount(week_data4, "LG > MP", "14:40") || 0;
    ws.getCell("J37").value =
      getRouteCount(week_data4, "LG > MP", "15:40") || 0;
    ws.getCell("J38").value =
      getRouteCount(week_data4, "LG > MP", "16:40") || 0;
    ws.getCell("J39").value =
      getRouteCount(week_data4, "LG > MP", "17:40") || 0;
    ws.getCell("J40").value =
      getRouteCount(week_data4, "LG > MP", "18:40") || 0;

    // DAY 5
    const week_data5 = weekData[4]
      ? allItems.filter(
          (sItem: any) =>
            moment(sItem?.Date).format("YYYY-MM-DD") ===
            moment(weekData[4]).format("YYYY-MM-DD")
        )
      : [];

    ws.getCell("K8").value = getRouteCount(week_data5, "LG > MP", "08:40") || 0;
    ws.getCell("K9").value = getRouteCount(week_data5, "LG > MP", "09:40") || 0;

    ws.getCell("L8").value = ws.getCell("K8").value || 0;
    ws.getCell("L9").value = ws.getCell("K9").value || 0;

    ws.getCell("K10").value =
      ws.getCell("K8").value + ws.getCell("K9").value || 0;
    ws.getCell("K11").value = Math.floor(ws.getCell("K10").value / 2 || 0);

    // DAY 5 PATH 2
    ws.getCell("K18").value =
      getRouteCount(week_data5, "MP > LG", "10:10") || 0;
    ws.getCell("K19").value =
      getRouteCount(week_data5, "MP > LG", "11:10") || 0;
    ws.getCell("K20").value =
      getRouteCount(week_data5, "MP > LG", "12:10") || 0;
    ws.getCell("K21").value =
      getRouteCount(week_data5, "MP > LG", "14:10") || 0;
    ws.getCell("K22").value =
      getRouteCount(week_data5, "MP > LG", "15:10") || 0;
    ws.getCell("K23").value =
      getRouteCount(week_data5, "MP > LG", "16:10") || 0;
    ws.getCell("K24").value =
      getRouteCount(week_data5, "MP > LG", "17:10") || 0;
    ws.getCell("K25").value =
      getRouteCount(week_data5, "MP > LG", "18:10") || 0;

    ws.getCell("L18").value =
      getRouteCount(week_data5, "MP > LG", "10:10") || 0;
    ws.getCell("L19").value =
      getRouteCount(week_data5, "MP > LG", "11:10") || 0;
    ws.getCell("L20").value =
      getRouteCount(week_data5, "MP > LG", "12:10") || 0;
    ws.getCell("L21").value =
      getRouteCount(week_data5, "MP > LG", "14:10") || 0;
    ws.getCell("L22").value =
      getRouteCount(week_data5, "MP > LG", "15:10") || 0;
    ws.getCell("L23").value =
      getRouteCount(week_data5, "MP > LG", "16:10") || 0;
    ws.getCell("L24").value =
      getRouteCount(week_data5, "MP > LG", "17:10") || 0;
    ws.getCell("L25").value =
      getRouteCount(week_data5, "MP > LG", "18:10") || 0;

    // DAY 5 PATH 3
    ws.getCell("K33").value =
      getRouteCount(week_data5, "LG > MP", "10:40") || 0;
    ws.getCell("K34").value =
      getRouteCount(week_data5, "LG > MP", "11:40") || 0;
    ws.getCell("K35").value =
      getRouteCount(week_data5, "LG > MP", "12:40") || 0;
    ws.getCell("K36").value =
      getRouteCount(week_data5, "LG > MP", "14:40") || 0;
    ws.getCell("K37").value =
      getRouteCount(week_data5, "LG > MP", "15:40") || 0;
    ws.getCell("K38").value =
      getRouteCount(week_data5, "LG > MP", "16:40") || 0;
    ws.getCell("K39").value =
      getRouteCount(week_data5, "LG > MP", "17:40") || 0;
    ws.getCell("K40").value =
      getRouteCount(week_data5, "LG > MP", "18:40") || 0;

    ws.getCell("L33").value =
      getRouteCount(week_data5, "LG > MP", "10:40") || 0;
    ws.getCell("L34").value =
      getRouteCount(week_data5, "LG > MP", "11:40") || 0;
    ws.getCell("L35").value =
      getRouteCount(week_data5, "LG > MP", "12:40") || 0;
    ws.getCell("L36").value =
      getRouteCount(week_data5, "LG > MP", "14:40") || 0;
    ws.getCell("L37").value =
      getRouteCount(week_data5, "LG > MP", "15:40") || 0;
    ws.getCell("L38").value =
      getRouteCount(week_data5, "LG > MP", "16:40") || 0;
    ws.getCell("L39").value =
      getRouteCount(week_data5, "LG > MP", "17:40") || 0;
    ws.getCell("L40").value =
      getRouteCount(week_data5, "LG > MP", "18:40") || 0;

    ws.getCell("M8").value =
      ws.getCell("C8").value +
      ws.getCell("E8").value +
      ws.getCell("G8").value +
      ws.getCell("I8").value +
      ws.getCell("K8").value;

    ws.getCell("M18").value =
      ws.getCell("C18").value +
      ws.getCell("E18").value +
      ws.getCell("G18").value +
      ws.getCell("I18").value +
      ws.getCell("K18").value;

    ws.getCell("M19").value =
      ws.getCell("C19").value +
      ws.getCell("E19").value +
      ws.getCell("G19").value +
      ws.getCell("I19").value +
      ws.getCell("K19").value;

    ws.getCell("M20").value =
      ws.getCell("C20").value +
      ws.getCell("E20").value +
      ws.getCell("G20").value +
      ws.getCell("I20").value +
      ws.getCell("K20").value;

    ws.getCell("M21").value =
      ws.getCell("C21").value +
      ws.getCell("E21").value +
      ws.getCell("G21").value +
      ws.getCell("I21").value +
      ws.getCell("K21").value;

    ws.getCell("M22").value =
      ws.getCell("C22").value +
      ws.getCell("E22").value +
      ws.getCell("G22").value +
      ws.getCell("I22").value +
      ws.getCell("K22").value;

    ws.getCell("M23").value =
      ws.getCell("C23").value +
      ws.getCell("E23").value +
      ws.getCell("G23").value +
      ws.getCell("I23").value +
      ws.getCell("K23").value;

    ws.getCell("M24").value =
      ws.getCell("C24").value +
      ws.getCell("E24").value +
      ws.getCell("G24").value +
      ws.getCell("I24").value +
      ws.getCell("K24").value;

    ws.getCell("M25").value =
      ws.getCell("C25").value +
      ws.getCell("E25").value +
      ws.getCell("G25").value +
      ws.getCell("I25").value +
      ws.getCell("K25").value;

    ws.getCell("M33").value =
      ws.getCell("C33").value +
      ws.getCell("E33").value +
      ws.getCell("G33").value +
      ws.getCell("I33").value +
      ws.getCell("K33").value;

    ws.getCell("M34").value =
      ws.getCell("C34").value +
      ws.getCell("E34").value +
      ws.getCell("G34").value +
      ws.getCell("I34").value +
      ws.getCell("K34").value;

    ws.getCell("M35").value =
      ws.getCell("C35").value +
      ws.getCell("E35").value +
      ws.getCell("G35").value +
      ws.getCell("I35").value +
      ws.getCell("K35").value;

    ws.getCell("M36").value =
      ws.getCell("C36").value +
      ws.getCell("E36").value +
      ws.getCell("G36").value +
      ws.getCell("I36").value +
      ws.getCell("K36").value;

    ws.getCell("M37").value =
      ws.getCell("C37").value +
      ws.getCell("E37").value +
      ws.getCell("G37").value +
      ws.getCell("I37").value +
      ws.getCell("K37").value;

    ws.getCell("M38").value =
      ws.getCell("C38").value +
      ws.getCell("E38").value +
      ws.getCell("G38").value +
      ws.getCell("I38").value +
      ws.getCell("K38").value;

    ws.getCell("M39").value =
      ws.getCell("C39").value +
      ws.getCell("E39").value +
      ws.getCell("G39").value +
      ws.getCell("I39").value +
      ws.getCell("K39").value;

    ws.getCell("M40").value =
      ws.getCell("C40").value +
      ws.getCell("E40").value +
      ws.getCell("G40").value +
      ws.getCell("I40").value +
      ws.getCell("K40").value;

    ws.getCell("M9").value =
      ws.getCell("C9").value +
      ws.getCell("E9").value +
      ws.getCell("G9").value +
      ws.getCell("I9").value +
      ws.getCell("K9").value;

    ws.getCell("N8").value = Math.floor(ws.getCell("M8").value / 5) || 0;
    ws.getCell("N9").value = Math.floor(ws.getCell("M9").value / 5) || 0;

    ws.getCell("N18").value = Math.floor(ws.getCell("M18").value / 5) || 0;
    ws.getCell("N19").value = Math.floor(ws.getCell("M19").value / 5) || 0;
    ws.getCell("N20").value = Math.floor(ws.getCell("M20").value / 5) || 0;
    ws.getCell("N21").value = Math.floor(ws.getCell("M21").value / 5) || 0;
    ws.getCell("N22").value = Math.floor(ws.getCell("M22").value / 5) || 0;
    ws.getCell("N23").value = Math.floor(ws.getCell("M23").value / 5) || 0;
    ws.getCell("N24").value = Math.floor(ws.getCell("M24").value / 5) || 0;
    ws.getCell("N25").value = Math.floor(ws.getCell("M25").value / 5) || 0;

    ws.getCell("N33").value = Math.floor(ws.getCell("M33").value / 5) || 0;
    ws.getCell("N34").value = Math.floor(ws.getCell("M34").value / 5) || 0;
    ws.getCell("N35").value = Math.floor(ws.getCell("M35").value / 5) || 0;
    ws.getCell("N36").value = Math.floor(ws.getCell("M36").value / 5) || 0;
    ws.getCell("N37").value = Math.floor(ws.getCell("M37").value / 5) || 0;
    ws.getCell("N38").value = Math.floor(ws.getCell("M38").value / 5) || 0;
    ws.getCell("N39").value = Math.floor(ws.getCell("M39").value / 5) || 0;
    ws.getCell("N40").value = Math.floor(ws.getCell("M40").value / 5) || 0;

    ws.getCell("O8").value =
      (ws.getCell("C8").value > 22 ? 1 : 0) +
      (ws.getCell("E8").value > 22 ? 1 : 0) +
      (ws.getCell("G8").value > 22 ? 1 : 0) +
      (ws.getCell("I8").value > 22 ? 1 : 0) +
      (ws.getCell("K8").value > 22 ? 1 : 0);
    ws.getCell("P8").value =
      (Math.floor((ws.getCell("O8").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O9").value =
      (ws.getCell("C9").value > 22 ? 1 : 0) +
      (ws.getCell("E9").value > 22 ? 1 : 0) +
      (ws.getCell("G9").value > 22 ? 1 : 0) +
      (ws.getCell("I9").value > 22 ? 1 : 0) +
      (ws.getCell("K9").value > 22 ? 1 : 0);
    ws.getCell("P9").value =
      (Math.floor((ws.getCell("O9").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("C26").value =
      ws.getCell("C18").value +
        ws.getCell("C19").value +
        ws.getCell("C20").value +
        ws.getCell("C21").value +
        ws.getCell("C22").value +
        ws.getCell("C23").value +
        ws.getCell("C24").value +
        ws.getCell("C25").value || 0;
    ws.getCell("C27").value = Math.floor(ws.getCell("C26").value / 8 || 0);

    ws.getCell("E26").value =
      ws.getCell("E18").value +
        ws.getCell("E19").value +
        ws.getCell("E20").value +
        ws.getCell("E21").value +
        ws.getCell("E22").value +
        ws.getCell("E23").value +
        ws.getCell("E24").value +
        ws.getCell("E25").value || 0;
    ws.getCell("E27").value = Math.floor(ws.getCell("E26").value / 8 || 0);

    ws.getCell("G26").value =
      ws.getCell("G18").value +
        ws.getCell("G19").value +
        ws.getCell("G20").value +
        ws.getCell("G21").value +
        ws.getCell("G22").value +
        ws.getCell("G23").value +
        ws.getCell("G24").value +
        ws.getCell("G25").value || 0;
    ws.getCell("G27").value = Math.floor(ws.getCell("G26").value / 8 || 0);

    ws.getCell("I26").value =
      ws.getCell("I18").value +
        ws.getCell("I19").value +
        ws.getCell("I20").value +
        ws.getCell("I21").value +
        ws.getCell("I22").value +
        ws.getCell("I23").value +
        ws.getCell("I24").value +
        ws.getCell("I25").value || 0;
    ws.getCell("I27").value = Math.floor(ws.getCell("I26").value / 8 || 0);

    ws.getCell("K26").value =
      ws.getCell("K18").value +
        ws.getCell("K19").value +
        ws.getCell("K20").value +
        ws.getCell("K21").value +
        ws.getCell("K22").value +
        ws.getCell("K23").value +
        ws.getCell("K24").value +
        ws.getCell("K25").value || 0;
    ws.getCell("K27").value = Math.floor(ws.getCell("K26").value / 8 || 0);

    ws.getCell("O18").value =
      (ws.getCell("C18").value > 22 ? 1 : 0) +
      (ws.getCell("E18").value > 22 ? 1 : 0) +
      (ws.getCell("G18").value > 22 ? 1 : 0) +
      (ws.getCell("I18").value > 22 ? 1 : 0) +
      (ws.getCell("K18").value > 22 ? 1 : 0);
    ws.getCell("P18").value =
      (Math.floor((ws.getCell("O18").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O19").value =
      (ws.getCell("C19").value > 22 ? 1 : 0) +
      (ws.getCell("E19").value > 22 ? 1 : 0) +
      (ws.getCell("G19").value > 22 ? 1 : 0) +
      (ws.getCell("I19").value > 22 ? 1 : 0) +
      (ws.getCell("K19").value > 22 ? 1 : 0);
    ws.getCell("P19").value =
      (Math.floor((ws.getCell("O19").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O20").value =
      (ws.getCell("C20").value > 22 ? 1 : 0) +
      (ws.getCell("E20").value > 22 ? 1 : 0) +
      (ws.getCell("G20").value > 22 ? 1 : 0) +
      (ws.getCell("I20").value > 22 ? 1 : 0) +
      (ws.getCell("K20").value > 22 ? 1 : 0);
    ws.getCell("P20").value =
      (Math.floor((ws.getCell("O20").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O21").value =
      (ws.getCell("C21").value > 22 ? 1 : 0) +
      (ws.getCell("E21").value > 22 ? 1 : 0) +
      (ws.getCell("G21").value > 22 ? 1 : 0) +
      (ws.getCell("I21").value > 22 ? 1 : 0) +
      (ws.getCell("K21").value > 22 ? 1 : 0);
    ws.getCell("P21").value =
      (Math.floor((ws.getCell("O21").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O22").value =
      (ws.getCell("C22").value > 22 ? 1 : 0) +
      (ws.getCell("E22").value > 22 ? 1 : 0) +
      (ws.getCell("G22").value > 22 ? 1 : 0) +
      (ws.getCell("I22").value > 22 ? 1 : 0) +
      (ws.getCell("K22").value > 22 ? 1 : 0);
    ws.getCell("P22").value =
      (Math.floor((ws.getCell("O22").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O23").value =
      (ws.getCell("C23").value > 22 ? 1 : 0) +
      (ws.getCell("E23").value > 22 ? 1 : 0) +
      (ws.getCell("G23").value > 22 ? 1 : 0) +
      (ws.getCell("I23").value > 22 ? 1 : 0) +
      (ws.getCell("K23").value > 22 ? 1 : 0);
    ws.getCell("P23").value =
      (Math.floor((ws.getCell("O23").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O24").value =
      (ws.getCell("C24").value > 22 ? 1 : 0) +
      (ws.getCell("E24").value > 22 ? 1 : 0) +
      (ws.getCell("G24").value > 22 ? 1 : 0) +
      (ws.getCell("I24").value > 22 ? 1 : 0) +
      (ws.getCell("K24").value > 22 ? 1 : 0);
    ws.getCell("P24").value =
      (Math.floor((ws.getCell("O24").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O25").value =
      (ws.getCell("C25").value > 22 ? 1 : 0) +
      (ws.getCell("E25").value > 22 ? 1 : 0) +
      (ws.getCell("G25").value > 22 ? 1 : 0) +
      (ws.getCell("I25").value > 22 ? 1 : 0) +
      (ws.getCell("K25").value > 22 ? 1 : 0);
    ws.getCell("P25").value =
      (Math.floor((ws.getCell("O25").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O26").value =
      (ws.getCell("C26").value > 22 ? 1 : 0) +
      (ws.getCell("E26").value > 22 ? 1 : 0) +
      (ws.getCell("G26").value > 22 ? 1 : 0) +
      (ws.getCell("I26").value > 22 ? 1 : 0) +
      (ws.getCell("K26").value > 22 ? 1 : 0);
    ws.getCell("P26").value =
      (Math.floor((ws.getCell("O26").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O27").value =
      (ws.getCell("C27").value > 22 ? 1 : 0) +
      (ws.getCell("E27").value > 22 ? 1 : 0) +
      (ws.getCell("G27").value > 22 ? 1 : 0) +
      (ws.getCell("I27").value > 22 ? 1 : 0) +
      (ws.getCell("K27").value > 22 ? 1 : 0);
    ws.getCell("P27").value =
      (Math.floor((ws.getCell("O27").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O10").value =
      (ws.getCell("C10").value > 22 ? 1 : 0) +
      (ws.getCell("E10").value > 22 ? 1 : 0) +
      (ws.getCell("G10").value > 22 ? 1 : 0) +
      (ws.getCell("I10").value > 22 ? 1 : 0) +
      (ws.getCell("K10").value > 22 ? 1 : 0);
    ws.getCell("P10").value =
      (Math.floor((ws.getCell("O10").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O11").value =
      (ws.getCell("C11").value > 22 ? 1 : 0) +
      (ws.getCell("E11").value > 22 ? 1 : 0) +
      (ws.getCell("G11").value > 22 ? 1 : 0) +
      (ws.getCell("I11").value > 22 ? 1 : 0) +
      (ws.getCell("K11").value > 22 ? 1 : 0);
    ws.getCell("P11").value =
      (Math.floor((ws.getCell("O11").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O33").value =
      (ws.getCell("C33").value > 22 ? 1 : 0) +
      (ws.getCell("E33").value > 22 ? 1 : 0) +
      (ws.getCell("G33").value > 22 ? 1 : 0) +
      (ws.getCell("I33").value > 22 ? 1 : 0) +
      (ws.getCell("K33").value > 22 ? 1 : 0);
    ws.getCell("P33").value =
      (Math.floor((ws.getCell("O33").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O34").value =
      (ws.getCell("C34").value > 22 ? 1 : 0) +
      (ws.getCell("E34").value > 22 ? 1 : 0) +
      (ws.getCell("G34").value > 22 ? 1 : 0) +
      (ws.getCell("I34").value > 22 ? 1 : 0) +
      (ws.getCell("K34").value > 22 ? 1 : 0);
    ws.getCell("P34").value =
      (Math.floor((ws.getCell("O34").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O35").value =
      (ws.getCell("C35").value > 22 ? 1 : 0) +
      (ws.getCell("E35").value > 22 ? 1 : 0) +
      (ws.getCell("G35").value > 22 ? 1 : 0) +
      (ws.getCell("I35").value > 22 ? 1 : 0) +
      (ws.getCell("K35").value > 22 ? 1 : 0);
    ws.getCell("P35").value =
      (Math.floor((ws.getCell("O35").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O36").value =
      (ws.getCell("C36").value > 22 ? 1 : 0) +
      (ws.getCell("E36").value > 22 ? 1 : 0) +
      (ws.getCell("G36").value > 22 ? 1 : 0) +
      (ws.getCell("I36").value > 22 ? 1 : 0) +
      (ws.getCell("K36").value > 22 ? 1 : 0);
    ws.getCell("P36").value =
      (Math.floor((ws.getCell("O36").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O37").value =
      (ws.getCell("C37").value > 22 ? 1 : 0) +
      (ws.getCell("E37").value > 22 ? 1 : 0) +
      (ws.getCell("G37").value > 22 ? 1 : 0) +
      (ws.getCell("I37").value > 22 ? 1 : 0) +
      (ws.getCell("K37").value > 22 ? 1 : 0);
    ws.getCell("P37").value =
      (Math.floor((ws.getCell("O37").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O38").value =
      (ws.getCell("C38").value > 22 ? 1 : 0) +
      (ws.getCell("E38").value > 22 ? 1 : 0) +
      (ws.getCell("G38").value > 22 ? 1 : 0) +
      (ws.getCell("I38").value > 22 ? 1 : 0) +
      (ws.getCell("K38").value > 22 ? 1 : 0);
    ws.getCell("P38").value =
      (Math.floor((ws.getCell("O38").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O39").value =
      (ws.getCell("C39").value > 22 ? 1 : 0) +
      (ws.getCell("E39").value > 22 ? 1 : 0) +
      (ws.getCell("G39").value > 22 ? 1 : 0) +
      (ws.getCell("I39").value > 22 ? 1 : 0) +
      (ws.getCell("K39").value > 22 ? 1 : 0);
    ws.getCell("P39").value =
      (Math.floor((ws.getCell("O39").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O40").value =
      (ws.getCell("C40").value > 22 ? 1 : 0) +
      (ws.getCell("E40").value > 22 ? 1 : 0) +
      (ws.getCell("G40").value > 22 ? 1 : 0) +
      (ws.getCell("I40").value > 22 ? 1 : 0) +
      (ws.getCell("K40").value > 22 ? 1 : 0);
    ws.getCell("P40").value =
      (Math.floor((ws.getCell("O40").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("O42").value =
      (ws.getCell("C42").value > 22 ? 1 : 0) +
      (ws.getCell("E42").value > 22 ? 1 : 0) +
      (ws.getCell("G42").value > 22 ? 1 : 0) +
      (ws.getCell("I42").value > 22 ? 1 : 0) +
      (ws.getCell("K42").value > 22 ? 1 : 0);
    ws.getCell("P42").value =
      (Math.floor((ws.getCell("O42").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q8").value =
      (ws.getCell("C8").value === 28 ? 1 : 0) +
        (ws.getCell("E8").value === 28 ? 1 : 0) +
        (ws.getCell("G8").value === 28 ? 1 : 0) +
        (ws.getCell("I8").value === 28 ? 1 : 0) +
        (ws.getCell("K8").value === 28 ? 1 : 0) || 0;
    ws.getCell("R8").value =
      (Math.floor((ws.getCell("Q8").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q9").value =
      (ws.getCell("C8").value === 28 ? 1 : 0) +
        (ws.getCell("E8").value === 28 ? 1 : 0) +
        (ws.getCell("G8").value === 28 ? 1 : 0) +
        (ws.getCell("I8").value === 28 ? 1 : 0) +
        (ws.getCell("K8").value === 28 ? 1 : 0) || 0;
    ws.getCell("R9").value =
      (Math.floor((ws.getCell("Q9").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q10").value =
      (ws.getCell("C10").value === 28 ? 1 : 0) +
        (ws.getCell("E10").value === 28 ? 1 : 0) +
        (ws.getCell("G10").value === 28 ? 1 : 0) +
        (ws.getCell("I10").value === 28 ? 1 : 0) +
        (ws.getCell("K10").value === 28 ? 1 : 0) || 0;
    ws.getCell("R10").value =
      (Math.floor((ws.getCell("Q10").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q11").value =
      (ws.getCell("C11").value === 28 ? 1 : 0) +
        (ws.getCell("E11").value === 28 ? 1 : 0) +
        (ws.getCell("G11").value === 28 ? 1 : 0) +
        (ws.getCell("I11").value === 28 ? 1 : 0) +
        (ws.getCell("K11").value === 28 ? 1 : 0) || 0;
    ws.getCell("R11").value =
      (Math.floor((ws.getCell("Q11").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q18").value =
      (ws.getCell("C18").value === 28 ? 1 : 0) +
        (ws.getCell("E18").value === 28 ? 1 : 0) +
        (ws.getCell("G18").value === 28 ? 1 : 0) +
        (ws.getCell("I18").value === 28 ? 1 : 0) +
        (ws.getCell("K18").value === 28 ? 1 : 0) || 0;
    ws.getCell("R18").value =
      (Math.floor((ws.getCell("Q18").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q19").value =
      (ws.getCell("C19").value === 28 ? 1 : 0) +
        (ws.getCell("E19").value === 28 ? 1 : 0) +
        (ws.getCell("G19").value === 28 ? 1 : 0) +
        (ws.getCell("I19").value === 28 ? 1 : 0) +
        (ws.getCell("K19").value === 28 ? 1 : 0) || 0;
    ws.getCell("R19").value =
      (Math.floor((ws.getCell("Q19").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q20").value =
      (ws.getCell("C20").value === 28 ? 1 : 0) +
        (ws.getCell("E20").value === 28 ? 1 : 0) +
        (ws.getCell("G20").value === 28 ? 1 : 0) +
        (ws.getCell("I20").value === 28 ? 1 : 0) +
        (ws.getCell("K20").value === 28 ? 1 : 0) || 0;
    ws.getCell("R20").value =
      (Math.floor((ws.getCell("Q20").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q21").value =
      (ws.getCell("C21").value === 28 ? 1 : 0) +
        (ws.getCell("E21").value === 28 ? 1 : 0) +
        (ws.getCell("G21").value === 28 ? 1 : 0) +
        (ws.getCell("I21").value === 28 ? 1 : 0) +
        (ws.getCell("K21").value === 28 ? 1 : 0) || 0;
    ws.getCell("R21").value =
      (Math.floor((ws.getCell("Q21").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q22").value =
      (ws.getCell("C22").value === 28 ? 1 : 0) +
        (ws.getCell("E22").value === 28 ? 1 : 0) +
        (ws.getCell("G22").value === 28 ? 1 : 0) +
        (ws.getCell("I22").value === 28 ? 1 : 0) +
        (ws.getCell("K22").value === 28 ? 1 : 0) || 0;
    ws.getCell("R22").value =
      (Math.floor((ws.getCell("Q22").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q23").value =
      (ws.getCell("C23").value === 28 ? 1 : 0) +
        (ws.getCell("E23").value === 28 ? 1 : 0) +
        (ws.getCell("G23").value === 28 ? 1 : 0) +
        (ws.getCell("I23").value === 28 ? 1 : 0) +
        (ws.getCell("K23").value === 28 ? 1 : 0) || 0;
    ws.getCell("R23").value =
      (Math.floor((ws.getCell("Q23").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q24").value =
      (ws.getCell("C24").value === 28 ? 1 : 0) +
        (ws.getCell("E24").value === 28 ? 1 : 0) +
        (ws.getCell("G24").value === 28 ? 1 : 0) +
        (ws.getCell("I24").value === 28 ? 1 : 0) +
        (ws.getCell("K24").value === 28 ? 1 : 0) || 0;
    ws.getCell("R24").value =
      (Math.floor((ws.getCell("Q24").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q25").value =
      (ws.getCell("C25").value === 28 ? 1 : 0) +
        (ws.getCell("E25").value === 28 ? 1 : 0) +
        (ws.getCell("G25").value === 28 ? 1 : 0) +
        (ws.getCell("I25").value === 28 ? 1 : 0) +
        (ws.getCell("K25").value === 28 ? 1 : 0) || 0;
    ws.getCell("R25").value =
      (Math.floor((ws.getCell("Q25").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q26").value =
      (ws.getCell("C26").value === 28 ? 1 : 0) +
        (ws.getCell("E26").value === 28 ? 1 : 0) +
        (ws.getCell("G26").value === 28 ? 1 : 0) +
        (ws.getCell("I26").value === 28 ? 1 : 0) +
        (ws.getCell("K26").value === 28 ? 1 : 0) || 0;
    ws.getCell("R26").value =
      (Math.floor((ws.getCell("Q26").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q27").value =
      (ws.getCell("C27").value === 28 ? 1 : 0) +
        (ws.getCell("E27").value === 28 ? 1 : 0) +
        (ws.getCell("G27").value === 28 ? 1 : 0) +
        (ws.getCell("I27").value === 28 ? 1 : 0) +
        (ws.getCell("K27").value === 28 ? 1 : 0) || 0;
    ws.getCell("R27").value =
      (Math.floor((ws.getCell("Q27").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q33").value =
      (ws.getCell("C33").value === 28 ? 1 : 0) +
        (ws.getCell("E33").value === 28 ? 1 : 0) +
        (ws.getCell("G33").value === 28 ? 1 : 0) +
        (ws.getCell("I33").value === 28 ? 1 : 0) +
        (ws.getCell("K33").value === 28 ? 1 : 0) || 0;
    ws.getCell("R33").value =
      (Math.floor((ws.getCell("Q33").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q34").value =
      (ws.getCell("C34").value === 28 ? 1 : 0) +
        (ws.getCell("E34").value === 28 ? 1 : 0) +
        (ws.getCell("G34").value === 28 ? 1 : 0) +
        (ws.getCell("I34").value === 28 ? 1 : 0) +
        (ws.getCell("K34").value === 28 ? 1 : 0) || 0;
    ws.getCell("R34").value =
      (Math.floor((ws.getCell("Q34").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q35").value =
      (ws.getCell("C35").value === 28 ? 1 : 0) +
        (ws.getCell("E35").value === 28 ? 1 : 0) +
        (ws.getCell("G35").value === 28 ? 1 : 0) +
        (ws.getCell("I35").value === 28 ? 1 : 0) +
        (ws.getCell("K35").value === 28 ? 1 : 0) || 0;
    ws.getCell("R35").value =
      (Math.floor((ws.getCell("Q35").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q36").value =
      (ws.getCell("C36").value === 28 ? 1 : 0) +
        (ws.getCell("E36").value === 28 ? 1 : 0) +
        (ws.getCell("G36").value === 28 ? 1 : 0) +
        (ws.getCell("I36").value === 28 ? 1 : 0) +
        (ws.getCell("K36").value === 28 ? 1 : 0) || 0;
    ws.getCell("R36").value =
      (Math.floor((ws.getCell("Q36").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q37").value =
      (ws.getCell("C37").value === 28 ? 1 : 0) +
        (ws.getCell("E37").value === 28 ? 1 : 0) +
        (ws.getCell("G37").value === 28 ? 1 : 0) +
        (ws.getCell("I37").value === 28 ? 1 : 0) +
        (ws.getCell("K37").value === 28 ? 1 : 0) || 0;
    ws.getCell("R37").value =
      (Math.floor((ws.getCell("Q37").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q38").value =
      (ws.getCell("C38").value === 28 ? 1 : 0) +
        (ws.getCell("E38").value === 28 ? 1 : 0) +
        (ws.getCell("G38").value === 28 ? 1 : 0) +
        (ws.getCell("I38").value === 28 ? 1 : 0) +
        (ws.getCell("K38").value === 28 ? 1 : 0) || 0;
    ws.getCell("R38").value =
      (Math.floor((ws.getCell("Q38").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q39").value =
      (ws.getCell("C39").value === 28 ? 1 : 0) +
        (ws.getCell("E39").value === 28 ? 1 : 0) +
        (ws.getCell("G39").value === 28 ? 1 : 0) +
        (ws.getCell("I39").value === 28 ? 1 : 0) +
        (ws.getCell("K39").value === 28 ? 1 : 0) || 0;
    ws.getCell("R39").value =
      (Math.floor((ws.getCell("Q39").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q40").value =
      (ws.getCell("C40").value === 28 ? 1 : 0) +
        (ws.getCell("E40").value === 28 ? 1 : 0) +
        (ws.getCell("G40").value === 28 ? 1 : 0) +
        (ws.getCell("I40").value === 28 ? 1 : 0) +
        (ws.getCell("K40").value === 28 ? 1 : 0) || 0;
    ws.getCell("R40").value =
      (Math.floor((ws.getCell("Q40").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q41").value =
      (ws.getCell("C41").value === 28 ? 1 : 0) +
        (ws.getCell("E41").value === 28 ? 1 : 0) +
        (ws.getCell("G41").value === 28 ? 1 : 0) +
        (ws.getCell("I41").value === 28 ? 1 : 0) +
        (ws.getCell("K41").value === 28 ? 1 : 0) || 0;
    ws.getCell("R41").value =
      (Math.floor((ws.getCell("Q41").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("Q42").value =
      (ws.getCell("C42").value === 28 ? 1 : 0) +
        (ws.getCell("E42").value === 28 ? 1 : 0) +
        (ws.getCell("G42").value === 28 ? 1 : 0) +
        (ws.getCell("I42").value === 28 ? 1 : 0) +
        (ws.getCell("K42").value === 28 ? 1 : 0) || 0;
    ws.getCell("R42").value =
      (Math.floor((ws.getCell("Q42").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("M10").value = ws.getCell("M8").value + ws.getCell("M9").value;
    ws.getCell("M11").value = Math.floor(ws.getCell("M10").value / 2) || 0;

    ws.getCell("M26").value =
      ws.getCell("M18").value +
      ws.getCell("M19").value +
      ws.getCell("M20").value +
      ws.getCell("M21").value +
      ws.getCell("M22").value +
      ws.getCell("M23").value +
      ws.getCell("M24").value +
      ws.getCell("M25").value;
    ws.getCell("M27").value = Math.floor(ws.getCell("M26").value / 8) || 0;

    ws.getCell("M41").value =
      ws.getCell("M33").value +
      ws.getCell("M34").value +
      ws.getCell("M35").value +
      ws.getCell("M36").value +
      ws.getCell("M37").value +
      ws.getCell("M38").value +
      ws.getCell("M39").value +
      ws.getCell("M40").value;
    ws.getCell("M42").value = Math.floor(ws.getCell("M41").value / 8) || 0;

    ws.getCell("N10").value = ws.getCell("N8").value + ws.getCell("N9").value;
    ws.getCell("N11").value = Math.floor(ws.getCell("N10").value / 2) || 0;

    ws.getCell("N26").value =
      ws.getCell("N18").value +
      ws.getCell("N19").value +
      ws.getCell("N20").value +
      ws.getCell("N21").value +
      ws.getCell("N22").value +
      ws.getCell("N23").value +
      ws.getCell("N24").value +
      ws.getCell("N25").value;
    ws.getCell("N27").value = Math.floor(ws.getCell("N26").value / 8) || 0;

    ws.getCell("C41").value =
      ws.getCell("C33").value +
        ws.getCell("C34").value +
        ws.getCell("C35").value +
        ws.getCell("C36").value +
        ws.getCell("C37").value +
        ws.getCell("C38").value +
        ws.getCell("C39").value +
        ws.getCell("C40").value || 0;
    ws.getCell("C42").value = Math.floor(ws.getCell("C41").value / 8 || 0);

    ws.getCell("E41").value =
      ws.getCell("E33").value +
        ws.getCell("E34").value +
        ws.getCell("E35").value +
        ws.getCell("E36").value +
        ws.getCell("E37").value +
        ws.getCell("E38").value +
        ws.getCell("E39").value +
        ws.getCell("E40").value || 0;
    ws.getCell("E42").value = Math.floor(ws.getCell("E41").value / 8 || 0);

    ws.getCell("G41").value =
      ws.getCell("G33").value +
        ws.getCell("G34").value +
        ws.getCell("G35").value +
        ws.getCell("G36").value +
        ws.getCell("G37").value +
        ws.getCell("G38").value +
        ws.getCell("G39").value +
        ws.getCell("G40").value || 0;
    ws.getCell("G42").value = Math.floor(ws.getCell("G41").value / 8 || 0);

    ws.getCell("I41").value =
      ws.getCell("I33").value +
        ws.getCell("I34").value +
        ws.getCell("I35").value +
        ws.getCell("I36").value +
        ws.getCell("I37").value +
        ws.getCell("I38").value +
        ws.getCell("I39").value +
        ws.getCell("I40").value || 0;
    ws.getCell("I42").value = Math.floor(ws.getCell("I41").value / 8 || 0);

    ws.getCell("K41").value =
      ws.getCell("K33").value +
        ws.getCell("K34").value +
        ws.getCell("K35").value +
        ws.getCell("K36").value +
        ws.getCell("K37").value +
        ws.getCell("K38").value +
        ws.getCell("K39").value +
        ws.getCell("K40").value || 0;
    ws.getCell("K42").value = Math.floor(ws.getCell("K41").value / 8 || 0);

    ws.getCell("O41").value =
      (ws.getCell("C41").value > 22 ? 1 : 0) +
      (ws.getCell("E41").value > 22 ? 1 : 0) +
      (ws.getCell("G41").value > 22 ? 1 : 0) +
      (ws.getCell("I41").value > 22 ? 1 : 0) +
      (ws.getCell("K41").value > 22 ? 1 : 0);
    ws.getCell("P41").value =
      (Math.floor((ws.getCell("O41").value / ws.getCell("P3").value) * 100) ||
        0) + "%";

    ws.getCell("N41").value =
      ws.getCell("N33").value +
      ws.getCell("N34").value +
      ws.getCell("N35").value +
      ws.getCell("N36").value +
      ws.getCell("N37").value +
      ws.getCell("N38").value +
      ws.getCell("N39").value +
      ws.getCell("N40").value;
    ws.getCell("N42").value = Math.floor(ws.getCell("N41").value / 8) || 0;

    // FINAL TOTALS
    ws.getCell("C48").value = ws.getCell("C26").value + ws.getCell("C41").value;
    ws.getCell("D48").value = ws.getCell("E26").value + ws.getCell("E41").value;
    ws.getCell("E48").value = ws.getCell("G26").value + ws.getCell("G41").value;
    ws.getCell("F48").value = ws.getCell("I26").value + ws.getCell("I41").value;
    ws.getCell("G48").value = ws.getCell("J26").value + ws.getCell("J41").value;

    ws.getCell("C49").value = ws.getCell("C27").value + ws.getCell("C42").value;
    ws.getCell("D49").value = ws.getCell("E27").value + ws.getCell("E42").value;
    ws.getCell("E49").value = ws.getCell("G27").value + ws.getCell("G42").value;
    ws.getCell("F49").value = ws.getCell("I27").value + ws.getCell("I42").value;
    ws.getCell("G49").value = ws.getCell("J27").value + ws.getCell("J42").value;

    ws.getCell("H48").value =
      ws.getCell("C48").value +
      ws.getCell("D48").value +
      ws.getCell("E48").value +
      ws.getCell("F48").value +
      ws.getCell("G48").value;
    ws.getCell("I48").value =
      Math.floor(ws.getCell("H48").value / ws.getCell("P3").value) || 0;

    ws.getCell("H49").value =
      ws.getCell("C49").value +
      ws.getCell("D49").value +
      ws.getCell("E49").value +
      ws.getCell("F49").value +
      ws.getCell("G49").value;
    ws.getCell("I49").value =
      Math.floor(ws.getCell("H49").value / ws.getCell("P3").value) || 0;

    ws.eachRow(function(row: any, rowNumber: any) {
      if (![1, 3, 4, 13, 14, 29, 45].includes(rowNumber)) {
        ws.getRow(rowNumber).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
      }

      // IF LESS THAN 0 THEN IT WILL BE SHOW IN RED
      row.eachCell({ includeEmpty: true }, (cell, cellNumber) => {
        if (
          typeof ws.getCell(cell._address).value === "number" &&
          ws.getCell(cell._address).value < 0
        ) {
          ws.getCell(cell._address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "FF0000",
            },
          };
        }
      });

      // IF MORE THAN 28 THEN IT WILL BE SHOW IN RED
      checkWeekMoreThan28.map((cItem) => {
        if (
          typeof ws.getCell(cItem).value === "number" &&
          ws.getCell(cItem).value > 28
        ) {
          ws.getCell(cItem).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "FF0000",
            },
          };
        } else if (
          typeof ws.getCell(cItem).value === "number" &&
          ws.getCell(cItem).value === 28
        ) {
          ws.getCell(cItem).font = {
            color: { argb: "FF0000" },
          };
        }
      });
    });
  });
};
