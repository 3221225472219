import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
interface Values {
  tab1_en: string;
  tab1_ch: string;
  tab2_en: string;
  tab2_ch: string;
  tab3_en: string;
  tab3_ch: string;
  tab4_en: string;
  tab4_ch: string;
}
export const AppInfo = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const siteId = searchParams.get("siteId");
  const [entities, setEntities] = useState<any>();

  const getSiteData = async () => {
    const isSiteID = siteId ? `?siteId=${siteId}` : "";
    try {
      const { data }: any = await api({
        url: `/appinfo${isSiteID}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: any = data.result.data;
        setEntities({
          ...item,
          status: item.isActive ? "Active" : "Inactive",
        });
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  useEffect(() => {
    getSiteData();
    // eslint-disable-next-line
  }, [siteId]);

  const setAppInfo = async (values: Values) => {
    let body = {
      tab1_en: values.tab1_en,
      tab1_ch: values.tab1_ch,
      tab2_en: values.tab2_en,
      tab2_ch: values.tab2_ch,
      tab3_en: values.tab3_en,
      tab3_ch: values.tab3_ch,
      tab4_en: values.tab4_en,
      tab4_ch: values.tab4_ch,
    };
    if (siteId) {
      body["siteId"] = siteId;
    }
    try {
      const { data }: any = await api({
        url: `/appinfo/modify`,
        method: "put",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("App Info Updated Successfully");
        // history.push("/site");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tab1_en: entities ? entities?.tab1_en : "",
      tab1_ch: entities ? entities?.tab1_ch : "",
      tab2_en: entities ? entities?.tab2_en : "",
      tab2_ch: entities ? entities?.tab2_ch : "",
      tab3_en: entities ? entities?.tab3_en : "",
      tab3_ch: entities ? entities?.tab3_ch : "",
      tab4_en: entities ? entities?.tab4_en : "",
      tab4_ch: entities ? entities?.tab4_ch : "",
    },
    validationSchema: Yup.object({
      tab1_en: Yup.string().required("Tab 1 (English) is required"),
      tab1_ch: Yup.string().required("Tab 1 (Chinese) is required"),
      tab2_en: Yup.string().required("Tab 2 (English) is required"),
      tab2_ch: Yup.string().required("Tab 2 (Chinese) is required"),
      tab3_en: Yup.string().required("Tab 3 (English) is required"),
      tab3_ch: Yup.string().required("Tab 3 (Chinese) is required"),
      tab4_en: Yup.string().required("Tab 4 (English) is required"),
      tab4_ch: Yup.string().required("Tab 4 (Chinese) is required"),
    }),
    onSubmit: (values: Values) => {
      setAppInfo(values);
    },
  });
  return (
    <div>
      <Card>
        <CardHeader title={"Set App Info"}></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 1 (English)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab1_en"
                  name="tab1_en"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab1_en || ""}
                />
                {formik.touched.tab1_en && formik.errors.tab1_en ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab1_en}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 1 (Chinese)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab1_ch"
                  name="tab1_ch"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab1_ch || ""}
                />
                {formik.touched.tab1_ch && formik.errors.tab1_ch ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab1_ch}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 2 (English)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab2_en"
                  name="tab2_en"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab2_en || ""}
                />
                {formik.touched.tab2_en && formik.errors.tab2_en ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab2_en}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 2 (Chinese)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab2_ch"
                  name="tab2_ch"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab2_ch || ""}
                />
                {formik.touched.tab2_ch && formik.errors.tab2_ch ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab2_ch}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 3 (English)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab3_en"
                  name="tab3_en"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab3_en || ""}
                />
                {formik.touched.tab3_en && formik.errors.tab3_en ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab3_en}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 3 (Chinese)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab3_ch"
                  name="tab3_ch"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab3_ch || ""}
                />
                {formik.touched.tab3_ch && formik.errors.tab3_ch ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab3_ch}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 4 (English)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab4_en"
                  name="tab4_en"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab4_en || ""}
                />
                {formik.touched.tab4_en && formik.errors.tab4_en ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab4_en}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Tab 4 (Chinese)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tab4_ch"
                  name="tab4_ch"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tab4_ch || ""}
                />
                {formik.touched.tab4_ch && formik.errors.tab4_ch ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tab4_ch}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/site">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AppInfo;
