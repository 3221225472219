import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { api } from "../../../common/api";
import excel from "exceljs";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import _ from "lodash";
import { summarySheet } from "./summary";
import { weeklySheet } from "./weekluReport.jsx";

const LateReport = () => {
  const [siteList, setSiteList] = useState<any>([]);

  //   GET SITE LIST DATA
  const fetchSiteListData = async () => {
    try {
      const { data }: any = await api({
        url: "/site/list",
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const findManulife = data.result?.data?.siteData?.find(
          (x: any) => x.name === "ManuLife"
        );
        if (findManulife) {
          formik.setFieldValue("site", findManulife._id);
        }
        setSiteList(data.result.data.siteData);
      }
    } catch (err) {}
  };

  const getCarSeats = (
    pathData: any,
    index: any,
    selectedLoc: any,
    seats: any
  ) => {
    const prevRemainSeat = pathData[index === 0 ? 0 : index - 1];
    const remained = index === 0 ? 0 : prevRemainSeat.seat || 0;

    const totalSeats = seats || 0;

    let carSeats = totalSeats;

    carSeats = index !== 0 ? remained || 0 : carSeats;

    const onSeat: any = selectedLoc?.remain_seat?.peopleOnBoard || 0;
    const offSeat: any = selectedLoc?.remain_seat?.peopleOffBoard || 0;

    let availableSeats = carSeats - onSeat + offSeat;

    availableSeats = index === pathData.length - 1 ? "" : availableSeats;

    if (selectedLoc.remain_seat?.clearCar) {
      availableSeats = totalSeats;
    }

    availableSeats = availableSeats < 0 ? 0 : availableSeats;

    availableSeats = availableSeats > totalSeats ? totalSeats : availableSeats;

    return availableSeats;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      month: moment().format("YYYY-MM"),
      site: "",
    },

    validationSchema: Yup.object({
      month: Yup.string().required("Please Select the Month"),
      site: Yup.string(),
    }),

    onSubmit: async (values) => {
      const wb: any = new excel.Workbook();
      try {
        const startDate = moment(`${values.month}-01`)
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDate = moment(`${values.month}-01`)
          .endOf("month")
          .format("YYYY-MM-DD");

        const carRes = await api({
          url: "/car/list?page=1&size=1000&isActive=true",
          method: "get",
        });

        const carList = carRes?.data?.result?.data?.carData;
        if (carList.length) {
          const { data }: any = await api({
            url: `/schedule/schedule/export/${values.site}?start_date=${startDate}&end_date=${endDate}`, //  `/schedule/sc/list/
            method: "get",
          });

          if (data.serverResponse.isError) {
            toast.error(data.serverResponse.message);
          } else {
            let item: any = data.result.data.map((x: any) => {
              return {
                ...x,
                scheduleDate: moment(x?.scheduleDate).format("YYYY-MM-DD"),
              };
            });

            const pathEventList: any = [];

            for (const element of item) {
              if (element && element.location && element.location.length > 1) {
                for (const [
                  index,
                  locationData,
                ] of element.location.entries()) {
                  const locationTime = element.location[0].start.split(":");
                  const sortOrder = Number(
                    Number(locationTime[0] * 60) + Number(locationTime[1])
                  );

                  // DELAYED TIME CALCULATE
                  let finalValDelay = 0;
                  if (
                    element.location[index].reached &&
                    element.location[index].reachedTime !== null
                  ) {
                    const reachedSplit = moment(
                      element.location[index].reachedTime
                    )
                      .format("HH:mm")
                      .split(":");
                    const startSplit = element.location[index].start.split(":");
                    const reachedMin =
                      parseInt(reachedSplit[0]) * 60 +
                      parseInt(reachedSplit[1]);
                    const startMin =
                      parseInt(startSplit[0]) * 60 + parseInt(startSplit[1]);
                    finalValDelay = Math.max(0, reachedMin - startMin);
                  }
                  // else if (element.location[index].start) {
                  //   const departureSplit = moment(new Date())
                  //     .format("HH:mm")
                  //     .split(":");
                  //   const startSplit = element.location[index].start.split(":");
                  //   const departureMin =
                  //     parseInt(departureSplit[0]) * 60 +
                  //     parseInt(departureSplit[1]);
                  //   const startMin =
                  //     parseInt(startSplit[0]) * 60 + parseInt(startSplit[1]);
                  //   finalValDelay = Math.max(0, departureMin - startMin);
                  // }

                  if (index !== element.location.length - 1) {
                    let locationTitle = "";
                    if (locationData.location_id) {
                      locationTitle = `${
                        element.location[index].location_id.code
                      } > ${element.location[index + 1].location_id.code}`;
                    }

                    const carSeats =
                      carList.find((cId: any) => cId._id === element?.carId)
                        ?.seats || 0;

                    const availableSeats = getCarSeats(
                      element.location,
                      index,
                      element.location[index],
                      carSeats
                    );

                    element.location[index].seat = availableSeats;

                    const pathEventObject = {
                      title: locationTitle,
                      delay: finalValDelay || 0,
                      sortT: sortOrder,
                      Date: moment(element.scheduleDate).format("YYYY-MM-DD"),
                      Time: locationData.start,
                      carId: element.carId,
                      "Direct bus": element.location.length > 2 ? 0 : 1,
                      "Route From": element.location[index].location_id.code,
                      "Route To": element.location[index + 1].location_id.code,
                      "Midway stop": index,
                      Cancellation: locationData.status === "CANCEL" ? 1 : 0,
                      passengers: carSeats - element.location[index].seat || 0, // available passengers at the current route
                      onBoard: locationData?.remain_seat?.peopleOnBoard || 0,
                      offBoard: locationData?.remain_seat?.peopleOffBoard || 0,
                      Delay:
                        locationData.reached && locationData.status === "DELAY"
                          ? 1
                          : 0,
                      onfullPercentage:
                        ((locationData?.remain_seat?.peopleOnBoard || 0) / // locationData?.remain_seat?.peopleOnBoard
                          (carList.find(
                            (cId: any) => cId._id === element?.carId
                          )?.seats || 0)) *
                        100,
                      fullPercentage:
                        ((carSeats - element.location[index].seat || 0) / // locationData?.remain_seat?.peopleOnBoard
                          (carList.find(
                            (cId: any) => cId._id === element?.carId
                          )?.seats || 0)) *
                        100,
                    };
                    pathEventList.push(pathEventObject);
                  }
                }
              }
            }

            const totalDays = Object.keys(_.groupBy(item, "scheduleDate"))
              .length;
            const dates = Object.keys(_.groupBy(item, "scheduleDate"));

            const weekDates = dates.reduce((acc: any, date: any) => {
              const yearWeek = `${moment(date).year()}-${moment(date).week()}`;
              if (!acc[yearWeek]) {
                acc[yearWeek] = [];
              }
              acc[yearWeek].push(date);

              return acc;
            }, {});

            summarySheet(wb, pathEventList, totalDays);

            weeklySheet(wb, pathEventList, totalDays, weekDates);

            wb.xlsx
              .writeBuffer(
                `Shuttle Bus Usage Summery - ${moment().format(
                  "MMM YYYY"
                )}.xlsx`
              )
              .then((buffer) => {
                saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  `Shuttle Bus Usage Summery - ${moment(
                    formik.values.month,
                    "YYYY-MM"
                  ).format("MMM YYYY")}.xlsx`
                );
              });
          }
        }
      } catch (err) {
        // @ts-ignore
        err.response && toast.error(err.message);
      }
    },
  });

  useEffect(() => {
    fetchSiteListData();
    // eslint-disable-next-line
  }, []);

  // SITE OPTIONS
  const siteOptions =
    [
      ...siteList?.map((site: any) => ({
        label: site.name,
        value: site._id,
      })),
    ] || [];

  return (
    <div>
      <Card>
        <CardHeader title={"Monthly ManuLife Report"}></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Select Month</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="month"
                      className="form-control"
                      id="month"
                      name="month"
                      onChange={formik.handleChange}
                      value={formik.values.month}
                    />

                    {formik.touched.month && formik.errors.month ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.month}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Site</div>
                <div className="col-lg-8">
                  <div className="input-group search-filter relative d-flex align-items-center">
                    <Select
                      isDisabled
                      name="site"
                      options={siteOptions}
                      closeMenuOnSelect={true}
                      value={siteOptions.filter(
                        (x: any) => x.value === formik.values.site
                      )}
                      onChange={(val: any) => {
                        formik.setFieldValue("site", val.value);
                      }}
                      className="w-100 filter-select"
                      classNamePrefix="select"
                      placeholder="-- Select Site --"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <button
                    className="btn btn-primary btn-success h-100"
                    name="fetch"
                    type="submit"
                    onClick={() => formik.handleSubmit()}
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default LateReport;
