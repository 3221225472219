export const yellowBgCell = [
    "A11", "A12", "C5", "D5", "E5", "F5", "G5", "H5", "I5",
    "J5", "K5", "L5", "M5", "N5", "O5", "P5", "Q5", "R5",
    "T5", "U5", "V5", "W5", "X5", "S5",
    "A21", "A22", "C15", "D15", "E15", "F15", "G15", "H15", "I15",
    "J15", "K15", "L15", "M15", "N15", "O15", "P15", "Q15", "R15",
    "T15", "U15", "V15", "W15", "X15", "S15", "C6", "C7", "C8", "C9", "C10", "C11", "C12",
    "D6", "D7", "D8", "D9", "D10", "D11", "D12", "E6", "E7", "E8", "E9", "E10", "E11", "E12",
    "F6", "F7", "F8", "F9", "F10", "F11", "F12", "G6", "G7", "G8", "G9", "G10", "G11", "G12",
    "H6", "H7", "H8", "H9", "H10", "H11", "H12", "I6", "I7", "I8", "I9", "I10", "I11", "I12",
    "J6", "J7", "J8", "J9", "J10", "J11", "J12", "K6", "K7", "K8", "K9", "K10", "K11", "K12",
    "L6", "L7", "L8", "L9", "L10", "L11", "L12", "M6", "M7", "M8", "M9", "M10", "M11", "M12",
    "N6", "N7", "N8", "N9", "N10", "N11", "N12", "O6", "O7", "O8", "O9", "O10", "O11", "O12",
    "P6", "P7", "P8", "P9", "P10", "P11", "P12", "Q6", "Q7", "Q8", "Q9", "Q10", "Q11", "Q12",
    "R6", "R7", "R8", "R9", "R10", "R11", "R12", "S6", "S7", "S8", "S9", "S10", "S11", "S12",
    "T6", "T7", "T8", "T9", "T10", "T11", "T12", "U6", "U7", "U8", "U9", "U10", "U11", "U12",
    "V6", "V7", "V8", "V9", "V10", "V11", "V12", "W6", "W7", "W8", "W9", "W10", "W11", "W12",
    "X6", "X7", "X8", "X9", "X10", "X11", "X12", "C16", "C17", "C18", "C19", "C20", "C21", "C22",
    "D16", "D17", "D18", "D19", "D20", "D21", "D22",
    "E16", "E17", "E18", "E19", "E20", "E21", "E22",
    "F16", "F17", "F18", "F19", "F20", "F21", "F22",
    "G16", "G17", "G18", "G19", "G20", "G21", "G22",
    "H16", "H17", "H18", "H19", "H20", "H21", "H22",
    "I16", "I17", "I18", "I19", "I20", "I21", "I22",
    "J16", "J17", "J18", "J19", "J20", "J21", "J22",
    "K16", "K17", "K18", "K19", "K20", "K21", "K22",
    "L16", "L17", "L18", "L19", "L20", "L21", "L22",
    "M16", "M17", "M18", "M19", "M20", "M21", "M22",
    "N16", "N17", "N18", "N19", "N20", "N21", "N22",
    "O16", "O17", "O18", "O19", "O20", "O21", "O22",
    "P16", "P17", "P18", "P19", "P20", "P21", "P22",
    "Q16", "Q17", "Q18", "Q19", "Q20", "Q21", "Q22",
    "R16", "R17", "R18", "R19", "R20", "R21", "R22",
    "S16", "S17", "S18", "S19", "S20", "S21", "S22",
    "T16", "T17", "T18", "T19", "T20", "T21", "T22",
    "U16", "U17", "U18", "U19", "U20", "U21", "U22",
    "V16", "V17", "V18", "V19", "V20", "V21", "V22",
    "W16", "W17", "W18", "W19", "W20", "W21", "W22",
    "X16", "X17", "X18", "X19", "X20", "X21", "X22",
    "C25", "D25", "E25", "F25", "G25", "H25", "I25", "J25",
    "K25", "L25", "M25", "N25", "O25", "C26", "D26", "E26", "F26", "G26", "H26", "I26", "J26",
    "K26", "L26", "M26", "N26", "O26", "C27", "D27", "E27", "F27", "G27", "H27", "I27", "J27",
    "K27", "L27", "M27", "N27", "O27", "C28", "D28", "E28", "F28", "G28", "H28", "I28", "J28",
    "K28", "L28", "M28", "N28", "O28", "C29", "D29", "E29", "F29", "G29", "H29", "I29", "J29",
    "K29", "L29", "M29", "N29", "O29", "C30", "D30", "E30", "F30", "G30", "H30", "I30", "J30",
    "K30", "L30", "M30", "N30", "O30", "C31", "D31", "E31", "F31", "G31", "H31", "I31", "J31",
    "K31", "L31", "M31", "N31", "O31", "C32", "D32", "E32", "F32", "G32", "H32", "I32", "J32",
    "K32", "L32", "M32", "N32", "O32", "A31", "A32",
    "C35", "D35", "E35", "F35", "G35", "H35", "I35", "J35",
    "K35", "L35", "M35", "N35", "O35", "C36", "D36", "E36", "F36", "G36", "H36", "I36", "J36",
    "K36", "L36", "M36", "N36", "O36", "C37", "D37", "E37", "F37", "G37", "H37", "I37", "J37",
    "K37", "L37", "M37", "N37", "O37", "C38", "D38", "E38", "F38", "G38", "H38", "I38", "J38",
    "K38", "L38", "M38", "N38", "O38", "C39", "D39", "E39", "F39", "G39", "H39", "I39", "J39",
    "K39", "L39", "M39", "N39", "O39", "C40", "D40", "E40", "F40", "G40", "H40", "I40", "J40",
    "K40", "L40", "M40", "N40", "O40", "C41", "D41", "E41", "F41", "G41", "H41", "I41", "J41",
    "K41", "L41", "M41", "N41", "O41", "A40", "A41", "C45", "D45", "E45", "F45", "G45", "H45",
    "I45", "J45", "K45", "L45", "M45", "N45", "O45", "P45", "Q45", "R45", "S45", "T45", "U45",
    "V45", "C46", "D46", "K46", "L46", 'S46', "T46", "U46", "V46",
    "A47", "C47", "D47", "K47", "L47", 'S47', "T47", "U47", "V47",
    "A48", "C48", "D48", "K48", "L48", 'S48', "T48", "U48", "V48", "C52", "D52", "E52", "F52", "G52", "H52", "I52",
    "J52", "K52", "L52", "M52", "N52", "O52", "P52", "Q52", "R52", "S52", "T52", "U52", "V52", "W52", "X52", "A67", "A68",
    "C53", "D53", "E53", "F53", "G53", "H53", "I53", "J53", "K53", "L53", "M53", "N53", "O53", "P53", "Q53", "R53", "S53", "T53", "U53", "V53", "W53", "X53",
    "C54", "D54", "E54", "F54", "G54", "H54", "I54", "J54", "K54", "L54", "M54", "N54", "O54", "P54", "Q54", "R54", "S54", "T54", "U54", "V54", "W54", "X54",
    "C55", "D55", "E55", "F55", "G55", "H55", "I55", "J55", "K55", "L55", "M55", "N55", "O55", "P55", "Q55", "R55", "S55", "T55", "U55", "V55", "W55", "X55",
    "C56", "D56", "E56", "F56", "G56", "H56", "I56", "J56", "K56", "L56", "M56", "N56", "O56", "P56", "Q56", "R56", "S56", "T56", "U56", "V56", "W56", "X56",
    "C57", "D57", "E57", "F57", "G57", "H57", "I57", "J57", "K57", "L57", "M57", "N57", "O57", "P57", "Q57", "R57", "S57", "T57", "U57", "V57", "W57", "X57",
    "C58", "D58", "E58", "F58", "G58", "H58", "I58", "J58", "K58", "L58", "M58", "N58", "O58", "P58", "Q58", "R58", "S58", "T58", "U58", "V58", "W58", "X58",
    "C59", "D59", "E59", "F59", "G59", "H59", "I59", "J59", "K59", "L59", "M59", "N59", "O59", "P59", "Q59", "R59", "S59", "T59", "U59", "V59", "W59", "X59",
    "C60", "D60", "E60", "F60", "G60", "H60", "I60", "J60", "K60", "L60", "M60", "N60", "O60", "P60", "Q60", "R60", "S60", "T60", "U60", "V60", "W60", "X60",
    "C61", "D61", "E61", "F61", "G61", "H61", "I61", "J61", "K61", "L61", "M61", "N61", "O61", "P61", "Q61", "R61", "S61", "T61", "U61", "V61", "W61", "X61",
    "C62", "D62", "E62", "F62", "G62", "H62", "I62", "J62", "K62", "L62", "M62", "N62", "O62", "P62", "Q62", "R62", "S62", "T62", "U62", "V62", "W62", "X62",
    "C63", "D63", "E63", "F63", "G63", "H63", "I63", "J63", "K63", "L63", "M63", "N63", "O63", "P63", "Q63", "R63", "S63", "T63", "U63", "V63", "W63", "X63",
    "C64", "D64", "E64", "F64", "G64", "H64", "I64", "J64", "K64", "L64", "M64", "N64", "O64", "P64", "Q64", "R64", "S64", "T64", "U64", "V64", "W64", "X64",
    "C65", "D65", "E65", "F65", "G65", "H65", "I65", "J65", "K65", "L65", "M65", "N65", "O65", "P65", "Q65", "R65", "S65", "T65", "U65", "V65", "W65", "X65",
    "C66", "D66", "E66", "F66", "G66", "H66", "I66", "J66", "K66", "L66", "M66", "N66", "O66", "P66", "Q66", "R66", "S66", "T66", "U66", "V66", "W66", "X66",
    "C67", "D67", "E67", "F67", "G67", "H67", "I67", "J67", "K67", "L67", "M67", "N67", "O67", "P67", "Q67", "R67", "S67", "T67", "U67", "V67", "W67", "X67",
    "C68", "D68", "E68", "F68", "G68", "H68", "I68", "J68", "K68", "L68", "M68", "N68", "O68", "P68", "Q68", "R68", "S68", "T68", "U68", "V68", "W68", "X68",
    "C71", "D71", "E71", "F71", "G71", "H71", "I71", "J71", "K71", "L71", "M71", "N71", "O71", "P71", "Q71", "R71", "S71", "T71", "U71", "V71", "W71", "X71",
    "C72", "D72", "E72", "F72", "G72", "H72", "I72", "J72", "K72", "L72", "M72", "N72", "O72", "P72", "Q72", "R72", "S72", "T72", "U72", "V72", "W72", "X72",
    "C73", "D73", "E73", "F73", "G73", "H73", "I73", "J73", "K73", "L73", "M73", "N73", "O73", "P73", "Q73", "R73", "S73", "T73", "U73", "V73", "W73", "X73",
    "C74", "D74", "E74", "F74", "G74", "H74", "I74", "J74", "K74", "L74", "M74", "N74", "O74", "P74", "Q74", "R74", "S74", "T74", "U74", "V74", "W74", "X74",
    "C75", "D75", "E75", "F75", "G75", "H75", "I75", "J75", "K75", "L75", "M75", "N75", "O75", "P75", "Q75", "R75", "S75", "T75", "U75", "V75", "W75", "X75",
    "C76", "D76", "E76", "F76", "G76", "H76", "I76", "J76", "K76", "L76", "M76", "N76", "O76", "P76", "Q76", "R76", "S76", "T76", "U76", "V76", "W76", "X76",
    "C77", "D77", "E77", "F77", "G77", "H77", "I77", "J77", "K77", "L77", "M77", "N77", "O77", "P77", "Q77", "R77", "S77", "T77", "U77", "V77", "W77", "X77",
    "C78", "D78", "E78", "F78", "G78", "H78", "I78", "J78", "K78", "L78", "M78", "N78", "O78", "P78", "Q78", "R78", "S78", "T78", "U78", "V78", "W78", "X78",
    "C79", "D79", "E79", "F79", "G79", "H79", "I79", "J79", "K79", "L79", "M79", "N79", "O79", "P79", "Q79", "R79", "S79", "T79", "U79", "V79", "W79", "X79",
    "C80", "D80", "E80", "F80", "G80", "H80", "I80", "J80", "K80", "L80", "M80", "N80", "O80", "P80", "Q80", "R80", "S80", "T80", "U80", "V80", "W80", "X80",
    "C81", "D81", "E81", "F81", "G81", "H81", "I81", "J81", "K81", "L81", "M81", "N81", "O81", "P81", "Q81", "R81", "S81", "T81", "U81", "V81", "W81", "X81",
    "C82", "D82", "E82", "F82", "G82", "H82", "I82", "J82", "K82", "L82", "M82", "N82", "O82", "P82", "Q82", "R82", "S82", "T82", "U82", "V82", "W82", "X82",
    "C83", "D83", "E83", "F83", "G83", "H83", "I83", "J83", "K83", "L83", "M83", "N83", "O83", "P83", "Q83", "R83", "S83", "T83", "U83", "V83", "W83", "X83",
    "C84", "D84", "E84", "F84", "G84", "H84", "I84", "J84", "K84", "L84", "M84", "N84", "O84", "P84", "Q84", "R84", "S84", "T84", "U84", "V84", "W84", "X84",
    "C85", "D85", "E85", "F85", "G85", "H85", "I85", "J85", "K85", "L85", "M85", "N85", "O85", "P85", "Q85", "R85", "S85", "T85", "U85", "V85", "W85", "X85",
    "C86", "D86", "E86", "F86", "G86", "H86", "I86", "J86", "K86", "L86", "M86", "N86", "O86", "P86", "Q86", "R86", "S86", "T86", "U86", "V86", "W86", "X86",
    "C87", "D87", "E87", "F87", "G87", "H87", "I87", "J87", "K87", "L87", "M87", "N87", "O87", "P87", "Q87", "R87", "S87", "T87", "U87", "V87", "W87", "X87",
    "C88", "D88", "E88", "F88", "G88", "H88", "I88", "J88", "K88", "L88", "M88", "N88", "O88", "P88", "Q88", "R88", "S88", "T88", "U88", "V88", "W88", "X88",
    "C89", "D89", "E89", "F89", "G89", "H89", "I89", "J89", "K89", "L89", "M89", "N89", "O89", "P89", "Q89", "R89", "S89", "T89", "U89", "V89", "W89", "X89",
    "A90", "A91", "C90", "D90", "E90", "F90", "G90", "H90", "I90", "J90", "K90", "L90", "M90", "N90", "O90", "P90", "Q90", "R90", "S90", "T90", "U90", "V90", "W90", "X90",
    "C91", "D91", "E91", "F91", "G91", "H91", "I91", "J91", "K91", "L91", "M91", "N91", "O91", "P91", "Q91", "R91", "S91", "T91", "U91", "V91", "W91", "X91",
    "C94", "C95", "C96", "C97", "C98", "C99", "C100", "C101", "C102", "C103", "C104", "C105", "C106", "C107", "C108", "C109", "C110", "C111", "C112", "A111",
    "D94", "D95", "D96", "D97", "D98", "D99", "D100", "D101", "D102", "D103", "D104", "D105", "D106", "D107", "D108", "D109", "D110", "D111", "D112", "A112",
    "E94", "E95", "E96", "E97", "E98", "E99", "E100", "E101", "E102", "E103", "E104", "E105", "E106", "E107", "E108", "E109", "E110", "E111", "E112",
    "F94", "F95", "F96", "F97", "F98", "F99", "F100", "F101", "F102", "F103", "F104", "F105", "F106", "F107", "F108", "F109", "F110", "F111", "F112",
    "G94", "G95", "G96", "G97", "G98", "G99", "G100", "G101", "G102", "G103", "G104", "G105", "G106", "G107", "G108", "G109", "G110", "G111", "G112",
    "H94", "H95", "H96", "H97", "H98", "H99", "H100", "H101", "H102", "H103", "H104", "H105", "H106", "H107", "H108", "H109", "H110", "H111", "H112",
    "I94", "I95", "I96", "I97", "I98", "I99", "I100", "I101", "I102", "I103", "I104", "I105", "I106", "I107", "I108", "I109", "I110", "I111", "I112",
    "J94", "J95", "J96", "J97", "J98", "J99", "J100", "J101", "J102", "J103", "J104", "J105", "J106", "J107", "J108", "J109", "J110", "J111", "J112",
    "K94", "K95", "K96", "K97", "K98", "K99", "K100", "K101", "K102", "K103", "K104", "K105", "K106", "K107", "K108", "K109", "K110", "K111", "K112",
    "L94", "L95", "L96", "L97", "L98", "L99", "L100", "L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108", "L109", "L110", "L111", "L112",
    "M94", "M95", "M96", "M97", "M98", "M99", "M100", "M101", "M102", "M103", "M104", "M105", "M106", "M107", "M108", "M109", "M110", "M111", "M112",
    "N94", "N95", "N96", "N97", "N98", "N99", "N100", "N101", "N102", "N103", "N104", "N105", "N106", "N107", "N108", "N109", "N110", "N111", "N112", "A133", "A134",
    "C115", "C116", "C117", "C118", "C119", "C120", "C121", "C122", "C123", "C124", "C125", "C126", "C127", "C128", "C129", "C130", "C131", "C132", "C133", "C134",
    "D115", "D116", "D117", "D118", "D119", "D120", "D121", "D122", "D123", "D124", "D125", "D126", "D127", "D128", "D129", "D130", "D131", "D132", "D133", "D134",
    "E115", "E116", "E117", "E118", "E119", "E120", "E121", "E122", "E123", "E124", "E125", "E126", "E127", "E128", "E129", "E130", "E131", "E132", "E133", "E134",
    "F115", "F116", "F117", "F118", "F119", "F120", "F121", "F122", "F123", "F124", "F125", "F126", "F127", "F128", "F129", "F130", "F131", "F132", "F133", "F134",
    "G115", "G116", "G117", "G118", "G119", "G120", "G121", "G122", "G123", "G124", "G125", "G126", "G127", "G128", "G129", "G130", "G131", "G132", "G133", "G134",
    "H115", "H116", "H117", "H118", "H119", "H120", "H121", "H122", "H123", "H124", "H125", "H126", "H127", "H128", "H129", "H130", "H131", "H132", "H133", "H134",
    "I115", "I116", "I117", "I118", "I119", "I120", "I121", "I122", "I123", "I124", "I125", "I126", "I127", "I128", "I129", "I130", "I131", "I132", "I133", "I134",
    "J115", "J116", "J117", "J118", "J119", "J120", "J121", "J122", "J123", "J124", "J125", "J126", "J127", "J128", "J129", "J130", "J131", "J132", "J133", "J134",
    "K115", "K116", "K117", "K118", "K119", "K120", "K121", "K122", "K123", "K124", "K125", "K126", "K127", "K128", "K129", "K130", "K131", "K132", "K133", "K134",
    "L115", "L116", "L117", "L118", "L119", "L120", "L121", "L122", "L123", "L124", "L125", "L126", "L127", "L128", "L129", "L130", "L131", "L132", "L133", "L134",
    "M115", "M116", "M117", "M118", "M119", "M120", "M121", "M122", "M123", "M124", "M125", "M126", "M127", "M128", "M129", "M130", "M131", "M132", "M133", "M134",
    "N115", "N116", "N117", "N118", "N119", "N120", "N121", "N122", "N123", "N124", "N125", "N126", "N127", "N128", "N129", "N130", "N131", "N132", "N133", "N134",
    "C137", "C138", "C139", "C140", "C141", "A140", "A141",
    "D137", "D138", "D139", "D140", "D141",
    "E137", "E138", "E139", "E140", "E141",
    "F137", "F138", "F139", "F140", "F141",
    "G137", "G138", "G139", "G140", "G141",
    "H137", "H138", "H139", "H140", "H141",
    "I137", "I138", "I139", "I140", "I141",
    "J137", "J138", "J139", "J140", "J141",
    "K137", "K138", "K139", "K140", "K141",
    "L137", "L138", "L139", "L140", "L141",
    "M137", "M138", "M139", "M140", "M141",
    "N137", "N138", "N139", "N140", "N141",
    "C144", "C145", "C146", "C147", "C148", "A147", "A148",
    "D144", "D145", "D146", "D147", "D148",
    "E144", "E145", "E146", "E147", "E148",
    "F144", "F145", "F146", "F147", "F148",
    "G144", "G145", "G146", "G147", "G148",
    "H144", "H145", "H146", "H147", "H148",
    "I144", "I145", "I146", "I147", "I148",
    "J144", "J145", "J146", "J147", "J148",
    "K144", "K145", "K146", "K147", "K148",
    "L144", "L145", "L146", "L147", "L148",
    "M144", "M145", "M146", "M147", "M148",
    "N144", "N145", "N146", "N147", "N148",
    "C153", "C154", "C155", "C156", "C157", "A156", "A157",
    "D153", "D154", "D155", "D156", "D157",
    "E153", "E154", "E155", "E156", "E157",
    "F153", "F154", "F155", "F156", "F157",
    "G153", "G154", "G155", "G156", "G157",
    "H153", "H154", "H155", "H156", "H157",
    "I153", "I154", "I155", "I156", "I157",
    "J153", "J154", "J155", "J156", "J157",
    "K153", "K154", "K155", "K156", "K157",
    "L153", "L154", "L155", "L156", "L157",
    "M153", "M154", "M155", "M156", "M157",
    "N153", "N154", "N155", "N156", "N157",
    "C160", "C161", "C162", "C163", "C164", "A163", "A164",
    "D160", "D161", "D162", "D163", "D164",
    "E160", "E161", "E162", "E163", "E164",
    "F160", "F161", "F162", "F163", "F164",
    "G160", "G161", "G162", "G163", "G164",
    "H160", "H161", "H162", "H163", "H164",
    "I160", "I161", "I162", "I163", "I164",
    "J160", "J161", "J162", "J163", "J164",
    "K160", "K161", "K162", "K163", "K164",
    "L160", "L161", "L162", "L163", "L164",
    "M160", "M161", "M162", "M163", "M164",
    "N160", "N161", "N162", "N163", "N164", "C168", "C169",
    "C167", "C168", "C169", "D167", "D168", "D169", "A176",
    "E167", "E168", "E169", "F167", "F168", "F169", "A177",
    "G167", "G168", "G169", "H167", "H168", "H169",
    "C172", "C173", "C174", "C175", "C176", "C177",
    "D172", "D173", "D174", "D175", "D176", "D177",
    "E172", "E173", "E174", "E175", "E176", "E177",
    "F172", "F173", "F174", "F175", "F176", "F177",
    "G172", "G173", "G174", "G175", "G176", "G177",
    "H172", "H173", "H174", "H175", "H176", "H177",
    "I172", "I173", "I174", "I175", "I176", "I177",
    "J172", "J173", "J174", "J175", "J176", "J177",
    "K172", "K173", "K174", "K175", "K176", "K177",
    "L172", "L173", "L174", "L175", "L176", "L177",
    "M172", "M173", "M174", "M175", "M176", "M177",
    "N172", "N173", "N174", "N175", "N176", "N177",
    "O172", "O173", "O174", "O175", "O176", "O177",
    "P172", "P173", "P174", "P175", "P176", "P177",
    "Q172", "Q173", "Q174", "Q175", "Q176", "Q177",
    "R172", "R173", "R174", "R175", "R176", "R177",
    "S172", "S173", "S174", "S175", "S176", "S177",
    "T172", "T173", "T174", "T175", "T176", "T177",
    "U172", "U173", "U174", "U175", "U176", "U177",
    "V172", "V173", "V174", "V175", "V176", "V177",
    "W172", "W173", "W174", "W175", "W176", "W177",
    "X172", "X173", "X174", "X175", "X176", "X177",
]

export const lightPurpleCell = [
    "B6", "B7", "B8", "B9", "B10", "B16", "B17", "B18", "B19", 'B20', "B46",
    "B72", "B73", "B74", "B75", "B76", "B77", "B78", "B79", "B80", "B81", "B82",
    "B83", "B84", "B85", "B86", "B87", "B88", "B89", "B173", "B174", "B175"
]

export const lightGreenCell = ["B53", "B54", "B55", "B56", "B57", "B58", "B59", "B60", "B61", "B62", "B63", "B64", "B65", "B66"]

export const lightBlueCell = []

export const borderRightMediumCell = []

export const borderTopBottomMediumCell = [
    "A5", "C5", "D5", "E5", "F5", "G5", "H5",
    "I5", "K5", "L5", "M5", "N5", "O5", "P5",
    "Q5", "S5", "U5", "W5", "S6", "U6",
    "A15", "C15", "D15", "E15", "F15", "G15", "H15",
    "I15", "K15", "L15", "M15", "N15", "O15", "P15",
    "Q15", "S15", "U15", "W15", "S16", "U16", "A25",
    "C25", "D25", "E25", "F25", "G25", "H25", "I25", "J25",
    "L25", "N25", "L26", "N26", "A35", "S53", "U53", "S72", "U72",
    "C35", "D35", "E35", "F35", "G35", "H35", "I35", "J35", "S173", "U173",
    "L35", "N35", "L36", "N36", "A45", "C45", "D45", "E45", "F45", "G45", "H45", "I45",
    "K45", "L45", "M45", "N45", "O45", "P45", "Q45", "S45", "U45", "S46", "U46", "A52",
    "B52", "C52", "D52", "E52", "F52", "G52", "H52", "I52", "K52", "L52", "M52", "N52", "O52",
    "P52", "Q52", "S52", "U52", "W52", "A71", "A172",
    "C71", "D71", "E71", "F71", "G71", "H71", "I71", "K71", "L71", "M71", "N71", "O71",
    "P71", "Q71", "S71", "U71", "W71", "A94", "A115",
    "C94", "D94", "E94", "F94", "G94", "H94", "I94", "K94", "M94", "K95", "M95",
    "C115", "D115", "E115", "F115", "G115", "H115", "I115", "K115", "M115", "K116", "M116",
    "C172", "D172", "E172", "F172", "G172", "H172", "I172", "K172", "L172", "M172", "N172", "O172",
    "P172", "Q172", "S172", "U172", "W172",
]

export const topBottomMediumRightThin = [
    "C137", "C144", "C153", "C160", "C167"
]

export const borderTopRightBottomMediumCell = [
    "B5", "J5", "R5", "T5", "V5", "X5", "A6", "B6",
    "T6", "V6",
    "B15", "J15", "R15", "T15", "V15", "X15", "A16", "B16",
    "T16", "V16", "B25", "A26", "K25", "M25", "O25", "M26", "O26",
    "B35", "A36", "K35", "M35", "O35", "M36", "O36", "A36",
    "M36", "O36", "B45", "J45", "R45", "T45", "V45",
    "A46", "B46", "D46", "L46", "T46", "V46", "B52", "B71", "B172",
    "J52", "R52", "T52", "V52", "X52", "A53", "B53", "J172", "R172", "T172", "V172", "X172", "A173", "B173",
    "J71", "R71", "T71", "V71", "X71", "A72", "B72", "E137", "E144", "E153", "E160", "D160", "H160", "K160",
    "B94", "A95", "J94", "L94", "N94", "D137", "H137", "K137", "D144", "H144", "K144", "D153", "H153", "K153",
    "B115", "A116", "J115", "L115", "N115", "A137", "M137", "A144", "M144", "A153", "M153", "A160", "M160",
    "G137", "J137", "G144", "J144", "G153", "J153", "G160", "J160", "A167", "D167", "E167", "G167"
]

export const borderRightThingBottomMediumCell = [
    "C6", "E6", "H6", "K6", "M6", "P6", "W6",
    "C10", "C12", "F10", "F12", "I10", "I12",
    "K10", "K12", "N10", "N12", "Q10", "Q12",
    "S10", "S12", "U10", "U12", "W10", "W12",
    "C16", "E16", "H16", "K16", "M16", "P16", "W16",
    "C20", "C22", "F20", "F22", "I20", "I22",
    "K20", "K22", "N20", "N22", "Q20", "Q22",
    "S20", "S22", "U20", "U22", "W20", "W22",
    "C26", "C30", "C32", "C36", "C39", "C41", "C46", "F175", "F177", "I175", "I177", "N175", "N177", "Q175", "Q177", "W175", "W177",
    "K46", "C48", "K48", "C53", "E53", "H53", "K53", "M53", "P53", "W53", "C72", "E72", "H72", "K72", "M72", "P72", "W72", "K175", "K177",
    "C66", "C68", "K66", "K68", "W66", "W68", "F66", "F68", "I66", "I68", "C173", "E173", "H173", "K173", "M173", "P173", "W173",
    "N66", "N68", "Q66", "Q68", "C89", "C91", "K89", "K91", "W89", "W91", "F89", "F91", "I89", "I91", "N89", "N91", "Q89", "Q91",
    "C95", "C110", "C112", "C116", "C132", "C134", "C139", "C141", "C146", "C148", "C155", "C157", "C162", "C164", "C169", "C175", "C177"
]

export const borderRightMediumBottomThinCell = [
    "A7", "A8", "A9", "B7", "B8", "B9", "A11",
    "D7", "D8", "D9", "D11", "G7", "G8", "G9",
    "G11", "J7", "J8", "J9", "J11", "L7", "L8", "L9", "L11",
    "O7", "O8", "O9", "O11", "R7", "R8", "R9", "R11",
    "T7", "T8", "T9", "T11", "V7", "V8", "V9", "V11",
    "X7", "X8", "X9", "X11",
    "A17", "A18", "A19", "B17", "B18", "B19", "A21",
    "D17", "D18", "D19", "D21", "G17", "G18", "G19",
    "G21", "J17", "J18", "J19", "J21", "L17", "L18", "L19", "L21",
    "O17", "O18", "O19", "O21", "R17", "R18", "R19", "R21",
    "T17", "T18", "T19", "T21", "V17", "V18", "V19", "V21",
    "X17", "X18", "X19", "X21", "A27", "A28", "A29", "A31",
    "D27", "D28", "D29", "D31", "D37", "D38", "D40",
    "F27", "F28", "F29", "F31", "F37", "F38", "F40",
    "H27", "H28", "H29", "H31", "H37", "H38", "H40",
    "J27", "J28", "J29", "J31", "J37", "J38", "J40",
    "M27", "M28", "M29", "M31", "M37", "M38", "M40",
    "O27", "O28", "O29", "O31", "O37", "O38", "O40",
    "K27", "K28", "K29", "K31", "A37", "A38", "A40",
    "K37", "K38", "K40", "A47", "D47", "L47", "T47", "V47",
    "A54", "A55", "A56", "A57", "A58", "A59", "A60", "A61", "A62", "A63", "A64", "A65",
    "B54", "B55", "B56", "B57", "B58", "B59", "B60", "B61", "B62", "B63", "B64", "B65",
    "A73", "A74", "A75", "A76", "A77", "A78", "A79", "A80", "A81", "A82", "A83", "A84", "A85", "A86", "A87", "A88",
    "B73", "B74", "B75", "B76", "B77", "B78", "B79", "B80", "B81", "B82", "B83", "B84", "B85", "B86", "B87", "B88", "B174",
    "A67", "D54", "D55", "D56", "D57", "D58", "D58", "D59", "D60", "D61", "D62", "D63", "D64", "D65", "D67", "A90", "A176",
    "L54", "L55", "L56", "L57", "L58", "L58", "L59", "L60", "L61", "L62", "L63", "L64", "L65", "L67",
    "O54", "O55", "O56", "O57", "O58", "O58", "O59", "O60", "O61", "O62", "O63", "O64", "O65", "O67",
    "R54", "R55", "R56", "R57", "R58", "R58", "R59", "R60", "R61", "R62", "R63", "R64", "R65", "R67",
    "T54", "T55", "T56", "T57", "T58", "T58", "T59", "T60", "T61", "T62", "T63", "T64", "T65", "T67",
    "G54", "G55", "G56", "G57", "G58", "G58", "G59", "G60", "G61", "G62", "G63", "G64", "G65", "G67",
    "J54", "J55", "J56", "J57", "J58", "J58", "J59", "J60", "J61", "J62", "J63", "J64", "J65", "J67",
    "V54", "V55", "V56", "V57", "V58", "V58", "V59", "V60", "V61", "V62", "V63", "V64", "V65", "V67",
    "X54", "X55", "X56", "X57", "X58", "X58", "X59", "X60", "X61", "X62", "X63", "X64", "X65", "X67",
    "D73", "D74", "D75", "D76", "D77", "D78", "D79", "D80", "D81", "D82", "D83", "D84", "D85", "D86", "D87", "D88", "D90",
    "G73", "G74", "G75", "G76", "G77", "G78", "G79", "G80", "G81", "G82", "G83", "G84", "G85", "G86", "G87", "G88", "G90",
    "J73", "J74", "J75", "J76", "J77", "J78", "J79", "J80", "J81", "J82", "J83", "J84", "J85", "J86", "J87", "J88", "J90",
    "V73", "V74", "V75", "V76", "V77", "V78", "V79", "V80", "V81", "V82", "V83", "V84", "V85", "V86", "V87", "V88", "V90",
    "X73", "X74", "X75", "X76", "X77", "X78", "X79", "X80", "X81", "X82", "X83", "X84", "X85", "X86", "X87", "X88", "X90",
    "L73", "L74", "L75", "L76", "L77", "L78", "L79", "L80", "L81", "L82", "L83", "L84", "L85", "L86", "L87", "L88", "L90",
    "O73", "O74", "O75", "O76", "O77", "O78", "O79", "O80", "O81", "O82", "O83", "O84", "O85", "O86", "O87", "O88", "O90",
    "R73", "R74", "R75", "R76", "R77", "R78", "R79", "R80", "R81", "R82", "R83", "R84", "R85", "R86", "R87", "R88", "R90",
    "T73", "T74", "T75", "T76", "T77", "T78", "T79", "T80", "T81", "T82", "T83", "T84", "T85", "T86", "T87", "T88", "T90",
    "D96", "D97", "D98", "D99", "D100", "D101", "D102", "D103", "D104", "D105", "D106", "D107", "D108", "D109", "D111",
    "A96", "A97", "A98", "A99", "A100", "A101", "A102", "A103", "A104", "A105", "A106", "A107", "A108", "A109", "A111",
    "F96", "F97", "F98", "F99", "F100", "F101", "F102", "F103", "F104", "F105", "F106", "F107", "F108", "F109", "F111",
    "I96", "I97", "I98", "I99", "I100", "I101", "I102", "I103", "I104", "I105", "I106", "I107", "I108", "I109", "I111",
    "L96", "L97", "L98", "L99", "L100", "L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108", "L109", "L111",
    "N96", "N97", "N98", "N99", "N100", "N101", "N102", "N103", "N104", "N105", "N106", "N107", "N108", "N109", "N111", "X174", "X176",
    "G96", "G97", "G98", "G99", "G100", "G101", "G102", "G103", "G104", "G105", "G106", "G107", "G108", "G109", "G111", "V174", "V176",
    "J96", "J97", "J98", "J99", "J100", "J101", "J102", "J103", "J104", "J105", "J106", "J107", "J108", "J109", "J111", "T174", "T176",
    "A117", "A118", "A119", "A120", "A121", "A122", "A123", "A124", "A125", "A126", "A127", "A128", "A129", "A130", "A131", "A133",
    "F117", "F118", "F119", "F120", "F121", "F122", "F123", "F124", "F125", "F126", "F127", "F128", "F129", "F130", "F131", "F133",
    "I117", "I118", "I119", "I120", "I121", "I122", "I123", "I124", "I125", "I126", "I127", "I128", "I129", "I130", "I131", "I133",
    "L117", "L118", "L119", "L120", "L121", "L122", "L123", "L124", "L125", "L126", "L127", "L128", "L129", "L130", "L131", "L133",
    "N117", "N118", "N119", "N120", "N121", "N122", "N123", "N124", "N125", "N126", "N127", "N128", "N129", "N130", "N131", "N133",
    "G117", "G118", "G119", "G120", "G121", "G122", "G123", "G124", "G125", "G126", "G127", "G128", "G129", "G130", "G131", "G133",
    "J117", "J118", "J119", "J120", "J121", "J122", "J123", "J124", "J125", "J126", "J127", "J128", "J129", "J130", "J131", "J133",
    "D117", "D118", "D119", "D120", "D121", "D122", "D123", "D124", "D125", "D126", "D127", "D128", "D129", "D130", "D131", "D133",
    "A138", "A140", "D138", "D140", "F138", "F140", "I138", "I140", "L138", "L140", "N138", "N140", "G138", "G140", "J138", "J140",
    "A145", "A147", "D145", "D147", "F145", "F147", "I145", "I147", "L145", "L147", "N145", "N147", "G145", "G147", "J145", "J147",
    "A154", "A156", "D154", "D156", "F154", "F156", "I154", "I156", "L154", "L156", "N154", "N156", "G154", "G156", "J154", "J156",
    "A161", "A163", "D161", "D163", "F161", "F163", "I161", "I163", "L161", "L163", "N161", "N163", "G161", "G163", "J161", "J163",
    "A168", "D168", "F168", "G168", "A174", "D174", "D176", "G174", "G176", "J174", "J176", "L174", "L176", "O174", "O176", "R174", "R176",
]

export const borderBottomMediumCell = [
    "E10", "E12", "H10", "H12",
    "M10", "M12", "P10", "P12",
    "E20", "E22", "H20", "H22",
    "M20", "M22", "P20", "P22",
    "E30", "E32", "I30", "I32",
    "G30", "G32", "L30", "L32",
    "N30", "N32", "E39", "E41",
    "G39", "G41", "I39", "I41",
    "L39", "L41", "N39", "N41",
    "S48", "U48", "E66", "E68", "H66", "H68", "H89", "H91", "M89", "M91", "P89", "P91",
    "M66", "M68", "P66", "P68", "S66", "S68", "S89", "S91", "S175", "S177", "U175", "U177",
    "U66", "U68", "E89", "E91", "M91", "M89", "P91", "P89", "S91", "U91", "U89",
    "E110", "E112", "H110", "H112", "K110", "K112", "M110", "M112",
    "E132", "E134", "H132", "H134", "K132", "K134", "M132", "M134",
    "E139", "E141", "H139", "H141", "K139", "K141", "M139", "M141",
    "E146", "E148", "H146", "H148", "K146", "K148", "M146", "M148", "E175", "E177", "H175", "H177", "M175", "M177", "P175", "P177",
    "E155", "E157", "H155", "H157", "K155", "K157", "M155", "M157", "E162", "E164", "H162", "H164", "K162", "K164", "M162", "M164",
]

export const borderBottomThinCell = [
    "E7", "E8", "E9", "E11",
    "H7", "H8", "H9", "H11",
    "M7", "M8", "M9", "M11",
    "P7", "P8", "P9", "P11",
    "E17", "E18", "E19", "E21",
    "H17", "H18", "H19", "H21",
    "M17", "M18", "M19", "M21",
    "P17", "P18", "P19", "P21",
    "E27", "E28", "E29", "E31",
    "G27", "G28", "G29", "G31",
    "I27", "I28", "I29", "I31",
    "L27", "L28", "L29", "L31",
    "N27", "N28", "N29", "N31",
    "E37", "E38", "E40", "G37", "G38", "G40",
    "I37", "I38", "I40", "L37", "L38", "L40",
    "N37", "N38", "N40", "S47", "U47",
    "E54", "E55", "E56", "E57", "E58", "E59", "E60", "E61", "E62", "E63", "E64", "E65",
    "H54", "H55", "H56", "H57", "H58", "H59", "H60", "H61", "H62", "H63", "H64", "H65",
    "M54", "M55", "M56", "M57", "M58", "M59", "M60", "M61", "M62", "M63", "M64", "M65",
    "P54", "P55", "P56", "P57", "P58", "P59", "P60", "P61", "P62", "P63", "P64", "P65",
    "S54", "S55", "S56", "S57", "S58", "S59", "S60", "S61", "S62", "S63", "S64", "S65",
    "U54", "U55", "U56", "U57", "U58", "U59", "U60", "U61", "U62", "U63", "U64", "U65",
    "E67", "H67", "M67", "P67", "S67", "U67",
    "E73", "E74", "E75", "E76", "E77", "E78", "E79", "E80", "E81", "E82", "E83", "E84", "E85", "E86", "E87", "E88", "E90",
    "H73", "H74", "H75", "H76", "H77", "H78", "H79", "H80", "H81", "H82", "H83", "H84", "H85", "H86", "H87", "H88", "H90",
    "M73", "M74", "M75", "M76", "M77", "M78", "M79", "M80", "M81", "M82", "M83", "M84", "M85", "M86", "M87", "M88", "M90",
    "P73", "P74", "P75", "P76", "P77", "P78", "P79", "P80", "P81", "P82", "P83", "P84", "P85", "P86", "P87", "P88", "P90",
    "S73", "S74", "S75", "S76", "S77", "S78", "S79", "S80", "S81", "S82", "S83", "S84", "S85", "S86", "S87", "S88", "S90",
    "U73", "U74", "U75", "U76", "U77", "U78", "U79", "U80", "U81", "U82", "U83", "U84", "U85", "U86", "U87", "U88", "U90",
    "E96", "E97", "E98", "E99", "E100", "E101", "E102", "E103", "E104", "E105", "E106", "E107", "E108", "E109", "E111",
    "H96", "H97", "H98", "H99", "H100", "H101", "H102", "H103", "H104", "H105", "H106", "H107", "H108", "H109", "H111",
    "K96", "K97", "K98", "K99", "K100", "K101", "K102", "K103", "K104", "K105", "K106", "K107", "K108", "K109", "K111", "U174", "U176",
    "M96", "M97", "M98", "M99", "M100", "M101", "M102", "M103", "M104", "M105", "M106", "M107", "M108", "M109", "M111", "S174", "S176",
    "E117", "E118", "E119", "E120", "E121", "E122", "E123", "E124", "E125", "E126", "E127", "E128", "E129", "E130", "E131", "E133",
    "H117", "H118", "H119", "H120", "H121", "H122", "H123", "H124", "H125", "H126", "H127", "H128", "H129", "H130", "H131", "H133",
    "K117", "K118", "K119", "K120", "K121", "K122", "K123", "K124", "K125", "K126", "K127", "K128", "K129", "K130", "K131", "K133",
    "M117", "M118", "M119", "M120", "M121", "M122", "M123", "M124", "M125", "M126", "M127", "M128", "M129", "M130", "M131", "M133",
    "E138", "E140", "H138", "H140", "K138", "K140", "M138", "M140", "E145", "E147", "H145", "H147", "K145", "K147", "M145", "M147",
    "E154", "E156", "H154", "H156", "K154", "K156", "M154", "M156", "E161", "E163", "H161", "H163", "K161", "K163", "M161", "M163",
    "E174", "E176", "H174", "H176", "M174", "M176", "P174", "P176"
]

export const borderBottomRightMediumCell = [
    "A10", "D6", "G6", "J6", "L6", "O6", "R6", "X6", "B10",
    "A12", "D10", "D12", "G10", "G12", "J10", "J12", "L10", "L12",
    "O10", "O12", "R10", "R12", "T10", "T12", "V10", "V12",
    "X10", "X12",
    "A20", "D16", "G16", "J16", "L16", "O16", "R16", "X16", "B20",
    "A22", "D20", "D22", "G20", "G22", "J20", "J22", "L20", "L22",
    "O20", "O22", "R20", "R22", "T20", "T22", "V20", "V22",
    "X20", "X22", "B30", "A32", "D26", "D30", "D32", "E26", "G26",
    "I26", "K26", "F30", "F32", "H30", "H32", "J30", "J32", "K30", "K32",
    "M30", "M32", "O30", "O32", "D36", "E36", "G36", "I36", "K36",
    "A39", "A41", "D39", "D41", "F39", "F41", "H39", "H41", "J39", "J41",
    "M39", "M41", "O39", "O41", "K39", "K41", "A48", "D48", "L48", "T48", "X89", "X91",
    "V48", "A66", "B66", "A68", "D53", "G53", "J53", 'L53', "O53", "R53", "V89", "V91",
    "T53", "V53", "X53", "D66", "D68", "L66", "L68", "X66", "X68", "G66", "T89", "T91",
    "G68", "J66", "J68", "O66", "O68", "T66", "T68", "V66", "V68", "R66", "R89", "R91", "T175", "T177", "V175", "V177", "X175", "X177",
    "R68", "D72", "G72", "J72", 'L72', "O72", "R72", "A89", "B89", "A91", "L89", "L91", "D173", "G173", "J173", 'L173', "O173", "R173", "T173", "V173", "X173",
    "T72", "V72", "X72", "D89", "D91", "G89", "G91", "J89", "J91", "O89", "O91", "D95", "D116", "A132", "A134", "D132", "D134", "N132", "N134", "A169", "B175", "O175", "O177", "R175", "R177",
    "A110", "A112", "D110", "D112", "E95", "G95", "H95", "J95", "L95", "N95", "N110", "E116", "G116", "H116", "J116", "L116", "N116", "A162", "A164", "G162", "G164", "F162", "F164", "F169", "G169",
    "F110", "F112", "G110", "G112", "I110", "I112", "J110", "J112", "L110", "L112", "N112", "A139", "A141", "G139", "G141", "F139", "F141", "A146", "A148", "G146", "G148", "F146", "F148",
    "F132", "F134", "G132", "G134", "I132", "I134", "J132", "J134", "L132", "L134", "D139", "D141", "N139", "N141", "I139", "I141", "L139", "L141", "A155", "A157", "G155", "G157", "F155", "F157",
    "J139", "J141", "D146", "D148", "N146", "N148", "I146", "I148", "L146", "L148", "J146", "J148", "D155", "D157", "N155", "N157", "I155", "I157", "L155", "L157", "J155", "J157",
    "D162", "D164", "N162", "N164", "I162", "I164", "L162", "L164", "J162", "J164", "D169", "A175", "A177", "D175", "D177", "G175", "G177", "J175", "J177", "L175", "L177",
]

export const borderBottomRightThin = [
    "C7", "C8", "C9", "C11", "F7", "F8", "F9",
    "F11", "I7", "I8", "I9", "I11", "K7", "K8", "K9", "K11",
    "N7", "N8", "N9", "N11", "Q7", "Q8", "Q9", "Q11",
    "S7", "S8", "S9", "S11", "U7", "U8", "U9", "U11",
    "W7", "W8", "W9", "W11",
    "C17", "C18", "C19", "C21", "F17", "F18", "F19",
    "F21", "I17", "I18", "I19", "I21", "K17", "K18", "K19", "K21",
    "N17", "N18", "N19", "N21", "Q17", "Q18", "Q19", "Q21",
    "S17", "S18", "S19", "S21", "U17", "U18", "U19", "U21",
    "W17", "W18", "W19", "W21", "C27", "C28", "C29", "C31",
    "C37", "C38", "C40", "C47", "K47", "C54", "C55", "C56",
    "C57", "C58", "C59", "C60", "C61", "C62", "C63", "C64", "C67",
    "C73", "C74", "C75", "F67", "I67", "N67", "C86", "C87", "C88", "C90", "C111",
    "C76", "C77", "C78", "C79", "C80", "C81", "C82", "C83", "C84", "C85",
    "C65", "K54", "K55", "K56", "K57", "K58", "K59", "K60", "K67", "Q67",
    "K61", "K62", "K63", "K64", "K65", "W54", "W55", "W56", "W67",
    "W57", "W58", "W59", "W60", "W61", "W62", "W63", "W64", "W65",
    "F54", "F55", "F56", "F57", "F58", "F59", "F60", "F61", "F62", "F63", "F64", "F65",
    "I54", "I55", "I56", "I57", "I58", "I59", "I60", "I61", "I62", "I63", "I64", "I65",
    "N54", "N55", "N56", "N57", "N58", "N59", "N60", "N61", "N62", "N63", "N64", "N65",
    "Q54", "Q55", "Q56", "Q57", "Q58", "Q59", "Q60", "Q61", "Q62", "Q63", "Q64", "Q65", "Q174", "Q176",
    "F73", "F74", "F75", "F76", "F77", "F78", "F79", "F80", "F81", "F82", "F83", "F84", "F85", "F86", "F87", "F88", "F90",
    "I73", "I74", "I75", "I76", "I77", "I78", "I79", "I80", "I81", "I82", "I83", "I84", "I85", "I86", "I87", "I88", "I90",
    "N73", "N74", "N75", "N76", "N77", "N78", "N79", "N80", "N81", "N82", "N83", "N84", "N85", "N86", "N87", "N88", "N90",
    "Q73", "Q74", "Q75", "Q76", "Q77", "Q78", "Q79", "Q80", "Q81", "Q82", "Q83", "Q84", "Q85", "Q86", "Q87", "Q88", "Q90",
    "W73", "W74", "W75", "W76", "W77", "W78", "W79", "W80", "W81", "W82", "W83", "W84", "W85", "W86", "W87", "W88", "W90",
    "K73", "K74", "K75", "K76", "K77", "K78", "K79", "K80", "K81", "K82", "K83", "K84", "K85", "K86", "K87", "K88", "K90", "W174", "W176",
    "C96", "C97", "C98", "C99", "C100", "C101", "C102", "C103", "C104", "C105", "C106", "C107", "C108", "C109", "N174", "N176",
    "C117", "C118", "C119", "C120", "C121", "C122", "C123", "C124", "C125", "C126", "C127", "C128", "C129", "C130", "C131", "C133",
    "C138", "C140", "C145", "C147", "C154", "C156", "C161", "C163", "C168", "F174", "F176", "C174", "C176", "I174", "I176", "K174", "K176",
]