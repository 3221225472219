import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { Switch } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import { store } from "../../../redux/store";
import moment from "moment";

interface Values {
  content: string;
  replied: boolean;
}
interface Params {
  id?: string;
}

const InquiryServiceDetails = () => {
  const params: Params = useParams();
  const [latestReportData, setLatestReportData] = useState<any>([]);
  const history = useHistory();

  const getLatestReportById = async () => {
    try {
      const { data }: any = await api({
        url: `/inquiry/details/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: any = data.result.data;
        setLatestReportData({
          ...item,
        });
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getLatestReportById();
    // eslint-disable-next-line
  }, []);

  const updateScheduleReport = async (values: Values) => {
    let body = {
      queryContent: values.content,
      replied: values.replied,
    };
    try {
      const { data }: any = await api({
        url: `inquiry/update/${params?.id}`,
        method: "put",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Data updated Successfully");
        history.push("/companyService/inquiryService");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: params.id ? latestReportData.queryContent : "",
      replied: params.id ? latestReportData.replied : false,
    },
    onSubmit: (values: Values) => {
      updateScheduleReport(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader title="Inquiry Service Details"></CardHeader>
        <CardBody>
          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Created Date
            </div>
            <div className="col-4">
              {moment(latestReportData?.createdAt).format("YYYY-MM-DD HH:mm")}
            </div>
          </div>

          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Service Name
            </div>
            <div className="col-4">
              {latestReportData?.serviceId?.serviceName_chi || ""}
            </div>
          </div>

          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Contact Number
            </div>
            <div className="col-4">{latestReportData?.contactNumber || ""}</div>
          </div>

          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">Name</div>
            <div className="col-4">{latestReportData?.name}</div>
          </div>

          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Email Address
            </div>
            <div className="col-4">{latestReportData?.emailAddress}</div>
          </div>

          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Query Type
            </div>
            <div className="col-4">{latestReportData?.queryType}</div>
          </div>

          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Query Content
            </div>
            <div className="col-4">
              <textarea
                className="form-control"
                style={{ maxWidth: "500px" }}
                id="content"
                rows={4}
                value={formik.values.content || ""}
                name="content"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              ></textarea>
            </div>
          </div>

          <div className="row mt-8">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Replied
            </div>
            <div className="col-4">
              <input
                type="checkbox"
                checked={formik.values.replied}
                onClick={() => {
                  formik.setFieldValue("replied", !formik.values.replied);
                }}
              />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <Link
                className="btn btn-primary"
                to="/companyService/inquiryService"
              >
                Back
              </Link>
            </div>
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default InquiryServiceDetails;
