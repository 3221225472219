export const yellowBgCell = [
    "M6", "N6", "O6", "P6", "Q6", "R6", "M16", "N16", "O16", "P16", "Q16", "R16", "M33", "N33", "O33", "P33", "Q33", "R33",
    "M7", "N7", "O7", "P7", "Q7", "R7", "M17", "N17", "O17", "P17", "Q17", "R17", "M34", "N34", "O34", "P34", "Q34", "R34",
    "M8", "N8", "O8", "P8", "Q8", "R8", "M18", "N18", "O18", "P18", "Q18", "R18", "M35", "N35", "O35", "P35", "Q35", "R35",
    "M9", "N9", "O9", "P9", "Q9", "R9", "M19", "N19", "O19", "P19", "Q19", "R19", "M36", "N36", "O36", "P36", "Q36", "R36",
    "M20", "N20", "O20", "P20", "Q20", "R20", "M37", "N37", "O37", "P37", "Q37", "R37",
    "M21", "N21", "O21", "P21", "Q21", "R21", "M38", "N38", "O38", "P38", "Q38", "R38",
    "M22", "N22", "O22", "P22", "Q22", "R22", "M39", "N39", "O39", "P39", "Q39", "R39",
    "M23", "N23", "O23", "P23", "Q23", "R23", "M40", "N40", "O40", "P40", "Q40", "R40",
    "M24", "N24", "O24", "P24", "Q24", "R24", "M41", "N41", "O41", "P41", "Q41", "R41",
    "M25", "N25", "O25", "P25", "Q25", "R25", "M42", "N42", "O42", "P42", "Q42", "R42",
    "A10", "B10", "C10", "D10", "E10", "F10", "G10", "H10", "I10", "J10", "K10", "L10", "M10", "N10", "O10", "P10", "Q10", "R10",
    "A11", "B11", "C11", "D11", "E11", "F11", "G11", "H11", "I11", "J11", "K11", "L11", "M11", "N11", "O11", "P11", "Q11", "R11",
    "A41", "B41", "C41", "D41", "E41", "F41", "G41", "H41", "I41", "J41", "K41", "L41", "M41", "N41", "O41", "P41", "Q41", "R41",
    "A42", "B42", "C42", "D42", "E42", "F42", "G42", "H42", "I42", "J42", "K42", "L42", "M42", "N42", "O42", "P42", "Q42", "R42",
    "A26", "B26", "C26", "D26", "E26", "F26", "G26", "H26", "I26", "J26", "K26", "L26", "M26", "N26", "O26", "P26", "Q26", "R26",
    "A27", "B27", "C27", "D27", "E27", "F27", "G27", "H27", "I27", "J27", "K27", "L27", "M27", "N27", "O27", "P27", "Q27", "R27",
    "H47", "I47", "A48", "B48", "C48", "D48", "E48", "F48", "G48", "H48", "I48",
    "A49", "B49", "C49", "D49", "E49", "F49", "G49", "H49", "I49",
    "M31", "N31", "O31", "P31", "Q31", "R31",
    "M32", "N32", "O32", "P32", "Q32",
]

export const lightPurpleCell = ["B7", "B8", "B9", "D7", "D8", "D9",
    "F7", "F8", "F9", "H7", "H8", "H9",
    "J7", "J8", "J9", "L7", "L8", "L9"
];

export const lightGreenCell = [
    "B17", "B18", "B19", "B20", "B21", "B22", "B23", "B24", "B25",
    "D17", "D18", "D19", "D20", "D21", "D22", "D23", "D24", "D25",
    "F17", "F18", "F19", "F20", "F21", "F22", "F23", "F24", "F25",
    "H17", "H18", "H19", "H20", "H21", "H22", "H23", "H24", "H25",
    "J17", "J18", "J19", "J20", "J21", "J22", "J23", "J24", "J25",
    "L17", "L18", "L19", "L20", "L21", "L22", "L23", "L24", "L25",
];

export const lightBlueCell = [
    "B32", "B33", "B34", "B35", "B36", "B37", "B38", "B39", "B40",
    "D32", "D33", "D34", "D35", "D36", "D37", "D38", "D39", "D40",
    "F32", "F33", "F34", "F35", "F36", "F37", "F38", "F39", "F40",
    "H32", "H33", "H34", "H35", "H36", "H37", "H38", "H39", "H40",
    "J32", "J33", "J34", "J35", "J36", "J37", "J38", "J39", "J40",
    "L32", "L33", "L34", "L35", "L36", "L37", "L38", "L39", "L40"
];

export const borderTopRightBottomMediumCell = [
    "B6", "R6", "B16", "R16", "A47", "A7", "B7", "O7", "Q7",
    "O17", "Q17", "A17", "A32", "B17", "B32", "B31", "R31",
    "O32", "Q32", "G47", "I47"
]

export const topBottomMediumRightThin = ["D6", "F6", "H6", "J6", "L6",
    "D16", "F16", "H16", "J16", "L16",
    "D31", "F31", "H31", "J31", "L31", "D47", "E47", "F47", "H47"
]

export const borderRightMediumCell = [];

export const borderRightThinCell = [];

export const borderTopBottomMediumCell = ["A6", "C6", "E6", "G6", "I6", "K6", "M6", "N6", "O6", "P6", "Q6",
    "A16", "C16", "E16", "G16", "I16", "K16", "M16", "N16", "O16", "P16", "Q16",
    "C31", "E31", "G31", "I31", "K31", "M31", "N31", "O31", "P31", "Q31",
    "A31"
];

export const borderRightThingBottomMediumCell = ["C7", "C11", "M11", "C9", "M9",
    "D9", "E9", "F9", "G9", "H9", "I9", "J9", "K9",
    "D11", "E11", "F11", "G11", "H11", "I11", "J11", "K11",
    "E7", "G7", "I7", "K7", "M7",
    "C17", "E17", "G17", "I17", "K17", "M17",
    "C32", "E32", "G32", "I32", "K32", "M32",
    "C25", "D25", "E25", "F25", "G25", "H25", "I25", "J25", "K25", "M25",
    "C40", "D40", "E40", "F40", "G40", "H40", "I40", "J40", "K40", "M40",
    "C27", "D27", "E27", "F27", "G27", "H27", "I27", "J27", "K27", "M27",
    "C42", "D42", "E42", "F42", "G42", "H42", "I42", "J42", "K42", "M42",
    "C49", "D49", "E49", "F49", "H49",
]

export const borderRightMediumBottomThinCell = ["A8", "B8", "L8", "N8", "A10", "L10", "N10",
    "P8", "P10", "R8", "R10", "A18", "A33", "A34", "B18", "A19", "B19", "A20", "B20", "A21", "B21", "A22", "B22",
    "A23", "B23", "A24", "B24", "A26", "A41", "A48", "L18", "L19", "L20", "L21", "L22", "L23", "L24",
    "N18", "N19", "N20", "N21", "N22", "N23", "N24",
    "N33", "N34", "N35", "N36", "N37", "N38", "N39",
    "P18", "P19", "P20", "P21", "P22", "P23", "P24",
    "R18", "R19", "R20", "R21", "R22", "R23", "R24",
    "R33", "R34", "R35", "R36", "R37", "R38", "R39",
    "N26", "P26", "R26", "L26", "A35", "A36", "A37", "A38",
    "A39", "B33", "B34", "B35", "B36", "B37", "B38", "B39",
    "L33", "L34", "L35", "L36", "L37", "L38", "L39",
    "P33", "P34", "P35", "P36", "P37", "P38", "P39",
    "L41", "P41", "R41", "G48", "I48",
    "N41",
]

export const borderBottomMediumCell = ["O9", "O11", "Q9", "Q11", "O25", "Q25", "O27", "Q27",
    "O40", "O42", "Q40", "Q42", "O40"
]

export const borderBottomThinCell = ["O8", "O10", "Q8", "Q10",
    "O18", "O19", "O20", "O21", "O22", "O23", "O24",
    "O33", "O34", "O35", "O36", "O37", "O38", "O39",
    "Q18", "Q19", "Q20", "Q21", "Q22", "Q23", "Q24", "O26", "Q26", "O41", "Q41",
    "Q33", "Q34", "Q35", "Q36", "Q37", "Q38", "Q39",

]

export const borderBottomRightMediumCell = [
    "A9", "B9", "L9", "N9", "M9", "A11", "L11", "N11",
    "D7", "F7", "H7", "J7", "L7", "N7", "R7",
    "D17", "F17", "H17", "J17", "L17", "N17", "R17",
    "D32", "F32", "H32", "J32", "L32", "N32", "R32",
    "P9", "P11", "R9", "R11", "A25", "A40",
    "B25", "B40", "A27", "A42", "A49", "L25", "N25", "P25", "R25",
    "N40", "P40", "R40",
    "L27", "N27", "P27", "R27", "L40", "L42", "N42", "P42", "R42", "G49", "I49"
]

export const borderBottomRightThin = [
    "C8", "C10", "C18", "C19", "C20", "C21", "C22", "C23", "C24", "C33", "C34", "C35", "C36", "C37", "C38", "C39",
    "M8", "M10", "M18", "M19", "M20", "M21", "M22", "M23", "M24", "M33", "M34", "M35", "M36", "M37", "M38", "M39",
    "D8", "D10", "D18", "D19", "D20", "D21", "D22", "D23", "D24", "D33", "D34", "D35", "D36", "D37", "D38", "D39",
    "E8", "E10", "E18", "E19", "E20", "E21", "E22", "E23", "E24", "E33", "E34", "E35", "E36", "E37", "E38", "E39",
    "F8", "F10", "F18", "F19", "F20", "F21", "F22", "F23", "F24", "F33", "F34", "F35", "F36", "F37", "F38", "F39",
    "G8", "G10", "G18", "G19", "G20", "G21", "G22", "G23", "G24", "G33", "G34", "G35", "G36", "G37", "G38", "G39",
    "H8", "H10", "H18", "H19", "H20", "H21", "H22", "H23", "H24", "H33", "H34", "H35", "H36", "H37", "H38", "H39",
    "I8", "I10", "I18", "I19", "I20", "I21", "I22", "I23", "I24", "I33", "I34", "I35", "I36", "I37", "I38", "I39",
    "J8", "J10", "J18", "J19", "J20", "J21", "J22", "J23", "J24", "J33", "J34", "J35", "J36", "J37", "J38", "J39",
    "K8", "K10", "K18", "K19", "K20", "K21", "K22", "K23", "K24", "K33", "K34", "K35", "K36", "K37", "K38", "K39",
    "C26", "D26", "E26", "F26", "G26", "H26", "I26", "J26", "K26", "C41", "D41", "E41", "F41", "G41", "H41", "I41",
    "J41", "K41", "M41", "M26", "C48", "D48", "E48", "F48", "H48",
]

export const alignmentCells = []