import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../helpers";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../partials/controls";

import { Link, useHistory } from "react-router-dom";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { sortString } from "../../../common/table/sorter";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
import { IconButton } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import TickIcon from "../../../../src/assets/Icons/true-icon.png";
import ActionIcon from "../../../../src/assets/Icons/blue-action.svg";
import { PasswordDialog } from "../../../common/changePasswordModal";
import moment from "moment";

const InquiryService: FC = () => {
  const history = useHistory();
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(0);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [queryType, setQueryType] = useState<any>("");
  const [type, setType] = useState<string>("");
  const [passwordModal, setPasswordModal] = useState<string | null>(null);
  const [sortingType, setSortingType] = useState<any>("");

  const renderRepliedCell = (tick?: any) => {
    return tick === true ? (
      <img
        src={TickIcon}
        style={{ marginLeft: "15px", maxWidth: 28 }}
        alt="action"
      />
    ) : null;
  };

  const renderActionCell = (id?: any) => {
    return (
      <img
        src={ActionIcon}
        style={{ marginLeft: "15px" }}
        alt="action"
        width="30"
        height="30"
      />
    );
  };

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isQuery = query !== "" ? `&search=${query}` : "";
    const isType = type !== "" ? `&isActive=${type}` : "";
    const queryToPass = queryType !== "" ? `&queryType=${queryType}` : "";
    const isSortColumn = sortingType !== "" ? `&${sortingType}` : "";
    try {
      const { data }: any = await api({
        url: `/inquiry/list/?page=${page}&size=${sizePerPage}${isQuery}${isType}${isSortColumn}${queryToPass}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.inquiryServiceData;
        const DriverEntities = entities.map((entity: any, ind: number) => {
          return {
            ...entity,
            id: ++ind + (page - 1) * sizePerPage,
            contactNumber: entity.contactNumber,
            created_at: moment(entity.createdAt).format("YYYY-MM-DD HH:mm"),
            serviceName: entity.serviceId?.serviceName_chi,
            name: entity.name,
            emailAddress: entity.emailAddress,
            queryType: entity.queryType,
            replied: renderRepliedCell(entity.replied || false),
            action: renderActionCell(entity._id),
          };
        });
        setTotalData(
          data.result.data.countInquiryService > 0
            ? data.result.data.countInquiryService
            : 0
        );
        setEntities(DriverEntities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingType]);

  const columns = [
    {
      dataField: "created_at",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "serviceName",
      text: "Service Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "contactNumber",
      text: "Contact Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "emailAddress",
      text: "Email Address",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "queryType",
      text: "Query Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "replied",
      text: "Replied",
      sort: false,
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
    },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };
  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };
  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: (data: any) =>
      history.push(`/companyService/inquiryService/${data._id}`),
  };

  return (
    <Card>
      <CardHeader title="Inquiry Service"></CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex flex-column flex-sm-row">
                <SizePerPage
                  paginationProps={paginationProps}
                  handleSizePerPage={handleSizePerPage}
                  entities={entities}
                />
                <FilterQuery query={query} setQuery={setQuery} title="Search" />
                <StatusFilter
                  status={queryType}
                  setStatus={setQueryType}
                  options={[
                    { label: "All", value: "" },
                    { label: "索取報價", value: "索取報價" },
                    { label: "行程規劃", value: "行程規劃" },
                  ]}
                  title="Type"
                />
                <StatusFilter
                  status={type}
                  setStatus={setType}
                  options={[
                    { label: "All", value: "" },
                    { label: "Replied", value: "true" },
                    { label: "Not Repiled", value: "false" },
                  ]}
                  title="Status"
                />
                <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    setPage(1);
                    getEntities(1, sizePerPage);
                  }}
                >
                  <span className="navigation-icon icon-white">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </button>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {Boolean(passwordModal) && (
        <PasswordDialog
          name="edit"
          show={passwordModal}
          onHide={() => setPasswordModal(null)}
          action=""
          headerText="Password"
          bodyText="Are you sure you want to edit password?"
          loadingText="Password changed..."
          actionText="Modify"
          actionStyle="primary"
        />
      )}
    </Card>
  );
};

export default InquiryService;
