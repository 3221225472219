export const yellowBgCell = [
    "W5", "X5", "Y5", "Z5", "AA5", "AB5", "AC5", "AD5", "AE5", "AF5", "AG5", "AH5", "AI5", "AJ5",
    "W6", "X6", "Y6", "Z6", "AA6", "AB6", "AC6", "AD6", "AE6", "AF6", "AG6", "AH6", "AI6", "AJ6",
    "W7", "X7", "Y7", "Z7", "AA7", "AB7", "AC7", "AD7", "AE7", "AF7", "AG7", "AH7", "AI7", "AJ7",
    "W8", "X8", "Y8", "Z8", "AA8", "AB8", "AC8", "AD8", "AE8", "AF8", "AG8", "AH8", "AI8", "AJ8",
    "W9", "X9", "Y9", "Z9", "AA9", "AB9", "AC9", "AD9", "AE9", "AF9", "AG9", "AH9", "AI9", "AJ9",
    "W10", "X10", "Y10", "Z10", "AA10", "AB10", "AC10", "AD10", "AE10", "AF10", "AG10", "AH10", "AI10", "AJ10",
    "W11", "X11", "Y11", "Z11", "AA11", "AB11", "AC11", "AD11", "AE11", "AF11", "AG11", "AH11", "AI11", "AJ11",
    "W12", "X12", "Y12", "Z12", "AA12", "AB12", "AC12", "AD12", "AE12", "AF12", "AG12", "AH12", "AI12", "AJ12",
    "A11", "B11", "C11", "D11", "E11", "F11", "G11", "H11", "I11", "J11", "K11", "L11", "M11", "N11", "O11", "P11", "Q11", "R11", "S11", "T11", "U11", "V11",
    "A12", "B12", "C12", "D12", "E12", "F12", "G12", "H12", "I12", "J12", "K12", "L12", "M12", "N12", "O12", "P12", "Q12", "R12", "S12", "T12", "U12", "V12",
    "W15", "X15", "Y15", "Z15", "AA15", "AB15", "AC15", "AD15", "AE15", "AF15", "AG15", "AH15", "AI15", "AJ15",
    "W16", "X16", "Y16", "Z16", "AA16", "AB16", "AC16", "AD16", "AE16", "AF16", "AG16", "AH16", "AI16", "AJ16",
    "W17", "X17", "Y17", "Z17", "AA17", "AB17", "AC17", "AD17", "AE17", "AF17", "AG17", "AH17", "AI17", "AJ17",
    "W18", "X18", "Y18", "Z18", "AA18", "AB18", "AC18", "AD18", "AE18", "AF18", "AG18", "AH18", "AI18", "AJ18",
    "W19", "X19", "Y19", "Z19", "AA19", "AB19", "AC19", "AD19", "AE19", "AF19", "AG19", "AH19", "AI19", "AJ19",
    "W20", "X20", "Y20", "Z20", "AA20", "AB20", "AC20", "AD20", "AE20", "AF20", "AG20", "AH20", "AI20", "AJ20",
    "W21", "X21", "Y21", "Z21", "AA21", "AB21", "AC21", "AD21", "AE21", "AF21", "AG21", "AH21", "AI21", "AJ21",
    "W22", "X22", "Y22", "Z22", "AA22", "AB22", "AC22", "AD22", "AE22", "AF22", "AG22", "AH22", "AI22", "AJ22",
    "A21", "B21", "C21", "D21", "E21", "F21", "G21", "H21", "I21", "J21", "K21", "L21", "M21", "N21", "O21", "P21", "Q21", "R21", "S21", "T21", "U21", "V21",
    "A22", "B22", "C22", "D22", "E22", "F22", "G22", "H22", "I22", "J22", "K22", "L22", "M22", "N22", "O22", "P22", "Q22", "R22", "S22", "T22", "U22", "V22",
    "H25", "I25", "J25", "K25", "L25", "M25", "H26", "I26", "J26", "K26", "L26", "M26", "H27", "I27", "J27", "K27", "L27", "M27", "H28", "I28", "J28", "K28", "L28", "M28", "H29", "I29", "J29", "K29", "L29", "M29", "H30", "I30", "J30", "K30", "L30", "M30", "H31", "I31", "J31", "K31", "L31", "M31",
    "H32", "I32", "J32", "K32", "L32", "M32", "A31", "C31", "D31", "E31", "F31", "G31", "A32", "C32", "D32", "E32", "F32", "G32",
    "H35", "I35", "J35", "K35", "L35", "M35",
    "H36", "I36", "J36", "K36", "L36", "M36",
    "H37", "I37", "J37", "K37", "L37", "M37",
    "H38", "I38", "J38", "K38", "L38", "M38",
    "H39", "I39", "J39", "K39", "L39", "M39",
    "H40", "I40", "J40", "K40", "L40", "M40",
    "H41", "I41", "J41", "K41", "L41", "M41",
    "A40", "C40", "D40", "E40", "F40", "G40", "A41", "C41", "D41", "E41", "F41", "G41",
    "W45", "X45", "Y45", "Z45", "AA45", "AB45", "AC45", "AD45", "AE45", "AF45", "AG45", "AH45",
    "W46", "X46", "Y46", "Z46", "AA46", "AB46", "AC46", "AD46", "AE46", "AF46", "AG46", "AH46",
    "W47", "X47", "Y47", "Z47", "AA47", "AB47", "AC47", "AD47", "AE47", "AF47", "AG47", "AH47",
    "W48", "X48", "Y48", "Z48", "AA48", "AB48", "AC48", "AD48", "AE48", "AF48", "AG48", "AH48",
    "A47", "B47", "C47", "D47", "E47", "F47", "G47", "H47", "I47", "J47", "K47", "L47", "M47", "N47", "O47", "P47", "Q47", "R47", "S47", "T47", "U47", "V47",
    "A48", "B48", "C48", "D48", "E48", "F48", "G48", "H48", "I48", "J48", "K48", "L48", "M48", "N48", "O48", "P48", "Q48", "R48", "S48", "T48", "U48", "V48",
    "W52", "X52", "Y52", "Z52", "AA52", "AB52", "AC52", "AD52", "AE52", "AF52", "AG52", "AH52", "AI52", "AJ52",
    "W53", "X53", "Y53", "Z53", "AA53", "AB53", "AC53", "AD53", "AE53", "AF53", "AG53", "AH53", "AI53", "AJ53",
    "W54", "X54", "Y54", "Z54", "AA54", "AB54", "AC54", "AD54", "AE54", "AF54", "AG54", "AH54", "AI54", "AJ54",
    "W55", "X55", "Y55", "Z55", "AA55", "AB55", "AC55", "AD55", "AE55", "AF55", "AG55", "AH55", "AI55", "AJ55",
    "W56", "X56", "Y56", "Z56", "AA56", "AB56", "AC56", "AD56", "AE56", "AF56", "AG56", "AH56", "AI56", "AJ56",
    "W57", "X57", "Y57", "Z57", "AA57", "AB57", "AC57", "AD57", "AE57", "AF57", "AG57", "AH57", "AI57", "AJ57",
    "W58", "X58", "Y58", "Z58", "AA58", "AB58", "AC58", "AD58", "AE58", "AF58", "AG58", "AH58", "AI58", "AJ58",
    "W59", "X59", "Y59", "Z59", "AA59", "AB59", "AC59", "AD59", "AE59", "AF59", "AG59", "AH59", "AI59", "AJ59",
    "W60", "X60", "Y60", "Z60", "AA60", "AB60", "AC60", "AD60", "AE60", "AF60", "AG60", "AH60", "AI60", "AJ60",
    "W61", "X61", "Y61", "Z61", "AA61", "AB61", "AC61", "AD61", "AE61", "AF61", "AG61", "AH61", "AI61", "AJ61",
    "W62", "X62", "Y62", "Z62", "AA62", "AB62", "AC62", "AD62", "AE62", "AF62", "AG62", "AH62", "AI62", "AJ62",
    "W63", "X63", "Y63", "Z63", "AA63", "AB63", "AC63", "AD63", "AE63", "AF63", "AG63", "AH63", "AI63", "AJ63",
    "W64", "X64", "Y64", "Z64", "AA64", "AB64", "AC64", "AD64", "AE64", "AF64", "AG64", "AH64", "AI64", "AJ64",
    "W65", "X65", "Y65", "Z65", "AA65", "AB65", "AC65", "AD65", "AE65", "AF65", "AG65", "AH65", "AI65", "AJ65",
    "W66", "X66", "Y66", "Z66", "AA66", "AB66", "AC66", "AD66", "AE66", "AF66", "AG66", "AH66", "AI66", "AJ66",
    "W67", "X67", "Y67", "Z67", "AA67", "AB67", "AC67", "AD67", "AE67", "AF67", "AG67", "AH67", "AI67", "AJ67",
    "W68", "X68", "Y68", "Z68", "AA68", "AB68", "AC68", "AD68", "AE68", "AF68", "AG68", "AH68", "AI68", "AJ68",
    "A67", "B67", "C67", "D67", "E67", "F67", "G67", "H67", "I67", "J67", "K67", "L67", "M67", "N67", "O67", "P67", "Q67", "R67", "S67", "T67", "U67", "V67",
    "A68", "B68", "C68", "D68", "E68", "F68", "G68", "H68", "I68", "J68", "K68", "L68", "M68", "N68", "O68", "P68", "Q68", "R68", "S68", "T68", "U68", "V68",
    "W71", "X71", "Y71", "Z71", "AA71", "AB71", "AC71", "AD71", "AE71", "AF71", "AG71", "AH71", "AI71", "AJ71",
    "W72", "X72", "Y72", "Z72", "AA72", "AB72", "AC72", "AD72", "AE72", "AF72", "AG72", "AH72", "AI72", "AJ72",
    "W73", "X73", "Y73", "Z73", "AA73", "AB73", "AC73", "AD73", "AE73", "AF73", "AG73", "AH73", "AI73", "AJ73",
    "W74", "X74", "Y74", "Z74", "AA74", "AB74", "AC74", "AD74", "AE74", "AF74", "AG74", "AH74", "AI74", "AJ74",
    "W75", "X75", "Y75", "Z75", "AA75", "AB75", "AC75", "AD75", "AE75", "AF75", "AG75", "AH75", "AI75", "AJ75",
    "W76", "X76", "Y76", "Z76", "AA76", "AB76", "AC76", "AD76", "AE76", "AF76", "AG76", "AH76", "AI76", "AJ76",
    "W77", "X77", "Y77", "Z77", "AA77", "AB77", "AC77", "AD77", "AE77", "AF77", "AG77", "AH77", "AI77", "AJ77",
    "W78", "X78", "Y78", "Z78", "AA78", "AB78", "AC78", "AD78", "AE78", "AF78", "AG78", "AH78", "AI78", "AJ78",
    "W79", "X79", "Y79", "Z79", "AA79", "AB79", "AC79", "AD79", "AE79", "AF79", "AG79", "AH79", "AI79", "AJ79",
    "W80", "X80", "Y80", "Z80", "AA80", "AB80", "AC80", "AD80", "AE80", "AF80", "AG80", "AH80", "AI80", "AJ80",
    "W81", "X81", "Y81", "Z81", "AA81", "AB81", "AC81", "AD81", "AE81", "AF81", "AG81", "AH81", "AI81", "AJ81",
    "W82", "X82", "Y82", "Z82", "AA82", "AB82", "AC82", "AD82", "AE82", "AF82", "AG82", "AH82", "AI82", "AJ82",
    "W83", "X83", "Y83", "Z83", "AA83", "AB83", "AC83", "AD83", "AE83", "AF83", "AG83", "AH83", "AI83", "AJ83",
    "W84", "X84", "Y84", "Z84", "AA84", "AB84", "AC84", "AD84", "AE84", "AF84", "AG84", "AH84", "AI84", "AJ84",
    "W85", "X85", "Y85", "Z85", "AA85", "AB85", "AC85", "AD85", "AE85", "AF85", "AG85", "AH85", "AI85", "AJ85",
    "W86", "X86", "Y86", "Z86", "AA86", "AB86", "AC86", "AD86", "AE86", "AF86", "AG86", "AH86", "AI86", "AJ86",
    "W87", "X87", "Y87", "Z87", "AA87", "AB87", "AC87", "AD87", "AE87", "AF87", "AG87", "AH87", "AI87", "AJ87",
    "W88", "X88", "Y88", "Z88", "AA88", "AB88", "AC88", "AD88", "AE88", "AF88", "AG88", "AH88", "AI88", "AJ88",
    "W89", "X89", "Y89", "Z89", "AA89", "AB89", "AC89", "AD89", "AE89", "AF89", "AG89", "AH89", "AI89", "AJ89",
    "W90", "X90", "Y90", "Z90", "AA90", "AB90", "AC90", "AD90", "AE90", "AF90", "AG90", "AH90", "AI90", "AJ90",
    "W91", "X91", "Y91", "Z91", "AA91", "AB91", "AC91", "AD91", "AE91", "AF91", "AG91", "AH91", "AI91", "AJ91",
    "A90", "B90", "C90", "D90", "E90", "F90", "G90", "H90", "I90", "J90", "K90", "L90", "M90", "N90", "O90", "P90", "Q90", "R90", "S90", "T90", "U90", "V90",
    "A91", "B91", "C91", "D91", "E91", "F91", "G91", "H91", "I91", "J91", "K91", "L91", "M91", "N91", "O91", "P91", "Q91", "R91", "S91", "T91", "U91", "V91",
    "H94", "I94", "J94", "K94", "L94", "M94",
    "H95", "I95", "J95", "K95", "L95", "M95",
    "H96", "I96", "J96", "K96", "L96", "M96",
    "H97", "I97", "J97", "K97", "L97", "M97",
    "H98", "I98", "J98", "K98", "L98", "M98",
    "H99", "I99", "J99", "K99", "L99", "M99",
    "H100", "I100", "J100", "K100", "L100", "M100",
    "H101", "I101", "J101", "K101", "L101", "M101",
    "H102", "I102", "J102", "K102", "L102", "M102",
    "H103", "I103", "J103", "K103", "L103", "M103",
    "H104", "I104", "J104", "K104", "L104", "M104",
    "H105", "I105", "J105", "K105", "L105", "M105",
    "H106", "I106", "J106", "K106", "L106", "M106",
    "H107", "I107", "J107", "K107", "L107", "M107",
    "H108", "I108", "J108", "K108", "L108", "M108",
    "H109", "I109", "J109", "K109", "L109", "M109",
    "H110", "I110", "J110", "K110", "L110", "M110",
    "H111", "I111", "J111", "K111", "L111", "M111",
    "H112", "I112", "J112", "K112", "L112", "M112",
    "A111", "B2111", "C111", "D111", "E111", "F111", "G111",
    "A112", "B2112", "C112", "D112", "E112", "F112", "G112",
    "H115", "I115", "J115", "K115", "L115", "M115",
    "H116", "I116", "J116", "K116", "L116", "M116",
    "H117", "I117", "J117", "K117", "L117", "M117",
    "H118", "I118", "J118", "K118", "L118", "M118",
    "H119", "I119", "J119", "K119", "L119", "M119",
    "H120", "I120", "J120", "K120", "L120", "M120",
    "H121", "I121", "J121", "K121", "L121", "M121",
    "H122", "I122", "J122", "K122", "L122", "M122",
    "H123", "I123", "J123", "K123", "L123", "M123",
    "H124", "I124", "J124", "K124", "L124", "M124",
    "H125", "I125", "J125", "K125", "L125", "M125",
    "H126", "I126", "J126", "K126", "L126", "M126",
    "H127", "I127", "J127", "K127", "L127", "M127",
    "H128", "I128", "J128", "K128", "L128", "M128",
    "H129", "I129", "J129", "K129", "L129", "M129",
    "H130", "I130", "J130", "K130", "L130", "M130",
    "H131", "I131", "J131", "K131", "L131", "M131",
    "H132", "I132", "J132", "K132", "L132", "M132",
    "H133", "I133", "J133", "K133", "L133", "M133",
    "H134", "I134", "J134", "K134", "L134", "M134",
    "A133", "B2133", "C133", "D133", "E133", "F133", "G133",
    "A134", "B2134", "C134", "D134", "E134", "F134", "G134",
    "H137", "I137", "J137", "K137", "L137", "M137",
    "H138", "I138", "J138", "K138", "L138", "M138",
    "H139", "I139", "J139", "K139", "L139", "M139",
    "H140", "I140", "J140", "K140", "L140", "M140",
    "H141", "I141", "J141", "K141", "L141", "M141",
    "A140", "B2140", "C140", "D140", "E140", "F140", "G140",
    "A141", "B2141", "C141", "D141", "E141", "F141", "G141",
    "H144", "I144", "J144", "K144", "L144", "M144",
    "H145", "I145", "J145", "K145", "L145", "M145",
    "H146", "I146", "J146", "K146", "L146", "M146",
    "H147", "I147", "J147", "K147", "L147", "M147",
    "H148", "I148", "J148", "K148", "L148", "M148",
    "A147", "B2147", "C147", "D147", "E147", "F147", "G147",
    "A148", "B2148", "C148", "D148", "E148", "F148", "G148",
    "H153", "I153", "J153", "K153", "L153", "M153",
    "H154", "I154", "J154", "K154", "L154", "M154",
    "H155", "I155", "J155", "K155", "L155", "M155",
    "H156", "I156", "J156", "K156", "L156", "M156",
    "H157", "I157", "J157", "K157", "L157", "M157",
    "A156", "B2156", "C156", "D156", "E156", "F156", "G156",
    "A157", "B2157", "C157", "D157", "E157", "F157", "G157",
    "H160", "I160", "J160", "K160", "L160", "M160",
    "H161", "I161", "J161", "K161", "L161", "M161",
    "H162", "I162", "J162", "K162", "L162", "M162",
    "H163", "I163", "J163", "K163", "L163", "M163",
    "H164", "I164", "J164", "K164", "L164", "M164",
    "A163", "B2163", "C163", "D163", "E163", "F163", "G163",
    "A164", "B2164", "C164", "D164", "E164", "F164", "G164",
    "H167", "I167",
    "H168", "I168",
    "H169", "I169",
    "A168", "B2168", "C168", "D168", "E168", "F168", "G168",
    "A169", "B2169", "C169", "D169", "E169", "F169", "G169",
    "W172", "X172", "Y172", "Z172", "AA172", "AB172", "AC172", "AD172", "AE172", "AF172", "AG172", "AH172", "AI172", "AJ172",
    "W173", "X173", "Y173", "Z173", "AA173", "AB173", "AC173", "AD173", "AE173", "AF173", "AG173", "AH173", "AI173", "AJ173",
    "W174", "X174", "Y174", "Z174", "AA174", "AB174", "AC174", "AD174", "AE174", "AF174", "AG174", "AH174", "AI174", "AJ174",
    "W175", "X175", "Y175", "Z175", "AA175", "AB175", "AC175", "AD175", "AE175", "AF175", "AG175", "AH175", "AI175", "AJ175",
    "W176", "X176", "Y176", "Z176", "AA176", "AB176", "AC176", "AD176", "AE176", "AF176", "AG176", "AH176", "AI176", "AJ176",
    "W177", "X177", "Y177", "Z177", "AA177", "AB177", "AC177", "AD177", "AE177", "AF177", "AG177", "AH177", "AI177", "AJ177",
    "A176", "B176", "C176", "D176", "E176", "F176", "G176", "H176", "I176", "J176", "K176", "L176", "M176", "N176", "O176", "P176", "Q176", "R176", "S176", "T176", "U176", "V176",
    "A177", "B177", "C177", "D177", "E177", "F177", "G177", "H177", "I177", "J177", "K177", "L177", "M177", "N177", "O177", "P177", "Q177", "R177", "S177", "T177", "U177", "V177",
]

export const lightPurpleCell = [
    "B6", "B7", "B8", "B9", "B10", "D6", "D7", "D8", "D9", "D10", "E6", "E7", "E8", "E9", "E10", "F6", "F7", "F8", "F9", "F10", "H6", "H7", "H8", "H9", "H10", "I6", "I7", "I8", "I9", "I10", "J6", "J7", "J8", "J9", "J10", "L6", "L7", "L8", "L9", "L10", "M6", "M7", "M8", "M9", "M10", "N6", "N7", "N8", "N9", "N10", "P6", "P7", "P8", "P9", "P10", "Q6", "Q7", "Q8", "Q9", "Q10", "R6", "R7", "R8", "R9", "R10", "T6", "T7", "T8", "T9", "T10", "U6", "U7", "U8", "U9", "U10", "V6", "V7", "V8", "V9", "V10",
    "B16", "B17", "B18", "B19", "B20", "D16", "D17", "D18", "D19", "D20", "E16", "E17", "E18", "E19", "E20", "F16", "F17", "F18", "F19", "F20", "H16", "H17", "H18", "H19", "H20", "I16", "I17", "I18", "I19", "I20", "J16", "J17", "J18", "J19", "J20", "L16", "L17", "L18", "L19", "L20", "M16", "M17", "M18", "M19", "M20", "N16", "N17", "N18", "N19", "N20", "P16", "P17", "P18", "P19", "P20", "Q16", "Q17", "Q18", "Q19", "Q20", "R16", "R17", "R18", "R19", "R20", "T16", "T17", "T18", "T19", "T20", "U16", "U17", "U18", "U19", "U20", "V16", "V17", "V18", "V19", "V20",
    "A26", "A27", "A28", "A29", "A30", "A46", "D46", "E46", "F46", "H46", "I46", "J46", "L46", "M46", "N46", "P46", "Q46", "R46", "T46", "U46", "V46", "F173", "F174", "F175",
    "B173", "B174", "B175", "D173", "D174", "D175", "E173", "E174", "E175", "H173", "H174", "H175", "I173", "I174", "I175", "J173", "J174", "J175", "L173", "L174", "L175", "M173", "M174", "M175", "N173", "N174", "N175", "P173", "P174", "P175", "Q173", "Q174", "Q175", "R173", "R174", "R175", "T173", "T174", "T175", "U173", "U174", "U175", "V173", "V174", "V175",
]

export const lightGreenCell = [
    "B53", "B54", "B55", "B56", "B57", "B58", "B59", "B60", "B61", "B62", "B63", "B64", "B65", "B66",
    "D53", "D54", "D55", "D56", "D57", "D58", "D59", "D60", "D61", "D62", "D63", "D64", "D65", "D66",
    "E53", "E54", "E55", "E56", "E57", "E58", "E59", "E60", "E61", "E62", "E63", "E64", "E65", "E66",
    "F53", "F54", "F55", "F56", "F57", "F58", "F59", "F60", "F61", "F62", "F63", "F64", "F65", "F66",
    "H53", "H54", "H55", "H56", "H57", "H58", "H59", "H60", "H61", "H62", "H63", "H64", "H65", "H66",
    "I53", "I54", "I55", "I56", "I57", "I58", "I59", "I60", "I61", "I62", "I63", "I64", "I65", "I66",
    "J53", "J54", "J55", "J56", "J57", "J58", "J59", "J60", "J61", "J62", "J63", "J64", "J65", "J66",
    "L53", "L54", "L55", "L56", "L57", "L58", "L59", "L60", "L61", "L62", "L63", "L64", "L65", "L66",
    "M53", "M54", "M55", "M56", "M57", "M58", "M59", "M60", "M61", "M62", "M63", "M64", "M65", "M66",
    "N53", "N54", "N55", "N56", "N57", "N58", "N59", "N60", "N61", "N62", "N63", "N64", "N65", "N66",
    "P53", "P54", "P55", "P56", "P57", "P58", "P59", "P60", "P61", "P62", "P63", "P64", "P65", "P66",
    "Q53", "Q54", "Q55", "Q56", "Q57", "Q58", "Q59", "Q60", "Q61", "Q62", "Q63", "Q64", "Q65", "Q66",
    "R53", "R54", "R55", "R56", "R57", "R58", "R59", "R60", "R61", "R62", "R63", "R64", "R65", "R66",
    "T53", "T54", "T55", "T56", "T57", "T58", "T59", "T60", "T61", "T62", "T63", "T64", "T65", "T66",
    "U53", "U54", "U55", "U56", "U57", "U58", "U59", "U60", "U61", "U62", "U63", "U64", "U65", "U66",
    "V53", "V54", "V55", "V56", "V57", "V58", "V59", "V60", "V61", "V62", "V63", "V64", "V65", "V66",
]

export const lightBlueCell = [
    "B72", "B73", "B74", "B75", "B76", "B77", "B78", "B79", "B80", "B81", "B82", "B83", "B84", "B85", "B86", "B87", "B88", "B89",
    "D72", "D73", "D74", "D75", "D76", "D77", "D78", "D79", "D80", "D81", "D82", "D83", "D84", "D85", "D86", "D87", "D88", "D89",
    "E72", "E73", "E74", "E75", "E76", "E77", "E78", "E79", "E80", "E81", "E82", "E83", "E84", "E85", "E86", "E87", "E88", "E89",
    "F72", "F73", "F74", "F75", "F76", "F77", "F78", "F79", "F80", "F81", "F82", "F83", "F84", "F85", "F86", "F87", "F88", "F89",
    "H72", "H73", "H74", "H75", "H76", "H77", "H78", "H79", "H80", "H81", "H82", "H83", "H84", "H85", "H86", "H87", "H88", "H89",
    "I72", "I73", "I74", "I75", "I76", "I77", "I78", "I79", "I80", "I81", "I82", "I83", "I84", "I85", "I86", "I87", "I88", "I89",
    "J72", "J73", "J74", "J75", "J76", "J77", "J78", "J79", "J80", "J81", "J82", "J83", "J84", "J85", "J86", "J87", "J88", "J89",
    "L72", "L73", "L74", "L75", "L76", "L77", "L78", "L79", "L80", "L81", "L82", "L83", "L84", "L85", "L86", "L87", "L88", "L89",
    "M72", "M73", "M74", "M75", "M76", "M77", "M78", "M79", "M80", "M81", "M82", "M83", "M84", "M85", "M86", "M87", "M88", "M89",
    "N72", "N73", "N74", "N75", "N76", "N77", "N78", "N79", "N80", "N81", "N82", "N83", "N84", "N85", "N86", "N87", "N88", "N89",
    "P72", "P73", "P74", "P75", "P76", "P77", "P78", "P79", "P80", "P81", "P82", "P83", "P84", "P85", "P86", "P87", "P88", "P89",
    "Q72", "Q73", "Q74", "Q75", "Q76", "Q77", "Q78", "Q79", "Q80", "Q81", "Q82", "Q83", "Q84", "Q85", "Q86", "Q87", "Q88", "Q89",
    "R72", "R73", "R74", "R75", "R76", "R77", "R78", "R79", "R80", "R81", "R82", "R83", "R84", "R85", "R86", "R87", "R88", "R89",
    "T72", "T73", "T74", "T75", "T76", "T77", "T78", "T79", "T80", "T81", "T82", "T83", "T84", "T85", "T86", "T87", "T88", "T89",
    "U72", "U73", "U74", "U75", "U76", "U77", "U78", "U79", "U80", "U81", "U82", "U83", "U84", "U85", "U86", "U87", "U88", "U89",
    "V72", "V73", "V74", "V75", "V76", "V77", "V78", "V79", "V80", "V81", "V82", "V83", "V84", "V85", "V86", "V87", "V88", "V89",
]

export const borderRightMediumCell = []

export const borderTopBottomMediumCell = [
    "A5", "C5", "D5", "E5", "G5", "H5", "I5", "K5", "L5", "M5", "O5", "P5", "Q5", "S5", "T5", "U5", "W5", "X5", "Y5", "Z5", "AA5", "AC5", "AD5", "AE5", "AF5", "AG5", "AI5",
    "A15", "C15", "D15", "E15", "G15", "H15", "I15", "K15", "L15", "M15", "O15", "P15", "Q15", "S15", "T15", "U15", "W15", "X15", "Y15", "Z15", "AA15", "AC15", "AD15", "AE15", "AF15", "AG15", "AI15",
    "A25", "H25", "I25", "J25", "K25", "L25", "A35", "H35", "I35", "J35", "K35", "L35",
    "A45", "C45", "D45", "E45", "G45", "H45", "I45", "K45", "L45", "M45", "O45", "P45", "Q45", "S45", "T45", "U45", "W45", "X45", "Y45", "Z45", "AA45", "AC45", "AD45", "AE45", "AF45", "AG45",
    "A52", "C52", "D52", "E52", "G52", "H52", "I52", "K52", "L52", "M52", "O52", "P52", "Q52", "S52", "T52", "U52", "W52", "X52", "Y52", "Z52", "AA52", "AC52", "AD52", "AE52", "AF52", "AG52", "AI52",
    "A71", "C71", "D71", "E71", "G71", "H71", "I71", "K71", "L71", "M71", "O71", "P71", "Q71", "S71", "T71", "U71", "W71", "X71", "Y71", "Z71", "AA71", "AC71", "AD71", "AE71", "AF71", "AG71", "AI71",
    "A94", "C94", "D94", "E94", "F94", "I94", "J94", "K94", "L94", "H94", "H115",
    "A115", "C115", "D115", "E115", "F115", "I115", "J115", "K115", "L115",
    "A167", "A172", "C172", "D172", "E172", "G172", "H172", "I172", "K172", "L172", "M172", "O172", "P172", "Q172", "S172", "T172", "U172", "W172", "X172", "Y172", "Z172", "AA172", "AC172", "AD172", "AE172", "AF172", "AG172", "AI172",
]

export const topBottomMediumRightThin = [
    "F5", "J5", "N5", "R5", "F15", "J15", "N15", "R15", "F45", "J45", "N45", "R45",
    "C6", "D6", "E6", "G6", "H6", "I6", "K6", "L6", "M6", "O6", "P6", "Q6", "S6", "T6", "U6", "W6", "Y6", "AC6", "AE6", "AI6",
    "C16", "D16", "E16", "G16", "H16", "I16", "K16", "L16", "M16", "O16", "P16", "Q16", "S16", "T16", "U16", "W16", "Y16", "AC16", "AE16", "AI16",
    "C53", "D53", "E53", "G53", "H53", "I53", "K53", "L53", "M53", "O53", "P53", "Q53", "S53", "T53", "U53", "W53", "Y53", "AC53", "AE53", "AI53",
    "C72", "D72", "E72", "G72", "H72", "I72", "K72", "L72", "M72", "O72", "P72", "Q72", "S72", "T72", "U72", "W72", "Y72", "AC72", "AE72", "AI72",
    "C25", "D25", "E25", "F25", "C35", "D35", "E35", "F35",
    "C26", "D26", "E26", "F26", "C36", "D36", "E36", "F36", "H26", "J26", "H36", "J36",
    "C46", "D46", "E46", "G46", "H46", "I46", "K46", "L46", "M46", "O46", "P46", "Q46", "S46", "T46", "U46", "W46", "AC46",
    "F52", "J52", "N52", "R52", "F71", "J71", "N71", "R71",
    "C95", "D95", "E95", "F95", "C116", "D116", "E116", "F116", "H95", "J95", "H116", "J116",
    "C137", "D137", "E137", "F137", "H137", "I137", "J137",
    "C144", "D144", "E144", "F144", "H144", "I144", "J144",
    "C153", "D153", "E153", "F153", "H153", "I153", "J153",
    "C160", "D160", "E160", "F160", "H160", "I160", "J160",
    "C167", "D167", "E167", "F167", "H167",
    "C173", "D173", "E173", "G173", "H173", "I173", "K173", "L173", "M173", "O173", "P173", "Q173", "S173", "T173", "U173",
    "W173", "Y173", "AC173", "AE173", "AI173"

]

export const borderTopRightBottomMediumCell = [
    "B5", "V5", "AB5", "AH5", "AJ5", "B15", "V15", "AB15", "AH15", "AJ15", "B45", "V45", "AB45", "AH45",
    "A6", "B6", "F6", "J6", "N6", "R6", "V6", "X6", "AA6", "AD6", "AG6", "AJ6",
    "A16", "B16", "F16", "J16", "N16", "R16", "V16", "X16", "AA16", "AD16", "AG16", "AJ16",
    "A53", "B53", "F53", "J53", "N53", "R53", "V53", "X53", "AA53", "AD53", "AG53", "AJ53",
    "A72", "B72", "F72", "J72", "N72", "R72", "V72", "X72", "AA72", "AD72", "AG72", "AJ72",
    "B25", "B35", "G25", "G35", "M25", "M35", "A26", "A36",
    "G26", "G36", "I26", "I36", "L26", "L36", "B94", "B115", "G94", "G115",
    "A46", "B46", "F46", "J46", "N46", "R46", "V46", "X46", "AD46", "M94", "M115",
    "B52", "V52", "AB52", "AH52", "AJ52", "B71", "V71", "AB71", "AH71", "AJ71",
    "A116", "A95", "G95", "G116", "I95", "I116", "L95", "L116",
    "A137", "L137", "G137", "A144", "G144", "L144", "A153", "L153", "G153", "A160", "G160", "L160",
    "B167", "B172", "G167", "I167", "V172", "AB172", "AH172", "AJ172", "A173", "B173",
    "F173", "J173", "N173", "R173", "V173", "X173", "AA173", "AD173", "AG173", "AJ173",
    "F172", "J172", "N172", "R172", "V172",
]

export const borderRightThingBottomMediumCell = [
    "C10", "D10", "E10", "G10", "H10", "I10", "K10", "L10", "M10", "O10", "P10", "Q10", "S10", "T10", "U10", "W10", "AC10", "AI10",
    "C12", "D12", "E12", "G12", "H12", "I12", "K12", "L12", "M12", "O12", "P12", "Q12", "S12", "T12", "U12", "W12", "AC12", "AI12",
    "C20", "D20", "E20", "G20", "H20", "I20", "K20", "L20", "M20", "O20", "P20", "Q20", "S20", "T20", "U20", "W20", "AC20", "AI20",
    "C22", "D22", "E22", "G22", "H22", "I22", "K22", "L22", "M22", "O22", "P22", "Q22", "S22", "T22", "U22", "W22", "AC22", "AI22",
    "Z10", "Z12", "AB10", "AB12", "AF10", "AF12", "AH10", "AH12",
    "Z20", "Z22", "AB20", "AB22", "AF20", "AF22", "AH20", "AH22",
    "C30", "C32", "D30", "D32", "E30", "E32", "F30", "F32",
    "C39", "C41", "D39", "D41", "E39", "E41", "F39", "F41",
    "H30", "H32", "H39", "H41", "K30", "K32", "K39", "K41",
    "C48", "D48", "E48", "F48", "G48", "H48", "I48", "J48", "K48", "L48", "M48", "N48", "O48", "P48", "Q48", "R48", "S48", "T48", "U48", "V48", "W48", "AC48",
    "C66", "C68", "C89", "C91",
    "D66", "D68", "D89", "D91",
    "E66", "E68", "E89", "E91",
    "G66", "G68", "G89", "G91",
    "H66", "H68", "H89", "H91",
    "I66", "I68", "I89", "I91",
    "K66", "K68", "K89", "K91",
    "L66", "L68", "L89", "L91",
    "M66", "M68", "M89", "M91",
    "O66", "O68", "O89", "O91",
    "P66", "P68", "P89", "P91",
    "Q66", "Q68", "Q89", "Q91",
    "S66", "S68", "S89", "S91",
    "T66", "T68", "T89", "T91",
    "U66", "U68", "U89", "U91",
    "W66", "W68", "W89", "W91",
    "AC66", "AC68", "AC89", "AC91",
    "AI66", "AI68", "AI89", "AI91", "C110", "C112", "D110", "D112", "E110", "E112", "F110", "F112", "H110", "H112",
    "Z66", "Z68", "Z89", "Z91", "AF66", "AF68", "AF89", "AF91",
    "C132", "C134", "D132", "D134", "E132", "E134", "F132", "F134", "H132", "H134",
    "K110", "K112", "K132", "K134",
    "C139", "C141", "D139", "D141", "E139", "E141", "F139", "F141", "H139", "H141", "I139", "I141", "K139", "K141",
    "C146", "C148", "D146", "D148", "E146", "E148", "F146", "F148", "H146", "H148", "I146", "I148", "K146", "K148",
    "C155", "C157", "D155", "D157", "E155", "E157", "F155", "F157", "H155", "H157", "I155", "I157", "K155", "K157",
    "C162", "C164", "D162", "D164", "E162", "E164", "F162", "F164", "H162", "H164", "I162", "I164", "K162", "K164",
    "C169", "C175", "C177", "D169", "D175", "D177", "E169", "E175", "E177", "F169", "H169", "H175", "H177",
    "G175", "G177", "H175", "H177", "I175", "I177", "K175", "K177", "L175", "L177", "M175", "M177", "O175", "O177", "P175", "P177", "Q175", "Q177", "S175", "S177", "T175", "T177", "U175", "U177",
    "W175", "W177", "AC175", "AC177", "AI175", "AI177", "Z175", "Z177", "AF175", "AF177",
]

export const borderRightMediumBottomThinCell = [
    "A7", "A8", "A9", "B7", "B8", "B9", "A11", "A17", "A18", "A19", "B17", "B18", "B19", "A21",
    "F7", "F8", "F9", "F11", "J7", "J8", "J9", "J11", "N7", "N8", "N9", "N11", "R7", "R8", "R9", "R11", "V7", "V8", "V9", "V11", "X7", "X8", "X9", "X11", "AD7", "AD8", "AD9", "AD11", "AJ7", "AJ8", "AJ9", "AJ11",
    "F17", "F18", "F19", "F21", "J17", "J18", "J19", "J21", "N17", "N18", "N19", "N21", "R17", "R18", "R19", "R21", "V17", "V18", "V19", "V21", "X17", "X18", "X19", "X21", "AD17", "AD18", "AD19", "AD21", "AJ17", "AJ18", "AJ19", "AJ21",
    "A27", "A28", "A29", "A31", "A37", "A38", "A40", "A47",
    'G27', "G28", "G29", "G31", 'G37', "G38", "G40", "X47", "AD47",
    'I27', "I28", "I29", "I31", 'I37', "I38", "I40",
    "M27", "M28", "M29", "M31", "M37", "M38", "M40",
    "A54", "A55", "A56", "A57", "A58", "A59", "A60", "A61", "A62", "A63", "A64", "A65",
    "B54", "B55", "B56", "B57", "B58", "B59", "B60", "B61", "B62", "B63", "B64", "B65",
    "A73", "A74", "A75", "A76", "A77", "A78", "A79", "A80", "A81", "A82", "A83", "A84", "A85", "A86", "A87", "A88", "A90",
    "B73", "B74", "B75", "B76", "B77", "B78", "B79", "B80", "B81", "B82", "B83", "B84", "B85", "B86", "B87", "B88", "A67",
    "A20", "A22",
    "F53", "F54", "F55", "F56", "F57", "F58", "F59", "F60", "F61", "F62", "F63", "F64", "F65", "F67",
    "J53", "J54", "J55", "J56", "J57", "J58", "J59", "J60", "J61", "J62", "J63", "J64", "J65", "J67",
    "N53", "N54", "N55", "N56", "N57", "N58", "N59", "N60", "N61", "N62", "N63", "N64", "N65", "N67",
    "R53", "R54", "R55", "R56", "R57", "R58", "R59", "R60", "R61", "R62", "R63", "R64", "R65", "R67",
    "V53", "V54", "V55", "V56", "V57", "V58", "V59", "V60", "V61", "V62", "V63", "V64", "V65", "V67",
    "X53", "X54", "X55", "X56", "X57", "X58", "X59", "X60", "X61", "X62", "X63", "X64", "X65", "X67",
    "AD53", "AD54", "AD55", "AD56", "AD57", "AD58", "AD59", "AD60", "AD61", "AD62", "AD63", "AD64", "AD65", "AD67",
    "AJ53", "AJ54", "AJ55", "AJ56", "AJ57", "AJ58", "AJ59", "AJ60", "AJ61", "AJ62", "AJ63", "AJ64", "AJ65", "AJ67",
    "F73", "F74", "F75", "F76", "F77", "F78", "F79", "F80", "F81", "F82", "F83", "F84", "F85", "F86", "F87", "F88", "F90",
    "J73", "J74", "J75", "J76", "J77", "J78", "J79", "J80", "J81", "J82", "J83", "J84", "J85", "J86", "J87", "J88", "J90",
    "N73", "N74", "N75", "N76", "N77", "N78", "N79", "N80", "N81", "N82", "N83", "N84", "N85", "N86", "N87", "N88", "N90",
    "R73", "R74", "R75", "R76", "R77", "R78", "R79", "R80", "R81", "R82", "R83", "R84", "R85", "R86", "R87", "R88", "R90",
    "V73", "V74", "V75", "V76", "V77", "V78", "V79", "V80", "V81", "V82", "V83", "V84", "V85", "V86", "V87", "V88", "V90",
    "X73", "X74", "X75", "X76", "X77", "X78", "X79", "X80", "X81", "X82", "X83", "X84", "X85", "X86", "X87", "X88", "X90",
    "AD73", "AD74", "AD75", "AD76", "AD77", "AD78", "AD79", "AD80", "AD81", "AD82", "AD83", "AD84", "AD85", "AD86", "AD87", "AD88", "AD90",
    "AJ73", "AJ74", "AJ75", "AJ76", "AJ77", "AJ78", "AJ79", "AJ80", "AJ81", "AJ82", "AJ83", "AJ84", "AJ85", "AJ86", "AJ87", "AJ88", "AJ90",
    "AB53", "AB54", "AB55", "AB56", "AB57", "AB58", "AB59", "AB60", "AB61", "AB62", "AB63", "AB64", "AB65", "AB67",
    "AH53", "AH54", "AH55", "AH56", "AH57", "AH58", "AH59", "AH60", "AH61", "AH62", "AH63", "AH64", "AH65", "AH67",
    "AB73", "AB74", "AB75", "AB76", "AB77", "AB78", "AB79", "AB80", "AB81", "AB82", "AB83", "AB84", "AB85", "AB86", "AB87", "AB88", "AB90",
    "AH73", "AH74", "AH75", "AH76", "AH77", "AH78", "AH79", "AH80", "AH81", "AH82", "AH83", "AH84", "AH85", "AH86", "AH87", "AH88", "AH90",
    "A96", "A97", "A98", "A99", "A100", "A101", "A102", "A103", "A104", "A105", "A106", "A107", "A108", "A109", "A111", "A133",
    "A117", "A118", "A119", "A120", "A121", "A122", "A123", "A124", "A125", "A126", "A127", "A128", "A129", "A130", "A131",
    "G96", "G97", "G98", "G99", "G100", "G101", "G102", "G103", "G104", "G105", "G106", "G107", "G108", "G109", "G111",
    "I96", "I97", "I98", "I99", "I100", "I101", "I102", "I103", "I104", "I105", "I106", "I107", "I108", "I109", "I111",
    "M96", "M97", "M98", "M99", "M100", "M101", "M102", "M103", "M104", "M105", "M106", "M107", "M108", "M109", "M111",
    "G117", "G118", "G119", "G120", "G121", "G122", "G123", "G124", "G125", "G126", "G127", "G128", "G129", "G130", "G131", "G133",
    "I117", "I118", "I119", "I120", "I121", "I122", "I123", "I124", "I125", "I126", "I127", "I128", "I129", "I130", "I131", "I133",
    "M117", "M118", "M119", "M120", "M121", "M122", "M123", "M124", "M125", "M126", "M127", "M128", "M129", "M130", "M131", "M133",
    "A138", "A140", "G138", "G140", "M138", "M140",
    "A145", "A147", "G145", "G147", "M145", "M147",
    "A154", "A156", "G154", "G156", "M154", "M156",
    "A161", "A163", "G161", "G163", "M161", "M163",
    "A168", "A176", "G168", "I168", "A174", "B174",
    "F174", "F176", "J174", "J176", "N174", "N176", "R174", "R176", "V174", "V176", "X174", "X176", "AB174", "AB176", "AD174", "AD176", "AH174", "AH176", "AJ174", "AJ176",
]

export const borderBottomMediumCell = [
    "Y10", "Y12", "AA10", "AA12", "AE10", "AE12", "AG10", "AG12",
    "Y20", "Y22", "AA20", "AA22", "AE20", "AE22", "AG20", "AG22",
    "J30", "J32", "L30", "L32", "J39", "J41", "L39", "L41",
    "Y66", "Y68", "Y89", "Y91", "AE66", "AE68", "AE89", "AE91",
    "AA66", "AA68", "AA89", "AA91", "AG66", "AG68", "AG89", "AG91",
    "J110", "J112", "L110", "L112", "J132", "J134", "L132", "L134",
    "J139", "J141", "L139", "L141",
    "J146", "J148", "L146", "L148",
    "J155", "J157", "L155", "L157",
    "J162", "J164", "L162", "L164",
    "Y175", "Y177", "AA175", "AA177", "AE175", "AE177", "AG175", "AG177",
]

export const borderBottomThinCell = [
    "Y7", "Y8", "Y9", "Y11", "AA7", "AA8", "AA9", "AA11", "AE7", "AE8", "AE9", "AE11", "AG7", "AG8", "AG9", "AG11",
    "Y17", "Y18", "Y19", "Y21", "AA17", "AA18", "AA19", "AA21", "AE17", "AE18", "AE19", "AE21", "AG17", "AG18", "AG19", "AG21",
    "J27", "J28", "J29", "J31", "L27", "L28", "L29", "L31",
    "J37", "J38", "J40", "L37", "L38", "L40",
    "Y53", "Y54", "Y55", "Y56", "Y57", "Y58", "Y59", "Y60", "Y61", "Y62", "Y63", "Y64", "Y65", "Y67",
    "AA53", "AA54", "AA55", "AA56", "AA57", "AA58", "AA59", "AA60", "AA61", "AA62", "AA63", "AA64", "AA65", "AA67",
    "Y73", "Y74", "Y75", "Y76", "Y77", "Y78", "Y79", "Y80", "Y81", "Y82", "Y83", "Y84", "Y85", "Y86", "Y87", "Y88", "Y90",
    "AA73", "AA74", "AA75", "AA76", "AA77", "AA78", "AA79", "AA80", "AA81", "AA82", "AA83", "AA84", "AA85", "AA86", "AA87", "AA88", "AA90",
    "AE53", "AE54", "AE55", "AE56", "AE57", "AE58", "AE59", "AE60", "AE61", "AE62", "AE63", "AE64", "AE65", "AE67",
    "AG53", "AG54", "AG55", "AG56", "AG57", "AG58", "AG59", "AG60", "AG61", "AG62", "AG63", "AG64", "AG65", "AG67",
    "AE73", "AE74", "AE75", "AE76", "AE77", "AE78", "AE79", "AE80", "AE81", "AE82", "AE83", "AE84", "AE85", "AE86", "AE87", "AE88", "AE90",
    "AG73", "AG74", "AG75", "AG76", "AG77", "AG78", "AG79", "AG80", "AG81", "AG82", "AG83", "AG84", "AG85", "AG86", "AG87", "AG88", "AG90",
    "J117", "J118", "J119", "J120", "J121", "J122", "J123", "J124", "J125", "J126", "J127", "J128", "J129", "J130", "J131", "J133",
    "L117", "L118", "L119", "L120", "L121", "L122", "L123", "L124", "L125", "L126", "L127", "L128", "L129", "L130", "L131", "L133",
    "J96", "J97", "J98", "J99", "J100", "J101", "J102", "J103", "J104", "J105", "J106", "J107", "J108", "J109", "J111",
    "L96", "L97", "L98", "L99", "L100", "L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108", "L109", "L111",
    "J138", "J140", "L138", "L140",
    "J145", "J147", "L145", "L147",
    "J154", "J156", "L154", "L156",
    "J161", "J163", "L161", "L163",
    "Y174", "Y176", "AA174", "AA176", "AE174", "AE176", "AG174", "AG176",
]

export const borderBottomRightMediumCell = [
    "A10", "A12", "B10", "A20", "A22", "B20", "A30", "A32", "A39", "A41", "A48", "AB89", "AB91",
    "F10", "F12", "J10", "J12", "N10", "N12", "R10", "R12", "V10", "V12", "X10", "X12", "AD10", "AD12", "AJ10", "AJ12",
    "F20", "F22", "J20", "J22", "N20", "N22", "R20", "R22", "V20", "V22", "X20", "X22", "AD20", "AD22", "AJ20", "AJ22",
    "G30", "G32", "G39", "G41", "I30", "I32", "I39", "I41", "M30", "M32", "M39", "M41", "X48", "AD48", "AB66", "AB68",
    "A66", "A68", "B66", "A89", "B89", "A91", "AH66", "AH68", "AH89", "AH91",
    "F66", "F68", "F89", "F91",
    "J66", "J68", "J89", "J91",
    "N66", "N68", "N89", "N91",
    "R66", "R68", "R89", "R91",
    "V66", "V68", "V89", "V91",
    "X66", "X68", "X89", "X91",
    "AD66", "AD68", "AD89", "AD91",
    "AJ66", "AJ68", "AJ89", "AJ91",
    "A110", "A112", "G110", "G112", "I110", "I112", "M110", "M112",
    "A132", "A134", "G132", "G134", "I132", "I134", "M132", "M134",
    "A139", "A141", "A146", "A148", "A155", "A157", "A162", "A164",
    "G139", "G141", "G146", "G148", "G155", "G157", "G162", "G164",
    "M139", "M141", "M146", "M148", "M155", "M157", "M162", "M164",
    "A169", "A177", "G169", "I169", "A175", "B175",
    "F175", "F177", "J175", "J177", "N175", "N177", "R175", "R177", "V175", "V177",
    "X175", "X177", "AB175", "AB177", "AD175", "AD177", "AH175", "AH177", "AJ175", "AJ177",
]

export const borderBottomRightThin = [
    "C7", "C8", "C9", "C11", "D7", "D8", "D9", "D11", "E7", "E8", "E9", "E11", "G7", "G8", "G9", "G11", "H7", "H8", "H9", "H11", "I7", "I8", "I9", "I11", "K7", "K8", "K9", "K11", "L7", "L8", "L9", "L11", "M7", "M8", "M9", "M11", "O7", "O8", "O9", "O11", "P7", "P8", "P9", "P11", "Q7", "Q8", "Q9", "Q11", "S7", "S8", "S9", "S11", "T7", "T8", "T9", "T11", "U7", "U8", "U9", "U11", "W7", "W8", "W9", "W11", "AC7", "AC8", "AC9", "AC11", "AI7", "AI8", "AI9", "AI11",
    "C17", "C18", "C19", "C21", "D17", "D18", "D19", "D21", "E17", "E18", "E19", "E21", "G17", "G18", "G19", "G21", "H17", "H18", "H19", "H21", "I17", "I18", "I19", "I21", "K17", "K18", "K19", "K21", "L17", "L18", "L19", "L21", "M17", "M18", "M19", "M21", "O17", "O18", "O19", "O21", "P17", "P18", "P19", "P21", "Q17", "Q18", "Q19", "Q21", "S17", "S18", "S19", "S21", "T17", "T18", "T19", "T21", "U17", "U18", "U19", "U21", "W17", "W18", "W19", "W21", "AC17", "AC18", "AC19", "AC21", "AI17", "AI18", "AI19", "AI21",
    "Z7", "Z8", "Z9", "Z11", "AB7", "AB8", "AB9", "AB11", "AF7", "AF8", "AF9", "AF11", "AH7", "AH8", "AH9", "AH11",
    "Z17", "Z18", "Z19", "Z21", "AB17", "AB18", "AB19", "AB21", "AF17", "AF18", "AF19", "AF21", "AH17", "AH18", "AH19", "AH21",
    "C27", "C28", "C29", 'C31', "D27", "D28", "D29", "D31", "E27", "E28", "E29", "E31", "F27", "F28", "F29", "F31",
    "C37", "C38", 'C40', "D37", "D38", "D40", "E37", "E38", "E40", "F37", "F38", "F40",
    "H27", "H28", "H29", "H31", "H37", "H38", "H40", "K27", "K28", "K29", "K31", "K37", "K38", "K40",
    "C47", "D47", "E47", "F47", "G47", "H47", "I47", "J47", "K47", "L47", "M47", "N47", "O47", "P47", "Q47", "R47", "S47", "T47", "U47", "V47", "W47", "AC47",
    "C53", "C54", "C55", "C56", "C57", "C58", "C59", "C60", "C61", "C62", "C63", "C64", "C65", "C67",
    "D53", "D54", "D55", "D56", "D57", "D58", "D59", "D60", "D61", "D62", "D63", "D64", "D65", "D67",
    "E53", "E54", "E55", "E56", "E57", "E58", "E59", "E60", "E61", "E62", "E63", "E64", "E65", "E67",
    "G53", "G54", "G55", "G56", "G57", "G58", "G59", "G60", "G61", "G62", "G63", "G64", "G65", "G67",
    "H53", "H54", "H55", "H56", "H57", "H58", "H59", "H60", "H61", "H62", "H63", "H64", "H65", "H67",
    "I53", "I54", "I55", "I56", "I57", "I58", "I59", "I60", "I61", "I62", "I63", "I64", "I65", "I67",
    "K53", "K54", "K55", "K56", "K57", "K58", "K59", "K60", "K61", "K62", "K63", "K64", "K65", "K67",
    "L53", "L54", "L55", "L56", "L57", "L58", "L59", "L60", "L61", "L62", "L63", "L64", "L65", "L67",
    "M53", "M54", "M55", "M56", "M57", "M58", "M59", "M60", "M61", "M62", "M63", "M64", "M65", "M67",
    "O53", "O54", "O55", "O56", "O57", "O58", "O59", "O60", "O61", "O62", "O63", "O64", "O65", "O67",
    "P53", "P54", "P55", "P56", "P57", "P58", "P59", "P60", "P61", "P62", "P63", "P64", "P65", "P67",
    "Q53", "Q54", "Q55", "Q56", "Q57", "Q58", "Q59", "Q60", "Q61", "Q62", "Q63", "Q64", "Q65", "Q67",
    "S53", "S54", "S55", "S56", "S57", "S58", "S59", "S60", "S61", "S62", "S63", "S64", "S65", "S67",
    "T53", "T54", "T55", "T56", "T57", "T58", "T59", "T60", "T61", "T62", "T63", "T64", "T65", "T67",
    "U53", "U54", "U55", "U56", "U57", "U58", "U59", "U60", "U61", "U62", "U63", "U64", "U65", "U67",
    "W53", "W54", "W55", "W56", "W57", "W58", "W59", "W60", "W61", "W62", "W63", "W64", "W65", "W67",
    "AC53", "AC54", "AC55", "AC56", "AC57", "AC58", "AC59", "AC60", "AC61", "AC62", "AC63", "AC64", "AC65", "AC67",
    "AI53", "AI54", "AI55", "AI56", "AI57", "AI58", "AI59", "AI60", "AI61", "AI62", "AI63", "AI64", "AI65", "AI67",
    "C73", "C74", "C75", "C76", "C77", "C78", "C79", "C80", "C81", "C82", "C83", "C84", "C85", "C86", "C87", "C88", "C90",
    "D73", "D74", "D75", "D76", "D77", "D78", "D79", "D80", "D81", "D82", "D83", "D84", "D85", "D86", "D87", "D88", "D90",
    "E73", "E74", "E75", "E76", "E77", "E78", "E79", "E80", "E81", "E82", "E83", "E84", "E85", "E86", "E87", "E88", "E90",
    "G73", "G74", "G75", "G76", "G77", "G78", "G79", "G80", "G81", "G82", "G83", "G84", "G85", "G86", "G87", "G88", "G90",
    "H73", "H74", "H75", "H76", "H77", "H78", "H79", "H80", "H81", "H82", "H83", "H84", "H85", "H86", "H87", "H88", "H90",
    "I73", "I74", "I75", "I76", "I77", "I78", "I79", "I80", "I81", "I82", "I83", "I84", "I85", "I86", "I87", "I88", "I90",
    "K73", "K74", "K75", "K76", "K77", "K78", "K79", "K80", "K81", "K82", "K83", "K84", "K85", "K86", "K87", "K88", "K90",
    "L73", "L74", "L75", "L76", "L77", "L78", "L79", "L80", "L81", "L82", "L83", "L84", "L85", "L86", "L87", "L88", "L90",
    "M73", "M74", "M75", "M76", "M77", "M78", "M79", "M80", "M81", "M82", "M83", "M84", "M85", "M86", "M87", "M88", "M90",
    "O73", "O74", "O75", "O76", "O77", "O78", "O79", "O80", "O81", "O82", "O83", "O84", "O85", "O86", "O87", "O88", "O90",
    "P73", "P74", "P75", "P76", "P77", "P78", "P79", "P80", "P81", "P82", "P83", "P84", "P85", "P86", "P87", "P88", "P90",
    "Q73", "Q74", "Q75", "Q76", "Q77", "Q78", "Q79", "Q80", "Q81", "Q82", "Q83", "Q84", "Q85", "Q86", "Q87", "Q88", "Q90",
    "S73", "S74", "S75", "S76", "S77", "S78", "S79", "S80", "S81", "S82", "S83", "S84", "S85", "S86", "S87", "S88", "S90",
    "T73", "T74", "T75", "T76", "T77", "T78", "T79", "T80", "T81", "T82", "T83", "T84", "T85", "T86", "T87", "T88", "T90",
    "U73", "U74", "U75", "U76", "U77", "U78", "U79", "U80", "U81", "U82", "U83", "U84", "U85", "U86", "U87", "U88", "U90",
    "W73", "W74", "W75", "W76", "W77", "W78", "W79", "W80", "W81", "W82", "W83", "W84", "W85", "W86", "W87", "W88", "W90",
    "AC73", "AC74", "AC75", "AC76", "AC77", "AC78", "AC79", "AC80", "AC81", "AC82", "AC83", "AC84", "AC85", "AC86", "AC87", "AC88", "AC90",
    "AI73", "AI74", "AI75", "AI76", "AI77", "AI78", "AI79", "AI80", "AI81", "AI82", "AI83", "AI84", "AI85", "AI86", "AI87", "AI88", "AI90",
    "Z73", "Z74", "Z75", "Z76", "Z77", "Z78", "Z79", "Z80", "Z81", "Z82", "Z83", "Z84", "Z85", "Z86", "Z87", "Z88", "Z90",
    "AF73", "AF74", "AF75", "AF76", "AF77", "AF78", "AF79", "AF80", "AF81", "AF82", "AF83", "AF84", "AF85", "AF86", "AF87", "AF88", "AF90",
    "Z53", "Z54", "Z55", "Z56", "Z57", "Z58", "Z59", "Z60", "Z61", "Z62", "Z63", "Z64", "Z65", "Z67",
    "AF53", "AF54", "AF55", "AF56", "AF57", "AF58", "AF59", "AF60", "AF61", "AF62", "AF63", "AF64", "AF65", "AF67",
    "H53", "H54", "H55", "H56", "H57", "H58", "H59", "H60", "H61", "H62", "H63", "H64", "H65", "H67",
    "C96", "C97", "C98", "C99", "C100", "C101", "C102", "C103", "C104", "C105", "C106", "C107", "C108", "C109", "C111",
    "D96", "D97", "D98", "D99", "D100", "D101", "D102", "D103", "D104", "D105", "D106", "D107", "D108", "D109", "D111",
    "E96", "E97", "E98", "E99", "E100", "E101", "E102", "E103", "E104", "E105", "E106", "E107", "E108", "E109", "E111",
    "F96", "F97", "F98", "F99", "F100", "F101", "F102", "F103", "F104", "F105", "F106", "F107", "F108", "F109", "F111",
    "H96", "H97", "H98", "H99", "H100", "H101", "H102", "H103", "H104", "H105", "H106", "H107", "H108", "H109", "H111",
    "C117", "C118", "C119", "C120", "C121", "C122", "C123", "C124", "C125", "C126", "C127", "C128", "C129", "C130", "C131", "C133",
    "D117", "D118", "D119", "D120", "D121", "D122", "D123", "D124", "D125", "D126", "D127", "D128", "D129", "D130", "D131", "D133",
    "E117", "E118", "E119", "E120", "E121", "E122", "E123", "E124", "E125", "E126", "E127", "E128", "E129", "E130", "E131", "E133",
    "F117", "F118", "F119", "F120", "F121", "F122", "F123", "F124", "F125", "F126", "F127", "F128", "F129", "F130", "F131", "F133",
    "H117", "H118", "H119", "H120", "H121", "H122", "H123", "H124", "H125", "H126", "H127", "H128", "H129", "H130", "H131", "H133",
    "K96", "K97", "K98", "K99", "K100", "K101", "K102", "K103", "K104", "K105", "K106", "K107", "K108", "K109", "K111",
    "K117", "K118", "K119", "K120", "K121", "K122", "K123", "K124", "K125", "K126", "K127", "K128", "K129", "K130", "K131", "K133",
    "C138", "C140", "D138", "D140", "E138", "E140", "F138", "F140", "H138", "H140", "I138", "I140", "K138", "K140",
    "C145", "C147", "D145", "D147", "E145", "E147", "F145", "F147", "H145", "H147", "I145", "I147", "K145", "K147",
    "C154", "C156", "D154", "D156", "E154", "E156", "F154", "F156", "H154", "H156", "I154", "I156", "K154", "K156",
    "C161", "C163", "D161", "D163", "E161", "E163", "F161", "F163", "H161", "H163", "I161", "I163", "K161", "K163",
    "C168", "C174", "C176", "D168", "D174", "D176", "E168", "E174", "E176", "F168", "H168", "G174", "G176", "H174", "H176", "I174", "I176", "K174", "K176", "L174", "L176", "M174", "M176",
    "O174", "O176", "P174", "P176", "Q174", "Q176", "S174", "S176", "T174", "T176", "U174", "U176", "W174", "W176", "AC174", "AC176", "AI174", "AI176",
    "Z174", "Z176", "AF174", "AF176",
]