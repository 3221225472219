import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import SearchSelect from "react-select";
import { api } from "../../../common/api";
import { toast } from "react-toastify";
import moment from "moment";
import { Dialog } from "../../../common/modal";
interface Params {
  id?: string;
}

const SetSchedule: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(document.location.search);
  const siteID = searchParams.get("siteId");
  const isSiteId = siteID ? `?siteId=${siteID}` : "";
  const params: Params = useParams();
  const [stateEvent, setStateEvent] = useState<any>({
    calendarEvents: [],
  });
  const [siteId, setSiteId] = useState<string>("");
  const [siteName, setSiteName] = useState<string>("");
  const [siteEntities, setSiteEntities] = useState<any>([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [useTo, setUseTo] = useState<string>("");
  const [singlePathData, setSinglePathData] = useState<any>();

  const [isOpenAddPathModal, setIsOpenAddPathModal] = useState<boolean>(false);
  const [carList, setCarList] = useState<any>([]);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // FETCH SITES
  const fetchSiteEntities = async () => {
    try {
      const { data } = await api({
        url: `/site/list/?page=1&size=1000&isActive=true&sort={"name":"1"}`,
        method: "get",
      });
      const entities = data.result.data.siteData.map((x) => {
        return {
          label: x?.name,
          value: x?._id,
        };
      });

      if (!siteID) {
        const findManuLife = entities.find((x: any) => x.label === "ManuLife");
        setSelectedSite(findManuLife ? findManuLife?.value : "");
      } else {
        const findManuLife = entities.find((x: any) => x.value === siteID);
        setSelectedSite(findManuLife ? findManuLife?.value : "");
      }

      setSiteEntities(entities);
    } catch (err) {}
  };

  useEffect(() => {
    getTemplateData(); //
    if (siteId) {
      getCarList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, location.pathname]);

  useEffect(() => {
    fetchSiteEntities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTemplateDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const getTemplateData = async () => {
    try {
      const { data }: any = await api({
        url: `/pre-schedule/details/${params.id}`,
        method: "get",
      });
      let item: any = data.result.data;
      setSiteId(item.siteId._id);
      setSiteName(item.siteId.name);

      setUseTo(item?.dates.join(", "));
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getTemplateDetail = async () => {
    try {
      const { data }: any = await api({
        url: `/path/pd/list/${params.id}`,
        method: "get",
      });
      let item: any = data.result.data.paths;
      const eventList: any = [];
      for (const data of item) {
        const eventObject = {
          resourceId: data.carId._id,
          title: data.title ? data.title : ``,
          start:
            data.location && data.location.length
              ? moment(data.location[0].start, "HH:mm").toISOString()
              : ``,
          end:
            data.location && data.location.length
              ? moment(
                  data.location[data.location.length - 2].end,
                  "HH:mm"
                ).toISOString()
              : ``,
          backgroundColor: "#B78989",
          borderColor: "#B78989",
          extendedProps: {
            // 'defId': data.template_details.template.defId,
            // 'instanceId': info.event._instance?.instanceId,
            backgroundColor: "#B78989",
            borderColor: "#B78989",
            pathId: data._id,
            carData: data.carId,
          },
        };
        eventList.push(eventObject);
      }
      setStateEvent((state) => {
        return {
          ...state,
          calendarEvents: eventList,
        };
      });
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const handleEventDrop = (info) => {
    // const resourceMatch = info.event._def.resourceIds![0] === info.oldEvent._def.resourceIds![0];
  };

  const handleEventClick = (info) => {
    setIsOpenAddPathModal(true);
    setSinglePathData(info.event);
  };

  const getResources = () => {
    return carList.map((selectedCar) => {
      return { id: selectedCar._id, title: selectedCar.name };
    });
  };

  const openAddPathModal = () => {
    setIsOpenAddPathModal(true);
    setSinglePathData(undefined);
  };

  const modalSaveAndRemoveClick = () => {
    setIsOpenAddPathModal(false);
    getTemplateDetail();
  };

  const getCarList = async () => {
    try {
      const { data }: any = await api({
        url: `/car/site/${siteId}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const carListResponse = data.result.data.carData;
        setCarList(carListResponse);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <h5>Site: {siteName}</h5>
          <h6>Applied to: {useTo}</h6>
        </div>
        <div className="col-6 d-flex flex-wrap align-items-end justify-content-end">
          <button
            type="button"
            className="btn button-yellow mr-2 mx-sm-2 m-2 text-white"
            onClick={openAddPathModal}
          >
            <span className="font-weight-bold">+</span> Add Path
          </button>
          <Link
            className="btn btn-primary mx-sm-2 m-2"
            to={`/pre-schedule${isSiteId}`}
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FullCalendar
            droppable={true}
            scrollTime={moment()
              .subtract(5, "minutes")
              .format("HH:mm:ss")}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            timeZone="local"
            plugins={[resourceTimeGridPlugin, interactionPlugin]}
            initialView="resourceTimeGridDay"
            allDaySlot={false}
            headerToolbar={{
              left: "",
              center: "",
              right: "",
            }}
            eventDurationEditable={false}
            eventOverlap={false}
            slotDuration={"00:05:00"}
            eventDrop={(info) => handleEventDrop(info)}
            eventClick={(info) => handleEventClick(info)}
            resources={getResources()}
            nowIndicator={true}
            events={stateEvent.calendarEvents}
          />
        </div>
      </div>
      {isOpenAddPathModal && (
        <Dialog
          name="edit"
          show={isOpenAddPathModal}
          onHide={() => setIsOpenAddPathModal(false)}
          saveAndRemoveClick={() => modalSaveAndRemoveClick()}
          action=""
          headerText="Add"
          actionText="Save"
          actionStyle="success"
          showNormalDriverField
          // showBackupDriverField
          disableNormalDriverField={false}
          dangerActionText="Remove"
          dangerActionStyle="danger"
          siteId={siteId}
          templateId={params.id}
          pathData={singlePathData}
          isPreScheduleDetails={true}
        />
      )}
    </>
  );
};

export default SetSchedule;
