import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import { Switch } from "@material-ui/core";
import SearchSelect from "react-select";
import CloseRounded from "@material-ui/icons/CloseRounded";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledcEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import juice from "juice";
import { styles } from "../../ckStyle";

interface Values {
  serviceName: string;
  serviceName_chi: string;
  minSeats: any;
  maxSeats: any;
  details: string;
  details_chi: string;
  image: any;
  tags: any;
  isActive: boolean;
}
interface Params {
  id?: string;
}

const AddCompanyService = () => {
  const params: Params = useParams();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const mimeType: string[] = ["image/png", "image/jpeg"];
  const [latestReportData, setLatestReportData] = useState<any>([]);
  const [serviceTags, setServiceTags] = useState<any>([]);
  const [selectedImage, setSelectedImage] = useState<any>([]);
  const history = useHistory();

  const getServiceTagEntities = async () => {
    try {
      const { data }: any = await api({
        url: `/service/tag/list`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setServiceTags(
          data.result.data.serviceTagData.filter((tag: any) => tag.isActive)
        );
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const removeImage = (src: any) => {
    let imageList =
      formik.values.image &&
      formik.values.image.filter((filterImg: any) => filterImg !== src);
    setSelectedImage(imageList);
  };

  useEffect(() => {
    if (selectedImage) {
      formik.setFieldValue("image", selectedImage);
    }
    // eslint-disable-next-line
  }, [selectedImage]);

  const uploadImage = async () => {
    if (inputFile.current?.files) {
      for (const element of inputFile.current.files) {
        if (mimeType.includes(element.type)) {
          const formData = new FormData();
          formData.append("file", element);
          try {
            const { data }: any = await api({
              url: "/file/file-upload",
              method: "post",
              body: formData,
            });
            if (data.serverResponse.isError) {
              formik.setFieldError("image", "image is required");
              toast.error(data.serverResponse.message);
            } else {
              selectedImage.push(data.data.fileUrl);
              formik.setFieldValue("image", selectedImage);
              toast.success("image uploaded");
            }
          } catch (err) {
            formik.setFieldError("image", "image is required");
            toast.error("image not uploaded, please try again");
          }
        }
      }
    }
  };

  const addCompanyService = async (values: Values) => {
    let body = {
      serviceName: values.serviceName,
      serviceName_chi: values.serviceName_chi,
      // minSeats: values.minSeats,
      // maxSeats: values.maxSeats,
      seats: `${values.minSeats} - ${values.maxSeats}`,
      details: values.details,
      details_chi: values.details_chi,
      image: values.image,
      serviceTag: values.tags.map((x: any) => x._id),
      isActive: values.isActive,
    };

    try {
      const { data }: any = await api({
        url: params?.id
          ? `company/service/update/${params?.id}`
          : `company/service/add`,
        method: params?.id ? "put" : "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(
          params?.id ? "Data Updated Successfully" : "Data added successfully"
        );
        history.push("/companyService/list");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return uploadAdapter(loader);
    };
  }

  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file: any) => {
            const formData = new FormData();
            formData.append("file", file);
            // const fileSize = file;
            api({
              url: "/file/file-upload",
              method: "post",
              body: formData,
            })
              .then((res) => {
                resolve({
                  default: res.data.data.fileUrl,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  const seatsForEdit = latestReportData?.seats?.split(" - ");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceName: params.id
        ? latestReportData && latestReportData?.serviceName
        : "",
      serviceName_chi: params.id
        ? latestReportData && latestReportData?.serviceName_chi
        : "",
      minSeats: params.id ? seatsForEdit && (seatsForEdit[0] || 0) : "",
      maxSeats: params.id ? seatsForEdit && (seatsForEdit[1] || 0) : "",
      details: params.id
        ? latestReportData && (latestReportData?.details as string)
        : "",
      details_chi: params.id
        ? latestReportData && (latestReportData?.details_chi as string)
        : "",
      tags: params.id ? latestReportData && latestReportData?.serviceTag : [],
      image: params.id ? latestReportData && latestReportData?.image : [],
      isActive: params.id
        ? latestReportData && latestReportData?.isActive
        : false,
    },
    validationSchema: Yup.object().shape({
      serviceName: Yup.string().required("Service Name (English) is required"),
      serviceName_chi: Yup.string().required(
        "Service Name (Chinese) is required"
      ),
      minSeats: Yup.number().required("Min Seats is required"),
      maxSeats: Yup.number().required("Max Seats is required"),
      details: Yup.string().required("Details (English)  is required"),
      details_chi: Yup.string().required("Details (Chinese) is required"),
      tags: Yup.array()
        .min(1, "Pick at least 1 item")
        .required("Service Tag is required"),
      image: Yup.array()
        .min(1, "Please select at least one image")
        .required("Image is required"),
    }),
    onSubmit: (values: Values) => {
      addCompanyService({
        ...values,
        details: juice.inlineContent(values.details, styles),
        details_chi: juice.inlineContent(values.details_chi, styles),
        minSeats: Number(values.minSeats),
        maxSeats: Number(values.maxSeats),
      });
    },
  });

  const dropHandler = (ev: any) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          let files = ev.dataTransfer.items[i].getAsFile();
          if (files) {
            try {
              if (mimeType.includes(files.type)) {
                const formData = new FormData();
                formData.append("file", files);
                api({
                  url: "/file/file-upload",
                  method: "post",
                  body: formData,
                }).then((response: any) => {
                  if (response.data.serverResponse.isError) {
                    formik.setFieldError("image", "image is required");
                    toast.error(response.data.serverResponse.message);
                  } else {
                    selectedImage.push(response.data.data.fileUrl);
                    formik.setFieldValue("image", selectedImage);
                    toast.success("image uploaded");
                  }
                });
              } else {
                toast.error("Please select image file type only");
              }
            } catch (e) {
              toast.error("image not uploaded , please try again");
            }
          } else {
            toast.error("File size limit not more than 200kb");
          }
        }
      }
    }
  };

  const getLatestReportById = async () => {
    try {
      const { data }: any = await api({
        url: `/company/service/details/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: any = data.result.data;
        setLatestReportData({
          ...item,
          isActive: Boolean(item.isActive),
        });
        setSelectedImage(item?.image ? item?.image : []);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getServiceTagEntities();
    params.id && getLatestReportById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagOptions =
    serviceTags?.map((tag: any) => ({
      label: `${tag?.name_chi} (${tag.name})`,
      value: tag,
    })) || [];

  const dragOverHandler = (ev: any) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const onUploadFileButtonClick = () => {
    const fileUploadElement: any = document.getElementById("upload-image");
    if (fileUploadElement) {
      fileUploadElement.click();
    }
  };

  const DetailsHandleChange = (e: any, editor: any) => {
    const datas = editor.getData();
    let neData = datas;

    formik.setFieldValue("details", neData);
  };

  const DetailsChineseHandleChange = (e: any, editor: any) => {
    const datas = editor.getData();
    let neData = datas;

    formik.setFieldValue("details_chi", neData);
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={
            Boolean(params.id)
              ? "Company Service Details"
              : "Add Company Service"
          }
        ></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Service Name (Chinese)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="serviceName_chi"
                  name="serviceName_chi"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.serviceName_chi || ""}
                />
                {formik.touched.serviceName_chi &&
                formik.errors.serviceName_chi ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.serviceName_chi}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Service Name (English)
            </div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="serviceName"
                  name="serviceName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.serviceName || ""}
                />
                {formik.touched.serviceName && formik.errors.serviceName ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.serviceName}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Number of Seats
            </div>
            <div className="col-4 d-flex">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="minSeats"
                  name="minSeats"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.minSeats || ""}
                  style={{ width: 140 }}
                />
                {formik.touched.minSeats && formik.errors.minSeats ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.minSeats}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <div style={{ padding: "9px" }}>-</div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="maxSeats"
                  name="maxSeats"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.maxSeats || ""}
                  style={{ width: 140 }}
                />
                {formik.touched.maxSeats && formik.errors.maxSeats ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.maxSeats}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* PHOTO START */}

          <div className="row mt-4">
            <div className="col-4 col-sm-2 mb-4 mb-sm-0  ws-nowrap ">
              Upload Photo (Multiple)
            </div>
            <div className="col-8">
              <div className="position-relative fit-content w-100">
                <div
                  style={{
                    padding: "2rem",
                    textAlign: "center",
                    background: "#FFF",
                    borderRadius: "0.5rem",
                    borderStyle: "dashed",
                  }}
                  onDrop={(e) => dropHandler(e)}
                  onDragOver={(e) => dragOverHandler(e)}
                  onClick={() => onUploadFileButtonClick()}
                >
                  <p>Drop files here or click to upload</p>
                  <input
                    type="file"
                    name="upload-image"
                    id="upload-image"
                    onChange={uploadImage}
                    accept={mimeType.join(",")}
                    className="w-100 h-100"
                    ref={inputFile}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
                {selectedImage.length > 0 && (
                  <div className="container border mt-4">
                    <div
                      className="row pt-1 pb-2 "
                      style={{ overflowX: "auto" }}
                    >
                      <div className="d-flex">
                        {formik.values.image &&
                          formik.values.image.map((src, index) => {
                            return (
                              <div key={index}>
                                <div className="position-relative fit-content m-2 cursor-pointer">
                                  <CloseRounded
                                    className="position-absolute bg-secondary"
                                    onClick={() => removeImage(src)}
                                  />
                                  <img
                                    style={{
                                      height: "100px",
                                      width: "150px",
                                      border: "1px solid gray",
                                    }}
                                    src={src}
                                    alt=""
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
                {formik.touched.image && formik.errors.image ? (
                  <div className="text-danger mb-0 mt-4 pt-1">
                    {formik.errors.image}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* PHOTO END */}

          <div className="row mt-10">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Details (Chinese)
            </div>
            <div className="col-7">
              <div className="form-group">
                <CKEditor
                  editor={DecoupledcEditor}
                  // config={{
                  //   toolbarLocation: "bottom"
                  // }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  className="ck"
                  data={formik.values.details_chi || ""}
                  onReady={(editor) => {
                    editor.ui
                      .getEditableElement()
                      .parentElement.append(editor.ui.view.toolbar.element);
                  }}
                  onChange={DetailsChineseHandleChange}
                  onBlur={() => formik.setFieldTouched("details_chi", true)}
                />

                {formik.touched.details_chi && formik.errors.details_chi ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.details_chi}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-10">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Details (English)
            </div>
            <div className="col-7">
              <div className="form-group">
                <CKEditor
                  editor={DecoupledcEditor}
                  // config={{
                  //   toolbarLocation: "bottom"
                  // }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  className="ck"
                  data={formik.values.details || ""}
                  onReady={(editor) => {
                    editor.ui
                      .getEditableElement()
                      .parentElement.append(editor.ui.view.toolbar.element);
                  }}
                  onChange={DetailsHandleChange}
                  onBlur={() => formik.setFieldTouched("details", true)}
                />

                {formik.touched.details && formik.errors.details ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.details}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Service Tag
            </div>
            <div className="col-4">
              <div className="form-group">
                <SearchSelect
                  isMulti={true}
                  options={tagOptions}
                  onChange={(opt: any) => {
                    const newVall = opt?.map((x: any) => x?.value) || [];
                    formik.setFieldValue("tags", newVall);
                  }}
                  value={tagOptions.filter(
                    (x: any) =>
                      formik.values?.tags
                        ?.map((y) => y._id)
                        ?.indexOf(x?.value?._id) !== -1
                  )}
                />
                {formik.touched.tags && formik.errors.tags ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tags}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Status
            </div>
            <div className="col-4">
              <div className="form-group">
                <Switch
                  name="isActive"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.isActive || ""}
                  checked={
                    formik.values.isActive ? formik.values.isActive : false
                  }
                />
                {formik.touched.isActive && formik.errors.isActive ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.isActive}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/companyService/list">
                Back
              </Link>
            </div>
            <div className="mt-4 mt-sm-0">
              <button
                type="submit"
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params?.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default AddCompanyService;
