import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../helpers";
import { Cascader } from "antd";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../partials/controls";
import { useHistory } from "react-router-dom";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { sortString } from "../../../common/table/sorter";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { api } from "../../../common/api";
import { toast } from "react-toastify";

const optionslist = [
  {
    label: "Blank",
    value: "BLANK",
  },
  {
    label: "From Regular",
    value: "FROM_REGULAR",
    isLeaf: false,
  },
  {
    label: "From Pre-Schedule",
    value: "FROM_PRE_SCHEDULE",
    isLeaf: false,
  },
];

const ScheduleDaily: FC = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const siteId = searchParams.get("siteId");
  const isSiteId = siteId ? `?siteId=${siteId}` : "";
  const history = useHistory();

  const [query, setQuery] = useState<string>("");
  const [templateEntities, setTemplateEntities] = useState([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("true");
  const [sortingType, setSortingType] = useState<any>("");
  const [createOptions, setCreateOptions] = useState(optionslist);

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isQuery = query !== "" ? `${query}` : "";
    const isSiteID = siteId ? `&siteId=${siteId}` : "";
    const isSortColumn = sortingType !== "" ? `&${sortingType}` : "";
    try {
      const { data }: any = await api({
        url: `/pre-schedule/list?isActive=${type}&search=${isQuery}${isSiteID}&page=${page}&size=${sizePerPage}${isSortColumn}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.preScheduleData.map(
          (entity: any, ind: number) => {
            return {
              ...entity,
              dates: entity?.dates?.join(", "),
              id: ++ind + (page - 1) * sizePerPage,
              isActive: entity.isActive ? "ACTIVE" : "INACTIVE",
            };
          }
        );
        setTotalData(data.result.data.countTodaySiteList);
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    // load options lazily
    if (selectedOptions[0]?.value === "FROM_REGULAR") {
      targetOption.loading = false;
      targetOption.children = templateEntities.map((item: any) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
    } else if (selectedOptions[0]?.value === "FROM_PRE_SCHEDULE") {
      targetOption.loading = false;
      targetOption.children = entities?.map((item: any) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
    }

    setCreateOptions([...createOptions]);
  };

  const getTemplateEntities = async () => {
    const isSiteID = siteId ? `&siteId=${siteId}` : "";
    try {
      const { data }: any = await api({
        url: `/template/list/?page=1&size=1000&isActive=${type}${isSiteID}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.templateData;
        setTemplateEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const onChange = (value: any, selectedOptions: any) => {
    if (value[0] === "BLANK") {
      history.push(`/pre-schedule/add${isSiteId}`);
    } else if (value[0] === "FROM_REGULAR" && value[1]) {
      history.push(`/pre-schedule/add${isSiteId}&templateId=${value[1]}`);
    } else if (value[0] === "FROM_PRE_SCHEDULE" && value[1]) {
      history.push(`/pre-schedule/add${isSiteId}&preScheduleId=${value[1]}`);
    }
  };

  useEffect(() => {
    getTemplateEntities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingType, siteId]);

  const columns = [
    {
      dataField: "name",
      text: "Pre-Schedule Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
    {
      dataField: "dates",
      text: "Dates",
      sort: false,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setSortingType),
    },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: (data: any) =>
      history.push(`/pre-schedule/update/${data._id}${isSiteId}`),
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  return (
    <Card>
      <CardHeader title="Pre-Schedule">
        <CardHeaderToolbar>
          <Cascader
            style={{ width: 100, height: "38.38px" }}
            placeholder="Create"
            options={createOptions}
            loadData={loadData}
            onChange={onChange}
            changeOnSelect
            allowClear={false}
          />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <div className="d-flex flex-column flex-sm-row">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={setQuery}
                    title="Search"
                  />
                  <StatusFilter
                    status={type}
                    setStatus={setType}
                    options={[
                      { label: "All", value: "" },
                      { label: "Active", value: "true" },
                      { label: "Inactive", value: "false" },
                    ]}
                    title="Type"
                  />
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => {
                      setPage(1);
                      getEntities(1, sizePerPage);
                    }}
                  >
                    <span className="navigation-icon icon-white">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Search.svg"
                        )}
                      />
                    </span>
                  </button>
                </div>

                {/* <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    history.push(`/pre-schedule/add${isSiteId}`);
                  }}
                >
                  Add Pre-Schedule
                </button> */}
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default ScheduleDaily;
